import qs from 'querystringify';

export const isSalsify = () => /salsify/i.test(document.location.hostname);

export const getAppHostname = () =>
  isSalsify() ? 'supplierxm.salsify.com' : 'alkemics.com';

export const getLocation = () => location;

export const withQuery = (url: string, query: { [key: string]: any }) =>
  `${url}${qs.stringify(query, true)}`;

export const NAVIGATE_EVENT = 'root_app:navigate';

export const navigate = (opt: {
  to: string;
  queries?: { [key: string]: any };
  replace?: boolean;
}) => {
  window.postMessage(
    {
      type: NAVIGATE_EVENT,
      to: withQuery(opt.to, opt.queries || {}),
      replace: opt.replace || false,
    },
    window.location.origin,
  );
};
