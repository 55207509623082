import moment, { Moment } from 'moment';
import { ChangeEvent } from 'react';

import { Checkbox } from '@alkem/react-ui-inputs';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import { Ruleset } from 'modules/ruleset-selector/state';
import { VALIDATION_RULESET_TYPE__MATURITY } from 'modules/validation-dashboard/constants';

import * as constants from '../../constants';

import { DeadLineSelector } from './DeadlineSelector';
import './rule-set.scss';

interface Props {
  ruleset: Ruleset;
  disabled?: boolean;
  manageDeadline?: boolean;
  onUpdate: (r: Ruleset) => void;
}

export const RulesetEditor = (props: Props) => {
  const { ruleset, disabled = false, manageDeadline = true, onUpdate } = props;

  const {
    id: rulesetId,
    restrictionType,
    with_deadline = false,
    default_deadline = null,
    displayName,
    type: ruleSetType,
  } = ruleset.toJS();

  const tomorrow = moment().add(1, 'day').endOf('day');

  const handleUpdateBlocking = (event: ChangeEvent<Element>) => {
    const e = event as ChangeEvent<HTMLInputElement>;
    onUpdate(
      ruleset.set(
        'restrictionType',
        e.currentTarget.checked
          ? constants.restrictionTypes.blocking
          : constants.restrictionTypes.warning,
      ),
    );
  };

  const toggleWithDeadline = () => {
    const wd = !with_deadline;
    let rs: Ruleset = ruleset.set('with_deadline', wd ? 1 : 0);
    if (wd && !default_deadline) {
      // ensure default_deadline is set whenever with_deadline is true
      rs = rs.set('default_deadline', tomorrow.format());
    }
    onUpdate(rs);
  };

  const handleDefaultDeadlineChange = (default_deadline: Moment) => {
    const rs = ruleset.set('default_deadline', default_deadline.format());
    onUpdate(rs);
  };

  const editedDeadline = default_deadline ? moment(default_deadline) : tomorrow; // in case data from back is inconsistent (with_Deadline, default_deadline) couple

  const proposeDeadline =
    manageDeadline && ruleSetType === VALIDATION_RULESET_TYPE__MATURITY;

  return (
    <div className="RuleSet">
      <div>{displayName}</div>
      {proposeDeadline && (
        <div>
          <Checkbox
            id={`with_deadline-${rulesetId}`}
            checked={!!with_deadline}
            onChange={toggleWithDeadline}
            label={
              <div className="ValidationRule__checkbox">with deadline</div>
            }
            disabled={disabled}
          />
          {!!with_deadline && (
            <DeadLineSelector
              baseTestId={`rule-${rulesetId}-deadline-selector`}
              value={editedDeadline}
              onChange={handleDefaultDeadlineChange}
              disabled={disabled}
              minimumDate={tomorrow}
            />
          )}
          <LazyTooltip
            id={`with_deadline-${rulesetId}-tooltip`}
            className="deadline-tooltip"
            tooltipLabel={
              'by checking this box you tell that deadline must be set when applying a ruleset to a product, with the given provided date as fallback'
            }
          >
            <i className="mdi mdi-help-circle" />
          </LazyTooltip>
        </div>
      )}
      <Checkbox
        id={`restrictionType-${rulesetId}`}
        checked={!!restrictionType}
        onChange={handleUpdateBlocking}
        label={<div className="ValidationRule__checkbox">blocking</div>}
        disabled={disabled}
      />
    </div>
  );
};
