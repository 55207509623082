import PropTypes from 'prop-types';
import qs from 'querystringify';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import * as routes from 'constants/routes';
import { withLocation } from 'hocs/with-location';

import DashboardList from './dashboard-list';
import ReportingResult from './result';

// Class
export class ReportingDashboard extends PureComponent {
  static propTypes = { location: PropTypes.object.isRequired };

  renderPage() {
    const queries = qs.parse(this.props.location.search);
    const dashboardListParams = {
      isAdmin: queries.a === 'true',
      dashboard: queries.d ? queries.d : null,
      orga: queries.o ? [queries.o] : null,
      filter: queries.f ? queries.f : null,
    };
    return (
      <div className="ReportingConfig__container row">
        <div className="ReportingConfig__menu col-md-3">
          <DashboardList params={dashboardListParams} />
        </div>

        <div className="ReportingConfig__body col-md-9">
          <ReportingResult />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="ReportingConfig">
        <HeaderLayout
          title="Reporting Handling"
          backHref={routes.home}
          backMessage="Back to list"
          isTitleSmall
        />
        {this.renderPage()}
      </div>
    );
  }
}

export default connect()(withLocation(ReportingDashboard));
