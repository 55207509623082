import { useState } from 'react';

import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

import { IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_KEEP_HASH } from '../../../../constants';
import { ImportMappingDetails, Property } from '../../../../types';

interface Props {
  details: ImportMappingDetails;
  dispatchImportMapping: (properties: Property[]) => void;
  fieldName: string;
  type: string;
}
export function ImportMappingsItemOptionalPropertyMustKeepHash({
  details,
  dispatchImportMapping,
  fieldName,
  type,
}: Props): JSX.Element {
  const [mustKeepHash, setMustKeepHash] = useState(details.keep_hash);

  const updateMustKeepHash = (event: { target: { value: string } }) => {
    const importMappingDetailsMustKeepHash =
      event.target.value === 'true' ? true : undefined;
    setMustKeepHash(importMappingDetailsMustKeepHash);
    dispatchImportMapping([
      { name: 'keep_hash', value: importMappingDetailsMustKeepHash },
    ]);
  };

  return (
    <>
      {IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_KEEP_HASH.includes(type) && (
        <InputWithLabel
          inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-must-keep-hash`}
          label="Must keep hash"
        >
          <Radio
            id={`gdsn-import-mappings-field-${fieldName}-optional-property-must-keep-hash`}
            value={mustKeepHash}
            options={[
              { label: 'TRUE', value: true },
              { label: 'FALSE', value: undefined },
            ]}
            onChange={updateMustKeepHash}
          />
        </InputWithLabel>
      )}
    </>
  );
}
