import React from 'react';
import listensToClickOutside from 'react-onclickoutside';

import { ChoiceTree } from '@alkem/react-ui-choicetree';
import { SelectNoListener } from '@alkem/react-ui-select';

class FieldSelect extends SelectNoListener {
  renderChoiceTree() {
    const { id, isTree, values, extraOptions, multiple } = this.props;
    const { isOpen, options, focusPath, inputValue } = this.state;
    const choicelistHtmlId = `SelectChoiceTree-${id}`;
    if (!isOpen || !options.length) {
      return null;
    }
    return (
      <ChoiceTree
        id={choicelistHtmlId}
        options={options}
        onSelect={this.onSelectChange}
        selectedValues={values}
        focusPath={focusPath}
        selectedPath={isTree && !multiple ? this.state.selectedPath : []}
        extraOptions={extraOptions}
        flatten={this.shouldFlattenOptions()}
        matchText={inputValue}
        multiple
      />
    );
  }
}

export default listensToClickOutside(FieldSelect);
