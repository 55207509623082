import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';
import { Tooltip } from '@alkem/react-ui-tooltip';

import StatusBar from 'components/ui/status-bar';
import { separateActions } from 'utils/redux';
import {
  TIME_FORMAT_SECOND,
  TIME_FORMAT_SECOND_NO_DAY,
  formatTimeImplicitUTC,
} from 'utils/time';

import { downloadFile, loadImportDetail, updateFilter } from '../../actions';

const stepIcons = {
  upload: 'mdi-file-upload-outline',
  queued: 'mdi-pipe',
  import: 'mdi-import',
  configuration: 'mdi-wrench',
  fetch: 'mdi-download',
  crawl: 'mdi-format-align-justify',
  parse: 'mdi-shuffle-variant',
  operate: 'mdi-import',
};

const mapDispatchToProps = {
  updateFilter,
  loadImportDetail,
  downloadFile,
};

const mapStateToProps = () => ({});

export class ImportFileRow extends PureComponent {
  static propTypes = {
    importfile: PropTypes.object.isRequired,
    importdetail: PropTypes.array,
    actions: PropTypes.shape({
      updateFilter: PropTypes.func.isRequired,
      loadImportDetail: PropTypes.func.isRequired,
      downloadFile: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      hasRows: false,
      offset: 0,
      limit: 20,
    };
  }

  onClickLinkToImport = (import_id) => {
    return () => this.props.actions.updateFilter('import_ids', import_id);
  };

  onClickDownload = (import_id) => {
    return () => this.props.actions.downloadFile(import_id);
  };

  onClickNextPage = () => {
    const { offset, limit } = this.state;
    const { importfile } = this.props;
    this.setState({ offset: offset + limit, hasRows: true });
    this.props.actions.loadImportDetail(importfile.id, offset + limit, limit);
  };

  onClickPreviousPage = () => {
    const { offset, limit } = this.state;
    const { importfile } = this.props;
    this.setState({ offset: offset - limit });
    this.props.actions.loadImportDetail(importfile.id, offset - limit, limit);
  };

  onClickExpand = () => {
    const { expanded, offset, limit } = this.state;
    const { importfile, importdetail } = this.props;
    if (!expanded && !importdetail) {
      this.props.actions.loadImportDetail(importfile.id, offset, limit);
    }
    this.setState({ expanded: !expanded });
  };

  renderSteps(id, steps) {
    const timeline = steps.map((step, i) => (
      <span
        key={step.name}
        data-tip
        data-for={`importfile-${id}-step-${step.name}`}
      >
        {i > 0 ? <i className="mdi mdi-arrow-right" /> : null}
        <i
          className={`mdi ${
            stepIcons[step.name] || 'mdi-help'
          } ImportFileListRow__Step--${step.status}`}
        />
        <Tooltip id={`importfile-${id}-step-${step.name}`}>
          <div className="ImportDetailsIcon__Tooltip--Header">{step.name}</div>
          <div>
            <i className="mdi mdi-calendar-import" />
            {formatTimeImplicitUTC(step.startedAt, TIME_FORMAT_SECOND)}
            <i className="mdi mdi-arrow-right" />
            {formatTimeImplicitUTC(step.endedAt, TIME_FORMAT_SECOND_NO_DAY)}
            {` (duration: ${moment(`${step.endedAt}Z`).diff(
              moment(`${step.startedAt}Z`),
              'seconds',
              true,
            )}s)`}
          </div>
          {step.data.message ? <div>message: {step.data.message}</div> : null}
        </Tooltip>
      </span>
    ));
    return <span>{timeline}</span>;
  }

  renderAction() {
    const { importfile } = this.props;
    const { expanded } = this.state;
    return (
      <div>
        {(importfile.parameters.fetcher ||
          importfile.parameters_replay.fetcher) && (
          <i
            className="mdi mdi-download ImportDetailsIcon"
            onClick={this.onClickDownload(importfile.id)}
          />
        )}
        <i
          className="mdi mdi-link-variant ImportDetailsIcon"
          onClick={this.onClickLinkToImport(importfile.id)}
        />
        <i
          className={`mdi mdi-chevron-${
            expanded ? 'up' : 'down'
          } ImportDetailsIcon`}
          onClick={this.onClickExpand}
        />
      </div>
    );
  }

  renderDetail() {
    const { offset, limit, hasRows } = this.state;
    const { importdetail } = this.props;

    if (!importdetail) {
      return (
        <div className="ImportDetailsRowWrapper">
          <div className="ImportDetailsRow ImportDetailsRow__Lines row">
            <StatusBar type="EMPTY" />
            <div className="col-xs-8">
              <Spinner loading small /> ... loading ...
            </div>
          </div>
        </div>
      );
    }
    if (!hasRows && importdetail && importdetail.length === 0) {
      return (
        <div className="ImportDetailsRowWrapper">
          <div className="ImportDetailsRow ImportDetailsRow__Lines row">
            <StatusBar type="EMPTY" />
            <div className="col-xs-8">
              No entities attached to this import{' '}
              <i className="mdi mdi-heart-broken" />
            </div>
          </div>
        </div>
      );
    }
    const lines = importdetail.map((line) => (
      <div
        key={`import-detail-${importdetail.id}-${line.identifier}`}
        className="ImportDetailsRow ImportDetailsRow__Lines row"
      >
        <StatusBar type="EMPTY" />
        <div className="col-xs-2">{line.identifier}</div>
        <div className="col-xs-2">{line.type}</div>
        <div className="col-xs-2">{`${line.organization.nameLegal} (${line.organization.id})`}</div>
        <div className="col-xs-6">
          {this.renderSteps(
            `detail-${importdetail.id}-${line.identifier}`,
            line.steps,
          )}
        </div>
      </div>
    ));

    return (
      <div className="ImportDetailsRowWrapper">
        <div className="ImportDetailsRow ImportDetailsRow__Header row">
          <div className="col-xs-2">Identifier</div>
          <div className="col-xs-2">Type</div>
          <div className="col-xs-2">Organization</div>
          <div className="col-xs-4">Workflow</div>
          <div className="col-xs-2">
            <span className="ImportDetailsRow__Header_icon">
              {offset > 0 && (
                <i
                  className="mdi mdi-arrow-left-bold"
                  onClick={this.onClickPreviousPage}
                />
              )}
              {importdetail.length === limit && (
                <i
                  className="mdi mdi-arrow-right-bold"
                  onClick={this.onClickNextPage}
                />
              )}
            </span>
          </div>
        </div>
        {importdetail.length > 0 && lines}
        {importdetail.length === 0 && hasRows && (
          <div className="ImportDetailsRowWrapper">
            <div className="ImportDetailsRow ImportDetailsRow__Lines row">
              <StatusBar type="EMPTY" />
              <div className="col-xs-8">No more entity to see.</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { importfile } = this.props;
    const { expanded } = this.state;
    return (
      <div className="ImportFileListRowWrapper">
        <div
          key={importfile.id}
          id={importfile.id}
          className="ImportFileListRow row"
        >
          <StatusBar type={importfile.status} />
          <div className="col-xs-4 ">
            {importfile.organization && (
              <div>
                <i className="mdi mdi-domain" />{' '}
                {`${importfile.organization.nameLegal} (${importfile.organization.id})`}
              </div>
            )}
            {importfile.user && (
              <div>
                <i className="mdi mdi-account" />{' '}
                {`${importfile.user.username} (${importfile.user.id})`}
              </div>
            )}
            {importfile.user_admin && (
              <div>
                <i className="mdi mdi-account-key" />{' '}
                {`${importfile.user_admin.username} (${importfile.user_admin.id})`}
              </div>
            )}
          </div>
          <div className="col-xs-1">
            <div className="ImportFileListRow__tag ImportFileListRow__tag_product">
              {importfile.importer}
            </div>
          </div>
          <div className="col-xs-1 ImportFileListRow__count">
            {importfile.entity_total_count > 0 &&
              `${importfile.entity_success_count} / ${importfile.entity_total_count}`}
          </div>
          <div className="col-xs-2">
            <div>
              <i className="mdi mdi-calendar-import" />
              {' '}
              {formatTimeImplicitUTC(importfile.startedAt, TIME_FORMAT_SECOND)}
            </div>
            <div>
              <i className="mdi mdi-calendar-clock" />
              {' '}
              {importfile.endedAt
                ? formatTimeImplicitUTC(importfile.endedAt, TIME_FORMAT_SECOND)
                : '-'}
            </div>
            <div>
              <i className="mdi mdi-calendar-check" />
              {' '}
              {importfile.closedAt
                ? formatTimeImplicitUTC(importfile.closedAt, TIME_FORMAT_SECOND)
                : '-'}
            </div>
          </div>
          <div className="col-xs-3">
            {this.renderSteps(importfile.id, importfile.steps)}
          </div>
          <div className="col-xs-1 ImportFileListRow__actions">
            {this.renderAction()}
          </div>
        </div>
        {expanded && this.renderDetail()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(ImportFileRow);
