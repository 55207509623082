import React from 'react';

import authApi from 'resources/authApi';

import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from '../legacy-autocomplete';

export const OrganizationGroupAutocomplete = React.memo(
  function OrganizationGroupAutocomplete(props: LegacyAutocompleteProps) {
    const onSearch = (search) => authApi.listOrganizationGroups(search || '');

    const transformData = (data) =>
      data.map((e) => ({
        ...e,
        label: e.name,
        key: e.id,
        value: e,
      }));

    return (
      <LegacyAutocomplete
        onSearch={onSearch}
        transformData={transformData}
        {...props}
      />
    );
  },
);
