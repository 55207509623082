import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';

import Notification from 'components/notification';
import { separateActions } from 'utils/redux';

import { closeSaveModal, save } from '../../actions/save-modal';
import { STATUS_PENDING } from '../../constants/save-modal';
import {
  selectFeatureStatus,
  selectIsModalOpened,
  selectSaveInProgress,
} from '../../selectors/save-modal';

import Summary from './summary';

const mapStateToProps = (state) => ({
  saveInProgress: selectSaveInProgress(state),
  isModalOpened: selectIsModalOpened(state),
  featureStatus: selectFeatureStatus(state),
});

const mapDispatchToProps = {
  closeSaveModal,
  save,
};

export class OrganizationPageSaveModal extends PureComponent {
  static propTypes = {
    saveInProgress: PropTypes.bool.isRequired,
    isModalOpened: PropTypes.bool.isRequired,
    featureStatus: ImmutablePropTypes.map.isRequired,
    actions: PropTypes.shape({
      save: PropTypes.func.isRequired,
      closeSaveModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  onClose = () => {
    if (!this.props.saveInProgress) {
      this.props.actions.closeSaveModal();
    }
  };

  isSaveDisabled = () => {
    const { featureStatus } = this.props;
    return !featureStatus
      .valueSeq()
      .map(({ value }) => value)
      .contains(STATUS_PENDING.value);
  };

  render() {
    const { actions, isModalOpened, saveInProgress } = this.props;
    if (!isModalOpened) {
      return null;
    }
    return (
      <Modal
        title="Save Organization"
        modalStyle="dynamic"
        confirmButtonText="Save"
        confirmDisabled={this.isSaveDisabled()}
        isProcessing={saveInProgress}
        onConfirm={actions.save}
        onClose={this.onClose}
      >
        <Notification type="modal" />
        <Summary />
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(OrganizationPageSaveModal);
