import { Map } from 'immutable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';

import Notification from 'components/notification';

import {
  acceptUser,
  activateUser,
  closeUserUpdateModal,
  deactivateUser,
  expireTokens,
  listTokens,
  update,
} from '../actions/modal';
import {
  selectIsEdited,
  selectIsModalLoading,
  selectIsModalOpen,
  selectModalUser,
  selectTokens,
} from '../selectors/modal';

import { UpdateUserFooter } from './footer';
import UserUpdateForm from './form';

type Props = {
  onClose?: (isEdited: boolean) => void;
};

const UserUpdate = React.memo((props: Props) => {
  const { onClose } = props;

  const dispatch = useDispatch();
  const user: Map<string, any> = useSelector(selectModalUser);
  const isModalOpen: boolean = useSelector(selectIsModalOpen);
  const isLoading: boolean = useSelector(selectIsModalLoading);
  const isEdited: boolean = useSelector(selectIsEdited);
  const tokens: any[] = useSelector(selectTokens);
  const adminUser = useSelector(({ user }: any) => user);

  const [modalState, setModalState] = useState(false);
  const openExpireConfirmationModal = () => setModalState(true);
  const hideExpireConfirmationModal = () => setModalState(false);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(listTokens());
    } else {
      hideExpireConfirmationModal();
      onClose && onClose(isEdited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  if (!user || !isModalOpen) {
    return null;
  }

  const isAccepted = !!user.getIn(['belongsTo', 0, 'accepted']);

  const _onClose = () => dispatch(closeUserUpdateModal());

  const onUpdate = () => dispatch(update());

  const onActivate = () => dispatch(activateUser());

  const onDeactivate = () => dispatch(deactivateUser());

  const onAccept = () => {
    dispatch(acceptUser(user.get('id'), user.getIn(['belongsTo', 0, 'id'])));
  };

  const onExpireTokens = () => dispatch(expireTokens());

  const getTokensCount = () => (tokens ? tokens.length : 0);

  const isActivable = () => {
    return user.get('status') === 0 || user.get('status') === 2;
  };

  const isDeactivable = () => {
    return user.get('status') === 1 && user.get('id') !== adminUser.get('id');
  };

  const isAcceptable = () => {
    return !user.getIn(['belongsTo', 0, 'accepted']);
  };

  const renderAdditionalFooterContent = () => {
    return (
      <UpdateUserFooter
        onActivate={isActivable() ? onActivate : undefined}
        onDeactivate={isDeactivable() ? onDeactivate : undefined}
        onAccept={isAcceptable() ? onAccept : undefined}
        onExpireTokens={
          getTokensCount() > 0 ? openExpireConfirmationModal : undefined
        }
        tokensCount={getTokensCount()}
        isLoading={isLoading}
      />
    );
  };

  return (
    <Modal
      title="Update user"
      modalStyle="dynamic"
      confirmButtonText="Update"
      isProcessing={isLoading}
      onConfirm={onUpdate}
      onClose={_onClose}
      additionalFooterContent={renderAdditionalFooterContent()}
    >
      <Notification type="modal" />
      <UserUpdateForm isAccepted={isAccepted} />
      {modalState && (
        <Modal
          title="Revoke tokens"
          modalStyle="dynamic"
          onConfirm={onExpireTokens}
          onClose={hideExpireConfirmationModal}
        >
          <div>Are you sure you want to revoke those tokens ?</div>
        </Modal>
      )}
    </Modal>
  );
});

export { UserUpdate };
