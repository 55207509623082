export function getKindAndChildrenIds(kind) {
  return [kind.id].concat(getChildrenIds(kind));
}

function getChildrenIds(kind) {
  return kind.children.reduce(
    (_ids, child) => _ids.concat(getKindAndChildrenIds(child)),
    [],
  );
}

export function getKindsOutsideTree(inclusionsAndExclusions, kindsIndex) {
  // EXP-879
  // ignore kinds that are not under the main Kind tree

  // there are multiple kinds trees but we handle only the main one
  // if we encounter a kinds from another tree (such as Purgatory tree)
  // we need to ignore it while computing the selection

  return inclusionsAndExclusions.filter(({ kind }) => !kindsIndex[kind.id]);
}

export function computeSelectedFromInclusionsAndExclusions(
  inclusionsAndExclusions,
  kindsIndex,
) {
  const selected = {};

  const ignored = getKindsOutsideTree(inclusionsAndExclusions, kindsIndex);
  const _inclusionsAndExclusions = inclusionsAndExclusions.filter(
    ({ kind }) => kindsIndex[kind.id],
  );

  // start with included
  const included = _inclusionsAndExclusions.filter((kind) => !kind.exclusion);
  const includedIds = included.map(({ kind }) => kind.id);
  for (const { kind } of included) {
    const ids = getKindAndChildrenIds(kindsIndex[kind.id]);
    for (const id of ids) {
      selected[id] = true;
    }
  }

  // then remove excluded
  const excluded = _inclusionsAndExclusions.filter((kind) => kind.exclusion);
  for (const { kind } of excluded) {
    const ids = getKindAndChildrenIds(kindsIndex[kind.id]);
    for (const id of ids) {
      if (!includedIds.includes(id)) {
        selected[id] = false;
      }
    }
  }

  return [selected, ignored];
}

export function computeInclusionAndExclusionsFromSelection(
  selection,
  kindsIndex,
  ignored,
) {
  const _selection = simplifySelection(selection, kindsIndex);
  const inclusionsAndExclusions = Object.keys(_selection).map((id) => ({
    displayInfo: null, // is it necessary?
    exclusion: !_selection[id],
    kind: { id, label: kindsIndex[id].name },
  }));

  return inclusionsAndExclusions.concat(ignored);
}

function simplifySelection(selection, kindsIndex) {
  const simplifiedSelection = { ...selection };

  // omit redundant
  for (const id of Object.keys(kindsIndex)) {
    const parentId = kindsIndex[id].parent && kindsIndex[id].parent.id;
    const inheritsFromParent =
      parentId && (selection[parentId] || null) === (selection[id] || null);
    if (inheritsFromParent || (!parentId && selection[id] === false)) {
      delete simplifiedSelection[id];
    }
  }

  return simplifiedSelection;
}
