import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class FieldsReadOnlySettingEditModalFieldIdsLine extends PureComponent {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
  };

  delete = () => {
    const { onDelete, field } = this.props;
    onDelete(field.id);
  };

  render() {
    const { field } = this.props;
    return (
      <li>
        <strong>{field.name}</strong> ({field.id}){' '}
        <span
          className="FieldsReadOnlySettingEditModal__deleteButton mdi mdi-delete"
          onClick={this.delete}
        />
      </li>
    );
  }
}
