import { fromJS } from 'immutable';
import qs from 'querystringify';

import { notificationError, notificationSuccess } from 'actions/notifications';
import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
  RFP_ANSWER,
  SHARING_UNIT,
  TARIFF,
} from 'constants/fields';
import {
  ORGANIZATION_TYPE_RETAILER,
  ORGANIZATION_TYPE_THIRD_PARTY,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
} from 'constants/organization';
import * as googleApis from 'modules/referentials/services/googleapis';
import { ruleEntityTypes } from 'modules/validation-dashboard/constants';
import buyingUnitApi from 'resources/buyingUnitApi';
import coreApi from 'resources/coreBaseApi';
import { fieldsApiImmutable as fieldsApi } from 'resources/fieldsApi';
import onboardingApi from 'resources/onboardingApi';
import { validationApi } from 'resources/validationApi';
import { logError } from 'utils';
import { get } from 'utils/immutable';
import { navigate } from 'utils/location';
import { extractSpreadsheetData, unserializeData } from 'utils/spreadsheet';

import { restrictionTypes } from './constants';
import * as events from './events';
import { authApi } from './resources';
import {
  selectEditedOrganization,
  selectEditedReferentials,
  selectImportedReferentials,
  selectSelectedRootIdReferentials,
  selectSourceReferentials,
} from './selectors';
import {
  addLeaves,
  addRemoveOrganizationReferential,
  checkNodeSelected,
  groupDataColumns,
  withOrganizationReferential,
} from './utils';

export const getOrganizationRules = (organizationId) => async (dispatch) => {
  dispatch({ type: events.FETCH_RULES });
  try {
    const response = await validationApi.getOrganizationRules(organizationId);
    dispatch({
      type: events.RECEIVE_RULES,
      rules: fromJS(response.data.rules),
      ruleSets: fromJS(response.data.ruleSets),
    });
  } catch (e) {
    logError(e);
    dispatch(notificationError('Failed to fetch organization rules'));
  } finally {
    dispatch({ type: events.FETCH_RULES_DONE });
  }
};

export const saveOrganizationRules =
  (organization, checkSharingUnitRules) => async (dispatch) => {
    const { rules, ruleSets } = organization;
    if (
      checkSharingUnitRules &&
      !rules
        .filter((r) => r.entityType === ruleEntityTypes.SHARING_UNIT.id)
        .some((r) => r.restrictionType === restrictionTypes.blocking)
    ) {
      dispatch(
        notificationError(
          'No required blocking field for the sharing units. Please configure one.',
        ),
      );
      return null;
    }
    try {
      return await validationApi.upsertOrganizationRules(organization.id, {
        rules,
        ruleSets,
      });
    } catch (e) {
      logError(e);
      dispatch(notificationError('Failed to update organization rules'));
      return null;
    }
  };

export const selectOrganization = (organization) => async (dispatch) => {
  const organizationId = get(organization, 'id');
  navigate({
    to: `${location.pathname}${
      organizationId
        ? qs.stringify({ organization_id: organizationId }, true)
        : ''
    }`,
    replace: true,
  });
  dispatch({
    type: events.SELECT_ORGANIZATION,
    organization,
  });
  if (organizationId) {
    await dispatch(getOrganizationRules(organizationId));
  }
};

export const importOrganization = (organization) => async (dispatch) => {
  const organizationId = get(organization, 'id');
  navigate({
    to: `${location.pathname}${
      organizationId
        ? qs.stringify({ organization_id: organizationId }, true)
        : ''
    }`,
    replace: true,
  });
  dispatch({
    type: events.IMPORT_ORGANIZATION,
    organization,
  });
  if (organizationId) {
    await dispatch(getOrganizationRules(organizationId));
  }
};

export const loadOrganization = (organizationId) => async (dispatch) => {
  const response = await authApi.OrganizationListV3(
    {
      types: [ORGANIZATION_TYPE_RETAILER.id, ORGANIZATION_TYPE_THIRD_PARTY.id],
      statuses: [STATUS_ACTIVE, STATUS_INACTIVE],
      ids: [organizationId],
    },
    {
      fieldsUsed: true,
      type: true,
      network: true,
      settings: true,
      status: true,
    },
  );
  const organization = response.data.data.get(0);
  await dispatch(selectOrganization(organization));
};

export const updateOrganizationFields = (value) => (dispatch) =>
  dispatch({
    type: events.UPDATE_ORGANIZATION_FIELDS,
    value,
  });

export const deleteRules = (fieldName, entityType) => ({
  type: events.DELETE_RULES,
  fieldName,
  entityType,
});

export const updateRules = (fieldName, entityType, path, value) => ({
  type: events.UPDATE_RULES,
  fieldName,
  entityType,
  path,
  value,
});

export const createRule = (createdRule) => ({
  type: events.CREATE_RULE,
  createdRule,
});

export const updateRuleSets = (ruleSets) => ({
  type: events.UPDATE_RULESETS,
  ruleSets,
});

export const saveOrganization = () => async (dispatch, getState) => {
  const organization = selectEditedOrganization(getState()).toJS();
  const checkSharingUnitRules =
    get(organization, 'settings.listing') === 'enable';
  try {
    await authApi.updateOrganizationUsesFields(organization);
    const response = await dispatch(
      saveOrganizationRules(organization, checkSharingUnitRules),
    );
    if (!response) {
      return;
    }

    dispatch({ type: events.ORGANIZATION_SAVED });
    dispatch({
      type: events.RECEIVE_RULES,
      rules: fromJS(response.data.rules),
      ruleSets: fromJS(response.data.ruleSets),
    });
    dispatch(
      notificationSuccess(
        'Successfully updated organization fields and rulesets',
      ),
    );
  } catch (e) {
    logError(e);
    dispatch(
      notificationError('Failed to update organization fields or rulesets'),
    );
  }
};

// REFERENTIALS

export const fetchReferentials = (params) => async (dispatch) => {
  try {
    const response = await buyingUnitApi.ListAdminReferentials(params);
    dispatch({
      type: events.REFERENTIALS_FETCHED,
      referentials: fromJS(response.data.data),
    });
  } catch (e) {
    logError(e);
    notificationError('An error occured while fetching the referentials');
  }
};

export const toggleReferential = (referential, value) => ({
  type: events.TOGGLE_REFERENTIAL,
  referential,
  value,
});

export const selectRootNode = (nodeId) => ({
  type: events.SELECT_ROOT_NODE,
  nodeId,
});

export const saveReferentials = () => async (dispatch, getState) => {
  const state = getState();
  const edited = selectEditedReferentials(state);
  const selectedRootNodeId = selectSelectedRootIdReferentials(state);
  const editedOrganizationId = selectEditedOrganization(state).get('id');
  const referential = edited
    .toJS()
    .filter((node) => node.id === selectedRootNodeId)[0];
  // Add or remove organization from root node
  const selected = referential.children.some((child) =>
    checkNodeSelected(child, editedOrganizationId),
  );
  const newReferential = addRemoveOrganizationReferential(
    referential,
    editedOrganizationId,
    selected,
  );
  try {
    dispatch({ type: events.START_SAVING });
    await buyingUnitApi.UpdateReferential(newReferential, false);
    dispatch({ type: events.REFERENTIAL_SAVED });
    dispatch({ type: events.STOP_SAVING });
    dispatch(notificationSuccess('Successfully updated referentials'));
  } catch (e) {
    logError(e);
    dispatch({ type: events.STOP_SAVING });
    dispatch(notificationError('Failed to update referentials'));
  }
};

export const addRootNode = (rootNode) => async (dispatch) => {
  try {
    dispatch({ type: events.START_ADDING });
    await buyingUnitApi.CreateReferential(rootNode);
    dispatch(
      fetchReferentials({
        with_organizations: true,
        translate_children: false,
      }),
    );
    dispatch({ type: events.STOP_ADDING });
    dispatch(notificationSuccess('Successfully added root referential'));
  } catch (e) {
    logError(e);
    dispatch({ type: events.STOP_ADDING });
    dispatch(notificationError('Failed to add root referential'));
  }
};

export const updateReferential = (referentialId, data) => async (dispatch) => {
  try {
    dispatch({ type: events.START_UPDATING });
    await buyingUnitApi.UpdateReferentialEntity(referentialId, {
      label: data.label,
      data: JSON.parse(data.data),
      translatable: data.translatable,
    });
    dispatch(
      fetchReferentials({
        with_organizations: true,
        translate_children: false,
      }),
    );
    dispatch({ type: events.STOP_UPDATING });
    dispatch(notificationSuccess('Successfully updated referential'));
  } catch (e) {
    logError(e);
    dispatch({ type: events.STOP_UPDATING });
    dispatch(notificationError('Failed to updated referential'));
  }
};

export const addChildrenToReferential =
  (referentialId, data) => async (dispatch) => {
    try {
      dispatch({ type: events.START_UPDATING });
      await buyingUnitApi.AddChildrenToReferential(referentialId, {
        code: data.code,
        label: data.label,
        data: JSON.parse(data.data),
      });
      dispatch(
        fetchReferentials({
          with_organizations: true,
          translate_children: false,
        }),
      );
      dispatch({ type: events.STOP_UPDATING });
      dispatch(notificationSuccess('Successfully updated referential'));
    } catch (e) {
      logError(e);
      dispatch({ type: events.STOP_UPDATING });
      dispatch(notificationError('Failed to updated referential'));
    }
  };

export const importReferentials = (url) => async (dispatch) => {
  try {
    dispatch({ type: events.START_IMPORTING });
    const data = await googleApis.loadSpreadsheet(url);
    const entities = extractSpreadsheetData(data, groupDataColumns);
    dispatch({
      type: events.REFERENTIALS_IMPORTED,
      importedReferentials: fromJS(entities),
    });
    dispatch({ type: events.STOP_IMPORTING });
    dispatch(notificationSuccess(`Imported successfully.`));
  } catch (error) {
    logError(error);
    dispatch({ type: events.STOP_IMPORTING });
    dispatch(notificationError(`Import has failed.`));
  }
};

export const saveImportedReferentials =
  (parent) => async (dispatch, getState) => {
    // Get imported referentials and current referentials from state
    const state = getState();
    const imported = selectImportedReferentials(state)
      .toJS()
      .map((entity) => unserializeData(entity));
    const source = selectSourceReferentials(state).toJS();
    const editedOrganization = selectEditedOrganization(state)
      ? selectEditedOrganization(state).toJS()
      : null;
    try {
      dispatch({ type: events.START_BRANCHING });
      // Build new tree for update
      let treeIndex;
      for (let i = 0; i < source.length; i += 1) {
        const found = addLeaves(
          source[i],
          parent,
          withOrganizationReferential(imported, editedOrganization),
        );
        if (found) {
          treeIndex = i;
          break;
        }
      }
      if (!treeIndex) {
        dispatch({ type: events.STOP_BRANCHING });
        dispatch(
          notificationError(
            `The selected parent does seem to be in the current tree`,
          ),
        );
        return;
      }
      // Add organization to root node if necessary
      if (editedOrganization) {
        source[treeIndex] = addRemoveOrganizationReferential(
          source[treeIndex],
          editedOrganization.id,
          true,
        );
      }
      // Save and update new tree
      await buyingUnitApi.UpdateReferential(source[treeIndex]);
      dispatch(
        fetchReferentials({
          with_organizations: true,
          translate_children: false,
        }),
      );
      dispatch({ type: events.STOP_BRANCHING });
      dispatch(notificationSuccess(`Imported referentials saved succesfully`));
    } catch (error) {
      logError(error);
      dispatch({ type: events.STOP_BRANCHING });
      dispatch(
        notificationError(`Imported referentials could not be saved. ${error}`),
      );
    }
  };

export const cleanViewAs = (organizationId, productIds) => (dispatch) => {
  const body = {
    content_owner_id: organizationId,
    commit: true,
  };
  if (productIds && productIds.length > 0) {
    body.product_ids = productIds;
  }
  return coreApi.post('/core/v4/versions/clean_view_as', body).then(() => {
    dispatch(
      notificationSuccess('Successfully sent the products to be cleaned'),
    );
  });
};

export const refreshCache = (organizationId) => async (dispatch) => {
  await authApi.post('/auth/v3/organization/viewas/refresh', {
    organization_ids: [organizationId],
  });
  dispatch(notificationSuccess('Successfully refreshed the cache'));
};

export const fetchFieldsWithValidationRules =
  (organizationId, fieldType, withSpecific = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: events.START_LOADING_FIELDS_WITH_RULES, fieldType });

      const trueFieldType =
        {
          [LOGISTICAL_UNIT]: CONSUMER_UNIT, // LUs and DUs are CU fields with added flags
          [DISPLAY_UNIT]: CONSUMER_UNIT,
          [TARIFF]: SHARING_UNIT, // Tariff fields are the same as Sharing Unit fields
          [RFP_ANSWER]: SHARING_UNIT, // Rfp answer fields are the same as Sharing Unit fields
        }[fieldType] || fieldType;

      const attributes = [
        'type',
        'functional_key',
        'declinable_by',
        'children',
        'displayInfo',
        'tags',
      ];
      if (trueFieldType === CONSUMER_UNIT) {
        attributes.concat(['specific']);
      }

      // retrieve fields
      const mainFieldsResponse = await fieldsApi.listMainFields(
        '',
        attributes,
        trueFieldType,
        {
          withSpecific,
          only_for_logistical_units: fieldType === LOGISTICAL_UNIT,
          only_for_display_units: fieldType === DISPLAY_UNIT,
        },
      );

      const fields = mainFieldsResponse.data.data;
      if (fields.size === 0) {
        dispatch({
          type: events.RECEIVE_FIELDS_FOR_FIELD_TYPE,
          fieldType,
          fields,
          requiredFieldNames: fromJS([]),
        });
      } else {
        // get fieldnames that are required
        const requiredFieldsResponse =
          await validationApi.filterRequiredFields(fields);
        dispatch({
          type: events.RECEIVE_FIELDS_FOR_FIELD_TYPE,
          fieldType,
          fields: mainFieldsResponse.data.data,
          requiredFieldNames: requiredFieldsResponse.data.data.get('fields'),
        });
      }
    } catch (e) {
      logError(e);
      dispatch(notificationError('Failed to fetch fields'));
    }
  };

// SUPPLIER REFERENTIAL

export const fetchSupplierReferentials =
  (dispatch) => async (retailerId, params) => {
    try {
      dispatch({
        type: events.SUPPLIER_REFERENTIALS_FETCHING,
      });
      const response = await onboardingApi.listReferentials(retailerId, {
        ...params,
        active: 1,
      });
      dispatch({
        type: events.SUPPLIER_REFERENTIALS_FETCHED,
        supplierReferentials: fromJS(
          response.data.data.map(({ name }) => name),
        ),
      });
    } catch (e) {
      logError(e);
      dispatch({
        type: events.SUPPLIER_REFERENTIALS_FETCHED,
        supplierReferentials: fromJS([]),
      });
      notificationError(
        'An error occured while fetching the supplier referentials',
      );
    }
  };
