import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';

import { DispatchType } from 'types/redux';

import { reviewTriggerOrganization } from '../../actions';

type Props = {
  organizationId: number;
  close: () => void;
};

export const OrganizationPageReviewTriggerModal = ({
  organizationId,
  close,
}: Props) => {
  const dispatch: DispatchType = useDispatch();

  const onReviewTrigger = useCallback(() => {
    dispatch(reviewTriggerOrganization(organizationId));
    close();
  }, [dispatch, close, organizationId]);

  return (
    <Modal
      title="Review Trigger Organization"
      confirmButtonText="Review trigger"
      onConfirm={onReviewTrigger}
      onClose={close}
      modalStyle="dynamic"
    >
      <p>Trigger all products review of an organization can take some time.</p>
    </Modal>
  );
};
