import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { getIn } from 'utils/immutable';

import './route.scss';
import Runnable from './runnable';

export default class DiscoverRoute extends PureComponent {
  static propTypes = {
    route: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
  };

  state = {
    isRunnableOpen: false,
  };

  clickRunnable = () =>
    this.setState((state) => ({ isRunnableOpen: !state.isRunnableOpen }));

  isRunnable = () => getIn(this.props.config, 'protocols.http.runnable');

  renderRunnable() {
    if (!this.state.isRunnableOpen) {
      return null;
    }
    return <Runnable config={this.props.config} />;
  }

  renderAuthorization() {
    const { authorization } = this.props.config;
    if (!authorization) {
      return null;
    }
    return (
      <span className="DiscoverRoute__authorization">
        {authorization.toUpperCase()}
      </span>
    );
  }

  renderDeprecated() {
    const deprecated = !!this.props.config.deprecated_by;
    if (!deprecated) {
      return null;
    }
    return <span className="DiscoverRoute__deprecated">DEPRECATED</span>;
  }

  renderHttp() {
    const { http } = this.props.config.protocols;
    if (!http) {
      return null;
    }
    return (
      <div className="DiscoverRoute__protocol">
        <span>HTTP: </span>
        <code>
          {http.method} {http.address}
        </code>
        {http.rpc && <span className="DiscoverRoute__rpc">RPC</span>}
      </div>
    );
  }

  renderRmq() {
    const { rmq } = this.props.config.protocols;
    if (!rmq) {
      return null;
    }
    return (
      <div className="DiscoverRoute__protocol">
        <span>RMQ: </span>
        <code>{rmq.address}</code>
      </div>
    );
  }

  renderDocumentation() {
    const { documentation } = this.props.config;
    if (!documentation) {
      return null;
    }
    return <pre className="DiscoverRoute__doc">{documentation}</pre>;
  }

  render() {
    const { route } = this.props;
    return (
      <div className="DiscoverRoute">
        <div className="DiscoverRoute__header">
          <div className="DiscoverRoute__label">
            <pre className="DiscoverRoute__name">{route}</pre>
            {this.renderAuthorization()}
            {this.renderDeprecated()}
          </div>
          <div className="DiscoverRoute__links">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://hound.internal.alkemics.com/?q=${route}`}
            >
              <i className="mdi mdi-magnify" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://app.datadoghq.com/logs?query=%40route.current%3A${route}+env%3Aprod`}
            >
              <i className="mdi mdi-dns" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://app.datadoghq.com/metric/explorer?exp_metric=alk.discovery.request.time.avg&exp_scope=env%3Aprod%2Croute%3A${route}&exp_agg=avg`}
            >
              <i className="mdi mdi-speedometer" />
            </a>
            {this.isRunnable() && (
              <i
                className="mdi mdi-spin mdi-alert-decagram-outline"
                onClick={this.clickRunnable}
              />
            )}
          </div>
        </div>
        {this.renderDocumentation()}
        {this.renderHttp()}
        {this.renderRmq()}
        {this.renderRunnable()}
      </div>
    );
  }
}
