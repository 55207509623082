import { List, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from '../constants/events';

const initialState = fromJS({
  organization: {},
  editedOrganization: {},
  GLNmodal: {
    isOpen: false,
    gln: '',
  },
});

export default createReducer(initialState, {
  [events.ORGANIZATION_RECEIVED]: (state, { organization }) =>
    initialState.set(
      'organization',
      organization.filter(
        (value, key) =>
          !![
            'id',
            'nameLegal',
            'type',
            'status',
            'network',
            'group',
            'worksOnTargetMarkets',
            'identifierLegal',
            'address',
            'identifierCity',
            'postCode',
            'supportsLocales',
            'country',
            'createdAt',
            'source',
            'ownsGLN',
            'help',
            'decommission_reason',
            'domain',
            'email_domain',
          ].find((field) => field === key),
      ),
    ),

  [events.ORGANIZATION_INFOS_ADD_TARGET_MARKET]: (state, { value }) => {
    let targetMarkets =
      state.getIn(['editedOrganization', 'worksOnTargetMarkets']) ||
      state.getIn(['organization', 'worksOnTargetMarkets']) ||
      List();

    if (
      targetMarkets.findIndex((tm) => tm.get('id') === value.get('id')) !== -1
    ) {
      return state;
    }

    targetMarkets = targetMarkets.push(value).sortBy((tm1) => tm1.get('id'));

    return state.setIn(
      ['editedOrganization', 'worksOnTargetMarkets'],
      targetMarkets,
    );
  },

  [events.ORGANIZATION_INFOS_REMOVE_TARGET_MARKET]: (state, { value }) => {
    let targetMarkets =
      state.getIn(['editedOrganization', 'worksOnTargetMarkets']) ||
      state.getIn(['organization', 'worksOnTargetMarkets']) ||
      List();

    targetMarkets = targetMarkets.filter(
      (tm) => tm.get('id') !== value.get('id'),
    );

    return state.setIn(
      ['editedOrganization', 'worksOnTargetMarkets'],
      targetMarkets,
    );
  },

  [events.ORGANIZATION_INFOS_UPDATE_FIELD]: (state, { payload }) => {
    const { value, path } = payload;
    if (!path || !path.length) {
      return state;
    }
    if (value === state.getIn(['organization', ...path])) {
      return state.deleteIn(['editedOrganization', ...path]);
    }
    return state.setIn(['editedOrganization', ...path], value);
  },

  [events.OPEN_GLN_MODAL]: (state) => state.setIn(['GLNmodal', 'isOpen'], true),
  [events.CLOSE_GLN_MODAL]: (state) =>
    state.setIn(['GLNmodal', 'isOpen'], false),
  [events.ON_CHANGE_GLN]: (state, { value }) =>
    state.setIn(['GLNmodal', 'gln'], value),
  [events.ORGANIZATION_GLN_REMOVED]: (state, { glnID }) => {
    const index = state
      .getIn(['organization', 'ownsGLN'])
      .findIndex((gln) => gln.get('id') === glnID);
    return state.deleteIn(['organization', 'ownsGLN', index]);
  },
});
