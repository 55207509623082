import { notificationError, notificationSuccess } from 'actions/notifications';
import { authApi } from 'resources/authApi';
import { createAction } from 'utils/reducers';

import {
  CREATE_SSO,
  DELETE_SSO,
  FETCH_SSO,
  RECEIVE_SSO,
  UPDATE_SSO,
} from '../constants/events';
import { OrganizationSSOConfiguration } from '../types';

export const receiveSSO = createAction<{
  sso: OrganizationSSOConfiguration;
} | null>(RECEIVE_SSO);

export const operationError = createAction<Error>(RECEIVE_SSO);

export const loadOrganizationSSO =
  ({ organizationId }: { organizationId: number }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_SSO });
      const {
        data: { data },
      } = await authApi.get(`/auth/v3/saml/configuration/${organizationId}`);
      dispatch(receiveSSO(data));
    } catch (exception: any) {
      dispatch(operationError(exception));
      dispatch(notificationError('Could not retrieve SSO configuration'));
    }
  };

export const deleteOrganizationSSO =
  ({ organizationId }: { organizationId: number }) =>
  async (dispatch) => {
    dispatch({ type: DELETE_SSO });
    try {
      await authApi.delete(`/auth/v3/saml/configuration/${organizationId}`);
      dispatch(receiveSSO(null));
      dispatch(notificationSuccess('Configuration has been deleted'));
    } catch (exception: any) {
      dispatch(operationError(exception));
      dispatch(notificationError('Could not delete SSO configuration'));
    }
  };

export const createOrganizationSSO =
  ({
    organizationId,
    ssoConfiguration,
  }: {
    organizationId: number;
    ssoConfiguration: OrganizationSSOConfiguration;
  }) =>
  async (dispatch) => {
    dispatch({ type: CREATE_SSO });
    try {
      const {
        data: { data },
      } = await authApi.post(
        `/auth/v3/saml/configuration/${organizationId}`,
        ssoConfiguration,
      );
      dispatch(receiveSSO(data));
      dispatch(notificationSuccess('Configuration has been created'));
    } catch (exception: any) {
      dispatch(operationError(exception));
      dispatch(notificationError('Could not create SSO configuration'));
    }
  };

export const updateOrganizationSSO =
  ({
    organizationId,
    ssoConfiguration,
  }: {
    organizationId: number;
    ssoConfiguration: OrganizationSSOConfiguration;
  }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_SSO });
    try {
      const {
        data: { data },
      } = await authApi.put(
        `/auth/v3/saml/configuration/${organizationId}`,
        ssoConfiguration,
      );
      dispatch(receiveSSO(data));
      dispatch(notificationSuccess('Configuration has been updated'));
    } catch (exception: any) {
      dispatch(operationError(exception));
      dispatch(notificationError('Could not update SSO configuration'));
    }
  };
