import qs from 'querystringify';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';

import { indexerDashboardAccessPolicy } from 'access-policies';
import * as routes from 'constants/routes';
import { selectUserWriteAccess } from 'modules/auth/selectors';

import * as actions from '../actions';

import { IndexerQueueFilters } from './filters';
import './index.scss';
import IndexerQueueImage from './logo';
import { IndexerQueueOrganizations } from './organizations';
import { ReindexModal } from './reindexer';
import { IndexerQueueStatus } from './status';

function getIntQueryParam(value?: string): number | null {
  if (value === null || value === undefined) {
    return null;
  }
  return value.length > 0 ? parseInt(value, 10) : null;
}

function getBoolQueryParam(value?: string): boolean {
  return value === 'true';
}

function useQueryParams() {
  const { search } = useLocation();
  const { org, openModal } = qs.parse(search) as any;
  return useMemo(
    () => ({
      org: getIntQueryParam(org),
      openModal: getBoolQueryParam(openModal),
    }),
    [search],
  );
}

export default function IndexerQueue() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const [reindexModal, openReindexModal] = useState<boolean>(params.openModal);
  const userHasReindexing = useSelector(
    selectUserWriteAccess(indexerDashboardAccessPolicy),
  );

  useEffect(() => {
    dispatch(actions.init());
    if (params.org !== null) {
      dispatch(actions.fetchOrganization(params.org));
    }
  }, [dispatch]);

  const headerProps = {
    title: <IndexerQueueImage />,
    backHref: routes.home,
    backMessage: 'Back home',
    isTitleSmall: true,
  };

  return (
    <div>
      <HeaderLayout {...headerProps}>
        <Button
          onClick={() => openReindexModal(true)}
          disabled={!userHasReindexing}
          className="IndexerQueue__reindexBtn"
        >
          <i className="mdi mdi-plus" />
          Reindex Organization
        </Button>
      </HeaderLayout>
      <div className="IndexerQueue">
        <div className="IndexerQueue__filters">
          <IndexerQueueFilters />
        </div>
        <div className="IndexerQueue__content">
          <div className="IndexerQueue__status">
            <IndexerQueueStatus />
          </div>
          <IndexerQueueOrganizations />
        </div>
      </div>
      {reindexModal && (
        <ReindexModal closeModal={() => openReindexModal(false)} />
      )}
    </div>
  );
}
