import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Ellitips } from '@alkem/react-ui-ellitips';
import { ListColumn as Column, ListRow as Row } from '@alkem/react-ui-list';

import { separateActions } from 'utils/redux';

import { updateBrandClaimStatus } from '../../actions';
import {
  STATUS_ACCEPTED,
  STATUS_CREATED,
  STATUS_MAPPING,
  STATUS_REFUSED,
  TYPE_ATTRIBUTION,
  TYPE_MAPPING,
} from '../../constants';
import { selectActionInProgress } from '../../selectors';

import './row.scss';

const mapStateToProps = (state) => ({
  actionInProgress: selectActionInProgress(state),
});

const mapDispatchToProps = {
  updateBrandClaimStatus,
};

export class BrandClaimRow extends Component {
  static propTypes = {
    isFirst: PropTypes.bool,
    brandClaim: ImmutablePropTypes.map,
    actionInProgress: PropTypes.bool,
    actions: PropTypes.shape({
      updateBrandClaimStatus: PropTypes.func.isRequired,
    }).isRequired,
  };

  onAcceptClaim = () => {
    this.props.actions.updateBrandClaimStatus(
      this.props.brandClaim.get('id'),
      STATUS_ACCEPTED.id,
    );
  };

  onRefuseClaim = () => {
    this.props.actions.updateBrandClaimStatus(
      this.props.brandClaim.get('id'),
      STATUS_REFUSED.id,
    );
  };

  formatDate(date) {
    if (!date) {
      return '';
    }
    return moment.utc(date).local().format('L LT');
  }

  renderType() {
    const { brandClaim } = this.props;
    const type = TYPE_MAPPING[brandClaim.get('type')];
    return <span>{type ? type.label : '?'}</span>;
  }

  renderStatus() {
    const { brandClaim } = this.props;
    const status = STATUS_MAPPING[brandClaim.get('status')];
    return <span>{status ? status.label : '?'}</span>;
  }

  renderActions() {
    const { isFirst, brandClaim, actionInProgress } = this.props;
    const buttons = [
      <Button
        primary
        key="accept"
        className="BrandClaimRow__buttonAccept"
        disabled={actionInProgress}
        content="Accept"
        onClick={this.onAcceptClaim}
      />,
      <Button
        secondary
        key="refuse"
        className="BrandClaimRow__buttonRefuse"
        disabled={actionInProgress}
        content="Refuse"
        onClick={this.onRefuseClaim}
      />,
    ];
    return (
      <Column className="BrandClaimRow__actions">
        {isFirst && this.renderHeader('Actions')}
        {brandClaim.get('status') === STATUS_CREATED.id &&
          brandClaim.get('type') === TYPE_ATTRIBUTION.id &&
          buttons}
      </Column>
    );
  }

  render() {
    const { brandClaim } = this.props;

    return (
      <Row className="BrandClaimRow">
        <Column className="BrandClaimRow__brand">
          <Ellitips
            id={`brandname-${brandClaim.get('id')}`}
            label={brandClaim.get('value')}
          />
        </Column>
        <Column className="BrandClaimRow__username">
          <Ellitips
            id={`userrname-${brandClaim.get('id')}`}
            label={brandClaim.getIn(['user', 'username'])}
          />
        </Column>
        <Column className="BrandClaimRow__type">{this.renderType()}</Column>
        <Column className="BrandClaimRow__status">{this.renderStatus()}</Column>
        <Column className="BrandClaimRow__createdAt">
          {this.formatDate(brandClaim.get('createdAt'))}
        </Column>
        <Column className="BrandClaimRow__updatedAt">
          {this.formatDate(brandClaim.get('updatedAt'))}
        </Column>
        {this.renderActions()}
      </Row>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(BrandClaimRow);
