import { List, Map } from 'immutable';
import { ReactFragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';

import { FieldAutocomplete } from 'components/autocomplete';
import { SUPPLIER } from 'constants/fields';
import {
  fetchFieldsWithValidationRules,
  fetchSupplierReferentials,
} from 'modules/organization-uses-fields/actions';
import {
  selectFields,
  selectFieldsAreLoaded,
  selectSupplierReferentials,
} from 'modules/organization-uses-fields/selectors';
import onboardingApi from 'resources/onboardingApi';

const supplierFieldsSelector = (state) =>
  selectFields(state, { fieldEntity: SUPPLIER }); // reselect kind of selector can't be used with react-redux hook directly
const fieldsAreLoadedSelector = (state) =>
  selectFieldsAreLoaded(state, { fieldEntity: SUPPLIER });

interface Props {
  readOnly: boolean;
  editedOrganization: Map<string, any>;
}

const SupplierReferential = (props: Props) => {
  const { readOnly, editedOrganization } = props;
  const orgId = editedOrganization.get('id');
  const orgName = editedOrganization.get('nameLegal');

  const dispatch = useDispatch();
  const fieldsAreLoaded = useSelector(fieldsAreLoadedSelector) as boolean;
  const supplierFields: Map<string, any> = useSelector(supplierFieldsSelector);

  const supplierReferentialFieldnames: List<string> | 'fetching' = useSelector(
    selectSupplierReferentials,
  );

  useEffect(() => {
    fetchSupplierReferentials(dispatch)(orgId);
    fetchFieldsWithValidationRules(orgId, SUPPLIER, true)(dispatch);
  }, [orgId, dispatch]);

  const [upsertInProgress, setUpsertInProgress] = useState(false);

  const spinner = useMemo(
    () => (
      <div className="OrganizationUsesFieldsView__Spinner">
        <Spinner center />
      </div>
    ),
    [],
  );

  if (!fieldsAreLoaded || supplierReferentialFieldnames === 'fetching') {
    return spinner;
  }

  const upsertReferential = async (
    params: Parameters<typeof onboardingApi.upsertReferential>[0],
  ) => {
    setUpsertInProgress(true);
    try {
      await onboardingApi.upsertReferential(params);
    } finally {
      setUpsertInProgress(false);
    }
    fetchSupplierReferentials(dispatch)(orgId);
  };

  const handleFieldSelected = (selection: { value: Map<string, any> }) => {
    upsertReferential({
      retailerId: orgId,
      name: selection.value.get('name'),
      active: true,
    });
  };

  const handleFieldRemoved = (name: string) => {
    upsertReferential({ retailerId: orgId, name, active: false });
  };

  const supplierReferentialFields = supplierReferentialFieldnames
    .map((name) => name && supplierFields.get(name))
    .filter((f) => !!f); // keeping only referentials pointing to to known fields

  let autocompleteElt;
  if (!readOnly) {
    autocompleteElt = (
      <div className="OrganizationUsesFieldsView__fieldAutocomplete">
        <FieldAutocomplete
          id="field-autocomplete"
          value={[]}
          onSelect={handleFieldSelected}
          entityType={SUPPLIER}
          organizationId={orgId}
          searchOnClick
          excludeList={supplierReferentialFields.toJS()}
          disabled={upsertInProgress}
        />
      </div>
    );
  }

  const supplierRefElements: ReactFragment = (function () {
    if (upsertInProgress) {
      return spinner;
    } else if (supplierReferentialFields.size) {
      return supplierReferentialFields.map((f) => (
        <Field
          key={f.get('name')}
          readOnly={readOnly || upsertInProgress}
          field={f}
          onRemove={handleFieldRemoved}
        />
      ));
    } else {
      return (
        <div className="OrganizationUsesFieldsView__nofields">None yet</div>
      );
    }
  })();

  return (
    <div className="OrganizationUsesFieldsView__fields">
      <div className="OrganizationUsesFieldsView__header">
        <h3>{`${orgName} referential fields are :`}</h3>
      </div>
      {autocompleteElt}
      {supplierRefElements}
    </div>
  );
};

interface FieldProps {
  readOnly: boolean;
  field: Map<string, any>;
  onRemove: (name: string) => void;
}

function Field({ readOnly, field, onRemove }: FieldProps) {
  const name = field.get('name');
  return (
    <div className="OrganizationUsesFieldsView__field" data-field-name={name}>
      <code className="OrganizationUsesFieldsView__fieldName">{name}</code>
      {!readOnly && (
        <span
          className="OrganizationUsesFieldsView__delete mdi mdi-delete"
          onClick={() => onRemove(name)}
        />
      )}
    </div>
  );
}

export default SupplierReferential;
