import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';

import Dropdown from 'components/dropdown';
import { PayloadModal } from 'components/payload-modal';
import { getIn } from 'utils/immutable';

import './node.scss';

class NodeInformation extends PureComponent {
  static propTypes = {
    node: PropTypes.object.isRequired,
    runResults: PropTypes.object,
    edges: PropTypes.array,
    actions: PropTypes.array,
  };

  static defaultProps = {
    runResults: {},
    edges: [],
    actions: [],
  };

  state = {
    modalValue: '',
  };

  render() {
    const { node, runResults, edges, actions } = this.props;
    const { modalValue } = this.state;

    const took = getIn(runResults, [
      'inspectors',
      'trace',
      node.id,
      'duration',
    ]);

    const prismValue = getIn(runResults, ['inspectors', 'prism', node.id]);

    const conditions = edges
      .filter((e) => e.targetId === node.id)
      .filter((e) => e.inputVar === '__condition__');

    const offset = 0;

    let nodeFilename = node.filename;
    if (node.filename) {
      const idx = node.filename.indexOf('service-product/');
      if (idx >= 0) {
        nodeFilename = node.filename.slice(idx + 'service-product/'.length);
      }
    }

    return (
      <div className="ProductNode">
        <div>
          <div className="flex flex-align-items--center flex-justify-content--space-between">
            <h3 id={node.id}>
              {node.id}{' '}
              <code>
                {node.type !== 'cluster' && node.pkg && `${node.pkg}.`}
                {node.type}
              </code>
            </h3>
            {actions.length > 0 && (
              <Dropdown
                label={<i className="mdi mdi-dots-horizontal" />}
                options={actions}
                selectKey={(opt) => opt.label} // eslint-disable-line react/jsx-no-bind
                selectLabel={(opt) => opt.label} // eslint-disable-line react/jsx-no-bind
                selectOptions={() => null} // eslint-disable-line react/jsx-no-bind
                selectOnClick={(opt) => opt.onClick} // eslint-disable-line react/jsx-no-bind
                rightDropdown
              />
            )}
          </div>
          {nodeFilename && (
            <div className="ProductNode__githubLink">
              <a
                className="flex flex-align-items--center"
                href={`https://github.com/alkemics/service-product/blob/staging/${nodeFilename}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="mdi mdi-github-circle" />
                {nodeFilename}
              </a>
            </div>
          )}
          <div className="ProductNode__title">
            {took && <span>(Took: {took})</span>}
            {prismValue && (
              <Button
                content="View prism calls"
                secondary
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => this.setState({ modalValue: prismValue })}
              />
            )}
          </div>
        </div>
        {node.id && (
          <div>
            <div className="ProductNode__section">
              <pre>
                {node.doc || (
                  <em className="text-mutedf">No documentation provided</em>
                )}
              </pre>
            </div>
            {conditions && conditions.length > 0 && (
              <div className="ProductNode__section">
                <h4>
                  <strong>Conditions:</strong>
                </h4>
                <ul>
                  {conditions.map((condition) => {
                    const id = `${condition.sourceId}.${condition.outputVar}`;
                    return (
                      <li key={id}>
                        <div className="ProductNode__io">
                          <div className="flex flex-align-items--center">
                            <code>{id}</code>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="ProductNode__section">
              <h4>
                <strong>Inputs:</strong>
              </h4>
              <ul>
                {(node.inputs || []).map((input, idx) => {
                  const val =
                    (offset === 0 || idx > 0) && // offset > 0 means that the first input is the context
                    runResults &&
                    runResults.inspectors &&
                    runResults.inspectors.spy &&
                    runResults.inspectors.spy[node.id] &&
                    runResults.inspectors.spy[node.id].inputs.length >
                      idx - offset
                      ? runResults.inspectors.spy[node.id].inputs[idx - offset]
                      : undefined;
                  const validValue = typeof val !== 'undefined';

                  let tooLong = false;
                  if (validValue && JSON.stringify(val).length > 30) {
                    tooLong = true;
                  }

                  const inputEdges = edges.filter(
                    (e) => e.targetId === node.id && e.inputVar === input.name,
                  );

                  return (
                    <li key={input.name}>
                      <div className="ProductNode__io ellipsis">
                        <div
                          className="flex flex-align-items--center"
                          data-tip
                          data-for={`input-edges-${node.id}-${input.name}`}
                        >
                          <code>{input.name}</code>:{' '}
                          <code className="code-no-bg">{input.type}</code>
                        </div>
                        {validValue && (
                          <span className="ProductNode__io__result">
                            <i className="mdi mdi-arrow-right" />
                            <code className="code-no-bg code-ellipsis">
                              {JSON.stringify(val)}
                            </code>
                            {tooLong && (
                              <Button
                                content="View all"
                                link
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() =>
                                  this.setState({ modalValue: val })
                                }
                              />
                            )}
                          </span>
                        )}
                      </div>
                      {inputEdges.length > 0 && (
                        <Tooltip
                          id={`input-edges-${node.id}-${input.name}`}
                          place="bottom"
                        >
                          <small>
                            <ul className="ProductNode__tooltipList">
                              {inputEdges.map((e) => (
                                <li
                                  className="flex flex-align-items--center"
                                  key={`${e.sourceId}-${e.outputVar}`}
                                >
                                  {e.sourceId}.{e.outputVar}
                                </li>
                              ))}
                            </ul>
                          </small>
                        </Tooltip>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="ProductNode__section">
              <h4>
                <strong>Outputs:</strong>
              </h4>
              <ul>
                {(node.outputs || []).map((output, idx) => {
                  const val =
                    runResults &&
                    runResults.inspectors &&
                    runResults.inspectors.spy &&
                    runResults.inspectors.spy[node.id] &&
                    runResults.inspectors.spy[node.id].outputs.length > idx
                      ? runResults.inspectors.spy[node.id].outputs[idx]
                      : undefined;
                  const validValue = typeof val !== 'undefined';

                  let tooLong = false;
                  if (validValue && JSON.stringify(val).length > 30) {
                    tooLong = true;
                  }

                  const outputEdges = edges.filter(
                    (e) =>
                      e.sourceId === node.id && e.outputVar === output.name,
                  );

                  return (
                    <li
                      key={output.name || `output-${idx}`}
                      className="ProductNode__io"
                    >
                      <div
                        className="flex flex-align-items--center"
                        data-tip
                        data-for={`output-edges-${node.id}-${output.name}`}
                      >
                        <code>{output.name}</code>:{' '}
                        <code className="code-no-bg">{output.type}</code>
                      </div>
                      {validValue && (
                        <span className="ProductNode__io__result">
                          <i className="mdi mdi-arrow-right" />
                          <code className="code-no-bg code-ellipsis">
                            {JSON.stringify(val)}
                          </code>
                          {tooLong && (
                            <Button
                              content="View all"
                              link
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => this.setState({ modalValue: val })}
                            />
                          )}
                        </span>
                      )}
                      {outputEdges.length > 0 && (
                        <Tooltip
                          id={`output-edges-${node.id}-${output.name}`}
                          place="bottom"
                        >
                          <small>
                            <ul className="ProductNode__tooltipList">
                              {outputEdges.map((e) => (
                                <li
                                  className="flex flex-align-items--center"
                                  key={`${e.targetId}-${e.inputVar}`}
                                >
                                  {e.targetId}.{e.inputVar}
                                </li>
                              ))}
                            </ul>
                          </small>
                        </Tooltip>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        {modalValue && (
          <PayloadModal
            payload={modalValue}
            // eslint-disable-next-line react/jsx-no-bind
            onClose={() => this.setState({ modalValue: '' })}
          />
        )}
      </div>
    );
  }
}

NodeInformation.propTypes = {
  node: PropTypes.object.isRequired,
};

export default NodeInformation;
