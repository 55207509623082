import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { INFOS_REDUCER_KEY, ORGANIZATION_PAGE_STORE_KEY } from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_PAGE_STORE_KEY][
    INFOS_REDUCER_KEY
  ];
}

export function selectOrganization(state) {
  return selectState(state).get('organization');
}

export function selectOrganizationGLN(state) {
  return selectOrganization(state).get('ownsGLN');
}

export function selectIsGLNModalOpen(state) {
  return selectState(state).getIn(['GLNmodal', 'isOpen']);
}

export function selectGLNModalGLN(state) {
  return selectState(state).getIn(['GLNmodal', 'gln']);
}
