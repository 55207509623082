import { List, Map } from 'immutable';
import { debounce, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HeaderLayout } from '@alkem/react-layout';
import { Modal } from '@alkem/react-ui-modal';
import { TwinPanel } from '@alkem/react-ui-twinpanel';

import { fieldDashboardAccessPolicy } from 'access-policies';
import { notificationError } from 'actions/notifications';
import {
  PRODUCT_DOCUMENT,
  PRODUCT_PICTURE,
  PRODUCT_VIDEO,
} from 'constants/fields';
import * as routes from 'constants/routes';
import { selectUserReadOnlyAccess } from 'modules/auth/selectors';
import { get } from 'utils/immutable';
import { hasFullPower } from 'utils/user';

import * as events from '../../events';
import * as selectors from '../../selectors';
import FieldBulkEdit from '../bulk-edit';
import FieldForm from '../field-form';
import FieldHeader from '../field-header';
import FieldTree from '../tree';
import FieldTreeHeader from '../tree-header';

import './fields-page.scss';
import { dangerousFieldsUpdated, updateNeedsConfirmation } from './utils';

const mapStateToProps = createStructuredSelector({
  roots: selectors.selectRoots,
  fields: selectors.selectFields,
  tags: selectors.selectTags,
  fieldModified: selectors.selectFieldModified,
  sourceField: selectors.selectSourceField,
  editedField: selectors.selectEditedField,
  saveInProgress: selectors.selectSaveInProgress,
  deleteInProgress: selectors.selectDeleteInProgress,
  shouldBlockSaveField: selectors.selectShouldBlockSaveField,
  readOnly: selectUserReadOnlyAccess(fieldDashboardAccessPolicy),
  canDelete: (state) => hasFullPower(state.user),
});

export class FieldsPage extends PureComponent {
  static propTypes = {
    roots: PropTypes.object,
    fields: PropTypes.object,
    tags: PropTypes.array,
    fieldModified: PropTypes.bool.isRequired,
    sourceField: PropTypes.object,
    editedField: PropTypes.object,
    saveInProgress: PropTypes.bool.isRequired,
    deleteInProgress: PropTypes.bool.isRequired,
    shouldBlockSaveField: PropTypes.bool,
    dispatch: PropTypes.func,
    readOnly: PropTypes.bool,
    canDelete: PropTypes.bool,
  };

  state = {
    currentRoot: null,
    search: '',
    bulkEditing: false,
    confirmationModalOpen: false,
    shouldBlockSaveField: false,
  };

  componentDidMount() {
    this.props.dispatch({ type: events.GET_ROOTS_LIST });
    this.props.dispatch({ type: events.GET_TAGS });
  }

  componentDidUpdate(prevProps, prevState) {
    const { roots, saveInProgress } = this.props;
    if (roots !== prevProps.roots) {
      const currentRoot = roots.first();
      if (currentRoot) {
        this.props.dispatch({
          type: events.GET_FIELDS_LIST,
          entityType: currentRoot.get('label'),
          search: this.state.search,
        });
        this.setState({ currentRoot: currentRoot.toJS() }); // eslint-disable-line
      } else {
        this.setState({ currentRoot: null }); // eslint-disable-line
      }
    }
    // Close the bulk edit modal if necessary.
    if (!saveInProgress && prevProps.saveInProgress && prevState.bulkEditing) {
      this.setState({ bulkEditing: false }); // eslint-disable-line
    }
  }

  onStartBulkEdit = () => {
    const { fieldModified } = this.props;
    if (fieldModified) {
      this.props.dispatch(
        notificationError('A field is being edited, save or discard first'),
      );
    } else {
      this.setState({ bulkEditing: true });
    }
  };

  onCancelBulkEdit = () => {
    this.setState({ bulkEditing: false });
  };

  onApplyBulkEdit = (fields) => {
    this.props.dispatch({
      type: events.SAVE_BULK_EDIT,
      fields,
    });
  };

  onCloseConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false });
  };

  onValidateConfirmationModal = () => {
    this.onSaveFieldForRealThisTime();
    this.onCloseConfirmationModal();
  };

  onRefreshCache = () => {
    this.props.dispatch({ type: events.REFRESH_CACHE });
  };

  onSelectRoot = (root) => {
    this.setState({ currentRoot: root });
    this.props.dispatch({ type: events.RECEIVE_FIELDS_LIST, fields: List() });
    this.props.dispatch({
      type: events.GET_FIELDS_LIST,
      entityType: root.label,
      search: this.state.search,
    });
  };

  onCreateRoot = (name) => {
    this.props.dispatch({ type: events.CREATE_ROOT, name });
  };

  onUpdateSearch = (search) => {
    this.setState({ search });
    this.searchDebounced();
  };

  searchDebounced = debounce(() => {
    const { currentRoot, search } = this.state;
    this.props.dispatch({
      type: events.GET_FIELDS_LIST,
      entityType: currentRoot?.label,
      search: search,
    });
  }, 400);

  onSelectField = (id) => {
    const { fieldModified } = this.props;
    if (fieldModified) {
      this.props.dispatch(
        notificationError('A field is being edited, save or discard first'),
      );
    } else {
      this.props.dispatch({ type: events.GET_FIELD, id });
    }
  };

  onCreateField = () => {
    const { fieldModified } = this.props;
    const { currentRoot } = this.state;
    if (fieldModified) {
      this.props.dispatch(
        notificationError('A field is being edited, save or discard first'),
      );
    } else {
      this.props.dispatch({
        type: events.CREATE_FIELD,
        parentId: currentRoot.id,
      });
    }
  };

  onUpdateField = (path, value) => {
    this.props.dispatch({ type: events.UPDATE_FIELD, path, value });
  };

  onSaveField = () => {
    const { sourceField, editedField } = this.props;

    if (updateNeedsConfirmation(sourceField, editedField)) {
      this.setState({ confirmationModalOpen: true });
    } else {
      this.onSaveFieldForRealThisTime();
    }
  };

  onSaveFieldForRealThisTime = () => {
    const { editedField } = this.props;
    const { currentRoot } = this.state;

    this.props.dispatch({
      type: events.SAVE_FIELD,
      field: editedField,
      entityType: currentRoot.label,
    });
  };

  onDiscardChanges = () => {
    this.props.dispatch({ type: events.DISCARD_CHANGES });
  };

  onDeleteField = () => {
    const { editedField } = this.props;
    const { currentRoot } = this.state;
    this.props.dispatch({
      type: events.DELETE_FIELD,
      id: editedField.get('id'),
      entityType: currentRoot.label,
    });
  };

  renderTreeHeader() {
    const { roots, readOnly } = this.props;
    const { currentRoot, search } = this.state;
    if (!roots) {
      return null;
    }
    return (
      currentRoot && (
        <FieldTreeHeader
          roots={roots}
          currentRoot={currentRoot}
          selectRoot={this.onSelectRoot}
          createRoot={this.onCreateRoot}
          createField={this.onCreateField}
          canCreate
          refreshCache={this.onRefreshCache}
          startBulkEdit={this.onStartBulkEdit}
          search={search}
          updateSearch={this.onUpdateSearch}
          readOnly={readOnly}
        />
      )
    );
  }

  renderTree() {
    const { fields } = this.props;
    if (!fields) {
      return null;
    }
    return <FieldTree fields={fields} selectField={this.onSelectField} />;
  }

  renderFieldHeader() {
    const {
      editedField,
      fieldModified,
      saveInProgress,
      deleteInProgress,
      shouldBlockSaveField,
      readOnly,
      canDelete,
    } = this.props;
    if (!editedField) {
      return 'Select or create a new field';
    }
    return (
      <FieldHeader
        id={editedField.get('id')}
        name={editedField.get('name')}
        label={editedField.getIn(['displayInfo', 'label'])}
        modified={fieldModified}
        shouldBlockSaveField={shouldBlockSaveField}
        saveInProgress={saveInProgress}
        deleteInProgress={deleteInProgress}
        save={this.onSaveField}
        discard={this.onDiscardChanges}
        delete={this.onDeleteField}
        readOnly={readOnly}
        canDelete={canDelete}
      />
    );
  }

  renderFieldForm() {
    const { editedField, tags, readOnly } = this.props;
    const { currentRoot } = this.state;
    if (!editedField) {
      return null;
    }
    return (
      <FieldForm
        field={editedField}
        path={[]}
        update={this.onUpdateField}
        tags={tags}
        isRoot={!(editedField.get('level') > 1)}
        isCreation={isUndefined(editedField.get('id'))}
        isConsumerUnitField={[
          'consumerUnit',
          'specificFields',
          PRODUCT_PICTURE,
          PRODUCT_DOCUMENT,
          PRODUCT_VIDEO,
        ].includes(get(currentRoot, 'label'))}
        readOnly={readOnly}
      />
    );
  }

  renderBulkEdit() {
    const { fields, tags, saveInProgress } = this.props;
    const { bulkEditing, currentRoot } = this.state;
    if (!bulkEditing || !fields || !currentRoot) {
      return null;
    }
    return (
      <FieldBulkEdit
        fields={fields}
        tags={tags}
        isConsumerUnitField={[
          'consumerUnit',
          'specificFields',
          PRODUCT_PICTURE,
          PRODUCT_DOCUMENT,
          PRODUCT_VIDEO,
        ].includes(get(currentRoot, 'label'))}
        saveInProgress={saveInProgress}
        stop={this.onCancelBulkEdit}
        save={this.onApplyBulkEdit}
      />
    );
  }

  renderConfirmationModal() {
    const { sourceField, editedField } = this.props;
    return (
      <Modal
        danger
        modalStyle="dynamic"
        title="Bulk edit fields"
        confirmButtonText="Save"
        onConfirm={this.onValidateConfirmationModal}
        onClose={this.onCloseConfirmationModal}
      >
        <div>
          <strong>Some of the fields you modified are dangerous.</strong>
        </div>
        <ul>
          {dangerousFieldsUpdated(
            sourceField || Map(),
            editedField || Map(),
          ).map((field) => (
            <li key={field}>
              <strong>{field}</strong>
            </li>
          ))}
        </ul>
        <div>Do you really want to save those changes?</div>
      </Modal>
    );
  }

  render() {
    const { readOnly } = this.props;
    const { confirmationModalOpen } = this.state;
    return (
      <div className="FieldsPage__body">
        <HeaderLayout
          title="Fields management dashboard"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        />
        <div className="FieldsPage__content">
          <TwinPanel
            leftPanel={{
              title: this.renderTreeHeader(),
              content: this.renderTree(),
              className: 'FieldsPage__panel--left',
            }}
            rightPanel={{
              title: this.renderFieldHeader(),
              content: this.renderFieldForm(),
              className: 'FieldsPage__panel--right',
            }}
          />
        </div>
        {!readOnly && (
          <>
            {this.renderBulkEdit()}
            {confirmationModalOpen && this.renderConfirmationModal()}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(FieldsPage);
