import {
  RegularOptionWithStringId,
  RegularOptionsWithStringId,
} from '../../../types';

export const itemTypeOptions: RegularOptionsWithStringId = [
  { id: 'field', label: 'SXM field' },
  { id: 'value', label: 'Hard coded value' },
  { id: 'custom_parse', label: 'Custom parse' },
];

export const dataTypeOptions: RegularOptionsWithStringId = [
  { id: 'int', label: 'integer' },
  { id: 'int positive', label: 'positive integer' },
  { id: 'float', label: 'float' },
  { id: 'float positive', label: 'positive float' },
  { id: 'string', label: 'string (3000 char.)' },
  { id: 'text', label: 'text (20000 char.)' },
  { id: 'boolean', label: 'boolean' },
  { id: 'entity', label: 'entity' },
  { id: 'date', label: 'date' },
  { id: 'iso_date', label: 'iso date' },
];

export const valueCastOptions: RegularOptionsWithStringId = [
  { id: 'value', label: "A value I'm going to write" },
  { id: 'lambda', label: 'A lambda function' },
  { id: 'function', label: 'A function name' },
];

export const checkFloat = (value: string, isOnlyPositive = false): boolean => {
  if ((isOnlyPositive && value[0] === '-') || value === '-.') return false;
  if (
    (!isOnlyPositive && value === '-') ||
    (value !== '.' && value[value.length - 1] === '.')
  ) {
    return true;
  }
  const regex = new RegExp(
    isOnlyPositive ? /^\d*\.?\d+$/ : /^-?\d*\.{0,1}\d+$/,
  );
  return regex.test(value);
};

export const checkInt = (value: string, isOnlyPositive = false): boolean => {
  if (isOnlyPositive && value[0] === '-') return false;
  if (!isOnlyPositive && value === '-') {
    return true;
  }
  const regex = new RegExp(isOnlyPositive ? /^\d+$/ : /^-?\d+$/);
  return regex.test(value);
};

export const getData = (
  data: string,
  dataType: RegularOptionWithStringId,
  setValueData: (string) => void,
) => {
  let isValueDataSet = false;
  let updatedData: string | number | undefined = data;
  if (['string', 'text'].includes(dataType.id) || updatedData === '') {
    updatedData = updatedData || undefined;
  } else {
    let isValid = false;
    while (!isValid) {
      if (dataType.id.includes('float')) {
        isValid = checkFloat(updatedData, dataType.id.includes('positive'));
      } else {
        isValid = checkInt(updatedData, dataType.id !== 'int');
      }
      if (!isValid) {
        updatedData = updatedData.slice(0, -1);
      }
      if (updatedData === '') break;
    }
    if (updatedData === '') {
      updatedData = undefined;
    } else if (updatedData === '-') {
      setValueData(updatedData);
      isValueDataSet = true;
      updatedData = undefined;
    } else {
      if (updatedData[updatedData.length - 1] === '.') {
        setValueData(updatedData);
        isValueDataSet = true;
        updatedData = updatedData.slice(0, -1);
      }
      updatedData = dataType.id.includes('float')
        ? parseFloat(updatedData)
        : parseInt(updatedData);
      updatedData = isNaN(Number(updatedData))
        ? undefined
        : Number(updatedData);
    }
  }
  return { data: updatedData, isValueDataSet };
};
