import copy from 'copy-to-clipboard';
import { SyntheticEvent } from 'react';
import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';

import { notificationSuccess } from 'actions/notifications';

import { ReadOnlyRule, Rule, RuleSelector, RuleSet } from './types';

export const stopPropagation = (e: SyntheticEvent<EventTarget>) => {
  e.stopPropagation();
};

export const getRuleSetLabelForFilter = (ruleSet: RuleSet) => {
  return `${ruleSet.id}: ${ruleSet.label} (${
    ruleSet.type ? ruleSet.type : 'untyped'
  })`;
};

export const formatRuleSetIdInFilter = (ruleSet: RuleSet) => {
  return {
    path: 'ruleSetIdIn',
    value: {
      key: ruleSet.id,
      value: ruleSet,
      label: getRuleSetLabelForFilter(ruleSet),
    },
  };
};

export const copyEntryValue = (value: any | undefined, dispatch: Dispatch) => {
  if (value) {
    copy(typeof value === 'string' ? value.trim() : JSON.stringify(value));
    dispatch(notificationSuccess('Copied!'));
  }
};

export const dictToRuleSelectorList = (
  dict: { [key: string]: string },
  usedToDisplayErrors?: boolean,
): RuleSelector[] => {
  return Object.entries(dict).map(([key, value]) => {
    const result: RuleSelector = { id: uuid(), key, value };

    if (usedToDisplayErrors !== undefined) {
      result.usedToDisplayErrors = usedToDisplayErrors;
    }
    return result;
  });
};

export const readOnlyRuleToRule = (readOnlyRule: ReadOnlyRule): Rule => {
  const constants = dictToRuleSelectorList(readOnlyRule.constants || {});

  const selectors = dictToRuleSelectorList(readOnlyRule.selectors || {}, true);
  const conditionSelectors = dictToRuleSelectorList(
    readOnlyRule.conditionSelectors || {},
    false,
  );

  return {
    ...readOnlyRule,
    constantList: constants,
    selectorList: [...selectors, ...conditionSelectors],
  };
};
