import { MODULE_REDUCER_KEY } from 'constants/reducer';

import {
  USER_LIST_STORE_KEY,
  USER_LIST_USER_STORE_KEY,
} from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][USER_LIST_STORE_KEY][
    USER_LIST_USER_STORE_KEY
  ];
}

export function selectSearch(state) {
  return selectState(state).search;
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectEntities(state) {
  return selectState(state).entities;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export function selectTotalResults(state) {
  return selectPagination(state).totalResults;
}

export function selectLimit(state) {
  return selectPagination(state).limit;
}

export function selectCurrentPage(state) {
  return selectPagination(state).currentPage;
}

export function selectFilters(state) {
  return selectState(state).filters;
}

export function selectOrganizationFilters(state) {
  return selectFilters(state).get('organizations');
}

export function selectSelectedFilters(state) {
  return selectFilters(state).get('selected');
}

export function selectAdminFilters(state) {
  return selectFilters(state).get('admin');
}

export function selectAreUsersLoading(state) {
  return selectState(state).isLoading;
}

export function selectUser(state) {
  return selectState(state).user;
}
