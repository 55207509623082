import classNames from 'classnames';
import Immutable from 'immutable';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import type { AnyAction } from 'redux';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { Tooltip } from '@alkem/react-ui-tooltip';

import Dropdown from 'components/dropdown';
import StatusBar from 'components/ui/status-bar';
import * as routes from 'constants/routes';
import {
  applyTransaction,
  onShowDiff,
  onShowPayload,
  reindex,
  restoreHierarchy,
} from 'modules/transaction/actions';
import { ROLES, STATUSES } from 'modules/transaction/constants';
import { selectState } from 'modules/transaction/reducer';
import { getGTIN, getProductLabel } from 'modules/transaction/utils';
import { TIME_FORMAT_MINUTE, formatTime } from 'utils/time';

import './row.scss';

interface Props {
  transaction: Immutable.Map<string, any>;
}

export function TransactionRow({ transaction }: Props) {
  const dispatch: (action: AnyAction) => void = useDispatch();
  const organizations = useSelector((state) =>
    selectState(state).extra.get('organizations'),
  );
  const products = useSelector((state) =>
    selectState(state).extra.get('products'),
  );
  const productKeys = useSelector((state) =>
    selectState(state).extra.get('productKeys'),
  );
  const [page, setPage] = useState(0);

  const actions = useMemo(() => {
    const as = [
      {
        label: 'Reindex',
        onClick: () => dispatch(reindex(transaction.get('id'))),
      },
    ];

    if (transaction.get('status') === 4) {
      as.push({
        label: 'Un-discard',
        onClick: () => dispatch(applyTransaction('undo', transaction)),
      });
    } else {
      as.push({
        label: 'Discard',
        onClick: () => dispatch(applyTransaction('discard', transaction)),
      });
    }

    if (
      transaction.getIn(['metadata', 'type']) ===
        'ProductVersionLogisticalHierarchies' &&
      (!transaction.getIn(['data', 'new']) ||
        transaction.getIn(['data', 'new']).size === 0)
    ) {
      as.push({
        label: 'Restore',
        onClick: () => dispatch(restoreHierarchy(transaction)),
      });
    }

    return as;
  }, [dispatch, transaction]);

  return (
    <div id={`transaction-${transaction.get('id')}`} className="TransactionRow">
      <StatusBar type={STATUSES[transaction.get('status')].statusbar} />
      <div className="TransactionRow__metadata">
        <div>
          <div className="TransactionRow__event">
            <strong>
              <Ellitips
                id={`transaction-row-${transaction.get('id')}-event`}
                label={transaction.getIn(['metadata', 'event', 'type'])}
              />
            </strong>
          </div>
          <div className="TransactionRow__event">
            <strong>
              <Ellitips
                id={`transaction-row-${transaction.get('id')}-event-action`}
                label={transaction.getIn(['metadata', 'event', 'action'])}
              />
            </strong>
          </div>
          <div className="TransactionRow__metadataType">
            {transaction.getIn(['metadata', 'type'])}
          </div>
        </div>
        <div>
          <strong>
            <i className="mdi mdi-calendar" />
            {formatTime(transaction.get('createdAt', TIME_FORMAT_MINUTE))}
          </strong>
          {` - ${transaction.get('id')}`}
        </div>
      </div>
      {page === 0 && (
        <div className="TransactionRow__entities TransactionRow__organizations">
          <TransactionRowOrganization
            organizations={organizations}
            productKeys={productKeys}
            transaction={transaction}
          />
        </div>
      )}
      {page === 0 && (
        <div className="TransactionRow__entities TransactionRow__products">
          <TransactionRowProduct
            products={products}
            productKeys={productKeys}
            transaction={transaction}
          />
        </div>
      )}
      {page === 1 && (
        <div className="TransactionRow__entities__raw">
          <TransactionRowEntities transaction={transaction} />
        </div>
      )}
      {page === 2 && (
        <div className="TransactionRow__statusHistory">
          <TransactionRowStatusHistory transaction={transaction} />
        </div>
      )}
      <div className="TransactionRow__displayChoice">
        {[0, 1, 2].map((i) => (
          <button
            key={i}
            type="button"
            className="btn btn-link"
            onClick={() => setPage(i)}
          >
            <i
              className={classNames({
                mdi: true,
                'mdi-checkbox-blank-circle': page === i,
                'mdi-checkbox-blank-circle-outline': page !== i,
              })}
            />
          </button>
        ))}
      </div>
      <div className="TransactionRow__actions">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            dispatch(onShowPayload(transaction));
          }}
        >
          <i className="mdi mdi-code-braces" />
        </button>
        {transaction.get('status') === 3 && (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              dispatch(
                onShowDiff(
                  transaction.get('data'),
                  transaction.getIn(['metadata', 'type']),
                ),
              );
            }}
          >
            <i className="mdi mdi-vector-difference" />
          </button>
        )}
        <Dropdown
          label={<i className="mdi mdi-dots-horizontal" />}
          theme="phantom"
          options={actions}
          selectKey={(opt) => opt.label}
          selectLabel={(opt) => opt.label}
          selectOptions={() => null}
          selectOnClick={(opt) => opt.onClick}
          rightDropdown
        />
        <Link
          className="btn btn-link"
          to={`${routes.transactionExplorer}?transaction_id=${transaction.get(
            'id',
          )}`}
        >
          <i className="mdi mdi-sitemap" />
        </Link>
      </div>
    </div>
  );
}

function TransactionRowOrganization({
  organizations,
  transaction,
  productKeys,
}) {
  const entities =
    transaction.getIn(['metadata', 'entities']) || Immutable.List();
  const orgEntities = entities.filter(
    (e) => e.get('type').toLowerCase() === 'organization',
  );

  let sourceOrganization = orgEntities.find((o) => o.get('role') === 0);
  if (!sourceOrganization) {
    const productKey = entities.find(
      (e) =>
        e.get('type').toLowerCase() === 'productkey' && e.get('role') === 0,
    );
    if (productKey) {
      sourceOrganization = Immutable.Map({
        id: productKeys.getIn([`${productKey.get('id')}`, 'organization_id']),
      });
    }
  }

  let targetOrganization = orgEntities.find((o) => o.get('role') === 1);
  if (!targetOrganization) {
    const productKey = entities.find(
      (e) =>
        e.get('type').toLowerCase() === 'productkey' && e.get('role') === 1,
    );
    if (productKey) {
      targetOrganization = Immutable.Map({
        id: productKeys.getIn([`${productKey.get('id')}`, 'organization_id']),
      });
    }
  }

  const sourceOrganizationName = sourceOrganization
    ? organizations.getIn([sourceOrganization.get('id'), 'nameLegal'])
    : undefined;
  const targetOrganizationName = targetOrganization
    ? organizations.getIn([targetOrganization.get('id'), 'nameLegal'])
    : undefined;

  return (
    <div className="TransactionRow__entityRow">
      <strong>
        Organization
        {sourceOrganizationName && targetOrganizationName && 's'}:{' '}
      </strong>
      <div className="TransactionRow__organizationNames">
        <div>
          <Ellitips
            id={`transaction-row-${transaction.get(
              'id',
            )}-org-${sourceOrganizationName}`}
            label={sourceOrganizationName}
          />
        </div>
        <div className="TransactionRow__center">
          {sourceOrganizationName && targetOrganizationName && (
            <strong>
              <i className="mdi mdi-arrow-down" />
            </strong>
          )}
        </div>
        <div>
          <Ellitips
            id={`transaction-row-${transaction.get(
              'id',
            )}-org-${targetOrganizationName}`}
            label={targetOrganizationName}
          />
        </div>
      </div>
    </div>
  );
}

function TransactionRowProduct({ transaction, products, productKeys }) {
  const entities =
    transaction.getIn(['metadata', 'entities']) || Immutable.List();
  const productIds = entities
    .filter((e) => e.get('type').toLowerCase() === 'product')
    .map((e) => e.get('id'))
    .concat(
      entities
        .filter((e) => e.get('type').toLowerCase() === 'productkey')
        .map((e) => productKeys.getIn([`${e.get('id')}`, 'product_id']))
        .filter((e) => e),
    )
    .toSet()
    .toList();

  const productNames = productIds.map((productId) => {
    const version = products.get(productId);
    if (!version) {
      return {
        id: productId,
        label: <em className="text-muted">Not found (id: {productId})</em>,
      };
    }

    const host = window.location.host.replace('admin', 'stream');
    return {
      id: productId,
      label: (
        <a
          href={`${window.location.protocol}//${host}/#/product/${getGTIN(
            version,
          )}`}
        >
          <Ellitips
            id={`transaction-row-${transaction.get('id')}-product-${productId}`}
            label={getProductLabel(version)}
          />
        </a>
      ),
    };
  });

  return (
    <div className="TransactionRow__entityRow">
      <div className="TransactionRow__entityRow__entities">
        <strong>Products: </strong>
        {productNames.map((p) => <div key={p.id}>{p.label}</div>).toJS()}
      </div>
    </div>
  );
}

function TransactionRowStatusHistory({ transaction }) {
  const id = transaction.get('id');
  const statusHistory = transaction.get('status_history');
  return (
    <div>
      <div className="row TransactionRow__statusHistory__row">
        <div className="offset-xs-1 col-xs-3">
          <strong>User id</strong>
        </div>
        <div className="col-xs-3">
          <strong>Transaction id</strong>
        </div>
        <div className="col-xs-5">
          <strong>Date</strong>
        </div>
      </div>
      {statusHistory
        .reverse()
        .map((entry, i) => (
          <div
            key={JSON.stringify(entry)}
            className="row TransactionRow__statusHistory__row"
          >
            <div
              className="col-xs-1"
              data-tip
              data-for={`status-history-${id}-reason-${i}`}
            >
              <div
                className={classNames({
                  TransactionRow__statusHistory__status: true,
                  [`TransactionRow__statusHistory__status--${
                    STATUSES[entry.get('status')].statusbar
                  }`]: true,
                })}
              />
              <Tooltip id={`status-history-${id}-reason-${i}`} place="right">
                {entry.get('reason')}
              </Tooltip>
            </div>
            <div className="col-xs-3">
              {entry.get('user_id') ? (
                <Ellitips
                  id={`transaction-row-${id}-user-id-${i}`}
                  label={entry.get('user_id').toString()}
                />
              ) : (
                <em className="text-muted">(null)</em>
              )}
            </div>
            <div className="col-xs-3">
              {entry.get('transaction_id') ? (
                <Ellitips
                  id={`transaction-row-${id}-transaction-id-${i}`}
                  label={entry.get('transaction_id').toString()}
                />
              ) : (
                <em className="text-muted">(null)</em>
              )}
            </div>
            <div className="col-xs-5">
              {formatTime(entry.get('createdAt', TIME_FORMAT_MINUTE))}
            </div>
          </div>
        ))
        .toJS()}
    </div>
  );
}

function TransactionRowEntities({ transaction }) {
  return (transaction.getIn(['metadata', 'entities']) || Immutable.List())
    .filter((e) => ROLES[e.get('role')])
    .map((entity) => (
      <div
        key={`${entity.get('type')}-${entity.get('id')}-${entity.get('role')}`}
        className="TransactionRow__entity__raw"
      >
        <strong>{ROLES[entity.get('role')].direction}: </strong>
        {entity.get('type')} {entity.get('id')}
      </div>
    ))
    .toArray();
}
