import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { Radio, Text, Textarea } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

import { SAVE_NEW_XPATH_ELEMENT } from '../../../actions/constants';
import { Xpath } from '../../../types';

import styles from './new-element-creation.module.scss';

interface Props {
  element: Xpath;
  elementOrder: number;
  closeXpathCreationView: () => void;
  isChild: boolean;
}

export function ExportMappingsNewElementCreation({
  element,
  elementOrder,
  closeXpathCreationView,
  isChild,
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const [elementName, setElementName] = useState<string | undefined>(undefined);

  const [elementType, setElementType] = useState<string>('SIMPLE');

  const saveNewElement = (): void => {
    dispatch({
      type: SAVE_NEW_XPATH_ELEMENT,
      name: elementName,
      elementType,
      order: elementOrder,
      parentId: isChild ? element.id : element.parent_id,
    });
    closeXpathCreationView();
  };

  const xpath = isChild
    ? `${element.xpath}/...`
    : element?.xpath.split('/').slice(0, -1).join('/') + '/...';

  return (
    <div className={styles.container}>
      <InputWithLabel
        inputId="gdsn-export-mappings-new-element-xpath"
        label="xpath"
      >
        <Textarea
          id={`gdsn-export-mappings-new-element-xpath`}
          value={xpath}
          autoresize
          disabled
          onChange={() => {}}
        ></Textarea>
      </InputWithLabel>
      <InputWithLabel
        inputId="gdsn-export-mappings-new-element-name"
        label={
          <span>
            Name of the element{' '}
            <HelpTooltip
              id={`gdsn-export-mappings-new-element-name-tooltip`}
              place="top"
              message="Must be identical to the xsd. Copy / paste it from the xsd to be sure."
            />
          </span>
        }
      >
        <Text
          id={`gdsn-export-mappings-new-element-name`}
          value={elementName || ''}
          onChange={(e) => setElementName(e.target.value || undefined)}
          invalid={!elementName}
        />
      </InputWithLabel>
      <InputWithLabel
        inputId="gdsn-export-mappings-new-element-type"
        label={
          <span>
            Type of the element{' '}
            <HelpTooltip
              id={`gdsn-export-mappings-new-element-type-tooltip`}
              place="top"
              message="In the xsd, a list element is an element having a complexType."
            />
          </span>
        }
      >
        <Radio
          id={`gdsn-export-mappings-new-element-type`}
          value={elementType}
          options={[
            { label: 'Simple element', value: 'SIMPLE' },
            { label: 'List element', value: 'LIST' },
          ]}
          onChange={(e) => {
            setElementType(e.target.value);
          }}
        />
      </InputWithLabel>
      <div className={styles.buttonsContainer}>
        <Button secondary content="Cancel" onClick={closeXpathCreationView} />
        <Button
          primary
          content="Save"
          onClick={saveNewElement}
          disabled={!elementName}
        />
      </div>
    </div>
  );
}
