import CancelablePromise from 'cancelable-promise';

import { immutableTargetMarketApi } from 'resources/targetMarketApi';

import { TM_RECEIVE } from '../constants/targetMarkets';
import { selectTargetMarketList } from '../selectors/targetMarkets';

export const receiveTargetMarkets = (list) => ({ type: TM_RECEIVE, list });

export const getTargetMarkets = () => (dispatch, getState) => {
  const targetMarket = selectTargetMarketList(getState());
  if (targetMarket.size > 0) {
    return new CancelablePromise((resolve) => resolve(targetMarket));
  }

  return immutableTargetMarketApi.TargetMarketList().then((response) => {
    const { data } = response.data;
    dispatch(receiveTargetMarkets(data));
  });
};
