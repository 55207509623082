import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';

import InputWithLabel from 'components/input-with-label';

export class NewGroupModal extends PureComponent {
  state = {
    groupName: '',
  };

  onCreate = () => {
    const { onCreate } = this.props;
    const { groupName } = this.state;
    onCreate(groupName);
  };

  onEdit = (evt) => {
    this.setState({ groupName: evt.target.value });
  };

  render() {
    const { onClose } = this.props;
    const { groupName } = this.state;
    return (
      <Modal
        title="Import data"
        modalStyle="dynamic"
        onClose={onClose}
        confirmButtonText="Create"
        confirmDisabled={!groupName}
        onConfirm={this.onCreate}
      >
        <InputWithLabel inputId="new-group" label="Group name">
          <Text
            id="new-group"
            value={groupName}
            onChange={this.onEdit}
            placeholder="Group name"
          />
        </InputWithLabel>
      </Modal>
    );
  }
}

NewGroupModal.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewGroupModal;
