import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import {
  CheckboxFilter,
  Filter,
  Filters,
  FiltersBlock,
  SelectedFilters,
} from '@alkem/react-ui-list-filter';

import { selectUserHasPlatformPermission } from 'modules/auth/selectors';
import { selectUser } from 'reducers/user';
import { hasFullPower } from 'utils/user';

import OrganizationCreateModule from '../../../organization-create';
import { openOrganizationCreateModal } from '../../../organization-create/actions/modal';
import {
  addFilter,
  clearFilters,
  getOrganizations,
  removeFilter,
} from '../../actions';
import { getGroups } from '../../actions/groups';
import { getNetworks } from '../../actions/networks';
import { getTargetMarkets } from '../../actions/targetMarkets';
import {
  OrganizationStatusActive,
  OrganizationStatusDecommissioned,
  OrganizationStatusInactive,
} from '../../constants/statuses';
import {
  OrganizationTypeManufacturer,
  OrganizationTypePrivateLabel,
  OrganizationTypeRetailer,
  OrganizationTypeThirdParty,
  OrganizationTypeWhiteLabel,
} from '../../constants/types';
import {
  selectGroupFilters,
  selectNetworkFilters,
  selectSearch,
  selectSelectedFilters,
  selectStatusFilters,
  selectTargetMarketFilters,
  selectTypeFilters,
} from '../../selectors';
import { selectGroupList } from '../../selectors/groups';
import { selectNetworkList } from '../../selectors/networks';
import { selectTargetMarketList } from '../../selectors/targetMarkets';

import './index.scss';
import SearchableFilter from './searchable-filter';

const mapStateToProps = (state) => ({
  networks: selectNetworkList(state),
  groups: selectGroupList(state),
  targetMarkets: selectTargetMarketList(state),
  selected: selectSelectedFilters(state),
  typesFilters: selectTypeFilters(state),
  statusesFilters: selectStatusFilters(state),
  networksFilters: selectNetworkFilters(state),
  groupsFilters: selectGroupFilters(state),
  targetMarketFilters: selectTargetMarketFilters(state),
  search: selectSearch(state),
  user: selectUser(state),
  groupFilterAllowed: selectUserHasPlatformPermission(
    'organizationgroup.show',
    state,
  ),
});

const mapDispatchToProps = {
  getNetworks,
  getGroups,
  getTargetMarkets,
  getOrganizations,
  addFilter,
  removeFilter,
  clearFilters,
  openOrganizationCreateModal,
};

class OrganizationListFilters extends PureComponent {
  static propTypes = {
    networks: ImmutablePropTypes.list.isRequired,
    groups: ImmutablePropTypes.list.isRequired,
    targetMarkets: ImmutablePropTypes.list.isRequired,
    selected: ImmutablePropTypes.list.isRequired,
    typesFilters: ImmutablePropTypes.map.isRequired,
    statusesFilters: ImmutablePropTypes.map.isRequired,
    networksFilters: ImmutablePropTypes.map.isRequired,
    groupsFilters: ImmutablePropTypes.map.isRequired,
    targetMarketFilters: ImmutablePropTypes.map.isRequired,
    user: ImmutablePropTypes.map.isRequired,
    groupFilterAllowed: PropTypes.bool,
    search: PropTypes.string,
    getNetworks: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    getTargetMarkets: PropTypes.func.isRequired,
    getOrganizations: PropTypes.func.isRequired,
    addFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    openOrganizationCreateModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onTypeFilterChange = this.onFilterChange
      .bind(this)
      .bind(this, 'types');
    this.onStatusFilterChange = this.onFilterChange
      .bind(this)
      .bind(this, 'statuses');
    this.onNetworkFilterChange = this.onNetworkFilterChange.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.onOrganizationCreate = this.onOrganizationCreate.bind(this);

    this.typeFilters = [
      OrganizationTypeManufacturer,
      OrganizationTypeRetailer,
      OrganizationTypePrivateLabel,
      OrganizationTypeThirdParty,
      OrganizationTypeWhiteLabel,
    ];
    this.statusFilters = [
      OrganizationStatusActive,
      OrganizationStatusInactive,
      OrganizationStatusDecommissioned,
    ];

    this.typeFilterSelectors = this.buildSelector('type');
    this.statusFilterSelectors = this.buildSelector('status');
  }

  componentDidMount() {
    this.props.getNetworks();
    this.props.getTargetMarkets();
    if (this.shouldDisplayOrganizationGroupFilter()) {
      this.props.getGroups();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selected,
      typesFilters,
      statusesFilters,
      networksFilters,
      groupsFilters,
      targetMarketFilters,
      search,
    } = this.props;
    if (
      selected !== prevProps.selected ||
      typesFilters !== prevProps.typesFilters ||
      statusesFilters !== prevProps.statusesFilters ||
      networksFilters !== prevProps.networksFilters ||
      groupsFilters !== prevProps.groupsFilters ||
      targetMarketFilters !== prevProps.targetMarketFilters ||
      search !== prevProps.search
    ) {
      this.props.getOrganizations();
    }
  }

  onFilterChange(filterName, filter, newValue) {
    if (newValue === false) {
      this.props.removeFilter(filterName, filter.id);
    } else {
      this.props.addFilter(filterName, filter.id, newValue, filter.name);
    }
  }

  onNetworkFilterChange(filter, newValue) {
    if (newValue === false) {
      this.props.removeFilter('networks', filter.get('id'));
    } else {
      this.props.addFilter(
        'networks',
        filter.get('id'),
        newValue,
        filter.get('name'),
      );
    }
  }

  onGroupFilterChange = (filter, newValue) => {
    if (newValue === false) {
      this.props.removeFilter('groups', filter.get('id'));
    } else {
      this.props.addFilter(
        'groups',
        filter.get('id'),
        newValue,
        filter.get('name'),
      );
    }
  };

  onTargetMarketFilterChange = (filter, newValue) => {
    if (newValue === false) {
      this.props.removeFilter('targetMarkets', filter.get('id'));
    } else {
      this.props.addFilter(
        'targetMarkets',
        filter.get('id'),
        newValue,
        filter.get('label'),
      );
    }
  };

  onOrganizationCreate() {
    this.props.openOrganizationCreateModal();
  }

  shouldDisplayOrganizationGroupFilter() {
    const { user, groupFilterAllowed } = this.props;
    return groupFilterAllowed || hasFullPower(user);
  }

  buildSelector(name) {
    return {
      selectId: (filter) => `${name}-${filter.id}`,
      selectNode: (filter) => filter.name,
    };
  }

  isFilterSelected(filter, selection) {
    return selection.get(filter.id);
  }

  clearFilter(filter) {
    this.props.removeFilter(filter.get('filterType'), filter.get('filterId'));
  }

  selectSelectedFilterId(filter) {
    return `${filter.get('filterType')}-${filter.get('filterId')}`;
  }

  selectSelectedFilterLabel(filter) {
    return filter.get('filterLabel');
  }

  render() {
    return (
      <Filters>
        <li>
          <Button
            secondary
            className="OrganizationListFilters__createButton"
            onClick={this.onOrganizationCreate}
          >
            <span className="OrganizationListFilters__createContent">
              <i className="OrganizationListFilters__createIcon mdi mdi-plus-circle" />
              <span className="OrganizationListFilters__createLabel">
                Create an organization
              </span>
            </span>
          </Button>
        </li>
        <SelectedFilters
          id="organizationlist-selectedfilters"
          title="Selected Filters"
          emptyLabel="No selected filters"
          removeLabel="Remove"
          filters={this.props.selected}
          selectId={this.selectSelectedFilterId}
          selectLabel={this.selectSelectedFilterLabel}
          onRemoveAll={this.props.clearFilters}
          onRemove={this.clearFilter}
        />
        <FiltersBlock>
          <Filter
            id="organizationlist-filter-type"
            title="Organization Types"
            filters={this.typeFilters}
            selection={this.props.typesFilters}
            Item={CheckboxFilter}
            selectors={this.typeFilterSelectors}
            isSelected={this.isFilterSelected}
            onChange={this.onTypeFilterChange}
          />
          <Filter
            id="organizationlist-filter-status"
            title="Organization Statuses"
            filters={this.statusFilters}
            selection={this.props.statusesFilters}
            Item={CheckboxFilter}
            selectors={this.statusFilterSelectors}
            isSelected={this.isFilterSelected}
            onChange={this.onStatusFilterChange}
          />
          <SearchableFilter
            id="organizationlist-filter-network"
            title="Networks"
            filters={this.props.networks}
            selection={this.props.networksFilters}
            onChange={this.onNetworkFilterChange}
          />
          {this.shouldDisplayOrganizationGroupFilter() && (
            <SearchableFilter
              id="organizationlist-filter-group"
              title="Groups"
              filters={this.props.groups}
              selection={this.props.groupsFilters}
              onChange={this.onGroupFilterChange}
            />
          )}
          <SearchableFilter
            id="organizationlist-filter-target-market"
            title="Target Markets"
            filters={this.props.targetMarkets}
            selection={this.props.targetMarketFilters}
            onChange={this.onTargetMarketFilterChange}
          />
          <OrganizationCreateModule />
        </FiltersBlock>
      </Filters>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationListFilters);
