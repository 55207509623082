import { List } from 'immutable';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';
import { Filter } from '@alkem/react-ui-filter';
import { Text } from '@alkem/react-ui-inputs';

import { craftTree } from '../utils';

export default class ReferentialManagementImport extends PureComponent {
  static propTypes = {
    sourceReferentials: ImmutablePropTypes.list,
    isLoading: PropTypes.bool,
    isImporting: PropTypes.bool,
    isBranching: PropTypes.bool,
    imported: ImmutablePropTypes.list,
    importReferentials: PropTypes.func,
    saveImportedReferentials: PropTypes.func,
  };

  state = {
    parent: null,
    url: null,
  };

  onChangeImportUrl = (event) => {
    const { value } = event.target;
    this.setState({ url: value });
  };

  onChangeImportParent = (_, node) => {
    this.setState({ parent: node });
  };

  onImport = () => {
    this.props.importReferentials(this.state.url);
    this.setState({ url: null });
  };

  onBranch = () => {
    this.props.saveImportedReferentials(this.state.parent);
    this.setState({
      url: null,
      parent: null,
    });
  };

  renderImportedReferentials = () => (
    <table className="OrganizationUsesFieldsView__imported__referentials">
      <thead>
        <tr>
          <th className="OrganizationUsesFieldsView__imported__referentials__code">
            Code
          </th>
          <th className="OrganizationUsesFieldsView__imported__referentials__label">
            Label
          </th>
          <th className="OrganizationUsesFieldsView__imported__referentials__data">
            Data
          </th>
        </tr>
      </thead>
      <tbody>
        {this.props.imported.map((entity) => (
          <tr
            key={JSON.stringify(entity)}
            className="OrganizationUsesFieldsView__imported__referentials__row"
          >
            <td>{entity.get('code')}</td>
            <td>{entity.get('label')}</td>
            <td>{entity.get('data')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  render = () => {
    const {
      sourceReferentials,
      isImporting,
      imported,
      isBranching,
      isLoading,
    } = this.props;
    const { parent, url } = this.state;
    return (
      <div>
        <div className="OrganizationUsesFieldsView__referentials__import">
          <div className="OrganizationUsesFieldsView__referentials__import__url__row">
            <div className="OrganizationUsesFieldsView__referentials__import__url__input">
              <div>Paste the spreadsheet link below</div>
              <Text
                id="organization-referentials-import"
                onChange={this.onChangeImportUrl}
                value={url}
              />
            </div>
            <div className="OrganizationUsesFieldsView__referentials__import__url__action">
              <Button
                id="organization-referentials-import-url"
                onClick={this.onImport}
                content="Import"
                disabled={isLoading || !url}
                displaySpinner={isImporting}
                primary
              />
            </div>
          </div>
          <div className="OrganizationUsesFieldsView__referentials__import__imported">
            {imported && this.renderImportedReferentials()}
          </div>
          <div className="OrganizationUsesFieldsView__referentials__import__parent">
            <div>Choose the parent referential</div>
            <Filter
              name="ReferentialsImportParentSelection"
              type="tree"
              filters={craftTree(
                get(parent, 'id'),
                (sourceReferentials || List()).toJS(),
              )}
              onChange={this.onChangeImportParent}
            />
          </div>
          <div className="OrganizationUsesFieldsView__referentials__import__branch">
            <div>
              Branch the referentials to the parent referential (they will be
              added by default to the selected retailer)
            </div>
            <Button
              id="organization-referentials-branch"
              onClick={this.onBranch}
              content="Save"
              disabled={isLoading || !imported || !parent}
              displaySpinner={isBranching}
              primary
            />
          </div>
        </div>
      </div>
    );
  };
}
