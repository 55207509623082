import { fromJS } from 'immutable';
import { isUndefined } from 'lodash';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import { fieldsApiImmutable as fieldsApi } from 'resources/fieldsApi';
import referentialApi from 'resources/referentialApi';
import { logError } from 'utils';
import { request, requestWithHeaders } from 'utils/api';
import { takeFirst } from 'utils/saga';

import * as events from './events';

export default function* mainSaga() {
  yield takeLatest(events.REFRESH_CACHE, refreshCache);
  yield takeLatest(events.GET_TAGS, listTags);
  yield takeFirst(events.GET_ROOTS_LIST, listRoots);
  yield takeEvery(events.CREATE_ROOT, createRoot);
  yield takeLatest(events.GET_FIELDS_LIST, listFields);
  yield takeEvery(events.GET_FIELD, getField);
  yield takeEvery(events.SAVE_FIELD, saveField);
  yield takeEvery(events.DELETE_FIELD, deleteField);
  yield takeEvery(events.SAVE_BULK_EDIT, bulkEdit);
}

function* refreshCache() {
  const { error } = yield call(requestWithHeaders, fieldsApi, 'refreshCache');
  if (error) {
    logError(error);
    yield put(notificationError('Failed to refresh cache'));
  } else {
    yield put(notificationSuccess('Cache refreshed'));
  }
}

function* listTags() {
  const { result, error } = yield call(
    request,
    referentialApi,
    'ReferentialGetList',
    'fieldtags',
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load tags'));
    yield put({
      type: events.RECEIVE_TAGS,
      tags: [],
    });
  } else {
    yield put({
      type: events.RECEIVE_TAGS,
      tags: result.map((t) => ({ label: t.code })),
    });
  }
}

function* listRoots() {
  const { result, error } = yield call(
    request,
    fieldsApi,
    'FieldMetaDataRoots',
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to list roots'));
  } else {
    yield put({
      type: events.RECEIVE_ROOTS_LIST,
      roots: result.map((r) =>
        fromJS({
          id: r.get('id'),
          label: r.get('name'),
        }),
      ),
    });
  }
}

function* createRoot({ name }) {
  const { error } = yield call(request, fieldsApi, 'FieldMetaDataCreate', {
    name,
    children: [],
  });
  if (error) {
    logError(error);
    yield put(notificationError('Failed to create root'));
  } else {
    yield put(notificationSuccess('Root creation successful'));
    yield put({ type: events.GET_ROOTS_LIST });
  }
}

const sortChildren = (field) => {
  const children = field.get('children');
  if (!children || children.size < 2) {
    return field;
  }

  return field.update('children', (cs) =>
    cs.sortBy((c) => c.getIn(['displayInfo', 'rank'])).map(sortChildren),
  );
};

function* listFields({ entityType, search }) {
  const { result, error } = yield call(
    request,
    fieldsApi,
    'listMainFields',
    search,
    ['tags', 'displayInfo', 'children'],
    entityType,
    { with_disabled: true, with_specific: true, limit: 100 },
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to fetch fields'));
  } else {
    yield put({
      type: events.RECEIVE_FIELDS_LIST,
      fields: result.map(sortChildren),
    });
  }
}

function* getField({ id }) {
  const { result, error } = yield call(
    requestWithHeaders,
    fieldsApi,
    'getField',
    id,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to get field'));
  } else {
    yield put({
      type: events.RECEIVE_FIELD,
      field: sortChildren(fromJS(result.data)),
    });
  }
}

function* saveField({ field, entityType }) {
  const id = field.get('id');
  yield put({ type: events.SET_SAVE_IN_PROGRESS, saveInProgress: true });
  if (isUndefined(id)) {
    const { result, error } = yield call(
      request,
      fieldsApi,
      'FieldMetaDataCreate',
      field.toJS(),
    );
    if (error) {
      logError(error);
      yield put(notificationError('Failed to create field'));
    } else {
      yield put(notificationSuccess('Field has been created'));
      yield put({ type: events.RECEIVE_FIELD, field: sortChildren(result) });
    }
  } else {
    const { result, error } = yield call(
      request,
      fieldsApi,
      'FieldMetaDataUpdate',
      field.toJS(),
    );
    if (error) {
      logError(error);
      yield put(notificationError('Failed to update field'));
    } else {
      yield put(notificationSuccess('Field has been updated'));
      yield put({ type: events.RECEIVE_FIELD, field: sortChildren(result) });
    }
  }
  yield put({ type: events.SET_SAVE_IN_PROGRESS, saveInProgress: false });
  yield put({
    type: events.GET_FIELDS_LIST,
    entityType: entityType,
    search: '',
  });
}

function* bulkEdit({ fields }) {
  yield put({ type: events.SET_SAVE_IN_PROGRESS, saveInProgress: true });
  const errors = [];
  for (let i = 0; i < fields.length; i += 1) {
    const { error } = yield call(
      request,
      fieldsApi,
      'FieldMetaDataUpdate',
      fields[i],
    );
    if (error) {
      logError(error);
      errors.push(error);
    }
  }
  if (errors.length) {
    yield put(notificationError('Failed to bulk edit fields'));
  } else {
    yield put(notificationSuccess('Bulk edit complete!'));
  }
  yield put({ type: events.SET_SAVE_IN_PROGRESS, saveInProgress: false });
}

function* deleteField({ id, entityType }) {
  yield put({ type: events.SET_DELETE_IN_PROGRESS, deleteInProgress: true });
  const { error } = yield call(
    requestWithHeaders,
    fieldsApi,
    'deleteField',
    id,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to delete field'));
  } else {
    yield put({ type: events.RECEIVE_FIELD, field: null });
    yield put({
      type: events.GET_FIELDS_LIST,
      entityType: entityType,
      search: '',
    });
    yield put(notificationSuccess('Field has been deleted'));
  }
  yield put({ type: events.SET_DELETE_IN_PROGRESS, deleteInProgress: false });
}
