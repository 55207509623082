import React from 'react';
import { ModuleWrapper } from 'redux/register';

import PcrModal from './components';
import { PCR_MODAL } from './constants/reducers';
import reducer from './reducers';

export default function PcrModalContainer(props) {
  return (
    <ModuleWrapper reducerKey={PCR_MODAL} reducer={reducer}>
      <PcrModal {...props} />
    </ModuleWrapper>
  );
}
