import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { ListAutocomplete } from 'components/autocomplete';

import {
  downloadAllFiles,
  fetchFilters,
  updateSelectedCurrent,
  updateSelectedFilename,
  updateSelectedLang,
} from '../../actions';
import { selectFilters, selectSelectedFilters } from '../../selectors';
import * as types from '../../types';

import './filters.scss';

function buildOption(s: string): {
  key: string;
  label: string;
  value: string;
} {
  return {
    key: s,
    label: s,
    value: s,
  };
}

export function TranslationFilters() {
  const filters: types.Filters | null = useSelector(selectFilters);
  const selectedFilters: types.SelectedFilters = useSelector(
    selectSelectedFilters,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilters());
  }, [dispatch]);

  const onSelectCurrent = useCallback(
    (current) => {
      dispatch(updateSelectedCurrent(current.value));
    },
    [dispatch],
  );
  const onUnselectCurrent = useCallback(() => {
    dispatch(updateSelectedCurrent(null));
  }, [dispatch]);
  const currentOptions = useMemo(
    () => [
      {
        key: 'true',
        value: true,
        label: 'Yes',
      },
      {
        key: 'false',
        value: false,
        label: 'No',
      },
    ],
    [],
  );
  const selectedCurrent = useMemo(
    () =>
      selectedFilters.current === null
        ? []
        : [selectedFilters.current ? currentOptions[0] : currentOptions[1]],
    [selectedFilters, currentOptions],
  );

  const onSelectFilename = useCallback(
    (filename) => {
      dispatch(updateSelectedFilename(filename.value));
    },
    [dispatch],
  );
  const onUnselectFilename = useCallback(() => {
    dispatch(updateSelectedFilename(null));
  }, [dispatch]);
  const selectedFilename = useMemo(
    () =>
      selectedFilters.filename === null
        ? []
        : [buildOption(selectedFilters.filename)],
    [selectedFilters],
  );
  const filenameOptions = useMemo(
    () => (filters === null ? [] : filters.filenames?.map(buildOption)),
    [filters],
  );

  const onSelectLang = useCallback(
    (lang) => {
      dispatch(updateSelectedLang(lang.value));
    },
    [dispatch],
  );
  const onUnselectLang = useCallback(() => {
    dispatch(updateSelectedLang(null));
  }, [dispatch]);
  const selectedLang = useMemo(
    () =>
      selectedFilters.lang === null ? [] : [buildOption(selectedFilters.lang)],
    [selectedFilters],
  );
  const langOptions = useMemo(
    () => (filters === null ? [] : filters.langs?.map(buildOption)),
    [filters],
  );

  if (filters === null) {
    return null;
  }
  return (
    <>
      <div className="translation_button">
        <Button onClick={() => dispatch(downloadAllFiles())} primary>
          <i className="mdi mdi-download" />
          Download all files
        </Button>
      </div>
      <h3>Current</h3>
      <ListAutocomplete
        id={`translation-filters-current`}
        onSelect={onSelectCurrent}
        onUnselect={onUnselectCurrent}
        values={currentOptions}
        value={selectedCurrent}
        placeholder="Select current..."
        searchOnClick
      />
      <br />
      <h3>Filenames</h3>
      <ListAutocomplete
        id={`translation-filters-filenames`}
        onSelect={onSelectFilename}
        onUnselect={onUnselectFilename}
        values={filenameOptions}
        value={selectedFilename}
        placeholder="Select filename..."
        searchOnClick
      />
      <br />
      <h3>Languages</h3>
      <ListAutocomplete
        id={`translation-filters-languages`}
        onSelect={onSelectLang}
        onUnselect={onUnselectLang}
        values={langOptions}
        value={selectedLang}
        placeholder="Select language..."
        searchOnClick
      />
    </>
  );
}
