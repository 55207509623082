import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Ellitips } from '@alkem/react-ui-ellitips';

import './link.scss';

class FilterTreeLinkItem extends Component {
  static propTypes = {
    tree: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { tree, name, onChange } = this.props;
    event.preventDefault();
    onChange(name, tree, !tree.checked);
  }

  render() {
    const { tree, name } = this.props;
    if (!tree) {
      return null;
    }
    const id = `${name}-${tree.value}`;
    const count = 'count' in tree ? ` (${tree.count})` : '';
    let { label } = tree;
    if (tree.checked) {
      label = get(tree, 'linkLabels.activated') || label;
    } else {
      label = get(tree, 'linkLabels.deactivated') || label;
    }
    return (
      <li className="FilterTreeLinkItem">
        <button
          id={id}
          type="button"
          className="FilterTreeLinkItem__btn Button btn btn-link"
          onClick={this.onChange}
        >
          <Ellitips id={id} label={`${label} ${count}`} place="right" />
        </button>
      </li>
    );
  }
}

export default FilterTreeLinkItem;
