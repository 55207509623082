import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '@alkem/react-hooks/src/redux';
import { Spinner } from '@alkem/react-ui-spinner';
import { TwinPanel } from '@alkem/react-ui-twinpanel';

import { connect as connectGoogleApis } from 'modules/referentials/services/googleapis';

import * as actions from '../../../actions';
import { selectState } from '../../../selectors';

import './index.scss';
import OrganizationReferentials from './organization-referentials';
import ReferentialsManagement from './referentials-management';

export const TabReferentials = () => {
  const {
    referentials: {
      isSaving,
      isAdding,
      isImporting,
      isBranching,
      isUpdating,
      isDirty,
      edited: editedReferentials,
      source: sourceReferentials,
      selectedRootNodeId,
      imported,
    },
    editedOrganization,
  } = useSelector(selectState);

  const isLoading =
    isSaving || isAdding || isImporting || isBranching || isUpdating;

  const dispachingActions = useActions(actions);

  useEffect(() => {
    dispachingActions.fetchReferentials({
      with_organizations: true,
      translate_children: false,
    });
    connectGoogleApis();
  }, [dispachingActions]);

  if (!sourceReferentials) {
    return <Spinner center />;
  }

  let leftPanelContent = <span> Please select a retailer </span>;
  if (editedOrganization)
    leftPanelContent = (
      <OrganizationReferentials
        editedOrganization={editedOrganization}
        editedReferentials={editedReferentials}
        isDirty={isDirty}
        selectedRootNodeId={selectedRootNodeId}
        isSaving={isSaving}
        isLoading={isLoading}
        toggleReferential={dispachingActions.toggleReferential}
        saveReferentials={dispachingActions.saveReferentials}
        selectRootNode={dispachingActions.selectRootNode}
      />
    );

  const rightPanelContent = (
    <ReferentialsManagement
      sourceReferentials={sourceReferentials}
      isLoading={isLoading}
      isAdding={isAdding}
      isImporting={isImporting}
      isBranching={isBranching}
      isUpdating={isUpdating}
      imported={imported}
      addRootNode={dispachingActions.addRootNode}
      importReferentials={dispachingActions.importReferentials}
      saveImportedReferentials={dispachingActions.saveImportedReferentials}
      updateReferential={dispachingActions.updateReferential}
      addChildrenToReferential={dispachingActions.addChildrenToReferential}
    />
  );

  return (
    <div className="OrganizationUsesFieldsView__referentials">
      <TwinPanel
        leftPanel={{
          title: 'Organization Referentials',
          className: 'OrganizationUsesFieldsView__referentials__tree',
          content: leftPanelContent,
        }}
        rightPanel={{
          title: 'Referentials management',
          className: 'OrganizationUsesFieldsView__referentials__management',
          content: rightPanelContent,
        }}
      />
    </div>
  );
};
