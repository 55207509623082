import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// import { createI13 }
import { SimpleSelect } from '@alkem/react-ui-select';

const options = ['TO_DO', 'IN_PROGRESS', 'DONE'].map((status) => ({
  id: `support-status-${status}`,
  label: renderField(status.replace('_', ' ')),
  value: status,
}));
const extraOptions = {
  i13Action: 'supportStatus',
  i13Category: 'filter',
};

function renderField(status) {
  return (
    <span className="SimpleSelect_value">
      <i
        className={`mdi mdi-${
          {
            'TO DO': 'calendar',
            'IN PROGRESS': 'clock',
            DONE: 'check',
          }[status]
        }`}
      />
      <span>{status}</span>
    </span>
  );
}

export default class SupportStatusSelect extends PureComponent {
  static propTypes = {
    importFileId: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    extraOptions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      status: this.props.status,
    };
  }

  onChange({ value } = {}) {
    this.setState({ status: value });
    this.props.onChange(this.props.importFileId, value);
  }

  findSelectedValue() {
    return options.find((opt) => opt.value === this.state.status);
  }

  render() {
    return (
      <SimpleSelect
        id="supportStatus"
        options={options}
        value={this.findSelectedValue()}
        onSelect={this.onChange}
        extraOptions={{ ...extraOptions, ...this.props.extraOptions }}
        autoSize
      />
    );
  }
}
