import { call, put, select, takeEvery } from 'redux-saga/effects';

import { notificationError } from 'actions/notifications';
import { kronosApi } from 'resources/kronosApi';
import { logError } from 'utils';
import { requestWithHeaders } from 'utils/api';

import * as actions from './actions';
import * as events from './events';
import * as selectors from './selectors';

export default function* mainSaga() {
  yield takeEvery(
    [events.FETCH_REVISIONS, events.UPDATE_FILTERS],
    fetchRevisionList,
  );
}

function* fetchRevisionList() {
  yield put(actions.setLoading(true));
  const selectedFilters = yield select(selectors.selectFilters);

  const { result, error } = yield call(
    requestWithHeaders,
    kronosApi,
    'post',
    `/kronos/product/v1/revisions`,
    {
      ...selectedFilters,
    },
  );
  if (error) {
    logError(error);
    yield put(
      notificationError(
        `Failed to load revisions: ${JSON.stringify(error.data.errors)}`,
      ),
    );
  } else {
    yield put(actions.receiveRevisions(result.data));
  }
  yield put(actions.setLoading(false));
}
