import { Map } from 'immutable';
import React from 'react';

import { SwitchButton } from '@alkem/react-ui-button';
import HelpTooltip from '@alkem/react-ui-helptooltip';
import Well from '@alkem/react-ui-well';

import './style.scss';

const Space = () => <span style={{ marginRight: '5px' }}> </span>;

type Path = (string | number)[];

interface Props {
  path: Path;
  field: Map<string, any>;
  childIndex: number;
  children: React.ReactNode;
  onToggle: (path: Path, field: any) => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

export default function ChildField(props: Props) {
  const { childIndex, children, field, path, onToggle, onMoveUp, onMoveDown } =
    props;

  const toggleShowByDefault = React.useCallback(
    (enabled) => {
      const childPath = [...path, 'children', childIndex];
      const updatedField = field.setIn(
        ['displayInfo', 'plugins', 'options', 'hiddenByDefault'],
        !enabled,
      );
      onToggle(childPath, updatedField);
    },
    [childIndex, field, path, onToggle],
  );

  if (!field) {
    return null;
  }

  const checked = !field.getIn([
    'displayInfo',
    'plugins',
    'options',
    'hiddenByDefault',
  ]);

  const headerLeft = (
    <span className="ChildField__headerLeft">
      {field.get('name') || 'new child'}
      {onMoveUp && (
        <button type="button" className="btn btn-link" onClick={onMoveUp}>
          <i className="mdi mdi-chevron-up" />
        </button>
      )}
      {onMoveDown && (
        <button type="button" className="btn btn-link" onClick={onMoveDown}>
          <i className="mdi mdi-chevron-down" />
        </button>
      )}
    </span>
  );

  const headerRight = (
    <div className="ChildField__headerRight">
      <span>Show by default</span>
      <Space />
      <HelpTooltip
        id="help-tooltip"
        message={
          ' This setting will define if the user needs to click on a "show more" button in order to see the field.'
        }
      />
      <Space />
      <SwitchButton checked={checked} onChange={toggleShowByDefault} />
    </div>
  );

  return (
    <div className="FieldForm__child">
      <Well v2 header={headerLeft} headerRight={headerRight}>
        {children}
      </Well>
    </div>
  );
}
