export const groupDataColumns = ({ code, label, ...data }) => ({
  code,
  label,
  data,
});

// addLeaves finds parent in the tree and add it the children.
// It raises if there is a code conflict
export const addLeaves = (tree, parent, children) => {
  if (tree.id === parent.id) {
    // Check unicity of children code (ceinture-bretelle)
    const unicity = parent.children
      .map((child) => child.code)
      .find((code) => children.map((child) => child.code).includes(code));
    if (unicity) {
      throw Error(
        `Some new children have same codes as current children: ${unicity}`,
      );
    }
    tree.children.push(...children);
    return true;
  } else if (tree.children) {
    for (let i = 0; i < tree.children.length; i += 1) {
      const found = addLeaves(tree.children[i], parent, children);
      if (found) {
        return true;
      }
    }
  }
  return false;
};

// withOrganizationReferential return referentials enriched with organization
export const withOrganizationReferential = (referentials, organization) =>
  referentials.map((child) => ({
    ...child,
    organizations: organization ? [{ organization_id: organization.id }] : [],
  }));

// addRemoveOrganizationReferential add or remove organization to the referential depending on the value
export const addRemoveOrganizationReferential = (
  referential,
  organizationId,
  value,
) => {
  const newReferential = { ...referential };
  if (!newReferential.organizations) {
    newReferential.organizations = [];
  }
  const selectedIndex = newReferential.organizations.findIndex(
    (org) => org.organization_id === organizationId,
  );
  if (selectedIndex === -1 && value) {
    newReferential.organizations.push({
      organization_id: organizationId,
    });
  }
  if (selectedIndex >= 0 && !value) {
    newReferential.organizations.splice(selectedIndex, 1);
  }
  return newReferential;
};

// checkNodeSelected returns if at least one node of the referential tree is selected by the organization
export const checkNodeSelected = (referential, organizationId) => {
  if (referential.organizations) {
    const selected = referential.organizations.some(
      (org) => org.organization_id === organizationId,
    );
    if (selected) {
      return true;
    }
  }
  return referential.children.some((child) =>
    checkNodeSelected(child, organizationId),
  );
};

export const organizationHasField = (organization, field) =>
  organization
    .get('usesFields')
    .some(
      (f) =>
        f.get('name') === field.get('name') &&
        f.get('entityType') === field.get('entityType'),
    );
