import { any, bool, object } from 'prop-types';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { Spinner } from '@alkem/react-ui-spinner';

import * as routes from 'constants/routes';
import CoreLayout from 'layouts/CoreLayout';
import { hasAccess } from 'modules/access-policy/common/utils';
import { loadProfile } from 'modules/auth/actions';
import { CommandPalette } from 'modules/command-palette';
import { selectUser } from 'reducers/user';
import { getUserType } from 'utils/user';

import {
  selectIsAuthPending,
  selectIsAuthenticated,
  selectIsFetchingProfile,
  selectPermissions,
} from '../reducer';

import './ProtectedRoute.scss';

const withRestriction = (
  View,
  { accessPolicy, bypassAccessPolicy = false },
) => {
  function ProtectedRoute(props) {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isAuthPending = useSelector(selectIsAuthPending);
    const isFetchingProfile = useSelector(selectIsFetchingProfile);
    const user = useSelector(selectUser);
    const userPermissions = useSelector(selectPermissions);
    const location = useLocation();

    useEffect(
      () => {
        if (!user || user.isEmpty()) {
          dispatch(loadProfile());
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    if (isAuthPending || isFetchingProfile) {
      return (
        <div style={{ paddingTop: '100px', textAlign: 'center' }}>
          <Spinner loading big />
        </div>
      );
    }
    if (isAuthenticated) {
      if (
        bypassAccessPolicy ||
        hasAccess(getUserType(user), userPermissions, accessPolicy)
      ) {
        return <View {...props} />;
      }
      return (
        <div className="ProtectedRoute__accessDenied">
          <h1>Access denied</h1>
        </div>
      );
    }
    return (
      <Navigate
        to={{
          pathname: routes.login,
          search: `next=${location.pathname}`,
        }}
      />
    );
  }
  return memo(ProtectedRoute);
};

/**
 * @param {object} props
 * @param {any} props.component
 * @param {object=} props.accessPolicy
 * @param {boolean=} props.isPublic
 * @param {boolean=} props.bypassAccessPolicy
 * @returns {any}
 */
const ProtectedRoute = ({
  isPublic,
  component,
  accessPolicy,
  bypassAccessPolicy,
  ...props
}) => {
  const MemoizedComponent = useMemo(
    () =>
      isPublic
        ? component
        : withRestriction(component, {
            accessPolicy,
            bypassAccessPolicy,
          }),
    [component, accessPolicy, bypassAccessPolicy, isPublic],
  );

  return (
    <CoreLayout isPublic={isPublic}>
      <MemoizedComponent {...props} />
      {isPublic ? null : <CommandPalette />}
    </CoreLayout>
  );
};

ProtectedRoute.propTypes = {
  isPublic: bool,
  component: any.isRequired,
  accessPolicy: object,
  bypassAccessPolicy: bool,
};

export default ProtectedRoute;
