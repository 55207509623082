import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Filter from 'components/ui/filter';
import OrganizationFilter from 'components/ui/filter/organization';
import SelectedFilters from 'components/ui/selected-filters';
import { separateActions } from 'utils/redux';

import {
  addExportListFilters,
  addOneExportListFilters,
  clearExportListFilters,
  getExportListFilters,
  removeExportListFilters,
  updateExportListFilters,
} from '../actions';
import { selectFilters, selectFiltersAvailable } from '../selectors';

const mapStateToProps = (state) => ({
  filters: selectFilters(state),
  filtersAvailable: selectFiltersAvailable(state),
});

const mapDispatchToProps = {
  addExportListFilters,
  removeExportListFilters,
  updateExportListFilters,
  clearExportListFilters,
  getExportListFilters,
  addOneExportListFilters,
};

class ExportFilters extends PureComponent {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    filtersAvailable: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      addExportListFilters: PropTypes.func.isRequired,
      removeExportListFilters: PropTypes.func.isRequired,
      updateExportListFilters: PropTypes.func.isRequired,
      clearExportListFilters: PropTypes.func.isRequired,
      getExportListFilters: PropTypes.func.isRequired,
      addOneExportListFilters: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.getExportListFilters();
  }

  onOrganizationFilterChange = (filter, newValue) => {
    if (newValue === false) {
      this.props.actions.removeExportListFilters('organizations', {
        label: filter.get('nameLegal'),
        value: filter.get('id'),
        checked: true,
      });
    } else {
      this.props.actions.addExportListFilters('organizations', {
        label: filter.get('nameLegal'),
        value: filter.get('id'),
        checked: false,
      });
    }
  };

  onFormatFilterChange = (name, value) => {
    this.props.actions.addOneExportListFilters(name, value);
  };

  onDateFilterChange = (filterName, newValue) => {
    const dates = {
      label: newValue.label,
      value: newValue.value,
      name: newValue.name,
    };
    this.props.actions.updateExportListFilters('dates', dates);
  };

  calculateFiltersDate = (filters, filtersAvailable) => {
    const filterAvailableDateInJSON = filtersAvailable.get('dates').toJS();
    for (let date of filterAvailableDateInJSON) {
      const filteredValue = filters.dates.filter(
        (element) => element.name === date.name,
      );
      if (filteredValue.length > 0) {
        date.value = filteredValue[0].value;
      } else {
        date.value = undefined;
      }
    }
    return filterAvailableDateInJSON;
  };

  render() {
    const { filters, filtersAvailable } = this.props;
    const selectedOrganizations = filters.organizations.reduce(
      (prev, curr) => prev.set(curr.value, true),
      Map(),
    );
    return (
      <div>
        <SelectedFilters
          filters={filters}
          removeFilter={this.props.actions.removeExportListFilters}
          removeAllFilters={this.props.actions.clearExportListFilters}
        />
        <div className="card">
          <Filter
            type="list"
            filters={filtersAvailable.get('statuses').toJS()}
            onChange={this.onFormatFilterChange}
            name="status"
            title="Status"
          />
          <OrganizationFilter
            id="import-filter-organization"
            title="Organizations"
            filters={filtersAvailable.get('organizations')}
            selection={selectedOrganizations}
            onChange={this.onOrganizationFilterChange}
          />
          <Filter
            type="date"
            filters={this.calculateFiltersDate(filters, filtersAvailable)}
            onChange={this.onDateFilterChange}
            name="dates"
            title="Dates"
          />
          <Filter
            type="list"
            filters={filtersAvailable.get('formats').toJS()}
            onChange={this.onFormatFilterChange}
            name="format"
            title="Formats"
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(ExportFilters);
