import { fromJS } from 'immutable';
import qs from 'querystringify';

import {
  dates,
  statusFilters,
  supportStatusFilters,
} from 'modules/io/import/constants';
import * as events from 'modules/io/import/constants/events';
import {
  MONITORING_USER,
  UNKNOWN_GLN_CHECKED,
} from 'modules/io/import/filters';
import { createReducer } from 'utils/reducers';
import {
  addFilter,
  addOneOptionFilter,
  changeLimit,
  clearFilter,
  initPagination,
  nextPage,
  previousPage,
  receiveReducer,
  removeFilter,
  search,
} from 'utils/reducers/list';

import { formatSpiderFilter } from '../utils';

const LIMIT_STORAGE_KEY = 'importListLimit';
const SORTING_STORAGE_KEY = 'importListSorting';

function getSpidersFilter() {
  return qs.parse(location.search).spiders
    ? [
        {
          value: qs.parse(location.search).spiders,
          label: qs.parse(location.search).spiders,
          checked: true,
        },
      ]
    : [];
}

function getOrganizationsFilter() {
  const organizationIds = qs.parse(location.search).filter_organization_ids;
  if (organizationIds) {
    return organizationIds.split(',').map((id) => ({
      value: id,
      label: id,
      checked: true,
    }));
  }
  return [];
}

function getStatusFilter() {
  return qs.parse(location.search).status
    ? [
        {
          value: qs.parse(location.search).status,
          label: qs.parse(location.search).status,
        },
      ]
    : [];
}

function getSupportStatusFilter() {
  return qs.parse(location.search).supportStatus
    ? [
        {
          value: qs.parse(location.search).supportStatus,
          label: qs.parse(location.search).supportStatus,
        },
      ]
    : [];
}

function getIdInFilter() {
  return qs.parse(location.search).filter_ids
    ? [
        {
          value: qs.parse(location.search).filter_ids,
        },
      ]
    : [];
}

function getDatesFilter() {
  const datesFilter = [];
  if (qs.parse(location.search).date) {
    datesFilter.push({
      name: 'date',
      value: qs.parse(location.search).date,
      label: 'Date',
    });
  }
  if (qs.parse(location.search).date_from) {
    datesFilter.push({
      name: 'date_from',
      value: qs.parse(location.search).date_from,
      label: 'Date from',
    });
  }
  if (qs.parse(location.search).date_to) {
    datesFilter.push({
      name: 'date',
      value: qs.parse(location.search).date_to,
      label: 'Date to',
    });
  }
  return datesFilter;
}

const initialState = {
  search: qs.parse(location.search).q,
  list: [],
  isLoading: true,
  filters: {
    spiders: getSpidersFilter(),
    organization: getOrganizationsFilter(),
    unknown_gln: [UNKNOWN_GLN_CHECKED],
    user_out: [MONITORING_USER],
    empty_out: [],
    dates: getDatesFilter(),
    supportStatus: getSupportStatusFilter(),
    status: getStatusFilter(),
    filter_ids: getIdInFilter(),
  },
  filtersAvailable: fromJS({
    spiders: [],
    organization: [],
    supportStatus: supportStatusFilters,
    status: statusFilters,
    filter_ids: [],
    dates,
  }),
  importDetails: {},
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
};

export default createReducer(initialState, {
  [events.RECEIVE_IMPORT_LIST]: (state, action) =>
    receiveReducer(state, action.payload),
  [events.SEARCH_IMPORT_LIST]: search,
  [events.ADD_ONE_OPTION_IMPORT_LIST_FILTER]: addOneOptionFilter,
  [events.ADD_IMPORT_LIST_FILTER]: addFilter,
  [events.REMOVE_IMPORT_LIST_FILTER]: removeFilter,
  [events.CLEAR_IMPORT_LIST_FILTER]: clearFilter,
  [events.NEXT_PAGE_IMPORT_LIST]: nextPage,
  [events.PREVIOUS_PAGE_IMPORT_LIST]: previousPage,
  [events.IMPORT_LIST_IS_LOADING]: (state) => {
    const newState = { ...state };
    newState.isLoading = true;
    return newState;
  },
  [events.CHANGE_LIMIT_IMPORT_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.RECEIVE_IMPORT_DETAILS]: (state, action) => {
    const newState = { ...state };
    newState.importDetails = {
      ...state.importDetails,
      [action.importfileId]: action.list,
    };
    return newState;
  },
  [events.RECEIVE_IMPORT_SPIDER]: (state, action) => {
    const newState = { ...state, isLoading: false };
    const qsv = qs.parse(location.search);
    let formatedSpider = state.filtersAvailable.get('spiders').toJS();

    // Format spider
    if (action.filters.which) {
      formatedSpider = formatSpiderFilter(action.filters.which);
      newState.filtersAvailable = fromJS({
        ...action.filters,
        status: statusFilters,
        dates,
        supportStatus: supportStatusFilters,
        spiders: formatedSpider,
      });
    }

    const selectedSpider = (qsv.spiders && qsv.spiders.split(',')) || [];
    const spiders = formatSpiderFilter(selectedSpider).map((w) => ({
      label: w.label,
      value: w.value,
      checked: false,
    }));
    newState.filters = {
      ...newState.filters,
      spiders,
    };
    formatedSpider = formatedSpider.map((w) => {
      if (selectedSpider.includes(w.value)) {
        return { label: w.label, value: w.value, checked: true };
      }
      return { label: w.label, value: w.value, checked: false };
    });
    newState.filtersAvailable = newState.filtersAvailable.setIn(
      ['spiders'],
      fromJS(formatedSpider),
    );

    if (qsv.status) {
      const selectedStatus = (qsv.status && qsv.status.split(',')) || [];
      const status = selectedStatus.map((s) =>
        statusFilters.find((formatedStatus) => formatedStatus.value === s),
      );
      newState.filters = {
        ...newState.filters,
        status,
      };
    }

    if (qsv.supportStatus) {
      const selectedSupportStatus =
        (qsv.supportStatus && qsv.supportStatus.split(',')) || [];
      const supportStatus = selectedSupportStatus.map((s) =>
        supportStatusFilters.find(
          (formatedSupportStatus) => formatedSupportStatus.value === s,
        ),
      );
      newState.filters = {
        ...newState.filters,
        supportStatus,
      };
    }

    if (qsv.filter_ids) {
      const filter_ids = [
        {
          label: `Show only ${qsv.filter_ids}`,
          value: qsv.filter_ids,
          checked: true,
        },
      ];
      newState.filters = {
        ...newState.filters,
        filter_ids,
      };
    } else {
      const filter_ids = [];
      newState.filters = {
        ...newState.filters,
        filter_ids,
      };
    }

    if (qsv.filter_organization_ids) {
      const organization = qsv.filter_organization_ids
        .split(',')
        .map((organizationID) => {
          const selectedOrganization = newState.filtersAvailable
            .get('organization')
            .find((o) => o.get('id') === parseInt(organizationID, 10));
          if (selectedOrganization) {
            return {
              checked: true,
              label: selectedOrganization.get('nameLegal'),
              value: selectedOrganization.get('id'),
            };
          }
          return null;
        })
        .filter((org) => org || false);
      newState.filters = {
        ...newState.filters,
        organization,
      };
    }

    // Dates
    const datesAvailable = [
      { name: 'date', value: qsv.date || undefined, label: 'Date' },
      {
        name: 'date_from',
        value: qsv.date_from || undefined,
        label: 'Date from',
      },
      { name: 'date_to', value: qsv.date_to || undefined, label: 'Date to' },
    ];
    newState.filtersAvailable = newState.filtersAvailable.setIn(
      ['dates'],
      fromJS(datesAvailable),
    );
    const datesSelected = [];
    datesAvailable.forEach((d) => {
      if (d.value) {
        datesSelected.push(d);
      }
    });
    newState.filters = {
      ...newState.filters,
      dates: datesSelected,
    };

    return newState;
  },
});
