import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

export const REDUCER_KEY = 'fields';

function selectState(state) {
  return state[MODULE_REDUCER_KEY][REDUCER_KEY];
}

export const selectFields = createSelector(
  selectState,
  (state) => state.fields,
);

export const selectFieldOptions = createSelector(selectFields, (fields) =>
  fields
    ? fields
        .map((f) => ({
          id: f.get('id'),
          label: f.get('name'),
          value: f.get('id'),
        }))
        .toArray()
    : [],
);

export const selectTags = createSelector(selectState, (state) => state.tags);

export const selectRoots = createSelector(selectState, (state) => state.roots);

export const selectFieldModified = createSelector(
  selectState,
  (state) => state.editedField !== state.sourceField,
);

export const selectSourceField = createSelector(
  selectState,
  (state) => state.sourceField,
);

export const selectEditedField = createSelector(
  selectState,
  (state) => state.editedField,
);

export const selectSaveInProgress = createSelector(
  selectState,
  (state) => state.saveInProgress,
);

export const selectDeleteInProgress = createSelector(
  selectState,
  (state) => state.deleteInProgress,
);

export const selectShouldBlockSaveField = createSelector(
  selectState,
  (state) => state.shouldBlockSaveField,
);
