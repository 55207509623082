import classnames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import RangePicker from 'react-daterange-picker';

interface Props {
  baseTestId: string;
  value: Moment;
  minimumDate?: Moment;
  onChange: (value: Moment) => void;
  disabled?: boolean;
}

export const DeadLineSelector = (props: Props) => {
  const {
    value,
    onChange,
    disabled = false,
    baseTestId,
    minimumDate = moment().add(1, 'day').startOf('day'),
  } = props;

  const [pickerIsShown, setPickerIsShown] = useState(false);

  const togglePicker = () => {
    setPickerIsShown(!pickerIsShown);
  };

  const handleSelect = (v: Moment) => {
    togglePicker();
    onChange(v);
  };

  let dateElt: React.ReactNode;
  if (disabled) {
    dateElt = <span>{value.format()}</span>;
  } else if (pickerIsShown) {
    dateElt = (
      <RangePicker
        numberOfCalendars={1}
        selectionType="single"
        onSelect={handleSelect}
        value={value}
        minimumDate={minimumDate}
      />
    );
  } else {
    const classes = classnames('deadline-toggleButton', 'btn', 'btn-secondary');
    dateElt = (
      <button
        data-testid={`${baseTestId}-toggleButton`}
        className={classes}
        onClick={togglePicker}
      >
        {value.format('ll')}
      </button>
    );
  }

  return (
    <>
      {' '}defaulting to {dateElt}
    </>
  );
};
