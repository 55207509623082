import { notificationError } from 'actions/notifications';
import { immutableAuthApi } from 'resources/authApi';

import { STATUS_CREATED, TYPE_ATTRIBUTION } from '../constants';
import * as events from '../constants/events';
import {
  selectActionnableClaimsOnly,
  selectCurrentPage,
  selectLimit,
  selectSearchBrand,
  selectSearchUsername,
} from '../selectors';

export const setLoading = (value) => ({
  type: events.BRANDCLAIMS_LOADING,
  value,
});

export const setActionInProgress = (value) => ({
  type: events.BRANDCLAIMS_ACTION_IN_PROGRESS,
  value,
});

export const setActionnableClaimsOnly = (value) => ({
  type: events.BRANDCLAIMS_SET_ACTIONNABLE_CLAIMS_ONLY,
  value,
});

// Pagination

export const nextPage = () => ({
  type: events.BRANDCLAIMS_NEXT_PAGE,
});

export const previousPage = () => ({
  type: events.BRANDCLAIMS_PREVIOUS_PAGE,
});

export const onLimitChange = (limit) => ({
  type: events.BRANDCLAIMS_CHANGE_LIMIT,
  limit,
});

// Search

export const searchByUsername = (search) => ({
  type: events.USERNAME_SEARCH_UPDATE,
  search,
});

export const searchByBrand = (search) => ({
  type: events.BRAND_SEARCH_UPDATE,
  search,
});

// List

const constructFilters = (state) => {
  const filters = {};

  const searchUsername = selectSearchUsername(state);
  const searchBrand = selectSearchBrand(state);
  const actionnableClaimsOnly = selectActionnableClaimsOnly(state);

  if (searchUsername && searchUsername.length) {
    filters.username = searchUsername;
  }
  if (searchBrand && searchBrand.length) {
    filters.brandName = searchBrand;
  }
  if (actionnableClaimsOnly) {
    filters.status = STATUS_CREATED.id;
    filters.type = TYPE_ATTRIBUTION.id;
  }

  return filters;
};

const computeOffset = (state) => {
  const limit = selectLimit(state);
  const currentPage = selectCurrentPage(state);
  return limit * (currentPage - 1);
};

export const getBrandClaims =
  (resetPagination = false) =>
  async (dispatch, getState) => {
    const state = getState();

    const filters = constructFilters(state);
    const queries = {
      withTotalResults: true,
    };

    const limit = selectLimit(state);
    const offset = resetPagination ? 0 : computeOffset(state);

    dispatch(setLoading(true));
    try {
      const response = await immutableAuthApi.UserClaimProductBrandList(
        queries,
        filters,
        offset,
        limit,
        false,
      );

      dispatch({
        type: events.BRANDCLAIMS_RECEIVED,
        list: response.data.data,
        totalResults: response.data.totalResults,
        resetPagination,
      });
      dispatch(setLoading(false));
    } catch (exception) {
      dispatch(
        notificationError(
          'An error occured while loading the list, refresh the page and retry',
        ),
      );
      dispatch(setLoading(false));
    }
  };

// Actions

export const updateBrandClaimStatus = (claimId, status) => async (dispatch) => {
  dispatch(setActionInProgress(true));
  try {
    await immutableAuthApi.UserClaimProductBrandUpdate(claimId, status);
    dispatch(setActionInProgress(false));
    dispatch(getBrandClaims(true));
  } catch (exception) {
    dispatch(notificationError('An error occured, refresh the page and retry'));
    dispatch(setActionInProgress(false));
  }
};
