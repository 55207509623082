import 'font-awesome/css/font-awesome.min.css';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

// Display
import { Button } from '@alkem/react-ui-button';
import { Radio } from '@alkem/react-ui-inputs';

import { OrganizationAutocomplete } from 'components/autocomplete';
import { DashboardAutocomplete } from 'components/autocomplete/custom/dashboard-autocomplete';
import { parseBoolean } from 'utils';
import { separateActions } from 'utils/redux';

// Action
import { getDashboardList } from '../actions/dashboard';
import {
  clearReportingTable,
  downloadReportingTable,
  downloadReportingTableAsAdmin,
  getReportingTable,
  getReportingTableAsAdmin,
  getReportingTableTransifex,
  getReportingTableTransifexAsAdmin,
} from '../actions/reporting-table';
import { selectAvsFilter, selectDashboardList } from '../selectors/dashboard';
import { getIsDownloading } from '../selectors/reporting-table';

import './dashboard-list.scss';
import { defaultFilters } from './default-filters';
import ToggableElement from './form-utils/toggable-element';

const mapDispatchToProps = {
  getDashboardList,
  clearReportingTable,
  getReportingTable,
  getReportingTableAsAdmin,
  getReportingTableTransifex,
  getReportingTableTransifexAsAdmin,
  downloadReportingTable,
  downloadReportingTableAsAdmin,
};

const mapStateToProps = (state) => ({
  isDownloading: getIsDownloading(state),
  dashboardList: selectDashboardList(state),
  avsFilter: selectAvsFilter(state),
});

// Class
export class DashboardList extends PureComponent {
  static propTypes = {
    params: PropTypes.object.isRequired,
    isDownloading: PropTypes.bool,
    dashboardList: PropTypes.object,
    avsFilter: PropTypes.object,
    actions: PropTypes.shape({
      getDashboardList: PropTypes.func.isRequired,
      clearReportingTable: PropTypes.func.isRequired,
      getReportingTable: PropTypes.func.isRequired,
      getReportingTableAsAdmin: PropTypes.func.isRequired,
      getReportingTableTransifex: PropTypes.func.isRequired,
      getReportingTableTransifexAsAdmin: PropTypes.func.isRequired,
      downloadReportingTable: PropTypes.func.isRequired,
      downloadReportingTableAsAdmin: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: true,
      dashboard: null,
      orga: [],
      filters: [],
    };
    this.state.isAdmin = this.props.params.isAdmin;
    if (this.props.params.filter) {
      const filters = JSON.parse(this.props.params.filter);

      const selectedFilter = filters.reduce((acc, v) => {
        acc[v.key] = v;
        return acc;
      }, {});

      this.state.filters = selectedFilter;
    }
  }

  componentDidMount() {
    this.props.actions.getDashboardList();
  }

  componentDidUpdate(prevProps) {
    const { dashboardList, params } = this.props;
    if (
      dashboardList !== prevProps.dashboardList ||
      params !== prevProps.params
    ) {
      if (!this.state.dashboard) {
        const dh = dashboardList.filter(
          (x) => x.get('code') === params.dashboard,
        );
        if (dh.size !== 0) {
          // eslint-disable-next-line
          this.setState({
            dashboard: dh.get(0).toJS(),
          });
        }
      }
    }
    // TODO orga
  }

  onSelectOrga = (value) => {
    let res = [value];
    if (this.state.isAdmin) {
      res = res.concat(this.state.orga);
    }
    this.setState({
      orga: res,
    });
  };

  onUnSelectOrga = () =>
    this.setState({
      orga: null,
    });

  onSelectDashboard = (value) => {
    this.setState({
      dashboard: value.value,
      orga: [],
    });
  };

  onUnSelectDashboard = () => {
    this.setState({
      dashboard: null,
      orga: [],
    });
  };

  onTypeChange = (event) => {
    this.setState({
      dashboard: null,
      orga: null,
      isAdmin: parseBoolean(event.currentTarget.value),
    });
  };

  onClickFilter = (obj, key, filter, isIn) => () => {
    this.props.actions.clearReportingTable();
    if (!isIn) {
      this.setState((prevState) => {
        const filters = { ...prevState.filters };
        filters[key] = filter;
        return { filters };
      });
    } else {
      this.setState((prevState) => {
        const filters = { ...prevState.filters };
        delete filters[key];
        return { filters };
      });
    }
  };

  computeTable = () => {
    if (!this.state.isAdmin) {
      this.props.actions.getReportingTable(
        this.state.orga[0].key,
        this.state.dashboard,
        this.state.filters,
      );
    } else {
      this.props.actions.getReportingTableAsAdmin(
        this.state.dashboard,
        this.state.filters,
      );
    }
  };

  linkTable = () => {
    const filters = Object.values(this.state.filters);

    const urlparam = !this.state.isAdmin
      ? {
          a: false,
          o: this.state.orga[0].key,
          d: this.state.dashboard.code,
          f: JSON.stringify(filters).replace('/ /g', ''),
        }
      : {
          a: true,
          d: this.state.dashboard.code,
          f: JSON.stringify(filters).replace('/ /g', ''),
        };

    const urlParameters = Object.entries(urlparam)
      .map((e) => e.join('='))
      .join('&');

    const url = `./reporting-handling?${urlParameters}`;
    const win = window.open(url, '_blank');
    win.focus();
  };

  computeTableTransifex = () => {
    if (!this.state.isAdmin) {
      this.props.actions.getReportingTableTransifex(
        this.state.orga[0].key,
        this.state.dashboard,
        this.state.filters,
      );
    } else {
      this.props.actions.getReportingTableTransifexAsAdmin(
        this.state.dashboard,
        this.state.filters,
      );
    }
  };

  downloadExl = () => {
    if (!this.state.isAdmin) {
      this.props.actions.downloadReportingTable(
        this.state.orga[0].key,
        this.state.dashboard,
        this.state.filters,
      );
    } else {
      this.props.actions.downloadReportingTableAsAdmin(
        this.state.orga.map((orga) => orga.key),
        this.state.dashboard,
        this.state.filters,
      );
    }
  };

  createFilter = (filter, idx) => {
    const selected =
      this.state.filters &&
      Object.keys(this.state.filters).indexOf(filter.key) > -1;

    return (
      <div
        key={`FilterList_${idx}`}
        onClick={this.onClickFilter(this, filter.key, filter, selected)}
        style={{ cursor: 'pointer' }}
      >
        <i
          className={selected ? 'fa fa-check-square ' : 'fa fa-square-o '}
          aria-hidden="true"
        />
        &nbsp; {filter.name}
      </div>
    );
  };

  createFilterGroup = (filters) => {
    const groupFilter = filters
      .filter((it) => it.group)
      .map((it) => ({ [it.group]: [it] }))
      .reduce((acc, it) => {
        const key = Object.keys(it)[0];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(it[key][0]);
        return acc;
      });

    return Object.keys(groupFilter).map((key) => (
      <ToggableElement
        key={key}
        body={<div>{groupFilter[key].map(this.createFilter)}</div>}
        header={
          <h4>
            {key} ({groupFilter[key].length}){' '}
          </h4>
        }
        hidden
        showCaret
      />
    ));
  };

  displayDashboardConfigurationItem(header, elements) {
    return (
      <div>
        <span> {header} </span>
        <br />
        {elements.map((value) => (
          <span key={JSON.stringify(value)}>
            <a href=""> {value}</a>
            <br />
          </span>
        ))}
        <br />
      </div>
    );
  }

  displayDashboardConfiguration() {
    if (!this.state.dashboard) {
      return null;
    }
    const dashboardReferential = fromJS(this.state.dashboard);
    return (
      <div className="ReportingHandlingMenu--line">
        {this.displayDashboardConfigurationItem(
          'Group Head',
          dashboardReferential.getIn(
            ['data', 'reporting', 'aggregations', 'group_all'],
            [],
          ),
        )}
        {this.displayDashboardConfigurationItem(
          'Group Detail',
          dashboardReferential.getIn(
            ['data', 'reporting', 'aggregations', 'group_detail'],
            [],
          ),
        )}
        {this.displayDashboardConfigurationItem(
          'Group By',
          dashboardReferential.getIn(
            ['data', 'reporting', 'aggregations', 'group_by'],
            [],
          ),
        )}
        {this.displayDashboardConfigurationItem(
          'Selected',
          dashboardReferential.getIn(
            ['data', 'reporting', 'aggregations', 'selected'],
            [],
          ),
        )}
      </div>
    );
  }

  displayDashboardType() {
    return (
      <div className="ReportingHandlingMenu--line">
        <h3>Type</h3>
        <div>
          <Radio
            id="ReportingHandlingMenu__Type"
            value={this.state.isAdmin}
            onChange={this.onTypeChange}
            options={[
              { label: 'Admin', value: true },
              { label: 'LogAs', value: false },
            ]}
          />
        </div>
      </div>
    );
  }

  displayDashboardSelected() {
    if (this.props.dashboardList.size === 0) {
      return (
        <div className="ReportingHandlingMenu--line">
          <h3>Dashboard</h3>
          <div style={{ textAlign: 'center' }}>
            <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true" />
          </div>
        </div>
      );
    }
    return (
      <div className="ReportingHandlingMenu--line">
        <h3>Dashboard</h3>
        <div>
          <DashboardAutocomplete
            id="DashboardListDashboardSelectAutoComplet"
            value={this.state.dashboard ? [this.state.dashboard] : []}
            onSelect={this.onSelectDashboard}
            onUnselect={this.onUnSelectDashboard}
            placeholder="Select a dashboard"
            searchOnClick
            selector={this.formatDashboardAutocompleteValue}
            permissionType={this.state.isAdmin ? 1 : 2}
          />
        </div>
      </div>
    );
  }

  displaySelectOrganisation() {
    if (this.state.isAdmin) {
      return null;
    }
    return (
      <div className="ReportingHandlingMenu--line">
        <h3>Orga</h3>
        <div>
          <OrganizationAutocomplete
            id="ReportingTableOrgaisationSelectAutoComplet"
            value={this.state.orga ? this.state.orga : []}
            onSelect={this.onSelectOrga}
            onUnselect={this.onUnSelectOrga}
            placeholder="Select an organization"
            searchOnClick
            multiple={this.state.isAdmin}
            selector={this.formatOrganizationAutocompleteValue}
            disabled={!this.state.dashboard}
            filters={
              this.state.dashboard
                ? {
                    types: this.state.dashboard.data.reporting.orga_type,
                  }
                : {}
            }
          />
        </div>
      </div>
    );
  }

  displaySelectFilter() {
    let filters = defaultFilters;

    filters = _.filter(filters, (el) =>
      this.state.isAdmin ? true : !el.admin,
    );
    filters = filters.concat(Object.values(this.state.filters));

    filters = filters.concat(this.props.avsFilter.toJS());

    filters.sort((a, b) => a.key.localeCompare(b.key));
    let oldkey = '';
    filters = filters.filter((x) => {
      const isCopy = x.key === oldkey;
      oldkey = x.key;
      return !isCopy;
    });

    return (
      <div className="ReportingHandlingMenu--line">
        <ToggableElement
          key="FilterGroupList_None"
          body={
            <div>
              {filters.filter((it) => !it.group).map(this.createFilter)}
            </div>
          }
          header={
            <h4>Standard ({filters.filter((it) => !it.group).length}) </h4>
          }
          hidden
          showCaret
        />
        {this.createFilterGroup(filters)}
        <br />
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="ReportingHandlingMenu card">
        <div className="ReportingHandlingMenu--line ReportingHandlingMenu--button">
          <Button
            id="ReportingHandlingMenu__download"
            className="button"
            onClick={this.downloadExl}
            content="Download"
            secondary
            displaySpinner={this.props.isDownloading}
            disabled={
              this.props.isDownloading ||
              !this.state.dashboard ||
              (!this.state.isAdmin && !this.state.orga)
            }
          />
          <Button
            id="ReportingHandlingMenu__transifex"
            className="button"
            onClick={this.computeTableTransifex}
            content="Transifex"
            secondary
            disabled={
              !this.state.dashboard || (!this.state.isAdmin && !this.state.orga)
            }
          />

          <Button
            id="ReportingHandlingMenu__go"
            className="button"
            onClick={this.computeTable}
            content="Compute"
            primary
            disabled={
              !this.state.dashboard || (!this.state.isAdmin && !this.state.orga)
            }
          />
          <Button
            id="ReportingHandlingMenu__link"
            className="button"
            onClick={this.linkTable}
            content="Link"
            primary
            disabled={
              !this.state.dashboard || (!this.state.isAdmin && !this.state.orga)
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="left-menu">
        <ToggableElement
          body={
            <div>
              {this.displayDashboardType()}
              {this.displayDashboardSelected()}
              {this.displaySelectOrganisation()}
            </div>
          }
          containerCssClasses="ReportingHandlingMenu card"
          headerCssClasses="ReportingHandlingMenu--line"
          bodyCssClasses=""
          header={<h2>Reporting selection</h2>}
        />

        {this.renderButtons()}

        <ToggableElement
          body={this.displaySelectFilter()}
          containerCssClasses="ReportingHandlingMenu card without--overlay"
          headerCssClasses="ReportingHandlingMenu--line"
          bodyCssClasses=""
          header={<h2>Filters</h2>}
          hidden
        />
        <ToggableElement
          body={this.displayDashboardConfiguration()}
          containerCssClasses="ReportingHandlingMenu card without--overlay"
          headerCssClasses="ReportingHandlingMenu--line"
          bodyCssClasses=""
          header={<h2>Dashboard Config</h2>}
          hidden
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(DashboardList);
