import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { SimpleSelect } from '@alkem/react-ui-select';

import {
  CountryAutocomplete,
  RegExpAutocomplete,
} from 'components/autocomplete';
import { SOURCES } from 'constants/organization';
import { selectFirstNetwork } from 'modules/organization-list/selectors/networks';
import { createGroup } from 'modules/organization-page/actions/infos';
import OrganizationGroup from 'modules/organization-page/components/infos/organization-group';
import { isImmutable } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import { resetOrganizationCreate } from '../../actions';
import { updateFormInput } from '../../actions/form';
import {
  MANUFACTURER,
  PRIVATE_LABEL,
  RETAILER,
  THIRD_PARTY,
} from '../../constants/org-types';
import {
  selectOrgCountries,
  selectOrgGroup,
  selectOrgName,
  selectOrgNetwork,
  selectOrgSource,
  selectOrgType,
} from '../../selectors/form';

import './index.scss';
import { NetworkAutocomplete, formatNetwork } from './network-autocomplete';
import TextInput from './text-input';

const mapStateToProps = (state) => ({
  name: selectOrgName(state),
  type: selectOrgType(state),
  network: selectOrgNetwork(state),
  group: selectOrgGroup(state),
  countries: selectOrgCountries(state),
  firstNetwork: selectFirstNetwork(state),
  user: state.user,
  source: selectOrgSource(state),
});

const mapDispatchToProps = {
  updateFormInput,
  resetOrganizationCreate,
  createGroup,
};

class OrganizationCreateForm extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    type: ImmutablePropTypes.mapContains({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
    }),
    network: ImmutablePropTypes.mapContains({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
    }),
    group: ImmutablePropTypes.map,
    countries: ImmutablePropTypes.list,
    firstNetwork: ImmutablePropTypes.map,
    actions: PropTypes.shape({
      updateFormInput: PropTypes.func.isRequired,
      resetOrganizationCreate: PropTypes.func.isRequired,
      createGroup: PropTypes.func.isRequired,
    }).isRequired,
    user: ImmutablePropTypes.map,
    source: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTypeSelect = this.onTypeSelect.bind(this);
    this.onTypeUnselect = this.onTypeUnselect.bind(this);
    this.onNetworkSelect = this.onNetworkSelect.bind(this);
    this.onNetworkUnselect = this.onNetworkUnselect.bind(this);
    this.onAddNetwork = this.onAddNetwork.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.resetOrganizationCreate();
  }

  onNameChange(value) {
    this.props.actions.updateFormInput({
      key: 'name',
      value,
    });
  }

  onTypeSelect(type) {
    this.props.actions.updateFormInput({
      key: 'type',
      value: fromJS(type),
    });
  }

  onTypeUnselect() {
    this.props.actions.updateFormInput({
      key: 'type',
      value: fromJS(MANUFACTURER),
    });
  }

  onNetworkSelect(network) {
    this.props.actions.updateFormInput({
      key: 'network',
      value: fromJS(network),
    });
  }

  onNetworkUnselect() {
    this.props.actions.updateFormInput({
      key: 'network',
      value: fromJS(formatNetwork(this.props.firstNetwork.toJS())),
    });
  }

  onAddNetwork(network) {
    this.props.actions.updateFormInput({
      key: 'network',
      value: fromJS({
        label: `New network: ${network}`,
        value: -1,
        key: network,
        isNewNetwork: true,
      }),
    });
  }

  onCreateGroup = async (name) => {
    const response = await this.props.actions.createGroup(name);
    this.onGroupSelect(fromJS(response.data));
  };

  onGroupSelect = (group) => {
    this.props.actions.updateFormInput({
      key: 'group',
      value: group,
    });
  };

  onCountrySelect = (countries) => {
    this.props.actions.updateFormInput({
      key: 'worksOnTargetMarkets',
      value: fromJS(countries.map((country) => country)),
    });
  };

  onSourceSelect = (source) =>
    this.props.actions.updateFormInput({
      key: 'source',
      value: source.id,
    });

  isRetailer() {
    return !!this.props.type && this.props.type.get('value') === RETAILER.value;
  }

  formatValueForAutocomplete(value) {
    return value && value.toJS ? [value.toJS()] : [];
  }

  formatValueForCountryAutocomplete(value) {
    if (!value) {
      return [];
    }
    return (isImmutable(value) ? value.toJS() : value).map(({ id }) => id);
  }

  render() {
    return (
      <div className="OrganizationCreateForm">
        <div className="OrganizationCreateForm__line">
          <div className="OrganizationCreateForm__label">Organization name</div>
          <div className="OrganizationCreateForm__inputs">
            <TextInput
              className="OrganizationCreateForm__textInput form-control"
              placeholder="Organization name"
              value={this.props.name}
              onChange={this.onNameChange}
            />
          </div>
        </div>
        <div className="OrganizationCreateForm__line">
          <div className="OrganizationCreateForm__label">Type</div>
          <div className="OrganizationCreateForm__inputs">
            <RegExpAutocomplete
              id="organization-create-type-input"
              value={this.formatValueForAutocomplete(this.props.type)}
              list={[MANUFACTURER, RETAILER, PRIVATE_LABEL, THIRD_PARTY]}
              onSelect={this.onTypeSelect}
              onUnselect={this.onTypeUnselect}
              searchOnClick
            />
          </div>
        </div>
        <div className="OrganizationCreateForm__line">
          <div className="OrganizationCreateForm__label">Network</div>
          <div className="OrganizationCreateForm__inputs">
            <NetworkAutocomplete
              id="organization-create-network-input"
              value={this.formatValueForAutocomplete(this.props.network)}
              onSelect={this.onNetworkSelect}
              onUnselect={this.onNetworkUnselect}
              searchOnClick
              onAdd={this.onAddNetwork}
            />
          </div>
        </div>
        {this.isRetailer() && (
          <div className="OrganizationCreateForm__line">
            <div className="OrganizationCreateForm__label">Group</div>
            <div className="OrganizationCreateForm__inputs">
              <OrganizationGroup
                id="organization-create-group"
                group={this.props.group}
                onSelect={this.onGroupSelect}
                onCreate={this.onCreateGroup}
              />
            </div>
          </div>
        )}
        <div className="OrganizationCreateForm__line">
          <div className="OrganizationCreateForm__label">Country</div>
          <div className="OrganizationCreateForm__inputs">
            <CountryAutocomplete
              id="organization-create-country-autocomplete"
              value={this.formatValueForCountryAutocomplete(
                this.props.countries,
              )}
              onChange={this.onCountrySelect}
              user={this.props.user}
              isSearchable
              isClearable={false}
              isMulti
            />
          </div>
        </div>
        <div className="OrganizationCreateForm__line">
          <div className="OrganizationCreateForm__label">Source</div>
          <div className="OrganizationCreateForm__inputs">
            <SimpleSelect
              id="organization-create-source-select"
              options={SOURCES}
              value={SOURCES.find((s) => s.id === this.props.source)}
              onSelect={this.onSourceSelect}
              autoSize
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(OrganizationCreateForm);
