import { Map as ImmutableMap } from 'immutable';
import { useDispatch } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-inputs';

import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  SHARING_UNIT,
  TARIFF,
} from 'constants/fields';

import { updateOrganizationFields } from '../../../actions';

interface Props {
  field: ImmutableMap<string, any>;
  readOnly?: boolean;
  organization: ImmutableMap<string, any>;
  fieldEntity: string;
}

export const OverridableCheckbox = (props: Props) => {
  const { field, organization, fieldEntity, readOnly = false } = props;

  const dispatch = useDispatch();

  if (
    field.get('specific') ||
    ![CONSUMER_UNIT, DISPLAY_UNIT, SHARING_UNIT, TARIFF].includes(fieldEntity)
  ) {
    return null;
  }

  const fieldName = field.get('name');
  const usesFields = organization.get('usesFields');

  const usedField = usesFields
    .filter(
      (f) => f.get('name') === fieldName && f.get('entityType') === fieldEntity,
    )
    .first();

  let label = 'specific value';
  if (SHARING_UNIT === fieldEntity) {
    label = 'product specific (in templates)';
  } else if (TARIFF === fieldEntity) {
    label = 'product specific';
  }

  let disabled = readOnly;
  if (
    ['priceWaterfalls', 'hierarchyProduct', 'hierarchy'].includes(
      field.get('name'),
    )
  ) {
    disabled = true;
    label = 'always product specific';
  }

  const handleChange = (event) => {
    const overridable = event.target.checked;
    updateOrganizationFields(
      usesFields.update(
        usesFields.findIndex(
          (field) =>
            field.get('name') === fieldName &&
            field.get('entityType') === fieldEntity,
        ),
        (field) => field.set('overridable', overridable),
      ),
    )(dispatch);
  };

  return (
    <span className="OrganizationUsesFieldsView__overridable">
      <Checkbox
        id={`${fieldEntity}-${field.get('name')}-overridable`}
        checked={usedField.get('overridable')}
        onChange={handleChange}
        label={label}
        disabled={disabled}
      />
    </span>
  );
};
