import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { TRANSACTION_EXPLORER_DASHBOARD_STORE_KEY } from './reducer';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][TRANSACTION_EXPLORER_DASHBOARD_STORE_KEY];
}

export const selectTransactionId = createSelector(selectState, (state) =>
  state.get('transactionId'),
);

export const selectLinks = createSelector(selectState, (state) =>
  state.get('links'),
);

export const selectLayers = createSelector(selectState, (state) =>
  state.get('layers'),
);

export const selectTransactions = createSelector(selectState, (state) =>
  state.get('transactions'),
);

export const selectLoadedTraces = createSelector(selectState, (state) =>
  state.get('tracesLoaded'),
);

export const selectSelected = createSelector(selectState, (state) =>
  state.get('selected'),
);

export const selectModal = createSelector(selectState, (state) =>
  state.get('modal'),
);
