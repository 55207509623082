import { List, Map } from 'immutable';

import {
  APPLICATION_SAVED,
  CREATE_APPLICATION,
  DISABLE_ORGANIZATION_SUCCESS,
  ENABLE_ORGANIZATION_SUCCESS,
  FETCH_DATA_SUCCESS,
  MARK_APPLICATION_EDITED,
  NEW_APPLICATION_ID,
  RESET_APPLICATION,
  SAVING_APPLICATION,
  SELECT_APPLICATION,
  SET_APPLICATIONS_LOADING,
  SHOW_APP_FORM_ERRORS,
} from 'modules/applications/constants';
import { RECEIVE_LISTCOLUMN_REFERENTIAL } from 'modules/organization-page/constants/events';
import { createReducer } from 'utils/reducers';

import { mapValuesToClient, newAppData } from '../utils/data';

export const initialState = Map({
  applications: Map(),
  organizations: Map(),
  appsHaveOrganizations: Map(),
  formErrors: Map(),
  selected: null,
  pristine: true,
  saving: false,
  created: false,
  loading: false,
  listColumnsReferential: List(),
});

export default createReducer(initialState, {
  [SET_APPLICATIONS_LOADING]: setApplicationLoading,
  [FETCH_DATA_SUCCESS]: convertAndStoreFetchedData,
  [SELECT_APPLICATION]: selectApplication,
  [RESET_APPLICATION]: resetApplication,
  [MARK_APPLICATION_EDITED]: markApplicationEdited,
  [SAVING_APPLICATION]: savingApplication,
  [APPLICATION_SAVED]: applicationSaved,
  [SHOW_APP_FORM_ERRORS]: showFormErrors,
  [CREATE_APPLICATION]: createApplication,
  [ENABLE_ORGANIZATION_SUCCESS]: enableOrganization,
  [DISABLE_ORGANIZATION_SUCCESS]: disableOrganization,
  [RECEIVE_LISTCOLUMN_REFERENTIAL]: receiveColumns,
});

function setApplicationLoading(state, action) {
  return state.set('loading', action.loading);
}

function receiveColumns(state, action) {
  return state.set('listColumnsReferential', action.referential);
}

function convertAndStoreFetchedData(state, action) {
  const nextState = state
    .set('organizations', action.organizations)
    .set('appsHaveOrganizations', action.appsHaveOrganizations)
    .set('categories', action.categories);
  return action.applications.reduce(setApplication, nextState);
}

function setApplication(state, app) {
  const organizations = state.get('organizations');
  const appsHaveOrganizations = state.get('appsHaveOrganizations');
  const appData = mapValuesToClient(app, organizations, appsHaveOrganizations);
  return state.setIn(['applications', app.id], appData);
}

function selectApplication(state, action) {
  return state.set('selected', action.applicationId);
}

function resetApplication(state) {
  let newState = state;
  if (state.get('selected') === NEW_APPLICATION_ID) {
    newState = newState
      .set('selected', null)
      .deleteIn(['applications', NEW_APPLICATION_ID]);
  }
  return newState.set('pristine', true).set('formErrors', Map());
}

function savingApplication(state) {
  return state.set('saving', true);
}

function applicationSaved(state, action) {
  return state
    .set('saving', false)
    .set('pristine', true)
    .set('formErrors', Map())
    .set('selected', action.application.id)
    .set('created', action.created)
    .setIn(
      ['applications', action.application.id],
      mapValuesToClient(
        action.application,
        state.get('organizations'),
        state.get('appsHaveOrganizations'),
      ),
    )
    .deleteIn(['applications', NEW_APPLICATION_ID]);
}

function markApplicationEdited(state) {
  return state.set('pristine', false);
}

function showFormErrors(state, action) {
  return state.set('formErrors', Map(action.errors)).set('saving', false);
}

function createApplication(state) {
  const newApp = newAppData();
  return state
    .setIn(['applications', NEW_APPLICATION_ID], newApp)
    .set('selected', NEW_APPLICATION_ID);
}

function enableOrganization(state, { payload }) {
  // Find full orga in State
  const fullOrganization = state
    .get('organizations')
    .find((o) => o.id === payload.organization.id);
  return state.setIn(
    [
      'applications',
      payload.applicationId,
      'enabledFor',
      String(payload.organization.id),
    ],
    Map({
      active: true,
      nameLegal: payload.organization.nameLegal,
      network: fullOrganization.network,
    }),
  );
}

function disableOrganization(state, { payload }) {
  return state.deleteIn([
    'applications',
    payload.applicationId,
    'enabledFor',
    String(payload.organizationId),
  ]);
}
