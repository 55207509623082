import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import LogoDark from 'images/logo-dark.png';

import './PublicLayout.scss';

class PublicLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  renderHeader() {
    return (
      <div className="PublicLayout__header text-xs-center">
        <img src={LogoDark} alt="Salsify" />
      </div>
    );
  }

  renderFooter() {
    return (
      <div className="PublicLayout__footer">
        <h3 className="PublicLayout__footerTitle text-xs-center">
          Stay ahead with smarter data
        </h3>
        <p className="PublicLayout__footerIcons text-xs-center">
          <a
            href="https://twitter.com/Salsify"
            className="PublicLayout__footerIcon mdi mdi-twitter"
          >
            Twitter
          </a>
          <a
            href="https://www.salsify.com/platform/supplierxm"
            className="PublicLayout__footerIcon mdi mdi-rss"
          >
            RSS
          </a>
        </p>
        <ul className="PublicLayout__footerLinks">
          <li className="PublicLayout__footerLink">
            <a href="https://www.salsify.com/about-salsify">About</a>
          </li>
          <li className="PublicLayout__footerLink">
            <a href="https://www.salsify.com/press-salsify">Press resources</a>
          </li>
          <li className="PublicLayout__footerLink">
            <a href="https://www.salsify.com/careers">Careers</a>
          </li>
          <li className="PublicLayout__footerLink">
            <a href="https://www.salsify.com/en/contact-alkemics">Contact</a>
          </li>
          <li className="PublicLayout__footerLink">
            <a href="https://www.salsify.com/privacy-policy">Privacy</a>
          </li>
        </ul>
        <div className="PublicLayout__footerLogo">
          <img className="logo" src={LogoDark} alt="Salsify" />
        </div>
      </div>
    );
  }

  render() {
    const { children } = this.props;
    return (
      <div className="PublicLayout">
        {this.renderHeader()}
        <div className="row">
          <div
            className={classNames(
              'col-lg-4',
              'offset-lg-4',
              'col-md-6',
              'offset-md-3',
              'col-xs-8',
              'offset-xs-2',
              'PublicLayout__children',
            )}
          >
            {children}
          </div>
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

export default PublicLayout;
