import { ModuleWrapper } from 'redux/register';

import { ORGANIZATION_LIST_STORE_KEY } from './constants/reducers';
import reducer from './reducers';
import OrganizationDashboardView from './views';

export default function OrganizationListContainer() {
  return (
    <ModuleWrapper reducerKey={ORGANIZATION_LIST_STORE_KEY} reducer={reducer}>
      <OrganizationDashboardView />
    </ModuleWrapper>
  );
}
