import Immutable from 'immutable';
import { memo, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { HeaderLayout } from '@alkem/react-layout';

import { ErrorFallback } from 'components/error/ErrorFallback';
import { PayloadModal } from 'components/payload-modal';
import * as routes from 'constants/routes';

import { closePayload, loadFilters } from './actions';
import { TransactionExplorerList } from './components/list';
import { selectModal } from './selectors';

export const TransactionExplorerView = memo(() => {
  const dispatch = useDispatch();
  const modal = useSelector<any, Immutable.Map<string, any>>(selectModal);
  const [searchParams] = useSearchParams();
  const transaction_id = searchParams.get('transaction_id') as string;

  useEffect(() => {
    const transactionId = Number(transaction_id);
    dispatch(loadFilters(transactionId));
  }, [dispatch, transaction_id]);

  return (
    <>
      <HeaderLayout
        title="Transaction explorer"
        backHref={routes.home}
        backMessage="Back home"
        isTitleSmall
      />
      <ErrorBoundary
        FallbackComponent={({ error }) => <ErrorFallback error={error} />}
      >
        <div className="container-fluid row">
          <div className="col-md-12">
            <TransactionExplorerList />
          </div>
        </div>
        {modal.get('show') && (
          <PayloadModal
            payload={modal.get('payload')}
            onClose={() => dispatch(closePayload())}
          />
        )}
      </ErrorBoundary>
    </>
  );
});
