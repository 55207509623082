import { List } from 'immutable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  LegacyAutocompleteProps,
  RegExpAutocomplete,
} from 'components/autocomplete';
import { getNetworks } from 'modules/organization-list/actions/networks';

type Network = {
  id: number;
  name: string;
};

export const formatNetwork = (network) => ({
  label: network.name,
  value: network.id,
  key: `org-network-${network.id}`,
});

const transformData = (data: Network[]) => data.map(formatNetwork);

const NetworkAutocomplete = React.memo(function NetworkAutocomplete({
  onSelect,
  ...props
}: LegacyAutocompleteProps) {
  const [values, setValues] = useState<any[]>([]);
  const dispatch: (action) => Promise<List<Network>> = useDispatch();

  const fetchNetworks = useCallback(async () => {
    const data = await dispatch(getNetworks());
    const networks: any[] = data.toJS();
    setValues(networks);
    const [first] = networks;
    onSelect && onSelect(formatNetwork(first));
  }, [dispatch, onSelect]);

  useEffect(() => {
    fetchNetworks();
  }, [fetchNetworks]);

  return (
    <RegExpAutocomplete
      list={values}
      transformData={transformData}
      field={(item) => item.name}
      onSelect={onSelect}
      {...props}
    />
  );
});

export { NetworkAutocomplete };
