import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AddButton, Button } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';

import referentialApi from 'resources/referentialApi';

import { ReferentialAutocomplete } from '../autocomplete';

import './index.scss';

function getConst(name) {
  if (!name) {
    return null;
  }

  const lower = name.toLowerCase();
  return lower.slice(-1) === 's' ? lower : `${lower}s`;
}

function validateName(name) {
  return /^([A-Z][a-z0-9]+)+$/.test(name); // allow only CamelCase letters and numbers only based name
}

export default class ReferentialInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    value: PropTypes.array,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
    onUnselect: PropTypes.func,
    placeholder: PropTypes.string,
    fieldsUsingSameReferential: PropTypes.bool.isRequired,
    referentialWillNotBeCreatedWarning: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool,
  };

  static defaultProps = {
    className: 'ReferentialInput',
    placeholder: '',
    disabled: false,
    isRequired: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      showingNew: false,
      saving: false,
      newReferentialName: null,
    };
    this.showNew = this.showNew.bind(this);
    this.cancelNew = this.cancelNew.bind(this);
  }

  saveNew = () => {
    const name = this.state.newReferentialName;
    this.setState({ saving: true });
    referentialApi
      .ReferentialCreate(name)
      .then((response) => {
        const value = response.data.data;
        this.props.onSelect({
          value,
          key: value.id,
          label: value.label,
        });
        this.setState({
          showingNew: false,
          saving: false,
          newReferentialName: null,
        });
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };

  updateNewReferentialName = (event) => {
    const { value } = event.target;
    this.setState({
      newReferentialName: value || null,
    });
  };

  showNew() {
    this.setState({
      showingNew: true,
      saving: false,
    });
  }

  cancelNew() {
    this.setState({
      showingNew: false,
      newReferentialName: null,
    });
  }

  renderInput() {
    const { newReferentialName, saving } = this.state;
    const isValid = validateName(newReferentialName);
    const helpMessage =
      (isValid && newReferentialName && (
        <span>
          {' '}
          This referential will have const{' '}
          <code>{getConst(newReferentialName)}</code>
        </span>
      )) ||
      null;
    const errorMessage =
      (newReferentialName && !isValid && (
        <span>
          This name is invalid. Only letters and numbers are allowed, use
          CamelCase
        </span>
      )) ||
      null;
    return (
      <div>
        <Text
          id="new-referential-name"
          placeholder="Enter a referential name (use CamelCase)"
          invalid={isValid}
          onChange={this.updateNewReferentialName}
        />
        <div className="ReferentialInput__helpText">
          {helpMessage}
          {errorMessage}
        </div>
        <div className="ReferentialInput__actions">
          <Button
            primary
            id="ReferentialInput__save"
            onClick={this.saveNew}
            disabled={newReferentialName === null || !isValid}
            displaySpinner={saving}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
          <Button
            secondary
            id="ReferentialInput__cancel"
            onClick={this.cancelNew}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  renderAutocomplete() {
    return (
      <div>
        <ReferentialAutocomplete
          id="referential-input"
          {...this.props}
          searchOnClick
        />
        {this.renderWarning()}
        {!this.props.disabled && (
          <AddButton label="Add a referential" onClick={this.showNew} />
        )}
      </div>
    );
  }

  renderWarning() {
    const {
      fieldsUsingSameReferential,
      referentialWillNotBeCreatedWarning,
      isRequired,
      value,
    } = this.props;

    const isRequiredButEmpty = isRequired && isEmpty(value);
    if (
      !fieldsUsingSameReferential &&
      !referentialWillNotBeCreatedWarning &&
      !isRequiredButEmpty
    ) {
      return null;
    }

    return (
      <div className="ReferentialInput__warning">
        {isRequiredButEmpty && (
          <div>
            <i className="mdi mdi-exclamation" />
            This field is required
          </div>
        )}
        {fieldsUsingSameReferential && (
          <div>
            <i className="mdi mdi-exclamation" />
            This referential is already used in another field
          </div>
        )}
        {referentialWillNotBeCreatedWarning && (
          <div>
            <i className="mdi mdi-exclamation" />
            The referential will not be created. To create it use the button
            below.
          </div>
        )}
      </div>
    );
  }

  render() {
    const { showingNew } = this.state;
    return (
      <div className="ReferentialInput">
        {showingNew ? this.renderInput() : this.renderAutocomplete()}
      </div>
    );
  }
}
