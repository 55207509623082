export type ExportMappingPayloadCast =
  | 'boolean'
  | 'int'
  | 'float'
  | 'int positive'
  | 'float positive'
  | 'string'
  | 'text'
  | 'gdsn_node'
  | 'list'
  | 'date'
  | 'iso_date'
  | 'entity';

export type Field = {
  id: number;
  name: string;
  displayInfo?: {
    label: string;
  };
  children?: Field[];
  tags?: string[];
  referential?: string;
  declinable_by?: string;
  type?: ExportMappingPayloadCast | string;
  specific?: boolean;
  functional_key?: boolean;
};

export type Fields = Field[];

export type Xpath = {
  id: number;
  name: string;
  type: string;
  order: number;
  parent_id?: number;
  children: XpathList;
  xpath: string;
  is_inactive?: boolean;
};

export type XpathList = Xpath[];

export type Option = {
  id: string;
};

export type Options = Option[];

export type FieldOption = {
  id: number;
  label: string;
  data: Field;
};

export type FieldOptions = FieldOption[];

export type ExportMappingPayloadValueData =
  | string
  | number
  | boolean
  | { code: string }
  | { data: string; expressedIn: { code: string } }[];

export type ExportMappingPayloadValue = {
  cast?: string;
  data?: ExportMappingPayloadValueData;
};

export enum ExportMappingConstantCast {
  FUNCTION = 'function',
  LAMBDA = 'lambda',
}

export type ExportMappingPayloadConstant = {
  cast: string;
  data?: string;
};

export type ExportMappingPayloadConstants = {
  [key: string]: ExportMappingPayloadConstant;
};

export type ExportMappingConditions = {
  must_use_or_logic?: true;
  data: Conditions;
};

export type ExportMappingPayloadSimple = {
  cast?: ExportMappingPayloadCast;
  field?: string;
  value?: ExportMappingPayloadValue;
  custom_parse?: string;
  use_su?: boolean;
  referential?: string;
  is_declinable?: boolean;
};

export type ExportMappingPayload = (
  | {
      cast?: 'boolean';
      default_value?: boolean;
    }
  | {
      cast?: 'int' | 'float' | 'int positive' | 'float positive';
      is_declinable: undefined;
      default_value?: number;
    }
  | {
      cast?: 'string' | 'text';
      is_declinable: undefined;
      default_value?: string;
    }
  | {
      cast?:
        | 'int'
        | 'float'
        | 'int positive'
        | 'float positive'
        | 'string'
        | 'text';
      is_declinable: true;
      default_value: undefined;
    }
  | {
      cast?: 'gdsn_node' | 'list' | 'date' | 'iso_date' | 'entity';
      default_value: undefined;
    }
) & {
  cast?: string;
  field?: string;
  value?: ExportMappingPayloadValue;
  custom_parse?: string;
  use_su?: boolean;
  referential?: string;
  is_declinable?: boolean;
  constants_for_later_use?: ExportMappingPayloadConstants;
  must_check_conditions_at_child_level?: true;
  conditions?: ExportMappingConditions;
  do_not_add_module?: true;
  use_get_attr_rec_with_children?: true;
  is_GS1_enum?: true;
  must_not_loop?: true;
  new_module?: string;
  set_attribute_from_value?: string;
  set_attribute_from_field?: string;
  is_normalized?: true;
  is_not_GS1_declinable?: true;
  expressed_in_code?: string;
  unit?: string;
  strip_html?: true;
  predicate?: string;
  code_list_name_code?: string;
  repeat?: false;
  is_on_paris_timezone?: true;
  is_on_other_timezone?: true;
};

export type ExportMapping = {
  id?: number;
  date_start: string;
  date_end: string;
  order: number;
  xpath_id: number;
  parent_id?: number;
  payload: ExportMappingPayload;
  children: ExportMapping[];
  sxm_list_parent?: {
    field: string;
    use_su: boolean;
  };
  fallback: ExportMapping | {};
  is_fallback?: true;
};

export type ExportMappings = ExportMapping[];

export interface GDSNExportMappingsState {
  customParseOptions: Options;
  selectedExportMapping?: ExportMapping;
  exportMappings: ExportMappings;
  isSavingInProcess: boolean;
  listFieldOptions: FieldOptions;
  fieldOptions: FieldOptions;
  functionForValuesOptions: Options;
  functionForConstantsOptions: Options;
  functionForConditionsOptions: Options;
  referentialCodeOptions: { [key: string]: RegularOptionsWithNumberId };
  xpathList: XpathList;
  editionId?: number | string;
  reorderingId?: number;
  targetGlnOptions: Options;
}

export type RegularOptionWithStringId = {
  id: string;
  label: string;
};

export type RegularOptionsWithStringId = RegularOptionWithStringId[];

export type RegularOptionWithNumberId = {
  id: number;
  label: string;
};

export type RegularOptionsWithNumberId = RegularOptionWithNumberId[];

export type ConditionCast =
  | 'function'
  | 'lambda'
  | 'target_glns'
  | 'not_target_glns'
  | 'same_level_gdsn_field_exists'
  | 'does_module_name_match_composition'
  | 'not_empty_module'
  | 'rules_bypassed'
  | 'same_level_fields_exist'
  | 'same_level_fields_dont_exist';

export type ConditionCastOption = {
  id: ConditionCast;
};

export type ConditionCastOptions = ConditionCastOption[];

export type ConditionWithStringData = {
  cast:
    | 'function'
    | 'lambda'
    | 'target_glns'
    | 'not_target_glns'
    | 'same_level_gdsn_field_exists';
  data?: string;
};

export type ConditionWithBooleanData = {
  cast: 'does_module_name_match_composition' | 'not_empty_module';
  data: true;
};

export type ConditionWithNumberArrayData = {
  cast: 'rules_bypassed';
  data?: number[];
};

export type ConditionWithStringArrayData = {
  cast: 'same_level_fields_exist' | 'same_level_fields_dont_exist';
  data?: string[];
};

export type Condition =
  | ConditionWithStringData
  | ConditionWithBooleanData
  | ConditionWithNumberArrayData
  | ConditionWithStringArrayData;

export type Conditions = Condition[];
