import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ListPaginationInfo from 'components/ui/list/pagination/info';
import Pagination from 'components/ui/pagination';

import './controller.scss';

class ListController extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
    type: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    rowsLength: PropTypes.number,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    actions: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    limit: PropTypes.number,
    onLimitChange: PropTypes.func,
    paginationSortOptions: PropTypes.arrayOf(PropTypes.object),
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,
    onSortChange: PropTypes.func,
    paginationExtraOptions: PropTypes.object,
    bottomActions: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { showBottom: false };
  }

  componentDidMount() {
    this.listControllerRef = React.createRef();
    this.checkScrollSize();
  }

  componentDidUpdate() {
    this.checkScrollSize();
  }

  componentWillUnmout() {
    cancelAnimationFrame(this._animationFrame);
  }

  checkScrollSize() {
    cancelAnimationFrame(this._animationFrame);
    this._animationFrame = requestAnimationFrame(() => {
      if (this.listControllerRef.current) {
        const showBottom =
          this.listControllerRef.current.getBoundingClientRect().bottom >
          window.innerHeight;
        this.setState({ showBottom });
      }
    });
  }

  renderPagination(bottom) {
    const {
      onNext,
      onPrev,
      currentPage,
      totalPages,
      limit,
      paginationSortOptions,
      sortBy,
      sortOrder,
      onLimitChange,
      paginationExtraOptions,
      onSortChange,
    } = this.props;
    return (
      <Pagination
        onNextClick={onNext}
        onPrevClick={onPrev}
        currentPage={currentPage}
        totalPages={totalPages}
        limit={bottom && limit}
        sortOptions={bottom && paginationSortOptions}
        sortBy={bottom && sortBy}
        sortOrder={bottom && sortOrder}
        onLimitChange={bottom && onLimitChange}
        limitExtraOptions={bottom && paginationExtraOptions}
        sortExtraOptions={bottom && paginationExtraOptions}
        onSortChange={bottom && onSortChange}
      />
    );
  }

  renderPaginationInfo() {
    const { currentPage, totalPages, totalResults, rowsLength, type } =
      this.props;
    return (
      <ListPaginationInfo
        currentPage={currentPage}
        totalPages={totalPages}
        totalResults={totalResults}
        selected={rowsLength}
        type={type}
      />
    );
  }

  render() {
    const { actions, children, bottomActions } = this.props;
    const { showBottom } = this.state;
    return (
      <div className="ListController" ref={this.listControllerRef}>
        <div className="ListController__row">
          <div className="ListController__col">{actions}</div>
          <div className="ListController__col">
            {this.renderPagination(true)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">{this.renderPaginationInfo()}</div>
        </div>
        {children}
        {showBottom && (
          <div className="row">
            <div className="col-md-12">{this.renderPaginationInfo()}</div>
          </div>
        )}
        {showBottom && (
          <div className="ListController__row">
            <div className="ListController__col">
              {bottomActions && actions}
            </div>
            <div className="ListController__col">{this.renderPagination()}</div>
          </div>
        )}
      </div>
    );
  }
}

export default ListController;
export { ListController };
