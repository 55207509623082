import { Map, fromJS } from 'immutable';

import { createReducer } from 'utils/reducers';
import {
  addFilter,
  changeLimit,
  clearFilter,
  initPagination,
  nextPage,
  previousPage,
  receiveReducer,
  removeFilter,
  updateFilter,
} from 'utils/reducers/list';

import * as events from '../constants/events';

const LIMIT_STORAGE_KEY = 'endpointListLimit';
const SORTING_STORAGE_KEY = 'endpointListSorting';

const initialState = {
  list: [],
  files: Map(),
  filters: {
    organizations: [],
  },
  filtersRaw: fromJS({
    organizations: [],
  }),
  modal: {
    isOpen: false,
    endpoint: fromJS({
      name: '',
      description: '',
      active: false,
      config: {
        confirm: true,
        method: 'password',
        password: '',
        ssh_keys: [],
        kex: [],
        extra_event_types: [],
        hostname: '',
        port: 22,
        base_url: '',
        client_id: '',
        client_secret: '',
      },
      handlers: [],
      organization: {},
      type: 0,
      username: '',
    }),
  },
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
};

export default createReducer(initialState, {
  [events.RECEIVE_ENDPOINT_LIST]: (state, action) =>
    receiveReducer(state, action.payload),
  [events.CHANGE_LIMIT_ENDPOINT_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.NEXT_PAGE_ENDPOINT_LIST]: nextPage,
  [events.PREVIOUS_PAGE_ENDPOINT_LIST]: previousPage,
  [events.RECEIVE_ENDPOINT_LIST_FILES]: (state, action) => {
    const newState = { ...state };
    newState.files = Map([
      ...newState.files,
      [
        action.payload.endpointId,
        {
          totalResults: action.payload.totalResults,
          files: action.payload.files,
        },
      ],
    ]);
    return { ...newState };
  },
  [events.ENDPOINT_MODAL_OPEN]: (state, action) => {
    const newState = { ...state };
    newState.modal = {
      isOpen: true,
      endpoint: initialState.modal.endpoint.mergeDeep(
        fromJS(action.payload || {}),
      ),
    };
    return newState;
  },
  [events.ENDPOINT_MODAL_CLOSE]: (state) => {
    const newState = { ...state };
    newState.modal = {
      isOpen: false,
      endpoint: initialState.modal.endpoint,
    };
    return newState;
  },
  [events.ENDPOINT_MODAL_UPDATE]: (state, action) => {
    const newState = { ...state };
    newState.modal = {
      ...state.modal,
      endpoint: state.modal.endpoint.setIn(
        action.payload.key,
        action.payload.value,
      ),
    };
    return newState;
  },
  [events.RECEIVE_ENDPOINT_LIST_FILTERS]: (state, action) => {
    const newState = { ...state };
    newState.filtersRaw = fromJS(action.payload.data);
    return newState;
  },

  [events.ADD_ENDPOINT_LIST_FILTER]: addFilter,
  [events.REMOVE_ENDPOINT_LIST_FILTER]: removeFilter,
  [events.UPDATE_ENDPOINT_LIST_FILTER]: updateFilter,
  [events.CLEAR_ENDPOINT_LIST_FILTER]: clearFilter,
});
