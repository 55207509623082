import React from 'react';

import { ListColumn as Column, ListRow as Row } from '@alkem/react-ui-list';

import './row.scss';

const GLNClaimHeaderRow = () => (
  <Row className="GLNClaimHeaderRow">
    <Column className="GLNClaimRow__organization GLNClaimRow__header">
      Organization
    </Column>
    <Column className="GLNClaimRow__gln GLNClaimRow__header">GLN</Column>
    <Column className="GLNClaimRow__data GLNClaimRow__header">GLN Infos</Column>
    <Column className="GLNClaimRow__username GLNClaimRow__header">
      Username
    </Column>
    <Column className="GLNClaimRow__status GLNClaimRow__header">Status</Column>
    <Column className="GLNClaimRow__createdAt GLNClaimRow__header">
      <span>Created At</span>
    </Column>
    <Column className="GLNClaimRow__updatedAt GLNClaimRow__header">
      Updated At
    </Column>
    <Column className="GLNClaimRow__actions GLNClaimRow__header">
      Actions
    </Column>
  </Row>
);

export default GLNClaimHeaderRow;
