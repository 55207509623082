import {
  TYPE_ALK_SUPER_ADMIN,
  TYPE_PLATFORM_ADMIN,
  TYPE_SUPPORT,
} from 'constants/user';
import { Organization, User } from 'modules/auth/types';
import { get, getIn } from 'utils/immutable';

export const getUserType = (user: User) => get(user, 'type');

export const hasFullPower = (user: User) =>
  getUserType(user) === TYPE_ALK_SUPER_ADMIN;

export const hasRestrictedPower = (user: User) =>
  [TYPE_SUPPORT, TYPE_PLATFORM_ADMIN].includes(getUserType(user));

export const isAtLeastSupport = (user: User) =>
  [TYPE_SUPPORT, TYPE_PLATFORM_ADMIN, TYPE_ALK_SUPER_ADMIN].includes(
    getUserType(user),
  );

export const isAtLeastPlatformAdmin = (user: User) =>
  [TYPE_PLATFORM_ADMIN, TYPE_ALK_SUPER_ADMIN].includes(getUserType(user));

export const getUserId = (user: User): number => get(user, ['id']);

export const getOrganization = (user: User): Organization =>
  getIn(user, ['belongsTo', 0]);

export const getOrganizationId = (user: User): number =>
  getIn(getOrganization(user), ['id']);

export const asUser = (user: any): User => user;
