import { curry, flow } from 'lodash/fp';

import { PERMISSION_ENTITY_PLATFORMS } from 'modules/access-policy/common/constants';
import { hasAccess } from 'modules/access-policy/common/utils';
import { selectUser } from 'reducers/user';
import { getUserType, hasFullPower, hasRestrictedPower } from 'utils/user';

import { selectPermissions } from './reducer';

function userAccessPolicyCheck(
  state,
  accessPolicy,
  accessType: 'read' | 'write' = 'read',
) {
  const user = selectUser(state);
  if (hasFullPower(user)) {
    return true;
  }
  return (
    hasRestrictedPower(user) &&
    hasAccess(
      getUserType(user),
      selectPermissions(state),
      accessPolicy,
      accessType,
    )
  );
}

export const selectUserHasFullPower: (state: any) => boolean = flow(
  selectUser,
  hasFullPower,
);

export const selectUserReadAccess = curry((accessPolicy, state) =>
  userAccessPolicyCheck(state, accessPolicy, 'read'),
);

export const selectUserWriteAccess = curry((accessPolicy, state) =>
  userAccessPolicyCheck(state, accessPolicy, 'write'),
);

export const selectUserReadOnlyAccess = curry(
  (accessPolicy, state) => !selectUserWriteAccess(accessPolicy, state),
);

export const selectUserHasPlatformPermission = curry((permission, state) => {
  const user = selectUser(state);
  if (hasFullPower(user)) {
    return true;
  }
  const userPermissions = selectPermissions(state);
  return (
    hasRestrictedPower(user) &&
    !!userPermissions &&
    userPermissions[PERMISSION_ENTITY_PLATFORMS].includes(permission)
  );
});
