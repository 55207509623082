import * as notificationConstants from 'constants/events/notifications';
import { ERROR, SUCCESS } from 'constants/notifications';

export const notificationError = (
  message: string,
  context: string = 'base',
) => {
  const style = ERROR;
  return {
    context,
    message,
    style,
    type: notificationConstants.ADD_NOTIFICATION,
  };
};

export const notificationSuccess = (
  message: string,
  context: string = 'base',
) => {
  const style = SUCCESS;
  return {
    context,
    message,
    style,
    type: notificationConstants.ADD_NOTIFICATION,
  };
};

export const notificationRemove = (index: number) => ({
  index,
  type: notificationConstants.REMOVE_NOTIFICATION,
});

export const notificationDisplayed = () => ({
  type: notificationConstants.NOTIFICATION_DISPLAYED,
});

export const notificationHidden = () => ({
  type: notificationConstants.NOTIFICATION_HIDDEN,
});
