import {
  LOAD_PRODUCT,
  REINDEX,
  RESYNC,
  REVIEW,
  SAVE,
  UPDATE_PRODUCT,
  UPDATE_REASON,
} from './constants';

export const loadProduct = (productKeyId) => ({
  type: LOAD_PRODUCT,
  productKeyId,
});

export const updateProduct = (key, value) => ({
  type: UPDATE_PRODUCT,
  key,
  value,
});

export const save = () => ({
  type: SAVE,
});

export const reindexProduct = () => ({
  type: REINDEX,
});

export const resyncProduct = () => ({
  type: RESYNC,
});

export const triggerReview = () => ({
  type: REVIEW,
});

export const updateReason = (reason) => ({
  type: UPDATE_REASON,
  reason,
});
