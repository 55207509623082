import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import * as routes from 'constants/routes';

import GDSNPSRListFilters from '../filters';
import GDSNPSRList from '../list';

export class GDSNPSRDashboardView extends PureComponent {
  render() {
    const headerProps = {
      title: 'GDSN Price: Items',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };

    return (
      <div className="GDSNPSRDashboardView__body">
        <HeaderLayout {...headerProps} />
        <div className="container-fluid row">
          <div className="col-md-3">
            <GDSNPSRListFilters />
          </div>
          <div className="col-md-9">
            <GDSNPSRList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(GDSNPSRDashboardView);
