import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';

import Notification from 'components/notification';
import { separateActions } from 'utils/redux';

import {
  activate,
  closeOrganizationDeactivateModal,
  deactivate,
} from '../actions';
import {
  selectIsModalLoading,
  selectIsModalOpen,
  selectModalOrganization,
} from '../selectors/modal';

import './index.scss';

const mapStateToProps = (state) => ({
  organization: selectModalOrganization(state),
  isModalOpen: selectIsModalOpen(state),
  isLoading: selectIsModalLoading(state),
});

const mapDispatchToProps = {
  closeOrganizationDeactivateModal,
  deactivate,
  activate,
};

class OrganizationDeactivate extends PureComponent {
  static propTypes = {
    organization: ImmutablePropTypes.map,
    isModalOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    actions: PropTypes.shape({
      closeOrganizationDeactivateModal: PropTypes.func.isRequired,
      deactivate: PropTypes.func.isRequired,
      activate: PropTypes.func.isRequired,
    }).isRequired,
    onClose: PropTypes.func,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  closeModal = () => {
    this.props.actions.closeOrganizationDeactivateModal();
  };

  done = (result = {}) => {
    if (this._isMounted && result.success) {
      this.props.onClose();
    }
  };

  deactivate = () => {
    this.props.actions.deactivate().then(this.done);
  };

  activate = () => {
    this.props.actions.activate().then(this.done);
  };

  render() {
    const { isModalOpen, isLoading, organization } = this.props;
    if (!organization || !isModalOpen) {
      return null;
    }
    const isActivated = organization.get('status') === 1;
    const name = organization.get('nameLegal');
    return (
      <Modal
        danger
        modalStyle="dynamic"
        title={`${isActivated ? 'Dea' : 'A'}ctivating an organization`}
        confirmButtonText={`${isActivated ? `Dea` : `A`}ctivate ${name}`}
        onConfirm={isActivated ? this.deactivate : this.activate}
        isProcessing={isLoading}
        onClose={this.closeModal}
      >
        <Notification type="modal" />
        <span>Are you sure you want to </span>
        <span>{isActivated ? 'deactivate' : 'activate'} </span>
        <span className="OrganizationDeactivate__orgName">{name}</span>
        <span>?</span>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(OrganizationDeactivate);
