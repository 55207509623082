import { notificationError } from 'actions/notifications';
import { validationApi } from 'resources/validationApi';
import { logError } from 'utils';

import * as events from './events';

export const fetchAllRuleSets = async (dispatch) => {
  try {
    const response = await validationApi.listRuleSets();
    dispatch({
      type: events.RECEIVE_ALL_RULE_SETS,
      rulesets: response.data.data,
    });
  } catch (e) {
    logError(e);
    notificationError('An error occured while loading the rule sets');
  }
};
