import { noop } from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { kindsIndexSelector } from 'redux/selectors';

import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import EmptyState from '@alkem/react-ui-empty-state';
import { Spinner } from '@alkem/react-ui-spinner';

import Dropdown from 'components/dropdown';
import ListController from 'components/ui/list/controller';
import {
  validationAddToFilters,
  validationClearFilters,
  validationClearSelectedRules,
  validationDeleteRules,
  validationDuplicateRulesInRuleset,
  validationFetchRules,
  validationMoveRulesToRuleset,
  validationSetPagination,
  validationToggleRules,
} from 'modules/validation-dashboard/actions';
import {
  selectSelectedRules,
  selectValidationIsLoading,
  selectValidationPagination,
  selectValidationSelectedFilters,
} from 'modules/validation-dashboard/selectors';
import { Rule, RuleSet } from 'modules/validation-dashboard/types';
import { formatRuleSetIdInFilter } from 'modules/validation-dashboard/utils';

import { ruleEntityTypesMap, ruleTemplateLabels } from '../../constants';
import { BulkEditProps, BulkEditRulesModal } from '../bulk-edit-rules-modal';

import { ValidationRuleBeta } from './validation-rule-beta';
import './validation-rules-list.scss';

export enum BulkEditRulesMode {
  MOVE = 'move',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
}

interface Props {
  rules: Rule[];
}

export const ValidationRulesList = ({ rules }: Props) => {
  const dispatch = useDispatch();

  const selectedFilters = useSelector(selectValidationSelectedFilters);

  const [isSelectRulesetModalOpen, openSelectRulesetModal] = useState(false);

  const [bulkModalMode, setBulkModalMode] = useState(BulkEditRulesMode.MOVE);

  const selectedRules = useSelector(selectSelectedRules);
  const selectedRulesQty = Object.values(selectedRules).filter(
    (value) => value,
  ).length;
  const isLoading = useSelector(selectValidationIsLoading);
  const pagination = useSelector(selectValidationPagination);
  const kinds = useSelector(kindsIndexSelector);
  const [showAllSummaries, setShowAllSummaries] = useState(false);

  const bulkOptions = useMemo(
    () => [
      {
        key: 'moveselectedrules',
        label: `Move ${selectedRulesQty} rule(s) to another ruleset`,
        onClick: () => {
          setBulkModalMode(BulkEditRulesMode.MOVE);
          openSelectRulesetModal(true);
        },
      },
      {
        key: 'deleteselectedrules',
        label: `Delete ${selectedRulesQty} rule(s)`,
        onClick: () => {
          setBulkModalMode(BulkEditRulesMode.DELETE);
          openSelectRulesetModal(true);
        },
      },
      {
        key: 'duplicateselectedrules',
        label: `Duplicate ${selectedRulesQty} rule(s)`,
        onClick: () => {
          setBulkModalMode(BulkEditRulesMode.DUPLICATE);
          openSelectRulesetModal(true);
        },
      },
    ],
    [selectedRulesQty],
  );

  const changePage = useCallback(
    (currentPage: number) => {
      dispatch(validationClearSelectedRules());
      dispatch(
        validationSetPagination({
          currentPage,
        }),
      );
    },
    [dispatch],
  );

  const setItemsPerPage = useCallback(
    (limit: number) => {
      dispatch(validationClearSelectedRules());
      dispatch(
        validationSetPagination({
          currentPage: 1,
          limit,
        }),
      );
    },
    [dispatch],
  );

  const rulesGroupedByEntityType: { [entityType: number]: Rule[] } = useMemo(
    () =>
      rules?.reduce((acc, rule) => {
        if (!acc[Number(rule.ruleEntityType)]) {
          acc[Number(rule.ruleEntityType)] = [];
        }
        acc[Number(rule.ruleEntityType)].push(rule);
        return acc;
      }, {}),
    [rules],
  );

  const sortedRules = useMemo(() => {
    const rules: Rule[] = [];
    Object.values(rulesGroupedByEntityType).forEach(
      (entityTypeRules: Rule[]) => {
        rules.push(...entityTypeRules);
      },
    );
    return rules;
  }, [rulesGroupedByEntityType]);

  const [lastCheckboxClickedRuleId, setLastCheckboxClickedRuleId] = useState<
    number | null
  >(null);

  const onSelectRule = useCallback(
    (event: ChangeEvent<HTMLInputElement>, ruleId: number) => {
      const ruleToggleState = {
        [ruleId]: event.target.checked,
      };
      if (
        (event.nativeEvent as PointerEvent).shiftKey &&
        lastCheckboxClickedRuleId
      ) {
        const indexLastSelectedRule = sortedRules.findIndex(
          (rule) => rule.id === lastCheckboxClickedRuleId,
        );
        const indexCurrentSelectedRule = sortedRules.findIndex(
          (rule) => rule.id === ruleId,
        );

        sortedRules.forEach((rule, index) => {
          if (
            (indexLastSelectedRule <= index &&
              index <= indexCurrentSelectedRule) ||
            (indexCurrentSelectedRule <= index &&
              index <= indexLastSelectedRule)
          ) {
            ruleToggleState[rule.id] = event.target.checked;
          }
        });
      }
      dispatch(validationToggleRules(ruleToggleState));
      setLastCheckboxClickedRuleId(ruleId);
    },
    [dispatch, lastCheckboxClickedRuleId, sortedRules],
  );

  function isBulkSelectCheckboxPartiallyChecked(rules, selectedRulesQty) {
    if (!selectedRulesQty || selectedRulesQty === rules.length) {
      return false;
    } else {
      return true;
    }
  }

  function isBulkSelectCheckboxChecked(rules, selectedRulesQty) {
    if (!selectedRulesQty || selectedRulesQty !== rules.length) {
      return false;
    } else {
      return true;
    }
  }

  const isBulkSelectCheckboxCheckedStatus = isBulkSelectCheckboxChecked(
    rules,
    selectedRulesQty,
  );
  const isBulkSelectCheckboxPartiallyCheckedStatus =
    isBulkSelectCheckboxPartiallyChecked(rules, selectedRulesQty);

  function handleBulkSelectCheckboxUpdate(checked, minus, rules) {
    const ruleToggleStates = {};
    const selectedState = !checked && !minus;
    for (const rule of rules) {
      ruleToggleStates[rule.id] = selectedState;
    }

    dispatch(validationToggleRules(ruleToggleStates));
  }

  function nextPage() {
    if (pagination.currentPage < pagination.totalPages) {
      changePage(pagination.currentPage + 1);
    } else {
      changePage(1);
    }
  }

  function prevPage() {
    if (pagination.currentPage > 1) {
      changePage(pagination.currentPage - 1);
    } else {
      changePage(pagination.totalPages);
    }
  }

  function filterSelectedRules(rules, selectedRules): Rule[] {
    return rules.filter((rule) => {
      return Object.keys(selectedRules).includes(rule.id.toString());
    });
  }

  const setFilter = (filter, isOnlyActive?: boolean) => {
    dispatch(validationClearFilters());
    dispatch(validationClearSelectedRules());
    dispatch(validationAddToFilters(filter));
    if (!isOnlyActive) {
      dispatch(
        validationAddToFilters({ path: 'onlyActive', value: isOnlyActive }),
      );
    }
  };

  const bulkEditModalPropsForMode = (
    mode: BulkEditRulesMode,
    rules: Rule[],
    selectedRules,
  ): BulkEditProps => {
    const commonProps = {
      id: 'bulk-edit-modal',
      rules: filterSelectedRules(rules, selectedRules),
      onCloseAction: () => {
        openSelectRulesetModal(false);
      },
    };

    switch (mode) {
      case BulkEditRulesMode.MOVE:
        return {
          ...commonProps,
          title: 'Assign rule(s) to a new ruleset',
          eligibleRulesHeader:
            'These rules will be assigned to the selected ruleset:',
          eligibleRulesFilter: (rule) => !!rule.ruleSet,
          ineligibleRulesHeader:
            "These rules won't be assigned to the selected ruleset (as they are not in a ruleset):",
          confirmButtonText: 'Move rules to ruleset',
          requiresRuleSetSelection: true,
          getSumUpLabel: (
            nbSelectedRules,
            nbEligibleRules,
            nbNonEligibleRules,
          ) => (
            <>
              Select the ruleset to assign the <b>{nbSelectedRules}</b> selected
              rule(s) to
              {!!nbNonEligibleRules && (
                <span className="Text__warning">
                  {' '}
                  (<b>{nbNonEligibleRules}</b> rule(s) cannot be assigned to a
                  ruleset, only the <b>{nbEligibleRules}</b> assignabled ones
                  will be moved)
                </span>
              )}
              .
            </>
          ),
          performAction: (
            selectedRuleIds: number[],
            selectedRuleSet: RuleSet | undefined,
          ) => {
            if (selectedRuleSet) {
              dispatch(
                validationMoveRulesToRuleset({
                  ruleIds: selectedRuleIds,
                  rulesetId: selectedRuleSet.id,
                }),
              );
            }
          },
          performDoneAction: (selectedRuleSet) => {
            if (selectedRuleSet) {
              setFilter(
                formatRuleSetIdInFilter(selectedRuleSet),
                selectedFilters.onlyActive,
              );
            }
          },
        };
      case BulkEditRulesMode.DELETE:
        return {
          ...commonProps,
          title: 'Delete rule(s)',
          eligibleRulesHeader: 'These rules will be deleted:',
          eligibleRulesFilter: (rule) => !!rule.ruleSet,
          ineligibleRulesHeader:
            "These rules won't be deleted (as they are not in a ruleset):",
          confirmButtonText: 'Delete rules',
          requiresRuleSetSelection: false,
          getSumUpLabel: (nbSelectedRules, _, nbNonEligibleRules) => (
            <>
              <b>{nbSelectedRules}</b> selected rule(s)
              {!!nbNonEligibleRules && (
                <>
                  {', '}
                  <span className="Text__warning">
                    <b>{nbNonEligibleRules}</b> rule(s) cannot be deleted
                  </span>
                </>
              )}
              .
            </>
          ),
          performAction: (selectedRuleIds: number[]) => {
            dispatch(validationDeleteRules(selectedRuleIds));
          },
          performDoneAction: () => {
            dispatch(validationClearSelectedRules());
          },
        };
      case BulkEditRulesMode.DUPLICATE:
        return {
          ...commonProps,
          title: 'Duplicate rule(s)',
          eligibleRulesHeader:
            'These rules will be duplicated in the selected ruleset:',
          ineligibleRulesHeader:
            "These rules won't be duplicated (as they are templated rules):",
          eligibleRulesFilter: (rule) =>
            rule.template?.label === ruleTemplateLabels.CUSTOM,
          confirmButtonText: 'Duplicate rules',
          requiresRuleSetSelection: true,
          getSumUpLabel: (nbSelectedRules, _, nbNonEligibleRules) => (
            <>
              Select the ruleset to duplicate the <b>{nbSelectedRules}</b>{' '}
              selected rule(s) in
              {!!nbNonEligibleRules && (
                <span className="Text__warning">
                  {' '}
                  (<b>{nbNonEligibleRules}</b> rule(s) cannot be duplicated)
                </span>
              )}
              .
            </>
          ),
          performAction: (
            selectedRuleIds: number[],
            selectedRuleSet: RuleSet | undefined,
          ) => {
            if (selectedRuleSet) {
              dispatch(
                validationDuplicateRulesInRuleset({
                  ruleIds: selectedRuleIds,
                  rulesetId: selectedRuleSet.id,
                }),
              );
            }
          },
          performDoneAction: (selectedRuleSet) => {
            if (selectedRuleSet) {
              setFilter(
                formatRuleSetIdInFilter(selectedRuleSet),
                selectedFilters.onlyActive,
              );
            }
          },
        };
      default:
        throw Error(`No properties defined for mode ${mode}`);
    }
  };

  return (
    <div className="ValidationRulesList">
      <ListController
        currentPage={pagination.currentPage}
        totalPages={pagination.totalPages}
        totalResults={pagination.totalResults}
        type="default"
        sortBy={pagination.sortBy}
        sortOrder={pagination.sortOrder}
        limit={pagination.limit}
        onNext={() => nextPage()}
        onPrev={() => prevPage()}
        onLimitChange={(value) => setItemsPerPage(value)}
        rowsLength={rules.length}
        actions={
          <>
            <div className="ValidationRuleGeneralCheckboxContainer">
              <Checkbox
                checked={isBulkSelectCheckboxCheckedStatus}
                partiallyChecked={isBulkSelectCheckboxPartiallyCheckedStatus}
                onChange={() =>
                  handleBulkSelectCheckboxUpdate(
                    isBulkSelectCheckboxCheckedStatus,
                    isBulkSelectCheckboxPartiallyCheckedStatus,
                    rules,
                  )
                }
                id={'general-validation-dashboard'}
                testid={'checkbox-general-validation-dashboard'}
              />
            </div>
            <Button
              onClick={() => dispatch(validationFetchRules())}
              secondary
              testid="ValidationRulesListRefresh"
            >
              <i className="mdi mdi-refresh" />
            </Button>
            <Button
              onClick={() => setShowAllSummaries(!showAllSummaries)}
              secondary
              testid="ValidationRulesListShowAllSummaries"
            >
              {showAllSummaries ? 'Hide summaries' : 'Show summaries'}
            </Button>
            <Dropdown
              disabled={!selectedRulesQty}
              buttonClassName={'btn-secondary'}
              label={'Bulk actions'}
              options={bulkOptions}
              selectOptions={noop}
              rightDropdown
              closeDropdownOnClickElement
              id="validation-rules-bulkaction-dropdown"
            />
          </>
        }
      >
        <div className="ValidationRulesList__sheet">
          {isLoading && (
            <div className="ValidationRulesList__spinner">
              <Spinner loading big />
            </div>
          )}
          {!isLoading &&
            Object.entries(rulesGroupedByEntityType).map(
              ([key, mapRules]: any) => {
                if (!rules?.length) {
                  return (
                    <div key="no-rules" className="ValidationRulesList--empty">
                      <EmptyState
                        title="Nothing to see here!"
                        text="No rule matches the selected filters. Try again"
                      />
                    </div>
                  );
                }
                return (
                  <div key={`category-${key}`}>
                    <div
                      className="ValidationRulesList__headerLabel"
                      data-testid="rule-entity-type-separator"
                    >
                      {ruleEntityTypesMap[key].label}
                    </div>
                    <div>
                      {mapRules.map((rule: Rule) => (
                        <ValidationRuleBeta
                          key={`rule-${rule.id}`}
                          rule={rule}
                          onSelectRule={(event) => onSelectRule(event, rule.id)}
                          isCheckboxChecked={!!selectedRules[rule.id]}
                          isCheckboxHighlighted={
                            lastCheckboxClickedRuleId === rule.id
                          }
                          showSummary={showAllSummaries}
                          kinds={kinds}
                        />
                      ))}
                    </div>
                  </div>
                );
              },
            )}
        </div>
      </ListController>
      {isSelectRulesetModalOpen && (
        <BulkEditRulesModal
          {...bulkEditModalPropsForMode(bulkModalMode, rules, selectedRules)}
        />
      )}
    </div>
  );
};
