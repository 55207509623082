import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Radio, Text } from '@alkem/react-ui-inputs';
import { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import { ReferentialAutocomplete } from 'components/autocomplete';
import InputWithLabel from 'components/input-with-label';

import { GET_REFERENTIAL_CODE_LIST } from '../../../../actions/constants';
import { DECLINABLE_TYPES } from '../../../../constants';
import { selectReferentialCodeOptions } from '../../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  ExportMappingPayloadSimple,
  RegularOptionWithStringId,
} from '../../../../types';
import { ExportMappingsTurboSelector } from '../../turbo-selector';
import { isExportMappingPayloadCast, isRegularOption } from '../../utils';
import styles from '../simple-element-item-type.module.scss';
import { dataTypeOptions } from '../utils';

import { ExportMappingsSimpleElementItemValueTypeValueSetter } from './value-setter';

const getInitialDataType = (payload: ExportMappingPayload) => {
  const { cast } = payload;
  if (!cast) return undefined;

  return dataTypeOptions.find((dataTypeOption) => dataTypeOption.id === cast);
};

interface Props {
  name: string;
  exportMapping: ExportMapping;
  dispatchSetExportMapping: (
    {
      cast,
      field,
      use_su,
      referential,
      is_declinable,
      value,
      custom_parse,
    }: ExportMappingPayloadSimple,
    isUpdatingValue?: boolean,
  ) => void;
  showEditionFields: boolean;
}
export function ExportMappingsSimpleElementItemValueTypePropsSelector({
  name,
  exportMapping,
  dispatchSetExportMapping,
  showEditionFields,
}: Props) {
  const dispatch = useDispatch();
  const referentialCodeOptions = useSelector(selectReferentialCodeOptions);
  const [dataTypeOption, setDataTypeOption] = useState<
    RegularOptionWithStringId | undefined
  >(getInitialDataType(exportMapping.payload));
  const [isDeclinable, setIsDeclinable] = useState(
    exportMapping.payload.is_declinable,
  );
  const [referentialSearchValue, setReferentialSearchValue] = useState<
    any | undefined
  >();
  const [referential, setReferential] = useState(
    exportMapping.payload.referential || undefined,
  );

  useEffect(() => {
    if (
      exportMapping.payload.referential &&
      !Object.keys(referentialCodeOptions).includes(
        exportMapping.payload.referential,
      )
    ) {
      dispatch({
        type: GET_REFERENTIAL_CODE_LIST,
        referential: exportMapping.payload.referential,
      });
    }
  }, []);

  const clearReferentialFields = () => {
    setReferentialSearchValue(undefined);
    setReferential(undefined);
  };

  const updateDataTypeOption = (
    value: TurboSelectOption<RegularOptionWithStringId>,
  ) => {
    setIsDeclinable(undefined);
    clearReferentialFields();
    if (isRegularOption(value)) {
      setDataTypeOption(value);
      dispatchSetExportMapping({
        cast: isExportMappingPayloadCast(value.id) ? value.id : undefined,
      });
    } else {
      setDataTypeOption(undefined);
      dispatchSetExportMapping({});
    }
  };

  const updateIsDeclinable = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const { cast } = exportMapping.payload;
    const exportMappingDataIsDeclinable = value === 'true' ? true : undefined;
    setIsDeclinable(exportMappingDataIsDeclinable);
    clearReferentialFields();
    dispatchSetExportMapping({
      cast,
      is_declinable: exportMappingDataIsDeclinable,
    });
  };

  const updateReferentialSearchValue = (value: {
    slug: string;
    value: any;
  }) => {
    const { cast, is_declinable, value: paylaodValue } = exportMapping.payload;
    setReferentialSearchValue(value.value);
    setReferential(value.slug);
    if (
      value.slug &&
      !Object.keys(referentialCodeOptions).includes(value.slug)
    ) {
      dispatch({
        type: GET_REFERENTIAL_CODE_LIST,
        referential: value.slug,
      });
    }
    dispatchSetExportMapping({
      cast,
      is_declinable,
      referential: value.slug,
      value: {
        ...(paylaodValue || {}),
        data: undefined,
      },
    });
  };

  return (
    <div>
      <ExportMappingsTurboSelector
        label="Type of data"
        testId={`gdsn-export-mappings-simple-element-${name}-data-type-property`}
        options={dataTypeOptions}
        onSelect={updateDataTypeOption}
        option={dataTypeOption}
        showEditionFields={showEditionFields}
      />
      {DECLINABLE_TYPES.includes(dataTypeOption?.id || '') && (
        <div
          data-testid={`gdsn-export-mappings-simple-element-${name}-data-is-declinable`}
        >
          <InputWithLabel
            inputId={`gdsn-export-mappings-simple-element-${name}-data-is-declinable`}
            label="Is the data declinable ?"
          >
            <Radio
              id={`gdsn-export-mappings-simple-element-${name}-data-is-declinable`}
              value={isDeclinable}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: undefined },
              ]}
              onChange={updateIsDeclinable}
            />
          </InputWithLabel>
        </div>
      )}
      {(isDeclinable || dataTypeOption?.id === 'entity') && (
        <>
          <div
            data-testid={`gdsn-export-mappings-simple-element-${name}-data-referential-search-value`}
          >
            <InputWithLabel
              inputId={`gdsn-export-mappings-simple-element-${name}-data-referential-search-value`}
              label="Select a referential"
            >
              <ReferentialAutocomplete
                id={`gdsn-export-mappings-simple-element-${name}-data-referential-search-value`}
                value={referentialSearchValue ? [referentialSearchValue] : []}
                onSelect={updateReferentialSearchValue}
                onUnselect={updateReferentialSearchValue}
                searchOnClick
                className={classNames({
                  [styles.errorInput]: !referential,
                })}
              />
            </InputWithLabel>
          </div>
          <div
            data-testid={`gdsn-export-mappings-simple-element-${name}-data-referential`}
          >
            <InputWithLabel
              inputId={`gdsn-export-mappings-simple-element-${name}-data-referential`}
              label="Referential"
            >
              <Text
                id={`gdsn-export-mappings-simple-element-${name}-data-referential`}
                value={referential || ''}
                onChange={() => {}}
                disabled
              />
            </InputWithLabel>
          </div>
        </>
      )}
      {dataTypeOption && (
        <ExportMappingsSimpleElementItemValueTypeValueSetter
          name={name}
          exportMapping={exportMapping}
          dataTypeOption={dataTypeOption}
          isDeclinable={isDeclinable}
          referential={referential}
          dispatchSetExportMapping={(
            exportMappingPayloadSimple: ExportMappingPayloadSimple,
          ) => dispatchSetExportMapping(exportMappingPayloadSimple, true)}
          showEditionFields={showEditionFields}
        />
      )}
    </div>
  );
}
