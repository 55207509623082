import { flow, get } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY } from './constants';

const selectState = get([MODULE_REDUCER_KEY, REDUCER_KEY]);

export const selectProduct = flow(selectState, get(['product']));

export const selectProductKeyId = flow(
  selectProduct,
  get(['product_key', 'id']),
);

export const selectIsSaving = flow(selectState, get(['isSaving']));

export const selectIsReindexing = flow(selectState, get(['isReindexing']));

export const selectIsResyncing = flow(selectState, get(['isResyncing']));

export const selectIsReviewing = flow(selectState, get(['isReviewing']));

export const selectReason = flow(selectState, get(['reason']));
