import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import {
  Condition,
  ConditionWithNumberArrayData,
} from 'modules/io/gdsn/exports/types';

import styles from '../item-conditions.module.scss';

interface Props {
  index: number;
  condition: ConditionWithNumberArrayData;
  updateCondition: (index: number, condition: Condition) => void;
  name: string;
}
export function ExportMappingsItemRulesBypassedCondition({
  index,
  condition,
  updateCondition,
  name,
}: Props) {
  const [rulesBypassed, setRulesBypassed] = useState<string | undefined>(
    condition.data?.join(',') || undefined,
  );

  const updateRulesBypassed = (event: { target: { value: string } }) => {
    const data =
      event.target.value.replace(' ', '').replace('.', '').replace('-', '') ||
      undefined;
    setRulesBypassed(data);
    updateCondition(index, {
      ...condition,
      data: data
        ?.split(',')
        .filter((input) => input !== '')
        .map((input) => parseInt(input)),
    });
  };

  return (
    <InputWithLabel
      inputId={`gdsn-export-mappings-rules-bypassed-${name}`}
      label={
        <span>
          Bypassed rules{' '}
          <HelpTooltip
            id={`gdsn-export-mappings-rules-bypassed-${name}-help`}
            place="top"
            message={
              <div className={styles.messageContainer}>
                <span>
                  Write a list of rules IDs with a comma separator if you want
                  to check several values.
                </span>
                <span>
                  If one of the rule is bypassed, the condition will be
                  fullfilled.
                </span>
              </div>
            }
          />
        </span>
      }
    >
      <div data-testid={`gdsn-export-mappings-rules-bypassed-${name}`}>
        <Text
          id={`gdsn-export-mappings-rules-bypassed-${name}`}
          value={rulesBypassed || ''}
          onChange={updateRulesBypassed}
          type="number"
          invalid={!rulesBypassed}
        />
      </div>
    </InputWithLabel>
  );
}
