import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { Tipster } from '@alkem/react-ui-tipster';

import { DispatchType } from 'types/redux';

import { bulkUpdateOrganizationDomains } from '../../../actions';

type Props = {
  domainType: 'domain' | 'email_domain';
  close: () => void;
};
const batchSize = 50;

export const OrganizationBulkDomainModal = ({ domainType, close }: Props) => {
  const dispatch: DispatchType = useDispatch();
  const [updatingBatchNumber, setUpdatingBatchNumber] = useState<number>(0);
  const [hasFailed, setHasFailed] = useState<boolean>(false);
  const [hasCompleted, setHasCompleted] = useState<boolean>(false);

  const [data, setData] = useState<string>('');
  const onDataChange = useCallback(
    (event) => {
      setData(event.target.value);
      setHasFailed(false);
    },
    [setData, setHasFailed],
  );
  const [domains, numberOfBatches] = useMemo<
    [{ id: number; domain: string }[], number]
  >(() => {
    const parsed: { id: number; domain: string }[] = [];
    for (const line of data.split(' ')) {
      const cols = line.split('\t');
      if (cols.length !== 2) {
        continue;
      }
      parsed.push({ id: parseInt(cols[0], 10), domain: cols[1] });
    }
    return [
      parsed,
      Math.floor(parsed.length / batchSize) +
        (parsed.length % batchSize > 0 ? 1 : 0),
    ];
  }, [data]);

  const onUpdate = useCallback(async () => {
    setHasCompleted(false);
    setHasFailed(false);
    for (let i = 0; i < numberOfBatches; i++) {
      setUpdatingBatchNumber(i + 1);
      const domainsBatch = domains.slice(i * batchSize, (i + 1) * batchSize);
      const success = await bulkUpdateOrganizationDomains(
        domainType,
        domainsBatch,
      );
      if (!success) {
        setHasFailed(true);
      }
    }
    setUpdatingBatchNumber(0);
    setHasCompleted(true);
  }, [
    dispatch,
    domainType,
    domains,
    numberOfBatches,
    setHasFailed,
    setUpdatingBatchNumber,
    setHasCompleted,
  ]);
  useEffect(() => {
    if (hasCompleted && !hasFailed) {
      close();
    }
  }, [close, hasFailed, hasCompleted]);

  return (
    <Modal
      title="Bulk update domains"
      confirmButtonText={
        updatingBatchNumber > 0
          ? `Updating batch ${updatingBatchNumber}/${numberOfBatches}`
          : 'Update'
      }
      confirmDisabled={numberOfBatches === 0 || updatingBatchNumber > 0}
      isProcessing={updatingBatchNumber > 0}
      onConfirm={onUpdate}
      onClose={close}
      modalStyle="dynamic"
    >
      <p>
        Copy paste two Google sheets columns without header. The first should be
        the organization ID and the second the domain.
      </p>
      <Text id={`bulk-org-domains`} value={data} onChange={onDataChange} />
      &nbsp;
      {numberOfBatches > 0 && (
        <p>
          You will be saving domains for {domains.length} organization(s). They
          will be saved in {numberOfBatches} batches of {batchSize}.
        </p>
      )}
      {hasFailed && (
        <Tipster type="error">
          At least one batch failed, check your console for details on the
          error.
        </Tipster>
      )}
    </Modal>
  );
};
