import { ImportMappings } from '../../types';

import './import-mappings.scss';
import { ImportMappingsItem } from './item';

interface Props {
  importMappings: ImportMappings;
}
export function ImportMappingsList({ importMappings }: Props): JSX.Element {
  return (
    <div className="GDSNImportMappings">
      {!importMappings?.find(({ isNew }) => isNew) && (
        <div className="GDSNImportMappings__message">
          <div>
            A new mapping version can't be created because one of the existing
            mapping has an unlimited activation end date.
          </div>
          <div>
            Update this mapping activation end date to be able to create a new
            one.
          </div>
        </div>
      )}
      {importMappings?.map((importMapping) => (
        <ImportMappingsItem
          key={importMapping.id}
          importMapping={importMapping}
          otherImportMappingsActivationDates={importMappings
            .filter(({ id, isNew }) => id !== importMapping.id && !isNew)
            .map(({ dateStart, dateEnd }) => ({ dateStart, dateEnd }))}
        />
      ))}
    </div>
  );
}
