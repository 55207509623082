import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  ORGANIZATION_TYPE_MANUFACTURER,
  ORGANIZATION_TYPE_PRIVATE_LABEL,
  ORGANIZATION_TYPE_RETAILER,
  ORGANIZATION_TYPE_THIRD_PARTY,
} from 'constants/organization';
import * as settings from 'constants/organization-settings';

import {
  hasSettingsChange,
  selectEditedOrSourceSetting,
  selectOrganizationType,
} from '../../selectors/settings';

import EnumRadioController from './controllers/enum-radio';
import MatrixSettingController from './controllers/matrix';
import SimpleSettingController from './controllers/simple';
import TagOnShareRadioController from './controllers/tagonshare-radiocontroller';
import InformationRequest from './information-request';
import ListReporting from './listreporting';
import ReadOnlyFields from './readonly-fields';
import Section from './section';
import './settings.scss';
import SupplierDirectory from './supplier-directory';

const mapStateToProps = (state) => ({
  organizationType: selectOrganizationType(state),
  areSettingsEdited: hasSettingsChange(state),
  listingSetting: selectEditedOrSourceSetting(state, [settings.LISTING]),
});

export class OrganizationPageSettings extends PureComponent {
  static propTypes = {
    organizationType: PropTypes.number,
    areSettingsEdited: PropTypes.bool.isRequired,
    listingSetting: PropTypes.string,
    isReadOnly: PropTypes.bool,
  };

  specificExports = {
    help: '⚠ Please do not leave empty lines',
    labels: ['label', 'name', 'type'],
    path: ['specific_exports'],
  };

  isManufacturer() {
    const { organizationType } = this.props;
    return (
      organizationType === ORGANIZATION_TYPE_MANUFACTURER.id ||
      organizationType === ORGANIZATION_TYPE_PRIVATE_LABEL.id
    );
  }

  isRetailer() {
    const { organizationType } = this.props;
    return organizationType === ORGANIZATION_TYPE_RETAILER.id;
  }

  isThirdParty() {
    const { organizationType } = this.props;
    return organizationType === ORGANIZATION_TYPE_THIRD_PARTY.id;
  }

  hasListing() {
    const { listingSetting } = this.props;
    return listingSetting === settings.VALUE_ENABLE;
  }

  renderSettings() {
    const { isReadOnly } = this.props;
    return (
      <div>
        <Section label="General">
          {this.isManufacturer() && (
            <div>
              <SimpleSettingController
                label="Superseed GDSN imports"
                help="When active, GDSN imports will remove all existing data before being applied."
                path={[settings.SUPERSEED_GDSN]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="Big manufacturer"
                help="When active, billing page is hidden and sharing is unlocked"
                path={[settings.BIG_MANUFACTURER]}
                checkedValue
                uncheckedValue={false}
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="Exclusive products only"
                help="When active, all products of this organization will be exclusive."
                path={[settings.EXCLUSIVE_PRODUCTS]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled={isReadOnly}
              />
            </div>
          )}
          {this.isRetailer() && (
            <div>
              <SimpleSettingController
                label={
                  <>
                    Allow push
                    <div className="OrganizationPageSettings__subText">
                      formerly <code>displayactiverangeonly</code> and{' '}
                      <code>open_catalog</code>
                    </div>
                  </>
                }
                help={
                  <>
                    Define if manufacturers can share a product even if the
                    retailer does not have it in their active range.
                    <ul>
                      <li>
                        the unchecked value corresponds to having the old
                        setting <code>displayactiverangeonly</code>
                      </li>
                      <li>
                        the checked value corresponds to having the old setting{' '}
                        <code>open_catalog</code>
                      </li>
                    </ul>
                  </>
                }
                path={[settings.ALLOW_PUSH]}
                checkedValue
                uncheckedValue={false}
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="Export synchronization status"
                help="Will show synchronization status on each sharing unit"
                path={[settings.EXPORT_SYNCHRONIZATION_STATUS]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="Export Registry"
                help="Will register any organization gtin modification for further export"
                path={[settings.EXPORT_REGISTRY]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="Listing"
                help="Define if the retailer accepts listings."
                path={[settings.LISTING]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                syncOthers={[
                  {
                    path: [settings.LISTING_COMPULSORY],
                    checkedValue: true,
                    uncheckedValue: false,
                  },
                ]}
                disabled={isReadOnly}
              >
                {this.hasListing() && [
                  <SimpleSettingController
                    key="compulsory"
                    label="Compulsory"
                    help="Define if at least one listing is required for share."
                    path={[settings.LISTING_COMPULSORY]}
                    checkedValue
                    uncheckedValue={false}
                  />,
                  <EnumRadioController
                    key="hierarchy"
                    path={[settings.LISTING_HIERARCHY]}
                    defaultValue={settings.VALUE_LISTING_HIERARCHY_NONE}
                    options={[
                      {
                        value: settings.VALUE_LISTING_HIERARCHY_NONE,
                        label: 'None',
                      },
                      {
                        value: settings.VALUE_LISTING_HIERARCHY_MATURE,
                        label: 'Mature',
                      },
                      {
                        value: settings.VALUE_LISTING_HIERARCHY_IMMATURE,
                        label: 'Immature',
                      },
                    ]}
                    disabled={isReadOnly}
                  />,
                  <EnumRadioController
                    key="price-displayunits"
                    label="Price waterfalls requirements for display units"
                    help="Define if the retailer needs a price for only the display unit, all consumer units or all of them."
                    path={[settings.PRICE_DISPLAYUNITS]}
                    defaultValue={settings.VALUE_PRICE_DISPLAYUNITS_DISPLAY}
                    options={[
                      {
                        value: settings.VALUE_PRICE_DISPLAYUNITS_DISPLAY,
                        label: 'Display unit only',
                      },
                      {
                        value: settings.VALUE_PRICE_DISPLAYUNITS_CONSUMERUNITS,
                        label: 'Consumer units only',
                        help: 'This will apply only for direct children of the display unit.',
                      },
                      {
                        value: settings.VALUE_PRICE_DISPLAYUNITS_ALL,
                        label: 'All of the above',
                      },
                    ]}
                    disabled={isReadOnly}
                  />,
                  <EnumRadioController
                    key="price-hlu"
                    label="Price waterfalls requirements for heterogeneous logistical units"
                    help="Define if the retailer needs a price for only the heterogeneous unit, all consumer units or all of them."
                    path={[settings.PRICE_HLU]}
                    defaultValue={settings.VALUE_PRICE_HLU_HETEROGENEOUS}
                    options={[
                      {
                        value: settings.VALUE_PRICE_HLU_HETEROGENEOUS,
                        label: 'Heterogeneous logistical unit only',
                      },
                      {
                        value: settings.VALUE_PRICE_HLU_CONSUMERUNITS,
                        label: 'Consumer units only',
                        help: 'This will apply only for direct children of the heterogeneous unit.',
                      },
                      {
                        value: settings.VALUE_PRICE_HLU_ALL,
                        label: 'All of the above',
                      },
                    ]}
                    disabled={isReadOnly}
                  />,
                ]}
              </SimpleSettingController>
              <SimpleSettingController
                label="Auto accept creations"
                help="Define if the retailer auto accept new products and new listings"
                path={[settings.AUTO_ACCEPT_SHARE_CREATE]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="Auto accept updates"
                help="Define if the retailer auto accept product and listing updates"
                path={[settings.AUTO_ACCEPT_SHARE_UPDATE]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="Recipient activation alert"
                help="Define if the activation of this recipient by a manufacturer should be followed by support for additional manual actions"
                path={[settings.ALERT_ON_RECIPIENT_ACTIVATION]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled={isReadOnly}
              />
            </div>
          )}
          <SimpleSettingController
            label="Restricted recipients"
            help="When active, only organization linked to this one will be able to see this organization."
            path={[settings.RECIPIENTS_RULE]}
            checkedValue={settings.VALUE_RECIPIENT_RULE_RESTRICTED}
            uncheckedValue={settings.VALUE_RECIPIENT_RULE_ALL}
            disabled={isReadOnly}
          />
        </Section>
        {this.isRetailer() && (
          <Section label="Exportability">
            <TagOnShareRadioController
              id="TagOrUntagRadioController"
              isReadOnly={isReadOnly}
            />
            <SimpleSettingController
              label="Single exportable gtin"
              help="When enabled, the retailer will only have 1 GTIN max tagged as exportable. Replaces the legacy “SCO” retailers behavior"
              path={[settings.SINGLE_EXPORTABLE_GTIN]}
              checkedValue={true}
              uncheckedValue={false}
              disabled={isReadOnly}
            />
          </Section>
        )}
        {this.isRetailer() && (
          <Section label="Data collection">
            <InformationRequest disabled={isReadOnly} />
          </Section>
        )}
        <Section label="Chat">
          {(this.isRetailer() || this.isThirdParty()) && (
            <div>
              <SimpleSettingController
                label="Organization"
                help="Define if the retailer can use the organization chat."
                path={[settings.CHAT_ORGANIZATION_ENABLED]}
                checkedValue
                uncheckedValue={false}
                defaultValue
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="ProductVersion"
                help="Define if the retailer can use product comments."
                path={[settings.CHAT_PRODUCTVERSION_ENABLED]}
                checkedValue
                uncheckedValue={false}
                defaultValue
                disabled={isReadOnly}
              />
            </div>
          )}
        </Section>
        <Section label="Sourcing">
          {this.isManufacturer() && (
            <div>
              <SimpleSettingController
                label="Eligible for sourcing"
                help="When active, the manufacturer will be eligible for sourcing."
                path={[settings.ELIGIBLE_FOR_SOURCING]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled={isReadOnly}
              />
              <SimpleSettingController
                label="Has accepted sourcing"
                help="When active, the manufacturer has explicitely accepted sourcing. READONLY"
                path={[settings.ACCEPTED_SOURCING]}
                checkedValue={settings.VALUE_ENABLE}
                uncheckedValue={settings.VALUE_DISABLE}
                disabled
              />
            </div>
          )}
          <SimpleSettingController
            label="View sourcing"
            help="Define if the organization has access to the sourcing."
            path={[settings.VIEW_SOURCING]}
            checkedValue={settings.VALUE_ENABLE}
            uncheckedValue={settings.VALUE_DISABLE}
            disabled={isReadOnly}
          />
          <SimpleSettingController
            label="Sourcinglabels sharing"
            help="Define if the organization can share its sourcinglabels."
            path={[settings.SOURCINGLABEL_SHARE]}
            checkedValue={settings.VALUE_ENABLE}
            uncheckedValue={settings.VALUE_DISABLE}
            disabled={isReadOnly}
          />
        </Section>
        {this.isRetailer() && (
          <>
            <Section label="Exports">
              <MatrixSettingController
                help={this.specificExports.help}
                labels={this.specificExports.labels}
                path={this.specificExports.path}
                disabled={isReadOnly}
              />
            </Section>
            <Section label="Supplier">
              <SupplierDirectory isReadOnly={isReadOnly} />
            </Section>
          </>
        )}
        <hr />
        <ListReporting isReadOnly={isReadOnly} />
        <hr />
        <ReadOnlyFields isReadOnly={isReadOnly} />
      </div>
    );
  }

  render() {
    const { areSettingsEdited } = this.props;
    return (
      <div className="OrganizationPageSettings OrganizationPageBlock">
        <div className="OrganizationPageBlock__header">
          <h2>Settings</h2>
          <span className="OrganizationPageBlock__edited">
            {areSettingsEdited && (
              <span>
                <i className="mdi mdi-alert" />
                Edited
              </span>
            )}
          </span>
        </div>
        {this.renderSettings()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(OrganizationPageSettings);
