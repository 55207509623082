import classNames from 'classnames';

import { Tag } from '@alkem/react-ui-inputs';

import { ruleEntityTypesMap } from '../../constants';

import './validation-rules-filters.scss';

const ActiveFilterLabel = ({ filterKey, filter, onDelete }) => {
  const getKeyLabel = () => {
    switch (filterKey) {
      case 'ruleSetIdIn':
        return 'Ruleset';
      case 'entityTypeIn':
        return 'Entity Type';
      case 'appliesOnPkIdIn':
        return 'Rules applied on product';
      case 'usedByRetailerIdIn':
        return 'Organization';
      case 'ruleSetTypeIn':
        return 'Ruleset Type';
      case 'onlyActive':
        return 'Only active rules';
      default:
        return filterKey;
    }
  };

  const getFilterValue = () => {
    if (filterKey === 'entityTypeIn') {
      return ruleEntityTypesMap[filter].label;
    }
    if (typeof filter === 'string') {
      return filter;
    }
    return filter.label;
  };

  return (
    <Tag
      className={classNames({
        TagsInput__tag: true,
        ActiveFilterLabel_fullWidth: true,
      })}
      onDelete={onDelete}
      label={`${getKeyLabel()}: ${getFilterValue()}`}
    />
  );
};
export { ActiveFilterLabel };
