import { fromJS } from 'immutable';

import { createReducer } from 'utils/reducers';
import {
  addFilter,
  changeLimit,
  clearFilter,
  initPagination,
  nextPage,
  previousPage,
  receiveReducer,
  removeFilter,
  updateFilter,
} from 'utils/reducers/list';

import * as events from '../constants/events';

const LIMIT_STORAGE_KEY = 'gdsnCISListLimit';
const SORTING_STORAGE_KEY = 'gdsnCISListSorting';

const initialState = {
  list: [],
  modal: {
    isOpen: false,
    cis: fromJS({
      gln: null,
      dataRecipient: null,
      targetMarketCountryCode: null,
      gpcCategoryCode: null,
      gtin: null,
      documentCommand_type: 'ADD',
      validate: true,
    }),
  },
  filters: {
    recipient_gln: [],
    source_gln: [],
  },
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
};

export default createReducer(initialState, {
  [events.RECEIVE_GDSN_CIS_LIST]: (state, action) =>
    receiveReducer(state, action.payload),
  [events.CHANGE_LIMIT_GDSN_CIS_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.NEXT_PAGE_GDSN_CIS_LIST]: nextPage,
  [events.PREVIOUS_PAGE_GDSN_CIS_LIST]: previousPage,
  [events.RECEIVE_GDSN_CIS_LIST_FILTERS]: (state, action) => {
    const newState = { ...state };
    newState.filtersRaw = fromJS(action.payload.data);
    return newState;
  },
  [events.ADD_GDSN_CIS_LIST_FILTER]: addFilter,
  [events.REMOVE_GDSN_CIS_LIST_FILTER]: removeFilter,
  [events.UPDATE_GDSN_CIS_LIST_FILTER]: updateFilter,
  [events.CLEAR_GDSN_CIS_LIST_FILTER]: clearFilter,
  [events.OPEN_GDSN_CIS_MODAL]: (state, action) => {
    const newState = { ...state };
    newState.modal = {
      isOpen: true,
      cis: initialState.modal.cis.mergeDeep(fromJS(action.payload || {})),
    };
    return newState;
  },
  [events.CLOSE_GDSN_CIS_MODAL]: (state) => {
    const newState = { ...state };
    newState.modal = {
      isOpen: false,
      cis: initialState.modal.cis,
    };
    return newState;
  },
  [events.GDSN_CIS_ATTR_UPDATE]: (state, action) => {
    const newState = { ...state };
    newState.modal = {
      ...state.modal,
      cis: state.modal.cis.setIn(action.payload.key, action.payload.value),
    };
    return newState;
  },
});
