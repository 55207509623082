import TransactionApi from 'resources/transactionApi';

export async function getTrace(transactionId: number) {
  try {
    const response = await TransactionApi.get(
      `/transaction/v2/tracing/${transactionId}`,
    );
    return { response };
  } catch (error: any) {
    return {
      error: error.data && error.data.message ? error.data.message : error,
    };
  }
}

export async function getTransactions(transactiondIds: number[]) {
  try {
    const body = { ids: transactiondIds, limit: transactiondIds.length };
    const response = await TransactionApi.Search(body);
    return { response };
  } catch (error: any) {
    return {
      error: error.data && error.data.message ? error.data.message : error,
    };
  }
}
