import qs from 'querystringify';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useQueryParams<T = { [key: string]: string }>() {
  const { search } = useLocation();
  return useMemo(() => qs.parse(search) as T, [search]);
}

export function useUpdateQueryParams() {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  return useCallback(
    (params: { [key: string]: any }) => {
      const currentParams = qs.parse(search);
      navigate({
        pathname,
        search: qs.stringify({ ...currentParams, ...params }),
      });
    },
    [navigate, search, pathname],
  );
}

export function updateQueryParams(queries: { [key: string]: any }) {
  window.postMessage(
    { type: 'ROOT_APP_UPDATE_QUERY_PARAMS', queries },
    window.location.origin,
  );
}
