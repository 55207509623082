import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import StatusBar from 'components/ui/status-bar';
import { separateActions } from 'utils/redux';

import { downloadGDSNDocument } from '../../actions';

import './row.scss';

const mapDispatchToProps = {
  downloadGDSNDocument,
};

export class GDSNCISListRow extends Component {
  static propTypes = {
    cis: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      downloadGDSNDocument: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  onClickExpand = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  getDocumentState(doc) {
    return doc.transaction.ack.status !== 'ACCEPTED' ? 'FAILURE' : 'SUCCESS';
  }

  getCISState(entity) {
    return !entity.documents ||
      entity.documents.filter(
        (doc) => doc.transaction.ack.status === 'ACCEPTED',
      ).length === 0
      ? 'FAILURE'
      : 'SUCCESS';
  }

  downloadDocument(messageID) {
    return () => this.props.actions.downloadGDSNDocument(messageID);
  }

  renderDocuments() {
    const { cis } = this.props;
    let lines = null;
    if (cis.documents !== undefined) {
      lines = cis.documents.map((doc) => (
        <div key={doc.id} className="GDSNCISListRow GDSNCISListRow_mini row">
          <StatusBar type={this.getDocumentState(doc)} />
          <div className="col-xs-1">{doc.type}</div>
          <div className="col-xs-2">{doc.createdAt}</div>
          <div className="col-xs-2">{doc.status}</div>
          <div className="col-xs-1">{doc.message.state}</div>
          <div className="col-xs-1">
            <i
              className="mdi mdi-download GDSNCISListDetailsIconDoc"
              onClick={this.downloadDocument(doc.message.id)}
            />
          </div>
          <div className="col-xs-1">{doc.transaction.command.type}</div>
          <div className="col-xs-3">
            {`${
              doc.transaction.ack.document_id
                ? `${doc.transaction.ack.status} @ ${doc.transaction.ack.createdAt}`
                : 'Not received'
            } `}
            {doc.transaction.ack.document_id && (
              <i
                className="mdi mdi-download GDSNCISListDetailsIconDoc"
                onClick={this.downloadDocument(doc.transaction.ack.document_id)}
              />
            )}
          </div>
        </div>
      ));
    }
    return (
      <div className="GDSNCISListRowWrapper">
        <div className="GDSNCISListRow GDSNCISListRow_mini GDSNCISListRow__Header row">
          <div className="col-xs-1">Type</div>
          <div className="col-xs-2">Created At</div>
          <div className="col-xs-2">Status</div>
          <div className="col-xs-1">State</div>
          <div className="col-xs-1">Download</div>
          <div className="col-xs-1">Type</div>
          <div className="col-xs-3">GS1Response</div>
        </div>
        <div className="GDSNCISListRow__Lines">{lines}</div>
      </div>
    );
  }

  renderParty(party) {
    if (party.organizations.length > 0) {
      return (
        <div className="GDSNCISListRow_boldy">
          <i className="mdi mdi-domain" />
          {` ${party.organizations[0].nameLegal} (${party.organizations[0].id})`}
          {party.organizations.length > 1 &&
            ` +${party.organizations.length - 1}`}
        </div>
      );
    }
    if (party.nameLegal === null) {
      return <div />;
    }
    return (
      <div className="GDSNCISListRow_boldy">
        <i className="mdi mdi-domain" />
        {` ${party.nameLegal}`}
      </div>
    );
  }

  render() {
    const { cis } = this.props;
    const { isExpanded } = this.state;
    const expandClass = {
      GDSNCISListDetailsIcon: true,
      'col-xs-0.5': true,
      mdi: true,
      'mdi-chevron-up': cis.documents.length > 0 && isExpanded,
      'mdi-chevron-down': cis.documents.length > 0 && !isExpanded,
      hidden: cis.documents.length === 0,
    };
    return (
      <div className="GDSNCISListRowWrapper">
        <div key={cis.id} id={cis.id} className="GDSNCISListRow row">
          <StatusBar type={this.getCISState(cis)} />
          <div className="col-xs-3">
            {this.renderParty(cis.recipient)}
            <div>
              <i className="mdi mdi-apps" />
              {` ${cis.recipient.gln}`}
            </div>
            {cis.recipient.datapool.name !== '' && (
              <div>
                <i className="mdi mdi-steam" />
                {` ${cis.recipient.datapool.name}`}
              </div>
            )}
          </div>
          <div className="col-xs-2">
            <center>
              <div>
                <i
                  className={`mdi ${
                    this.getCISState(cis) === 'SUCCESS'
                      ? 'mdi-sync'
                      : 'mdi-sync-alert'
                  } GDSNCISListDetailsIcon`}
                />
              </div>
              <div>
                {cis.documents.length > 0 && `${cis.documents[0].createdAt}`}
              </div>
            </center>
          </div>
          <div className="col-xs-5">
            {cis.source && this.renderParty(cis.source)}
            <div>
              {cis.source && `GLN: ${cis.source.gln}`}
              {cis.gtin && ` | GTIN: ${cis.gtin}`}
              {cis.targetMarket && ` | TM: ${cis.targetMarket}`}
              {cis.gpc && ` | GPC: ${cis.gpc}`}
            </div>
            {cis.source && cis.source.datapool.name !== '' && (
              <div>
                <i className="mdi mdi-steam" />
                {` ${cis.source.datapool.name}`}
              </div>
            )}
          </div>
          <div className="col-xs-1 GDSNCISListRow_actions">
            <div
              onClick={this.onClickExpand}
              className={classNames(expandClass)}
            />
          </div>
        </div>
        {isExpanded ? this.renderDocuments() : null}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions,
)(GDSNCISListRow);
