import { notificationError, notificationSuccess } from 'actions/notifications';
import { selectPermissions } from 'modules/auth/reducer';
import { selectUser } from 'reducers/user';
import authApi from 'resources/authApi';
import { getIn } from 'utils/immutable';
import { hasFullPower } from 'utils/user';

import { createAction } from '../../../utils/actions';
import {
  USER_UPDATE_FORM_UPDATE_INPUT,
  USER_UPDATE_MODAL_CLOSE,
  USER_UPDATE_MODAL_LOADING,
  USER_UPDATE_MODAL_OPEN,
  USER_UPDATE_MODAL_TOKENS_RECEIVED,
} from '../constants/events';
import {
  selectFormFirstname,
  selectFormLastname,
  selectFormOrganization,
  selectFormOrganizationRole,
  selectFormPhonenumber,
  selectFormTitle,
  selectFormType,
  selectFormUsername,
  selectModalUser,
} from '../selectors/modal';

export const openUserUpdateModal = createAction(USER_UPDATE_MODAL_OPEN);
export const closeUserUpdateModal = createAction(USER_UPDATE_MODAL_CLOSE);
export const userUpdateModalIsLoading = createAction(USER_UPDATE_MODAL_LOADING);

export const updateFormInput = createAction(USER_UPDATE_FORM_UPDATE_INPUT);
export const userTokensReceived = createAction(
  USER_UPDATE_MODAL_TOKENS_RECEIVED,
);

const shouldMoveOrganization = (user, organization) => {
  const currentOrganizationId = user.getIn(['belongsTo', 0, 'id']);
  return organization.get('value') !== currentOrganizationId;
};

const shouldUpdateOrganizationRole = (user, organization, role) => {
  const currentOrganizationId = user.getIn(['belongsTo', 0, 'id']);
  const currentRole = user.getIn(['belongsTo', 0, 'permissions', 0]);
  return (
    organization.get('value') === currentOrganizationId &&
    currentRole !== role.get('value')
  );
};

export const switchOrganization = () => (dispatch, getState) => {
  const state = getState();
  const user = selectModalUser(state);

  const organization = selectFormOrganization(state);
  if (shouldMoveOrganization(user, organization)) {
    const organizationRole = selectFormOrganizationRole(state);
    return authApi
      .UserMove(
        user.toJS(),
        { id: organization.get('value') },
        organizationRole.get('value'),
      )
      .then(
        () => null,
        (err) => getIn(err, 'data.message') || 'Unexpected error',
      );
  }
  return Promise.resolve(null);
};

export const upsertOrganizationRole = () => (dispatch, getState) => {
  const state = getState();
  const user = selectModalUser(state);

  const organization = selectFormOrganization(state);
  const organizationRole = selectFormOrganizationRole(state);
  if (!shouldUpdateOrganizationRole(user, organization, organizationRole)) {
    return Promise.resolve(null);
  }

  return authApi
    .UserUpsertUserWithRole(
      user.get('id'),
      organization.get('value'),
      organizationRole.get('value'),
    )
    .then(
      () => null,
      (err) => {
        if (getIn(err, 'data.status') === 402) {
          return 'User organization does not allows this role';
        } else if (getIn(err, 'data.message')) {
          return getIn(err, 'data.message');
        }
        return 'Unexpected error';
      },
    );
};

export const updateUser = () => (dispatch, getState) => {
  const state = getState();
  const user = selectModalUser(state);
  let payload = user;

  const firstname = selectFormFirstname(state);
  const lastname = selectFormLastname(state);
  const title = selectFormTitle(state);
  const username = selectFormUsername(state);
  const phonenumber = selectFormPhonenumber(state);
  const type = selectFormType(state);

  if (firstname && firstname.length && user.get('firstname') !== firstname) {
    payload = payload.set('firstname', firstname);
  }
  if (lastname && lastname.length && user.get('lastname') !== lastname) {
    payload = payload.set('lastname', lastname);
  }
  if (username && username.length && user.get('username') !== username) {
    payload = payload.set('username', username);
  }
  if (title && title.length && user.get('title') !== title) {
    payload = payload.set('title', title);
  }
  if (
    phonenumber &&
    phonenumber.length &&
    user.get('phonenumber') !== phonenumber
  ) {
    payload = payload.set('phonenumber', phonenumber);
  }
  if (type !== user.get('type')) {
    payload = payload.set('type', type);
  }

  if (payload !== user) {
    return authApi.UserUpdate(payload.toJS()).then(
      () => null,
      (err) => getIn(err, 'data.message') || 'Unexpected error',
    );
  }
  return Promise.resolve(null);
};

export const update = () => (dispatch, getState) => {
  const user = selectModalUser(getState());
  dispatch(userUpdateModalIsLoading(true));
  return Promise.all([
    dispatch(updateUser()),
    dispatch(switchOrganization()),
    dispatch(upsertOrganizationRole()),
  ]).then(
    ([updateError, switchError, roleError]) => {
      const error = updateError || switchError || roleError;
      if (!error) {
        dispatch(closeUserUpdateModal(true));
        dispatch(
          notificationSuccess(
            `${user.get('firstname')} ${user.get(
              'lastname',
            )} account has been updated`,
          ),
        );
      } else {
        [
          updateError ? `Data: ${updateError}` : null,
          switchError ? `Organization move: ${switchError}` : null,
          roleError ? `Organization role ${roleError}` : null,
        ]
          .filter((err) => !!err)
          .forEach((error) =>
            dispatch(
              notificationError(
                `An error occured while updating the user : ${error}`,
                'modal',
              ),
            ),
          );
        dispatch(userUpdateModalIsLoading(false));
      }
    },
    (err) => {
      dispatch(userUpdateModalIsLoading(false));
      return err;
    },
  );
};

export const activateUser = () => (dispatch, getState) => {
  const user = selectModalUser(getState());
  dispatch(userUpdateModalIsLoading(true));
  return authApi.UserReactivate({ id: user.get('id') }).then(
    () => {
      dispatch(closeUserUpdateModal(true));
      dispatch(
        notificationSuccess(
          `${user.get('firstname')} ${user.get(
            'lastname',
          )} account has been activated`,
        ),
      );
    },
    (err) => {
      dispatch(notificationError('Activation failed', 'modal'));
      dispatch(userUpdateModalIsLoading(false));
      return err;
    },
  );
};

export const deactivateUser = () => (dispatch, getState) => {
  const user = selectModalUser(getState());
  dispatch(userUpdateModalIsLoading(true));
  return authApi.UserDeactivate({ id: user.get('id') }).then(
    () => {
      dispatch(closeUserUpdateModal(true));
      dispatch(
        notificationSuccess(
          `${user.get('firstname')} ${user.get(
            'lastname',
          )} account has been deactivated`,
        ),
      );
    },
    (err) => {
      dispatch(notificationError('Deactivation failed', 'modal'));
      dispatch(userUpdateModalIsLoading(false));
      return err;
    },
  );
};

export const acceptUser = (userId, organizationId) => (dispatch, getState) => {
  const user = selectModalUser(getState());
  dispatch(userUpdateModalIsLoading(true));
  return authApi.UserAcceptInOrganization(userId, organizationId).then(
    () => {
      dispatch(closeUserUpdateModal(true));
      dispatch(
        notificationSuccess(
          `${user.get('firstname')} ${user.get(
            'lastname',
          )} account has been accepted`,
        ),
      );
    },
    (err) => {
      dispatch(notificationError('Acceptation failed', 'modal'));
      dispatch(userUpdateModalIsLoading(false));
      return err;
    },
  );
};

export const expireTokens = () => (dispatch, getState) => {
  const user = selectModalUser(getState());
  dispatch(userUpdateModalIsLoading(true));
  return authApi.UserExpireTokens(user.get('id')).then(
    () => {
      dispatch(closeUserUpdateModal(true));
      dispatch(
        notificationSuccess(
          `${user.get('firstname')} ${user.get(
            'lastname',
          )} refresh tokens have been expired`,
        ),
      );
    },
    (err) => {
      dispatch(notificationError('Expiration failed', 'modal'));
      dispatch(userUpdateModalIsLoading(false));
      return err;
    },
  );
};

export const listTokens = () => (dispatch, getState) => {
  const user = selectModalUser(getState());
  const permissions = selectPermissions(getState());
  if (
    permissions.organizations.indexOf('organization.user.update') === -1 &&
    !hasFullPower(selectUser(getState()))
  ) {
    return [];
  }
  return authApi.UserListTokens(user.get('id')).then(
    (tokens) => {
      dispatch(userTokensReceived(tokens.data.data));
    },
    (err) => {
      dispatch(notificationError('Token listing failed', 'modal'));
      return err;
    },
  );
};
