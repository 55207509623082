import * as events from './events';

export const init = () => ({ type: events.INIT });

export const fetchFilters = () => ({ type: events.FETCH_FILTERS });

export const updateSelectedCurrent = (current) => ({
  type: events.UPDATE_SELECTED_CURRENT,
  payload: current,
});

export const updateSelectedFilename = (filename) => ({
  type: events.UPDATE_SELECTED_FILENAME,
  payload: filename,
});

export const updateSelectedLang = (lang) => ({
  type: events.UPDATE_SELECTED_LANG,
  payload: lang,
});

export const fetchFiles = () => ({ type: events.FETCH_FILES });

export const updateLatestFile = (fileID) => ({
  type: events.UPDATE_LATEST_FILE,
  fileID: fileID,
});

export const fetchOneFile = (fileID, filename) => ({
  type: events.FETCH_ONE_FILE,
  fileID,
  filename,
});

export const fetchedOneFile = (file) => ({
  type: events.FETCHED_ONE_FILE,
  payload: file,
});

export const discardOneFile = (content) => ({
  type: events.DISCARD_ONE_FILE,
  payload: content,
});

export const downloadOneFile = (fileID, filename) => ({
  type: events.DOWNLOAD_ONE_TRANSLATION,
  fileID,
  filename,
});

export const downloadAllFiles = () => ({
  type: events.DOWNLOAD_ALL_TRANSLATIONS,
});

export const nextFilesPage = () => ({ type: events.NEXT_FILES_PAGE });

export const previousFilesPage = () => ({ type: events.PREVIOUS_FILES_PAGE });

export const changeFilesPageSize = (size) => ({
  type: events.CHANGE_FILES_PAGE_SIZE,
  payload: size,
});
