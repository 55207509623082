import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { Modal } from '@alkem/react-ui-modal';
import { ProgressBar } from '@alkem/react-ui-progress';

import { notificationSuccess } from 'actions/notifications';
import {
  bulkValidateSuggestions,
  closeModal,
} from 'modules/field-suggestion/actions';
import {
  selectList,
  selectModalState,
  selectValidatedEntitiesIds,
  selectValidating,
  selectValidationErrors,
} from 'modules/field-suggestion/selectors';
import { separateActions } from 'utils/redux';

import './index.scss';

const mapStateToProps = (state) => ({
  list: selectList(state),
  modalState: selectModalState(state),
  validating: selectValidating(state),
  errors: selectValidationErrors(state),
  validatedEntitiesIds: selectValidatedEntitiesIds(state),
});

const mapDispatchToProps = {
  notificationSuccess,
  bulkValidateSuggestions,
  closeModal,
};

export class FieldSuggestionValidationModal extends Component {
  static propTypes = {
    validatedEntitiesIds: PropTypes.array.isRequired,
    validating: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    modalState: PropTypes.object.isRequired,
    list: ImmutablePropTypes.list.isRequired,
    actions: PropTypes.shape({
      notificationSuccess: PropTypes.func.isRequired,
      bulkValidateSuggestions: PropTypes.func.isRequired,
      closeModal: PropTypes.func.isRequired,
    }),
  };

  componentDidUpdate(prevProps) {
    const { actions, list, validating, validatedEntitiesIds, errors } =
      this.props;
    const count = list.filter((e) => e.get('checked')).size;
    if (
      list !== prevProps.list ||
      validating !== prevProps.validating ||
      validatedEntitiesIds !== prevProps.validatedEntitiesIds ||
      errors !== prevProps.errors
    ) {
      if (!validating && validatedEntitiesIds.length === count) {
        if (errors.length === 0) {
          actions.closeModal();
          actions.notificationSuccess(
            `${validatedEntitiesIds.length} field suggestions were correctly validated`,
          );
        }
      }
    }
  }

  onValidate = () => {
    const { actions, modalState } = this.props;
    actions.bulkValidateSuggestions(modalState.id === 'validate');
  };

  getStatus() {
    const { validating, errors } = this.props;
    if (validating) {
      return 'info';
    } else if (errors.length) {
      return 'danger';
    }
    return 'success';
  }

  renderProgressBar(max) {
    const { validatedEntitiesIds } = this.props;
    const savedCount = validatedEntitiesIds.length;
    return (
      <div className="BulkEditModalSave__progressBar">
        <ProgressBar
          value={savedCount}
          max={max}
          color={this.getStatus()}
          height="medium"
        />
      </div>
    );
  }

  renderFailureReportHeader() {
    return (
      <div className="FieldSuggestionValidationModal__failuresHeader row">
        <div className="col-xs-1">PV id</div>
        <div className="col-xs-5">Name</div>
        <div className="col-xs-2">FieldMetaData</div>
        <div className="col-xs-3">Field</div>
        <div className="col-xs-1">Error</div>
      </div>
    );
  }

  renderFailedEntity(error) {
    const id = error.entity.get('id');
    return (
      <div key={id} className="FieldSuggestionValidationModal__failedRow row">
        <div className="col-xs-1">
          {error.entity.getIn(['metadata', 'productversion_id'])}
        </div>
        <div className="col-xs-5">
          <Ellitips
            label={error.entity.getIn(['metadata', 'name'])}
            id={`name-${id}`}
          />
        </div>
        <div className="col-xs-2">
          <Ellitips
            label={error.entity.getIn(['field', 'fieldmetadata_name'])}
            id={`fmd-${id}`}
          />
        </div>
        <div className="col-xs-3">
          <Ellitips
            label={error.entity.getIn(['field', 'field_name'])}
            id={`field-${id}`}
          />
        </div>
        <div className="col-xs-1">
          <HelpTooltip id={`helptooltip-${id}`} message={error.error} />
        </div>
      </div>
    );
  }

  render() {
    const {
      actions,
      list,
      validating,
      validatedEntitiesIds,
      errors,
      modalState,
    } = this.props;
    const count = list.filter((e) => e.get('checked')).size;

    return (
      <Modal
        title={`${modalState.label} Products`}
        confirmButtonText={modalState.label}
        isProcessing={validating}
        onConfirm={this.onValidate}
        onClose={actions.closeModal}
      >
        <div>
          <div>
            <span>
              Do you wish to <b>{modalState.id}</b> {count}
            </span>
            <span> field suggestion{count === 1 ? '' : 's'}?</span>
          </div>
          {validatedEntitiesIds.length || validating
            ? this.renderProgressBar(count)
            : null}
          {!!errors.length && (
            <div className="FieldSuggestionValidationModal__failures">
              <h3 className="FieldSuggestionValidationModal__failureTitle">
                Failure report
              </h3>
              {this.renderFailureReportHeader()}
              {errors.map((error) => this.renderFailedEntity(error))}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(FieldSuggestionValidationModal);
