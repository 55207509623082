import { List as ImmutableList } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from './events';

const initialState = {
  allRulesets: ImmutableList(),
};

export default createReducer(initialState, {
  [events.RECEIVE_ALL_RULE_SETS]: (state, { rulesets: allRulesets }) => ({
    ...state,
    allRulesets,
  }),
});
