import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Radio } from '@alkem/react-ui-inputs';
import { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';

import { GET_FIELD_LIST } from '../../../../actions/constants';
import { selectFieldOptions } from '../../../../selectors';
import {
  ExportMapping,
  ExportMappingPayloadSimple,
  FieldOption,
} from '../../../../types';
import { ExportMappingsTurboSelector } from '../../turbo-selector';
import { isExportMappingPayloadCast, isFieldOption } from '../../utils';
import styles from '../simple-element-item-type.module.scss';

interface Props {
  name: string;
  exportMapping: ExportMapping;
  dispatchSetExportMapping: ({
    cast,
    field,
    use_su,
    referential,
    is_declinable,
    value,
    custom_parse,
  }: ExportMappingPayloadSimple) => void;
  showEditionFields: boolean;
}
export function ExportMappingsSimpleElementItemFieldTypePropsSelector({
  name,
  exportMapping,
  dispatchSetExportMapping,
  showEditionFields,
}: Props) {
  const { payload } = exportMapping;
  const dispatch = useDispatch();
  const fieldOptions = useSelector(selectFieldOptions);
  const [category, setCategory] = useState(
    payload.use_su ? 'sharingUnit' : 'consumerUnit',
  );
  const [fieldSearchValue, setFieldSearchValue] = useState<string | undefined>(
    undefined,
  );
  const [fieldOption, setFieldOption] = useState<FieldOption | undefined>(
    undefined,
  );
  const [field, setField] = useState(payload.field || undefined);

  const dispatchGetFieldList = useCallback(
    ({ entityType, search }: { entityType?: string; search?: string }) => {
      dispatch({
        type: GET_FIELD_LIST,
        entityType: entityType || category,
        search: search ?? fieldSearchValue,
        sxmListParentField: exportMapping.sxm_list_parent?.field,
      });
    },
    [dispatch, category, fieldSearchValue],
  );

  useEffect(() => {
    dispatchGetFieldList({});
  }, []);

  const clearFieldSelectorFields = () => {
    setFieldSearchValue(undefined);
    setFieldOption(undefined);
    setField(undefined);
  };

  const updateCategory = (event: { target: { value: string } }) => {
    const { value } = event.target;
    setCategory(value);
    dispatchGetFieldList({ entityType: value, search: '' });
    clearFieldSelectorFields();
    dispatchSetExportMapping({
      use_su: value === 'sharingUnit' || undefined,
    });
  };

  const updateSearchValue = useCallback(
    (event: { target: { value: string } }): void => {
      const { value } = event.target;
      setFieldSearchValue(value);
      dispatchGetFieldList({ search: value });
    },
    [setFieldSearchValue, category],
  );

  const updateField = (value: TurboSelectOption<FieldOption>) => {
    const { use_su } = payload;
    if (isFieldOption(value)) {
      setFieldOption(value);
      setField(value.label);
      const { data } = value;
      const referential: string | undefined =
        data.referential || data.declinable_by;
      const is_declinable: boolean | undefined =
        !!data.declinable_by || undefined;
      let cast;
      if (typeof value.data.type !== 'undefined') {
        cast = isExportMappingPayloadCast(value.data.type)
          ? value.data.type
          : undefined;
      }
      dispatchSetExportMapping({
        cast,
        field: value.label,
        use_su,
        referential,
        is_declinable,
      });
    } else {
      setFieldOption(undefined);
      setField(undefined);
      dispatchSetExportMapping({
        use_su,
      });
    }
  };

  return (
    <div>
      <div
        className={classNames({
          [styles.disablesCategoryPropertyContainer]:
            !!exportMapping.sxm_list_parent,
        })}
        data-testid={`gdsn-export-mappings-simple-element-${name}-category-property`}
      >
        <InputWithLabel
          inputId={`gdsn-export-mappings-simple-element-${name}-category-property`}
          label="Category"
        >
          <Radio
            id={`gdsn-export-mappings-simple-element-${name}-category-property`}
            value={category}
            options={[
              { label: 'Consumer unit', value: 'consumerUnit' },
              { label: 'Sharing unit', value: 'sharingUnit' },
            ]}
            onChange={updateCategory}
          />
        </InputWithLabel>
      </div>
      <ExportMappingsTurboSelector
        label="Select a field"
        testId={`gdsn-export-mappings-simple-element-${name}-field-property`}
        options={fieldOptions}
        onSelect={updateField}
        option={fieldOption}
        placeholder={fieldOptions.length === 0 ? 'No field found' : undefined}
        disabled={fieldOptions.length === 0}
        searchProps={
          exportMapping.sxm_list_parent
            ? undefined
            : {
                onSearch: updateSearchValue,
                placeholder: 'Filter fields by name or tag',
                value: fieldSearchValue,
              }
        }
        displayedValueProps={{
          label: 'Field',
          value: field,
        }}
        showEditionFields={showEditionFields}
      />
    </div>
  );
}
