import { notificationError, notificationSuccess } from 'actions/notifications';
import { immutableReferentialAdminApi } from 'resources/referentialAdminApi';
import { immutableReferentialApi } from 'resources/referentialApi';
import { logError } from 'utils';
import { get } from 'utils/immutable';

import * as events from '../constants';
import { getEntities, selectEntityIdsToDelete } from '../selectors';

let getReferentialListPromise;

export const getReferentialList = () => (dispatch) => {
  if (getReferentialListPromise) {
    getReferentialListPromise.cancel();
  }

  getReferentialListPromise = immutableReferentialApi.ReferentialList();
  getReferentialListPromise.then((response) => {
    const list = response.data.data;
    dispatch({
      type: events.RECEIVED_REFERENTIAL_LIST,
      list,
    });
  });

  return getReferentialListPromise;
};

export const getReferentialEntities = (referential) => async (dispatch) => {
  dispatch({
    type: events.SELECT_REFERENTIAL_LOADING,
    refreshing: true,
  });
  const response = await immutableReferentialAdminApi.ReferentialGetList(
    get(referential, 'slug'),
    {
      lang: 'noop',
    },
  );
  const list = response.data.data;
  dispatch({
    type: events.RECEIVED_REFERENTIAL_ENTITIES,
    id: get(referential, 'id'),
    list,
  });
  dispatch({
    type: events.SELECT_REFERENTIAL_LOADING,
    refreshing: false,
  });
  return response;
};

export const getReferentialGroups = (referential) => async (dispatch) => {
  dispatch({
    type: events.SELECT_REFERENTIAL_LOADING,
    refreshing: true,
  });
  const response = await immutableReferentialAdminApi.ReferentialGetGroups(
    get(referential, 'slug'),
  );
  const list = response.data.data;
  dispatch({
    type: events.RECEIVED_REFERENTIAL_GROUPS,
    id: get(referential, 'id'),
    list,
  });
  dispatch({
    type: events.SELECT_REFERENTIAL_LOADING,
    refreshing: false,
  });
  return response;
};

export const deleteReferential = (slug) => async (dispatch) => {
  try {
    await immutableReferentialAdminApi.deleteReferential(slug);
    dispatch(notificationSuccess('Referential deleted'));
    dispatch(getReferentialList());
  } catch (e) {
    dispatch(notificationError('Could not delete referential'));
  }
};

export const updateReferentialTranslatable =
  (referentialId, translatable) => async (dispatch) => {
    try {
      await immutableReferentialAdminApi.updateReferentialTranslatable(
        referentialId,
        translatable,
      );
      dispatch(getReferentialList());
    } catch (e) {
      dispatch(notificationError('Error updating the referential'));
      return { error: e };
    }
    return {};
  };

export const selectReferential = (referential) => (dispatch) => {
  dispatch({
    type: events.SELECT_REFERENTIAL,
    referential,
  });
};

export const selectReferentialGroup = (referentialGroup) => (dispatch) => {
  dispatch({
    type: events.SELECT_REFERENTIAL_GROUP,
    referentialGroup,
  });
};

export const updateEntityKey = (referentialId, index, key, value) => ({
  type: events.UPDATE_ENTITY_KEY,
  referentialId,
  index,
  key,
  value,
});

export const reset = (referential) => async (dispatch) => {
  await dispatch(getReferentialEntities(referential));
};

export const save = (referential) => async (dispatch, getState) => {
  const state = getState();
  const referentialData = getEntities(state)(referential.get('id'));
  const entityIdsToDelete = selectEntityIdsToDelete(state);

  const getSourceByCode = (code) =>
    referentialData.get('source').find((e) => e.get('code') === code);

  try {
    const editedEntities = referentialData
      .get('edited')
      .filter((e) => e !== getSourceByCode(e.get('code')));

    if (!editedEntities.size && !entityIdsToDelete.size) {
      return dispatch(notificationError('No entities edited, not saving'));
    }
    const payload = {
      entities: editedEntities
        .toJS()
        .map(({ code, label, description, rank, data }) => ({
          code,
          label,
          description,
          rank,
          ...data,
        })),
      deleted_entities: entityIdsToDelete.toArray().map((id) => ({ id })),
    };
    await immutableReferentialAdminApi.upsertEntities(
      referential.get('slug'),
      payload,
    );
    dispatch(
      notificationSuccess(`Saved ${referential.get('slug')}. Reloading...`),
    );
    return dispatch(getReferentialEntities(referential));
  } catch (e) {
    return dispatch(
      notificationError(`Error while saving ${referential.get('slug')}.`),
    );
  }
};

export const deleteEntity = (referentialId, index, isNewlyCreated) => ({
  type: events.DELETE_ENTITY,
  referentialId,
  index,
  isNewlyCreated,
});

export const addEntity = (referentialId) => ({
  type: events.ADD_ENTITY,
  referentialId,
});

export const refreshCache = (referentialSlug) => async (dispatch) => {
  dispatch({
    type: events.REFRESHING_REFERENTIAL_CACHE,
    refreshing: true,
  });
  try {
    const query = '/core/v3/metadatacache/referentials';
    const body = {
      referential_slugs: [referentialSlug],
    };
    await immutableReferentialApi.post(query, body);
    dispatch(notificationSuccess('Cache refreshed'));
  } catch (err) {
    dispatch(
      notificationError(
        `Error refreshing cache: ${err.data?.message || 'some other error'}`,
      ),
    );
    console.error(err); // eslint-disable-line
  }
  dispatch({
    type: events.REFRESHING_REFERENTIAL_CACHE,
    refreshing: false,
  });
};

export const createReferentialGroup =
  (referential, groupName) => async (dispatch) => {
    if (groupName.indexOf(' ') >= 0) {
      dispatch(notificationError(`Group name should not contain spaces`));
    } else {
      try {
        await immutableReferentialAdminApi.ReferentialCreateGroup(
          referential.get('slug'),
          groupName,
        );
        dispatch(getReferentialGroups(referential));
      } catch (e) {
        logError(e);
        dispatch(notificationError(`Error while creating ${groupName}.`));
      }
    }
  };

export const addEntitiesToReferentialGroup =
  (referential, referentialGroup, entities) => async (dispatch) => {
    try {
      const entityIds = entities.valueSeq().map((entity) => entity.get('id'));
      await immutableReferentialAdminApi.ReferentialGroupsAddEntities(
        referential.get('slug'),
        referentialGroup.get('name'),
        entityIds,
      );
      await dispatch(getReferentialGroups(referential));
      dispatch(selectReferentialGroup(referentialGroup));
    } catch (e) {
      logError(e);
      dispatch(
        notificationError(
          `Error while creating ${referentialGroup.get('name')}.`,
        ),
      );
    }
  };

export const deleteEntitiesFromReferentialGroup =
  (referential, referentialGroup, entity) => async (dispatch) => {
    try {
      await immutableReferentialAdminApi.ReferentialGroupsDeleteEntity(
        referential.get('slug'),
        referentialGroup.get('name'),
        entity.get('id'),
      );
      await dispatch(getReferentialGroups(referential));
      dispatch(selectReferentialGroup(referentialGroup));
    } catch (e) {
      logError(e);
      dispatch(
        notificationError(
          `Error while creating ${referentialGroup.get('name')}.`,
        ),
      );
    }
  };
