export const GET_EXPORT_MAPPING_LIST =
  'io-gdsn-exports/GET_EXPORT_MAPPING_LIST';
export const GET_FIELD_LIST = 'io-gdsn-exports/GET_FIELD_LIST';
export const GET_REFERENTIAL_CODE_LIST =
  'io-gdsn-exports/GET_REFERENTIAL_CODE_LIST';
export const GET_XPATH_LIST = 'io-gdsn-exports/GET_XPATH_LIST';
export const GET_USER_DATA = 'io-gdsn-exports/GET_USER_DATA';

export const DELETE_XPATH_ELEMENT = 'io-gdsn-exports/DELETE_XPATH_ELEMENT';

export const RECEIVE_EXPORT_MAPPING_LIST =
  'io-gdsn-exports/RECEIVE_EXPORT_MAPPING_LIST';
export const RECEIVE_LIST_FIELD_OPTIONS =
  'io-gdsn-exports/RECEIVE_LIST_FIELD_OPTIONS';
export const RECEIVE_FIELD_OPTIONS = 'io-gdsn-exports/RECEIVE_FIELD_OPTIONS';
export const RECEIVE_REFERENTIAL_CODE_OPTIONS =
  'io-gdsn-exports/RECEIVE_REFERENTIAL_CODE_OPTIONS';
export const RECEIVE_XPATH_LIST = 'io-gdsn-exports/RECEIVE_XPATH_LIST';
export const RECEIVE_USER_DATA_OPTIONS =
  'io-gdsn-exports/RECEIVE_USER_DATA_OPTIONS';

export const SAVE_EXPORT_MAPPING = 'io-gdsn-exports/SAVE_EXPORT_MAPPING';
export const CHANGE_ORDER_EXPORT_MAPPING =
  'io-gdsn-exports/CHANGE_ORDER_EXPORT_MAPPING';
export const SAVE_NEW_XPATH_ELEMENT = 'io-gdsn-exports/SAVE_NEW_XPATH_ELEMENT';

export const SET_EDITION_ID = 'io-gdsn-exports/SET_EDITION_ID';
export const SET_REORDERING_ID = 'io-gdsn-exports/SET_REORDERING_ID';
export const SET_SELECTED_EXPORT_MAPPING =
  'io-gdsn-exports/SET_SELECTED_EXPORT_MAPPING';
export const SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS =
  'io-gdsn-exports/SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS';
