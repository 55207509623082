import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { GDSN_PARTY_LIST_STORE_KEY } from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][GDSN_PARTY_LIST_STORE_KEY];
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectModal(state) {
  return selectState(state).modal;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export const selectFilters = createSelector(
  selectState,
  (state) => state.filters,
);

export const selectedOrganizations = createSelector(
  selectFilters,
  (filterState) =>
    filterState.organizations.reduce(
      (prev, curr) => prev.set(curr.value, true),
      Map(),
    ),
);
