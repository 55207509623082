import authApi from 'resources/authApi';
import fieldApi from 'resources/fieldApi';
import reviewApi from 'resources/reviewApi';

import {
  OrganizationField,
  OrganizationGroupField,
  OrganizationGroupListParameters,
} from './type';

export const loadOrganization = async (organizationId) => {
  const {
    data: { data },
  } = await authApi.OrganizationListV3(
    { ids: [organizationId] },
    { fieldsUsed: true },
  );
  return data.pop();
};

export const createOrganizationGroup = async (
  organization_id: number,
  name: string,
  fields: OrganizationField[] = [],
) => {
  const query = `/field/organization-groups/v1`;
  return await fieldApi.post(query, { organization_id, name, fields });
};

export const updateOrganizationGroup = async (
  group_id: number,
  organizationGroupToUpdate: OrganizationGroupField,
) => {
  const query = `/field/organization-groups/v1/${group_id}`;
  return await fieldApi.put(query, organizationGroupToUpdate);
};

export const deleteOrganizationGroup = async (group_id: number) => {
  const query = `/field/organization-groups/v1/${group_id}`;
  return await fieldApi.delete(query);
};

export const listOrganizationGroup = async (
  organizationGroupListParameters: OrganizationGroupListParameters,
) => {
  const query = `/field/organization-groups/v1/list`;
  return await fieldApi.post(query, organizationGroupListParameters);
};

export const getDefaultOrganizationGroup = async (organization_id: number) => {
  const query = `/review/field-groups/v1/defaults/${organization_id}`;
  return await reviewApi.get(query, '', false);
};

export const setDefaultOrganizationGroup = async (
  organization_id: number,
  field_group_id: number,
) => {
  const query = `/review/field-groups/v1/defaults/${organization_id}`;
  return await reviewApi.post(query, { group_ids: [field_group_id] });
};
