import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { separateActions } from 'utils/redux';

import { updateSettingValue } from '../../../actions/settings';
import {
  selectEditedSettings,
  selectSettings,
} from '../../../selectors/settings';

import LabeledCheckbox from './checkbox';
import './simple.scss';

const mapStateToProps = (state) => ({
  settings: selectSettings(state),
  editedSettings: selectEditedSettings(state),
});

const mapDispatchToProps = {
  updateSettingValue,
};

export class OrganizationPageSettingsSimpleController extends PureComponent {
  static propTypes = {
    label: PropTypes.node.isRequired,
    help: PropTypes.node,
    path: PropTypes.arrayOf(PropTypes.string).isRequired,
    checkedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
      .isRequired,
    uncheckedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
      .isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    settings: ImmutablePropTypes.map.isRequired,
    editedSettings: ImmutablePropTypes.map.isRequired,
    children: PropTypes.node,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    // Following props are meant to sync others settings.
    syncOthers: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.arrayOf(PropTypes.string).isRequired,
        checkedValue: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          ImmutablePropTypes.map,
        ]).isRequired,
        uncheckedValue: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          ImmutablePropTypes.map,
        ]).isRequired,
      }).isRequired,
    ),
  };

  onChange = (checked) => {
    const { actions, checkedValue, uncheckedValue, path, syncOthers } =
      this.props;
    actions.updateSettingValue(path, checked ? checkedValue : uncheckedValue);
    if (syncOthers) {
      for (const {
        path: otherPath,
        checkedValue: otherCheckedValue,
        uncheckedValue: otherUncheckedValue,
      } of syncOthers) {
        actions.updateSettingValue(
          otherPath,
          checked ? otherCheckedValue : otherUncheckedValue,
        );
      }
    }
  };

  isChecked() {
    const { defaultValue, checkedValue, settings, editedSettings, path } =
      this.props;

    const editedValue = editedSettings.getIn(path);
    if (editedValue != null) {
      return editedValue === checkedValue;
    }

    const sourceValue = settings.getIn(path);
    if (sourceValue != null) {
      return sourceValue === checkedValue;
    }

    if (defaultValue != null) {
      return defaultValue === checkedValue;
    }

    return false; // ¯\_(ツ)_/¯
  }

  render() {
    const { label, help, children, disabled } = this.props;
    return (
      <div className="OrganizationPageSettingSimpleController">
        <LabeledCheckbox
          onChange={this.onChange}
          checked={this.isChecked()}
          label={label}
          help={help}
          disabled={disabled}
        />
        {children && (
          <div className="OrganizationPageSettingSimpleController__children">
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(OrganizationPageSettingsSimpleController);
