import { isArray } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Radio, Text, Textarea } from '@alkem/react-ui-inputs';
import { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';

import {
  selectFunctionForValuesOptions,
  selectReferentialCodeOptions,
} from '../../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  ExportMappingPayloadSimple,
  ExportMappingPayloadValueData,
  Option,
  Options,
  RegularOptionWithNumberId,
  RegularOptionWithStringId,
} from '../../../../types';
import { getStringWithoutLineBreaks } from '../../../../utils';
import { ExportMappingsTurboSelector } from '../../turbo-selector';
import { isOption, isRegularOption } from '../../utils';
import { getData, valueCastOptions } from '../utils';

const getInitialValueCast = (payload: ExportMappingPayload) => {
  const { value } = payload;
  if (!value) return undefined;

  return valueCastOptions.find(
    (valueTypeOption) => valueTypeOption.id === value.cast,
  );
};

const getInitialFunctionValue = (
  payload: ExportMappingPayload,
  functionValueOptions: Options,
) => {
  const { value } = payload;
  if (!value || value.cast !== 'function') return undefined;

  return functionValueOptions.find(
    (functionValueOption) => functionValueOption.id === value.data,
  );
};
interface Props {
  name: string;
  exportMapping: ExportMapping;
  dataTypeOption: RegularOptionWithStringId;
  isDeclinable?: boolean;
  referential?: string;
  dispatchSetExportMapping: ({
    cast,
    field,
    use_su,
    referential,
    is_declinable,
    value,
    custom_parse,
  }: ExportMappingPayloadSimple) => void;
  showEditionFields: boolean;
}
export function ExportMappingsSimpleElementItemValueTypeValueSetter({
  name,
  exportMapping,
  dataTypeOption,
  isDeclinable,
  referential,
  dispatchSetExportMapping,
  showEditionFields,
}: Props) {
  const referentialCodeOptions = useSelector(selectReferentialCodeOptions);
  const functionOptions = useSelector(selectFunctionForValuesOptions);
  const notInitialRender = useRef(false);
  const [valueCastOption, setValueCastOption] = useState(
    getInitialValueCast(exportMapping.payload),
  );
  const [valueData, setValueData] = useState<
    ExportMappingPayloadValueData | undefined
  >(exportMapping.payload.value?.data);
  const [functionOption, setFunctionOption] = useState<Option | undefined>(
    getInitialFunctionValue(exportMapping.payload, functionOptions),
  );
  const [referentialCode, setReferentialCode] = useState<
    RegularOptionWithNumberId | undefined
  >(undefined);

  useEffect(() => {
    if (notInitialRender.current) {
      setValueCastOption(undefined);
      setValueData(undefined);
      setFunctionOption(undefined);
      setReferentialCode(undefined);
    } else {
      notInitialRender.current = true;
    }
  }, [dataTypeOption, isDeclinable, referential]);

  const updateValueCastOption = (
    value: TurboSelectOption<RegularOptionWithStringId>,
  ) => {
    const { cast, field, use_su, is_declinable, referential } =
      exportMapping.payload;
    setReferentialCode(undefined);
    setFunctionOption(undefined);
    if (isRegularOption(value)) {
      let data: ExportMappingPayloadValueData | undefined = undefined;
      if (cast === 'boolean' && value.id === 'value') {
        data = true;
      }
      setValueCastOption(value);
      setValueData(data);

      dispatchSetExportMapping({
        cast,
        field,
        use_su,
        is_declinable,
        referential,
        value: {
          cast: value.id,
          data,
        },
      });
    } else {
      setValueCastOption(undefined);
      setValueData(undefined);
      dispatchSetExportMapping({
        cast,
        field,
        use_su,
        is_declinable,
        referential,
      });
    }
  };

  const updateBooleanValueData = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const { cast, field, use_su, referential, is_declinable } =
      exportMapping.payload;
    setValueData(value);
    dispatchSetExportMapping({
      cast,
      field,
      use_su,
      referential,
      is_declinable,
      value: {
        ...(exportMapping.payload.value || {}),
        data: value === 'true',
      },
    });
  };

  const updateReferentialCode = (
    valueOption: TurboSelectOption<RegularOptionWithNumberId>,
  ) => {
    const { cast, referential, value, is_declinable } = exportMapping.payload;
    if (isRegularOption(valueOption)) {
      setReferentialCode(valueOption);
      let data:
        | { code: string }
        | { data: string; expressedIn: { code: string } }[]
        | undefined = undefined;
      if (isDeclinable) {
        data = [
          {
            ...(value?.data?.[0] || {}),
            expressedIn: { code: valueOption.label },
          },
        ];
      } else {
        data = { code: valueOption.label };
      }
      setValueData(data);
      dispatchSetExportMapping({
        cast,
        referential,
        is_declinable,
        value: {
          ...(value || {}),
          data,
        },
      });
    } else {
      setReferentialCode(undefined);
      const data = isDeclinable
        ? [
            {
              ...value?.data?.[0],
              expressedIn: undefined,
            },
          ]
        : undefined;
      setValueData(data);
      dispatchSetExportMapping({
        cast,
        referential,
        is_declinable,
        value: {
          ...(value || {}),
          data,
        },
      });
    }
  };

  const updateValueDataDeclinable = (event: { target: { value: string } }) => {
    const { cast, referential, value, is_declinable } = exportMapping.payload;
    const dataType = dataTypeOption;
    const { data, isValueDataSet } = getData(
      event.target.value,
      dataType,
      (updatedData: string | number | undefined) =>
        setValueData([{ ...(value?.data?.[0] || {}), data: updatedData }]),
    );
    dispatchSetExportMapping({
      cast,
      referential,
      is_declinable,
      value: {
        ...(value || {}),
        data: [
          {
            ...(valueData?.[0] || {}),
            data,
          },
        ],
      },
    });
    if (!isValueDataSet) {
      setValueData([{ ...(value?.data?.[0] || {}), data }]);
    }
  };

  const updateValueData = (event: { target: { value: string } }) => {
    const { cast, referential, value, is_declinable } = exportMapping.payload;
    const dataType = dataTypeOption;
    const { data, isValueDataSet } = getData(
      event.target.value,
      dataType,
      setValueData,
    );
    dispatchSetExportMapping({
      cast,
      referential,
      is_declinable,
      value: {
        ...(value || {}),
        data,
      },
    });
    if (!isValueDataSet) {
      setValueData(data);
    }
  };

  const updateLambda = (event: { target: { value: string } }) => {
    const { cast, referential, value, is_declinable } = exportMapping.payload;
    const data = getStringWithoutLineBreaks(event.target.value) || undefined;
    setValueData(data);
    dispatchSetExportMapping({
      cast,
      referential,
      is_declinable,
      value: {
        ...(value || {}),
        data,
      },
    });
  };

  const updateFunctionValue = (valueOption: TurboSelectOption<Option>) => {
    const { cast, referential, value, is_declinable } = exportMapping.payload;
    if (isOption(valueOption)) {
      const data = valueOption.id;
      setFunctionOption(valueOption);
      dispatchSetExportMapping({
        cast,
        referential,
        is_declinable,
        value: {
          ...(value || {}),
          data,
        },
      });
    } else {
      setFunctionOption(undefined);
      dispatchSetExportMapping({
        cast,
        referential,
        is_declinable,
        value: {
          ...(value || {}),
          data: undefined,
        },
      });
    }
  };

  return (
    <div className="GDSNExportMappingsSimpleElementItemValueTypeValueSetter">
      <ExportMappingsTurboSelector
        label="The value is"
        testId={`gdsn-export-mappings-simple-element-${name}-value-type-property`}
        options={valueCastOptions}
        onSelect={updateValueCastOption}
        option={valueCastOption}
        showEditionFields={showEditionFields}
      />
      {valueCastOption?.id === 'value' && (
        <>
          {dataTypeOption?.id === 'boolean' && (
            <div
              className="GDSNExportMappingsSimpleElementItemType__booleanValueData"
              data-testid={`gdsn-export-mappings-simple-element-${name}-boolean-value-data`}
            >
              <InputWithLabel
                inputId={`gdsn-export-mappings-simple-element-${name}-boolean-value-data`}
                label="Value"
              >
                <Radio
                  id={`gdsn-export-mappings-simple-element-${name}-boolean-value-data`}
                  value={valueData}
                  options={[
                    { label: 'TRUE', value: true },
                    { label: 'FALSE', value: false },
                  ]}
                  onChange={updateBooleanValueData}
                />
              </InputWithLabel>
            </div>
          )}
          {!['boolean', 'entity'].includes(dataTypeOption.id) &&
            isDeclinable &&
            referential && (
              <InputWithLabel
                inputId={`gdsn-export-mappings-simple-element-${name}-value-data-declinable`}
                label="Value"
              >
                <Text
                  className="GDSNExportMappingsSimpleElementItemType__value-data-declinable"
                  id={`gdsn-export-mappings-simple-element-${name}-value-data-declinable`}
                  value={
                    isArray(valueData) &&
                    'data' in valueData[0] &&
                    typeof valueData[0].data !== 'undefined'
                      ? valueData[0].data
                      : ''
                  }
                  onChange={updateValueDataDeclinable}
                  invalid={
                    !isArray(valueData) ||
                    !('data' in valueData[0]) ||
                    typeof valueData[0].data === 'undefined'
                  }
                />
              </InputWithLabel>
            )}
          {(dataTypeOption?.id === 'entity' || isDeclinable) && referential && (
            <ExportMappingsTurboSelector
              label={`Select ${
                isDeclinable ? 'an expressed in' : 'a referential'
              } code`}
              testId={`gdsn-export-mappings-simple-element-${name}-referential-code`}
              options={referentialCodeOptions[referential]}
              onSelect={updateReferentialCode}
              option={referentialCode}
              placeholder={
                (referentialCodeOptions[referential] || []).length === 0
                  ? `No ${
                      isDeclinable ? 'expressed in' : 'referential'
                    } code found`
                  : undefined
              }
              disabled={
                (referentialCodeOptions[referential] || []).length === 0
              }
              displayedValueProps={{
                label: isDeclinable ? 'Expressed in' : 'Value',
                value: isDeclinable
                  ? isArray(valueData)
                    ? valueData[0].expressedIn?.code
                    : undefined
                  : valueData &&
                      typeof valueData === 'object' &&
                      'code' in valueData
                    ? valueData.code
                    : undefined,
              }}
              showEditionFields={showEditionFields}
            />
          )}
          {!['boolean', 'entity'].includes(dataTypeOption.id) &&
            !isDeclinable && (
              <div
                className="GDSNExportMappingsSimpleElementItemType__valueData"
                data-testid={`gdsn-export-mappings-simple-element-${name}-value-data`}
              >
                <InputWithLabel
                  inputId={`gdsn-export-mappings-simple-element-${name}-value-data`}
                  label="Value"
                >
                  <Text
                    id={`gdsn-export-mappings-simple-element-${name}-value-data`}
                    value={
                      typeof valueData === 'string' ||
                      typeof valueData === 'number'
                        ? valueData
                        : ''
                    }
                    onChange={updateValueData}
                    type={
                      ['string', 'text'].includes(dataTypeOption.id)
                        ? 'text'
                        : 'number'
                    }
                    invalid={typeof valueData === 'undefined'}
                  />
                </InputWithLabel>
              </div>
            )}
        </>
      )}
      {valueCastOption?.id === 'lambda' && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-simple-element-${name}-lambda`}
          label="Lambda self, hierarchy, constants:"
        >
          <Textarea
            id={`gdsn-export-mappings-simple-element-${name}-lamdba`}
            value={typeof valueData === 'string' ? valueData : ''}
            autoresize
            onChange={updateLambda}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            invalid={!valueData}
          />
        </InputWithLabel>
      )}
      {valueCastOption?.id === 'function' && (
        <ExportMappingsTurboSelector
          label="Function name"
          testId={`gdsn-export-mappings-simple-element-${name}-function-value`}
          options={functionOptions}
          onSelect={updateFunctionValue}
          option={functionOption}
          placeholder={
            functionOptions.length === 0 ? 'No function value found' : undefined
          }
          disabled={functionOptions.length === 0}
          showEditionFields={showEditionFields}
        />
      )}
    </div>
  );
}
