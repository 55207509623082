import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Checkbox, Text } from '@alkem/react-ui-inputs';
import { Select } from '@alkem/react-ui-select';

import {
  FieldTypesAutocomplete,
  ListAutocomplete,
  ReferentialAutocomplete,
} from 'components/autocomplete';
import {
  URIEntityRoutes,
  URIReferentialAutocomplete,
} from 'components/autocomplete/custom/uri-autocomplete';
import InputWithLabel from 'components/input-with-label';
import ReferentialInput from 'components/referential-input';

import * as actions from '../../../actions';
import { selectFieldOptions } from '../../../selectors';

import './technical-info.scss';

function validateName(name) {
  return /^[A-Za-z0-9]+$/.test(name);
}

const mapStateToProps = createStructuredSelector({
  fieldOptions: selectFieldOptions,
});

const URIEntityRouteOptions = URIEntityRoutes.map((v) => ({
  key: v,
  label: v,
}));

export class FieldTechnicalInfo extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    path: PropTypes.array.isRequired,
    update: PropTypes.func.isRequired,
    isCreation: PropTypes.bool.isRequired,
    displayHeader: PropTypes.bool,
    readOnly: PropTypes.bool,
    fieldOptions: PropTypes.array.isRequired,
  };

  static defaultProps = {
    displayHeader: true,
  };

  state = {
    fieldsUsingSameReferential: false,
  };

  async componentDidUpdate(prevProps) {
    const { field, isCreation } = this.props;
    if (field !== prevProps.field || isCreation !== prevProps.isCreation) {
      if (
        (!field.get('referential') || !isCreation) &&
        this.state.fieldsUsingSameReferential
      ) {
        this.setState({ fieldsUsingSameReferential: false }); // eslint-disable-line
      }
      let fields = await actions.listFieldsByReferential(
        field.get('referential'),
      );
      fields = fields.filter((n) => n !== field.get('name'));
      this.setState({ fieldsUsingSameReferential: !!fields.size }); // eslint-disable-line
    }
  }

  onUpdateName = (event) => {
    const { path, update } = this.props;
    update([...path, 'name'], event.target.value);
  };

  onUpdateDescription = (event) => {
    const { path, update } = this.props;
    update([...path, 'description'], event.target.value);
  };

  onUpdateType = (data) => {
    const { path, update } = this.props;
    update([...path, 'type'], data ? data.value : null);
  };

  onUpdateFunctionalKey = (event) => {
    const { path, update } = this.props;
    update([...path, 'functional_key'], event.target.checked);
  };

  onUpdateDeclinableBy = (data) => {
    const { path, update } = this.props;
    update([...path, 'declinable_by'], data.value ? data.value.slug : null);
  };

  onUpdateReferential = (data) => {
    const { path, update } = this.props;
    update([...path, 'referential'], data.value ? data.value.slug : null);
  };

  onUpdateBUReferential = (data) => {
    const { path, update } = this.props;
    update([...path, 'referential'], data.code);
  };

  onAddUri = (v) => this.onUpdateUri({ key: v });

  onUpdateUri = ({ key }) => {
    const { path, update } = this.props;
    update([...path, 'uri'], key);
  };

  onSetCopyOf = ({ id }) => {
    const { path, update } = this.props;
    update([...path, 'copyOf_id'], id);
  };

  onDeleteCopyOf = () => {
    const { path, update } = this.props;
    update([...path, 'copyOf_id'], null);
  };

  onUpdateNullable = (event) => {
    const { path, update } = this.props;
    update([...path, 'nullable'], event.target.checked);
  };

  renderNameErrorMessage() {
    const { field } = this.props;
    if (!field.get('name')) {
      return null;
    }
    const isValid = validateName(field.get('name'));
    if (isValid) {
      return null;
    }
    return (
      <div className="FieldTechnicalInfo__nameError">
        This name is invalid. Use pascalCase and only letters and numbers are
        allowed.
      </div>
    );
  }

  render() {
    const { field, isCreation, path, displayHeader, readOnly, fieldOptions } =
      this.props;
    const { fieldsUsingSameReferential } = this.state;

    const fieldOption = fieldOptions.find(
      (f) => f.id === field.get('copyOf_id'),
    );

    return (
      <div className="FieldTechnicalInfo">
        {displayHeader && (
          <div className="FieldForm__sectionTitle">Technical information</div>
        )}
        <InputWithLabel inputId={`field-${path.join('-')}-name`} label="Name">
          <Text
            id={`field-${path.join('-')}-name`}
            value={field.get('name') || ''}
            onChange={this.onUpdateName}
            placeholder="pascalCase"
            disabled={readOnly || !isCreation}
          />
          {this.renderNameErrorMessage()}
        </InputWithLabel>
        <InputWithLabel
          inputId={`field-${path.join('-')}-description`}
          label="Description"
        >
          <Text
            id={`field-${path.join('-')}-description`}
            value={field.get('description') || ''}
            onChange={this.onUpdateDescription}
            disabled={readOnly}
          />
        </InputWithLabel>
        <InputWithLabel inputId={`field-${path.join('-')}-type`} label="Type">
          <FieldTypesAutocomplete
            id={`field-${path.join('-')}-type`}
            value={field.get('type') ? [{ label: field.get('type') }] : []}
            onSelect={this.onUpdateType}
            onUnselect={this.onUpdateType}
            disabled={readOnly || !isCreation}
            searchOnClick
          />
        </InputWithLabel>
        <InputWithLabel
          inputId={`field-${path.join('-')}-functional_key`}
          label="Is a functional key?"
        >
          <Checkbox
            id={`field-${path.join('-')}-functional_key`}
            checked={field.get('functional_key')}
            onChange={this.onUpdateFunctionalKey}
            label={<div className="FieldForm__checkboxLabel" />}
            disabled={readOnly || !isCreation}
          />
        </InputWithLabel>
        {field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-declinable_by`}
            label="Declinable by"
          >
            <ReferentialAutocomplete
              id={`field-${path.join('-')}-declinable_by`}
              value={
                field.get('declinable_by')
                  ? [{ label: field.get('declinable_by') }]
                  : []
              }
              onSelect={this.onUpdateDeclinableBy}
              onUnselect={this.onUpdateDeclinableBy}
              disabled={readOnly || !isCreation}
              searchOnClick
            />
          </InputWithLabel>
        )}
        {field.get('type') === 'entity' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-referential`}
            label="Referential *"
          >
            <ReferentialInput
              id={`field-${path.join('-')}-referential`}
              value={
                field.get('referential')
                  ? [{ label: field.get('referential') }]
                  : []
              }
              onSelect={this.onUpdateReferential}
              isRequired
              onUnselect={this.onUpdateReferential}
              disabled={readOnly || !isCreation}
              fieldsUsingSameReferential={fieldsUsingSameReferential}
              referentialWillNotBeCreatedWarning={false}
            />
          </InputWithLabel>
        )}
        {field.get('type') === 'uri_entity' && (
          <>
            <InputWithLabel
              inputId={`field-${path.join('-')}-uri`}
              label="Custom route"
            >
              <ListAutocomplete
                id={`field-${path.join('-')}-uri`}
                onAdd={this.onAddUri}
                onSelect={this.onUpdateUri}
                onUnselect={this.onUpdateUri}
                values={URIEntityRouteOptions}
                value={
                  field.get('uri')
                    ? [{ key: field.get('uri'), label: field.get('uri') }]
                    : []
                }
                searchOnClick
                disabled={readOnly || !isCreation}
              />
            </InputWithLabel>
            <InputWithLabel
              inputId={`field-${path.join('-')}-referential`}
              label="Referential"
            >
              <URIReferentialAutocomplete
                id={`field-${path.join('-')}-referential`}
                value={
                  field.get('referential')
                    ? [{ label: field.get('referential') }]
                    : []
                }
                onSelect={this.onUpdateBUReferential}
                onUnselect={this.onUpdateBUReferential}
                disabled={readOnly || !isCreation}
                uri={field.get('uri') || ''}
                searchOnClick
              />
            </InputWithLabel>
          </>
        )}
        {field.get('type') === 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-uri`}
            label="Field id to copy"
          >
            <Select
              id={`field-${path.join('-')}-copyOf`}
              onValueAdd={this.onSetCopyOf}
              onValueDelete={this.onDeleteCopyOf}
              options={fieldOptions}
              values={fieldOption ? [fieldOption] : []}
              inputable
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-nullable`}
            label="Nullable?"
          >
            <Checkbox
              id={`field-${path.join('-')}-nullable`}
              checked={field.get('nullable')}
              onChange={noop}
              label={<div className="FieldForm__checkboxLabel" />}
              disabled
            />
          </InputWithLabel>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(FieldTechnicalInfo);
