import classNames from 'classnames';
import { result } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RangePicker from 'react-daterange-picker';
import listensToClickOutside from 'react-onclickoutside';

import './date.scss';

class Date extends Component {
  static demoProps = [
    {
      value: moment(),
      onSelect: (e) => {
        if (!e) {
          alert('Date deleted');
        } // eslint-disable-line no-alert
        alert(`New date: ${e.format('LL')}`); // eslint-disable-line no-alert
      },
      label: 'Date',
      placeholder: 'Select a date',
    },
    {
      value: moment(),
      label: 'read only date',
      readOnly: true,
    },
  ];

  static propTypes = {
    value: PropTypes.object,
    onSelect: PropTypes.func,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    small: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: false,
    small: false,
    placeholder: null,
    onSelect: () => null,
    value: moment(),
  };

  constructor(props) {
    super(props);
    this.state = { displayCalendar: false };
    this.onClick = this.onClick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onClick() {
    if (this.props.readOnly) {
      return;
    }
    this.setState((prevState) => ({
      displayCalendar: !prevState.displayCalendar,
    }));
  }

  onDelete() {
    this.props.onSelect(null);
  }

  onSelect(date) {
    this.setState({
      displayCalendar: false,
    });
    this.props.onSelect(date);
  }

  handleClickOutside = () => {
    if (this.state.displayCalendar) {
      this.setState({
        displayCalendar: false,
      });
    }
  };

  renderDeleteSelectionButton() {
    const { value } = this.props;
    if (!value) {
      return null;
    }
    return (
      <div
        className="InputDate__deleteIcon mdi mdi-close"
        onClick={this.onDelete}
      />
    );
  }

  render() {
    const { value, placeholder, label, small, readOnly } = this.props;

    const inputClasses = {
      InputDate__itemLabel: true,
      'InputDate__itemLabel--full': !readOnly && value,
      'InputDate__itemLabel--readOnly': readOnly,
      'InputDate__itemLabel--small': small,
    };

    const calendarClasses = {
      InputDate__itemCalendar: true,
      'InputDate__itemCalendar--opened': this.state.displayCalendar,
    };

    const iconClasses = {
      'InputDate__itemIcon mdi mdi-calendar-blank': true,
      'InputDate__itemIcon--active': !readOnly,
      'InputDate__itemIcon--readOnly': readOnly,
    };

    const itemClasses = {
      'InputDate__item--center': true,
      'InputDate__item--readOnly': readOnly,
    };

    return (
      <div className="row">
        <label htmlFor="alk-date-input" className="InputDate__label col-xs-4">
          <span className="InputDate__label">{label}</span>
        </label>
        <div className="InputDate__input InputDate__item col-xs-8">
          <span className={classNames(itemClasses)}>
            <input
              id={`alk-date-input-${label}`}
              className={classNames(inputClasses)}
              placeholder={readOnly ? 'NC' : placeholder || label}
              onClick={this.onClick}
              value={value ? value.format('LL') : ''}
              disabled
            />
            {!!value && !readOnly && this.renderDeleteSelectionButton()}
            <span className={classNames(iconClasses)} onClick={this.onClick} />
          </span>
          {this.state.displayCalendar && (
            <div className={classNames(calendarClasses)}>
              <RangePicker
                numberOfCalendars={1}
                selectionType="single"
                onSelect={this.onSelect}
                value={(result(value, 'isValid') && value) || null}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { Date };
export default listensToClickOutside(Date);
