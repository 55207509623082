import classNames from 'classnames';
import { List } from 'immutable';
import { useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';

import { notificationError, notificationSuccess } from 'actions/notifications';
import { OrganizationAutocomplete } from 'components/autocomplete';
import { STATUS_ACTIVE } from 'constants/organization';
import { OrganizationTypeRetailer as retailer } from 'modules/organization-list/constants/types';
import etlApi from 'resources/etlApi';

import { selectImporters, selectWorkflowSelected } from '../../selectors';

import './modal.scss';

type Props = {
  onCloseModal: () => void;
};

const SupplierImportModal = (props: Props) => {
  const { onCloseModal } = props;

  const dispatch = useDispatch();

  const importers: List<string> = useSelector(selectImporters);
  const workflow: 'supplier-directory-v1' | 'rfp-answer-v1' = useSelector(
    selectWorkflowSelected,
  );

  const formats = useMemo(() => {
    // TODO remove mock 'XLSX'
    const importersWithXLSX = importers.contains('XLSX')
      ? importers
      : importers.push('XLSX');
    return importersWithXLSX
      .map((importer) => ({
        value: importer,
        label: importer,
      }))
      .toJS();
  }, [importers]);

  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrganizationOption, setSelectedOrganizationOption] =
    useState<any>();
  const [format, setFormat] = useState(formats[0]);

  const handleSelectFiles = (newFiles: File[]) => {
    const filenames = files.map(({ name }) => name);
    const newFilesWithNewNames = newFiles.filter(
      ({ name }) => !filenames.includes(name),
    );
    setFiles([...newFilesWithNewNames, ...files]);
  };

  const uploadSingle = async (file: File): Promise<void> => {
    let action;
    try {
      await etlApi.ImportPush(
        {
          organization_id: selectedOrganizationOption.value.id,
          workflow,
          importer: format.value,
          payload_format: 'form',
        },
        file,
      );
      action = notificationSuccess('File successfully imported');
    } catch (e) {
      action = notificationError(
        'There was an error while importing your file',
      );
    }
    dispatch(action);
  };

  const uploadFiles = async () => {
    setLoading(true);
    await Promise.all(files.map(uploadSingle)); // nice to have : handle errors here
    setLoading(false);
    onCloseModal();
  };

  const handleSelectOrganization = (option) => {
    setSelectedOrganizationOption(option);
  };

  const handleUnselectOrganization = () => {
    setSelectedOrganizationOption(undefined);
  };

  const parameters = (
    <div className="row SupplierImportModal__params">
      <div className="col-xs-4">
        <SimpleSelect
          id="ImportModal-Spider"
          options={formats}
          value={format}
          onSelect={setFormat}
          autoSize
          placeholder="Select a format..."
        />
      </div>
      <div className="col-xs-8">
        <OrganizationAutocomplete
          id="organization-autocomplete"
          value={selectedOrganizationOption ? [selectedOrganizationOption] : []}
          onSelect={handleSelectOrganization}
          onUnselect={handleUnselectOrganization}
          placeholder="Select an organization..."
          searchOnClick
          filters={{
            statuses: [STATUS_ACTIVE],
            types: [retailer.id],
          }}
        />
      </div>
    </div>
  );

  const dropZone = (
    <Dropzone multiple onDrop={handleSelectFiles}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps({
            className: classNames(
              'Dropzone',
              isDragActive && 'Dropzone--active',
            ),
          })}
        >
          <input {...getInputProps()} />
          <div>
            <strong>Drag and drop your file here</strong> or select a file from
            your computer...
          </div>
        </div>
      )}
    </Dropzone>
  );

  let filesToUpload: JSX.Element | null = null;
  if (files.length) {
    filesToUpload = (
      <div className="SupplierImportModal__summary">
        <div className="SupplierImportModal__summaryTitle">
          {`You are about to import ${files.length} file(s):`}
        </div>
        <div className="SupplierImportModal__fileSummary">
          {files.map((file) => (
            <div key={file.name}>
              <i className="mdi mdi-file" />
              {file.name}
            </div>
          ))}
        </div>
      </div>
    );
  }

  const importDisabled = !(files.length && selectedOrganizationOption);

  return (
    <Modal
      title="Import Suppliers"
      modalStyle="large"
      confirmButtonText="Upload"
      confirmDisabled={importDisabled}
      isProcessing={loading}
      onConfirm={uploadFiles}
      onClose={onCloseModal}
    >
      {filesToUpload}
      {dropZone}
      {parameters}
    </Modal>
  );
};

export default SupplierImportModal;
