import {
  OrganizationTypeManufacturer as manufacturer,
  OrganizationTypePrivateLabel as privateLabel,
  OrganizationTypeRetailer as retailer,
  OrganizationTypeThirdParty as thirdParty,
} from 'modules/organization-list/constants/types';

export const MANUFACTURER = {
  label: manufacturer.name,
  value: manufacturer.id,
  key: 'org-type-manufacturer',
};
export const RETAILER = {
  label: retailer.name,
  value: retailer.id,
  key: 'org-type-retailer',
};
export const PRIVATE_LABEL = {
  label: privateLabel.name,
  value: privateLabel.id,
  key: 'org-type-mdd',
};
export const THIRD_PARTY = {
  label: thirdParty.name,
  value: thirdParty.id,
  key: 'org-type-3rd-party',
};
