import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';
import { Modal } from '@alkem/react-ui-modal';

import { LegacyAutocomplete } from 'components/autocomplete';
import { validationSetProcessingBulkActionState } from 'modules/validation-dashboard/actions';
import { ruleEntityTypesMap } from 'modules/validation-dashboard/constants';
import { ProcessingBulkActionState } from 'modules/validation-dashboard/moduleState';
import { selectValidationProcessingBulkActionState } from 'modules/validation-dashboard/selectors';
import { Rule, RuleSet } from 'modules/validation-dashboard/types';
import { validationApiJS } from 'resources/validationApi';

import './bulk-edit-rules-modal.scss';

export interface BulkEditProps {
  id: string;
  rules: Rule[];
  onCloseAction: () => void;
  title: string;
  confirmButtonText: string;
  eligibleRulesHeader: string;
  eligibleRulesFilter: (rule: Rule) => boolean;
  ineligibleRulesHeader: string;
  requiresRuleSetSelection: boolean;
  getSumUpLabel: (
    nbSelectedRules: number,
    nbEligibleRules: number,
    nbNonEligibleRules: number,
  ) => ReactElement;
  performAction: (selectedRuleIds: number[], selectedRuleSet?: RuleSet) => void;
  performDoneAction?: (selectedRuleSet?: RuleSet) => void;
}

export const BulkEditRulesModal = ({
  rules,
  onCloseAction,
  eligibleRulesHeader,
  eligibleRulesFilter,
  ineligibleRulesHeader,
  requiresRuleSetSelection,
  getSumUpLabel,
  performAction,
  performDoneAction,
  ...props
}: BulkEditProps) => {
  const dispatch = useDispatch();

  const [rulesetSelected, selectRuleset] = useState<RuleSet>();
  const onSearch = (search) => validationApiJS.listRuleSets(search);

  const [checkedRules, setCheckedRules] = useState({});

  const processingBulkActionState = useSelector(
    selectValidationProcessingBulkActionState,
  );

  useEffect(
    () => () => {
      dispatch(
        validationSetProcessingBulkActionState(
          ProcessingBulkActionState.NOT_STARTED,
        ),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      processingBulkActionState === ProcessingBulkActionState.DONE &&
      performDoneAction
    ) {
      performDoneAction(rulesetSelected);
    }
    if (
      [
        ProcessingBulkActionState.ERROR,
        ProcessingBulkActionState.DONE,
      ].includes(processingBulkActionState)
    ) {
      onCloseAction();
    }
  }, [
    processingBulkActionState,
    onCloseAction,
    rulesetSelected,
    performDoneAction,
  ]);

  const onSelect = (rulesetSelected: RuleSet) => {
    selectRuleset(rulesetSelected);
  };
  const onUnselect = () => {
    selectRuleset(undefined);
  };

  const isRuleSelected = useCallback(
    (ruleId) => {
      return ruleId in checkedRules ? checkedRules[ruleId] : true;
    },
    [checkedRules],
  );

  function onChecked(ruleId: number) {
    return (checked: boolean) => {
      setCheckedRules((prevState) => ({
        ...prevState,
        [ruleId]: checked,
      }));
    };
  }

  const selectedRulesetValue = rulesetSelected
    ? [
        {
          key: rulesetSelected.id,
          label: rulesetSelected.id + ' - ' + rulesetSelected.label,
        },
      ]
    : [];

  const filterRulesList = (isAssignable: boolean) => {
    if (isAssignable) {
      return rules.filter(eligibleRulesFilter);
    } else {
      return rules.filter((rule) => !eligibleRulesFilter(rule));
    }
  };

  const eligibleRules: Rule[] = filterRulesList(true);
  const nonEligibleRules: Rule[] = filterRulesList(false);

  const eligibleRulesGroupedByRuleset = eligibleRules.reduce(
    (groupedRules, rule) => {
      const key = `${rule.ruleSet?.id} - ${rule.ruleSet?.label} (${rule.ruleSet?.type})`;
      (groupedRules[key] = groupedRules[key] || []).push(rule);
      return groupedRules;
    },
    {},
  );

  const eligibleSelectedRules: Rule[] = useMemo(() => {
    return eligibleRules.filter((rule) => isRuleSelected(rule.id));
  }, [isRuleSelected, eligibleRules]);

  const confirmAction = () => {
    const selectedRuleIds = eligibleSelectedRules.map((rule) => rule.id);
    performAction(selectedRuleIds, rulesetSelected);
  };

  function renderEligibleRule(rule) {
    const ruleLabel = `Rule ${rule.id}: ${rule.errorMessage} - ${
      ruleEntityTypesMap[rule.ruleEntityType].label
    }`;
    return (
      <li
        key={'selected-rule-key-' + rule.id}
        className={'liRuleSelected'}
        data-testid={'selected-rule-li'}
      >
        <Checkbox
          onChange={onChecked(rule.id)}
          id={'selected-rule-checkbox-' + rule.id}
          testid={'rule-checkbox-' + rule.id}
          checked={isRuleSelected(rule.id)}
          label={ruleLabel}
        />
      </li>
    );
  }

  function renderIneligibleRule(rule: Rule) {
    return (
      <li
        key={'not-selected-rule-key-' + rule.id}
        className={'liRuleSelected error'}
        data-testid={'not-selected-rule-li'}
      >
        Rule {rule.id}: {rule.errorMessage} -{' '}
        {ruleEntityTypesMap[rule.ruleEntityType].label}
      </li>
    );
  }

  return (
    <Modal
      {...props}
      className="BulkEditRulesModal"
      confirmDisabled={
        (requiresRuleSetSelection && !rulesetSelected) ||
        !eligibleSelectedRules.length ||
        processingBulkActionState === ProcessingBulkActionState.PROCESSING
      }
      isProcessing={
        processingBulkActionState === ProcessingBulkActionState.PROCESSING
      }
      onConfirm={confirmAction}
      closeButtonText="Cancel"
      onClose={onCloseAction}
    >
      <div className="BulkEditRulesModal_SumUp">
        {getSumUpLabel(
          eligibleSelectedRules.length,
          Object.keys(eligibleRules).length,
          Object.keys(nonEligibleRules).length,
        )}
      </div>
      {requiresRuleSetSelection && (
        <LegacyAutocomplete
          id="ValidationSelectRuleset_SelectInput"
          className="ValidationSelectRuleset_SelectInput"
          value={selectedRulesetValue}
          onSelect={onSelect}
          onUnselect={onUnselect}
          placeholder="Select a rule set"
          onSearch={onSearch}
          searchOnClick
        />
      )}

      {!!eligibleRules.length && (
        <>
          <h3>{eligibleRulesHeader}</h3>
          <ul>
            {Object.keys(eligibleRulesGroupedByRuleset).map((ruleset) => {
              return (
                <div key={ruleset}>
                  <b>From ruleset: {ruleset}</b>
                  {eligibleRulesGroupedByRuleset[ruleset].map(
                    renderEligibleRule,
                  )}
                </div>
              );
            })}
          </ul>
        </>
      )}
      {!!nonEligibleRules.length && (
        <>
          <h3>{ineligibleRulesHeader}</h3>
          <ul>{nonEligibleRules.map(renderIneligibleRule)}</ul>
        </>
      )}
    </Modal>
  );
};
