import { Field, Fields } from '../../types';

import './field-selector.scss';
import { FieldSelectorItem } from './item';

interface Props {
  fields: Fields;
  selectField: (field: Field) => void;
}

export function FieldSelector({ fields, selectField }: Props): JSX.Element {
  const renderField = (field: Field, parentField: Field): React.ReactNode => {
    if (!field) return null;
    const fieldId = field.id;
    return (
      <FieldSelectorItem
        key={fieldId}
        id={fieldId}
        name={field.name}
        label={field.displayInfo?.label}
        select={() => selectField(parentField)}
      >
        {(field.children || []).length > 0 &&
          field.children?.map((child) => renderField(child, parentField))}
      </FieldSelectorItem>
    );
  };

  return (
    <div className="GDSNImportMappingsFieldSelector">
      {fields?.map((field) => renderField(field, field))}
    </div>
  );
}
