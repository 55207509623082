import { createReducer } from 'utils/reducers';
import { ActionWithPayload } from 'utils/reducers/types';

import {
  RECEIVE_EXPORT_MAPPING_LIST,
  RECEIVE_FIELD_OPTIONS,
  RECEIVE_LIST_FIELD_OPTIONS,
  RECEIVE_REFERENTIAL_CODE_OPTIONS,
  RECEIVE_USER_DATA_OPTIONS,
  RECEIVE_XPATH_LIST,
  SET_EDITION_ID,
  SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
  SET_REORDERING_ID,
  SET_SELECTED_EXPORT_MAPPING,
} from '../actions/constants';
import {
  ExportMapping,
  ExportMappings,
  FieldOptions,
  GDSNExportMappingsState,
  Options,
  RegularOptionsWithNumberId,
  XpathList,
} from '../types';

type State = GDSNExportMappingsState;

const initialState: State = {
  customParseOptions: [],
  editionId: undefined,
  functionForValuesOptions: [],
  selectedExportMapping: undefined,
  functionForConstantsOptions: [],
  functionForConditionsOptions: [],
  exportMappings: [],
  isSavingInProcess: false,
  listFieldOptions: [],
  fieldOptions: [],
  referentialCodeOptions: {},
  xpathList: [],
  reorderingId: undefined,
  targetGlnOptions: [],
};

export default createReducer<State>(initialState, {
  [RECEIVE_USER_DATA_OPTIONS]: (
    state,
    {
      payload,
    }: ActionWithPayload<{
      customParseOptions: Options;
      functionForValuesOptions: Options;
      functionForConstantsOptions: Options;
      functionForConditionsOptions: Options;
      targetGlnOptions: Options;
    }>,
  ) => ({
    ...state,
    customParseOptions: payload?.customParseOptions || [],
    functionForValuesOptions: payload?.functionForValuesOptions || [],
    functionForConstantsOptions: payload?.functionForConstantsOptions || [],
    functionForConditionsOptions: payload?.functionForConditionsOptions || [],
    targetGlnOptions: payload?.targetGlnOptions || [],
  }),
  [RECEIVE_EXPORT_MAPPING_LIST]: (
    state,
    { payload }: ActionWithPayload<{ exportMappings: ExportMappings }>,
  ) => ({
    ...state,
    exportMappings: payload?.exportMappings || [],
  }),
  [RECEIVE_LIST_FIELD_OPTIONS]: (
    state,
    { payload }: ActionWithPayload<{ listFieldOptions: FieldOptions }>,
  ) => ({
    ...state,
    listFieldOptions: payload?.listFieldOptions || [],
  }),
  [RECEIVE_FIELD_OPTIONS]: (
    state,
    { payload }: ActionWithPayload<{ fieldOptions: FieldOptions }>,
  ) => ({
    ...state,
    fieldOptions: payload?.fieldOptions || [],
  }),
  [RECEIVE_REFERENTIAL_CODE_OPTIONS]: (
    state,
    {
      payload,
    }: ActionWithPayload<{
      referential: string;
      referentialCodeOptions?: RegularOptionsWithNumberId;
    }>,
  ) => {
    if (!payload) return { ...state };

    const referentialCodeOptions = { ...state.referentialCodeOptions };
    referentialCodeOptions[payload.referential] =
      payload.referentialCodeOptions || [];
    return {
      ...state,
      referentialCodeOptions,
    };
  },
  [RECEIVE_XPATH_LIST]: (
    state,
    { payload }: ActionWithPayload<{ xpathList?: XpathList }>,
  ) => ({
    ...state,
    xpathList: payload?.xpathList || [],
  }),
  [SET_EDITION_ID]: (
    state,
    { payload }: ActionWithPayload<{ editionId?: number | string }>,
  ) => {
    return {
      ...state,
      editionId: payload?.editionId,
    };
  },
  [SET_REORDERING_ID]: (
    state,
    { payload }: ActionWithPayload<{ reorderingId?: number }>,
  ) => {
    return {
      ...state,
      reorderingId: payload?.reorderingId,
    };
  },
  [SET_SELECTED_EXPORT_MAPPING]: (
    state,
    {
      payload,
    }: ActionWithPayload<{ exportMapping: ExportMapping | undefined }>,
  ) => {
    return {
      ...state,
      selectedExportMapping: payload?.exportMapping,
    };
  },
  [SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS]: (
    state,
    { payload }: ActionWithPayload<{ isSavingInProgress: boolean }>,
  ) => ({
    ...state,
    isSavingInProgress: payload?.isSavingInProgress || false,
  }),
});
