import { Set as ImmutableSet } from 'immutable';

import typePackagings from 'constants/typePackagings';

import { FieldMetadata, PackagingTypes } from './types';

export const computeForbiddenPackagingTypes: (
  meta: FieldMetadata,
  entityType?: number,
) => PackagingTypes = (meta, entityType) => {
  if (typeof entityType === 'undefined' || !typePackagings[entityType]) {
    return ImmutableSet();
  }

  const { applicableForTypePackagings } = meta;

  const applicablePackagingTypeIds = ImmutableSet<number>(
    applicableForTypePackagings.map(({ typePackaging: { id } }) => id),
  );

  const allPackagingTypeIds = ImmutableSet<number>(
    Object.values<{ id: number }>(typePackagings[entityType]).map(
      ({ id }) => id,
    ),
  );

  return allPackagingTypeIds.subtract(applicablePackagingTypeIds);
};
