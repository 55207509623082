import { createAction, createActionWithPayload } from 'utils/reducers';

import * as events from './events';
import * as types from './types';

export const init = createAction(events.INIT);

export const fetchBufferedConfigs = createAction(events.FETCH_BUFFERED_CONFIGS);

export const fetchConfigSources = createAction(events.FETCH_CONFIG_SOURCES);
export const receivedBufferedConfigs = createActionWithPayload<
  types.BufferedPumpConfig[]
>(events.RECEIVE_BUFFERED_CONFIGS);

export const createBufferedConfig =
  createActionWithPayload<types.BufferedPumpConfig>(
    events.CREATE_BUFFERED_CONFIG,
  );
export const updateBufferedConfig =
  createActionWithPayload<types.BufferedPumpConfig>(
    events.UPDATE_BUFFERED_CONFIG,
  );
export const deleteBufferedConfig =
  createActionWithPayload<types.BufferedPumpConfig>(
    events.DELETE_BUFFERED_CONFIG,
  );

export const receivedConfigSources = createActionWithPayload<
  types.ConfigSource[]
>(events.RECEIVE_CONFIG_SOURCES);

export const toggleAllBufferedConfigs = createActionWithPayload<boolean>(
  events.TOGGLE_BUFFERED_CONFIGS,
);

export const fetchBatchConfigs = createAction(events.FETCH_BATCH_CONFIGS);

export const createBatchConfig = createActionWithPayload<types.BatchPumpConfig>(
  events.CREATE_BATCH_CONFIG,
);
export const receivedBatchConfigs = createActionWithPayload<
  types.BatchPumpConfig[]
>(events.RECEIVE_BATCH_CONFIGS);
export const updateBatchConfig = createActionWithPayload<types.BatchPumpConfig>(
  events.UPDATE_BATCH_CONFIG,
);
export const deleteBatchConfig = createActionWithPayload<types.BatchPumpConfig>(
  events.DELETE_BATCH_CONFIG,
);
