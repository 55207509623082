import { MODULE_REDUCER_KEY } from 'constants/reducer';

import {
  REPORTING_CONFIG_STORE_KEY,
  REPORTING_TABLE_REDUCER_KEY,
} from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REPORTING_CONFIG_STORE_KEY][
    REPORTING_TABLE_REDUCER_KEY
  ];
}

export function getIsLoading(state) {
  return selectState(state).get('isLoading');
}

export function getIsDownloading(state) {
  return selectState(state).get('isDownloading');
}

export function selectReportingTable(state) {
  return selectState(state).get('reportingTable');
}
