import { notificationError, notificationSuccess } from 'actions/notifications';
import authApi from 'resources/authApi';

import { createAction } from '../../../utils/actions';
import {
  ORGANIZATION_DEACTIVATE_MODAL_CLOSE,
  ORGANIZATION_DEACTIVATE_MODAL_LOADING,
  ORGANIZATION_DEACTIVATE_MODAL_OPEN,
} from '../constants/events';
import {
  selectIsModalLoading,
  selectModalOrganization,
} from '../selectors/modal';

export const openOrganizationDeactivateModal = createAction(
  ORGANIZATION_DEACTIVATE_MODAL_OPEN,
);
export const closeOrganizationDeactivateModal = createAction(
  ORGANIZATION_DEACTIVATE_MODAL_CLOSE,
);
export const organizationDeactivateModalIsLoading = createAction(
  ORGANIZATION_DEACTIVATE_MODAL_LOADING,
);

async function activateOrDeactivate(dispatch, getState, isActivating = false) {
  const state = getState();

  if (selectIsModalLoading(state)) {
    return {};
  }

  const organization = selectModalOrganization(state).toJS();
  dispatch(organizationDeactivateModalIsLoading(true));
  try {
    await authApi.OrganizationUpdate({
      ...organization,
      status: isActivating ? 1 : 0,
    });
    dispatch(closeOrganizationDeactivateModal());
    dispatch(
      notificationSuccess(
        'Organization deactivated. Sometimes refresh is needed',
      ),
    );
  } catch (error) {
    dispatch(organizationDeactivateModalIsLoading(false));
    dispatch(notificationError('Could not deactivate organization', 'modal'));
    return { error };
  }
  return { success: true };
}

export const deactivate = () => (dispatch, getState) =>
  activateOrDeactivate(dispatch, getState, false);

export const activate = () => (dispatch, getState) =>
  activateOrDeactivate(dispatch, getState, true);
