import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import { organizationGroupDashboardAccessPolicy } from 'access-policies';
import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { selectUserReadOnlyAccess } from 'modules/auth/selectors';
import { separateActions } from 'utils/redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

import OrganizationGroupEditModal from './edit';
import OrganizationGroupsList from './list';

const mapStateToProps = (state) => ({
  groups: selectors.selectGroups(state),
  search: selectors.selectSearch(state),
  edited: selectors.selectEdited(state),
  uploading: selectors.selectUploading(state),
  saving: selectors.selectSaving(state),
  readOnly: selectUserReadOnlyAccess(organizationGroupDashboardAccessPolicy)(
    state,
  ),
});

const mapDispatchToProps = {
  init: actions.init,
  fetchGroups: actions.fetchGroups,
  updateSearch: actions.updateSearch,
  edit: actions.edit,
  updateName: actions.updateName,
  upload: actions.upload,
  save: actions.save,
};

export class OrganizationGroups extends PureComponent {
  static propTypes = {
    groups: ImmutablePropTypes.list,
    search: PropTypes.string,
    edited: ImmutablePropTypes.map,
    uploading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      fetchGroups: PropTypes.func.isRequired,
      updateSearch: PropTypes.func.isRequired,
      edit: PropTypes.func.isRequired,
      updateName: PropTypes.func.isRequired,
      upload: PropTypes.func.isRequired,
      save: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.init();
    this.props.actions.fetchGroups();
  }

  onUpdateSearch = (search) => {
    this.props.actions.updateSearch(search);
    this.props.actions.fetchGroups();
  };

  onCloseEditModal = () => {
    this.props.actions.edit(null);
  };

  render() {
    const { search, groups, edited, uploading, saving, readOnly } = this.props;
    return (
      <div>
        <HeaderLayout
          title="Organization groups"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        >
          <div className="OrganizationGroups__search">
            <Search
              query={search}
              placeholder="Search groups..."
              updateSearchQuery={this.onUpdateSearch}
            />
          </div>
        </HeaderLayout>
        <div className="container-fluid">
          {groups && (
            <OrganizationGroupsList
              groups={groups}
              edit={this.props.actions.edit}
              readOnly={readOnly}
            />
          )}
          {edited && (
            <OrganizationGroupEditModal
              group={edited}
              uploading={uploading}
              saving={saving}
              updateName={this.props.actions.updateName}
              upload={this.props.actions.upload}
              save={this.props.actions.save}
              close={this.onCloseEditModal}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(OrganizationGroups);
