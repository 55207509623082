import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ListAutocomplete } from 'components/autocomplete';
import SelectedFilters from 'components/ui/selected-filters';
import { separateActions } from 'utils/redux';

import {
  addGDSNCISListFilters,
  clearGDSNCISListFilters,
  removeGDSNCISListFilters,
  updateGDSNCISListFilters,
} from '../actions';
import { selectFilters } from '../selectors';

const mapStateToProps = (state) => ({
  filters: selectFilters(state),
});

const mapDispatchToProps = {
  addGDSNCISListFilters,
  removeGDSNCISListFilters,
  updateGDSNCISListFilters,
  clearGDSNCISListFilters,
};

class GDSNCISListFilters extends PureComponent {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      addGDSNCISListFilters: PropTypes.func.isRequired,
      removeGDSNCISListFilters: PropTypes.func.isRequired,
      updateGDSNCISListFilters: PropTypes.func.isRequired,
      clearGDSNCISListFilters: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {}

  onAddSource = (search) => {
    this.props.actions.addGDSNCISListFilters('source_gln', {
      label: search,
      value: search,
    });
  };

  onAddRecipient = (search) => {
    this.props.actions.addGDSNCISListFilters('recipient_gln', {
      label: search,
      value: search,
    });
  };

  render() {
    const { filters } = this.props;
    return (
      <div>
        <SelectedFilters
          filters={filters}
          removeFilter={this.props.actions.removeGDSNCISListFilters}
          removeAllFilters={this.props.actions.clearGDSNCISListFilters}
        />
        <h4>Source GLN</h4>
        <div className="card">
          <ListAutocomplete
            id="list-autocomplete-gln-source"
            onAdd={this.onAddSource}
            values={[]}
            value={filters.source_gln}
            addMissingOptionLabel="Search..."
          />
        </div>
        <h4>Recipient GLN</h4>
        <div className="card">
          <ListAutocomplete
            id="list-autocomplete-gln-recipient"
            onAdd={this.onAddRecipient}
            values={[]}
            value={filters.recipient_gln}
            addMissingOptionLabel="Search..."
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(GDSNCISListFilters);
