import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Modal } from '@alkem/react-ui-modal';

import { CodeEditor } from 'components/code-editor';
import { getIn } from 'utils/immutable';

import { getTargetOrganizations } from '../../utils';

import './modal.scss';

class ReplayModal extends Component {
  static propTypes = {
    importfile: PropTypes.object.isRequired,
    onReplayClick: PropTypes.func,
    onCloseModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const parameters = Object.assign(
      {},
      props.importfile.parameters,
      props.importfile.replayParameters,
      {
        replay_info: {
          original_import_id:
            getIn(
              props.importfile,
              'parameters.replay_info.original_import_id',
            ) || props.importfile.id,
        },
      },
    );
    if (!parameters.organization_id) {
      if (props.importfile.targetOrganizations.length === 1) {
        parameters.organization_id = props.importfile.targetOrganizations[0].id;
      } else if (
        props.importfile.sourceOrganization &&
        props.importfile.sourceOrganization.id &&
        props.importfile.sourceOrganization.id !== 1
      ) {
        parameters.organization_id = props.importfile.sourceOrganization.id;
      }
    }
    this.state = {
      parameters,
      parametersStr: JSON.stringify(parameters, null, '\t'),
      disableReplay: false,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  onChange = (newParametersStr) => {
    try {
      const parsedParameters = JSON.parse(newParametersStr);
      this.setState({
        parameters: parsedParameters,
        parametersStr: newParametersStr,
        disableReplay: false,
      });
    } catch (error) {
      this.setState({ parametersStr: newParametersStr, disableReplay: true });
    }
  };

  onReplayClick = () => {
    const { onReplayClick } = this.props;
    const { parameters } = this.state;
    onReplayClick(parameters);
  };

  renderParameters() {
    const { parametersStr } = this.state;
    return (
      <CodeEditor
        wrapEnabled
        name="replay-params"
        className="ReplayDetailsModal_Parameters"
        value={parametersStr}
        mode="json"
        theme="monokai"
        width="100%"
        height="100%"
        fontSize="18px"
        editorProps={{ $blockScrolling: Infinity }}
        onChange={this.onChange}
      />
    );
  }

  render() {
    const { importfile, onCloseModal } = this.props;
    const { disableReplay } = this.state;
    const sourceOrga = importfile.sourceOrganization;
    const targetOrgas = getTargetOrganizations(importfile);
    const title = `Replay import from ${sourceOrga.nameLegal} (${sourceOrga.id}) to ${targetOrgas}`;
    return (
      <Modal
        title={title}
        modalStyle="large"
        confirmButtonText="Replay"
        confirmDisabled={disableReplay}
        onConfirm={this.onReplayClick}
        onClose={onCloseModal}
      >
        <div className="ReplayDetailsModal">
          <div className="ReplayDetailsModal__Body">
            {this.renderParameters()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default ReplayModal;
