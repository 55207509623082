import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import { notificationError } from 'actions/notifications';
import { CodeEditor } from 'components/code-editor';
import { Rule } from 'modules/validation-dashboard/types';
import { validationApiJS } from 'resources/validationApi';
import { logError } from 'utils';

import { ruleEntityTypes, ruleStatuses } from '../../../constants';

import { RuleApplierExecutionDetails } from './rule-applier-execution-details/rule-applier-execution-details';
import { RuleApplierOverview } from './rule-applier-overview/rule-applier-overview';
import { DebugInfo } from './type';

interface Props {
  rule: Rule;
  disabled?: boolean;
}

const defaultLogisticalHierarchyRulePayload = `{
    "id": 42,
    "logisticalHierarchies": [
      {
        "id": 1,
        "gtin": "03312560822006",
        "version": {
          "id": 123,
          "typePackaging": {"id": 1},
          "gtin": "03312560822006"
        }
      }
    ],
    "sharingUnits": [
      {
        "data": {"hierarchyProduct": {"id": 1}},
        "targetOrganization": {"id": 1}
      }
    ]
  }`;

function isHierarchyRule(rule: Rule) {
  return [
    ruleEntityTypes.LOGISTICAL_UNIT.id,
    ruleEntityTypes.LOGISTICAL_HIERARCHY.id,
    ruleEntityTypes.LOGISTICAL_HIERARCHY_TOP_UNIT.id,
  ].includes(rule.ruleEntityType);
}

export const ValidationRuleApplier = ({ rule, disabled = false }: Props) => {
  const [payload, setPayload] = useState<string>(
    isHierarchyRule(rule) ? defaultLogisticalHierarchyRulePayload : '',
  );

  const [debugInfo, setDebugInfo] = useState<DebugInfo>({ call_traces: {} });
  const [applying, setApplying] = useState<boolean>(false);
  const [applicationStatus, setApplicationStatus] = useState<number>(-1);
  const [applicationErrorMessage, setApplicationErrorMessage] =
    useState<string>('');

  const dispatch = useDispatch();
  const notifyError = (errorMsg) => dispatch(notificationError(errorMsg));

  const onExplain = async () => {
    setApplying(true);
    setDebugInfo({ call_traces: {} });
    try {
      const res = await validationApiJS.applyOneRule(
        rule.id,
        JSON.parse(payload || '{}'),
      );
      setApplicationStatus(res.data.data.status);
      setApplicationErrorMessage(res.data.data.exception_message);
      setDebugInfo(res.data.data.debug_info);
    } catch (error) {
      notifyError(
        `There was an error while applying. Have a look at your console to find out what it was`,
      );
      logError(error, { force: true });
      setApplicationStatus(4);
    } finally {
      setApplying(false);
    }
  };

  const resultsClasses = {
    ValidationRule__applicationResult: true,
    [`ValidationRule__applicationResult--${applicationStatus}`]: true,
  };

  return (
    <div>
      <CodeEditor
        name="payload"
        className="ApplyRuleModal__Editor"
        value={payload}
        mode="json"
        theme="monokai"
        width="100%"
        height="300px"
        editorProps={{ $blockScrolling: Infinity }}
        onChange={setPayload}
      />
      <div className="ValidationRule__applyButton">
        <div className={classNames(resultsClasses)}>
          {ruleStatuses[applicationStatus]}
        </div>
        {applicationErrorMessage && <div>{applicationErrorMessage}</div>}
        <div>
          {isHierarchyRule(rule) && (
            <Button
              content="Reset"
              onClick={() => setPayload(defaultLogisticalHierarchyRulePayload)}
              link
              disabled={applying || disabled} // Explicitely not for readOnly
              displaySpinner={applying}
            />
          )}
          <LazyTooltip
            id="explain-rule-button"
            place="bottom"
            tooltipLabel="Test your rule and display information about its evaluation."
          >
            <Button
              content="Explain"
              onClick={onExplain}
              secondary
              disabled={applying || disabled}
              displaySpinner={applying}
            />
          </LazyTooltip>
        </div>
      </div>
      {(debugInfo.call_traces.validation ||
        debugInfo.call_traces.condition) && (
        <>
          <h3>Rule Execution Preview</h3>
          <RuleApplierOverview
            conditionExpression={debugInfo.call_traces?.condition?.expression}
            conditionOutcome={debugInfo.call_traces?.condition?.outcome}
            validationExpression={debugInfo.call_traces?.validation?.expression}
            validationOutcome={debugInfo.call_traces?.validation?.outcome}
          />
          <RuleApplierExecutionDetails debugInfo={debugInfo} />
        </>
      )}
      {isHierarchyRule(rule) && (
        <div className="ValidationRule__applierTipster">
          Make sure the rule set is active for at least one organization and use
          that organization id in{' '}
          <code>sharingUnits[0].targetOrganization.id</code>
        </div>
      )}
    </div>
  );
};
