import { List } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import EmptyState from '@alkem/react-ui-empty-state';
import { Spinner } from '@alkem/react-ui-spinner';

import ListController from 'components/ui/list/controller';
import {
  changeLimit,
  nextPage,
  previousPage,
  search,
} from 'modules/transaction/actions';
import { selectState } from 'modules/transaction/reducer';
import { separateActions } from 'utils/redux';

import './list.scss';
import { TransactionRow } from './row';

const mapStateToProps = (state) => ({
  list: selectState(state).list,
  isLoading: selectState(state).isLoading,
  pagination: selectState(state).pagination,
});

const mapDispatchToProps = {
  changeLimit,
  nextPage,
  previousPage,
  search,
};

export class TransactionList extends PureComponent {
  static propTypes = {
    list: ImmutablePropTypes.list.isRequired,
    isLoading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,

    actions: PropTypes.shape({
      changeLimit: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      search: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    list: List(),
    isLoading: false,
    pagination: {},
  };

  renderList() {
    const { list, isLoading } = this.props;

    if (isLoading) {
      return (
        <div className="TransactionList__spinner">
          <Spinner loading big />
        </div>
      );
    }

    return list
      .map((transaction) => (
        <TransactionRow key={transaction.get('id')} transaction={transaction} />
      ))
      .toJS();
  }

  render() {
    const { list, isLoading, actions, pagination } = this.props;
    const { currentPage, totalPages, limit, totalResults } = pagination;

    if (!isLoading && list.size === 0) {
      return (
        <EmptyState
          icon="cake"
          title="No transactions"
          text="No transaction could be found for your filters (but we could find a cake)"
        />
      );
    }

    return (
      <ListController
        onNext={actions.nextPage}
        onPrev={actions.previousPage}
        onLimitChange={actions.changeLimit}
        currentPage={currentPage}
        totalPages={totalPages}
        limit={limit}
        totalResults={totalResults}
        rowsLength={0}
        type="transactions"
        actions={
          <Button onClick={actions.search}>
            <i className="mdi mdi-refresh" />
          </Button>
        }
      >
        {this.renderList()}
      </ListController>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(TransactionList);
