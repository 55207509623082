import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';
import Checkbox from '@alkem/react-ui-checkbox';

import {
  MODAL_TYPE_EDIT,
  MODAL_TYPE_REFUSE,
  MODAL_TYPE_VALIDATE,
} from 'modules/field-suggestion/constants';

import './actionbar.scss';

export default class FieldSuggestionActionBar extends Component {
  static propTypes = {
    onCheckAll: PropTypes.func.isRequired,
    entities: ImmutablePropTypes.list.isRequired,
    checked: PropTypes.bool,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    checked: false,
  };

  onValidate = () => {
    this.props.openModal(MODAL_TYPE_VALIDATE);
  };

  onRefuse = () => {
    this.props.openModal(MODAL_TYPE_REFUSE);
  };

  onEdit = () => {
    this.props.openModal(MODAL_TYPE_EDIT);
  };

  handleCheck = (checked) => {
    this.props.onCheckAll(checked);
  };

  render() {
    const { checked, entities } = this.props;

    return (
      <div className="FieldSuggestionActionBar__row">
        <div className="FieldSuggestionActionBar_checkbox">
          <Checkbox
            id="check-all"
            checked={checked}
            onChange={this.handleCheck}
          />
        </div>
        <div
          id="FieldSuggestionActionBar__validate"
          className="FieldSuggestionActionBar__button"
        >
          <Button
            content="Validate"
            secondary
            onClick={this.onValidate}
            disabled={entities.size === 0}
          />
        </div>
        <div
          id="FieldSuggestionActionBar__refuse"
          className="FieldSuggestionActionBar__button"
        >
          <Button
            content="Refuse"
            secondary
            onClick={this.onRefuse}
            disabled={entities.size === 0}
          />
        </div>
        <div
          id="FieldSuggestionActionBar__edit"
          className="FieldSuggestionActionBar__button"
        >
          <Button
            content="Edit"
            secondary
            onClick={this.onEdit}
            disabled={entities.size === 0}
          />
        </div>
      </div>
    );
  }
}
