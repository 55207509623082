import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';

import KindsTree from 'components/kind-tree';

import './applicable-kinds.scss';

export default class ApplicableKinds extends PureComponent {
  static propTypes = {
    kinds: PropTypes.object,
    applicableKinds: PropTypes.object.isRequired,
    forbiddenKinds: ImmutablePropTypes.set,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      applicableKinds: props.applicableKinds,
    };

    this.applyChanges = this.applyChanges.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.update = this.update.bind(this);
  }

  toggleModal = () => {
    this.setState(({ modalIsOpen }) => ({ modalIsOpen: !modalIsOpen }));
  };

  applyChanges() {
    this.props.onChange(this.state.applicableKinds);
    this.toggleModal();
  }

  discardChanges() {
    this.setState({ applicableKinds: this.props.applicableKinds });
    this.toggleModal();
  }

  update(value) {
    this.setState({
      applicableKinds: fromJS(
        Object.entries(value)
          .filter((entry) => entry[1])
          .map((entry) => ({ id: +entry[0] })),
      ),
    });
  }

  computeSelected() {
    const selected = {};
    this.state.applicableKinds.forEach((kind) => {
      selected[kind.get('id')] = true;
    });
    return selected;
  }

  render() {
    const { disabled, applicableKinds, forbiddenKinds } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <div className="ApplicableKinds" data-testid="applicableKinds">
        <Button
          content={`Kinds (${applicableKinds.size})`}
          onClick={this.toggleModal}
          secondary
          disabled={disabled}
        />
        {modalIsOpen && (
          <Modal
            title="Select applicable kinds"
            confirmButtonText="Apply"
            onConfirm={this.applyChanges}
            onClose={this.discardChanges}
          >
            <KindsTree
              onSelectionChange={this.update}
              selection={this.computeSelected()}
              revealSelectedPaths
              forbiddenKinds={forbiddenKinds}
            />
          </Modal>
        )}
      </div>
    );
  }
}
