import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';
import Checkbox from '@alkem/react-ui-checkbox';
import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';

import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
  SHARING_UNIT,
} from 'constants/fields';
import { organizationHasField } from 'modules/organization-uses-fields/utils';
import { fieldsApiImmutable as fieldsApi } from 'resources/fieldsApi';

import './bulkModal.scss';

const allEntityTypes = [
  CONSUMER_UNIT,
  SHARING_UNIT,
  LOGISTICAL_UNIT,
  DISPLAY_UNIT,
];

class BulkFieldModal extends PureComponent {
  static propTypes = {
    editedOrganization: ImmutablePropTypes.map,
    onClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
  };

  state = {
    q: '',
    selectedFields: Map(),
    fields: Map(),
  };

  componentDidMount() {
    this.setState({ q: '', selectedFields: Map(), fields: Map() });
  }

  onAdd = () => {
    const { onAdd } = this.props;
    const { fields, selectedFields } = this.state;
    const toAdd = fields
      .toList()
      .flatten(1)
      .filter((field) =>
        selectedFields.getIn([field.get('entityType'), field.get('id')]),
      )
      .map((field) => field.remove('disabled'));
    onAdd(toAdd);
  };

  onSearch = async () => {
    const { q } = this.state;

    const res = await Promise.all(
      allEntityTypes.map(async (entityType) => this.fetchFields(entityType, q)),
    );

    const fields = res.reduce((acc, r) => acc.merge(r), Map());
    const selectedFields = fields.map((fs) =>
      fs.reduce((acc, field) => acc.set(field.get('id'), true), Map()),
    );

    this.setState({ fields, selectedFields });
  };

  onQChange = (evt) => {
    this.setState({ q: evt.target.value });
  };

  onQKeyPress = (evt) => {
    if (evt.charCode === 13) {
      // Enter
      this.onSearch();
    }
  };

  onSelectField = (field, entityType, selected) => {
    const { selectedFields } = this.state;
    this.setState({
      selectedFields: selectedFields.setIn(
        [entityType, field.get('id')],
        selected,
      ),
    });
  };

  onChange = (field) => (checked) => {
    this.onSelectField(field, field.get('entityType'), checked);
  };

  fetchFields = async (entityType, q) => {
    const { editedOrganization } = this.props;
    const trueEntityType =
      entityType === LOGISTICAL_UNIT || entityType === DISPLAY_UNIT
        ? CONSUMER_UNIT
        : entityType;

    const res = await fieldsApi.listMainFields('', [], trueEntityType, {
      attributes: 'id,name,type',
      tag: q,
      only_for_logistical_units: entityType === LOGISTICAL_UNIT,
      only_for_display_units: entityType === DISPLAY_UNIT,
    });

    return Map({
      [entityType]: res.data.data
        .map((field) => field.set('entityType', entityType))
        .map((field) =>
          field.set(
            'disabled',
            organizationHasField(editedOrganization, field),
          ),
        ),
    });
  };

  renderField(field) {
    const { selectedFields } = this.state;

    return (
      <Checkbox
        id={`field-${field.get('entityType')}-${field.get('id')}`}
        checked={selectedFields.getIn([
          field.get('entityType'),
          field.get('id'),
        ])}
        onChange={this.onChange}
        label={
          field.get('disabled') ? (
            <em>{field.get('name')} - already present</em>
          ) : (
            field.get('name')
          )
        }
        disabled={field.get('disabled')}
      />
    );
  }

  render() {
    const { onClose } = this.props;
    const { q, fields } = this.state;
    return (
      <Modal
        title="Search fields"
        confirmButtonText="Add"
        onConfirm={this.onAdd}
        onClose={onClose}
      >
        <div>
          <div className="flex flex-align-items--center w-100">
            <Text
              className="flex-grow--1"
              id="fields-bulk-modal-search-input"
              value={q}
              placeholder="Search by tag"
              onChange={this.onQChange}
              onKeyPress={this.onQKeyPress}
            />
            <Button content="Search" secondary onClick={this.onSearch} />
          </div>
          {fields.get(CONSUMER_UNIT) && fields.get(CONSUMER_UNIT).size > 0 && (
            <div className="BulkFieldModal__Section">
              Consumer unit fields:
              <ul>
                {fields.get(CONSUMER_UNIT).map((field) => (
                  <li key={field.get('name')}>{this.renderField(field)}</li>
                ))}
              </ul>
            </div>
          )}
          {fields.get(SHARING_UNIT) && fields.get(SHARING_UNIT).size > 0 && (
            <div className="BulkFieldModal__Section">
              Sharing unit fields:
              <ul>
                {fields.get(SHARING_UNIT).map((field) => (
                  <li key={field.get('name')}>{this.renderField(field)}</li>
                ))}
              </ul>
            </div>
          )}
          {fields.get(LOGISTICAL_UNIT) &&
            fields.get(LOGISTICAL_UNIT).size > 0 && (
              <div className="BulkFieldModal__Section">
                Logistical unit fields:
                <ul>
                  {fields.get(LOGISTICAL_UNIT).map((field) => (
                    <li key={field.get('name')}>{this.renderField(field)}</li>
                  ))}
                </ul>
              </div>
            )}
          {fields.get(DISPLAY_UNIT) && fields.get(DISPLAY_UNIT).size > 0 && (
            <div className="BulkFieldModal__Section">
              Display unit fields:
              <ul>
                {fields.get(DISPLAY_UNIT).map((field) => (
                  <li key={field.get('name')}>{this.renderField(field)}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default BulkFieldModal;
