import { Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

import { ExportMapping } from '../../../types';

import './item-automatic-properties.scss';

interface Props {
  name: string;
  exportMapping: ExportMapping;
}
export function ExportMappingsItemAutomaticProperties({
  name,
  exportMapping,
}: Props) {
  const { payload } = exportMapping;
  const { field, cast, referential, is_declinable } = payload;
  if (!field || !cast) return null;
  return (
    <div
      data-testid="GDSNExportMappingsItem_automaticProperties"
      className="GDSNExportMappingsItemAutomaticProperties"
    >
      <div className="GDSNExportMappingsItemAutomaticProperties__sectionTitle">
        Automatic properties
      </div>
      <InputWithLabel
        inputId={`gdsn-export-mappings-item-${name}-automatic-properties-type`}
        label="Type"
      >
        <Text
          className="GDSNExportMappingsItemAutomaticProperties__type"
          id={`gdsn-export-mappings-item-${name}-automatic-properties-type`}
          value={cast}
          onChange={() => {}}
          disabled
        />
      </InputWithLabel>
      {cast === 'entity' && !!referential && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-item-${name}-automatic-properties-referential`}
          label="Referential"
        >
          <Text
            className="GDSNExportMappingsItemAutomaticProperties__referential"
            id={`gdsn-export-mappings-item-${name}-automatic-properties-referential`}
            value={referential}
            onChange={() => {}}
            disabled
          />
        </InputWithLabel>
      )}
      {is_declinable && !!referential && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-item-${name}-automatic-properties-declinable-by`}
          label="Declinable by"
        >
          <Text
            className="GDSNExportMappingsItemAutomaticProperties__declinableBy"
            id={`gdsn-export-mappings-item-${name}-automatic-properties-declinable-by`}
            value={referential}
            onChange={() => {}}
            disabled
          />
        </InputWithLabel>
      )}
    </div>
  );
}
