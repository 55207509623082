import { call, put, select, takeEvery } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import indexerApi from 'resources/indexerApi';
import { logError } from 'utils';
import { requestWithHeaders } from 'utils/api';

import authApi from '../../../resources/authApi';

import * as actions from './actions';
import * as events from './events';
import * as selectors from './selectors';

export default function* mainSaga() {
  yield takeEvery(
    [
      events.FETCH_QUEUE_LIST,
      events.SELECT_SOURCE,
      events.SELECT_PRIORITY,
      events.PREVIOUS_PAGE,
      events.NEXT_PAGE,
      events.CHANGE_PAGE_SIZE,
      events.SELECT_ORGANIZATION,
    ],
    fetchQueueList,
  );
  yield takeEvery(
    [events.FETCH_QUEUE_LIST, events.SELECT_SOURCE, events.SELECT_PRIORITY],
    reloadDetail,
  );
  yield takeEvery(events.FETCH_DETAIL, fetchDetail);
  yield takeEvery(
    events.SELECT_ORGANIZATION,
    fetchDetailFromSelectedOrganization,
  );
  yield takeEvery(events.FETCH_SOURCES, fetchSources);
  yield takeEvery(events.FETCH_QUEUE_STATUS, fetchQueueStatus);
  yield takeEvery(events.REINDEX_ORGANIZATION, reindexOrganization);
  yield takeEvery(
    events.FETCH_ORGANIZATION_STATISTICS,
    fetchOrganizationStatistics,
  );
  yield takeEvery(events.FETCH_ORGANIZATION, fetchOrganization);
}

function* fetchSources() {
  const { result, error } = yield call(
    requestWithHeaders,
    indexerApi,
    'get',
    '/indexer/config/sources/v1/all',
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load source list'));
  } else {
    yield put(actions.receivedSources(result.data));
  }
}

function* fetchQueueList() {
  yield put(actions.setLoading(true));
  const selectedFilters = yield select(selectors.selectSelectedFilters);
  const pageRequest = yield select(selectors.selectPageRequest);
  const { result, error } = yield call(
    requestWithHeaders,
    indexerApi,
    'post',
    `/indexer/organizations/v2/queue`,
    {
      ...selectedFilters,
      ...pageRequest,
    },
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load indexing queue'));
  } else {
    yield put(actions.receiveQueuePage(result.data));
    yield put(actions.receiveQueueList(result.data.content));
  }
  yield put(actions.setLoading(false));
}

function* loadDetail(organizationId: number) {
  const selectedFilters = yield select(selectors.selectSelectedFilters);
  const { result, error } = yield call(
    requestWithHeaders,
    indexerApi,
    'post',
    '/indexer/organizations/v2/queue/detail',
    {
      ...selectedFilters,
      organization_id: organizationId,
    },
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load organization detail'));
  } else {
    yield put(
      actions.receiveDetail({
        id: organizationId,
        entries: result.data.content,
        totalProducts: result.data.total_products,
      }),
    );
  }
}

function* reloadDetail() {
  const detail = yield select(selectors.selectDetail);
  if (detail !== null) {
    yield* loadDetail(detail.id);
  }
}

function* fetchDetail(event) {
  yield put(actions.receiveDetail(null));
  if (event.payload !== null) {
    yield* loadDetail(event.payload);
  }
}

function* fetchDetailFromSelectedOrganization(event) {
  yield put(actions.receiveDetail(null));
  if (event.payload !== null) {
    yield* loadDetail(event.payload.key);
  }
}

function* fetchQueueStatus() {
  const { result, error } = yield call(
    requestWithHeaders,
    indexerApi,
    'get',
    `/indexer/queue/v1/status`,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load queue status'));
  } else {
    yield put(actions.receivedQueueStatus(result.data));
  }
}

function* reindexOrganization(event) {
  const { error } = yield call(
    requestWithHeaders,
    indexerApi,
    'post',
    `/indexer/organizations/v1/reindex`,
    event.payload,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to reindex organization'));
  } else {
    yield put(notificationSuccess('Indexing launched...'));
  }
}

function* fetchOrganizationStatistics(event) {
  const { result, error } = yield call(
    requestWithHeaders,
    indexerApi,
    'post',
    `/indexer/organizations/v1/reindex/statistics`,
    event.payload,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to fetch organization statistics'));
  } else {
    yield put(actions.receiveOrganizationStatistics(result.data));
  }
}

function* fetchOrganization(event) {
  const { result, error } = yield call(
    requestWithHeaders,
    authApi,
    'get',
    `/auth/v2/organization/${event.payload}`,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to fetch organization'));
  } else {
    const org = result.data.data;
    yield put(
      actions.selectOrganization({
        key: org.id,
        label: `${org.nameLegal} - ${org.id}`,
        value: {
          id: org.id,
          name: org.nameLegal,
          type: org.type,
        },
      }),
    );
  }
}
