import { ValidationFilters } from '@alkem/sdk-dashboard';

import {
  FieldsRawApplicability,
  ReadOnlyRule,
  Rule,
  RuleIDsInRuleset,
  RuleSet,
} from 'modules/validation-dashboard/types';
import { createAction, createActionWithPayload } from 'utils/reducers';

import {
  VALIDATION_ADD_TO_FILTERS,
  VALIDATION_CLEAR_RULES_FILTERS,
  VALIDATION_CLEAR_SELECTED_RULES,
  VALIDATION_DELETE_RULE,
  VALIDATION_DELETE_RULES,
  VALIDATION_DELETE_RULESET,
  VALIDATION_DELETE_RULES_DONE,
  VALIDATION_DELETE_RULE_DONE,
  VALIDATION_DIRTY_RULE,
  VALIDATION_DUPLICATE_RULE,
  VALIDATION_DUPLICATE_RULES_IN_RULESET,
  VALIDATION_EDITED_RULE,
  VALIDATION_FETCHING_RULE_SET_TAGS,
  VALIDATION_FETCH_RULE,
  VALIDATION_FETCH_RULES,
  VALIDATION_FETCH_RULESET,
  VALIDATION_FETCH_RULESET_DONE,
  VALIDATION_FETCH_RULES_DONE,
  VALIDATION_FETCH_RULE_DIMENSIONS,
  VALIDATION_FETCH_RULE_DIMENSIONS_DONE,
  VALIDATION_FETCH_RULE_DONE,
  VALIDATION_FETCH_RULE_SET_TAGS,
  VALIDATION_FETCH_RULE_SET_TAGS_DONE,
  VALIDATION_FILL_ORGANIZATIONS_CACHE,
  VALIDATION_INIT_FILTERS,
  VALIDATION_INIT_FILTERS_DONE,
  VALIDATION_LIST_RULESETS,
  VALIDATION_MOVE_RULES_TO_RULESET,
  VALIDATION_REMOVE_FROM_FILTERS,
  VALIDATION_RESET_PATH_VALUE_FROM_FILTERS,
  VALIDATION_RESET_RULESET,
  VALIDATION_SAVE_RULE_CANCEL,
  VALIDATION_SAVE_RULE_DATA,
  VALIDATION_SAVE_RULE_DONE,
  VALIDATION_SEARCH_QUERY,
  VALIDATION_SET_IS_LOADING,
  VALIDATION_SET_IS_LOADING_DIMENSIONS,
  VALIDATION_SET_IS_LOADING_RULE,
  VALIDATION_SET_PAGINATION,
  VALIDATION_SET_PROCESSING_BULK_ACTION_STATE,
  VALIDATION_TOGGLE_RULES,
  VALIDATION_UPDATE_DIRTY_RULE,
} from './constants';
import {
  ProcessingBulkActionState,
  RuleSetTagsFetchState,
  ValidationFiltersState,
} from './moduleState';

export const validationInitFilters = createAction(VALIDATION_INIT_FILTERS);
export const validationInitFiltersDone =
  createActionWithPayload<ValidationFiltersState>(VALIDATION_INIT_FILTERS_DONE);

export const validationClearFilters = createAction(
  VALIDATION_CLEAR_RULES_FILTERS,
);

export const validationAddToFilters = createActionWithPayload<{
  path: string;
  value: any;
}>(VALIDATION_ADD_TO_FILTERS);
export const validationRemoveFromFilters = createActionWithPayload<{
  path: string;
  value: any;
}>(VALIDATION_REMOVE_FROM_FILTERS);
export const validationResetPathValueFromFilters = createActionWithPayload<{
  path: string;
}>(VALIDATION_RESET_PATH_VALUE_FROM_FILTERS);

export const validationDeleteRule = createActionWithPayload<number>(
  VALIDATION_DELETE_RULE,
);

export const validationDeleteRuleset = createActionWithPayload<number | string>(
  VALIDATION_DELETE_RULESET,
);

export const validationMoveRulesToRuleset =
  createActionWithPayload<RuleIDsInRuleset>(VALIDATION_MOVE_RULES_TO_RULESET);

export const validationDuplicateRulesInRuleset =
  createActionWithPayload<RuleIDsInRuleset>(
    VALIDATION_DUPLICATE_RULES_IN_RULESET,
  );

export const validationDeleteRules = createActionWithPayload<number[]>(
  VALIDATION_DELETE_RULES,
);
export const validationDeleteRulesDone = createActionWithPayload<number[]>(
  VALIDATION_DELETE_RULES_DONE,
);

export const validationSetProcessingBulkActionState =
  createActionWithPayload<ProcessingBulkActionState>(
    VALIDATION_SET_PROCESSING_BULK_ACTION_STATE,
  );

export const validationSetEditedRule = createActionWithPayload<Rule>(
  VALIDATION_EDITED_RULE,
);
export const validationSetDirtyRule = createActionWithPayload<Rule>(
  VALIDATION_DIRTY_RULE,
);
export const validationUpdateDirtyRule = createActionWithPayload<{
  path: string[];
  value: any;
}>(VALIDATION_UPDATE_DIRTY_RULE);

export const validationSetIsLoadingRule = createActionWithPayload<boolean>(
  VALIDATION_SET_IS_LOADING_RULE,
);
export const validationFetchRule = createActionWithPayload<number>(
  VALIDATION_FETCH_RULE,
);
export const validationFetchRuleDone = createActionWithPayload<ReadOnlyRule>(
  VALIDATION_FETCH_RULE_DONE,
);

export const validationFetchRuleDimensions =
  createActionWithPayload<ReadOnlyRule>(VALIDATION_FETCH_RULE_DIMENSIONS);

export const validationFetchRuleDimensionsDone =
  createActionWithPayload<FieldsRawApplicability>(
    VALIDATION_FETCH_RULE_DIMENSIONS_DONE,
  );

export const validationFetchRules = createAction(VALIDATION_FETCH_RULES);
export const validationFetchRulesDone = createActionWithPayload<{
  data: ReadOnlyRule[];
  totalResults: number;
  totalPages: number;
}>(VALIDATION_FETCH_RULES_DONE);
export const validationListRuleSets =
  createActionWithPayload<ValidationFilters>(VALIDATION_LIST_RULESETS);

export const validationFetchRuleSetTags = createAction(
  VALIDATION_FETCH_RULE_SET_TAGS,
);
export const validationFetchingRuleSetTags = createAction(
  VALIDATION_FETCHING_RULE_SET_TAGS,
);
export const validationFetchRuleSetTagsDone =
  createActionWithPayload<RuleSetTagsFetchState>(
    VALIDATION_FETCH_RULE_SET_TAGS_DONE,
  );
export const validationFetchRuleSet = createActionWithPayload<number>(
  VALIDATION_FETCH_RULESET,
);
export const validationFetchRuleSetDone = createActionWithPayload<RuleSet>(
  VALIDATION_FETCH_RULESET_DONE,
);
export const validationResetRuleSetFromStore = createAction(
  VALIDATION_RESET_RULESET,
);

export const validationSetPagination = createActionWithPayload<Object>(
  VALIDATION_SET_PAGINATION,
);

export const validationFillOrganizationsCache = createActionWithPayload<
  { id: number; name: string }[]
>(VALIDATION_FILL_ORGANIZATIONS_CACHE);

export const validationSaveRuleData = createActionWithPayload<ReadOnlyRule>(
  VALIDATION_SAVE_RULE_DATA,
);

export const validationSaveRuleCancel = createActionWithPayload<ReadOnlyRule>(
  VALIDATION_SAVE_RULE_CANCEL,
);
export const validationSaveRuleDone = createActionWithPayload<ReadOnlyRule>(
  VALIDATION_SAVE_RULE_DONE,
);
export const validationSearchQuery = createActionWithPayload<string>(
  VALIDATION_SEARCH_QUERY,
);
export const validationSetIsLoading = createActionWithPayload<boolean>(
  VALIDATION_SET_IS_LOADING,
);
export const validationSetIsLoadingDimensions =
  createActionWithPayload<boolean>(VALIDATION_SET_IS_LOADING_DIMENSIONS);
export const validationToggleRules = createActionWithPayload<{
  [id: number]: boolean;
}>(VALIDATION_TOGGLE_RULES);
export const validationClearSelectedRules = createAction(
  VALIDATION_CLEAR_SELECTED_RULES,
);
export const validationDuplicateRule = createActionWithPayload<number | string>(
  VALIDATION_DUPLICATE_RULE,
);
export const validationDeleteRuleDone = createActionWithPayload<number>(
  VALIDATION_DELETE_RULE_DONE,
);
