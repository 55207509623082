import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  DASHBOARD_TYPE_1,
  DASHBOARD_TYPE_2,
  DASHBOARD_TYPE_3,
} from '../constants/dashboard';
import * as events from '../constants/events';

const initialState = fromJS({
  dashboardList: [],
  filtersList: [],
  avsFilter: [],
});

export default createReducer(initialState, {
  [events.DASHBOARD_LIST_RECEIVED]: (state, { dashboardList }) => {
    return state.set(
      'dashboardList',
      dashboardList.sort((a, b) =>
        a.get('label').localeCompare(b.get('label')),
      ),
    );
  },

  [events.REPORTING_TABLE_RECEIVED]: (state, { reportingTable }) => {
    if (!reportingTable.data) {
      return state;
    }

    // Get Reporting aggregation detail
    const reportTable = reportingTable.data.toJS();
    let reportData = null;
    if (reportTable.type === DASHBOARD_TYPE_1) {
      reportData = reportTable.aggregations;
    } else if (reportTable.type === DASHBOARD_TYPE_2) {
      reportData = reportTable.aggregations.detail;
    } else if (reportTable.type === DASHBOARD_TYPE_3) {
      // if two tables are present, chose detail from (all/detail), else single table
      reportData = reportTable.aggregations.detail
        ? reportTable.aggregations.detail
        : reportTable.aggregations;
    }

    // Get filter of group_by facette on the reporting
    const filterKeys = reportData.meta
      .filter((imeta) => imeta.type === 'group_by')
      .reduce((acc, obj) => {
        acc[obj.key] = obj.filter;
        return acc;
      }, {});

    const reg = new RegExp('%\\(value\\)s', 'g');

    // Loop on all reporting row to get value of filter
    const rowsfilter = reportData.row_values.map((irow) => {
      // Get filters for the row
      const rowFilters = Object.keys(filterKeys).map((ikey) => {
        const ivalue = Array.isArray(irow[ikey]) ? irow[ikey][0] : irow[ikey];
        return (
          filterKeys[ikey][ivalue] ||
          (filterKeys[ikey]['%(value)s'] &&
            JSON.parse(
              JSON.stringify(filterKeys[ikey]['%(value)s']).replace(
                reg,
                ivalue,
              ),
            )) ||
          {}
        );
      });

      let rowFiltersConbined = null;
      if (rowFilters.length === 1) {
        [rowFiltersConbined] = rowFilters;
      }
      if (rowFilters.length > 1) {
        rowFiltersConbined = { must: rowFilters };
        // todo : nested
      }

      const groupKey = Object.keys(filterKeys).join(' / ');
      const rowKey = Object.keys(filterKeys)
        .map((ikey) => irow[ikey])
        .join(' / ');

      // Create filter object
      return {
        name: rowKey,
        key: `${groupKey} - ${rowKey}`,
        group: groupKey,
        filter: rowFiltersConbined,
      };
    });

    // Add the filter on the new filter
    const oldFilter = state.get('avsFilter').toJS();
    const oldFilterKey = oldFilter.map((fo) => fo.key);
    const newFilter = rowsfilter.filter((f) => !oldFilterKey.includes(f.key));

    // Finaly set the reporting for the display.
    return state.set('avsFilter', fromJS(oldFilter.concat(newFilter)));
  },
});
