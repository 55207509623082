import { useCallback, useState } from 'react';

import { isFunction } from 'utils';

const storage = window.localStorage;
const hasStorage =
  !!storage && isFunction(storage.getItem) && isFunction(storage.setItem);

function parse(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

const storageExport = {
  getItem(key: string, defaultValue?: any) {
    if (!hasStorage) {
      return defaultValue;
    }
    const value = storage.getItem(key);
    return parse(value) || defaultValue;
  },
  setItem(key: string, value: any) {
    if (!hasStorage) {
      return;
    }
    storage.setItem(key, JSON.stringify(value));
  },
};

export default storageExport;

export function useLocalStorage<T>(key: string, defaultValue?: T) {
  const [value, setValue] = useState<T>(
    () => storageExport.getItem(key) || defaultValue,
  );

  const setItem = useCallback(
    (newValue: T) => {
      setValue(newValue);
      storageExport.setItem(key, newValue);
    },
    [key],
  );

  return [value, setItem] as [typeof value, typeof setItem];
}
