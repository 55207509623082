import { Env } from '@alkem/sdk-dashboard';

import { getAppConfig } from './app-config';
import { isSalsify } from './location';

export const getStreamUrl = (
  path: string = '',
  location: Location = window.location,
): string => {
  let url = getAppConfig().sxmAppUrl;
  if (!url) {
    switch (Env.getEnv().env) {
      case 'ppr':
        url = isSalsify()
          ? 'https://ppr-app.supplierxm.salsify.com'
          : 'https://ppr-stream.alkemics.com';
        break;
      case 'alk':
      case 'int':
      case 'pillar':
        url = `${location.protocol}//${location.host.replace(/^admin-/g, '')}`;
        break;
      case 'ltg':
        url = isSalsify()
          ? 'https://ltg-app.supplierxm.salsify.com'
          : 'https://ltg-stream.alkemics.com';
        break;
      default:
        url = isSalsify()
          ? 'https://app.supplierxm.salsify.com'
          : 'https://stream.alkemics.com';
    }
  }
  return `${url}/#/${path}`;
};
