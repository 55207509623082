import { useState } from 'react';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

import { IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_UNIT_STRING } from '../../../../constants';
import { ImportMappingDetails, Property } from '../../../../types';

interface Props {
  details: ImportMappingDetails;
  dispatchImportMapping: (properties: Property[]) => void;
  fieldName: string;
  type: string;
}
export function ImportMappingsItemOptionalPropertyUnitString({
  details,
  dispatchImportMapping,
  fieldName,
  type,
}: Props): JSX.Element {
  const [unitString, setUnitString] = useState(details.unitString);

  const updateUnitString = (event: { target: { value: string } }) => {
    setUnitString(event.target.value);
    dispatchImportMapping([{ name: 'unitString', value: event.target.value }]);
  };

  return (
    <>
      {IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_UNIT_STRING.includes(type) &&
        details.isDeclinable && (
          <InputWithLabel
            inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-unit-string`}
            label={
              <span>
                Unit string{' '}
                <HelpTooltip
                  id={`gdsn-import-mappings-field-${fieldName}-optional-property-unit-string-help`}
                  place="top"
                  message="Attribute name of the xml tag that will allow to get the referential code"
                />
              </span>
            }
          >
            <Text
              id={`gdsn-import-mappings-field-${fieldName}-optional-property-unit-string`}
              value={unitString}
              onChange={updateUnitString}
            />
          </InputWithLabel>
        )}
    </>
  );
}
