import { notificationError } from 'actions/notifications';
import { immutableKpiApi } from 'resources/kpiApi';
import { saveAs } from 'utils';

import * as events from '../constants/events';

const createAdvancedSearch = (avsFilters) => {
  let advancedSearchList = [];

  const groupFilter = avsFilters.filter((it) => it.group);

  if (groupFilter.length !== 0) {
    const byGroupFilter = groupFilter.reduce((acc, it) => {
      const key = it.group;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(it);
      return acc;
    }, {});
    advancedSearchList = Object.keys(byGroupFilter).map((key) => ({
      should: byGroupFilter[key].map((filter) => filter.filter),
    }));
  }

  avsFilters
    .filter((it) => !it.group)
    .forEach((it) => {
      advancedSearchList.push(it.filter);
    });
  let result = null;
  if (advancedSearchList.length !== 0) {
    result = { must: advancedSearchList };
  }
  return result;
};

export const clearReportingTable = () => ({
  type: events.REPORTING_TABLE_RECEIVED,
  reportingTable: { data: null },
});

export const getReportingTable =
  (orgaId, dashboard, avsFilters) => async (dispatch) => {
    dispatch({
      type: events.REPORTING_TABLE_FETCH,
    });

    const newAvsFilters = Object.values(avsFilters);
    const advancedSearch = createAdvancedSearch(newAvsFilters);

    try {
      const response = await immutableKpiApi.GetAdminReportingTableAs(
        orgaId,
        dashboard.id,
        { advanced_search: advancedSearch },
      );
      dispatch({
        type: events.REPORTING_TABLE_RECEIVED,
        reportingTable: response.data,
      });
    } catch (err) {
      dispatch(notificationError('Cannot load reporting table'));
    } finally {
      dispatch({
        type: events.REPORTING_TABLE_DONE,
      });
    }
  };

export const getReportingTableAsAdmin =
  (dashboard, avsFilters) => async (dispatch) => {
    dispatch({
      type: events.REPORTING_TABLE_FETCH,
    });

    const newAvsFilters = Object.values(avsFilters);
    dashboard.data.reporting.orga_type.forEach((orgaType) => {
      const key = 'orgaType'.concat(String(orgaType));
      newAvsFilters.push({
        key,
        group: 'orgaType',
        filter: { query: orgaType, fields: ['owner.type'], type: 'match' },
      });
    });

    const advancedSearch = createAdvancedSearch(newAvsFilters);

    try {
      const response = await immutableKpiApi.GetAdminReportingTable(
        dashboard.id,
        {
          advanced_search: advancedSearch,
        },
      );
      dispatch({
        type: events.REPORTING_TABLE_RECEIVED,
        reportingTable: response.data,
      });
    } catch (err) {
      dispatch(notificationError(`Cannot load reporting table ${err}`));
    } finally {
      dispatch({
        type: events.REPORTING_TABLE_DONE,
      });
    }
  };

export const getReportingTableTransifex =
  (orgaId, dashboard, avsFilters) => async (dispatch) => {
    dispatch({
      type: events.REPORTING_TABLE_FETCH,
    });

    const newAvsFilters = Object.values(avsFilters);
    const advancedSearch = createAdvancedSearch(newAvsFilters);

    try {
      await immutableKpiApi.PushTranslationKeysAdminReportingTableAs(
        orgaId,
        dashboard.id,
        { advanced_search: advancedSearch },
        false,
      );
    } catch (err) {
      dispatch(notificationError('Cannot load reporting table'));
    } finally {
      dispatch({
        type: events.REPORTING_TABLE_DONE,
      });
    }
  };

export const getReportingTableTransifexAsAdmin =
  (dashboard, avsFilters) => async (dispatch) => {
    dispatch({
      type: events.REPORTING_TABLE_FETCH,
    });

    const newAvsFilters = Object.values(avsFilters);
    dashboard.data.reporting.orga_type.forEach((orgaType) => {
      const key = 'orgaType'.concat(String(orgaType));
      newAvsFilters.push({
        key,
        group: 'orgaType',
        filter: { query: orgaType, fields: ['owner.type'], type: 'match' },
      });
    });
    const advancedSearch = createAdvancedSearch(newAvsFilters);

    try {
      await immutableKpiApi.PushTranslationKeysAdminReportingTable(
        dashboard.id,
        { advanced_search: advancedSearch },
        false,
      );
    } catch (err) {
      dispatch(notificationError('Cannot load reporting table'));
    } finally {
      dispatch({
        type: events.REPORTING_TABLE_DONE,
      });
    }
  };

export const downloadReportingTable =
  (orgaId, dashboard, avsFilters) => async (dispatch) => {
    dispatch({
      type: events.REPORTING_TABLE_DOWNLOAD,
    });

    const newAvsFilters = Object.values(avsFilters);
    const advancedSearch = createAdvancedSearch(newAvsFilters);

    try {
      const response = await immutableKpiApi.GetAdminReportingTableAs(
        orgaId,
        dashboard.id,
        { advanced_search: advancedSearch },
        true,
      );
      const filename = `reporting-${dashboard.label}.xls`;
      saveAs(response.data, filename);
    } catch (err) {
      dispatch(notificationError('Cannot download reporting table'));
    } finally {
      dispatch({
        type: events.REPORTING_TABLE_DOWNLOAD_DONE,
      });
    }
  };

export const downloadReportingTableAsAdmin =
  (orgaIds, dashboard, avsFilters) => async (dispatch) => {
    dispatch({
      type: events.REPORTING_TABLE_DOWNLOAD,
    });

    const newAvsFilters = Object.values(avsFilters);
    dashboard.data.reporting.orga_type.forEach((orgaType) => {
      const key = 'orgaType'.concat(String(orgaType));
      newAvsFilters.push({
        key,
        group: 'orgaType',
        filter: { query: orgaType, fields: ['owner.type'], type: 'match' },
      });
    });
    const advancedSearch = createAdvancedSearch(newAvsFilters);

    try {
      const response = await immutableKpiApi.GetAdminReportingTable(
        dashboard.id,
        { advanced_search: advancedSearch },
        true,
      );
      const filename = `reporting-${dashboard.label}.xls`;
      saveAs(response.data, filename);
    } catch (err) {
      dispatch(notificationError('Cannot download reporting table'));
    } finally {
      dispatch({
        type: events.REPORTING_TABLE_DOWNLOAD_DONE,
      });
    }
  };
