import proptypes from 'prop-types';
import React from 'react';

import './suggestion.scss';

export function Suggestion({
  command: commandProps,
  handleClickSuggestion,
  isFocused,
  handleMouseOver,
  handleMouseOut,
}) {
  const { command, title, help, param } = commandProps;
  const commandToDisplay = param ? `${command} ${param}:` : command;
  return (
    <button
      className={
        isFocused
          ? 'CommandPalette__Suggestion CommandPalette__Suggestion__selected'
          : 'CommandPalette__Suggestion'
      }
      onClick={() => handleClickSuggestion(commandToDisplay, !param)}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="CommandPalette__SuggestionCommand">{command}</div>
      <div className="CommandPalette__SuggestionInfo">
        <div className="CommandPalette__SuggestionTitle">{title}</div>
        <div className="CommandPalette__SuggestionHelp">{help || ''}</div>
      </div>
    </button>
  );
}

Suggestion.propTypes = {
  command: proptypes.shape({
    command: proptypes.string,
    title: proptypes.string,
    help: proptypes.string,
    param: proptypes.string,
  }),
  handleClickSuggestion: proptypes.func,
  isFocused: proptypes.bool,
  handleMouseOver: proptypes.func,
  handleMouseOut: proptypes.func,
};
