export const STATUS_PENDING = {
  id: 0,
  name: 'Pending',
  label: 'Pending',
  statusbar: 'PENDING',
};
export const STATUS_VALIDATED = {
  id: 1,
  name: 'Validated',
  label: 'Validated',
  statusbar: 'SUCCESS',
};
export const STATUS_REJECTED = {
  id: 2,
  name: 'Rejected',
  label: 'Rejected',
  statusbar: 'FAILURE',
};
export const STATUS_APPLIED = {
  id: 3,
  name: 'Applied',
  label: 'Applied',
  statusbar: 'NOT_ACTIVE',
};
export const STATUS_DISCARDED = {
  id: 4,
  name: 'Discarded',
  label: 'Discarded',
  statusbar: 'CONTAINS_ERRORS',
};
export const STATUS_DRAFT = {
  id: 5,
  name: 'Draft',
  label: 'Draft',
  statusbar: 'IN_PROGRESS',
};
export const STATUS_APPLYING = {
  id: 6,
  name: 'Applying',
  label: 'Applying',
  statusbar: 'TIMEOUT',
};
// This one should never appear, but to avoid any crash it is still needed.
// If you see one, please notify the Senate
export const STATUS_INVISIBLE = {
  id: '-1',
  name: 'Invisible',
  label: 'Invisible',
  statusbar: 'INVISIBLE',
};

export const STATUSES = [
  STATUS_PENDING,
  STATUS_VALIDATED,
  STATUS_REJECTED,
  STATUS_APPLIED,
  STATUS_DISCARDED,
  STATUS_DRAFT,
  STATUS_APPLYING,
  STATUS_INVISIBLE,
].reduce((acc, item) => {
  acc[item.id] = item;
  return acc;
}, {});

export const STATUSES_FILTERS = Object.keys(STATUSES)
  .map((k) => Object.assign({}, STATUSES[k], { id: k }))
  .filter((e) => e.id !== '-1');

export const ROLES = {
  0: { name: 'Source', direction: 'from' },
  1: { name: 'Target', direction: 'to' },
};

export const ROLES_FILTERS = Object.keys(ROLES).map((k) => ({
  key: k.toString(),
  value: k,
  label: ROLES[k].direction,
}));

export const EVENT_IMPORT = 'import';
export const EVENT_API = 'writeapi';
export const EVENT_SHARE = 'share';
export const EVENT_SHARE_MEDIA = 'share_media';
export const EVENT_SHARINGUNIT = 'sharingunit';
export const EVENT_SHARE_HIERARCHY = 'share_hierarchy';
export const EVENT_ARCHIVE = 'archive';
export const EVENT_SHARE_VALIDATION_BYPASS = 'share_validation_bypass';
export const EVENT_FIELD_CONTRIBUTION = 'field_contribution';

export const EVENT_TYPES = {
  [EVENT_IMPORT]: 'IMPORT',
  [EVENT_API]: 'WRITE_API',
  [EVENT_SHARE]: 'SHARE',
  [EVENT_SHARE_MEDIA]: 'SHARE_MEDIA',
  [EVENT_SHARINGUNIT]: 'SHARINGUNIT',
  [EVENT_SHARE_HIERARCHY]: 'SHARE_HIERARCHY',
  [EVENT_ARCHIVE]: 'ARCHIVE',
  [EVENT_SHARE_VALIDATION_BYPASS]: 'SHARE_VALIDATION_BYPASS',
  [EVENT_FIELD_CONTRIBUTION]: 'FIELD_CONTRIBUTION',
};

export const EVENT_TYPES_FILTERS = Object.keys(EVENT_TYPES).map((k) => ({
  key: k,
  value: k,
  label: EVENT_TYPES[k],
}));

export const EVENT_ACTIONS = {
  create: 'CREATE',
  update: 'UPDATE',
  delete: 'DELETE',
  no_data: 'NO_DATA',
};

export const EVENT_ACTIONS_FILTERS = Object.keys(EVENT_ACTIONS).map((k) => ({
  key: k,
  value: k,
  label: EVENT_ACTIONS[k],
}));

export const FILTER_PRESETS = [
  {
    key: 'import',
    label: 'Import',
    filters: {
      statuses: [STATUS_PENDING.id, STATUS_VALIDATED.id],
      eventTypes: [EVENT_IMPORT, EVENT_API],
    },
  },
  {
    key: 'history',
    label: 'History',
    filters: {
      statuses: [STATUS_APPLIED.id],
      eventTypes: [EVENT_ARCHIVE],
    },
  },
  {
    key: 'share',
    label: 'Share',
    filters: {
      statuses: [
        STATUS_PENDING.id,
        STATUS_VALIDATED.id,
        STATUS_REJECTED.id,
        STATUS_DISCARDED.id,
        STATUS_APPLYING.id,
      ],
      eventTypes: [
        EVENT_SHARE,
        EVENT_SHARE_MEDIA,
        EVENT_SHARINGUNIT,
        EVENT_SHARE_HIERARCHY,
        EVENT_SHARE_VALIDATION_BYPASS,
      ],
    },
  },
  { key: 'clear', label: 'Clear Filters', filters: {} },
];
