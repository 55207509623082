import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './selected.scss';
import SelectedFiltersTag from './tag';

class SelectedFilters extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    ignore: PropTypes.array,
    removeAllFilters: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ignore: [],
  };

  constructor(props) {
    super(props);
    this.removeAllFilters = this.removeAllFilters.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.filters !== this.props.filters;
  }

  removeAllFilters() {
    const { removeAllFilters } = this.props;
    removeAllFilters();
  }

  renderFilters() {
    const { removeFilter, filters, ignore } = this.props;
    let hasFilterSelected = false;

    const selectedFilters = Object.keys(filters).map((type) => {
      const ignoreItem = ignore.find((item) => item.type === type);
      const filter = filters[type];

      if (
        ignoreItem &&
        (!ignoreItem.ids || (filter && ignoreItem.ids.indexOf(filter.id) > -1))
      ) {
        return null;
      }

      if (Array.isArray(filter)) {
        return filter.map((entity) => {
          hasFilterSelected = true;
          return (
            <SelectedFiltersTag
              tag={entity}
              name={type}
              removeFilter={removeFilter}
              key={`${entity.id}-${entity.value}`}
            />
          );
        });
      } else if (filter) {
        hasFilterSelected = true;
        return (
          <SelectedFiltersTag
            tag={filter}
            name={type}
            removeFilter={removeFilter}
            key={`${filter.id}-${filter.value}`}
          />
        );
      }
      return null;
    });

    if (hasFilterSelected) {
      return (
        <div>
          {this.renderRemoveAllFilters()}
          <div className="button-group SelectedFilters__chipsList">
            {selectedFilters}
          </div>
        </div>
      );
    }

    return (
      <div className="SelectedFilters__noFilters">No filters selected</div>
    );
  }

  renderRemoveAllFilters() {
    return (
      <a className="SelectedFilters__removeAll" onClick={this.removeAllFilters}>
        Remove
      </a>
    );
  }

  render() {
    return (
      <aside className="SelectedFilters">
        <h3 className="SelectedFilters__title">Selected filters</h3>
        {this.renderFilters()}
      </aside>
    );
  }
}

export default SelectedFilters;
