import { ReactNode } from 'react';

import { Source } from '../types';

export function getSourceLabel(source: Source): ReactNode {
  if (!source.description) {
    return source.label;
  }
  return `${source.label} - ${source.description}`;
}
