import { Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from '../constants/events';

const initialState = fromJS({
  isLoading: false,
  isDownloading: false,
  reportingTable: {},
});

export default createReducer(initialState, {
  [events.REPORTING_TABLE_FETCH]: (state) =>
    state.set('isLoading', true).set('reportingTable', Map()),

  [events.REPORTING_TABLE_DONE]: (state) => state.set('isLoading', false),

  [events.REPORTING_TABLE_DOWNLOAD]: (state) =>
    state.set('isDownloading', true),

  [events.REPORTING_TABLE_DOWNLOAD_DONE]: (state) =>
    state.set('isDownloading', false),

  [events.REPORTING_TABLE_RECEIVED]: (state, { reportingTable }) =>
    state.set('reportingTable', reportingTable.data),
});
