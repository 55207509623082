import { List, Map } from 'immutable';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Radio } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';

import { ProductAutocomplete } from 'components/autocomplete';

import { cleanViewAs } from '../../actions';

import './cleanProductsModal.scss';

const radioOptions = [
  { value: 'select', label: 'Select some products' },
  { value: 'all', label: 'All products' },
];

const mapDispatchToProps = {
  cleanViewAs,
};

class CleanProductsModal extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    cleanViewAs: PropTypes.func.isRequired,
  };

  state = {
    isBusy: false,
    allProducts: false,
    products: List(),
  };

  onAddProduct = ({ key: id, label }) => {
    this.setState(({ products }) => ({
      products:
        products.findIndex((p) => p.get('id') === id) >= 0
          ? products
          : products.push(Map({ id, label })),
    }));
  };

  onRemoveProduct = (product) => () => {
    this.setState(({ products }) => ({
      products: products.filter((p) => p.get('id') !== product.get('id')),
    }));
  };

  onRadioChange = (evt) => {
    this.setState({ allProducts: evt.target.value === 'all' });
  };

  clean = () => {
    const { organizationId } = this.props;
    const { products, allProducts } = this.state;
    if (!allProducts && products.size === 0) {
      return;
    }

    this.setState({ isBusy: true });
    this.props
      .cleanViewAs(
        organizationId,
        allProducts ? null : products.map((p) => p.get('id')).toArray(),
      )
      .then(() => {
        this.setState({ isBusy: false });
        this.onClose();
      });
  };

  onClose = () => {
    this.setState({ products: List() });
    this.props.onClose();
  };

  render() {
    const { organizationId } = this.props;
    const { isBusy, products, allProducts } = this.state;
    return (
      <Modal
        title="Search fields"
        confirmButtonText="Clean"
        isProcessing={isBusy}
        onConfirm={this.clean}
        onClose={this.onClose}
        additionalFooterContent="The cleaning will be asynchronous, but it should not be long"
      >
        <Radio
          id="clean-products-modal-radio-button"
          value={allProducts ? radioOptions[1].value : radioOptions[0].value}
          options={radioOptions}
          onChange={this.onRadioChange}
        />
        {!allProducts && (
          <>
            <ProductAutocomplete
              id="clean-products-modal-product-autocomplete"
              className="CleanProductsModal__Autocomplete"
              onSelect={this.onAddProduct}
              onUnselect={noop}
              value={null}
              searchOnClick
              disabled={isBusy}
              extraParams={{
                filter_owners_in: [organizationId],
                allow_not_consumer_units: true,
                filter_is_displayable_as_target: false,
              }}
            />
            <div>
              {products
                .map((product) => (
                  <div
                    className="CleanProductsModal__Product"
                    key={product.get('id')}
                  >
                    <div>{product.get('label')}</div>
                    <div>
                      <span
                        className="mdi mdi-delete"
                        onClick={this.onRemoveProduct(product)}
                      />
                    </div>
                  </div>
                ))
                .toArray()}
            </div>
          </>
        )}
      </Modal>
    );
  }
}

export default connect(null, mapDispatchToProps)(CleanProductsModal);
