import { List, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Checkbox } from '@alkem/react-ui-inputs';
import { Select } from '@alkem/react-ui-select';

import InputWithLabel from 'components/input-with-label';

import DisplayGroupPicker from './display-groups';
import FieldKinds from './kinds';
import FieldOrganizations from './organizations';
import FieldPackagingTypes from './packaging-types';

export default class FieldVisibilityInfo extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    path: PropTypes.array.isRequired,
    update: PropTypes.func.isRequired,
    tags: PropTypes.array,
    isRoot: PropTypes.bool.isRequired,
    isConsumerUnitField: PropTypes.bool.isRequired,
    displayHeader: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    displayHeader: true,
  };

  onUpdateEnabled = (event) => {
    const { path, update } = this.props;
    update([...path, 'enabled'], event.target.checked);
  };

  onUpdateIsApplicableForLU = (event) => {
    const { path, update } = this.props;
    update([...path, 'isApplicableToLU'], event.target.checked);
  };

  onUpdateIsApplicableForDU = (event) => {
    const { path, update } = this.props;
    update([...path, 'isApplicableToDU'], event.target.checked);
  };

  onUpdateDisplayGroup = (displayGroup) => {
    const { path, update } = this.props;
    update([...path, 'belongsToDisplayGroups'], fromJS([displayGroup]));
  };

  onUpdatePackagingTypes = (packagingTypes) => {
    const { path, update } = this.props;
    update([...path, 'applicableForTypePackagings'], packagingTypes);
  };

  onUpdateKinds = (kinds) => {
    const { path, update } = this.props;
    update([...path, 'applicableForKinds'], kinds);
  };

  onAddTag = (tag) => {
    const { field, path, update } = this.props;
    const tags = field.get('tags') || List();
    if (!tags.includes(tag.label)) {
      update([...path, 'tags'], tags.push(tag.label));
    }
  };

  onRemoveTag = (index) => {
    const { field, path, update } = this.props;
    const tags = field.get('tags');
    update([...path, 'tags'], tags.remove(index));
  };

  onUpdatePrivate = (event) => {
    const { path, update } = this.props;
    update([...path, 'private'], event.target.checked);
  };

  onUpdateSpecific = (event) => {
    const { path, update } = this.props;
    update([...path, 'specific'], event.target.checked);
  };

  onUpdateOrganizations = (organizations) => {
    const { path, update } = this.props;
    update([...path, 'applicableForOrganizations'], organizations);
  };

  render() {
    const {
      field,
      isRoot,
      tags,
      isConsumerUnitField,
      path,
      displayHeader,
      readOnly,
    } = this.props;
    return (
      <div className="FieldVisibilityInfo">
        {displayHeader && (
          <div className="FieldForm__sectionTitle">Visibility information</div>
        )}
        <InputWithLabel
          inputId={`field-${path.join('-')}-enabled`}
          label="Enabled on stream"
        >
          <Checkbox
            id={`field-${path.join('-')}-enabled`}
            checked={field.get('enabled')}
            onChange={this.onUpdateEnabled}
            label={<div className="FieldForm__checkboxLabel" />}
            disabled={readOnly}
          />
        </InputWithLabel>
        {isRoot && isConsumerUnitField && field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-isApplicableToLU`}
            label="Applicable for logistical units"
          >
            <Checkbox
              id={`field-${path.join('-')}-isApplicableToLU`}
              checked={field.get('isApplicableToLU')}
              onChange={this.onUpdateIsApplicableForLU}
              label={<div className="FieldForm__checkboxLabel" />}
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {isRoot && isConsumerUnitField && field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-isApplicableToDU`}
            label="Applicable for display units"
          >
            <Checkbox
              id={`field-${path.join('-')}-isApplicableToDU`}
              checked={field.get('isApplicableToDU')}
              onChange={this.onUpdateIsApplicableForDU}
              label={<div className="FieldForm__checkboxLabel" />}
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {isRoot && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-belongsToDisplayGroups`}
            label="Position in product page"
          >
            <DisplayGroupPicker
              link={field.getIn(['belongsToDisplayGroups', 0])}
              fieldId={field.get('id')}
              save={this.onUpdateDisplayGroup}
              readOnly={readOnly}
            />
          </InputWithLabel>
        )}
        {isRoot && isConsumerUnitField && field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-applicableForTypePackagings`}
            label="Applicable for packaging types"
          >
            <FieldPackagingTypes
              id={`field-${path.join('-')}-applicableForTypePackagings`}
              packagingTypes={
                field.get('applicableForTypePackagings') || List()
              }
              onChange={this.onUpdatePackagingTypes}
              readOnly={readOnly}
            />
          </InputWithLabel>
        )}
        {isRoot && isConsumerUnitField && field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-applicableForKinds`}
            label="Applicable for kinds"
          >
            <FieldKinds
              id={`field-${path.join('-')}-applicableForKinds`}
              kinds={field.get('applicableForKinds') || List()}
              onChange={this.onUpdateKinds}
              readOnly={readOnly}
            />
          </InputWithLabel>
        )}
        {isRoot && field.get('type') !== 'copy' && (
          <InputWithLabel inputId={`field-${path.join('-')}-tags`} label="Tags">
            <Select
              id={`field-${path.join('-')}-tags`}
              placeholder="Add a tag..."
              options={tags}
              values={(field.get('tags') || List())
                .toArray()
                .map((t) => ({ label: t }))}
              onValueAdd={this.onAddTag}
              onValueDelete={this.onRemoveTag}
              inputable
              multiple
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {isRoot && isConsumerUnitField && field.get('private') && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-private`}
            label="Private"
          >
            <Checkbox
              id={`field-${path.join('-')}-private`}
              checked={field.get('private')}
              onChange={this.onUpdatePrivate}
              label={<div className="FieldForm__checkboxLabel" />}
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {isRoot && isConsumerUnitField && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-specific`}
            label="Specific"
          >
            <Checkbox
              id={`field-${path.join('-')}-specific`}
              checked={field.get('specific')}
              onChange={this.onUpdateSpecific}
              label={<div className="FieldForm__checkboxLabel" />}
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {isRoot && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-applicableForOrganizations`}
            label={
              isConsumerUnitField
                ? 'Scope to organizations'
                : 'Override display info for organizations'
            }
          >
            <FieldOrganizations
              id={`field-${path.join('-')}-applicableForOrganizations`}
              organizations={field.get('applicableForOrganizations') || List()}
              onChange={this.onUpdateOrganizations}
              readOnly={readOnly}
              isConsumerUnitField={isConsumerUnitField}
            />
          </InputWithLabel>
        )}
      </div>
    );
  }
}
