import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';
import { Filter } from '@alkem/react-ui-filter';
import { Select } from '@alkem/react-ui-select';

export default class OrganizationReferentials extends Component {
  static propTypes = {
    editedOrganization: ImmutablePropTypes.map,
    editedReferentials: ImmutablePropTypes.list,
    isDirty: PropTypes.bool,
    selectedRootNodeId: PropTypes.number,
    isSaving: PropTypes.bool,
    isLoading: PropTypes.bool,
    toggleReferential: PropTypes.func,
    saveReferentials: PropTypes.func,
    selectRootNode: PropTypes.func,
  };

  onSelectRoot = (node) => this.props.selectRootNode(node.id);

  onClearRoot = () => this.props.selectRootNode(null);

  onChangeReferentialTree = (_, referential, value) => {
    this.props.toggleReferential(referential, value);
  };

  craftTree = (tree, editedOrganizationId) =>
    tree.map((node) => ({
      ...node,
      children: node.children
        ? this.craftTree(node.children, editedOrganizationId)
        : [],
      value: node.id, // to have unique data-for for checkboxes in FilterTree
      checked: node.organizations
        .map((organization) => organization.organization_id)
        .includes(editedOrganizationId),
    }));

  render = () => {
    const {
      editedOrganization,
      editedReferentials,
      selectedRootNodeId,
      isDirty,
      isSaving,
      isLoading,
      saveReferentials,
    } = this.props;
    const tree = this.craftTree(
      editedReferentials.toJS(),
      editedOrganization.get('id'),
    );
    const options = editedReferentials
      .toJS()
      .map((ref) => ({ ...ref, children: [] })); // top node without children
    const selected = options.filter((opt) => opt.id === selectedRootNodeId);
    return (
      <div id="TabReferentials__ReferentialsTree">
        <Select
          id="organization-referentials-tree-select-root"
          onValueAdd={this.onSelectRoot}
          onValueDelete={this.onClearRoot}
          values={selected}
          options={options}
          placeholder=""
        />
        {selectedRootNodeId && (
          <Filter
            name="OrganizationHasReferentials"
            type="tree"
            filters={
              tree.filter((node) => node.id === selectedRootNodeId)[0].children
            }
            onChange={this.onChangeReferentialTree}
          />
        )}
        <div className="OrganizationUsesFieldsView__referentials__tree__save">
          <Button
            id="organization-referentials-tree-save"
            onClick={saveReferentials}
            content="Save referentials"
            disabled={isLoading || !isDirty}
            displaySpinner={isSaving}
            primary
          />
        </div>
      </div>
    );
  };
}
