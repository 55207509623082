import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CellHistoryQuality from './form-utils/cell-history-quality';
import CellHistorySimple from './form-utils/cell-history-simple';
// Display
import './table-result.scss';

export class TableResult extends PureComponent {
  static propTypes = {
    reportDataDetail: PropTypes.object,
    reportDataAll: PropTypes.object,
    columns: PropTypes.object,
  };

  renderSimple(value) {
    if (Array.isArray(value)) {
      return <div>{value[1]}</div>;
    }
    return <div>{value}</div>;
  }

  renderJson(value) {
    return (
      <div style={{ height: '100%', textAlign: 'left' }}>
        <pre>{JSON.stringify(value, null, '    ')}</pre>
      </div>
    );
  }

  renderPercentage(value) {
    if (!value || value.doc_count === 0) {
      return <div>-</div>;
    }
    return (
      <div>
        <div>{`${Math.round((value.ok / value.doc_count) * 100)}%`}</div>
        <div>{`${value.fail} / ${value.doc_count}`}</div>
      </div>
    );
  }

  renderFraction(value) {
    return (
      <div>
        <div>{`${value.num} / ${value.den}`}</div>
      </div>
    );
  }

  renderAverage(value) {
    return (
      <div>
        <div>{`${value.avg} (based on ${value.doc_count})`}</div>
      </div>
    );
  }

  renderHistoryQuality(value) {
    return (
      <div>
        <CellHistoryQuality data={value} />
      </div>
    );
  }

  renderHistorySimple(value) {
    return (
      <div>
        <CellHistorySimple data={value} />
      </div>
    );
  }

  renderTime(value, cell) {
    const seconds = value.avg;
    let avgTime;
    let unitSingular;
    let unitPlural;
    let lessThanOne;
    if (typeof seconds !== 'number' || seconds < 0) {
      return <div>-</div>;
    }

    const duration = moment.duration(seconds, 'seconds');

    switch (cell.unit) {
      case 'day':
        avgTime = Math.round(duration.asDays());
        unitSingular = `${avgTime} day`;
        unitPlural = `${avgTime} days`;
        lessThanOne = '< 1 day';
        break;
      case 'hour':
        avgTime = Math.round(duration.asHours());
        unitSingular = `${avgTime} hour`;
        unitPlural = `${avgTime} hours`;
        lessThanOne = '< 1 hour';
        break;
      case 'minute':
        avgTime = Math.round(duration.asMinutes());
        unitSingular = `${avgTime} minute`;
        unitPlural = `${avgTime} minutes`;
        lessThanOne = '< 1 minute';
        break;
      default:
        avgTime = Math.round(duration.asSeconds());
        unitSingular = `${avgTime} second`;
        unitPlural = `${avgTime} seconds`;
        lessThanOne = '< 1 second';
        break;
    }

    if (avgTime < 1) {
      return <div>{lessThanOne}</div>;
    } else if (avgTime < 2) {
      return <div>{unitSingular}</div>;
    } else {
      return <div>{unitPlural}</div>;
    }
  }

  renderValue(value, cell) {
    if (cell && ['simple-quality', 'total-percentage'].includes(cell.type)) {
      return this.renderPercentage(value);
    }
    if (cell && cell.type === 'fraction') {
      return this.renderFraction(value);
    }
    if (cell && cell.type === 'avg-time') {
      return this.renderTime(value, cell);
    }
    if (cell && cell.type === 'json') {
      return this.renderJson(value, cell);
    }
    if (cell && cell.type === 'history-quality') {
      return this.renderHistoryQuality(value, cell);
    }
    if (cell && cell.type === 'history-simple') {
      return this.renderHistorySimple(value, cell);
    }
    if (cell && cell.type === 'avg') {
      return this.renderAverage(value, cell);
    }
    return this.renderSimple(value);
  }

  renderAggregationCell(dataRow, key, columns) {
    // Get the filter of the cell
    const indexKey = Object.keys(columns).indexOf(key);
    // Get All key of columns need to craft the  filter
    const filterKeys = Object.keys(columns).filter(
      (ikey, idx) =>
        idx <= indexKey &&
        (idx === indexKey || columns[ikey].type === 'group_by'),
    );

    const listFilters = [];
    // Get the list of all filter we need
    filterKeys.forEach((ikey) => {
      if (columns[ikey].filter !== undefined) {
        let newFilter = null;

        if (columns[ikey].type === 'group_by') {
          let v = dataRow[ikey];
          if (
            columns[ikey].cell !== undefined &&
            columns[ikey].cell.type === 'text' &&
            typeof v === 'object'
          ) {
            [v] = v;
          }

          if (v !== undefined) {
            if (columns[ikey].filter[v] !== undefined) {
              newFilter = columns[ikey].filter[v];
            } else if (columns[ikey].filter['%(value)s'] !== undefined) {
              newFilter = JSON.parse(
                JSON.stringify(columns[ikey].filter['%(value)s']).replace(
                  /%\(value\)s/g,
                  v,
                ),
              );
            }
          }
        } else {
          if (
            ['simple-quality', 'total-percentage'].includes(
              columns[ikey].cell.type,
            )
          ) {
            if (columns[ikey].filter.fail !== undefined) {
              newFilter = columns[ikey].filter.fail;
            } else {
              newFilter = columns[ikey].filter;
            }
          }
          if (columns[ikey].cell.type === 'fraction') {
            newFilter = columns[ikey].filter.num;
          }
          if (columns[ikey].cell.type === 'number') {
            newFilter = columns[ikey].filter;
          }
        }
        if (newFilter) {
          listFilters.push(JSON.parse(JSON.stringify(newFilter)));
        }
      }
    });

    // Craft the final filter
    const filters = { must: [] };
    let curentFilter = filters.must;
    listFilters.forEach((ifilter) => {
      curentFilter.push(ifilter);
      if (ifilter.nested !== undefined) {
        curentFilter = ifilter.nested.must;
      }
    });

    if (filters.must.length > 0) {
      // TODO on click , add the filter on the list .
      // <input type="button" onClick={() => console.log(JSON.stringify(filters, null, '  '))}/>
      return <div>{this.renderValue(dataRow[key], columns[key].cell)}</div>;
    }
    return <div>{this.renderValue(dataRow[key], columns[key].cell)}</div>;
  }

  renderTr(columns, dataRow, trKey) {
    return (
      <tr key={trKey}>
        {Object.keys(columns)
          .filter((key) => columns[key].cell !== undefined)
          .map((key) => (
            <td key={`${trKey}_${key}`}>
              <div>{this.renderAggregationCell(dataRow, key, columns)}</div>
            </td>
          ))}
      </tr>
    );
  }

  renderAggregationAllRow(columns, agg) {
    if (!agg) {
      return null;
    }
    const allDataRow = agg.row_values[0];
    return this.renderTr(columns, allDataRow, 'all');
  }

  renderAggregation(columns, aggDetail, aggAll) {
    const detailData = aggDetail.row_values;
    detailData.sort((a, b) => b.doc_count - a.doc_count);
    return (
      <tbody>
        {this.renderAggregationAllRow(columns, aggAll)}
        {detailData.map((row, rowidx) =>
          this.renderTr(columns, row, `detail-${rowidx}`),
        )}
      </tbody>
    );
  }

  renderHeaderCell(column) {
    // if (column.filter)
    //   return (
    //     <span>{column.label}<pre style={{textAlign:'left'}}> {JSON.stringify(column.filter, null, '  ')}</pre></span>
    //   )
    return <span>{column.label}</span>;
  }

  renderHeader(columns) {
    return (
      <thead>
        <tr className="ReportingTable--header">
          {Object.keys(columns)
            .filter((key) => columns[key].cell !== undefined)
            .map((key) => (
              <th key={`TdHead${key}`}>
                {this.renderHeaderCell(columns[key])}
              </th>
            ))}
        </tr>
      </thead>
    );
  }

  render() {
    const { columns, reportDataDetail, reportDataAll } = this.props;

    return (
      <div className="ReportingTable">
        <table>
          {this.renderHeader(columns)}
          {this.renderAggregation(columns, reportDataDetail, reportDataAll)}
        </table>
      </div>
    );
  }
}

export default connect()(TableResult);
