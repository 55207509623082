import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ListAutocomplete } from 'components/autocomplete';
import SelectedFilters from 'components/ui/selected-filters';
import { separateActions } from 'utils/redux';

import {
  addGDSNPartyListFilters,
  clearGDSNPartyListFilters,
  removeGDSNPartyListFilters,
  updateGDSNPartyListFilters,
} from '../actions';
import { selectFilters } from '../selectors';

const mapStateToProps = (state) => ({
  filters: selectFilters(state),
});

const mapDispatchToProps = {
  addGDSNPartyListFilters,
  removeGDSNPartyListFilters,
  updateGDSNPartyListFilters,
  clearGDSNPartyListFilters,
};

class GDSNPartyListFilters extends PureComponent {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      addGDSNPartyListFilters: PropTypes.func.isRequired,
      removeGDSNPartyListFilters: PropTypes.func.isRequired,
      updateGDSNPartyListFilters: PropTypes.func.isRequired,
      clearGDSNPartyListFilters: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {}

  onAddGLN = (search) => {
    this.props.actions.addGDSNPartyListFilters('filter_gln', {
      label: search,
      value: search,
    });
  };

  onAddDPGLN = (search) => {
    this.props.actions.addGDSNPartyListFilters('filter_datapool_gln', {
      label: search,
      value: search,
    });
  };

  render() {
    const { filters } = this.props;
    return (
      <div>
        <SelectedFilters
          filters={filters}
          removeFilter={this.props.actions.removeGDSNPartyListFilters}
          removeAllFilters={this.props.actions.clearGDSNPartyListFilters}
        />
        <h4>GLN</h4>
        <div className="card">
          <ListAutocomplete
            id="list-autocomplete-gln-source"
            onAdd={this.onAddGLN}
            values={[]}
            value={filters.filter_gln}
            addMissingOptionLabel="Search..."
          />
        </div>
        <h4>Datapool GLN</h4>
        <div className="card">
          <ListAutocomplete
            id="list-autocomplete-gln-source"
            onAdd={this.onAddDPGLN}
            values={[]}
            value={filters.filter_datapool_gln}
            addMissingOptionLabel="Search..."
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(GDSNPartyListFilters);
