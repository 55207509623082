import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';

import { CodeEditor } from 'components/code-editor';

import { closeViewJsonModal } from '../actions';
import { selectIsViewJsonModalOpen, selectViewJsonData } from '../selectors';

import './viewJson.scss';

export default function ViewJsonModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsViewJsonModalOpen);
  const jsonData = useSelector(selectViewJsonData);

  const jsonAsString = useMemo(
    () => (jsonData ? JSON.stringify(jsonData, null, '\t') : ''),
    [jsonData],
  );

  const onClose = () => {
    dispatch(closeViewJsonModal());
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal title="View JSON" modalStyle="default" onClose={onClose} hideFooter>
      <div className="ViewJsonModal_Body">
        <CodeEditor
          name="export-configuration"
          className="ReplayDetailsModal_Parameters"
          value={jsonAsString}
          mode="json"
          theme="monokai"
          width="100%"
          height="100%"
          wrapEnabled
          editorProps={{ $blockScrolling: Infinity }}
          readOnly
        />
      </div>
    </Modal>
  );
}
