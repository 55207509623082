import { notificationError } from 'actions/notifications';
import { immutableAuthApi as authApi } from 'resources/authApi';
import { immutableSearchApi as searchApi } from 'resources/searchApi';

import * as events from './events';

export const listOrganizations = (organizationIds) => (dispatch) =>
  authApi.OrganizationListV3({ ids: organizationIds }).then(
    (response) => Promise.resolve(response.data.data),
    () =>
      dispatch(notificationError('Error fetching the list of orgnizations')),
  );

export const listVersions =
  (organizationIds, gtins, limit, offset) => (dispatch) =>
    searchApi
      .post('/search/v1/productversion', {
        filter_gtins_in: gtins || '',
        filter_source_include: [
          'id',
          'assets',
          'primaryInternalReference',
          'gtin',
          'tags',
        ],
        filter_owners_in: organizationIds || [],
        filter_has_packshot_new: true,
        filter_tags_in: ['exportable'],
        limit,
        offset,
      })
      .then(
        (response) =>
          dispatch({
            type: events.RETAILER_PICTURE_COMPARATOR_RECEIVE_VERSIONS,
            versions: response.data.data,
            totalResults: response.data.totalResults,
          }),
        () =>
          dispatch(notificationError('Error fetching the list of products')),
      );

export const setLimit = (limit) => (dispatch) =>
  dispatch({
    type: events.RETAILER_PICTURE_COMPARATOR_SET_LIMIT,
    limit,
  });

export const nextPage = () => (dispatch) =>
  dispatch({
    type: events.RETAILER_PICTURE_COMPARATOR_NEXT_PAGE,
  });

export const previousPage = () => (dispatch) =>
  dispatch({
    type: events.RETAILER_PICTURE_COMPARATOR_PREVIOUS_PAGE,
  });
