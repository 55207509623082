import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { useCallback, useEffect, useState } from 'react';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import { restrictionTypes } from 'modules/organization-uses-fields/constants';

import {
  MANDATORY_VIEW_AS_FIELDS,
  PRODUCT_PICTURE,
} from '../../../../../constants/fields';

import { useFieldMetadata } from './hooks/useFieldMetadata';
import { OverridableCheckbox } from './overridableCheckbox';
import { RequiredCheckbox } from './requiredCheckbox';
import { makeRule } from './rule';
import ValidationRuleForm, {
  Kinds,
  PackagingTypes,
} from './validation-rule/validation-rule-form';

interface Props {
  field: ImmutableMap<string, any>;
  readOnly?: boolean;
  organization: ImmutableMap<string, any>;
  fieldEntity: string;
  ruleEntity?: string;
  isRequirable: boolean;
  createRule: (rule: object) => void;
  deleteRules: (fieldName: string) => void;
  deleteField: (fieldName: string) => void;
  rule: ImmutableMap<string, any>;
  onUpdateRuleValue: (path: string[], value: any) => void;
}

export const Field = (props: Props) => {
  const {
    field,
    readOnly = false,
    organization,
    fieldEntity,
    ruleEntity,
    isRequirable,
    createRule,
    deleteRules,
    deleteField,
    rule,
    onUpdateRuleValue,
  } = props;
  const fieldId = field.get('id');
  const fieldName = field.get('name');

  const deleteDisabled =
    MANDATORY_VIEW_AS_FIELDS.includes(fieldName) &&
    fieldEntity === PRODUCT_PICTURE;

  const [ruleCreationRequested, setRuleCreationRequested] = useState(false);

  const hasRule = typeof ruleEntity !== 'undefined' && rule;

  const entityType = rule?.get('entityType');

  const fieldMetadataLoader = useFieldMetadata(fieldId, entityType, {
    skip: !(hasRule || ruleCreationRequested),
  });

  const handleCreateRule = useCallback(() => {
    switch (fieldMetadataLoader.status) {
      case 'loaded':
        break;
      case 'skipped':
        // fieldmetadata not loaded yet (because no rule is associated)
        // except we need to create a rule now and fieldmetadata is needed
        setRuleCreationRequested(true);
        return;
      default:
        return;
    }

    const rule = makeRule(
      field,
      fieldMetadataLoader.fieldMetadata,
      fieldEntity,
      {
        status: 1,
        restrictionType: restrictionTypes.warning,
      },
    );
    createRule(rule);
  }, [field, fieldEntity, createRule, fieldMetadataLoader]);

  useEffect(() => {
    if (ruleCreationRequested && fieldMetadataLoader.status === 'loaded') {
      setRuleCreationRequested(false);
      handleCreateRule(); // call was deferred until field metadata re loaded
    }
  }, [handleCreateRule, ruleCreationRequested, fieldMetadataLoader]);

  let ruleForm;
  if (hasRule) {
    let forbiddenKinds: Kinds;
    let forbiddenPackagingTypes: PackagingTypes;
    if (fieldMetadataLoader.status === 'loaded') {
      forbiddenKinds =
        fieldMetadataLoader.forbiddenKinds || ImmutableSet<string>();
      forbiddenPackagingTypes =
        fieldMetadataLoader.forbiddenPackagingTypes || ImmutableSet<number>();
    } else {
      forbiddenKinds =
        fieldMetadataLoader.status === 'loading' ? 'loading' : 'error';
      forbiddenPackagingTypes =
        fieldMetadataLoader.status === 'loading' ? 'loading' : 'error';
    }
    ruleForm = (
      <ValidationRuleForm
        key={rule.get('id', `created-rule-${fieldName}`)}
        rule={rule}
        onUpdateValue={onUpdateRuleValue}
        disabled={readOnly}
        forbiddenKinds={forbiddenKinds}
        forbiddenPackagingTypes={forbiddenPackagingTypes}
      />
    );
  }

  return (
    <div key={fieldName} id={`field-${fieldName}`} role="listitem">
      <div
        className="OrganizationUsesFieldsView__field"
        data-field-name={fieldName}
      >
        <code
          title={fieldName}
          className="OrganizationUsesFieldsView__fieldName"
        >
          {fieldName}
        </code>
        <OverridableCheckbox
          field={field}
          readOnly={readOnly}
          organization={organization}
          fieldEntity={fieldEntity}
        />
        <span className="OrganizationUsesFieldsView__fieldRules">
          <RequiredCheckbox
            field={field}
            fieldEntity={fieldEntity}
            ruleEntity={ruleEntity}
            isRequirable={isRequirable}
            hasRules={typeof rule !== 'undefined'}
            createRule={handleCreateRule}
            deleteRules={deleteRules}
          />
          {ruleForm}
        </span>
        {!readOnly &&
          !field.get('tags').includes('rfp_mandatory') &&
          !deleteDisabled && (
            <span
              id={`field-${fieldName}-delete-button`}
              data-testid={`field-${fieldName}-delete-button`}
              className="OrganizationUsesFieldsView__delete mdi mdi-delete"
              onClick={() => deleteField(fieldName)}
            ></span>
          )}
        {deleteDisabled && (
          <HelpTooltip
            id={`helptooltip-no-delete-${field.get('id')}`}
            message="This field can't be deleted because of the media fields migration. Please contact #tech-katalog-chat for any questions"
          />
        )}
        {field.get('tags').includes('rfp_mandatory') && (
          <span>
            <HelpTooltip
              id={`helptooltip-${field.get('id')}`}
              message="field is mandatory"
            />
          </span>
        )}
      </div>
    </div>
  );
};
