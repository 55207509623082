import qs from 'querystringify';

import { createReducer } from 'utils/reducers';
import {
  changeLimit,
  initPagination,
  nextPage,
  previousPage,
  receiveReducer,
  resetPagination,
} from 'utils/reducers/list';

import * as events from '../constants/events';

const LIMIT_STORAGE_KEY = 'gdsnCIListLimit';
const SORTING_STORAGE_KEY = 'gdsnCIListSorting';

function getQSorValue(key, cast, defaultValue) {
  const value = qs.parse(location.search)[key];
  return value === undefined ? defaultValue : cast(value);
}

const initialState = {
  list: [],
  cihwModal: {
    cisync: null,
    isOpen: false,
    isPushing: false,
  },
  filters: {
    direction: getQSorValue('direction', String, 'read'),
    filter_gln_in: getQSorValue('filter_gln_in', String, undefined),
    filter_gtin_in: getQSorValue('filter_gtin_in', String, undefined),
    filter_id_in: getQSorValue('filter_id_in', String, undefined),
  },
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
};

export default createReducer(initialState, {
  [events.RECEIVE_GDSN_CI_LIST]: (state, action) =>
    receiveReducer(state, action.payload),
  [events.CHANGE_LIMIT_GDSN_CI_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.NEXT_PAGE_GDSN_CI_LIST]: nextPage,
  [events.PREVIOUS_PAGE_GDSN_CI_LIST]: previousPage,
  [events.GDSN_CI_OPEN_CIHW_MODAL]: (state, action) => {
    const newState = { ...state };
    newState.cihwModal = {
      isOpen: true,
      isPushing: false,
      cisync: action.cisync,
    };
    return newState;
  },
  [events.GET_GDSN_CI_LIST]: (state) => {
    const newState = { ...state };
    newState.list = [];
    newState.isLoading = true;
    return newState;
  },
  [events.UPDATE_GDSN_CI_FILTER]: (state, action) => {
    const newState = { ...state };
    newState.list = [];
    newState.isLoading = true;
    if (action.key !== 'filter_id_in') {
      newState.filters['filter_id_in'] = undefined;
    }
    newState.filters[action.key] = action.value;
    return resetPagination(newState);
  },
  [events.GDSN_CI_PUSH_HIERARCHY_WITHDRAWAL]: (state) => {
    const newState = { ...state };
    newState.cihwModal = {
      ...newState.cihwModal,
      isPushing: true,
    };
    return newState;
  },
  [events.GDSN_CI_CLOSE_CIHW_MODAL]: (state) => {
    const newState = { ...state };
    newState.cihwModal = {
      isOpen: false,
      isPushing: false,
      cisync: null,
    };
    return newState;
  },
});
