import { Set } from 'immutable';
import { noop } from 'lodash';
import React from 'react';

import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
  RFP_ANSWER,
  SHARING_UNIT,
  TARIFF,
} from 'constants/fields';
import { fieldsApiImmutable as fieldsApi } from 'resources/fieldsApi';

import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from '../legacy-autocomplete';

import './field-autocomplete.scss';

const displayedTags = Set([
  'in_progress',
  'validated_global',
  'validated_specific',
  'demo',
  'obsolete',
]);

export function getLabel(field) {
  const name = field.get('name');
  let tags = field.get('tags');
  if (tags) {
    tags = tags.filter((t) => displayedTags.includes(t));
  }
  if (!tags || !tags.size) {
    return name;
  }
  return (
    <span className="FieldAutocomplete__fieldLabel">
      <span>{name}</span>
      {tags.map((t) => (
        <code key={t}>{t}</code>
      ))}
    </span>
  );
}

type Props = {
  entityType: string;
  filterFunc?: (i?: any) => boolean;
  organizationId?: number | null;
  attributes?: any[];
  withSpecific?: boolean;
  tag?: string | null;
  limit?: number | null;
} & LegacyAutocompleteProps;

export const FieldAutocomplete = React.memo(function FieldAutocomplete({
  placeholder = 'Add a field...',
  onUnselect = noop,
  filterFunc = () => true,
  organizationId = null,
  attributes = [],
  withSpecific = false,
  tag = null,
  entityType,
  excludeList,
  limit = null,
  ...props
}: Props) {
  const onSearch = (search) => {
    let computedEntityType;
    switch (entityType) {
      case LOGISTICAL_UNIT:
      case DISPLAY_UNIT:
        computedEntityType = CONSUMER_UNIT;
        break;
      case TARIFF:
        computedEntityType = SHARING_UNIT;
        break;
      case RFP_ANSWER:
        computedEntityType = SHARING_UNIT;
        break;
      default:
        computedEntityType = entityType;
    }
    const params: any = {
      only_for_logistical_units: entityType === LOGISTICAL_UNIT,
      only_for_display_units: entityType === DISPLAY_UNIT,
      withSpecific,
      tag,
    };
    if (organizationId) {
      params.with_specific_of = organizationId;
    }
    if (limit) {
      params.limit = limit;
    }
    return fieldsApi.listMainFields(
      search,
      [...(attributes || []), 'tags'],
      computedEntityType,
      params,
    );
  };

  const transformData = (data) => {
    let mappedData = data.map((field) => ({
      label: getLabel(field),
      value: field.set('entityType', entityType),
      key: field.get('id'),
    }));
    if (excludeList && excludeList.length > 0) {
      const excludedFieldIds = excludeList.map(
        (excludeField) => excludeField.id,
      );
      mappedData = mappedData.filter(
        (mappedField) => !excludedFieldIds.includes(mappedField.key),
      );
    }
    if (filterFunc) {
      mappedData = mappedData.filter(filterFunc);
    }
    return mappedData.toArray();
  };

  return (
    <LegacyAutocomplete
      placeholder={placeholder}
      onUnselect={onUnselect}
      excludeList={excludeList}
      {...props}
      onSearch={onSearch}
      transformData={transformData}
    />
  );
});

export default FieldAutocomplete;
