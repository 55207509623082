import PropTypes from 'prop-types';
import React from 'react';

import Collapsible from 'components/collapsible';

import './styles.scss';

const CollapsibleReferentialSection = ({
  title,
  collapsed,
  children,
  toggle,
}) => (
  <Collapsible
    className="CollapsibleReferentialSection"
    title={<h3>{title}</h3>}
    toggle={toggle}
    collapsed={collapsed}
  >
    {children}
  </Collapsible>
);

CollapsibleReferentialSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default CollapsibleReferentialSection;
