import { combineReducers } from 'redux';

import {
  ORGANIZATION_LIST_GROUP_STORE_KEY,
  ORGANIZATION_LIST_NETWORK_STORE_KEY,
  ORGANIZATION_LIST_ORG_STORE_KEY,
  ORGANIZATION_LIST_TM_STORE_KEY,
} from '../constants/reducers';

import groups from './groups';
import networks from './networks';
import organizations from './organizations';
import targetMarkets from './targetMarkets';

export default combineReducers({
  [ORGANIZATION_LIST_ORG_STORE_KEY]: organizations,
  [ORGANIZATION_LIST_NETWORK_STORE_KEY]: networks,
  [ORGANIZATION_LIST_GROUP_STORE_KEY]: groups,
  [ORGANIZATION_LIST_TM_STORE_KEY]: targetMarkets,
});
