import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Textarea } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import {
  Condition,
  ConditionWithStringData,
} from 'modules/io/gdsn/exports/types';
import { getStringWithoutLineBreaks } from 'modules/io/gdsn/exports/utils';

interface Props {
  index: number;
  condition: ConditionWithStringData;
  updateCondition: (index: number, condition: Condition) => void;
  name: string;
}
export function ExportMappingsItemLambdaCondition({
  index,
  condition,
  updateCondition,
  name,
}: Props) {
  const [lambda, setLambda] = useState<string | undefined>(condition.data);

  const updateLambda = (event: { target: { value: string } }) => {
    const data = getStringWithoutLineBreaks(event.target.value) || undefined;
    setLambda(data);
    updateCondition(index, { ...condition, data });
  };

  return (
    <InputWithLabel
      inputId={`gdsn-export-mappings-lambda-${name}`}
      label={
        <span>
          Lambda self, hierarchy, su, constants, module:{' '}
          <HelpTooltip
            id={`gdsn-export-mappings-lambda-${name}-help`}
            place="top"
            message="Must return true for the condition to be fulfilled"
          />
        </span>
      }
    >
      <div data-testid={`gdsn-export-mappings-lambda-${name}`}>
        <Textarea
          id={`gdsn-export-mappings-lambda-${name}`}
          value={lambda || ''}
          autoresize
          onChange={updateLambda}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          invalid={!lambda}
        />
      </div>
    </InputWithLabel>
  );
}
