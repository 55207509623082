import { LegacyAutocomplete } from 'components/autocomplete';
import {
  FilterValue,
  ValidationSelectedFilters,
} from 'modules/validation-dashboard/types';
import { getRuleSetLabelForFilter } from 'modules/validation-dashboard/utils';
import { validationApiJS } from 'resources/validationApi';

interface Props {
  title?: string;
  value?: FilterValue[];
  onSelect: (value: any) => any;
  onRemove: (value: any) => any;
  rulesFilter?: ValidationSelectedFilters;
  multiple?: boolean;
  withOrganizationIds?: boolean;
  withRuleIds?: boolean;
  disabled?: boolean;
}

export const RulesetAutocomplete = ({
  title,
  value,
  onSelect,
  onRemove,
  rulesFilter,
  multiple = true,
  disabled = false,
}: Props) => {
  const onSearch = (search) =>
    validationApiJS.listRuleSets(
      search,
      rulesFilter
        ? {
            ...rulesFilter,
            organizationIdIn: rulesFilter.usedByRetailerIdIn.map(
              (filter) => filter.key,
            ),
          }
        : {},
    );

  const transformData = (data) =>
    data.map((value) => ({
      key: value.id,
      value,
      label: getRuleSetLabelForFilter(value),
    }));

  return (
    <div className="RulesetAutocomplete ReactUiFilter__filter">
      {title && <h3 className="ReactUiFilter__filterTitle">{title}</h3>}
      <LegacyAutocomplete
        id="validation-filter-ruleset-id"
        value={value}
        onSelect={onSelect}
        onUnselect={(index) => (value ? onRemove(value[index]) : {})}
        placeholder="Select a rule set..."
        onSearch={onSearch}
        transformData={transformData}
        searchOnClick
        multiple={multiple}
        disabled={disabled}
      />
    </div>
  );
};
