import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import { Checkbox } from '@alkem/react-ui-inputs';

import * as routes from 'constants/routes';
import PublicLayout from 'layouts/PublicLayout';
import { getStreamUrl } from 'utils/stream';

import { login } from '../actions';
import {
  selectIsAuthPending,
  selectIsAuthenticated,
  selectLoginError,
} from '../reducer';

import './LoginView.scss';

export function LoginView() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAuthPending = useSelector(selectIsAuthPending);
  const loginError = useSelector(selectLoginError);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [searchParams] = useSearchParams();

  if (isAuthenticated) {
    return <Navigate to={searchParams.get('next') || routes.home} />;
  }
  return (
    <PublicLayout>
      <div id="Login" className="card card-block">
        <form
          onSubmit={(e) => {
            if (e) {
              e.preventDefault();
            }
            dispatch(login({ email, password, rememberMe }));
          }}
          method="post"
        >
          <h1 className="Login__title text-xs-center">Sign In</h1>

          {loginError ? (
            <div id="login-error" className="Login__error alert alert-danger">
              {loginError}
            </div>
          ) : null}

          <div className="row">
            <div className="col-xs-12">
              <input
                id="email"
                name="username"
                type="text"
                className="form-control Login__input Login__input-email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <input
                id="password"
                name="password"
                type="password"
                className="form-control Login__input Login__input-password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <p className="Login__submit text-xs-center">
            <input
              type="submit"
              id="sign-in"
              className="Button btn btn-primary"
              value="Sign In"
              disabled={isAuthPending}
            />
          </p>

          <p className="text-xs-center">
            <Checkbox
              id="rememberMe"
              label="Remember me"
              checked={rememberMe}
              onChange={(event) => {
                setRememberMe(
                  /** @type {HTMLInputElement} */ (event.target).checked,
                );
              }}
            />
          </p>
          <p className="text-xs-center">
            <a href={getStreamUrl('forgot-password')}>Reset password</a>
          </p>
        </form>
      </div>
    </PublicLayout>
  );
}

export default LoginView;
