import { initPagination } from 'utils/reducers/list';

import { VALIDATION_RULESET_TYPE__REGULATORY } from './constants';
import {
  PaginationState,
  Rule,
  RuleSet,
  RuleSetTag,
  ValidationSelectedFilters,
} from './types';

export type RuleSetTagsFetchState =
  | 'unfetched'
  | 'loading'
  | 'error'
  | RuleSetTag[];

export enum ProcessingBulkActionState {
  NOT_STARTED = 'not started',
  PROCESSING = 'processing',
  DONE = 'done',
  ERROR = 'error',
}

export interface ValidationFiltersState {
  // used for id query
  search: string;
  // used for filters
  selectedFilters: ValidationSelectedFilters;
}

export interface ValidationDashboardState {
  editingRule: Rule;
  dirtyRule: Rule;
  rules: Rule[];
  selectedRules?: { [id: number]: boolean };
  ruleSetTags: RuleSetTagsFetchState;
  ruleSet: RuleSet;
  filters: ValidationFiltersState;
  cachedOrganizations: { [orgID: string]: string };
  pagination: PaginationState;
  isLoading: boolean;
  isLoadingRule: boolean;
  isLoadingDimensions: boolean;
  isSavingRules: {
    [ruleId: string]: boolean;
    [ruleId: number]: boolean;
  };
  processingBulkActionState: ProcessingBulkActionState;
}

export const emptyRule: Rule = {
  id: 0,
  name: '',

  // rule params
  selectors: {},
  conditionSelectors: {},
  constants: {},
  condition: '',
  validation: '',

  // for frontend
  constantList: [],
  selectorList: [],

  // rule description
  documentation: '',
  errorLabel: '',
  errorLabels: [],
  errorMessage: '',
  translatedErrorMessage: '',

  // rule state
  status: 0,
  organizations: [],

  // rule application
  ruleEntityType: 0,
  bypassable: false,
  shouldHideFieldIfBypassed: 0,
  entityType: 0,
  kindsAreManuallySelected: false,
  applicableForKinds: [],
  applicableForTypePackagings: [],
  packagingTypes: [],

  metadata: {
    fields_raw_applicability: {
      generic_kind_ids: [],
      generic_packaging_type_ids: [],
    },
  },
};

export const defaultFiltersValues = {
  ruleSetIdIn: [],
  entityTypeIn: [],
  ruleSetTypeIn: [],
  appliesOnPkIdIn: [],
  usedByRetailerIdIn: [],
  onlyActive: true,
};

export const emptyRuleset: RuleSet = {
  id: 0,
  label: '',
  type: VALIDATION_RULESET_TYPE__REGULATORY,
  displayable: true,
  payload: {},
  tags: [],
};

export const initialState: ValidationDashboardState = {
  editingRule: emptyRule,
  dirtyRule: emptyRule,
  rules: [],
  selectedRules: {},
  ruleSetTags: 'unfetched',
  ruleSet: emptyRuleset,
  filters: {
    search: '',
    selectedFilters: { ...defaultFiltersValues },
  },
  pagination: initPagination(),
  cachedOrganizations: {},
  isLoading: true,
  isLoadingRule: false,
  isLoadingDimensions: false,
  isSavingRules: {},
  processingBulkActionState: ProcessingBulkActionState.NOT_STARTED,
};
