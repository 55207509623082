import Viz from 'viz.js';

import { D3Graph } from './D3Graph';

interface Props {
  dot: string;
  selectNode: Function;
  showPayload: Function;
}

export function TransactionGraph({ dot, selectNode, showPayload }: Props) {
  return (
    <D3Graph
      id="transaction-trace"
      svg={Viz(dot)}
      selectNode={selectNode}
      showPayload={showPayload}
    />
  );
}
