import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { separateActions } from 'utils/redux';

import { removeRecipient } from '../../actions/recipients';
import { OWWO_TYPE_MAPPING } from '../../constants/recipients';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  removeRecipient,
};

export class OrganizationPageRecipientsRelation extends PureComponent {
  static propTypes = {
    relation: ImmutablePropTypes.map,
    isReadOnly: PropTypes.bool,
    actions: PropTypes.shape({
      removeRecipient: PropTypes.func.isRequired,
    }).isRequired,
  };

  onDelete = () => {
    const { relation } = this.props;
    this.props.actions.removeRecipient(relation.get('organization_id'));
  };

  renderButtons() {
    return (
      <button
        type="button"
        className="OrganizationPageRecipients_relationRemove btn btn-link"
        data-testid="organizationPageRecipients-relationRemove"
        onClick={this.onDelete}
      >
        <i className="mdi mdi-delete" />
      </button>
    );
  }

  renderLabel() {
    const { relation } = this.props;
    const type = OWWO_TYPE_MAPPING[relation.get('type')];

    return (
      <span>
        {`${relation.get('organization_name')} `}
        {`(${relation.get('organization_id')}) - `}
        {type ? type.label : 'Unknown'}
      </span>
    );
  }

  render() {
    const { isReadOnly } = this.props;

    return (
      <li className="OrganizationPageRecipients_relation">
        {!isReadOnly && this.renderButtons()}
        {this.renderLabel()}
      </li>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(OrganizationPageRecipientsRelation);
