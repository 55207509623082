import cn from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { fetchProductTimeline } from './api';
import { EntryTypes } from './entry-types';
import { ProductTimelineEntryType } from './types';
import './view.scss';

type Props = {
  searchQuery: string;
  lastRefresh: any;
};

const ProductReviewView = ({ searchQuery, lastRefresh }: Props) => {
  const [productTimeline, setProductTimeline] = useState<any>(null);

  useEffect(() => {
    const fetchProds = async () => {
      const prods = await fetchProductTimeline(searchQuery);
      setProductTimeline(prods);
    };
    if (searchQuery) {
      fetchProds();
    }
  }, [searchQuery, lastRefresh]);

  return (
    <div>
      {productTimeline?.map((event: ProductTimelineEntryType, i: number) => (
        <div
          key={event.created_at}
          className={cn('ProductReviewView__TimelineEvent', {
            ProductReviewView__DateItem: event.entry_type === 17,
            ProductReviewView__Autoaccept: event.entry_type === 11,
          })}
        >
          <div className="ProductReviewView__TimelineDate">
            {event.entry_type === 17 ? (
              <div
                className={cn('ProductReviewView__Date', {
                  ProductReviewView__DateOffset: i,
                })}
              >
                {moment(event.created_at).format('LL')}
              </div>
            ) : (
              <div className="ProductReviewView__Timestamp">
                {event.created_at.split('T')[1]}
              </div>
            )}
          </div>
          <div className="ProductReviewView__TimelineIconWrapper">
            {!!i && (
              <div
                className={cn(
                  event.entry_type === 11 ||
                    event.entry_type === 17 ||
                    (i + 1 < productTimeline.length &&
                      productTimeline[i + 1].entry_type === 17)
                    ? 'ProductReviewView__TimelineSeparatorLong'
                    : 'ProductReviewView__TimelineSeparator',
                )}
              />
            )}
            <div
              className="ProductReviewView__TimelineIcon"
              style={{
                backgroundColor: EntryTypes[event.entry_type].color,
              }}
            >
              {EntryTypes[event.entry_type].component()}
            </div>
            {i !== productTimeline.length - 1 && (
              <div
                className={cn(
                  event.entry_type === 11 ||
                    event.entry_type === 17 ||
                    (i + 1 < productTimeline.length &&
                      productTimeline[i + 1].entry_type === 17)
                    ? 'ProductReviewView__TimelineSeparatorLong'
                    : 'ProductReviewView__TimelineSeparator',
                )}
              />
            )}
          </div>
          <div className="ProductReviewView__TimelineEventInfo">
            {event.entry_type !== 17 && (
              <>
                <pre className="ProductReviewView__Title">
                  {EntryTypes[event.entry_type].name}
                </pre>
                <div className="ProductReviewView__Text">
                  <div className="ProductReviewView__TextIcon">
                    {EntryTypes[event.entry_type].icon}
                  </div>
                  <div className="ProductReviewView__TextContent">
                    {event.txt}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export { ProductReviewView };
