import React from 'react';

import { AutocompleteProps, LegacyAutocomplete } from '../legacy-autocomplete';

export const NameAutocomplete = React.memo(function NameAutocomplete({
  ...props
}: AutocompleteProps) {
  const transformData = (data) =>
    data.map((result) => ({
      label: result.name,
      value: result.name,
      key: result.name,
    }));

  return <LegacyAutocomplete transformData={transformData} {...props} />;
});

export default NameAutocomplete;
