import { Text } from '@alkem/react-ui-inputs';

import { JsonText } from 'components/ui/input/json';

import { OrganizationSSOConfiguration } from '../../types';

export const OrganizationSSOForm = ({
  configuration,
  onChange,
}: {
  configuration: Partial<OrganizationSSOConfiguration>;
  onChange: (configuration: Partial<OrganizationSSOConfiguration>) => void;
}) => {
  const updateField =
    (field: keyof OrganizationSSOConfiguration) => (value) => {
      onChange({
        ...configuration,
        [field]: value,
      });
    };

  return (
    <div>
      <div>
        <div>sp_data</div>
        <div>
          <JsonText
            id="sp_data"
            defaultValue={configuration.sp_data}
            onChange={updateField('sp_data')}
          />
        </div>
      </div>
      <div>
        <div>security_data</div>
        <div>
          <JsonText
            id="security_data"
            defaultValue={configuration.security_data}
            onChange={updateField('security_data')}
          />
        </div>
      </div>
      <div>
        <div>idp_data</div>
        <div>
          <JsonText
            id="idp_data"
            defaultValue={configuration.idp_data}
            onChange={updateField('idp_data')}
          />
        </div>
      </div>
      <div className="flex flex-align-items--center">
        <div>idp_metadata_url</div>
        <div className="flex-grow--1">
          <Text
            id="idp_metadata_url"
            value={configuration.idp_metadata_url}
            onChange={({ target: { value } }: any) =>
              updateField('idp_metadata_url')(value)
            }
          />
        </div>
      </div>
    </div>
  );
};
