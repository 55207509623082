import { call, put, select, takeLatest } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import GdsnApi from 'resources/gdsnApi';
import { saveAs } from 'utils';

import { closeCISModal, receiveGDSNCISList } from '../actions';
import * as events from '../constants/events';
import { selectFilters, selectModal, selectPagination } from '../selectors';

const {
  GET_GDSN_CIS_LIST,
  ADD_GDSN_CIS_LIST_FILTER,
  REMOVE_GDSN_CIS_LIST_FILTER,
  UPDATE_GDSN_CIS_LIST_FILTER,
  CLEAR_GDSN_CIS_LIST_FILTER,
  CHANGE_LIMIT_GDSN_CIS_LIST,
  NEXT_PAGE_GDSN_CIS_LIST,
  PREVIOUS_PAGE_GDSN_CIS_LIST,
  DOWNLOAD_GDSN_CIS_DOCUMENT,
  RECEIVE_GDSN_CIS_LIST_FILTERS,
  GDSN_CIS_MODAL_SAVE,
} = events;

export default function* gdsnCISMainSaga() {
  yield takeLatest(GDSN_CIS_MODAL_SAVE, saveGDSNCIS);
  yield takeLatest(GET_GDSN_CIS_LIST, getGDSNCISList);
  yield takeLatest(DOWNLOAD_GDSN_CIS_DOCUMENT, downloadGDSNDocument);
  yield takeLatest(
    [
      ADD_GDSN_CIS_LIST_FILTER,
      REMOVE_GDSN_CIS_LIST_FILTER,
      UPDATE_GDSN_CIS_LIST_FILTER,
      CLEAR_GDSN_CIS_LIST_FILTER,
      CHANGE_LIMIT_GDSN_CIS_LIST,
      NEXT_PAGE_GDSN_CIS_LIST,
      PREVIOUS_PAGE_GDSN_CIS_LIST,
      RECEIVE_GDSN_CIS_LIST_FILTERS,
    ],
    getGDSNCISList,
  );
}

function* saveGDSNCIS() {
  try {
    const modal = yield select(selectModal);
    const cis = modal.cis.toJS();
    yield call([GdsnApi, GdsnApi.CreateCIS], {
      data: cis,
    });
    yield put(
      notificationSuccess(
        'You cis registration was sent to the global registry',
      ),
    );
    yield put(closeCISModal());
    yield call(getGDSNCISList);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured while creating party'));
    }
  }
}
function* getGDSNCISList() {
  try {
    const pagination = yield select(selectPagination);
    const filters = yield select(selectFilters);
    const response = yield call(
      [GdsnApi, GdsnApi.GdsnCatalogueItemSubscriptionListReport],
      { with_documents: true },
      {
        gln_source: filters.source_gln.length
          ? filters.source_gln[0].value
          : '',
        gln_recipient: filters.recipient_gln.length
          ? filters.recipient_gln[0].value
          : '',
      },
      (pagination.currentPage - 1) * pagination.limit,
      pagination.limit,
    );
    // Sort CIS documents
    if (response.data.data !== undefined) {
      response.data.data.forEach((cis) => {
        cis.documents.sort((a, b) => a.createdAt > b.createdAt);
      });
    }
    yield put(
      receiveGDSNCISList(response.data.data, response.data.totalResults),
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getGDSNCISList'));
    }
  }
}

function* downloadGDSNDocument(payload) {
  try {
    const filename = `gdsn_document_${payload.documentID}.xml`;
    const response = yield call(
      [GdsnApi, GdsnApi.DownloadDocument],
      payload.documentID,
    );
    yield call(saveAs, response.data, filename);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: downloadGDSNDocument'));
    }
  }
}
