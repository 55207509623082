import { orderBy } from 'lodash';
import React from 'react';

import KpiApi from 'resources/kpiApi';
import { isFunction } from 'utils';

import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from '../legacy-autocomplete';

const defaultSelector = (value) => ({
  key: value.code,
  value,
  label: `${value.code}`,
});

type Props = {
  selector?: (value: any) => any;
  permissionType: any;
} & LegacyAutocompleteProps;

export const DashboardAutocomplete = React.memo(function DashboardAutocomplete({
  selector = defaultSelector,
  permissionType,
  ...props
}: Props) {
  const onSearch = () => KpiApi.GetDashboardList();

  const transformData = (data) => {
    const items = isFunction(selector) ? data.map(selector) : data;
    const newitems = orderBy(items, ['key']);
    // filter on permissionType
    return newitems.filter(
      (dashboard) =>
        !dashboard.value.data.reporting.permission_type ||
        dashboard.value.data.reporting.permission_type.includes(permissionType),
    );
  };

  return (
    <LegacyAutocomplete
      onSearch={onSearch}
      transformData={transformData}
      {...props}
    />
  );
});

export default DashboardAutocomplete;
