import { ModuleWrapper } from 'redux/register';

import ReportingDashboard from './components';
import { REPORTING_CONFIG_STORE_KEY } from './constants';
import reducer from './reducers';

export default function ReportingHandlingView() {
  return (
    <ModuleWrapper reducerKey={REPORTING_CONFIG_STORE_KEY} reducer={reducer}>
      <ReportingDashboard />
    </ModuleWrapper>
  );
}
