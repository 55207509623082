import { call, put, select, takeLatest } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import GdsnApi from 'resources/gdsnApi';
import { saveAs } from 'utils';

import { closePartyModal, receiveGDSNPartyList } from '../actions';
import * as events from '../constants/events';
import { selectFilters, selectModal, selectPagination } from '../selectors';

const {
  GET_GDSN_PARTY_LIST,
  ADD_GDSN_PARTY_LIST_FILTER,
  REMOVE_GDSN_PARTY_LIST_FILTER,
  UPDATE_GDSN_PARTY_LIST_FILTER,
  CLEAR_GDSN_PARTY_LIST_FILTER,
  CHANGE_LIMIT_GDSN_PARTY_LIST,
  NEXT_PAGE_GDSN_PARTY_LIST,
  PREVIOUS_PAGE_GDSN_PARTY_LIST,
  DOWNLOAD_GDSN_PARTY_DOCUMENT,
  RECEIVE_GDSN_PARTY_LIST_FILTERS,
  GDSN_PARTY_MODAL_SAVE,
  GDSN_PARTY_SEND_DELETE,
} = events;

export default function* gdsnPartyMainSaga() {
  yield takeLatest(GDSN_PARTY_MODAL_SAVE, saveGDSNParty);
  yield takeLatest(GET_GDSN_PARTY_LIST, getGDSNPartyList);
  yield takeLatest(DOWNLOAD_GDSN_PARTY_DOCUMENT, downloadGDSNDocument);
  yield takeLatest(GDSN_PARTY_SEND_DELETE, sendDeleteParty);
  yield takeLatest(
    [
      ADD_GDSN_PARTY_LIST_FILTER,
      REMOVE_GDSN_PARTY_LIST_FILTER,
      UPDATE_GDSN_PARTY_LIST_FILTER,
      CLEAR_GDSN_PARTY_LIST_FILTER,
      CHANGE_LIMIT_GDSN_PARTY_LIST,
      NEXT_PAGE_GDSN_PARTY_LIST,
      PREVIOUS_PAGE_GDSN_PARTY_LIST,
      RECEIVE_GDSN_PARTY_LIST_FILTERS,
    ],
    getGDSNPartyList,
  );
}

function* saveGDSNParty() {
  try {
    const modal = yield select(selectModal);
    const party = modal.party.toJS();
    yield call([GdsnApi, GdsnApi.CreateParty], {
      data: party,
    });
    yield put(
      notificationSuccess(
        'You party registration was sent to the global registry',
      ),
    );
    yield put(closePartyModal());
    yield call(getGDSNPartyList);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured while creating party'));
    }
  }
}

function* getGDSNPartyList() {
  try {
    const pagination = yield select(selectPagination);
    const filters = yield select(selectFilters);
    const response = yield call(
      [GdsnApi, GdsnApi.ListParty],
      {
        filter_gln: filters.filter_gln.length
          ? filters.filter_gln[0].value
          : '',
        filter_datapool_gln: filters.filter_datapool_gln.length
          ? filters.filter_datapool_gln[0].value
          : '',
      },
      (pagination.currentPage - 1) * pagination.limit,
      pagination.limit,
    );
    yield put(
      receiveGDSNPartyList(response.data.data, response.data.totalResults),
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getGDSNPartyList'));
    }
  }
}

function* sendDeleteParty(payload) {
  try {
    const pagination = yield select(selectPagination);
    yield call([GdsnApi, GdsnApi.DeleteParty], {
      data: {
        party_id: payload.party.id,
      },
    });
    yield put(
      notificationSuccess(
        'You party unregister was sent to the global registry',
      ),
    );
    const responseList = yield call(
      [GdsnApi, GdsnApi.ListParty],
      {
        filter_gln: payload.party.gln,
      },
      (pagination.currentPage - 1) * pagination.limit,
      pagination.limit,
    );
    yield put(
      receiveGDSNPartyList(
        responseList.data.data,
        responseList.data.totalResults,
      ),
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: sendDeleteParty'));
    }
  }
}

function* downloadGDSNDocument(payload) {
  try {
    const response = yield call(
      [GdsnApi, GdsnApi.DownloadDocument],
      payload.documentID,
    );
    const filename = `gdsn_document_${payload.documentID}.xml`;
    saveAs(response.data, filename);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: downloadGDSNDocument'));
    }
  }
}
