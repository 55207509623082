import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  notificationDisplayed,
  notificationHidden,
  notificationRemove,
} from 'actions/notifications';
import { ERROR, SUCCESS } from 'constants/notifications';

import './index.scss';

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

class Notification extends Component {
  static propTypes = {
    notifications: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    type: PropTypes.string,
  };

  static defaultProps = {};

  state = {
    ready: true,
    current: { message: '' },
  };

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;

    // Displayed a new message, remove it
    if (this.state.ready === false && prevState.ready === true) {
      dispatch(notificationRemove(0));
    }

    // Component is ready again. Push a new notification or clear.
    if (this.state.ready === true && prevState.ready === false) {
      // Get second notification
      if (this.props.notifications.messages.length > 0) {
        const contextualMessage = this.getNextContextualMessage(
          this.props.notifications.messages,
        );
        if (contextualMessage) {
          this.updateNotification(contextualMessage);
        }
      } else {
        this.clearCurrentMessage();
        dispatch(notificationHidden());
      }
    }

    // New notification is pushed and component is ready to display a notification
    if (
      this.props.notifications.messages.length !==
        prevProps.notifications.messages.length &&
      prevState.ready &&
      this.props.notifications.messages.length > 0
    ) {
      const contextualMessage = this.getNextContextualMessage(
        this.props.notifications.messages,
      );
      if (contextualMessage) {
        this.updateNotification(contextualMessage);
        dispatch(notificationDisplayed());
      }
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this._timer);
  }

  getNextContextualMessage(messages) {
    const { type } = this.props;
    const notif = Object.values(messages).find((msg) => msg.context === type);

    if (!notif) {
      return;
    }

    let message = '';
    if (typeof notif.message === 'string') {
      message = notif.message;
    } else if (typeof notif.message.data?.message === 'string') {
      message = notif.message.data.message;
    } else if (notif.message.rules?.length > 0) {
      message = notif.message.rules
        .reduce((acc, rule) => {
          return `${acc} ${rule.label},`;
        }, notif.message.header || '')
        .slice(0, -1);
    } else if (typeof notif.message.data?.message.header === 'string') {
      message = notif.message.data?.message.header;
    }

    if (message !== 'None' && message !== '') {
      return {
        ...notif,
        message,
      };
    }
  }

  clearCurrentMessage() {
    this.setState({ current: { message: '' } });
  }

  updateNotification(current) {
    this.setState({ ready: false, current });
    this._timer = window.setTimeout(() => this.setState({ ready: true }), 4000);
  }

  render() {
    const classes = {
      Notification: true,
      'Notification--base': this.props.type === 'base',
      'Notification--modal': this.props.type === 'modal',
      'Notification--error': this.state.current.style === ERROR,
      'Notification--success': this.state.current.style === SUCCESS,
      'Notification--hide': this.state.ready,
    };
    return (
      <div className={classNames(classes)}>
        <div className="Notification__message">
          {this.state.current.message}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Notification);
