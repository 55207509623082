import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY } from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REDUCER_KEY];
}

export function selectGLNClaims(state) {
  return selectState(state).get('list');
}

export function selectGLNData(state) {
  return selectState(state).get('glnData');
}

export function selectIsLoading(state) {
  return selectState(state).get('loading');
}

export function selectActionInProgress(state) {
  return selectState(state).get('actionInProgress');
}

export function selectActionnableClaimsOnly(state) {
  return selectState(state).get('actionnableClaimsOnly');
}

// Search

export function selectSearch(state) {
  return selectState(state).get('search');
}

export function selectSearchUsername(state) {
  return selectSearch(state).get('username');
}

export function selectSearchGLN(state) {
  return selectSearch(state).get('gln');
}

// Pagination

export function selectPagination(state) {
  return selectState(state).get('pagination');
}

export function selectLimit(state) {
  return selectPagination(state).get('limit');
}

export function selectCurrentPage(state) {
  return selectPagination(state).get('currentPage');
}

export function selectTotalResults(state) {
  return selectPagination(state).get('totalResults');
}

export function selectTotalPages(state) {
  return selectPagination(state).get('totalPages');
}
