import { List, Map } from 'immutable';
import { KindsIndex } from 'redux/state/kinds';

import { LazyTooltip } from '@alkem/react-ui-tooltip';
import Well from '@alkem/react-ui-well';

import { get } from 'utils/immutable';

import './applicable-kinds-summary.scss';

interface Props {
  applicableKinds: List<Map<string, number>>;
  kinds: KindsIndex;
  kindsAreManuallySelected: boolean;
}

export const ApplicableKindsSummary = ({
  applicableKinds,
  kinds,
  kindsAreManuallySelected,
}: Props) => {
  const isApplyingToAllKinds = !applicableKinds?.size;

  const header = (
    <>
      {isApplyingToAllKinds ? 'All' : applicableKinds.size} kind(s) selected{' '}
      {kindsAreManuallySelected ? (
        <>
          manually
          <LazyTooltip
            id="tooltip-manual"
            tooltipLabel="Rule applicable kinds are based on your selection"
            className="ApplicableKinds_tooltip"
          >
            <i className="mdi mdi-account" />
          </LazyTooltip>
        </>
      ) : (
        <>
          automatically
          <LazyTooltip
            id="tooltip-automatic"
            tooltipLabel="No kind selected, rule applicable kinds are based on fields applicable kinds"
            className="ApplicableKinds_tooltip"
          >
            <i className="mdi mdi-laptop" />
          </LazyTooltip>
        </>
      )}
    </>
  );
  return (
    <Well header={header} textButtonExpand={isApplyingToAllKinds ? '' : 'view'}>
      <pre className="ApplicableKinds__selectedList">
        {applicableKinds.map((kind) =>
          kind ? (
            <div key={kind.get('id')}>
              {kind.get('id')}{' '}
              {get(kinds[kind.get('id')], 'name') || 'DELETED KIND'}
            </div>
          ) : (
            <></>
          ),
        )}
      </pre>
    </Well>
  );
};
