import { Radio } from '@alkem/react-ui-inputs';

import {
  FieldTypesAutocomplete,
  ReferentialAutocomplete,
} from 'components/autocomplete';
import InputWithLabel from 'components/input-with-label';
import ReferentialInput from 'components/referential-input';

import { ImportMappingDetails } from '../../../types';

import './item-automatic-properties.scss';

interface Props {
  fieldName: string;
  details: ImportMappingDetails;
  isSpecific?: boolean;
  isChild?: boolean;
}
export function ImportMappingsItemAutomaticProperties({
  fieldName,
  details,
  isSpecific = false,
  isChild = false,
}: Props): JSX.Element {
  const type = details.cast;
  const referential = !details.isDeclinable && details.referential;
  const declinable_by = details.isDeclinable && details.referential;
  const functional_key = details.functional_key || false;
  return (
    <div
      data-testid="GDSNImportMappingsItem_automaticProperties"
      className="GDSNImportMappingsItemAutomaticProperties"
    >
      <div className="GDSNImportMappingsItemAutomaticProperties__sectionTitle">
        Automatic properties
      </div>
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-type`}
        label="Type"
      >
        <FieldTypesAutocomplete
          id={`gdsn-import-mappings-field-${fieldName}-type`}
          value={type ? [{ label: type }] : []}
          disabled
        />
      </InputWithLabel>
      {type === 'entity' && (
        <InputWithLabel
          inputId={`gdsn-import-mappings-field-${fieldName}-automatic-property-referential`}
          label="Referential"
        >
          <ReferentialInput
            id={`gdsn-import-mappings-field-${fieldName}-automatic-property-referential`}
            value={referential ? [{ label: referential }] : []}
            disabled
            fieldsUsingSameReferential={false}
            referentialWillNotBeCreatedWarning={false}
          />
        </InputWithLabel>
      )}
      {declinable_by && (
        <InputWithLabel
          inputId={`gdsn-import-mappings-field-${fieldName}-automatic-property-declinable_by`}
          label="Declinable by"
        >
          <ReferentialAutocomplete
            id={`gdsn-import-mappings-field-${fieldName}-automatic-property-declinable_by`}
            value={[{ label: declinable_by }]}
            disabled
          />
        </InputWithLabel>
      )}
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-automatic-property-functional-key`}
        label="Is functional key"
      >
        <Radio
          id={`gdsn-import-mappings-field-${fieldName}-automatic-property-functional-key`}
          value={functional_key}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          disabled
        />
      </InputWithLabel>
      {!isChild && (
        <InputWithLabel
          inputId={`gdsn-import-mappings-field-${fieldName}-automatic-property-specific`}
          label="Is specific"
        >
          <Radio
            id={`gdsn-import-mappings-field-${fieldName}-automatic-property-specific`}
            value={isSpecific}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            disabled
          />
        </InputWithLabel>
      )}
    </div>
  );
}
