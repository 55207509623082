import { ModuleWrapper } from 'redux/register';

import RevisionList from './components';
import reducer, { REDUCER_KEY } from './reducers';
import saga from './sagas';

export default function RevisionListContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer} saga={saga}>
      <RevisionList />
    </ModuleWrapper>
  );
}
