import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'querystringify';
import { PureComponent } from 'react';

import { HeaderLayout } from '@alkem/react-layout';
import { Checkbox } from '@alkem/react-ui-checkbox';
import EmptyState from '@alkem/react-ui-empty-state';
import { Text } from '@alkem/react-ui-inputs';

import * as ROUTES from 'constants/routes';
import { withLocation } from 'hocs/with-location';
import { withNavigate } from 'hocs/with-navigate';

import { loadRoutes } from './api';
import DiscoverRoute from './components/route';
import './discover-services.scss';

class DiscoverServives extends PureComponent {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    routes: null,
    filteredRoutes: null,
    filters: {
      search: '',
      protocols: [],
      authorizations: [],
      sla: false,
      rpc: false,
      runnable: false,
    },
  };

  async componentDidMount() {
    const routes = await loadRoutes();
    const {
      q = '',
      p,
      a,
      sla,
      runnable,
      rpc,
    } = qs.parse(this.props.location.search);
    const filters = {
      search: q,
      protocols: p ? p.split(',') : [],
      authorizations: (a ? a.split(',') : []).map((e) =>
        e === 'none' ? undefined : e,
      ),
      sla: sla === 'true',
      runnable: runnable === 'true',
      rpc: rpc === 'true',
    };
    this.setState({
      routes,
      filteredRoutes: this.filterRoutes(routes, filters),
      filters,
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.filters !== prevState.filters) {
      this.updateLocation(this.state.filters);
    }
  };

  onSearch = (event) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      filters: { ...prevState.filters, search: value },
      filteredRoutes: this.filterRoutes(prevState.routes, {
        ...prevState.filters,
        search: value,
      }),
    }));
  };

  onFilterProtocol = (protocol) => () => {
    const { protocols } = this.state.filters;
    const index = protocols.findIndex((p) => p === protocol);
    this.setState((prevState) => {
      const newFilters = { ...prevState.filters };
      if (index === -1) {
        newFilters.protocols = [...protocols, protocol];
      } else {
        newFilters.protocols = protocols.filter((p) => p !== protocol);
      }
      return {
        filters: newFilters,
        filteredRoutes: this.filterRoutes(prevState.routes, newFilters),
      };
    });
  };

  onFilterSla = (event) => {
    this.setState((prevState) => {
      const newFilters = { ...prevState.filters, sla: event };
      return {
        filters: newFilters,
        filteredRoutes: this.filterRoutes(prevState.routes, newFilters),
      };
    });
  };

  onFilterRPC = (event) => {
    this.setState((prevState) => {
      const newFilters = { ...prevState.filters, rpc: event };
      return {
        filters: newFilters,
        filteredRoutes: this.filterRoutes(prevState.routes, newFilters),
      };
    });
  };

  onFilterRunnable = (event) => {
    this.setState((prevState) => {
      const newFilters = { ...prevState.filters, runnable: event };
      return {
        filters: newFilters,
        filteredRoutes: this.filterRoutes(prevState.routes, newFilters),
      };
    });
  };

  onFilterAuthorization = (authorization) => () => {
    this.setState((prevState) => {
      const newFilters = { ...prevState.filters };
      const { authorizations } = newFilters;
      const index = authorizations.findIndex((p) => p === authorization);
      if (index === -1) {
        newFilters.authorizations = [...authorizations, authorization];
      } else {
        newFilters.authorizations = authorizations.filter(
          (p) => p !== authorization,
        );
      }
      return {
        filters: newFilters,
        filteredRoutes: this.filterRoutes(prevState.routes, newFilters),
      };
    });
  };

  getAllAddresses = (config) =>
    Object.values(config.protocols)
      .map((c) => (c.address ? c.address : null))
      .filter((a) => !!a);

  routeMatchesFilter = (filters, route, config) => {
    const { search, protocols, authorizations, sla, rpc, runnable } = filters;
    const addresses = this.getAllAddresses(config);
    addresses.push(route);
    const routeProtocols = Object.keys(config.protocols);
    const { authorization } = config;
    let searchRegexps;
    const searchSplit = search && search.split(' ');
    if (searchSplit) {
      try {
        searchRegexps = searchSplit.map((s) => new RegExp(s));
      } catch (e) {
        return false;
      }
    }
    return (
      (!searchRegexps ||
        searchRegexps.every((re) => addresses.some((a) => a.match(re)))) &&
      (!protocols.length ||
        protocols.some((p) => routeProtocols.includes(p))) &&
      (!authorizations.length ||
        authorizations.some((a) => authorization === a)) &&
      (!sla || routeProtocols.some((p) => config.protocols[p].is_sla)) &&
      (!rpc ||
        (routeProtocols.includes('http') && config.protocols.http.rpc)) &&
      (!runnable || routeProtocols.some((p) => config.protocols[p].runnable))
    );
  };

  filterRoutes = (routes, filters) =>
    Object.entries(routes)
      .filter(([k, v]) => this.routeMatchesFilter(filters, k, v))
      .reduce((o, it) => ({ ...o, [it[0]]: it[1] }), {});

  updateLocation = (filters) => {
    const { search, protocols, authorizations, sla, rpc, runnable } = filters;
    this.props.navigate({
      pathname: this.props.location.pathname,
      search: qs.stringify({
        q: search,
        p: protocols.join(','),
        a: authorizations.map((a) => (isUndefined(a) ? 'none' : a)).join(','),
        sla,
        runnable,
        rpc,
      }),
    });
  };

  renderSearchFilter() {
    return (
      <Text
        id="discover-services-key-filter"
        value={this.state.filters.search}
        placeholder="Search the matrix..."
        onChange={this.onSearch}
      />
    );
  }

  renderProtocolsFilter() {
    const { protocols } = this.state.filters;
    return (
      <div className="DiscoverRoutes__filter">
        <span>Protocol:</span>
        <Checkbox
          id="http"
          label="HTTP"
          checked={protocols.includes('http')}
          onChange={this.onFilterProtocol('http')}
        />
        <Checkbox
          id="rmq"
          label="RMQ"
          checked={protocols.includes('rmq')}
          onChange={this.onFilterProtocol('rmq')}
        />
      </div>
    );
  }

  renderMiscFilter() {
    return (
      <div className="DiscoverRoutes__filter">
        <span>Misc:</span>
        <Checkbox
          id="is_sla"
          label="is sla"
          checked={this.state.filters.sla}
          onChange={this.onFilterSla}
        />
        <Checkbox
          id="is_rpc"
          label="with dedicated rpc"
          checked={this.state.filters.rpc}
          onChange={this.onFilterRPC}
        />
        <Checkbox
          id="is_runnable"
          label="is runnable"
          checked={this.state.filters.runnable}
          onChange={this.onFilterRunnable}
        />
      </div>
    );
  }

  renderAuthorizationsFilter() {
    const { authorizations } = this.state.filters;
    return (
      <div className="DiscoverRoutes__filter">
        <span>Authorization:</span>
        <Checkbox
          id="none"
          label="NONE"
          checked={authorizations.includes(undefined)}
          onChange={this.onFilterAuthorization(undefined)}
        />
        <Checkbox
          id="public"
          label="PUBLIC"
          checked={authorizations.includes('public')}
          onChange={this.onFilterAuthorization('public')}
        />
        <Checkbox
          id="valid"
          label="VALID"
          checked={authorizations.includes('valid')}
          onChange={this.onFilterAuthorization('valid')}
        />
        <Checkbox
          id="custom"
          label="CUSTOM"
          checked={authorizations.includes('custom')}
          onChange={this.onFilterAuthorization('custom')}
        />
        <Checkbox
          id="support"
          label="SUPPORT"
          checked={authorizations.includes('support')}
          onChange={this.onFilterAuthorization('support')}
        />
        <Checkbox
          id="admin"
          label="ADMIN"
          checked={authorizations.includes('admin')}
          onChange={this.onFilterAuthorization('admin')}
        />
        <Checkbox
          id="service"
          label="SERVICE"
          checked={authorizations.includes('service')}
          onChange={this.onFilterAuthorization('service')}
        />
      </div>
    );
  }

  renderRoutes(routes) {
    // Display only 25 routes.
    const totalRoutes = Object.entries(routes).length;
    const extraRoutes = totalRoutes - 25;
    const displayedRoutes = Object.entries(routes).slice(0, 25);
    return (
      <div className="DiscverServices__routes">
        <div className="DiscoverServices__filters">
          {this.renderSearchFilter()}
          {this.renderMiscFilter()}
          {this.renderProtocolsFilter()}
          {this.renderAuthorizationsFilter()}
        </div>
        {displayedRoutes.map(([route, config]) => (
          <DiscoverRoute key={route} route={route} config={config} />
        ))}
        {extraRoutes > 0 && (
          <div className="DiscoverService__extraRoutes">
            {`${extraRoutes} route(s) not displayed. Please use the filter.`}
          </div>
        )}
        {totalRoutes === 0 && (
          <EmptyState
            icon="ghost"
            title="Oups..."
            text="No routes match that search or your regexp is invalid"
          />
        )}
      </div>
    );
  }

  renderContent() {
    const { filteredRoutes } = this.state;
    if (!filteredRoutes) {
      return (
        <EmptyState icon="battery-charging" title="Loading routes..." text="" />
      );
    }
    return this.renderRoutes(filteredRoutes);
  }

  render() {
    return (
      <div>
        <HeaderLayout
          title="Routes"
          backHref={ROUTES.home}
          backMessage="Back home"
          isTitleSmall
        />
        <div className="container-fluid row">
          <div className="DiscoverServices col-xs-12">
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigate(withLocation(DiscoverServives));
