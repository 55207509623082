import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { ListController } from 'components/ui/list/controller';
import StatusBar from 'components/ui/status-bar';

import {
  changeFilesPageSize,
  downloadOneFile,
  fetchFiles,
  fetchOneFile,
  nextFilesPage,
  previousFilesPage,
} from '../../actions';
import { selectFiles, selectPagination } from '../../selectors';
import * as types from '../../types';

export function TranslationRow(props) {
  const { file } = props;
  const dispatch = useDispatch();

  return (
    <div className="ImportFileListRowWrapper">
      <div className="ImportFileListRow row">
        <StatusBar type={file.current ? 'SUCCESS' : 'EMPTY'} />
        <div className="col-xs-4">{file.filename}</div>
        <div className="col-xs-1">{file.lang}</div>
        <div className="col-xs-1">{file.key_count}</div>
        <div className="col-xs-3">{file.created_at}</div>
        <div className="col-xs-3 ImportFileListRow__actions">
          {/* {file.current === false && (
            <Button onClick={() => dispatch(updateLatestFile(file.id))}>
              <i className="mdi mdi-power-plug" />
            </Button>
          )} */}
          <Button
            onClick={() => dispatch(fetchOneFile(file.id, file.filename))}
          >
            <i className="mdi mdi-eye" />
          </Button>
          <Button
            onClick={() => dispatch(downloadOneFile(file.id, file.filename))}
          >
            <i className="mdi mdi-download" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export function TranslationList(props) {
  return (
    props.files?.map((file) => (
      <TranslationRow key={`translation-${file.id}`} file={file} />
    )) || <div>No translations found</div>
  );
}

export function TranslationFiles() {
  const filesPage: types.FilesPage | null = useSelector(selectFiles);
  const pagination: types.PageRequest = useSelector(selectPagination);
  const dispatch = useDispatch();

  const onNextPage = useCallback(() => {
    dispatch(nextFilesPage());
  }, [dispatch, nextFilesPage]);

  const onPreviousPage = useCallback(() => {
    dispatch(previousFilesPage());
  }, [dispatch, previousFilesPage]);

  const onPageSizeChange = useCallback(
    (size) => {
      dispatch(changeFilesPageSize(size));
    },
    [dispatch, changeFilesPageSize],
  );

  useEffect(() => {
    dispatch(fetchFiles());
  }, [dispatch]);

  if (filesPage === null) {
    return null;
  }

  return (
    <ListController
      currentPage={filesPage.number + 1}
      totalPages={filesPage.total_pages}
      totalResults={filesPage.total_elements}
      limit={pagination.size}
      onLimitChange={onPageSizeChange}
      onNext={onNextPage}
      onPrev={onPreviousPage}
      type="translationlist"
    >
      <TranslationList files={filesPage.content} />
    </ListController>
  );
}
