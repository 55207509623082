import { filter, set } from 'lodash/fp';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Button, SwitchButton } from '@alkem/react-ui-button';
import { Text, Textarea } from '@alkem/react-ui-inputs';
import { LazyTooltip, Tooltip } from '@alkem/react-ui-tooltip';

import InputWithLabel from 'components/input-with-label';
import { Rule, RuleSelector } from 'modules/validation-dashboard/types';
import { copyEntryValue } from 'modules/validation-dashboard/utils';

import './validation-conditions.scss';

interface Props {
  id: string;
  rule: Rule;
  initRule: Rule;
  disabled?: boolean;
  updateRule: (path: string[], value: any) => void;
}

interface ConditionEditorItemsProps {
  id: string;
  items: RuleSelector[];
  updateItems: (items: RuleSelector[]) => void;
  valuePlaceholder?: string;
  withErrorDisplaySelection?: boolean;
}

const ConditionEditorItems = ({
  id,
  items = [],
  updateItems,
  valuePlaceholder = '$.example.JsonPath',
  withErrorDisplaySelection = false,
}: ConditionEditorItemsProps) => {
  const dispatch = useDispatch();

  // Entry used for new key-value in dict
  const initialNewEntry: RuleSelector = { id: uuid(), key: '', value: '' };
  if (withErrorDisplaySelection) {
    initialNewEntry.usedToDisplayErrors = true;
  }
  const [newEntry, setNewEntry] = useState(initialNewEntry);

  const parseValue = (value: string) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };
  const parseKey = (key: string) => {
    return key.replaceAll(/[^a-zA-Z0-9]| /g, '');
  };

  const isNewEntryValid =
    newEntry.key &&
    !items.some((entry) => entry.key === newEntry.key) &&
    newEntry.value !== '';

  //Button actions
  const addEntry = () => {
    if (isNewEntryValid) {
      updateItems([...items, newEntry]);
      setNewEntry(initialNewEntry);
    }
  };
  const delEntry = (item: RuleSelector) => {
    updateItems(filter((elem) => elem !== item, items));
  };

  // Updates existing keys
  const setKey = (i: number, newKey: string) => {
    updateItems(set([i, 'key'], newKey, items));
  };
  const setValue = (i: number, newValue: string) => {
    updateItems(set([i, 'value'], newValue, items));
  };
  const setUsedToDisplayErrors = (i: number, usedToDisplayErrors: boolean) => {
    updateItems(set([i, 'usedToDisplayErrors'], usedToDisplayErrors, items));
  };

  return (
    <div className="ConditionEditorItems alk-flex alk-flex-row">
      <div className="flex-grow--1">
        {items.map((item, i) => (
          <div
            key={`entry-${item.id}`}
            className="ConditionEditorItem alk-flex alk-flex-column"
            id={item.id}
          >
            <div className="ConditionEditorItem__edit alk-flex alk-flex-row">
              <Text
                className="ConditionEditorItem__key"
                id={`cei-key-${item.id}`}
                onChange={(event) =>
                  setKey(i, parseKey((event.target as HTMLInputElement).value))
                }
                placeholder="Key (without the $)"
                value={item.key}
                invalid={!item.key}
                onBlur={() => addEntry()}
              />
              <div className="ConditionEditorItem__value">
                <Textarea
                  id={`cei-value-${item.id}`}
                  onChange={(event) =>
                    setValue(i, parseValue(event.target.value))
                  }
                  placeholder={valuePlaceholder}
                  value={
                    typeof item.value === 'string'
                      ? item.value
                      : JSON.stringify(item.value)
                  }
                  autoresize={true}
                  autoresizeDelta="2px"
                  rows={1}
                  invalid={item.value === initialNewEntry.value}
                  onBlur={() => addEntry()}
                />
              </div>

              <div
                data-tip
                data-for={`delete-entry-${item.id}-button`}
                className="alk-flex"
              >
                <Button
                  onClick={() => delEntry(item)}
                  className="bg-white small-padding"
                >
                  <i className="mdi mdi-delete"></i>
                </Button>
                <Tooltip id={`delete-entry-${item.id}-button`} place="bottom">
                  Delete entry
                </Tooltip>
              </div>

              <div
                data-tip
                data-for={`copy-value-${item.id}-button`}
                className="alk-flex"
              >
                <Button
                  className="bg-white small-padding"
                  onClick={() => copyEntryValue(item.value, dispatch)}
                  disabled={item.value === initialNewEntry.value}
                >
                  <i className="text-dark mdi mdi-content-copy"></i>
                </Button>
                <Tooltip id={`copy-value-${item.id}-button`} place="bottom">
                  {item.value !== initialNewEntry.value
                    ? 'Copy value'
                    : 'Nothing to copy'}
                </Tooltip>
              </div>
            </div>
            {withErrorDisplaySelection && (
              <div className="ConditionEditorItem__errorDisplay">
                <SwitchButton
                  key={`cei-bool-${item.id}`}
                  checked={item.usedToDisplayErrors}
                  onChange={(checked) => setUsedToDisplayErrors(i, checked)}
                  content={
                    <>
                      Do you want to use this selector to display errors?{' '}
                      <LazyTooltip
                        id={`error-display-help-${item.id}`}
                        tooltipLabel="Choose if you want the selector to be used in the error path computation. Error path are used to display errors in the product page."
                      >
                        <i className="mdi mdi-help-circle" />
                      </LazyTooltip>
                    </>
                  }
                />
              </div>
            )}
          </div>
        ))}

        <div key="new-entry" className="ConditionEditorItem" id={id}>
          <div className="ConditionEditorItem__edit alk-flex alk-flex-row">
            <Text
              className="ConditionEditorItem__key"
              id={`cei-key-new-${id}`}
              onChange={(event) => {
                setNewEntry({
                  ...newEntry,
                  key: parseKey((event.target as HTMLInputElement).value),
                });
              }}
              placeholder="Key (without the $)"
              value={newEntry.key}
              invalid={
                !newEntry.key ||
                !!items.find((elem) => elem.key === newEntry.key)
              }
              onBlur={() => addEntry()}
            />
            <div className="ConditionEditorItem__value">
              <Textarea
                id={`cei-value-new-${id}`}
                onChange={(event) => {
                  setNewEntry({
                    ...newEntry,
                    value: parseValue(event.target.value),
                  });
                }}
                onBlur={() => addEntry()}
                placeholder={valuePlaceholder}
                value={
                  typeof newEntry.value === 'string'
                    ? newEntry.value
                    : JSON.stringify(newEntry.value)
                }
                autoresize={true}
                autoresizeDelta="2px"
                rows={1}
              />
            </div>

            <div
              data-tip
              data-for={`add-entry-button-${id}`}
              className="alk-flex"
            >
              <Button
                link
                className="small-padding"
                onClick={() => addEntry()}
                disabled={!isNewEntryValid}
              >
                <i className="mdi mdi-plus-circle"></i>
              </Button>
              <Tooltip id={`add-entry-button-${id}`} place="bottom">
                {isNewEntryValid
                  ? 'Add a new entry'
                  : 'Fill with a unique key first'}
              </Tooltip>
            </div>

            <div
              data-tip
              data-for={`copy-value-button-${id}`}
              className="alk-flex"
            >
              <Button
                className="bg-white small-padding"
                onClick={() => copyEntryValue(newEntry.value, dispatch)}
                disabled={newEntry.value === initialNewEntry.value}
              >
                <i className="mdi mdi-content-copy"></i>
              </Button>
              <Tooltip id={`copy-value-button-${id}`} place="bottom">
                {newEntry.value !== initialNewEntry.value
                  ? 'Copy value'
                  : 'Nothing to copy'}
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ConditionsEditor = ({
  rule,
  disabled = false,
  id,
  updateRule,
}: Props) => {
  const dispatch = useDispatch();

  const updateConstants = (items: RuleSelector[]) => {
    updateRule(['constantList'], items);
  };

  const updateSelectors = (items: RuleSelector[]) => {
    updateRule(['selectorList'], items);
  };

  return (
    <div className="ConditionsEditor" id={id}>
      <div className="ConditionEditorItemsList alk-flex alk-flex-column">
        <InputWithLabel inputId="constants-editor" label="Constants">
          <ConditionEditorItems
            valuePlaceholder="Constant value"
            id="constants-editor"
            items={rule.constantList}
            updateItems={(newConstants) => updateConstants(newConstants)}
          />
        </InputWithLabel>
        <InputWithLabel inputId="selectors-editor" label="Selectors">
          <ConditionEditorItems
            id="selectors-editor"
            items={rule.selectorList}
            updateItems={(newSelectors) => updateSelectors(newSelectors)}
            withErrorDisplaySelection
          />
        </InputWithLabel>
      </div>

      <hr />

      <InputWithLabel inputId="condition-editor" label="Condition">
        <div className="alk-flex alk-flex-row">
          <Text
            id="condition-editor"
            value={rule.condition || ''}
            onChange={(event) =>
              updateRule(
                ['condition'],
                (event.target as HTMLInputElement).value,
              )
            }
            placeholder="Condition filtering products to apply the rule on. e.g. ANY[$a]"
          />
          <Button
            className="bg-white small-padding"
            onClick={() => copyEntryValue(rule.condition, dispatch)}
            disabled={!rule.condition}
          >
            <i className="text-dark mdi mdi-content-copy"></i>
          </Button>
        </div>
      </InputWithLabel>

      <InputWithLabel inputId="validation-editor" label="Validation">
        <div className="alk-flex alk-flex-row">
          <Text
            id="validation-editor"
            value={rule.validation || ''}
            onChange={(event) =>
              updateRule(
                ['validation'],
                (event.target as HTMLInputElement).value,
              )
            }
            invalid={!rule.validation}
            placeholder="Valid when this assertion is met. e.g. ANY[$a == false]"
          />
          <Button
            className="bg-white small-padding"
            onClick={() => copyEntryValue(rule.validation, dispatch)}
            disabled={!rule.validation}
          >
            <i className="text-dark mdi mdi-content-copy"></i>
          </Button>
        </div>
      </InputWithLabel>

      <hr />
      <InputWithLabel inputId="error-message-editor" label="Error message">
        <div className="alk-flex alk-flex-row">
          <Textarea
            id={`error-message-editor`}
            value={rule.errorMessage || ''}
            placeholder="Error message that will be displayed on front"
            onChange={(event) =>
              updateRule(
                ['errorMessage'],
                (event.target as HTMLTextAreaElement).value,
              )
            }
            disabled={disabled}
          />
          <Button
            className="bg-white small-padding"
            onClick={() => copyEntryValue(rule.errorMessage, dispatch)}
            disabled={!rule.errorMessage}
          >
            <i className="text-dark mdi mdi-content-copy"></i>
          </Button>
        </div>
      </InputWithLabel>
    </div>
  );
};
