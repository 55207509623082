import CancelablePromise from 'cancelable-promise';

import { immutableAuthApi } from 'resources/authApi';

import * as events from '../constants/organizations';
import {
  selectOrganizationFilteredList,
  selectOrganizationList,
  selectOrganizationSearch,
} from '../selectors/organizations';

export const receiveOrganizations = (list) => ({
  type: events.ORGANIZATIONS_RECEIVE,
  list,
});

export const receiveFilteredOrganizations = (list) => ({
  type: events.FILTERED_ORGANIZATIONS_RECEIVE,
  list,
});

export const getOrganizations = (options) => (dispatch, getState) => {
  const orgs = selectOrganizationList(getState());
  if (orgs.size > 0) {
    return new CancelablePromise((resolve) => resolve(orgs));
  }

  return immutableAuthApi
    .OrganizationListV3(options, {}, {}, undefined, 9000)
    .then((response) => {
      const { data } = response.data;
      dispatch(receiveOrganizations(data));
      return data;
    });
};

export const getFilteredOrganizations =
  (partialNameLegal: string, limit = 9000) =>
  (dispatch, getState) => {
    const search = selectOrganizationSearch(getState());
    if (search === partialNameLegal) {
      const filteredList = selectOrganizationFilteredList(getState());
      return new CancelablePromise((resolve) => resolve(filteredList));
    }

    return immutableAuthApi
      .OrganizationListV3(
        { nameLegal: partialNameLegal },
        {},
        {},
        undefined,
        limit,
      )
      .then((response) => {
        const { data } = response.data;
        dispatch(receiveFilteredOrganizations(data));
        return data;
      });
  };
