import PropTypes from 'prop-types';
import React from 'react';

import './tabs.scss';

export default class Tab extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className="react-ui-tab-content" data-tab-title={this.props.title}>
        {this.props.children}
      </div>
    );
  }
}
