import classNames from 'classnames';
import Dropzone from 'react-dropzone';

export function ImportDropzone({ onSelectFiles, withWarning }) {
  return (
    <Dropzone multiple onDrop={onSelectFiles}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps({
            className: classNames(
              'Dropzone',
              isDragActive && 'Dropzone--active',
            ),
          })}
        >
          <input {...getInputProps()} />
          <div>
            <b>Drag and drop your file here</b> or select a file from your
            computer...
          </div>
          {withWarning ? (
            <div className="Label--red">
              /!\ Use this modal only if you are sure of what you are doing /!\
            </div>
          ) : null}
        </div>
      )}
    </Dropzone>
  );
}
