import { List, Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { isImmutable } from 'utils/immutable';

import * as events from '../constants/events';

const initialState = fromJS({
  organization: {
    id: null,
    type: null,
  },
  referentials: {
    listcolumn: [],
    listreporting: [],
    fieldtags: [],
    suppliersReconciliationKeys: [],
  },
  fieldMetaDataRoots: [],
  settings: {},
  editedSettings: {},
});

function cleanEmptyEditedSettings(state) {
  // Clean empty maps
  return state.set(
    'editedSettings',
    state
      .get('editedSettings')
      .filter((element) => !(Map.isMap(element) && element.size === 0)),
  );
}

export default createReducer(initialState, {
  [events.ORGANIZATION_RECEIVED]: (state, { organization }) =>
    state.withMutations((newState) =>
      newState
        .setIn(['organization', 'type'], organization.get('type'))
        .setIn(['organization', 'id'], organization.get('id'))
        .set('settings', organization.get('settings'))
        .set('editedSettings', Map()),
    ),

  [events.RECEIVE_LISTCOLUMN_REFERENTIAL]: (state, { referential }) =>
    state.setIn(['referentials', 'listcolumn'], referential),

  [events.RECEIVE_LISTREPORTING_REFERENTIAL]: (state, { referential }) =>
    state.setIn(['referentials', 'listreporting'], referential),

  [events.RECEIVE_FIELDTAG_REFERENTIAL]: (state, { referential }) =>
    state.setIn(['referentials', 'fieldtags'], referential),

  [events.RECEIVE_SUPPLIER_RECONCILIATION_KEYS_REFERENTIAL]: (
    state,
    { referential },
  ) =>
    state.setIn(['referentials', 'suppliersReconciliationKeys'], referential),

  [events.UPDATE_SETTINGS_VALUE]: (state, { path, value }) => {
    const sourcePath = ['settings'].concat(path);
    const editedPath = ['editedSettings'].concat(path);

    let isEqual = (val) => val === state.getIn(sourcePath);
    if (isImmutable(value)) {
      isEqual = (val) => val.equals(state.getIn(sourcePath));
    }
    if (isEqual(value)) {
      return cleanEmptyEditedSettings(state.deleteIn(editedPath));
    }
    return state.setIn(editedPath, value);
  },

  [events.ADD_LIST_SETTING_VALUE]: (state, { path, value }) => {
    const editedPath = ['editedSettings'].concat(path);
    const sourcePath = ['settings'].concat(path);

    const sourceList = state.getIn(sourcePath, List());
    const editedList = state
      .getIn(editedPath, state.getIn(sourcePath, List()))
      .push(value);

    if (editedList.equals(sourceList)) {
      return cleanEmptyEditedSettings(state.deleteIn(editedPath));
    }
    return state.setIn(editedPath, editedList);
  },

  [events.REMOVE_LIST_SETTING_VALUE]: (state, { path, value }) => {
    const editedPath = ['editedSettings'].concat(path);
    const sourcePath = ['settings'].concat(path);

    const sourceList = state.getIn(sourcePath, List());
    const editedList = state
      .getIn(editedPath, state.getIn(sourcePath, List()))
      .filter((elem) => elem !== value);

    if (editedList.equals(sourceList)) {
      return cleanEmptyEditedSettings(state.deleteIn(editedPath));
    }
    return state.setIn(editedPath, editedList);
  },

  [events.RECEIVE_FIELDMETADATA_ROOTS]: (state, { roots }) =>
    state.set(
      'fieldMetaDataRoots',
      roots.map((r) => ({
        id: r.id,
        label: r.get('name'),
      })),
    ),
});
