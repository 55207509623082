import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { MultipleSelector } from '@alkem/react-ui-select';

export default class OrganizationFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    filters: ImmutablePropTypes.list.isRequired,
    selection: ImmutablePropTypes.map.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  static defaultProps = {
    title: 'Organizations',
  };

  cache = {
    filters: null,
    formattedFilters: [],
    selection: null,
    formattedSelection: [],
  };

  onSelect = ({ value }, path, selected) => {
    const { onChange, filters } = this.props;
    onChange(
      filters.find((filter) => filter.get('id') === value),
      selected,
    );
  };

  formatFilters() {
    const { id, filters } = this.props;
    if (this.cache.filters === filters) {
      return this.cache.formattedFilters;
    }
    this.cache.filters = filters;
    this.cache.formattedFilters = filters
      .map((filter) => ({
        key: `${id}-${filter.get('id')}`,
        label: (filter.get('nameLegal') || '') + ` (${filter.get('id')})`,
        value: filter.get('id'),
      }))
      .toJS();
    return this.cache.formattedFilters;
  }

  formatSelection() {
    const { filters, selection } = this.props;
    if (this.cache.selection === selection) {
      return this.cache.formattedSelection;
    }
    this.cache.formattedSelection = selection
      .keySeq()
      .map((filterId) => [
        filters.findIndex((filter) => filter.get('id') === filterId),
      ])
      .toJS();
    return this.cache.formattedSelection;
  }

  render() {
    const { id, title } = this.props;
    return (
      <div className="ReactUiFilter__filter ReactUiFilter__filter--withPadding">
        <h3 className="ReactUiFilter__filterTitle">{title}</h3>
        <MultipleSelector
          id={id}
          options={this.formatFilters()}
          selection={this.formatSelection()}
          onSelect={this.onSelect}
          emptyLabel="Not found"
          placeholder=""
          noToggleIcon
          scrollable
        />
      </div>
    );
  }
}
