import qs from 'querystringify';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { HeaderLayout } from '@alkem/react-layout';

import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { ImportFilters } from 'modules/io/import/filters';
import { ImportList } from 'modules/io/import/list';

import { searchImportReportList } from '../actions';

type Props = {
  search?: string;
};

export const ImportDashboardView = (props: Props) => {
  const location = useLocation();
  const { search = (qs.parse(location.search) as any).q || '' } = props;
  const dispatch = useDispatch();

  const updateSearchQuery = (e) => {
    dispatch(searchImportReportList(e));
  };

  const headerProps = {
    title: 'Import dashboard',
    backHref: routes.home,
    backMessage: 'Back home',
    isTitleSmall: true,
  };

  return (
    <div className="ImportDashboardView__body">
      <HeaderLayout {...headerProps}>
        <Search
          query={search}
          updateSearchQuery={updateSearchQuery}
          wide={true}
          placeholder="Gtins or product identifiers, separated by commas"
        />
      </HeaderLayout>
      <div className="container-fluid row">
        <div className="col-md-3">
          <ImportFilters />
        </div>
        <div className="col-md-9">
          <ImportList />
        </div>
      </div>
    </div>
  );
};

export default ImportDashboardView;
