import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ListAutocomplete } from 'components/autocomplete';
import SelectedFilters from 'components/ui/selected-filters';
import { separateActions } from 'utils/redux';

import {
  addGDSNRelationListFilters,
  clearGDSNRelationListFilters,
  removeGDSNRelationListFilters,
  updateGDSNRelationListFilters,
} from '../actions';
import { selectFilters } from '../selectors';

const mapStateToProps = (state) => ({
  filters: selectFilters(state),
});

const mapDispatchToProps = {
  addGDSNRelationListFilters,
  removeGDSNRelationListFilters,
  updateGDSNRelationListFilters,
  clearGDSNRelationListFilters,
};

class GDSNRelationListFilters extends PureComponent {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      addGDSNRelationListFilters: PropTypes.func.isRequired,
      removeGDSNRelationListFilters: PropTypes.func.isRequired,
      updateGDSNRelationListFilters: PropTypes.func.isRequired,
      clearGDSNRelationListFilters: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {}

  onAddGLN = (search) => {
    this.props.actions.addGDSNRelationListFilters('filter_gln', {
      label: search,
      value: search,
    });
  };

  render() {
    const { filters } = this.props;
    return (
      <div>
        <SelectedFilters
          filters={filters}
          removeFilter={this.props.actions.removeGDSNRelationListFilters}
          removeAllFilters={this.props.actions.clearGDSNRelationListFilters}
        />
        <h4>GLN</h4>
        <div className="card">
          <ListAutocomplete
            id="list-autocomplete-gln-source"
            onAdd={this.onAddGLN}
            values={[]}
            value={filters.filter_gln}
            addMissingOptionLabel="Search..."
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(GDSNRelationListFilters);
