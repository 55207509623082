import { List } from 'immutable';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Filter } from '@alkem/react-ui-filter';

import { craftTree } from '../utils';

import ReferentialManagementAddChildRow from './row-add-child';
import ReferentialManagementNodeUpdateRow from './row-update';

export default class ReferentialManagementNodeUpdate extends PureComponent {
  static propTypes = {
    sourceReferentials: ImmutablePropTypes.list,
    isLoading: PropTypes.bool,
    isUpdating: PropTypes.bool,
    updateReferential: PropTypes.func,
    addChildrenToReferential: PropTypes.func,
  };

  state = {
    selected: null,
  };

  onChangeUpdatedReferential = (_, node) => this.setState({ selected: node });

  onUpdate = (data) => {
    const { selected } = this.state;
    this.props.updateReferential(get(selected, 'id'), data);
    this.setState({
      selected: null,
    });
  };

  onAddChild = (data) => {
    const { selected } = this.state;
    this.props.addChildrenToReferential(get(selected, 'id'), data);
    this.setState({
      selected: null,
    });
  };

  render = () => {
    const { sourceReferentials, isLoading, isUpdating } = this.props;
    const { selected } = this.state;
    return (
      <div className="OrganizationUsesFieldsView__referential__update">
        <div>Select the referential you want to edit</div>
        <Filter
          name="ReferentialUpdateSelection"
          type="tree"
          filters={craftTree(
            get(selected, 'id'),
            (sourceReferentials || List()).toJS(),
          )}
          onChange={this.onChangeUpdatedReferential}
        />
        <hr />
        <h3>Update referential</h3>
        <ReferentialManagementNodeUpdateRow
          isLoading={isLoading}
          isUpdating={isUpdating}
          selected={selected}
          onUpdate={this.onUpdate}
        />
        <hr />
        <h3>Add children</h3>
        <ReferentialManagementAddChildRow
          isLoading={isLoading}
          isUpdating={isUpdating}
          parent={selected}
          onAddChild={this.onAddChild}
        />
      </div>
    );
  };
}
