import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import xmlFormat from 'xml-formatter';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';

import { notificationError } from 'actions/notifications';
import { OrganizationAutocomplete } from 'components/autocomplete';
import { CodeEditor } from 'components/code-editor';
import etlApi from 'resources/etlApi';
import { saveAs } from 'utils';

import {
  SAVE_EXPORT_MAPPING,
  SET_EDITION_ID,
  SET_SELECTED_EXPORT_MAPPING,
} from '../../../actions/constants';
import { UNLIMITED_DATE, XPATH_TYPES } from '../../../constants';
import {
  selectSelectedExportMapping,
  selectXpathList,
} from '../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  Xpath,
  XpathList,
} from '../../../types';
import { ExportMappingsItemActivationDates } from '../item-activation-dates';
import { ExportMappingsItemAutomaticProperties } from '../item-automatic-properties';
import { ExportMappingsItemChildren } from '../item-children';
import { ExportMappingsItemConditions } from '../item-conditions';
import { ExportMappingsItemConstants } from '../item-constants';
import { ExportMappingsItemFallback } from '../item-fallback';
import { ExportMappingsItemProperties } from '../item-properties';
import { ExportMappingsListElementItemType } from '../list-element-item-type';
import { ExportMappingsSimpleElementItemType } from '../simple-element-item-type';
import { getModuleId } from '../utils';

import styles from './item.module.scss';

interface Props {
  element: Xpath;
  sameLevelElementChildren?: XpathList;
  exportMapping: ExportMapping;
  editionId?: number | string;
  reorderingId?: number;
  isNew?: boolean;
  index?: number;
  changeOrder: Function;
  lengthMappings?: number;
  isFirst?: boolean;
  parentExportMapping?: ExportMapping;
  sxm_list_parent?: {
    field: string;
    use_su: boolean;
  };
  isFallback?: true;
  parentElement?: Xpath;
}
export function ExportMappingsItem(props: Props) {
  const {
    element,
    sameLevelElementChildren,
    exportMapping,
    editionId,
    reorderingId,
    isNew = false,
    index,
    changeOrder,
    lengthMappings = 0,
    isFirst = false,
    parentExportMapping,
    isFallback,
    parentElement,
  } = props;
  const dispatch = useDispatch();
  const selectedExportMapping = useSelector(selectSelectedExportMapping);
  const xpathList = useSelector(selectXpathList);
  const originalStartDate = useRef(moment(exportMapping.date_start)).current;
  const originalEndDate = useRef(moment(exportMapping.date_end)).current;
  const [isClosed, setIsClosed] = useState(true);
  const [showModalImport, setShowModalImport] = useState(false);
  const [showModalTest, setShowModalTest] = useState(false);
  const [isProcessingImport, setIsProcessingImport] = useState(false);
  const [xmlResult, setXmlResult] = useState('');
  const [jsonTest, setJsonTest] = useState('');
  const [files, setFiles] = useState<any[]>([]);
  const [organization, setOrganization] = useState<{ key: number } | undefined>(
    undefined,
  );
  const [isTested, setIsTested] = useState(false);
  const [showModalSaveWithoutTest, setShowModalSaveWithoutTest] =
    useState(false);

  const isReordering = reorderingId === element.id;

  let exportMappingToUse = exportMapping;
  let isEditionInProgress = false;
  if (selectedExportMapping) {
    if (
      (isNew && editionId === `new_${element.id}`) ||
      (!isNew && editionId === exportMapping.id)
    ) {
      exportMappingToUse = selectedExportMapping;
      isEditionInProgress = true;
    }
  }

  const openItem = (): void => {
    setIsClosed(false);
  };

  const getIsReadyForSaving = () => {
    if (!exportMappingToUse) return false;
    const {
      constants_for_later_use,
      conditions,
      unit,
      set_attribute_from_field,
      set_attribute_from_value,
    } = exportMappingToUse.payload;
    if (constants_for_later_use) {
      for (const key in constants_for_later_use) {
        if (!constants_for_later_use[key].data) {
          return false;
        }
      }
    }
    if (conditions?.data) {
      for (let i = 0; i < conditions.data.length; i++) {
        const condition = conditions.data[i];
        if (typeof condition.data === 'undefined') {
          return false;
        }
      }
    }
    if (
      set_attribute_from_field &&
      (!set_attribute_from_field.includes('=') ||
        set_attribute_from_field[0] === '=')
    ) {
      return false;
    }
    if (
      set_attribute_from_value &&
      (!set_attribute_from_value.includes('=') ||
        set_attribute_from_value[0] === '=')
    ) {
      return false;
    }
    if (element.type === XPATH_TYPES.list) {
      const { cast, field, custom_parse } = exportMappingToUse.payload;
      switch (cast) {
        case 'gdsn_node':
          return true;
        case 'list':
          return !!field;
        case undefined:
          return !!custom_parse;
        default:
          return false;
      }
    }
    if (element.type === XPATH_TYPES.simple) {
      const { cast, field, value, is_declinable, referential, custom_parse } =
        exportMappingToUse.payload;
      if (custom_parse) {
        return true;
      }
      let isReady = !!cast;
      if (value) {
        isReady = isReady && !!value.cast && typeof value.data !== 'undefined';
        if (cast === 'entity' || is_declinable) {
          isReady = isReady && !!referential;
          if (value.cast === 'value' && is_declinable) {
            isReady =
              isReady &&
              !!value.cast &&
              typeof value.data?.[0].data !== 'undefined' &&
              !!value.data?.[0].expressedIn?.code;
          }
        }
      } else {
        isReady = isReady && !!field;
      }
      if (
        ['int', 'int positive', 'float', 'float positive'].includes(
          cast || '',
        ) &&
        is_declinable
      ) {
        isReady = isReady && typeof unit !== 'undefined';
      }
      return isReady;
    }
    return false;
  };

  const saveExportMapping = (): void => {
    dispatch({
      type: SAVE_EXPORT_MAPPING,
      exportMapping: exportMappingToUse,
      xpath: element,
    });
    setOrganization(undefined);
    setJsonTest('');
    setXmlResult('');
    setIsTested(false);
    setShowModalTest(false);
    setShowModalSaveWithoutTest(false);
    setIsClosed(true);
    dispatch({
      type: SET_EDITION_ID,
    });
    dispatch({
      type: SET_SELECTED_EXPORT_MAPPING,
    });
  };

  const setExportMapping = (id?: number | string) => {
    dispatch({
      type: SET_EDITION_ID,
      payload: {
        editionId: id,
      },
    });
    dispatch({
      type: SET_SELECTED_EXPORT_MAPPING,
      payload: {
        exportMapping: exportMappingToUse,
      },
    });
  };

  const exportExportMapping = (): void => {
    const exportMappingToExport = {
      type: element.type,
      date_start: exportMappingToUse.date_start,
      date_end: exportMappingToUse.date_end,
      payload: exportMappingToUse.payload,
    };
    const dataStr = JSON.stringify(exportMappingToExport, null, 2);
    const exportFileName = `${element.name}.json`;
    saveAs(
      new Blob([dataStr], { type: 'application/json;charset=utf-8' }),
      exportFileName,
    );
  };

  const renderButtonsContainer = (): JSX.Element => {
    const isReadyForSaving = getIsReadyForSaving();
    const mustShowEdit =
      !isClosed &&
      (isNew
        ? editionId !== `new_${element.id}`
        : editionId !== exportMapping.id);
    const mustEnableEdit =
      (typeof editionId === 'undefined' ||
        (isNew && editionId === `new_${element.id}`) ||
        (!isNew && editionId === exportMappingToUse.id)) &&
      typeof reorderingId === 'undefined';
    return (
      <div className={styles.buttonsContainer}>
        {isClosed && !isReordering && (
          <>
            <Button
              id="export-gdsn-export-mapping"
              content="Import"
              secondary
              onClick={() => {
                setExportMapping(exportMappingToUse.id || `new_${element.id}`);
                setShowModalImport(true);
              }}
            />
            <Button
              id="view-gdsn-export-mapping"
              content="View"
              primary
              onClick={openItem}
            />
          </>
        )}
        {!isClosed && mustShowEdit && !isReordering && (
          <>
            <Button
              id="export-gdsn-export-mapping"
              content="Export"
              secondary
              onClick={exportExportMapping}
            />
            <Button
              id="edit-gdsn-export-mapping"
              content="Edit"
              primary
              disabled={!mustEnableEdit}
              onClick={() =>
                setExportMapping(exportMappingToUse.id || `new_${element.id}`)
              }
            />
          </>
        )}
        {!isClosed &&
          editionId ===
            (!isNew ? exportMappingToUse.id : `new_${element.id}`) && (
            <>
              <Button
                id="export-gdsn-export-mapping"
                content="Export"
                secondary
                onClick={exportExportMapping}
              />
              <Button
                id="cancel-gdsn-export-mapping"
                content="Cancel"
                secondary
                onClick={() => {
                  setExportMapping(undefined);
                  setIsClosed(true);
                }}
              />
              <Button
                id="save-gdsn-export-mapping"
                content="Save"
                primary
                disabled={!isReadyForSaving}
                onClick={() =>
                  typeof getModuleId(xpathList, element.xpath) !== 'undefined'
                    ? setShowModalTest(true)
                    : setShowModalSaveWithoutTest(true)
                }
              />
            </>
          )}
        {isReordering && index !== 0 && (
          <Button
            id="up-gdsn-export-mapping"
            content={
              <span>
                <i
                  data-testid={`up-${index}-${exportMappingToUse.id}`}
                  className="mdi mdi-chevron-up"
                />
              </span>
            }
            secondary
            onClick={() => changeOrder(index, 'UP')}
          />
        )}
        {isReordering && index !== lengthMappings - 1 && (
          <Button
            id="down-gdsn-export-mapping"
            content={
              <i
                data-testid={`down-${index}-${exportMappingToUse.id}`}
                className="mdi mdi-chevron-down"
              />
            }
            secondary
            onClick={() => changeOrder(index, 'DOWN')}
          />
        )}
      </div>
    );
  };

  const importExportMapping = useCallback(() => {
    if (files.length !== 1) {
      return;
    }

    setIsProcessingImport(true);
    const reader = new FileReader();
    reader.onload = function () {
      const content = reader.result as string;
      const importedExportMapping: {
        type: string;
        payload: ExportMappingPayload;
        date_start: string;
        date_end: string;
      } = JSON.parse(content);
      if (element.type === importedExportMapping.type) {
        const updatedExportMapping = {
          ...exportMappingToUse,
          payload: importedExportMapping.payload,
          date_start: importedExportMapping.date_start,
          date_end: importedExportMapping.date_end,
        };
        dispatch({
          type: SET_SELECTED_EXPORT_MAPPING,
          payload: {
            exportMapping: {
              ...updatedExportMapping,
            },
          },
        });
        openItem();
      } else {
        setExportMapping(undefined);
        dispatch(
          notificationError('The export mapping type is not the right one.'),
        );
      }
      setIsProcessingImport(false);
      setShowModalImport(false);
      setFiles([]);
    };
    reader.readAsText(files[0]);
  }, [dispatch, files]);

  const selectOrganization = (data?: { key: number }) => {
    setOrganization(data);
  };

  const renderModalImport = () => {
    return (
      <Modal
        testid="GDSNExportMappingsItemImportModal"
        title="Import export mapping"
        modalStyle="fullHeight"
        confirmButtonText="Import"
        onConfirm={importExportMapping}
        onClose={() => {
          setFiles([]);
          setExportMapping(undefined);
          setShowModalImport(false);
        }}
        isProcessing={isProcessingImport}
      >
        <Dropzone onDrop={setFiles}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps({
                className: classNames(
                  'Dropzone',
                  isDragActive && 'Dropzone--active',
                ),
              })}
            >
              <input {...getInputProps()} />
              <div>
                {files.length > 0 ? (
                  <>File selected: {files[0].name}</>
                ) : (
                  <>
                    <b>Drag and drop your json file here</b> or select a json
                    file from your computer...
                  </>
                )}
              </div>
            </div>
          )}
        </Dropzone>
      </Modal>
    );
  };

  const createXml = async () => {
    try {
      const res = await etlApi.post(
        '/etl/v2/gdsndashboard/export/test/mapping',
        {
          json_test: jsonTest,
          id_module: getModuleId(xpathList, element.xpath),
          id_node: exportMappingToUse.id || null,
          order: exportMappingToUse.order,
          parent_id: exportMappingToUse.parent_id,
          xpath_id: element.id,
          is_fallback: exportMappingToUse.is_fallback,
          date_start: exportMappingToUse.date_start,
          date_end: exportMappingToUse.date_end,
          payload: exportMappingToUse.payload,
          target_org_id: organization?.key,
          substitute_gs1_domain:
            global.env === 'ppr'
              ? true
              : moment(exportMappingToUse.date_start).isAfter(moment(), 'd'),
        },
        false,
      );
      setXmlResult(xmlFormat(res.data.data));
      setIsTested(true);
    } catch (error) {
      dispatch(
        notificationError(
          'There was an error while testing. Have a look at your console to find out what it was.',
        ),
      );
      setIsTested(false);
      console.error(error); // eslint-disable-line no-console
    }
  };

  const renderModalTest = () => {
    return (
      <Modal
        testid="GDSNExportMappingsItemModalTest"
        title="Test export mapping"
        modalStyle="fullHeight"
        confirmButtonText="Save"
        confirmDisabled={!isTested}
        onConfirm={saveExportMapping}
        onClose={() => {
          setOrganization(undefined);
          setJsonTest('');
          setXmlResult('');
          setIsTested(false);
          setShowModalTest(false);
        }}
      >
        <div className={styles.modalTestContainer}>
          <div className={styles.codeEditorsContainer}>
            <CodeEditor
              name="jsonTest"
              className="GDSNExportMappingsItemTest__Editor__json"
              value={jsonTest}
              mode="json"
              theme="monokai"
              width="50%"
              height="300px"
              editorProps={{ $blockScrolling: Infinity }}
              onChange={setJsonTest}
              placeholder="Write your json here"
            />
            <CodeEditor
              name="xmlResult"
              className="GDSNImportMappingsItemTest__Editor__xml"
              value={xmlResult}
              mode="xml"
              theme="monokai"
              width="50%"
              height="300px"
              editorProps={{ $blockScrolling: Infinity }}
              placeholder="The xml result will be displayed here"
            />
          </div>
          <div className={styles.organizationAutocomplete}>
            <OrganizationAutocomplete
              id="organization-autocomplete-gdsn-export-mapping"
              value={organization ? [organization] : undefined}
              onSelect={selectOrganization}
              onUnselect={() => selectOrganization(undefined)}
              placeholder="Select organization"
              searchOnClick
            />
          </div>
          <Button
            id="test-gdsn-export-mapping"
            content="Test"
            primary
            onClick={createXml}
            disabled={!organization}
          />
        </div>
      </Modal>
    );
  };

  const renderModalSaveWithoutTest = () => {
    return (
      <Modal
        testid="GDSNExportMappingsItemModalSaveWithoutTest"
        title="Save export mapping"
        modalStyle="fullHeight"
        confirmButtonText="Save"
        onConfirm={saveExportMapping}
        onClose={() => {
          setShowModalSaveWithoutTest(false);
        }}
      >
        You're about to save the mapping. It is not possible to test this kind
        of mapping. Do you really want to save it?
      </Modal>
    );
  };

  const name = `${element.name}_${element.id}_${exportMappingToUse.id}${
    isFallback ? '_fallback' : ''
  }${isNew ? '_new' : ''}`;

  return (
    <div
      className={styles.container}
      data-testid={`gdsn-export-mappings-item-${name}`}
    >
      <div
        className={classNames({
          [styles.headerClosed]: isClosed,
          [styles.headerOpened]: !isClosed,
        })}
      >
        <span
          className={styles.nameContainer}
          onClick={() => setIsClosed(!isClosed)}
        >
          <i
            className={classNames('mdi', styles.arrowButton, {
              'mdi-chevron-down': isClosed,
              'mdi-chevron-up': !isClosed,
            })}
          />
          {!isNew &&
            `id #${
              exportMappingToUse.id
            } - Valid from ${originalStartDate.format('YYYY-MM-DD')} ${
              originalEndDate.isSame(UNLIMITED_DATE)
                ? 'for an unlimited amount of time'
                : `to ${originalEndDate.format('YYYY-MM-DD')}${
                    originalEndDate.isBefore(moment())
                      ? ' (not valid anymore)'
                      : ''
                  }`
            }`}
          {isNew && `Create ${isFallback ? 'fallback' : 'new mapping'}`}
        </span>
        {renderButtonsContainer()}
      </div>

      {!isClosed && exportMappingToUse && (
        <>
          <div>
            {element.type === XPATH_TYPES.list && (
              <ExportMappingsListElementItemType
                name={name}
                exportMapping={exportMappingToUse}
                isEditionInProgress={isEditionInProgress}
                disabled={!(isNew && isEditionInProgress)}
              />
            )}
            {element.type === XPATH_TYPES.simple && (
              <ExportMappingsSimpleElementItemType
                name={name}
                exportMapping={exportMappingToUse}
                isEditionInProgress={isEditionInProgress}
                disabled={!(isNew && isEditionInProgress)}
              />
            )}
            <ExportMappingsItemActivationDates
              name={name}
              exportMapping={exportMappingToUse}
              disabled={!isEditionInProgress}
            />
            <ExportMappingsItemAutomaticProperties
              name={name}
              exportMapping={exportMappingToUse}
            />
            {element.type === XPATH_TYPES.list && (
              <ExportMappingsItemConstants
                name={name}
                exportMapping={exportMappingToUse}
                disabled={!isEditionInProgress}
              />
            )}
            {!exportMappingToUse.payload.custom_parse && (
              <>
                <ExportMappingsItemConditions
                  name={name}
                  element={element}
                  sameLevelElementChildren={sameLevelElementChildren}
                  exportMapping={exportMappingToUse}
                  disabled={!isEditionInProgress}
                />
                <ExportMappingsItemProperties
                  key={`export_mappings_item_properties_${element.id}_${exportMappingToUse.id}_${exportMappingToUse.payload.cast}_${exportMappingToUse.payload.field}_${exportMappingToUse.payload.is_declinable}_${exportMappingToUse.payload.referential}_${exportMappingToUse.payload.use_su}`}
                  name={name}
                  exportMapping={exportMappingToUse}
                  disabled={!isEditionInProgress}
                  isFirst={isFirst}
                  parentExportMapping={parentExportMapping}
                  parentElement={parentElement}
                />
              </>
            )}
            {!isNew && <ExportMappingsItemFallback {...props} />}
          </div>
          {element.type === XPATH_TYPES.list &&
            !isNew &&
            !exportMappingToUse.payload.custom_parse && (
              <ExportMappingsItemChildren
                name={name}
                element={element}
                exportMapping={exportMappingToUse}
              />
            )}
        </>
      )}
      {showModalImport && renderModalImport()}
      {showModalTest && renderModalTest()}
      {showModalSaveWithoutTest && renderModalSaveWithoutTest()}
    </div>
  );
}
