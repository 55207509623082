import PropTypes from 'prop-types';
import { Component } from 'react';
import { combineReducers } from 'redux';

import { getStore } from 'redux/store';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import rootReducer from '../reducers';

import { registerSaga } from './saga';

const moduleReducers = {};

function replaceReducer() {
  const modules =
    Object.keys(moduleReducers).length > 0
      ? { [MODULE_REDUCER_KEY]: combineReducers(moduleReducers) }
      : {};
  getStore().replaceReducer(
    combineReducers({
      ...rootReducer,
      ...modules,
    }),
  );
}

export function registerModule(key, reducer) {
  if (key in moduleReducers) {
    return;
  }
  moduleReducers[key] = (state, action) => {
    let newState = state;
    if (action.type === 'RESET_MODULE_REDUCER' && action.payload === key) {
      newState = undefined;
    }
    return reducer(newState, action);
  };
  replaceReducer();
}

export class ModuleWrapper extends Component {
  static propTypes = {
    reducerKey: PropTypes.string.isRequired,
    reducer: PropTypes.func.isRequired,
    saga: PropTypes.func,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = { ready: false };
  }

  componentDidMount() {
    registerModule(this.props.reducerKey, this.props.reducer);
    if (this.props.saga) {
      registerSaga(this.props.saga);
    }
    setTimeout(() => this.setState({ ready: true }), 0);
  }

  componentWillUnmount() {
    getStore().dispatch({
      type: 'RESET_MODULE_REDUCER',
      payload: this.props.reducerKey,
    });
    setTimeout(() => this.setState({ ready: false }), 0);
  }

  render() {
    if (!this.state.ready) {
      return null;
    }
    return this.props.children;
  }
}
