import classNames from 'classnames';
import React from 'react';

import { Text } from '@alkem/react-ui-inputs';
import TurboSelect, {
  TurboSelectActionMeta,
  TurboSelectOption,
} from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';

import styles from './turbo-selector.module.scss';

interface Props {
  label: string;
  testId: string;
  options?: any;
  onSelect: (
    value: TurboSelectOption<any>,
    action: TurboSelectActionMeta<any>,
  ) => void;
  option?: any;
  placeholder?: string;
  disabled?: boolean;
  searchProps?: {
    onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    placeholder: string;
  };
  displayedValueProps?: {
    label: string;
    value?: string;
  };
  showEditionFields?: boolean;
  mustShowError?: boolean;
}
export function ExportMappingsTurboSelector({
  label,
  testId,
  options,
  onSelect,
  option,
  placeholder,
  disabled,
  searchProps,
  displayedValueProps,
  showEditionFields = false,
  mustShowError = true,
}: Props) {
  return (
    <div data-testid={testId} className={styles.container}>
      {(showEditionFields || !displayedValueProps) && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-simple-selector`}
          label={label}
        >
          {showEditionFields && searchProps && (
            <Text
              className={styles.textField}
              id="gdsn-export-mappings-simple-selector-search"
              placeholder={searchProps.placeholder}
              onChange={searchProps.onSearch}
              value={searchProps.value || ''}
            />
          )}
          {(showEditionFields || !displayedValueProps) && (
            <TurboSelect
              id={`gdsn-export-mappings-simple-selector`}
              options={options}
              getOptionLabel={(item) => item.label || item.id.toString()}
              getOptionValue={(item) => item.id.toString()}
              value={option}
              isMulti={false}
              isClearable
              isSearchable={!searchProps}
              onChange={onSelect}
              placeholder={placeholder || 'Select...'}
              isDisabled={disabled}
              className={classNames({
                [styles.errorInput]:
                  mustShowError &&
                  (displayedValueProps ? !displayedValueProps.value : !option),
              })}
            />
          )}
        </InputWithLabel>
      )}
      {displayedValueProps && (
        <InputWithLabel
          inputId="gdsn-export-mappings-simple-selector-value"
          label={displayedValueProps.label}
        >
          <Text
            id="gdsn-export-mappings-simple-selector-value"
            value={displayedValueProps.value || ''}
            onChange={() => {}}
            disabled
          />
        </InputWithLabel>
      )}
    </div>
  );
}
