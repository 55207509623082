import { Map } from 'immutable';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { FIELD_SUGGESTION_DASHBOARD_STORE_KEY } from './reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][FIELD_SUGGESTION_DASHBOARD_STORE_KEY];
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export function selectFilters(state) {
  return selectState(state).filters;
}

export function selectFieldMetaDataAggregates(state) {
  return selectState(state).fieldMetaDataAggregates;
}

export function selectFieldMetaData(state) {
  return (
    selectFieldMetaDataAggregates(state).find(
      (e) => e.get('id') === selectFieldMetaDataId(state),
    ) || Map()
  );
}

export function selectFieldMetaDataId(state) {
  return selectFilters(state).get('fieldMetaData').keySeq().first();
}

export function selectNbSources(state) {
  return selectFilters(state).get('nbSources').keySeq().first();
}

export function selectValidating(state) {
  return selectState(state).validating;
}

export function selectValidatedEntitiesIds(state) {
  return selectState(state).validatedEntitiesIds;
}

export function selectValidationErrors(state) {
  return selectState(state).validationErrors;
}

export function selectEditing(state) {
  return selectState(state).editing;
}

export function selectEditedEntitiesIds(state) {
  return selectState(state).editedEntitiesIds;
}

export function selectEditionErrors(state) {
  return selectState(state).editionErrors;
}

export function selectModalState(state) {
  return selectState(state).modalState;
}

export function selectSearch(state) {
  return selectState(state).search;
}

export function selectOrganizations(state) {
  return selectState(state).organizations;
}

export function selectSourceCount(state) {
  return selectState(state).sourceCount;
}

export function selectKindLeaves(state) {
  return selectState(state).kindLeaves;
}

export function selectInternalCategories(state) {
  return selectState(state).internalCategories;
}

export function selectShowChildren(state) {
  return selectState(state).showChildren;
}

export function selectInternalCategoriesIdPath(state) {
  return selectState(state).internalCategoriesIdToPath;
}
