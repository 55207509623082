import { set } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import { ActionWithPayload } from 'utils/reducers/types';

import * as events from './events';
import * as types from './types';

export const REDUCER_KEY = 'indexer-queue';

const getInitialState = (): types.QueueState => ({
  loading: false,
  selectedFilters: {
    organization_id: null,
    source: null,
    priority: null,
  },
  page: null,
  pageRequest: {
    page: 0,
    size: 20,
  },
  organizations: null,
  detail: null,
  sources: null,
  status: null,
  organizationReindexStatistics: null,
  selectedOrganization: null,
});

export default createReducer(getInitialState(), {
  [events.INIT]: () => getInitialState(),
  [events.SELECT_SOURCE]: (
    state,
    { payload: source }: ActionWithPayload<number | null>,
  ) => set(['selectedFilters', 'source'], source, state),
  [events.SELECT_PRIORITY]: (
    state,
    { payload: priority }: ActionWithPayload<number | null>,
  ) => set(['selectedFilters', 'priority'], priority, state),
  [events.SELECT_ORGANIZATION]: (
    state,
    {
      payload: organization,
    }: ActionWithPayload<types.SelectedOrganization | null>,
  ) =>
    set(
      ['selectedFilters', 'organization_id'],
      organization?.key || null,
      set(['selectedOrganization'], organization, state),
    ),
  [events.PREVIOUS_PAGE]: (state) =>
    set(['pageRequest', 'page'], state.pageRequest.page - 1, state),
  [events.NEXT_PAGE]: (state) =>
    set(['pageRequest', 'page'], state.pageRequest.page + 1, state),
  [events.CHANGE_PAGE_SIZE]: (
    state,
    { payload: size }: ActionWithPayload<number>,
  ) => set(['pageRequest', 'size'], size, state),
  [events.SET_LOADING]: (
    state,
    { payload: loading }: ActionWithPayload<boolean>,
  ) => set(['loading'], loading, state),
  [events.RECEIVE_QUEUE_PAGE]: (
    state,
    { payload: page }: ActionWithPayload<types.Page>,
  ) => set(['page'], page, state),
  [events.RECEIVE_QUEUE_LIST]: (
    state,
    { payload: organizations }: ActionWithPayload<types.Organization[]>,
  ) => set(['organizations'], organizations, state),
  [events.RECEIVE_DETAIL]: (
    state,
    { payload: detail }: ActionWithPayload<types.OrganizationDetail | null>,
  ) => set(['detail'], detail, state),
  [events.RECEIVE_SOURCES]: (
    state,
    { payload: sources }: ActionWithPayload<types.Source[]>,
  ) => set(['sources'], sources, state),
  [events.RECEIVE_QUEUE_STATUS]: (
    state,
    { payload: status }: ActionWithPayload<types.QueueStatus>,
  ) => set(['status'], status, state),
  [events.RECEIVE_ORGANIZATION_STATISTICS]: (
    state,
    {
      payload: status,
    }: ActionWithPayload<types.OrganizationIndexRequestStatistics>,
  ) => set(['organizationReindexStatistics'], status, state),
});
