import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { separateActions } from 'utils/redux';

import { searchByBrand, searchByUsername } from '../actions';
import { selectSearchBrand, selectSearchUsername } from '../selectors';

import './index.scss';
import BrandClaimList from './list';

const mapStateToProps = (state) => ({
  searchUsername: selectSearchUsername(state),
  searchBrand: selectSearchBrand(state),
});

const mapDispatchToProps = {
  searchByUsername,
  searchByBrand,
};

export class BrandClaimDashboard extends PureComponent {
  static propTypes = {
    searchUsername: PropTypes.string,
    searchBrand: PropTypes.string,
    actions: PropTypes.shape({
      searchByUsername: PropTypes.func.isRequired,
      searchByBrand: PropTypes.func.isRequired,
    }).isRequired,
  };

  onUpdateUsernameSearchQuery = (query) => {
    this.props.actions.searchByUsername(query);
  };

  onUpdateBrandSearchQuery = (query) => {
    this.props.actions.searchByBrand(query);
  };

  renderSearchBars() {
    return (
      <div className="BrandClaim__searchContainer">
        <Search
          query={this.props.searchUsername}
          placeholder="Username"
          updateSearchQuery={this.onUpdateUsernameSearchQuery}
        />
        <Search
          query={this.props.searchBrand}
          placeholder="Brand"
          updateSearchQuery={this.onUpdateBrandSearchQuery}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <HeaderLayout
          title="Brand Claim Dashboard"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        >
          {this.renderSearchBars()}
        </HeaderLayout>
        <div className="container-fluid row">
          <div className="col-md-12">
            <BrandClaimList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(BrandClaimDashboard);
