import { set, unset } from 'lodash/fp';

import { createReducerStrict } from 'utils/reducers';
import { ActionWithPayloadStrict } from 'utils/reducers/types';

import {
  BILLING_RESET_CHANGES,
  UPDATE_GROUP_RELATION,
} from '../constants/events';
import { OrganizationBillingState } from '../types';

const initialState: OrganizationBillingState = {
  changes: {},
};

export default createReducerStrict<OrganizationBillingState>(initialState, {
  [UPDATE_GROUP_RELATION]: (
    state: OrganizationBillingState,
    {
      payload,
    }: ActionWithPayloadStrict<{ groupId: number; isEnabled: boolean }>,
  ) => {
    if (payload.groupId in state.changes) {
      return unset(['changes', payload.groupId], state);
    } else {
      return set(['changes', payload.groupId], payload.isEnabled, state);
    }
  },
  [BILLING_RESET_CHANGES]: set('changes', initialState.changes),
});
