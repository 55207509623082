import classNames from 'classnames';
import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Text } from '@alkem/react-ui-inputs';
import { TurboSelect, TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';
import {
  Condition,
  ConditionWithStringData,
  Xpath,
  XpathList,
} from 'modules/io/gdsn/exports/types';

import { isXpath } from '../../utils';
import styles from '../item-conditions.module.scss';

interface Props {
  index: number;
  condition: ConditionWithStringData;
  updateCondition: (index: number, condition: Condition) => void;
  sameLevelElementChildren?: XpathList;
  name: string;
  disabled?: boolean;
}
export function ExportMappingsItemSameLevelGDSNFieldExistsCondition({
  index,
  condition,
  updateCondition,
  sameLevelElementChildren = [],
  name,
  disabled,
}: Props) {
  const [fieldOption, setFieldOption] = useState<
    TurboSelectOption<Xpath> | undefined
  >(undefined);
  const [field, setField] = useState<string | undefined>(condition.data);

  const updateField = (option: TurboSelectOption<Xpath>) => {
    if (isXpath(option)) {
      setFieldOption(option);
      setField(option.name);
      updateCondition(index, { ...condition, data: option.name });
    } else {
      setFieldOption(undefined);
      setField(undefined);
      updateCondition(index, { ...condition, data: undefined });
    }
  };

  return (
    <>
      {!disabled && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-same-level-gdsn-field-exists-selector-${name}`}
          label={
            <span>
              Select GDSN field{' '}
              <HelpTooltip
                id={`gdsn-export-mappings-same-level-gdsn-field-exists-selector-${name}-help`}
                place="top"
                message="Select a GDSN element that must exist on same level for the actual mapping to be created."
              />
            </span>
          }
        >
          <div
            data-testid={`gdsn-export-mappings-same-level-gdsn-field-exists-selector-${name}`}
          >
            <TurboSelect
              id={`gdsn-export-mappings-same-level-gdsn-field-exists-selector-${name}`}
              options={sameLevelElementChildren}
              getOptionLabel={(child) => child.name}
              getOptionValue={(child) => child.name}
              value={fieldOption}
              isMulti={false}
              isSearchable={false}
              isClearable
              onChange={updateField}
              isDisabled={sameLevelElementChildren.length === 0}
              placeholder={
                sameLevelElementChildren.length > 0
                  ? 'Select...'
                  : 'No GDSN field available'
              }
              className={classNames({
                [styles.errorInput]: !field,
              })}
            />
          </div>
        </InputWithLabel>
      )}
      <InputWithLabel
        inputId={`gdsn-export-mappings-same-level-gdsn-field-exists-${name}`}
        label="GDSN field that must exist on same level"
      >
        <div
          data-testid={`gdsn-export-mappings-same-level-gdsn-field-exists-${name}`}
        >
          <Text
            id={`gdsn-export-mappings-same-level-gdsn-field-exists-${name}`}
            value={field || ''}
            onChange={() => {}}
            disabled
          />
        </div>
      </InputWithLabel>
    </>
  );
}
