import { useState } from 'react';

import { Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { ExportMapping } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

export const checkFloat = (value: string, isOnlyPositive = false): boolean => {
  if ((isOnlyPositive && value[0] === '-') || value === '-.') return false;
  if (
    (!isOnlyPositive && value === '-') ||
    (value !== '.' && value[value.length - 1] === '.')
  ) {
    return true;
  }
  const regex = isOnlyPositive ? /^\d*\.?\d+$/ : /^-?\d*\.{0,1}\d+$/;

  return regex.test(value);
};

export const checkInt = (value: string, isOnlyPositive = false): boolean => {
  if (isOnlyPositive && value[0] === '-') return false;
  if (!isOnlyPositive && value === '-') {
    return true;
  }
  const regex = isOnlyPositive ? /^\d+$/ : /^-?\d+$/;
  return regex.test(value);
};

const getInitialDefaultValue = (exportMapping: ExportMapping) => {
  const { cast, is_declinable } = exportMapping.payload;
  if (!cast) return undefined;
  if (
    !(
      (cast === 'string' ||
        cast === 'text' ||
        cast === 'int' ||
        cast === 'int positive' ||
        cast === 'float' ||
        cast === 'float positive') &&
      !is_declinable
    )
  )
    return undefined;
  return exportMapping.payload.default_value;
};

interface Props {
  name: string;
  exportMapping: ExportMapping;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemNotDeclinableElementProperties({
  name,
  exportMapping,
  dispatchProperty,
}: Props) {
  const { cast, is_declinable } = exportMapping.payload;
  const [defaultValue, setDefaultValue] = useState(
    getInitialDefaultValue(exportMapping),
  );

  if (!cast) return null;
  if (
    !(
      (cast === 'string' ||
        cast === 'text' ||
        cast === 'int' ||
        cast === 'int positive' ||
        cast === 'float' ||
        cast === 'float positive') &&
      !is_declinable
    )
  )
    return null;

  const updateDefaultValue = (event: { target: { value: string } }) => {
    let isValueDataSet = false;
    let updatedData: string | number | undefined = event.target.value;
    if (cast === 'string' || updatedData === '') {
      updatedData = updatedData || undefined;
    } else {
      let isValid = false;
      while (!isValid) {
        if (cast.includes('float')) {
          isValid = checkFloat(updatedData, cast.includes('positive'));
        } else {
          isValid = checkInt(updatedData, cast !== 'int');
        }
        if (!isValid) {
          updatedData = updatedData.slice(0, -1);
        }
        if (updatedData === '') break;
      }
      if (updatedData === '') {
        updatedData = undefined;
      } else if (updatedData === '-') {
        setDefaultValue(updatedData);
        isValueDataSet = true;
        updatedData = undefined;
      } else {
        if (updatedData[updatedData.length - 1] === '.') {
          setDefaultValue(updatedData);
          isValueDataSet = true;
          updatedData = updatedData.slice(0, -1);
        }
        updatedData = cast.includes('float')
          ? parseFloat(updatedData)
          : parseInt(updatedData);
        updatedData = isNaN(Number(updatedData))
          ? undefined
          : Number(updatedData);
      }
    }
    if (!isValueDataSet) setDefaultValue(updatedData);
    dispatchProperty('default_value', updatedData);
  };

  return (
    <div
      className={styles.property}
      data-testid={`gdsn-export-mappings-not-declinable-element-default-value-property-${name}`}
    >
      <InputWithLabel
        inputId={`gdsn-export-mappings-not-declinable-element-default-value-property-${name}`}
        label="Default value"
      >
        <Text
          id={`gdsn-export-mappings-not-declinable-element-default-value-property-${name}`}
          value={typeof defaultValue === 'undefined' ? '' : defaultValue}
          onChange={updateDefaultValue}
        />
      </InputWithLabel>
    </div>
  );
}
