import { ruleEntityTypes } from 'modules/validation-dashboard/constants';

const base_unit = { id: 0, name: 'base unit' };
const pack = { id: 1, name: 'pack' };
const _case = { id: 2, name: 'case' };
const pallet = { id: 3, name: 'pallet' };

const typePackagings = {
  [ruleEntityTypes.CONSUMER_UNIT.id]: {
    [base_unit.id]: base_unit,
    [pack.id]: pack,
    [_case.id]: _case,
  },
  [ruleEntityTypes.DISPLAY_UNIT.id]: {
    [_case.id]: _case,
    [pallet.id]: pallet,
  },
  [ruleEntityTypes.LOGISTICAL_UNIT.id]: {
    [pack.id]: pack,
    [_case.id]: _case,
    [pallet.id]: pallet,
  },
  [ruleEntityTypes.LOGISTICAL_HIERARCHY_TOP_UNIT.id]: {
    [pack.id]: pack,
    [_case.id]: _case,
    [pallet.id]: pallet,
  },
  [ruleEntityTypes.TEXTILE_VARIANT.id]: {
    [base_unit.id]: base_unit,
    [pack.id]: pack,
  },
  all: {
    [base_unit.id]: base_unit,
    [pack.id]: pack,
    [_case.id]: _case,
    [pallet.id]: pallet,
  },
};

export const typePackagingsForRuleEntityType = (
  ruleEntityTypeId: keyof typeof typePackagings,
) => {
  return Object.keys(
    typePackagings[ruleEntityTypeId] || typePackagings.all,
  ).map((s: string) => parseInt(s));
};

export default typePackagings;
