import { notificationError, notificationSuccess } from 'actions/notifications';
import { authApi, immutableAuthApi } from 'resources/authApi';
import productApi from 'resources/productApi';

import * as events from '../constants/events';

export const retrieveOrganization = (organizationId) => async (dispatch) => {
  let organization;

  dispatch({
    type: events.RESET_ORGANIZATION,
  });

  try {
    const response = await immutableAuthApi.OrganizationShow(
      organizationId,
      {
        settings: true,
        status: true,
        network: true,
        group: true,
        glnData: true,
        domains: true,
      },
      false,
    );
    organization = response.data.data;
  } catch (exception) {
    dispatch(notificationError('Error while loading organization'));
    return;
  }
  dispatch({
    type: events.ORGANIZATION_RECEIVED,
    organization,
  });
};

export const getManagedOrganizationPermissions =
  (organizationId) => async (dispatch) => {
    let permissions;

    dispatch({
      type: events.RESET_MANAGED_ORGANIZATION_PERMISSIONS,
    });

    try {
      const response =
        await immutableAuthApi.GetPermissionsForOrganizationManaged(
          organizationId,
          false,
        );
      permissions = response.data.data.get('permissions');
    } catch (exception) {
      dispatch(
        notificationError(
          'Error while loading managed organization permissions',
        ),
      );
      return;
    }

    dispatch({
      type: events.MANAGED_ORGANIZATION_PERMISSIONS_RECEIVED,
      permissions,
    });
  };

export const resyncOrganization =
  (organizationId, reason, scheduleType) => async (dispatch) => {
    try {
      if (scheduleType) {
        await productApi.post('/product/v2/organizations/schedules', {
          reason,
          type: scheduleType,
          organization_id: organizationId,
        });
      } else {
        await productApi.post('/product/v2/product/sync/catalog', {
          reason,
          organization_id: organizationId,
        });
      }
    } catch (exception: any) {
      dispatch(
        notificationError(
          exception.data.message || 'Error while resyncing organization.',
        ),
      );
    }
  };

export const reviewTriggerOrganization =
  (organizationId) => async (dispatch) => {
    try {
      await productApi.post('/review/process/v1/trigger/organization/', {
        organization_id: organizationId,
      });
      dispatch(notificationSuccess('Review has been triggered successfully'));
    } catch (exception: any) {
      dispatch(
        notificationError(
          exception.data.message || 'Error while review trigger organization.',
        ),
      );
    }
  };

export const bulkUpdateOrganizationDomains = async (
  domainType: 'domain' | 'email_domain',
  domains: { id: number; domain: string }[],
): Promise<boolean> => {
  try {
    await authApi.post('/auth/v3/organization/domains', {
      type: domainType,
      domains,
    });
  } catch (exception: any) {
    return false;
  }
  return true;
};
