import { ModuleWrapper } from 'redux/register';

import { GDSN_RELATION_LIST_STORE_KEY } from './constants/reducers';
import reducer from './reducers';
import GDSNRelationDashboardView from './views';

export default function GDSNRelationDashboardViewContainer() {
  return (
    <ModuleWrapper reducerKey={GDSN_RELATION_LIST_STORE_KEY} reducer={reducer}>
      <GDSNRelationDashboardView />
    </ModuleWrapper>
  );
}
