import classNames from 'classnames';
import React, { useState } from 'react';

import './validation-rules-filters.scss';

const DashboardBlock = React.memo(
  ({ title, expandable = 0, secondary = false, children }: any) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <li
        className={classNames({
          DashboardBlock: true,
          secondary,
        })}
      >
        <div className="DashboardBlock__title">{title}</div>
        <div
          className={classNames({
            DashboardBlock__children: true,
            DashboardBlock__hidden_overflow: !!expandable,
          })}
          style={
            expandable && !expanded
              ? {
                  height: expandable,
                }
              : undefined
          }
        >
          {children}
        </div>
        {!!expandable && (
          <div
            className="DashboardBlock__expandable"
            onClick={() => setExpanded((e) => !e)}
          >
            {expanded ? (
              <div className="Select__openicon mdi mdi-chevron-up" />
            ) : (
              <div className="Select__openicon mdi mdi-chevron-down" />
            )}
          </div>
        )}
      </li>
    );
  },
);

export { DashboardBlock };
