import { ModuleWrapper } from 'redux/register';

import { IMPORT_LIST_STORE_KEY } from './constants/reducers';
import reducer from './reducers';
import ImportDashboardView from './views';

export default function ImportDashboardViewContainer() {
  return (
    <ModuleWrapper reducerKey={IMPORT_LIST_STORE_KEY} reducer={reducer}>
      <ImportDashboardView />
    </ModuleWrapper>
  );
}
