import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio, Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { DECLINABLE_TYPES } from 'modules/io/gdsn/exports/constants';
import { ExportMapping } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

interface Props {
  name: string;
  exportMapping: ExportMapping;
  disabled: boolean;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemDeclinableByOtherThanLanguagesElementProperties({
  name,
  exportMapping,
  disabled,
  dispatchProperty,
}: Props) {
  const [unit, setUnit] = useState<string | undefined>(
    exportMapping.payload.unit,
  );
  const [repeat, setRepeat] = useState<false | undefined>(
    exportMapping.payload.repeat,
  );
  const [codeListNameCodeValue, setCodeListNameCodeValue] = useState<
    string | undefined
  >(exportMapping.payload.code_list_name_code);

  if (
    !DECLINABLE_TYPES.includes(exportMapping.payload.cast || '') ||
    !exportMapping.payload.is_declinable
  )
    return null;

  const updateUnit = (event: { target: { value: string } }) => {
    const updatedUnit = event.target.value || undefined;
    setUnit(updatedUnit);
    dispatchProperty('unit', updatedUnit);
  };

  const updateRepeat = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedRepeat = value === 'false' ? false : undefined;
    setRepeat(updatedRepeat);
    dispatchProperty('repeat', updatedRepeat);
  };

  const updateCodeListNameCodeValue = (event: {
    target: { value: string };
  }) => {
    const updatedCodeListNameCodeValue = event.target.value || undefined;
    setCodeListNameCodeValue(updatedCodeListNameCodeValue);
    dispatchProperty('code_list_name_code', updatedCodeListNameCodeValue);
  };

  return (
    <div
      data-testid={`gdsn-export-mappings-declinable-by-other-than-languages-element-properties-${name}`}
    >
      <div className={styles.property}>
        <InputWithLabel
          inputId={`gdsn-export-mappings-declinable-by-other-than-languages-element-unit-property-${name}`}
          label={
            <span>
              Unit attribute name{' '}
              <HelpTooltip
                id={`gdsn-export-mappings-declinable-by-other-than-languages-element-unit-property-${name}-help`}
                place="top"
                message="Attribute name of the attribute that will contain the unit given by the languages referential."
              />
            </span>
          }
        >
          <Text
            id={`gdsn-export-mappings-declinable-by-other-than-languages-element-unit-property-${name}`}
            value={unit || ''}
            onChange={updateUnit}
            invalid={!disabled && !unit}
          />
        </InputWithLabel>
      </div>
      <div className={styles.property}>
        <InputWithLabel
          inputId={`gdsn-export-mappings-declinable-by-other-than-languages-element-repeat-property-${name}`}
          label="Create only one item"
        >
          <Radio
            id={`gdsn-export-mappings-declinable-by-other-than-languages-element-repeat-property-${name}`}
            value={repeat}
            options={[
              { label: 'TRUE', value: false },
              { label: 'FALSE', value: undefined },
            ]}
            onChange={updateRepeat}
          />
        </InputWithLabel>
      </div>
      <div className={styles.property}>
        <InputWithLabel
          inputId={`gdsn-export-mappings-declinable-by-other-than-languages-element-code-list-name-code-property-${name}`}
          label={
            <span>
              Attribute codeListNameCode value{' '}
              <HelpTooltip
                id={`gdsn-export-mappings-declinable-by-other-than-languages-element-code-list-name-code-property-${name}-help`}
                place="top"
                message="If set, an attribute codeListNameCode will be created."
              />
            </span>
          }
        >
          <Text
            id={`gdsn-export-mappings-declinable-by-other-than-languages-element-code-list-name-code-property-${name}`}
            value={codeListNameCodeValue || ''}
            onChange={updateCodeListNameCodeValue}
          />
        </InputWithLabel>
      </div>
    </div>
  );
}
