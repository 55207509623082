import { List, Map, Set } from 'immutable';

import { ALLOWED_COLUMN, LISTCOLUMN } from 'constants/organization-settings';

export const getListColumnValueForUpdate = (
  settings,
  editedSettings,
  listColumnReferential,
  columnsToUpdate,
  isAddition,
) => {
  // Get option ids from listcolumn referential
  const optionIds = listColumnReferential
    ?.filter((option) => columnsToUpdate.includes(option.get('code')))
    .map((option) => option.get('id'));

  let settingListColumn =
    !editedSettings.isEmpty() &&
    !editedSettings.getIn([LISTCOLUMN], Map()).isEmpty()
      ? editedSettings.getIn([LISTCOLUMN], Map())
      : settings.getIn([LISTCOLUMN], Map());
  if (isAddition) {
    settingListColumn = settingListColumn.set(
      ALLOWED_COLUMN,
      Set(
        settingListColumn.get(ALLOWED_COLUMN, List()).concat(optionIds),
      ).toList(),
    );
  } else {
    settingListColumn = settingListColumn.set(
      ALLOWED_COLUMN,
      settingListColumn
        .get(ALLOWED_COLUMN, List())
        .filter((id) => !optionIds?.includes(id)),
    );
  }

  return settingListColumn;
};
