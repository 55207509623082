import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';

import { notificationError } from 'actions/notifications';
import Notification from 'components/notification';
import mediaApi from 'resources/mediaApi';

import './picture-modal.scss';

const mapDispatchToProps = {
  notificationError,
};

class AddOrganizationPicture extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    notificationError: PropTypes.func.isRequired,
    organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    type: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
    pictureFile: null,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.resetPicture();
  }

  selectPicture = async (pictures) => {
    if (pictures && pictures.length) {
      const [pictureFile] = pictures;
      const img = new Image();

      pictureFile.preview = URL.createObjectURL(pictureFile);
      img.src = pictureFile.preview;
      await img.decode();

      if (!pictureFile.type.includes('image/')) {
        this.props.notificationError(
          'The file format is invalid. Please upload a file with format : image',
          'modal',
        );
      } else if (
        pictureFile.size >= 8388608 ||
        img.height > 720 ||
        img.width > 1280
      ) {
        this.props.notificationError(
          'The image is too big. Maximum is 1280x720 - 8MB',
          'modal',
        );
      } else {
        this.setState({ pictureFile });
      }
    }
  };

  uploadPicture = () => {
    const { organizationId, type } = this.props;
    const { pictureFile } = this.state;
    this.setState({ loading: true });
    mediaApi
      .OrganizationPictureUpload(
        {
          name: pictureFile.name,
          organization_id: organizationId,
          picture_type: type,
        },
        pictureFile,
      )
      .then(() => {
        this.cancel();
        this.props.onClose({ success: true });
      })
      .catch(({ data: { status, data } }) => {
        this.cancel();
        if (status === 413) {
          this.props.notificationError(
            `Image contains too many pixels. Actual pixels quantity : ${data.actual}. Maximum : ${data.max}`,

            'modal',
          );
        } else {
          this.props.notificationError(
            'Error while uploading the picture',
            'modal',
          );
        }
      });
  };

  cancel = () => {
    if (this._isMounted) {
      this.resetPicture();
      this.setState({ pictureFile: null, loading: false });
    }
  };

  resetPicture() {
    const { pictureFile } = this.state;
    if (pictureFile && pictureFile.preview) {
      URL.revokeObjectURL(pictureFile.preview);
    }
  }

  renderSummary() {
    const { pictureFile } = this.state;
    return (
      <div className="row AddPicture__container">
        <div className="col-xs-12 AddPicture__imageContainer">
          <img
            src={pictureFile.preview}
            className="AddPicture__image"
            alt={pictureFile.name}
          />
        </div>
      </div>
    );
  }

  renderDropZone() {
    return (
      <div className="AddPicture">
        <Dropzone onDrop={this.selectPicture}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps({
                className: classNames(
                  'AddPicture__dropzone',
                  isDragActive && 'AddPicture__dropzone--active',
                ),
              })}
            >
              <input {...getInputProps()} />
              <span className="Dropzone__label">
                Drop or click to select a picture
              </span>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  renderBody() {
    if (this.state.pictureFile) {
      return this.renderSummary();
    }
    return this.renderDropZone();
  }

  render() {
    const { onClose } = this.props;
    return (
      <Modal
        title="Upload a picture"
        confirmButtonText="Validate"
        onConfirm={this.uploadPicture}
        isProcessing={this.state.loading}
        onClose={() => onClose({ success: false })}
        hideFooter={!this.state.pictureFile}
      >
        <Notification type="modal" />
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(null, mapDispatchToProps)(AddOrganizationPicture);
