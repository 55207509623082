import FileSaver from 'file-saver';

import { get } from 'utils/immutable';

export function setDebug(host) {
  if (host.startsWith('local') || host.startsWith('ppr-local')) {
    window.__DEBUG__ = true;
  }
}

export function parseBoolean(s) {
  if (s === 'true') {
    return true;
  } else if (s === 'false') {
    return false;
  }
  return null;
}

export function isFunction(func) {
  return typeof func === 'function';
}

export function isString(str) {
  return typeof str === 'string';
}

export function isNumber(num) {
  return typeof num === 'number';
}

export function isValidJSON(text) {
  try {
    JSON.parse(text);
    return true;
  } catch (e) {
    return false;
  }
}

export function isHexColor(hexCode) {
  return /^#[0-9A-F]{6}$/i.test(hexCode);
}

export function areArraysEqual(a, b) {
  if (a.length !== b.length) {
    return false;
  }
  let equal = true;
  a.forEach((e, i) => {
    if (e !== b[i]) {
      equal = false;
    }
  });
  return equal;
}

export const updateAt = (index, update, list) => [
  ...list.slice(0, index),
  update(list[index]),
  ...list.slice(index + 1),
];

export const removeAt = (index, list) => [
  ...list.slice(0, index),
  ...list.slice(index + 1),
];

export function compareNames(obj1, obj2) {
  return (get(obj1, 'label') || get(obj1, 'name')) <
    (get(obj2, 'label') || get(obj2, 'name'))
    ? -1
    : 1;
}

export const calculateOffset = (page, limit) => Math.max((page - 1) * limit, 0);

export const trimStringValue = (value) =>
  typeof value === 'string' ? value.trim() : value;

// ----------------------------------------------------------------------------

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const isPreProduction = () => global.env === 'ppr';

// ---------
// Debugging
// =========
//
// log() logs it's first parameter and returns it, so you can wrap it around any
// value where it is processed. Useful if you have functions like:
// ```
// const mapStateToProps = (state) => ({
//   a: state.a,
//   b: log(state.c),
// })
// ```
// So you don't need to convert it into a common function in order to log the
// value.
if (isDevelopment()) {
  window.log = function (descriptionOrvalue, valueWhenDescription) {
    /* eslint-disable no-console */
    if (arguments.length === 2) {
      console.log(
        `%c${descriptionOrvalue}`,
        'color: blue',
        valueWhenDescription,
      );
      return valueWhenDescription;
    } else {
      console.log(descriptionOrvalue);
      return descriptionOrvalue;
    }
  };
  // when you want to remove the log output, but keep the log(...) in your code
  window.xlog = (x, y) => y || x;

  window.log2 = (a) => (b) => window.log(a, b);
}

export function logError(error, { force = false } = {}) {
  if (isDevelopment() || force) {
    console.error(get(error, 'message') || get(error, 'data.message') || error);
  }
}

logError.force = (error) => logError(error, { force: true });

export const saveAs = global.saveAs || FileSaver.saveAs || FileSaver;

export const addBorder = (color = 'red', width = '2px') => ({
  style: { border: `${width} solid ${color}` },
});
