export function getFilenameFromHTTPHeaders(headers: Headers): string {
  let filename = 'no_name';
  const contentDispositionHeader = headers.get('Content-Disposition');
  if (!contentDispositionHeader) return filename;

  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i;
  const match = regex.exec(contentDispositionHeader);
  if (match && match[1].replace(/['"]+/g, '') !== '') {
    filename = match[1].replace(/['"]+/g, '');
  }
  return filename;
}
