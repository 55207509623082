import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { ExportMapping } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

interface Props {
  name: string;
  field?: string;
  exportMapping: ExportMapping;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemMustNotLoopProperty({
  name,
  field,
  exportMapping,
  dispatchProperty,
}: Props) {
  const [mustNotLoop, setMustNotLoop] = useState<true | undefined>(
    exportMapping.payload.must_not_loop,
  );

  const updateMustNotLoop = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedMustNotLoop = value === 'true' || undefined;
    setMustNotLoop(updatedMustNotLoop);
    dispatchProperty('must_not_loop', updatedMustNotLoop);
  };

  const splittedField = field?.split('.');
  if (!splittedField || splittedField.length !== 2) {
    return null;
  }
  const listField = splittedField[0];
  const childField = splittedField[1];

  return (
    <div
      className={styles.property}
      data-testid={`gdsn-export-mappings-must-not-loop-property-${name}`}
    >
      <InputWithLabel
        inputId={`gdsn-export-mappings-must-not-loop-property-${name}`}
        label={
          <span>
            Get value of first child of the list{' '}
            <HelpTooltip
              id={`gdsn-export-mappings-do-not-add-module-property-${name}-help`}
              place="top"
              message={`Must be set to TRUE if we only want to take in account ${listField}.0.${childField}"`}
            />
          </span>
        }
      >
        <Radio
          id={`gdsn-export-mappings-must-not-loop-property-${name}`}
          value={mustNotLoop}
          options={[
            { label: 'TRUE', value: true },
            { label: 'FALSE', value: undefined },
          ]}
          onChange={updateMustNotLoop}
        />
      </InputWithLabel>
    </div>
  );
}
