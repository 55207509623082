import qs from 'querystringify';
import { call, put, takeLatest } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import EtlApi from 'resources/etlApi';
import { logError } from 'utils';

import * as actions from '../actions';
import * as api from '../constants/api';
import * as events from '../constants/events';

export default function* AFSMainSaga() {
  yield takeLatest(events.ATTRIBUTE_LIST_FETCH, listAttributesSaga);
  yield takeLatest(events.ATTRIBUTE_FETCH, getAttributeSaga);
  yield takeLatest(events.SAVE_ATTRIBUTE, saveAttributeSaga);
  yield takeLatest(events.FUNCTION_LIST_FETCH, listFunctionsSaga);
  yield takeLatest(events.FUNCTION_FETCH, getFunctionSaga);
  yield takeLatest(events.CATEGORY_LIST_FETCH, listCategoriesSaga);
  yield takeLatest(events.CATEGORY_FETCH, getCategoryAttributesSaga);
}

function* listAttributesSaga({ attributeSearch }) {
  try {
    let limitReached = false;
    let pageCount = 0;
    let attributeList = [];
    let limit = 500;
    while (!limitReached) {
      const response = yield call(() =>
        EtlApi.get(
          '/etl/v2/afs/list/attributes',
          qs.stringify(
            {
              search: attributeSearch,
              limit: limit,
              offset: pageCount * limit,
            },
            true,
          ),
          false,
        ),
      );
      attributeList = attributeList.concat(
        response.data.data.map((r) => ({ index: r.index, name: r.name })),
      );
      limitReached = response.data.data.length < limit;
      pageCount++;
    }
    yield put({
      type: events.ATTRIBUTE_LIST_RECEIVE,
      attributeList: (attributeList || []).sort((a, b) =>
        a.name > b.name ? 1 : -1,
      ),
    });
  } catch (e) {
    yield put(notificationError('Failed to list attributes'));
    logError(e);
  }
}

function* getAttributeSaga({ selectedAttributeName }) {
  try {
    const response = yield call(() =>
      EtlApi.get(
        '/etl/v2/afs/get/attribute/versions',
        qs.stringify({ name: selectedAttributeName }, true),
        false,
      ),
    );
    yield put({
      type: events.ATTRIBUTE_GET,
      selectedAttributeVersions: response.data.data.map((r) => ({
        id: r.id,
        name: r.name,
        description: r.description,
        version: r.version,
        start_validity: r.start_validity,
        end_validity: r.end_validity,
        function: r.function,
        parameters: r.parameters,
        is_attribute_complete: r.is_attribute_complete,
      })),
    });
  } catch (e) {
    yield put(notificationError('Failed to get attribute version'));
    logError(e);
  }
}

function* listFunctionsSaga({ functionSearch }) {
  try {
    let limitReached = false;
    let pageCount = 0;
    let functionList = [];
    let limit = 500;
    while (!limitReached) {
      const response = yield call(() =>
        EtlApi.get(
          api.ETL_API_LIST_FUNCTIONS,
          qs.stringify(
            { search: functionSearch, limit: limit, offset: pageCount * limit },
            true,
          ),
          false,
        ),
      );
      functionList = functionList.concat(
        response.data.data.map((r) => ({ id: r.id, name: r.name })),
      );
      limitReached = response.data.data.length < limit;
      pageCount++;
    }
    yield put({
      type: events.FUNCTION_LIST_RECEIVE,
      functionList: (functionList || []).sort((a, b) =>
        a.name > b.name ? 1 : -1,
      ),
    });
  } catch (e) {
    yield put(notificationError('Failed to list functions'));
    logError(e);
  }
}

function* getFunctionSaga({ selectedFunctionId }) {
  try {
    const response = yield call(() =>
      EtlApi.get(
        api.ETL_API_GET_FUNCTION,
        qs.stringify({ function_id: selectedFunctionId }, true),
        false,
      ),
    );
    yield put({
      type: events.FUNCTION_GET,
      selectedFunctionDetails: response.data.data.map((r) => ({
        id: r.id,
        name: r.name,
        description: r.description,
      })),
    });
  } catch (e) {
    yield put(notificationError('Failed to get function'));
    logError(e);
  }
}

function* listCategoriesSaga({ categorySearch }) {
  try {
    let limitReached = false;
    let pageCount = 0;
    let categoryList = [];
    let limit = 500;
    while (!limitReached) {
      const response = yield call(() =>
        EtlApi.get(
          '/etl/v2/afs/list/categories',
          qs.stringify(
            { search: categorySearch, limit: limit, offset: pageCount * limit },
            true,
          ),
          false,
        ),
      );
      categoryList = categoryList.concat(
        response.data.data.map((r) => ({ id: r.id, name: r.name })),
      );
      limitReached = response.data.data.length < limit;
      pageCount++;
    }
    yield put({
      type: events.CATEGORY_LIST_RECEIVE,
      categoryList: (categoryList || []).sort((a, b) =>
        a.name > b.name ? 1 : -1,
      ),
    });
  } catch (e) {
    yield put(notificationError('Failed to list categories'));
    logError(e);
  }
}

function* getCategoryAttributesSaga({ selectedCategoryId }) {
  try {
    const response = yield call(() =>
      EtlApi.get(
        '/etl/v2/afs/get/category/attributes',
        qs.stringify({ category_id: selectedCategoryId }, true),
        false,
      ),
    );
    yield put({
      type: events.CATEGORY_GET,
      selectedCategoryAttributeList: response.data.data.map((r) => ({
        id: r.id,
        name: r.name,
        description: r.description,
        version: r.version,
        start_validity: r.start_validity,
        end_validity: r.end_validity,
        is_attribute_complete: r.is_attribute_complete,
      })),
    });
  } catch (e) {
    yield put(notificationError('Failed to get category attributes'));
    logError(e);
  }
}

function* saveAttributeSaga({ editedAttribute }) {
  try {
    yield put({ type: events.SET_SAVE_IN_PROGRESS, saveInProgress: true });
    yield call(() =>
      EtlApi.post(
        '/etl/v2/afs/post/attribute',
        {
          attribute_id: editedAttribute.id,
          ...(editedAttribute.function != null && {
            function_id: editedAttribute.function.id,
          }),
          ...(editedAttribute.parameters != null &&
            editedAttribute.parameters.length > 0 && {
              parameters: editedAttribute.parameters,
            }),
        },
        false,
      ),
    );
    yield put({ type: events.SET_SAVE_IN_PROGRESS, saveInProgress: false });
    yield put(actions.getAttribute(editedAttribute.name));
    yield put(notificationSuccess('Attribute saved'));
  } catch (e) {
    yield put(notificationError('Failed to save attribute'));
    logError(e);
  }
}
