import { useState } from 'react';

import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

import { IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_MUST_RAISE_ERROR } from '../../../../constants';
import { ImportMappingDetails, Property } from '../../../../types';

interface Props {
  details: ImportMappingDetails;
  dispatchImportMapping: (properties: Property[]) => void;
  fieldName: string;
  type: string;
}
export function ImportMappingsItemOptionalPropertyMustRaiseError({
  details,
  dispatchImportMapping,
  fieldName,
  type,
}: Props): JSX.Element {
  const [mustRaiseError, setMustRaiseError] = useState(details.raise_not_found);

  const updateMustRaiseError = (event: { target: { value: string } }) => {
    const importMappingDetailsMustRaiseError =
      event.target.value === 'false' ? false : undefined;
    setMustRaiseError(importMappingDetailsMustRaiseError);
    dispatchImportMapping([
      { name: 'raise_not_found', value: importMappingDetailsMustRaiseError },
    ]);
  };

  return (
    <>
      {IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_MUST_RAISE_ERROR.includes(
        type,
      ) &&
        (type === 'entity' || details.isDeclinable) && (
          <InputWithLabel
            inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-must-raise-error`}
            label="Must raise error if referential code not found"
          >
            <Radio
              id={`gdsn-import-mappings-field-${fieldName}-optional-property-must-raise-error`}
              value={mustRaiseError}
              options={[
                { label: 'TRUE', value: undefined },
                { label: 'FALSE', value: false },
              ]}
              onChange={updateMustRaiseError}
            />
          </InputWithLabel>
        )}
    </>
  );
}
