import { ComponentProps, Key } from 'react';

import { Filter } from '@alkem/react-ui-filter';

import {
  VALIDATION_RULESET_TYPE__DRAFT,
  VALIDATION_RULESET_TYPE__MATURITY,
  VALIDATION_RULESET_TYPE__OTHER,
  VALIDATION_RULESET_TYPE__REGULATORY,
  VALIDATION_RULESET_TYPE__STAGE_GATE,
} from './constants';

export type FilterValue = ComponentProps<typeof Filter>['filters'][number] & {
  id: number;
  key: Key;
};

export function toQueryId(value: FilterValue) {
  return value.id || value.key;
}

export enum ValidationRuleStatus {
  Enabled = 1,
  Disabled = 0,
}

export interface PaginationState {
  totalResults: number;
  currentPage: number;
  totalPages: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
}

export enum ValidationRuleEntityType {
  ConsumerUnit,
  SharingUnit,
  LogisticalUnit,
  LogisticalHierarchy,
  DisplayUnit,
  PriceWaterfall,
  LogisticalHierarchyTopUnit,
  SharingUnits,
  Tariff = 10,
  Picture,
  Document,
  TextileVariant,
  Video,
  Tariffs,
  RfpAnswer,
  Pictures,
  Documents,
  Videos,
}

export const VALIDATION_RULESET_TYPE__LABELS = {
  [VALIDATION_RULESET_TYPE__REGULATORY]: 'REGULATORY',
  [VALIDATION_RULESET_TYPE__MATURITY]: 'MATURITY',
  [VALIDATION_RULESET_TYPE__STAGE_GATE]: 'STAGE_GATE',
  [VALIDATION_RULESET_TYPE__OTHER]: 'OTHER',
  [VALIDATION_RULESET_TYPE__DRAFT]: 'DRAFT',
} as const;

export type ValidationRuleSetType =
  keyof typeof VALIDATION_RULESET_TYPE__LABELS;

export interface ValidationSelectedFilters {
  ruleSetIdIn: FilterValue[];
  entityTypeIn: ValidationRuleEntityType[];
  applicableForKinds?: FilterValue[];
  ruleSetTypeIn: ValidationRuleSetType[];
  onlyActive?: boolean;

  status?: ValidationRuleStatus[];

  usedByRetailerIdIn: FilterValue[];
  sourceOrganizationIds?: FilterValue[];

  appliesOnPkId?: FilterValue;
  appliesOnPkIdIn: FilterValue[];

  hasDocumention?: boolean[];
}

export enum ValidationFieldRoots {
  CONSUMER_UNIT = 'consumerUnit',
  SHARING_UNIT = 'sharingUnit',
  ORGANIZATION = 'organization',
  SUPPLIER = 'supplier',
  PRODUCT_PICTURE = 'productPicture',
  PRODUCT_DOCUMENT = 'productDocument',
  PRODUCT_VIDEO = 'productVideo',
  RFP_EVENT = 'rfpEvent',
  RFP_BU = 'rfpBU',
  RFP_SUPPLIER = 'rfpSupplier',
}

export enum ValidationFieldEntityType {
  CONSUMER_UNIT = 'consumerUnit',
  SHARING_UNIT = 'sharingUnit',
  TARIFF = 'tariff',
  SUPPLIER = 'supplier',
  LOGISTICAL_UNIT = 'logisticalUnit',
  DISPLAY_UNIT = 'displayUnit',
  PRODUCT_PICTURE = 'productPicture',
  PRODUCT_DOCUMENT = 'productDocument',
  PRODUCT_VIDEO = 'productVideo',
  RFP_EVENT = 'rfpEvent',
  RFP_BU = 'rfpBU',
  RFP_SUPPLIER = 'rfpSupplier',
  RFP_ANSWER = 'rfpAnswer',
}

export const fieldEntityTypesMap = [
  {
    key: 'CONSUMER_UNIT',
    value: 'consumerUnit',
    name: 'Consumer unit',
    root: ValidationFieldRoots.CONSUMER_UNIT,
  },
  {
    key: 'SHARING_UNIT',
    value: 'sharingUnit',
    name: 'Sharing unit',
    root: ValidationFieldRoots.SHARING_UNIT,
  },
  {
    key: 'TARIFF',
    value: 'tariff',
    name: 'Tariff',
    root: ValidationFieldRoots.SHARING_UNIT,
  },
  {
    key: 'SUPPLIER',
    value: 'supplier',
    name: 'Supplier',
    root: ValidationFieldRoots.SUPPLIER,
  },
  {
    key: 'LOGISTICAL_UNIT',
    value: 'logisticalUnit',
    name: 'Logistical unit',
    root: ValidationFieldRoots.CONSUMER_UNIT,
  },
  {
    key: 'DISPLAY_UNIT',
    value: 'displayUnit',
    name: 'Display unit',
    root: ValidationFieldRoots.CONSUMER_UNIT,
  },
  {
    key: 'PRODUCT_PICTURE',
    value: 'productPicture',
    name: 'Product Picture',
    root: ValidationFieldRoots.PRODUCT_PICTURE,
  },
  {
    key: 'PRODUCT_DOCUMENT',
    value: 'productDocument',
    name: 'Product Document',
    root: ValidationFieldRoots.PRODUCT_DOCUMENT,
  },
  {
    key: 'PRODUCT_VIDEO',
    value: 'productVideo',
    name: 'Product Video',
    root: ValidationFieldRoots.PRODUCT_VIDEO,
  },
  {
    key: 'RFP_EVENT',
    value: 'rfpEvent',
    name: 'Rfp event',
    root: ValidationFieldRoots.RFP_EVENT,
  },
  {
    key: 'RFP_BU',
    value: 'rfpBU',
    name: 'Rfp business unit',
    root: ValidationFieldRoots.RFP_BU,
  },
  {
    key: 'RFP_SUPPLIER',
    value: 'rfpSupplier',
    name: 'Rfp supplier',
    root: ValidationFieldRoots.RFP_SUPPLIER,
  },
  {
    key: 'RFP_ANSWER',
    value: 'rfpAnswer',
    name: 'Rfp answer',
    root: ValidationFieldRoots.SHARING_UNIT,
  },
];

export interface Organization {
  id: number;
  nameLegal: string;
  network: {
    id: number;
    name: string;
  };
  type: number;
  status: number;
}

export interface RuleSet {
  id: number;
  label: string;
  type: ValidationRuleSetType;
  displayable: boolean;
  deadline_delta?: number;
  payload: any;
  tags: string[];
  restrictionType?: number;
  linked_organization_ids?: number[];
  rule_ids?: number[];
  linked_organizations?: Organization[];
  rules?: ReadOnlyRule[];
}

export interface RuleSelector {
  id: string;
  key: string;
  value: string;
  usedToDisplayErrors?: boolean;
}

export interface ReadOnlyRule {
  // rule identification
  id: number;
  name?: string;
  ruleSet?: RuleSet;

  // rule params
  selectors: { [key: string]: string };
  conditionSelectors: { [key: string]: string };
  constants: { [key: string]: string };
  condition?: string;
  validation: string;

  // rule description
  documentation?: string;
  errorLabel?: string;
  errorLabels?: string[];
  errorMessage: string;
  translatedErrorMessage: string;

  // rule state
  status: ValidationRuleStatus;
  bypassable: boolean;

  // rule application
  ruleEntityType: ValidationRuleEntityType;
  entityType?: ValidationRuleEntityType;
  fieldEntityType?: ValidationFieldEntityType;
  shouldHideFieldIfBypassed: number;

  // on what "kind" dimension of (product or other entity) this rule will apply ?
  applicableForKinds: { id: number }[];
  applicableForTypePackagings: { label?: string; id: number }[];
  packagingTypes: number[];
  kindsAreManuallySelected: boolean;

  template?: {
    field?: { name: string | null };
    label: string;
    type: string;
  };

  // retailer specific informations
  organizations?: {
    organization_id: number;
    is_solvable: true;
    packagingTypes: number[];
    restrictionType: number;
    ruleset_ids?: number[];
  }[];

  root_fields?: string[];
  condition_root_fields?: string[];

  // field metadata
  metadata: {
    // context in which this field is available
    fields_raw_applicability: FieldsRawApplicability;
  };

  // dates
  createdAt?: string;
  updatedAt?: string;
  indexedAt?: string;
}

export interface RuleIDsInRuleset {
  ruleIds: string[] | number[];
  rulesetId: string | number;
}

export interface FieldsRawApplicability {
  // for which kind (of product) this field is available, independently of the retailer
  generic_kind_ids: number[];
  // for which packaging type this field is available, independently of the retailer
  generic_packaging_type_ids: number[];
}

export interface RuleSetTag {
  id: number;
  code: string;
  label: string;
  description: string;
}

export interface Rule extends ReadOnlyRule {
  constantList: RuleSelector[];
  selectorList: RuleSelector[];
}
