import qs from 'querystringify';

import { notificationError, notificationSuccess } from 'actions/notifications';
import AuthAPI, { immutableAuthApi } from 'resources/authApi';
import { logError } from 'utils';

import * as events from '../constants/events';
import { selectCurrentPage, selectLimit } from '../selectors/users';

const computeOffset = (state) => {
  const limit = selectLimit(state);
  const currentPage = selectCurrentPage(state);
  return limit * (currentPage - 1);
};

export const setLoading = (isLoading) => ({
  type: events.SET_LOADING,
  isLoading,
});

export const setModalOpen = (isModalOpen) => ({
  type: events.SET_MODAL_OPEN,
  isModalOpen,
});

export const resetModal = () => ({
  type: events.RESET_MODAL,
});

export const getUsers = (organizationId) => async (dispatch, getState) => {
  const state = getState();
  try {
    const response = await immutableAuthApi.OrganizationUsersV2(
      organizationId,
      qs.stringify(
        {
          with_not_accepted: true,
          with_inactive: true,
          limit: selectLimit(state),
          offset: computeOffset(state),
        },
        true,
      ),
    );
    dispatch({
      type: events.RECEIVE_USERS,
      users: response.data.data,
      totalUsers: response.data.totalResults,
    });
  } catch (exception) {
    logError(exception);
    dispatch(
      notificationError(
        "Something went wrong while retrieving organization's users",
      ),
    );
  }
};

export const nextPage = () => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: events.USERS_SET_PAGE,
    page: selectCurrentPage(state) + 1,
  });
};

export const previousPage = () => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: events.USERS_SET_PAGE,
    page: selectCurrentPage(state) - 1,
  });
};

export const passwordCheck = (password) => async (dispatch) => {
  try {
    await AuthAPI.post('/auth/v2/user/password/validate/public', {
      password,
    });
    dispatch({
      type: events.PASSWORD_CHECK,
      passwordErrors: null,
    });
  } catch (e) {
    let passwordErrors = [];
    e.data?.data?.forEach((element) => {
      const explicitError = {
        header: element.header,
        rules: element.rules.filter((rule) => !rule.valid),
      };
      if (explicitError.rules.length > 0) {
        passwordErrors = passwordErrors.concat(explicitError);
      }
    });

    dispatch({
      type: events.PASSWORD_CHECK,
      passwordErrors,
    });
  }
};

export const usernameCheck = (username) => async (dispatch) => {
  const {
    data: {
      data: { valid, message },
    },
  } = await AuthAPI.post('/auth/v2/user/email/validate', {
    email: username,
  });

  dispatch({
    type: events.USERNAME_CHECK,
    usernameErrors: !valid && message,
  });
};

export const userCheck = (user) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await AuthAPI.get(
      `/auth/v2/user?filter_username=${encodeURIComponent(user)}`,
    );

    dispatch({
      type: events.USER_CHECK,
      users: data,
    });
  } catch (e) {
    logError(e);
    dispatch(
      notificationError(
        e?.data?.data?.message ||
          "Something went wrong while checking user's organization",
        'modal',
      ),
    );
  }
};

export const addUserToOrganization =
  ({ userId, organizationId }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await AuthAPI.post('/auth/v3/organization/add_user', {
        user: { id: userId },
        organization: { id: organizationId },
      });
      dispatch(notificationSuccess('User has been added to organization'));
      dispatch(setModalOpen(false));
      dispatch(resetModal());
    } catch (e) {
      logError(e);
      dispatch(
        notificationError(
          e?.data?.data?.message ||
            'Something went wrong while adding user to organization',
          'modal',
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createUser =
  ({ username, password, organizationId }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await AuthAPI.post('/auth/v2/user', {
        username,
        password,
        belongsTo: { id: organizationId, permission: 'admin' },
      });
      dispatch(notificationSuccess('User has been created'));
      dispatch(getUsers(organizationId));
      dispatch(setModalOpen(false));
      dispatch(resetModal());
    } catch (e) {
      logError(e);
      if (e.data?.message) {
        dispatch(notificationError(e.data.message, 'modal'));
      }
      e.data?.data?.body?.forEach((element) =>
        dispatch(
          notificationError(
            {
              header: element.header,
              rules: element.rules.filter((rule) => !rule.valid),
            },
            'modal',
          ),
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
