import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AFS_OPTIONS } from '../../constants/constants';

import './left-panel.scss';

export default class LeftPanel extends PureComponent {
  static propTypes = {
    attributeList: PropTypes.array,
    functionList: PropTypes.array,
    categoryList: PropTypes.array,
    currentOption: PropTypes.object.isRequired,
    selectAttribute: PropTypes.func.isRequired,
    selectFunction: PropTypes.func.isRequired,
    selectCategory: PropTypes.func.isRequired,
  };

  renderAttributeList(attributes) {
    if (attributes) {
      return (
        <div>
          <div className="LeftPanelTree">
            {attributes.map((e) => (
              <div
                key={`mapper-afs-attribute-${e.index}`}
                onClick={() => this.props.selectAttribute(e.name)}
              >
                {e.name}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="LeftPanelTree"></div>
      </div>
    );
  }

  renderFunctionList(functions) {
    if (functions) {
      return (
        <div>
          <div className="LeftPanelTree">
            {functions.map((e) => (
              <div
                key={`mapper-afs-function-${e.id}`}
                onClick={() => this.props.selectFunction(e.id)}
              >
                {e.name}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="LeftPanelTree"></div>
      </div>
    );
  }

  renderCategoryList(categories) {
    if (categories) {
      return (
        <div>
          <div className="LeftPanelTree">
            {categories.map((e) => (
              <div
                key={`mapper-afs-category-${e.id}`}
                onClick={() => this.props.selectCategory(e.id)}
              >
                {e.name}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="LeftPanelTree"></div>
      </div>
    );
  }

  render() {
    const { attributeList, functionList, categoryList, currentOption } =
      this.props;
    if (currentOption.value === AFS_OPTIONS.ATTRIBUTES) {
      return this.renderAttributeList(attributeList);
    }
    if (currentOption.value === AFS_OPTIONS.FUNCTIONS) {
      return this.renderFunctionList(functionList);
    }
    if (currentOption.value === AFS_OPTIONS.CATEGORIES) {
      return this.renderCategoryList(categoryList);
    }
    return null;
  }
}
