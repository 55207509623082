import { flow, get } from 'lodash/fp';

import { REDUCER_KEY } from '../constants';
import { GDSNExportMappingsState } from '../types';

const selectState: (globalState: any) => GDSNExportMappingsState = get([
  'module',
  REDUCER_KEY,
]);

export const selectCustomParseOptions = flow(
  selectState,
  (state) => state.customParseOptions,
);
export const selectEditionId = flow(selectState, (state) => state.editionId);
export const selectFunctionForValuesOptions = flow(
  selectState,
  (state) => state.functionForValuesOptions,
);
export const selectFunctionForConstantsOptions = flow(
  selectState,
  (state) => state.functionForConstantsOptions,
);
export const selectFunctionForConditionsOptions = flow(
  selectState,
  (state) => state.functionForConditionsOptions,
);
export const selectTargetGlnOptions = flow(
  selectState,
  (state) => state.targetGlnOptions,
);
export const selectSelectedExportMapping = flow(
  selectState,
  (state) => state.selectedExportMapping,
);
export const selectExportMappings = flow(
  selectState,
  (state) => state.exportMappings,
);
export const selectReorderingId = flow(
  selectState,
  (state) => state.reorderingId,
);
export const selectListFieldOptions = flow(
  selectState,
  (state) => state.listFieldOptions,
);
export const selectFieldOptions = flow(
  selectState,
  (state) => state.fieldOptions,
);
export const selectReferentialCodeOptions = flow(
  selectState,
  (state) => state.referentialCodeOptions,
);
export const selectXpathList = flow(selectState, (state) => state.xpathList);
