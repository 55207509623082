import { useState } from 'react';

import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { ExportMapping } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

interface Props {
  name: string;
  exportMapping: ExportMapping;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemUseGetAttrRecWithChildrenProperty({
  name,
  exportMapping,
  dispatchProperty,
}: Props) {
  const [useGetAttrRecWithChildren, setUseGetAttrRecWithChildren] = useState<
    true | undefined
  >(exportMapping.payload.use_get_attr_rec_with_children);

  const updateUseGetAttrRecWithChildren = (event: {
    target: { value: string };
  }) => {
    const { value } = event.target;
    const updatedUseGetAttrRecWithChildren = value === 'true' || undefined;
    setUseGetAttrRecWithChildren(updatedUseGetAttrRecWithChildren);
    dispatchProperty(
      'use_get_attr_rec_with_children',
      updatedUseGetAttrRecWithChildren,
    );
  };

  return (
    <div
      className={styles.property}
      data-testid={`gdsn-export-mappings-use-get-attr-rec-with-children-property-${name}`}
    >
      <InputWithLabel
        inputId={`gdsn-export-mappings-use-get-attr-rec-with-children-property-${name}`}
        label="Get value in the children of the hierarchy if not found at first level"
      >
        <Radio
          id={`gdsn-export-mappings-use-get-attr-rec-with-children-property-${name}`}
          value={useGetAttrRecWithChildren}
          options={[
            { label: 'TRUE', value: true },
            { label: 'FALSE', value: undefined },
          ]}
          onChange={updateUseGetAttrRecWithChildren}
        />
      </InputWithLabel>
    </div>
  );
}
