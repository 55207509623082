import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import * as routes from 'constants/routes';

import GDSNCIListFilters from '../filters';
import GDSNCIList from '../list';
import { selectFiltersDirection } from '../selectors';

import './view.scss';

const mapStateToProps = (state) => ({
  direction: selectFiltersDirection(state),
});

export class GDSNCIDashboardView extends PureComponent {
  static propTypes = {
    direction: PropTypes.string.isRequired,
  };

  render() {
    const message = this.props.direction === 'read' ? 'Import' : 'Export';
    const headerProps = {
      title: `GDSN Products: ${message}`,
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };

    return (
      <div className="GDSNCIDashboardView__body">
        <HeaderLayout {...headerProps} />
        <div className="container-fluid row">
          <div className="col-md-3">
            <GDSNCIListFilters />
          </div>
          <div className="col-md-9">
            <GDSNCIList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(GDSNCIDashboardView);
