import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

class ListInput extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired, // formally named 'state'
    label: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      newParameter: { index: null, param: null },
      newParameterIndexIsValid: false,
      newParameterValueIsValid: false,
      updatedParameters: this.props.list,
    };
  }

  onUpdateNewIndex = (evt) => {
    const newIndex = evt.target.value;
    this.setState((state) => ({
      newParameter: { ...state.newParameter, index: newIndex },
      newParameterIndexIsValid: this.checkParameterIndex(newIndex),
    }));
  };

  onUpdateNewParam = (evt) => {
    const newValue = evt.target.value;
    const { newParameter } = this.state;
    this.setState({
      newParameter: { ...newParameter, param: newValue },
      newParameterValueIsValid: this.checkParameterValue(newValue),
    });
  };

  onAdd = () => {
    const { newParameter } = this.state;
    if (
      this.checkParameterIndex(newParameter.index) &&
      this.checkParameterValue(newParameter.param)
    ) {
      this.setState((state) => {
        const updatedParameters = state.updatedParameters
          .concat(state.newParameter)
          .sort((a, b) => (a.index > b.index ? 1 : -1));
        this.props.onUpdate(updatedParameters);
        return {
          updatedParameters,
          newParameter: { index: null, param: null },
          newParameterIndexIsValid: false,
          newParameterValueIsValid: false,
        };
      });
    }
  };

  onSelectorKeyPress = (event) => {
    if (event.charCode === 13) {
      // on press enter
      this.onAdd();
    }
  };

  onRemove = (key) => () => {
    this.setState((state) => {
      const updatedParameters = state.updatedParameters.filter(
        (p) => p.index !== key,
      );
      this.props.onUpdate(updatedParameters);
      return {
        updatedParameters,
      };
    });
  };

  checkParameterValue = (parameterValue) => {
    if (parameterValue === null || parameterValue.length === 0) {
      return false;
    }
    return true;
  };

  checkParameterIndex = (parameterIndex) => {
    if (parameterIndex === null || parameterIndex.length === 0) {
      return false;
    }
    if (!this.isNormalInteger(parameterIndex)) {
      return false;
    }
    if (
      this.state.updatedParameters.filter((e) => e.index === parameterIndex)
        .length > 0
    ) {
      return false;
    }
    return true;
  };

  isNormalInteger(str) {
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  render() {
    const { label } = this.props;
    const {
      newParameter,
      newParameterIndexIsValid,
      newParameterValueIsValid,
      updatedParameters,
    } = this.state;
    return (
      <div className="ListInput">
        <InputWithLabel inputId={`list-input-modal-${label}`} label={label}>
          {(updatedParameters || []).map((p) => {
            return (
              <div
                key={JSON.stringify(p)}
                className="flex flex-align-items--center ListInputModal__validationField"
              >
                <div className="flex-grow--1 flex flex-align-items--center ListInputModal__validationField__data">
                  <code>{p.index}</code>:<pre>{JSON.stringify(p.param)}</pre>
                </div>
                <span
                  className={classNames('ListInputModal__actionIcon mdi', {
                    'mdi-delete': true,
                  })}
                  onClick={this.onRemove(p.index)}
                />
              </div>
            );
          })}
          <div className="flex flex-align-items--center">
            <div className="ListInputModal__index">
              <Text
                id={`new-index-input-${label}`}
                placeholder="Index, e.g. 1"
                onChange={this.onUpdateNewIndex}
                value={newParameter.index}
                onKeyPress={this.onSelectorKeyPress}
                invalid={!newParameterIndexIsValid || !newParameterValueIsValid}
              />
            </div>
            <div className="ListInputModal__value">
              <Text
                id={`new-value-input-${label}`}
                placeholder="Value, e.g. battery"
                onChange={this.onUpdateNewParam}
                value={newParameter.param}
                onKeyPress={this.onSelectorKeyPress}
              />
            </div>
            <Button
              content="Add"
              onClick={this.onAdd}
              primary
              disabled={!newParameterIndexIsValid || !newParameterValueIsValid}
            />
          </div>
        </InputWithLabel>
      </div>
    );
  }
}

export default ListInput;
