import classNames from 'classnames';
import { getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import { ListColumn as Column, ListRow as Row } from '@alkem/react-ui-list';
import { Tooltip } from '@alkem/react-ui-tooltip';

import TimeAgo from 'components/timeago';
import * as routes from 'constants/routes';
import { addFilter } from 'modules/user-list/actions';
import { fill } from 'utils/routes';

import { openOrganizationDeactivateModal } from '../../../organization-deactivate/actions';
import {
  OrganizationStatusActive,
  OrganizationStatusInactive,
} from '../../constants/statuses';
import { OrganizationTypes } from '../../constants/types';
import {
  getOrganizationId,
  getOrganizationNameLegal,
  getOrganizationNetwork,
  getOrganizationStatus,
  getOrganizationType,
  getOrganizationUsersNb,
} from '../../utils';

import './row.scss';

const mapDispatchToProps = {
  openOrganizationDeactivateModal,
  filterUserList: addFilter,
};

class OrganizationRow extends Component {
  static propTypes = {
    filterUserList: PropTypes.func.isRequired,
    isFirst: PropTypes.bool,
    openOrganizationDeactivateModal: PropTypes.func,
    organization: ImmutablePropTypes.map.isRequired,
  };

  state = {
    redirectToDetailsPage: false,
  };

  onDeactivateOrganization = () => {
    this.props.openOrganizationDeactivateModal({
      payload: this.props.organization,
    });
  };

  onGoToUserList = () => {
    const { filterUserList, organization } = this.props;
    filterUserList(
      'organizations',
      getOrganizationId(organization),
      true,
      getOrganizationNameLegal(organization),
    );
  };

  getOrganizationTypeLabel(type) {
    if (Object.keys(OrganizationTypes).includes(type.toString())) {
      return OrganizationTypes[type].name;
    }
    return 'Unknown';
  }

  redirectToDetailsPage = (e) => {
    if (getOr('', 'target.className', e).includes('ListRowColumn')) {
      this.setState({ redirectToDetailsPage: true });
    }
  };

  isDeactivated() {
    return getOrganizationStatus(this.props.organization) === 0;
  }

  renderHeader(headerName) {
    return <span className="OrganizationRow__header">{headerName}</span>;
  }

  renderAction(organizationId, organizationStatus, detailsPageRoute) {
    let actionButtons = null;

    if (organizationStatus === OrganizationStatusInactive.id) {
      actionButtons = (
        <button
          type="button"
          className="OrganizationRow__btn OrganizationRow__btn--success"
          onClick={this.onDeactivateOrganization}
          data-tip
          data-for={`org-row-deactivate-${organizationId}`}
        >
          <i className="mdi mdi-checkbox-marked-circle" />
          <Tooltip id={`org-row-activate-${organizationId}`} place="top">
            Activate
          </Tooltip>
        </button>
      );
    } else {
      actionButtons = (
        <>
          <button
            type="button"
            className="OrganizationRow__btn OrganizationRow__btn--warn"
            onClick={this.onDeactivateOrganization}
            data-tip
            data-for={`org-row-deactivate-${organizationId}`}
          >
            <i className="mdi mdi-close-circle" />
            <Tooltip id={'org-row-deactivate-${organizationId}'} place="top">
              Deactivate
            </Tooltip>
          </button>
        </>
      );
    }

    return (
      <>
        {actionButtons}
        <Link className="OrganizationRow__btn" to={detailsPageRoute}>
          <button type="button">
            <i
              className="mdi mdi-eye"
              data-tip
              data-for={`org-row-view-${organizationId}`}
            />
            <Tooltip id={`org-row-view-${organizationId}`} place="top">
              View
            </Tooltip>
          </button>
        </Link>
      </>
    );
  }

  render() {
    const { organization, isFirst } = this.props;
    const { redirectToDetailsPage } = this.state;

    const detailsPageRoute = fill(routes.organization, organization.get('id'));

    if (redirectToDetailsPage) {
      return <Navigate to={detailsPageRoute} />;
    }

    const orgId = getOrganizationId(organization);
    const numberOfUsers = getOrganizationUsersNb(organization);
    const network = getOrganizationNetwork(organization);

    const organizationStatus = getOrganizationStatus(this.props.organization);

    const rowClasses = {
      OrganizationRow: true,
      'OrganizationRow--first': isFirst,
      'OrganizationRow--disabled':
        organizationStatus !== OrganizationStatusActive.id,
    };

    return (
      <Row
        className={classNames(rowClasses)}
        onMouseDown={this.redirectToDetailsPage}
      >
        <div
          className={classNames(
            'OrganizationRow__status',
            `OrganizationRow__status--${organizationStatus}`,
          )}
        />
        <Column className="OrganizationRow__label">
          {isFirst && this.renderHeader('Organization / id')}
          <span className="OrganizationRow__name">
            {getOrganizationNameLegal(organization)}
          </span>
          <span className="OrganizationRow__id">{orgId}</span>
        </Column>
        <Column className="OrganizationRow__type">
          {isFirst && this.renderHeader('Type')}
          <span>
            {this.getOrganizationTypeLabel(getOrganizationType(organization))}
          </span>
        </Column>
        <Column className="OrganizationRow__users">
          {isFirst && this.renderHeader('Users info')}
          <Link
            to={routes.users}
            onClick={this.onGoToUserList}
          >{`${numberOfUsers} ${numberOfUsers > 1 ? 'users' : 'user'}`}</Link>
        </Column>
        <Column className="OrganizationRow__networks">
          {isFirst && this.renderHeader('Network')}
          <span>{network.get('name')}</span>
        </Column>
        <Column className="OrganizationRow__lastActivity">
          {isFirst && this.renderHeader('Last connection')}
          <TimeAgo time={organization.get('lastLoggedIn')} />
        </Column>
        <Column className="OrganizationRow__action">
          {this.renderAction(orgId, organizationStatus, detailsPageRoute)}
        </Column>
      </Row>
    );
  }
}

export default connect(null, mapDispatchToProps)(OrganizationRow);
