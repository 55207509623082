import { List } from 'immutable';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { selectHasSettingFlagChanges } from 'modules/settings/organization/selectors';

import { MAIN_REDUCER_KEY, ORGANIZATION_PAGE_STORE_KEY } from '../constants';

import { hasApplicationsChanges } from './applications';
import { hasBillingChanges } from './billing';
import { hasChange as infosHasChange } from './infos';
import { selectHasPermissionChanges } from './permissions';
import { hasChange as recipientsHasChange } from './recipients';
import { hasChange as settingsHasChange } from './settings';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_PAGE_STORE_KEY][
    MAIN_REDUCER_KEY
  ];
}

export function selectIsOrganizationLoaded(state): boolean {
  return selectState(state).get('organizationLoaded');
}

export function selectManagedOrganizationPermissions(state): List<string> {
  return selectState(state).get('managedOrganizationPermissions');
}

export function isSaveButtonAvailable(state) {
  return (
    infosHasChange(state) ||
    settingsHasChange(state) ||
    recipientsHasChange(state) ||
    hasApplicationsChanges(state) ||
    hasBillingChanges(state) ||
    selectHasSettingFlagChanges(state) ||
    selectHasPermissionChanges(state)
  );
}
