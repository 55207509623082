import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import * as routes from 'constants/routes';

import GDSNCISListFilters from '../filters';
import GDSNCISList from '../list';

export class GDSNCISDashboardView extends PureComponent {
  render() {
    const headerProps = {
      title: 'GDSN Subscription (CIS)',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };

    return (
      <div className="GDSNCISDashboardView__body">
        <HeaderLayout {...headerProps} />
        <div className="container-fluid row">
          <div className="col-md-3">
            <GDSNCISListFilters />
          </div>
          <div className="col-md-9">
            <GDSNCISList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(GDSNCISDashboardView);
