import { Condition, XpathList } from 'modules/io/gdsn/exports/types';

import { ExportMappingsItemDoesModuleNameMatchCompositionCondition } from '../does-module-name-match-composition-condition';
import { ExportMappingsItemFunctionCondition } from '../function-condition';
import { ExportMappingsItemLambdaCondition } from '../lambda-condition';
import { ExportMappingsItemNotEmptyModuleCondition } from '../not-empty-module-condition';
import { ExportMappingsItemRulesBypassedCondition } from '../rules-bypassed-condition';
import { ExportMappingsItemSameLevelGDSNFieldExistsCondition } from '../same-level-gdsn-field-exists-condition';
import { ExportMappingsItemSameLevelSxmFieldsDontExistCondition } from '../same-level-sxm-fields-dont-exist-condition';
import { ExportMappingsItemSameLevelSxmFieldsExistCondition } from '../same-level-sxm-fields-exist-condition';
import { ExportMappingsItemTargetGlnsInCondition } from '../target-glns-in-condition';
import { ExportMappingsItemTargetGlnsNotInCondition } from '../target-glns-not-in-condition';

import styles from './condition.module.scss';

interface Props {
  index: number;
  condition: Condition;
  updateCondition: (index: number, condition: Condition) => void;
  deleteCondition: (index: number) => void;
  sameLevelElementChildren?: XpathList;
  name: string;
  disabled?: boolean;
}
export function ExportMappingsItemCondition({
  index,
  condition,
  updateCondition,
  deleteCondition,
  sameLevelElementChildren,
  name,
  disabled,
}: Props) {
  return (
    <div
      className={styles.container}
      data-testid={`gdsn-export-mappings-item-${name}`}
    >
      <div className={styles.buttonContainer}>
        <i
          className="mdi mdi-trash-can"
          onClick={() => deleteCondition(index)}
        />
      </div>
      {condition.cast === 'function' && (
        <ExportMappingsItemFunctionCondition
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          name={name}
        />
      )}
      {condition.cast === 'lambda' && (
        <ExportMappingsItemLambdaCondition
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          name={name}
        />
      )}
      {condition.cast === 'does_module_name_match_composition' && (
        <ExportMappingsItemDoesModuleNameMatchCompositionCondition
          name={name}
        />
      )}
      {condition.cast === 'rules_bypassed' && (
        <ExportMappingsItemRulesBypassedCondition
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          name={name}
        />
      )}
      {condition.cast === 'target_glns' && (
        <ExportMappingsItemTargetGlnsInCondition
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          name={name}
        />
      )}
      {condition.cast === 'not_target_glns' && (
        <ExportMappingsItemTargetGlnsNotInCondition
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          name={name}
        />
      )}
      {condition.cast === 'same_level_gdsn_field_exists' && (
        <ExportMappingsItemSameLevelGDSNFieldExistsCondition
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          sameLevelElementChildren={sameLevelElementChildren}
          name={name}
          disabled={disabled}
        />
      )}
      {condition.cast === 'same_level_fields_exist' && (
        <ExportMappingsItemSameLevelSxmFieldsExistCondition
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          name={name}
        />
      )}
      {condition.cast === 'same_level_fields_dont_exist' && (
        <ExportMappingsItemSameLevelSxmFieldsDontExistCondition
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          name={name}
        />
      )}
      {condition.cast === 'not_empty_module' && (
        <ExportMappingsItemNotEmptyModuleCondition name={name} />
      )}
    </div>
  );
}
