import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

import styles from './does-module-name-match-composition-condition.module.scss';

interface Props {
  name: string;
}
export function ExportMappingsItemDoesModuleNameMatchCompositionCondition({
  name,
}: Props) {
  return (
    <InputWithLabel
      inputId={`gdsn-export-mappings-does-module-name-match-composition-${name}`}
      label={
        <span>
          Module name match composition{' '}
          <HelpTooltip
            id={`gdsn-export-mappings-does-module-name-match-composition-${name}-help`}
            place="top"
            message={
              <div className={styles.messageContainer}>
                <span>
                  For the composition modules, the kind must match certain IDs
                  or be descendant of them for the condition to be fullfilled:
                </span>
                <span>
                  {'   '}- 80727 or 100975: food_and_beverage_ingredient
                </span>
                <span>{'   '}- 80734: animal_feedin</span>
                <span>{'   '}- other IDs: nonfood_ingredient</span>
              </div>
            }
          />
        </span>
      }
    >
      <div
        data-testid={`gdsn-export-mappings-does-module-name-match-composition-${name}`}
      >
        <Radio
          id={`gdsn-export-mappings-does-module-name-match-composition-${name}`}
          value={true}
          options={[{ label: 'True', value: true }]}
          onChange={() => {}}
          disabled
        />
      </div>
    </InputWithLabel>
  );
}
