import { set } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import { ActionWithPayload } from 'utils/reducers/types';

import * as events from './events';
import * as types from './types';

export const REDUCER_KEY = 'translation';

const getInitialState = (): {
  filters: types.Filters | null;
  selectedFilters: types.SelectedFilters;
  files: types.File[] | null;
  viewedFile: types.ViewedFile | null;
  pagination: types.PageRequest;
} => ({
  filters: null,
  selectedFilters: {
    current: null,
    filename: null,
    lang: null,
  },
  files: null,
  viewedFile: null,
  pagination: { page: 0, size: 20 },
});

export default createReducer(getInitialState(), {
  [events.INIT]: () => getInitialState(),
  [events.RECEIVE_FILTERS]: (
    state,
    { payload: filters }: ActionWithPayload<types.Filters>,
  ) => set(['filters'], filters, state),
  [events.UPDATE_SELECTED_CURRENT]: (
    state,
    { payload: current }: ActionWithPayload<boolean | null>,
  ) => set(['selectedFilters', 'current'], current, state),
  [events.UPDATE_SELECTED_FILENAME]: (
    state,
    { payload: filename }: ActionWithPayload<string | null>,
  ) => set(['selectedFilters', 'filename'], filename, state),
  [events.UPDATE_SELECTED_LANG]: (
    state,
    { payload: lang }: ActionWithPayload<string | null>,
  ) => set(['selectedFilters', 'lang'], lang, state),
  [events.RECEIVE_FILES]: (
    state,
    { payload: files }: ActionWithPayload<types.File[]>,
  ) => set(['files'], files, state),
  [events.FETCHED_ONE_FILE]: (
    state,
    { payload: file }: ActionWithPayload<types.ViewedFile>,
  ) => set(['viewedFile'], file, state),
  [events.DISCARD_ONE_FILE]: (state) => set(['viewedFile'], null, state),
  [events.NEXT_FILES_PAGE]: (state) =>
    set(['pagination', 'page'], state.pagination.page + 1, state),
  [events.PREVIOUS_FILES_PAGE]: (state) =>
    set(['pagination', 'page'], state.pagination.page - 1, state),
  [events.CHANGE_FILES_PAGE_SIZE]: (
    state,
    { payload: size }: ActionWithPayload<number>,
  ) => set(['pagination', 'size'], size, state),
});
