import proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { authApi } from 'resources/authApi';

import './show-organization.scss';

export function ShowOrganization({ id }) {
  const ORGANIZATION_STATUSES = {
    0: 'Inactive',
    1: 'Active',
    2: 'Organization configuration in progress',
    3: 'Decommissioned organization, cannot be reactivated',
  };

  const ORGANIZATION_TYPES = {
    1: 'Manufacturer',
    2: 'Retailer',
    3: 'Private label (MDD)',
    4: 'Third party',
    5: 'White label (LeclercWL-M)',
  };
  const [organization, setOrganization] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }

      setIsLoading(true);
      const callOrganizationApi = async () => {
        try {
          const response = await authApi.OrganizationShow(
            id,
            {
              settings: true,
              status: true,
              network: true,
              group: true,
              glnData: true,
            },
            true,
          );
          return response.data.data;
        } catch (exception) {
          return;
        }
      };

      const organizationResult = await callOrganizationApi();
      setOrganization(organizationResult);

      setIsLoading(false);
    };
    init();
  }, [id]);

  if (isLoading) {
    return <div>{`Fetching information for organization with id: ${id}`}</div>;
  }

  return (
    <div>
      {organization ? (
        <div className="ShowOrganization__container">
          <div className="ShowOrganization__containerRow">
            <span className="ShowOrganization__fieldLabel">ID:</span>
            <span className="ShowOrganization__fieldResult">
              {organization.id}
            </span>
          </div>
          <div className="ShowOrganization__containerRow">
            <span className="ShowOrganization__fieldLabel">Name:</span>
            <span className="ShowOrganization__fieldResult">
              {organization.nameLegal}
            </span>
          </div>
          <div className="ShowOrganization__containerRow">
            <span className="ShowOrganization__fieldLabel">Type:</span>
            <span className="ShowOrganization__fieldResult">
              {ORGANIZATION_TYPES[organization.type]}
            </span>
          </div>
          <div className="ShowOrganization__containerRow">
            <span className="ShowOrganization__fieldLabel">Status:</span>
            <span className="ShowOrganization__fieldResult">
              {ORGANIZATION_STATUSES[organization.status]}
            </span>
          </div>
          <div className="ShowOrganization__containerRow">
            <span className="ShowOrganization__fieldLabel">Network:</span>
            <span className="ShowOrganization__fieldResult">
              {organization.network.name}
            </span>
          </div>
          <div className="ShowOrganization__containerRow">
            <span className="ShowOrganization__fieldLabel">
              Target Markets:
            </span>
            <span className="ShowOrganization__fieldResult">
              {organization.worksOnTargetMarkets
                .map((tm) => tm.label)
                .join(', ')}
            </span>
          </div>
        </div>
      ) : (
        `no organization found for id: ${id}`
      )}
    </div>
  );
}

ShowOrganization.propTypes = {
  id: proptypes.string,
};
