import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { commands } from '../commands';
import { get_first_exact_prefix_match } from '../utils';

import { CommandHistoryItem } from './history';
import './palette.scss';
import { Result } from './result';
import { SearchBar } from './search-bar';
import { Suggestion } from './suggestion';

export function CommandPalette() {
  const KEY_CODE_ARROW_DOWN = 40;
  const KEY_CODE_ARROW_UP = 38;
  const KEY_CODE_ENTER = 13;
  const KEY_CODE_TAB = 9;

  const RESULTS_PANEL = 1;
  const SUGGESTIONS_PANEL = 2;
  const HISTORY_PANEL = 3;

  const [showPallete, setShowPallete] = useState(false);
  const [commandText, setCommandText] = useState('');
  const [currentPanel, setCurrentPanel] = useState(RESULTS_PANEL);

  const [results, setResults] = useState([]);
  const [history, setHistory] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [focusedIndex, setFocusedIndex] = useState(null);
  const [autocompleteSuggestion, setAutocompleteSuggestion] = useState('');

  const fuse = useMemo(() => new Fuse(commands, { keys: ['command'] }), []);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.altKey && event.metaKey && event.code === 'KeyP') {
        setShowPallete(true);
        event.preventDefault();
      } else if (event.code === 'Escape') {
        setShowPallete(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const splitCommandText = (cmdText) => {
    const coreCommandText = cmdText
      .split(' ')
      .filter((word) => !word.includes(':'))
      .join(' ');
    const command = commands.find((cmd) => cmd.command === coreCommandText);
    const params = cmdText
      .split(' ')
      .filter((word) => word.includes(':'))
      .reduce((acc, word) => {
        const [id, value] = word.split(':');
        acc[id] = value;
        return acc;
      }, {});
    return [command, params];
  };

  const submitCommand = useCallback(
    (commandText) => {
      setCurrentPanel(RESULTS_PANEL);

      // IDENTIFY
      const [command, params] = splitCommandText(commandText);

      if (commandText === 'history') {
        setCurrentPanel(HISTORY_PANEL);
        setSuggestions([]);
      }

      if (commandText === 'clear') {
        setResults([]);
      }

      if (command?.action || command?.render) {
        setHistory([commandText, ...history]);
      }

      // ACTION
      if (command?.action) {
        const actionResult = command.action(params);
        if (!actionResult) {
          setShowPallete(false);
        }
      }

      // RENDER
      if (command?.render) {
        setResults([
          {
            component: command.render(params),
            query: commandText,
            timestamp: Date.now(),
          },
          ...results,
        ]);
      }

      // RESET INPUT
      setCommandText('');
      setAutocompleteSuggestion('');
    },
    [results, history],
  );

  const handleSearchBarKeyDown = useCallback(
    (event) => {
      if (event.keyCode === KEY_CODE_ARROW_DOWN) {
        event.preventDefault();
        const lastIndex =
          currentPanel === HISTORY_PANEL
            ? history.length - 1
            : suggestions.length - 1;
        if (focusedIndex === null || focusedIndex === lastIndex) {
          setFocusedIndex(0);
        } else if (focusedIndex < lastIndex) {
          setFocusedIndex(focusedIndex + 1);
        }
      }

      if (focusedIndex !== null && event.keyCode === KEY_CODE_ARROW_UP) {
        event.preventDefault();
        if (focusedIndex === 0) {
          setFocusedIndex(null);
        } else {
          setFocusedIndex(focusedIndex - 1);
        }
      }

      let commandToUse = commandText;
      if (event.keyCode === KEY_CODE_ENTER && focusedIndex !== null) {
        if (currentPanel === HISTORY_PANEL) {
          setCommandText(history[focusedIndex]);
          commandToUse = history[focusedIndex];
        }

        if (currentPanel === SUGGESTIONS_PANEL) {
          setCommandText(
            suggestions[focusedIndex].param
              ? `${suggestions[focusedIndex].command} ${suggestions[focusedIndex].param}:`
              : suggestions[focusedIndex].command,
          );
          if (!suggestions[focusedIndex].param) {
            commandToUse = suggestions[focusedIndex].command;
          }
        }

        setFocusedIndex(null);

        // do not submit completed suggestion as
        // additional params are needed
        if (suggestions[focusedIndex]?.param) return;
      }

      // CMD + ENTER
      if (event.metaKey && event.keyCode === KEY_CODE_ENTER) {
        const [command, params] = splitCommandText(commandText);
        if (
          command?.actionNewTab &&
          typeof command.actionNewTab === 'function'
        ) {
          const actionResult = command.actionNewTab(params);
          if (!actionResult) {
            setShowPallete(false);
          }
        }
        return;
      }

      // TAB to autocomplete text
      if (event.keyCode === KEY_CODE_TAB) {
        event.preventDefault();
        if (autocompleteSuggestion !== '') {
          setCommandText(autocompleteSuggestion);
        }
        return;
      }

      if (event.keyCode === KEY_CODE_ENTER) {
        submitCommand(commandToUse);
      }
    },
    [
      commandText,
      focusedIndex,
      autocompleteSuggestion,
      history,
      currentPanel,
      submitCommand,
      suggestions,
    ],
  );

  const handleSearchBarTextChange = useCallback(
    (event) => {
      // find suggestions when the values change:
      const searchResults = fuse.search(event.target.value);

      setFocusedIndex(null);
      const suggestedCommands = searchResults.map((result) => result.item);
      setSuggestions(suggestedCommands);
      setCommandText(event.target.value);
      setAutocompleteSuggestion(
        get_first_exact_prefix_match(event.target.value, suggestedCommands) ||
          '',
      );
      setCurrentPanel(SUGGESTIONS_PANEL);
    },
    [fuse],
  );

  const handleClickSuggestion = useCallback(
    (commandText, mustSubmit = true) => {
      setCommandText(commandText);

      if (mustSubmit) submitCommand(commandText);
    },
    [submitCommand],
  );

  const handleMouseOverSuggestion = useCallback((index) => {
    setFocusedIndex(index);
  }, []);

  const handleMouseOutSuggestion = useCallback(() => {
    setFocusedIndex(null);
  }, []);

  const renderSuggestionsPanel = (suggestions, focusedIndex) => {
    return (
      <div className={'CommandPalette__Suggestions__Panel'}>
        {suggestions.map((suggestion, index) => (
          <Suggestion
            key={suggestion.command}
            command={suggestion}
            handleClickSuggestion={handleClickSuggestion}
            isFocused={focusedIndex === index}
            handleMouseOver={() => handleMouseOverSuggestion(index)}
            handleMouseOut={() => handleMouseOutSuggestion()}
          />
        ))}
      </div>
    );
  };

  const renderResultsPanel = (results) => {
    return (
      <div className={'CommandPalette__Results__Panel'}>
        {results.length !== 0 &&
          results.map((res) => {
            return (
              <Result key={res.query + res.timestamp} query={res.query}>
                {res.component}
              </Result>
            );
          })}
      </div>
    );
  };

  const handleMouseOverHistory = useCallback((index) => {
    setFocusedIndex(index);
  }, []);

  const handleMouseOutHistory = useCallback(() => {
    setFocusedIndex(null);
  }, []);

  const renderHistoryOfCommandsPanel = () => {
    return (
      <div>
        {history.map((commandText, index) => (
          <CommandHistoryItem
            key={`command_${commandText}_${index}`}
            commandText={commandText}
            selectCommandHistory={handleClickSuggestion}
            isFocused={focusedIndex === index}
            handleMouseOver={() => handleMouseOverHistory(index)}
            handleMouseOut={() => handleMouseOutHistory()}
          />
        ))}
      </div>
    );
  };

  const renderPanel = () => {
    if (currentPanel === HISTORY_PANEL) {
      return renderHistoryOfCommandsPanel();
    }

    if (currentPanel === SUGGESTIONS_PANEL) {
      return renderSuggestionsPanel(suggestions, focusedIndex);
    }

    return renderResultsPanel(results);
  };

  return (
    <>
      <div
        className="CommandPalette__ToggleStub"
        style={{ position: 'absolute', height: '1px', width: '1px' }}
      />
      {showPallete && (
        <>
          <div className="CommandPalette__BackgroundScreen" />
          <div className="CommandPalette__Container">
            <SearchBar
              handleKeyDown={handleSearchBarKeyDown}
              handleChange={handleSearchBarTextChange}
              commandText={commandText}
              suggestedCommand={autocompleteSuggestion}
            />
            <div className="CommandPalette__Panel">{renderPanel()}</div>
          </div>
        </>
      )}
    </>
  );
}
