import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class FieldsReadOnlySettingEditModalFieldTagsLine extends PureComponent {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    tag: PropTypes.string.isRequired,
  };

  delete = () => {
    const { onDelete, tag } = this.props;
    onDelete(tag);
  };

  render() {
    const { tag } = this.props;
    return (
      <li>
        <strong>{tag}</strong>{' '}
        <span
          className="FieldsReadOnlySettingEditModal__deleteButton mdi mdi-delete"
          onClick={this.delete}
        />
      </li>
    );
  }
}
