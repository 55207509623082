import { fork } from 'redux-saga/effects';

import authSaga from 'modules/auth/sagas';
import EntitiesMainSaga from 'modules/entities/sagas';
import { FieldSuggestionDashboardMainSaga } from 'modules/field-suggestion/sagas';
import { FieldsSaga } from 'modules/fields';
import EndpointMainSaga from 'modules/io/endpoint/sagas';
import ExportMainSaga from 'modules/io/export/sagas';
import GDSNCIMainSaga from 'modules/io/gdsn/ci/sagas';
import GDSNCISMainSaga from 'modules/io/gdsn/cis/sagas';
import GDSNPartyMainSaga from 'modules/io/gdsn/party/sagas';
import PCRModalMainSaga from 'modules/io/gdsn/pcr-modal/sagas';
import GDSNPSRMainSaga from 'modules/io/gdsn/psr/sagas';
import GDSNRCIMainSaga from 'modules/io/gdsn/rci/sagas';
import GDSNRelationMainSaga from 'modules/io/gdsn/relation/sagas';
import ImportMainSaga from 'modules/io/import/sagas';
import ImportGenericMainSaga from 'modules/io/importgeneric/sagas';
import AFSMainSaga from 'modules/io/mapper/afs/sagas';
import OrganizationGroupsSaga from 'modules/organization-groups/sagas';
import TransactionExplorerMainSaga from 'modules/transaction-explorer/sagas';
import TransactionMainSaga from 'modules/transaction/sagas';
import ValidationDashboardSaga from 'modules/validation-dashboard/sagas';

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(FieldSuggestionDashboardMainSaga);
  yield fork(EndpointMainSaga);
  yield fork(ExportMainSaga);
  yield fork(ImportMainSaga);
  yield fork(ImportGenericMainSaga);
  yield fork(FieldsSaga);
  yield fork(OrganizationGroupsSaga);
  yield fork(ValidationDashboardSaga);
  // Transaction related sagas
  yield fork(EntitiesMainSaga);
  yield fork(TransactionMainSaga);
  yield fork(TransactionExplorerMainSaga);
  // GDSN related sagas
  yield fork(GDSNCIMainSaga);
  yield fork(GDSNCISMainSaga);
  yield fork(GDSNPartyMainSaga);
  yield fork(GDSNRCIMainSaga);
  yield fork(GDSNPSRMainSaga);
  yield fork(GDSNRelationMainSaga);
  yield fork(PCRModalMainSaga);
  // AFS mapper
  yield fork(AFSMainSaga);
}
