import { createAction, createActionWithPayload } from 'utils/reducers';

import * as events from './events';
import * as types from './types';

export const init = createAction(events.INIT);

export const setLoading = createActionWithPayload<boolean>(events.SET_LOADING);
export const fetchRevisionList = createAction(events.FETCH_REVISIONS);
export const receiveRevisions = createActionWithPayload<
  types.ProductRevisionCompact[]
>(events.RECEIVE_REVISIONS);

export const updateFilters =
  createActionWithPayload<types.ProductRevisionFilter>(events.UPDATE_FILTERS);
