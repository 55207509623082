import { get } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY } from './reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REDUCER_KEY];
}

export function selectFilters(state) {
  return get(['selectedFilters'], selectState(state));
}

export function selectIsLoading(state) {
  return get(['loading'], selectState(state));
}

export function selectRevisions(state) {
  return get(['revisions'], selectState(state));
}
