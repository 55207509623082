import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Checkbox from '@alkem/react-ui-checkbox';
import { Modal } from '@alkem/react-ui-modal';

import './bulkModal.scss';

class AddFieldModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    fields: ImmutablePropTypes.list.isRequired,
  };

  state = {
    selectedFields: Map(),
  };

  onAdd = () => {
    const { fields } = this.props;
    const { selectedFields } = this.state;
    this.props.onAdd(
      fields.filter((field) => selectedFields.get(field.get('id'))),
    );
  };

  onSelectField = (field) => (selected) => {
    const { selectedFields } = this.state;
    this.setState({
      selectedFields: selectedFields.setIn([field.get('id')], selected),
    });
  };

  render() {
    const { onClose, fields } = this.props;
    const { selectedFields } = this.state;

    return (
      <Modal
        title="Add fields"
        confirmButtonText="Add"
        onConfirm={this.onAdd}
        onClose={onClose}
      >
        <div className="AddFieldModal__fields">
          {fields.map((field) => (
            <Checkbox
              key={`field-${field.get('id')}`}
              id={`field-${field.get('id')}`}
              checked={selectedFields.get(field.get('id'))}
              onChange={this.onSelectField(field)}
              label={field.get('label')}
            />
          ))}
        </div>
      </Modal>
    );
  }
}

export default AddFieldModal;
