import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';
import { Spinner } from '@alkem/react-ui-spinner';

import { separateActions } from 'utils/redux';

import { closeModal, uploadPicture } from '../../actions';
import {
  selectModalOpened,
  selectModalReferentialEntity,
  selectModalUploading,
} from '../../selectors';

import './index.scss';

const mapStateToProps = (state) => ({
  opened: selectModalOpened(state),
  referentialEntity: selectModalReferentialEntity(state),
  uploading: selectModalUploading(state),
});

const mapDispatchToProps = {
  closeModal,
  uploadPicture,
};

export class ReferentialPicturesUploadModal extends PureComponent {
  static propTypes = {
    referentialEntity: ImmutablePropTypes.map,
    opened: PropTypes.bool,
    uploading: PropTypes.bool,
    actions: PropTypes.shape({
      uploadPicture: PropTypes.func.isRequired,
      closeModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  onCloseModal = () => {
    const { actions, uploading } = this.props;
    if (!uploading) {
      actions.closeModal();
    }
  };

  uploadPicture = (pictures) => {
    const { referentialEntity, actions } = this.props;
    if (pictures && pictures.length > 0) {
      actions.uploadPicture(referentialEntity.get('id'), pictures[0]);
    }
  };

  renderPictureDrop() {
    const { uploading } = this.props;
    return (
      <Dropzone onDrop={this.uploadPicture}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: classNames(
                'Dropzone',
                isDragActive && 'Dropzone--active',
              ),
            })}
          >
            {uploading ? (
              <Spinner big />
            ) : (
              <>
                <input {...getInputProps()} />
                <div>
                  <strong>Drop your picture here</strong> or click to select it
                </div>
              </>
            )}
          </div>
        )}
      </Dropzone>
    );
  }

  render() {
    const { opened, referentialEntity, uploading } = this.props;
    if (!referentialEntity || !opened) {
      return null;
    }
    const title = `Upload picture for referentialEntity ${referentialEntity.get(
      'id',
    )}`;
    return (
      <Modal
        title={title}
        onClose={this.onCloseModal}
        isProcessing={uploading}
        hideFooter
      >
        <div className="ReferentialPicturesUploadModal__referentialEntity">
          Referential entity (<strong>{referentialEntity.get('id')}</strong>
          ):&nbsp;
          <em>{referentialEntity.get('label')}</em>
        </div>
        {this.renderPictureDrop()}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(ReferentialPicturesUploadModal);
