import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FieldTreeItem from './item';
import './tree.scss';

export default class FieldTree extends PureComponent {
  static propTypes = {
    fields: PropTypes.object,
    selectField: PropTypes.func.isRequired,
  };

  renderField = (field) => {
    const fieldId = field.get('id');
    const hasChildren =
      !!field.get('children') && field.get('children').size > 0;
    return (
      <FieldTreeItem
        key={fieldId}
        id={fieldId}
        name={field.get('name')}
        label={field.getIn(['displayInfo', 'label'])}
        select={this.props.selectField}
      >
        {hasChildren && field.get('children').map(this.renderField)}
      </FieldTreeItem>
    );
  };

  render() {
    const { fields } = this.props;

    return <div className="FieldTree">{fields.map(this.renderField)}</div>;
  }
}
