import classNames from 'classnames';
import { fromJS } from 'immutable';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import { Select, SimpleSelect } from '@alkem/react-ui-select';

import {
  OrganizationAutocomplete,
  ThirdPartyAutocomplete,
  UserAutocomplete,
} from 'components/autocomplete';
import { APPLICATION_TYPES } from 'constants/application';
import { PERMISSION_PLATFORM_VALIDATION_SHOW } from 'modules/access-policy/common/constants';
import { selectUserHasPlatformPermission } from 'modules/auth/selectors';
import { retrieveListColumnReferential } from 'modules/organization-page/actions/settings';
import RuleSetSelector from 'modules/ruleset-selector';

import {
  disableOrganization,
  enableOrganization,
  markApplicationEdited,
  validateApplication,
} from '../../actions';
import { APP_PERMISSIONS, VISIBILITY } from '../../constants';
import {
  getApplicationsHaveOrganizations,
  getCategoryOptions,
  getFormErrors,
  getListColumnsReferential,
  getOrganizationOption,
  getOrganizations,
  getUserOption,
} from '../../selectors';
import OrganizationActivated from '../organization-activated';

import './index.scss';

const labelWidth = 2;

const FormField = ({ name, label, children, errors }) => (
  <div className="form-group row">
    <label
      htmlFor={`app-${name}`}
      className={`col-xs-${labelWidth} col-form-label app-form-label`}
    >
      {label || name}
    </label>
    <div
      className={classNames(`col-xs-${12 - labelWidth}`, {
        'form-error': errors && errors.get(name),
      })}
    >
      {children}
      {errors && errors.get(name) && (
        <span className="form-error-message">
          <i className="mdi mdi-alert-outline" /> {errors.get(name)}
        </span>
      )}
    </div>
  </div>
);

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  errors: ImmutablePropTypes.map,
};

const getSelectedCategory = (categoryOptions, categories) => {
  if (categories && categories.size > 0) {
    return categoryOptions[categories.getIn(['0', 'id'])];
  }

  return null;
};

const pricingPlanOptions = {
  1: {
    key: 1,
    label: 'Paying',
  },
  2: {
    key: 2,
    label: 'Free',
  },
};

const visibilityOptions = {
  0: {
    key: 0,
    label: 'Salsify only',
  },
  1: {
    key: 1,
    label: 'Salsify + Manufacturers',
  },
  2: {
    key: 2,
    label: 'Salsify + Retailers',
  },
  3: {
    key: 3,
    label: 'All',
  },
};

const permissionOptions = {
  0: {
    key: 0,
    label: 'None',
  },
  1: {
    key: 1,
    label: 'Read only (API Access)',
  },
  2: {
    key: 2,
    label: 'Read + Write',
  },
};

const selectOptions = {
  pricingPlan: {
    1: { id: 1, name: 'Paying' },
    2: { id: 2, name: 'Free' },
  },
  visibility: {
    0: VISIBILITY.ALKEMICS_ONLY,
    1: VISIBILITY.ALKEMICS_AND_MANUFACTURER,
    2: VISIBILITY.ALKEMICS_AND_RETAIL,
    3: VISIBILITY.ALL,
  },
  permissions: {
    0: APP_PERMISSIONS.NONE,
    1: APP_PERMISSIONS.API_ACCESS,
    2: APP_PERMISSIONS.READ_WRITE,
  },
  type: Object.values(APPLICATION_TYPES).map(({ key }) => key),
};

const mapStateToProps = createStructuredSelector({
  organizations: getOrganizations,
  applicationsHaveOrganizations: getApplicationsHaveOrganizations,
  categoryOptions: getCategoryOptions,
  errors: getFormErrors,
  listColumnsReferential: getListColumnsReferential,
  validationAllowed: selectUserHasPlatformPermission(
    PERMISSION_PLATFORM_VALIDATION_SHOW,
  ),
});

const mapDispatchToProps = {
  markApplicationEdited,
  validateApplication,
  enableOrganization,
  disableOrganization,
  retrieveListColumnReferential,
};

export class ApplicationDetails extends PureComponent {
  static propTypes = {
    application: ImmutablePropTypes.map.isRequired,
    markApplicationEdited: PropTypes.func.isRequired,
    validateApplication: PropTypes.func.isRequired,
    enableOrganization: PropTypes.func.isRequired,
    disableOrganization: PropTypes.func.isRequired,
    categoryOptions: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    validationAllowed: PropTypes.bool.isRequired,
    errors: ImmutablePropTypes.map.isRequired,
    listColumnsReferential: ImmutablePropTypes.list,
    retrieveListColumnReferential: PropTypes.func.isRequired,
  };

  state = {
    fields: {},
  };

  componentDidMount() {
    this.setFieldValues(this.props.application);
    this.props.retrieveListColumnReferential();
  }

  componentDidUpdate(prevProps) {
    const { application, saving, pristine } = this.props;
    if (
      application.get('id') !== prevProps.application.get('id') ||
      (pristine && !prevProps.pristine)
    ) {
      this.setFieldValues(application);
    }
    if (saving && saving !== prevProps.saving) {
      this.props.validateApplication(this.state.fields);
    }
  }

  onInputChange = (e) => {
    this.updateField(e.target.name, e.target.value);
  };

  onCheckboxChange = (name) => (checked) => {
    this.updateField(name, checked);
  };

  onSelectChange = (name) => (value) => {
    this.updateField(name, selectOptions[name][value.key]);
  };

  onSelectCategory = (selectedCategory) => {
    this.updateField('categories', fromJS([{ id: selectedCategory.key }]));
  };

  onSelectOrganization = (selectedOrganization) => {
    this.updateField('organization', selectedOrganization.value);
  };

  onUnselectOrganization = () => {
    this.updateField('organization', null);
  };

  onSelectEnablingOrganization = (selectedOrganization) => {
    this.setState({ enablingOrganization: selectedOrganization });
  };

  onUnselectEnablingOrganization = () => {
    this.setState({ enablingOrganization: null });
  };

  onEnableOrganization = () => {
    this.props.enableOrganization(
      this.state.enablingOrganization?.value,
      this.state.fields?.id,
    );
    this.setState({ enablingOrganization: null });
  };

  onDisableOrganization = (selectedOrganization) => () => {
    // eslint-disable-next-line
    const areYouSure = window.confirm(
      `Are you sure you want to disable the organization: ${selectedOrganization}`,
    );
    if (areYouSure) {
      this.props.disableOrganization(
        selectedOrganization,
        this.state.fields.id,
      );
    }
  };

  onSelectUser = (selectedUser) => {
    this.updateField('user', {
      id: selectedUser.key,
      username: selectedUser.value,
    });
  };

  onUnselectUser = () => {
    this.updateField('user', null);
  };

  onAddCatalogColumns = (value) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        catalogColumns: prevState.fields.catalogColumns.push(fromJS(value.id)),
      },
    }));
    this.checkPristine();
  };

  onRemoveCatalogColumns = (index) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        catalogColumns: prevState.fields.catalogColumns.delete(index),
      },
    }));
    this.checkPristine();
  };

  onAddSourcingColumns = (value) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        sourcingColumns: prevState.fields.sourcingColumns.push(
          fromJS(value.id),
        ),
      },
    }));
    this.checkPristine();
  };

  onRemoveSourcingColumns = (index) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        sourcingColumns: prevState.fields.sourcingColumns.delete(index),
      },
    }));
    this.checkPristine();
  };

  onUpdateRuleSetScope = (ruleSets) => {
    this.setState((prevState) => ({
      fields: { ...prevState.fields, ruleSets },
    }));
    this.checkPristine();
  };

  setFieldValues(app) {
    this.setState({
      fields: {
        id: app.get('id'),
        name: app.get('name'),
        description: app.get('description'),
        descriptionShort: app.get('descriptionShort'),
        descriptionValue: app.get('descriptionValue'),
        color: app.get('color'),
        logoUrl: app.get('logoUrl'),
        user: app.get('userId') ? { id: app.get('userId') } : null,
        featured: app.get('featured'),
        url: app.get('url'),
        pricingPlan: app.get('pricingPlan').toJS(),
        visibility: app.get('visibility'),
        organization: app.getIn(['organization', 'id'])
          ? app.get('organization').toJS()
          : null,
        enabledFor: app.get('enabledFor').toJS(),
        flags: app.get('flags'),
        basicSettings: app.get('basicSettings'),
        valueFlags: app.get('valueFlags'),
        reportings: app.get('reportings'),
        catalogColumns: app.get('catalogColumns'),
        sourcingColumns: app.get('sourcingColumns'),
        permissions: app.get('permissions'),
        viewAsTag: app.get('viewAsTag'),
        ruleSets: app.get('ruleSets'),
        categories: app.get('categories'),
        type: app.get('type'),
      },
      enablingOrganization: null,
    });
  }

  getColumnsEnriched(columns, listColumnsReferential) {
    if (listColumnsReferential.count() === 0) {
      return [];
    } else {
      return columns
        .map((id) => {
          const r = listColumnsReferential.find((c) => c.get('id') === id);
          return { id: r.get('id'), key: r.get('id'), label: r.get('code') };
        })
        .toJS();
    }
  }

  checkPristine = () => {
    if (this.props.pristine) {
      this.props.markApplicationEdited(this.props.application.get('id'));
    }
  };

  toggleAppActivation = (orgId) => (value) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        enabledFor: {
          ...prevState.fields.enabledFor,
          [orgId]: {
            ...prevState.fields.enabledFor[orgId],
            active: value,
          },
        },
      },
    }));
    this.checkPristine();
  };

  updateField = (name, value) => {
    this.setState((prevState) => ({
      fields: { ...prevState.fields, [name]: value },
    }));
    this.checkPristine();
  };

  createColumnsOptions(selectedColumns, listColumnsReferential) {
    return listColumnsReferential
      .filter((o) => !selectedColumns.includes(o.get('id')))
      .map((o) => ({
        id: o.get('id'),
        label: `${o.get('code')} - ${o.get('description')}`,
      }))
      .toJS();
  }

  render() {
    const { fields } = this.state;
    const { errors, listColumnsReferential, readOnly, validationAllowed } =
      this.props;

    if (isEmpty(fields)) {
      return null;
    }

    return (
      <div className="app-form">
        <FormField name="type">
          <div className="app-type">
            <SimpleSelect
              id="app-type"
              options={Object.values(APPLICATION_TYPES)}
              value={APPLICATION_TYPES[fields.type]}
              onSelect={this.onSelectChange('type')}
              disabled={readOnly}
              autoSize
            />
          </div>
        </FormField>

        <FormField name="featured">
          <div className="app-featured">
            <Checkbox
              id="app-featured"
              checked={fields.featured}
              onChange={this.onCheckboxChange('featured')}
              disabled={readOnly}
            />
          </div>
        </FormField>

        <FormField name="name" errors={errors}>
          <input
            type="text"
            id="app-name"
            name="name"
            value={fields.name}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
            data-testid="app-name"
          />
        </FormField>

        <FormField name="descriptionShort" errors={errors}>
          <textarea
            rows={3}
            id="app-descriptionShort"
            name="descriptionShort"
            value={fields.descriptionShort}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
          />
        </FormField>

        <FormField name="description" errors={errors}>
          <textarea
            rows={5}
            id="app-description"
            name="description"
            value={fields.description}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
          />
        </FormField>

        <FormField name="descriptionValue">
          <textarea
            rows={3}
            id="app-descriptionValue"
            name="descriptionValue"
            value={fields.descriptionValue}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
          />
        </FormField>

        <FormField name="logoUrl" errors={errors}>
          <input
            type="text"
            id="app-logoUrl"
            name="logoUrl"
            value={fields.logoUrl}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
          />
        </FormField>

        <FormField name="url" errors={errors}>
          <input
            type="text"
            id="app-url"
            name="url"
            value={fields.url}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
          />
        </FormField>

        <FormField name="color" errors={errors}>
          <div className="row">
            <span className="col-xs-6">
              <input
                type="text"
                id="app-color"
                name="color"
                value={fields.color}
                onChange={this.onInputChange}
                className="form-control"
                disabled={readOnly}
              />
            </span>
            <span className="col-xs-6">
              <input
                type="text"
                disabled
                id="app-color-preview"
                className="form-control"
                style={{ background: fields.color }}
              />
            </span>
          </div>
        </FormField>

        <FormField name="visibility">
          <SimpleSelect
            id="app-visibility"
            options={Object.values(visibilityOptions)}
            value={visibilityOptions[fields.visibility]}
            onSelect={this.onSelectChange('visibility')}
            disabled={readOnly}
            autoSize
          />
        </FormField>

        <FormField name="organization" errors={errors}>
          <ThirdPartyAutocomplete
            id="organization-autocomplete"
            value={
              fields.organization
                ? [getOrganizationOption(fields.organization)]
                : []
            }
            selector={getOrganizationOption}
            onSelect={this.onSelectOrganization}
            onUnselect={this.onUnselectOrganization}
            disabled={readOnly}
            searchOnClick
          />
        </FormField>

        <FormField name="userId">
          <UserAutocomplete
            id="organization-autocomplete"
            value={fields.user ? [getUserOption(fields.user)] : []}
            selector={getUserOption}
            onSelect={this.onSelectUser}
            onUnselect={this.onUnselectUser}
            disabled={readOnly}
            searchOnClick
          />
        </FormField>

        <FormField name="pricingPlan">
          <SimpleSelect
            id="app-pricingPlan"
            options={Object.values(pricingPlanOptions)}
            value={pricingPlanOptions[fields.pricingPlan.id]}
            onSelect={this.onSelectChange('pricingPlan')}
            disabled={readOnly}
            autoSize
          />
        </FormField>

        <FormField name="categories">
          <SimpleSelect
            id="app-categories"
            options={Object.values(this.props.categoryOptions)}
            value={getSelectedCategory(
              this.props.categoryOptions,
              fields.categories,
            )}
            onSelect={this.onSelectCategory}
            disabled={readOnly}
            autoSize
          />
        </FormField>

        <FormField name="permissions">
          <SimpleSelect
            id="app-permissions"
            options={Object.values(permissionOptions)}
            value={permissionOptions[fields.permissions]}
            onSelect={this.onSelectChange('permissions')}
            disabled={readOnly}
            autoSize
          />
        </FormField>

        <hr />

        <FormField label="LD flags" name="flags" errors={errors}>
          <textarea
            id="app-flags"
            rows={3}
            type="text"
            name="flags"
            value={fields.flags || ''}
            onChange={this.onInputChange}
            className="form-control"
            placeholder={'["feature-retailer-test", "feature-retailer-view"]'}
            disabled={readOnly}
          />
        </FormField>

        <FormField name="valueFlags" label="LD flags value" errors={errors}>
          <textarea
            id="app-valueflags"
            rows={8}
            type="text"
            name="valueFlags"
            value={fields.valueFlags || ''}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
            placeholder={
              '[{"key":"feature-manufacturer-history","on":"true","off":"false"}, {"key":"feature-all-permission-management","on":"Governance","off":"None"}]'
            }
          />
        </FormField>

        <FormField label="settings" name="basicSettings" errors={errors}>
          <textarea
            id="app-basicsettings"
            rows={8}
            type="text"
            name="basicSettings"
            value={fields.basicSettings || ''}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
            placeholder={
              '[{"key": "setting1", "on": "true", "off": "false"}, {"key": "s2", "on": "enable", "off": "disable"}]'
            }
          />
        </FormField>

        <FormField label="reportings" name="reportings" errors={errors}>
          <textarea
            id="app-reportings"
            rows={2}
            type="text"
            name="reportings"
            value={fields.reportings || ''}
            onChange={this.onInputChange}
            className="form-control"
            placeholder="[100025579,100027595]"
            disabled={readOnly}
          />
        </FormField>

        <FormField name="catalog columns" errors={errors}>
          <Select
            id="catalogcolumns-select"
            onValueAdd={this.onAddCatalogColumns}
            onValueDelete={this.onRemoveCatalogColumns}
            options={this.createColumnsOptions(
              fields.catalogColumns,
              listColumnsReferential,
            )}
            multiple
            autoSize
            values={this.getColumnsEnriched(
              fields.catalogColumns,
              listColumnsReferential,
            )}
            disabled={readOnly}
          />
        </FormField>

        <FormField name="sourcing columns" errors={errors}>
          <Select
            id="sourcingcolumns-select"
            onValueAdd={this.onAddSourcingColumns}
            onValueDelete={this.onRemoveSourcingColumns}
            options={this.createColumnsOptions(
              fields.sourcingColumns,
              listColumnsReferential,
            )}
            multiple
            autoSize
            values={this.getColumnsEnriched(
              fields.sourcingColumns,
              listColumnsReferential,
            )}
            disabled={readOnly}
          />
        </FormField>

        <FormField name="viewAs tag" errors={errors}>
          <input
            type="text"
            id="app-viewAsTag"
            name="viewAsTag"
            value={fields.viewAsTag}
            onChange={this.onInputChange}
            className="form-control"
            disabled={readOnly}
          />
        </FormField>
        {validationAllowed && (
          <FormField name="rule sets" errors={errors}>
            <RuleSetSelector
              rulesets={fields.ruleSets}
              onChange={this.onUpdateRuleSetScope}
              readOnly={readOnly}
              manageDeadline={false}
            />
          </FormField>
        )}

        <hr />

        <FormField name="enabled for">
          <ul className="list-group">
            {Object.keys(fields.enabledFor).length === 0 && (
              <li className="list-group-item empty-message">
                This app is currently not enabled for any organizations.
              </li>
            )}
            <br />
            <h4> Production Network </h4>
            {map(
              fields.enabledFor,
              (ef, organizationId) =>
                ef.network.id === 1 && (
                  <OrganizationActivated
                    organizationId={organizationId}
                    organization={ef}
                    key={organizationId}
                    onToggle={this.toggleAppActivation(organizationId)}
                    onDelete={this.onDisableOrganization(organizationId)}
                    readOnly={readOnly}
                  />
                ),
            )}
            <br />
            <h4> Other Networks </h4>
            {map(
              fields.enabledFor,
              (ef, organizationId) =>
                ef.network.id !== 1 && (
                  <OrganizationActivated
                    organizationId={organizationId}
                    organization={ef}
                    key={organizationId}
                    onToggle={this.toggleAppActivation(organizationId)}
                    onDelete={this.onDisableOrganization(organizationId)}
                    readOnly={readOnly}
                    showNetwork
                  />
                ),
            )}
          </ul>
          {!readOnly && (
            <div className="enableOrganization">
              <OrganizationAutocomplete
                id="enable-organization-autocomplete"
                value={
                  this.state.enablingOrganization
                    ? [this.state.enablingOrganization]
                    : []
                }
                onSelect={this.onSelectEnablingOrganization}
                onUnselect={this.onUnselectEnablingOrganization}
                placeholder="Organization to enable"
                disabled={readOnly}
                searchOnClick
                selector={getOrganizationOption}
              />
              <Button
                primary
                id="enableOrganization__Add"
                onClick={this.onEnableOrganization}
                key="EnableOrganization"
                className="enableOrganization___AddButton"
                content="Enable organization"
              />
            </div>
          )}
        </FormField>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails);
