import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

interface Props {
  name: string;
}
export function ExportMappingsItemNotEmptyModuleCondition({ name }: Props) {
  return (
    <InputWithLabel
      inputId={`gdsn-export-mappings-not-empty-module-${name}`}
      label={
        <span>
          Not empty module{' '}
          <HelpTooltip
            id={`gdsn-export-mappings-not-empty-module-${name}-help`}
            place="top"
            message="If an other tag was created inside the parent tag, the condition will be fullfilled."
          />
        </span>
      }
    >
      <div data-testid={`gdsn-export-mappings-not-empty-module-${name}`}>
        <Radio
          id={`gdsn-export-mappings-not-empty-module-${name}`}
          value={true}
          options={[{ label: 'True', value: true }]}
          onChange={() => {}}
          disabled
        />
      </div>
    </InputWithLabel>
  );
}
