import { navigate } from 'utils/location';

import { ShowReferential } from '../components/results/show-referential';

export const root = {
  title: 'Go to the referential dashboard',
  command: 'ref',
  action: () => {
    navigate({ to: '/referentials' });
  },
  actionNewTab: () => {
    window.open('/referentials', '_blank')?.focus();
  },
};
export const what = {
  title: 'Documentation on Referentials in the context of SXM',
  command: 'ref what',
  render: () => (
    <div>
      <p>
        Referentials are equivalent to lists of constants in the SXM ecosystem.
      </p>
      <p>Some of our favourite referentials include `listcolumns`, and `gpc`</p>
      <p>
        To learn how our operations team manages referentials{' '}
        <a
          href="https://alkemics.atlassian.net/wiki/spaces/POMC/pages/2337112387/Referential"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </a>
        .
      </p>
    </div>
  ),
};
export const show = {
  title: 'Preview a referential',
  command: 'ref show',
  param: 'slug',
  help: 'usage: "ref show slug:listcolumns"',
  render: ({ slug }) => {
    if (slug) {
      return <ShowReferential slug={slug} />;
    } else {
      return (
        <div>
          You did not provide a referential slug. e.g. ref show slug:listcolumns
        </div>
      );
    }
  },
};

export const commands = [root, what, show];
