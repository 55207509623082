import classNames from 'classnames';
import { useState } from 'react';

import { ExportMappingsView } from '..';
import {
  ExportMapping,
  ExportMappings,
  Xpath,
  XpathList,
} from '../../../types';

import styles from './item-child.module.scss';

interface Props {
  element: Xpath;
  parentElement: Xpath;
  sameLevelElementChildren: XpathList;
  exportMappings: ExportMappings;
  parent_id?: number;
  sxm_list_parent?: {
    field: string;
    use_su: boolean;
  };
  parentExportMapping: ExportMapping;
}
export function ExportMappingsItemChild({
  element,
  parentElement,
  sameLevelElementChildren,
  exportMappings,
  parent_id,
  sxm_list_parent,
  parentExportMapping,
}: Props) {
  const [isClosed, setIsClosed] = useState(true);
  return (
    <div className={styles.container}>
      <div
        className={classNames({
          [styles.headerClosed]: isClosed,
          [styles.headerOpened]: !isClosed,
        })}
      >
        <span
          className={styles.nameContainer}
          onClick={() => setIsClosed(!isClosed)}
        >
          <i
            className={classNames('mdi', {
              'mdi-chevron-down': isClosed,
              'mdi-chevron-up': !isClosed,
            })}
          />
          {element.name} ({exportMappings.length} mapping
          {exportMappings.length > 1 ? 's' : ''})
        </span>
      </div>
      {!isClosed && (
        <ExportMappingsView
          element={element}
          parentElement={parentElement}
          sameLevelElementChildren={sameLevelElementChildren}
          exportMappings={exportMappings}
          parent_id={parent_id}
          sxm_list_parent={sxm_list_parent}
          parentExportMapping={parentExportMapping}
        />
      )}
    </div>
  );
}
