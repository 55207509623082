import moment from 'moment';

import { DATETIME_FORMAT, UNLIMITED_DATE } from '../../../constants';
import { ExportMapping, Xpath, XpathList } from '../../../types';
import { ExportMappingsItem } from '../item';
import { isExportMapping } from '../utils';

import styles from './item-fallback.module.scss';

interface Props {
  element: Xpath;
  sameLevelElementChildren?: XpathList;
  exportMapping: ExportMapping;
  editionId?: number | string;
  reorderingId?: number;
  isNew?: boolean;
  index?: number;
  changeOrder: Function;
  lengthMappings?: number;
  isFirst?: boolean;
  parentExportMapping?: ExportMapping;
  sxm_list_parent?: {
    field: string;
    use_su: boolean;
  };
}

export function ExportMappingsItemFallback(props: Props) {
  const { element, exportMapping, sxm_list_parent } = props;
  const { fallback } = exportMapping;
  let use_su: boolean | undefined = undefined;
  if (sxm_list_parent) {
    use_su = sxm_list_parent.use_su || undefined;
  }

  return (
    <div
      data-testid={`gdsn-export-mappings-item-fallback-${element.id}-${
        exportMapping.id
      }-${'id' in fallback ? fallback?.id : ''}`}
    >
      <div className={styles.sectionTitle}>Fallback</div>
      {isExportMapping(fallback) ? (
        <ExportMappingsItem {...props} exportMapping={fallback} isFallback />
      ) : (
        <ExportMappingsItem
          {...props}
          isNew
          exportMapping={{
            date_start: moment().format(DATETIME_FORMAT),
            date_end: UNLIMITED_DATE,
            order: 0,
            is_fallback: true,
            xpath_id: element.id,
            parent_id: exportMapping.id,
            payload: {
              use_su,
            },
            children: [],
            sxm_list_parent,
            fallback: {},
          }}
          isFallback
        />
      )}
    </div>
  );
}
