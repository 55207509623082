import { useDispatch } from 'react-redux';

import { TAG_ON_SHARE, UNTAG_ON_CHANGE } from 'constants/organization-settings';

import { updateSettingValue } from '../../../actions/settings';

import { RadioController } from './radiocontroller';

export const NONE = 'none';

const TagOnShareRadioController = (props) => {
  const { id, isReadOnly } = props;
  const dispatch = useDispatch();
  const set = (name: string, value: string) => {
    dispatch(updateSettingValue([name], value));
  };

  const valueGetter = (settings) => {
    if (
      settings.getIn([UNTAG_ON_CHANGE]) === 'enable' ||
      settings.getIn([UNTAG_ON_CHANGE]) === 'true'
    ) {
      return UNTAG_ON_CHANGE;
    }
    if (
      settings.getIn([TAG_ON_SHARE]) === 'enable' ||
      settings.getIn([TAG_ON_SHARE]) === 'true'
    ) {
      return TAG_ON_SHARE;
    }
    return NONE;
  };

  const options = [
    {
      label: 'None',
      value: NONE,
    },
    {
      label: 'Tag on share',
      value: TAG_ON_SHARE,
      help: 'Define if the product is tagged as exportable on share',
    },
    {
      label: 'Untag on change',
      value: UNTAG_ON_CHANGE,
      help: 'Define if the product is untagged as exportable on product change',
    },
  ];

  const onChange = (optionValue: string) => {
    switch (optionValue) {
      case UNTAG_ON_CHANGE:
        set(UNTAG_ON_CHANGE, 'enable');
        set(TAG_ON_SHARE, 'disable');
        break;
      case TAG_ON_SHARE:
        set(TAG_ON_SHARE, 'enable');
        set(UNTAG_ON_CHANGE, 'disable');
        break;
      case NONE:
        set(TAG_ON_SHARE, 'disable');
        set(UNTAG_ON_CHANGE, 'disable');
        break;
    }
  };

  return (
    <RadioController
      id={id}
      label="Auto-tagging"
      help="Defines the behavior of exportability tags on actions like a product share or a product update.
      Cannot be both “Tag on share” + “Untag on change”."
      valueGetter={valueGetter}
      options={options}
      onChange={onChange}
      disabled={isReadOnly}
    />
  );
};

export default TagOnShareRadioController;
