import { isEmpty } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';

import { OrganizationAutocomplete } from 'components/autocomplete';
import { OrganizationPageReviewTriggerModal } from 'modules/organization-page/components/review-trigger-modal';

import {
  createOrganizationFieldGroup,
  importFieldGroup,
  loadFieldGroupsForImport,
} from '../actions';
import { selectGroupsForImport, selectOrganization } from '../selectors';

const ImportFieldGroup = React.memo(() => {
  const dispatch = useDispatch();
  const currentOrganization = useSelector(selectOrganization);
  const [isModalOpen, setModalOpen] = useState(false);
  const [organization, setOrganization] = useState<any>(null);
  const [group, setGroup] = useState<any>(null);
  const groups = useSelector(selectGroupsForImport);

  const groupOptions = useMemo(
    () =>
      organization && groups
        ? groups.map((group) => ({ key: group.id, label: group.name }))
        : [],
    [groups, organization],
  );

  useEffect(() => {
    setGroup(null);
  }, [organization]);

  useEffect(() => {
    if (!isModalOpen) {
      setGroup(null);
      setOrganization(null);
    }
  }, [isModalOpen]);

  const onSelectOrganization = useCallback(
    (_organization) => {
      setOrganization(_organization);
      dispatch(loadFieldGroupsForImport(_organization.key));
    },
    [dispatch],
  );

  const onImportFieldGroup = useCallback(() => {
    if (!group) {
      return;
    }
    const groupToImport = groups?.find(({ id }) => group.key === id);
    if (groupToImport && currentOrganization) {
      dispatch(
        importFieldGroup({
          group: groupToImport,
          organization: currentOrganization,
        }),
      );
      setModalOpen(false);
    }
  }, [groups, currentOrganization, group, dispatch]);

  return (
    <div className="alk-margin-left">
      <Button primary onClick={() => setModalOpen(true)}>
        {'Import Group...'}
      </Button>
      {isModalOpen && (
        <Modal
          title={`Import Group from another Organization`}
          confirmButtonText={`Import`}
          confirmDisabled={!organization || !group}
          shouldCloseOnOverlayClick
          onClose={() => setModalOpen(false)}
          onConfirm={onImportFieldGroup}
          className="ImportFieldGroupModal"
          modalStyle="dynamic"
        >
          <div>
            <strong className="alk-padding-bottom">Organization</strong>
            <OrganizationAutocomplete
              id="import-field-group-organization-autocomplete"
              onSelect={onSelectOrganization}
              onUnselect={() => setOrganization(null)}
              value={organization ? [organization] : null}
              placeholder="Select an organization to import group from..."
              searchOnClick
              withs={{ users: false }}
              excludeList={[
                { key: currentOrganization?.id, value: currentOrganization },
              ]}
            />
          </div>
          <div className="alk-margin-top--large">
            <strong className="alk-padding-bottom">Field group</strong>
            <SimpleSelect
              id="FieldGroupToImport"
              disabled={isEmpty(groupOptions)}
              options={groupOptions || []}
              value={group ? group : null}
              onSelect={setGroup}
            />
          </div>
        </Modal>
      )}
    </div>
  );
});

export const FieldGroupActions = React.memo(() => {
  const dispatch = useDispatch();
  const organization = useSelector(selectOrganization);
  const [fieldGroupName, setFieldGroupName] = useState('');
  const [reviewModal, setReviewModal] = useState(false);

  const onChangeFieldGroupName = useCallback(
    (e) => {
      setFieldGroupName(e.target.value);
    },
    [setFieldGroupName],
  );

  const onCreateFieldGroup = useCallback(() => {
    if (fieldGroupName && organization?.id) {
      dispatch(createOrganizationFieldGroup(organization?.id, fieldGroupName));
      setFieldGroupName('');
    }
  }, [dispatch, fieldGroupName, organization]);

  return (
    <div className="OrganizationGroupField__AddFieldGroup">
      <div className="OrganizationGroupField__AddFieldGroup--name">
        <input
          type="text"
          value={fieldGroupName}
          className="form-control"
          onChange={onChangeFieldGroupName}
          placeholder="Group of attribute name"
        />
      </div>
      <div className="alk-margin-left">
        <Button primary onClick={onCreateFieldGroup} disabled={!fieldGroupName}>
          {'Add Group'}
        </Button>
      </div>
      <ImportFieldGroup />
      {organization && (
        <>
          <Button
            primary
            onClick={() => setReviewModal(true)}
            content="Review trigger"
          />
          {reviewModal && (
            <OrganizationPageReviewTriggerModal
              organizationId={organization.id}
              close={() => setReviewModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
});
