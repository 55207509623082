import Immutable from 'immutable';
import { useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { selectUser } from 'reducers/user';
import { getStreamUrl } from 'utils/stream';

export function LogAsButton({
  targetEmail,
  targetOrganization,
}: {
  targetEmail: string;
  targetOrganization: number;
}) {
  const user: Immutable.Map<string, any> = useSelector(selectUser);
  const encodedSource = encodeURIComponent(user.get('username'));
  const encodedTarget = encodeURIComponent(targetEmail);
  const encodedOrganizationId = encodeURIComponent(targetOrganization);

  return (
    <Button
      secondary
      className="LogAsButton"
      content="Log as"
      href={getStreamUrl(
        `logas?source=${encodedSource}&target=${encodedTarget}&organization=${encodedOrganizationId}`,
      )}
      target="_blank"
    />
  );
}
