import { ModuleWrapper } from 'redux/register';

import GLNClaim from './components';
import { REDUCER_KEY } from './constants';
import reducer from './reducers';

export default function GLNClaimDashboardContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <GLNClaim />
    </ModuleWrapper>
  );
}
