import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// import { createI13 }
import { SimpleSelect } from '@alkem/react-ui-select';

const options = [20, 50, 100].map((count) => ({
  id: `limit-option-${count}`,
  label: `${count} per page`,
  value: count,
}));
const extraOptions = {
  i13Action: 'numberOfElementsDisplayed',
  i13Category: 'filter',
};

export default class LimitSelect extends PureComponent {
  static propTypes = {
    limit: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    extraOptions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange({ value } = {}) {
    this.props.onChange(value);
  }

  findSelectedValue() {
    return options.find((opt) => opt.value === this.props.limit);
  }

  render() {
    return (
      <SimpleSelect
        id="itemsPerPage"
        options={options}
        value={this.findSelectedValue()}
        onSelect={this.onChange}
        extraOptions={{ ...extraOptions, ...this.props.extraOptions }}
        autoSize
      />
    );
  }
}
