import { List } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from '../constants/targetMarkets';

const initialState = {
  list: List(),
};

export default createReducer(initialState, {
  [events.TM_RECEIVE]: (state, action) => {
    const newState = { ...state };
    newState.list = action.list;
    return newState;
  },
});
