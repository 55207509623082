import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ListAutocomplete } from 'components/autocomplete';

import './autocomplete.scss';

class AutocompleteFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    onSelect: PropTypes.func.isRequired,
    onUnselect: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.any,
        label: PropTypes.node,
      }),
    ),
    value: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.any,
        label: PropTypes.node,
      }),
    ),
  };

  constructor(props) {
    super(props);

    this.onUnselect = this.onUnselect.bind(this);
    this.onAdd = this.onAdd.bind(this);

    this.state = { added: [] };
  }

  onAdd(v) {
    const { onSelect } = this.props;
    const { added } = this.state;

    const elt = { key: v, value: v, label: v };
    onSelect(elt);
    added.push(elt);
    this.setState({ added });
  }

  onUnselect(idx) {
    this.props.onUnselect(this.props.value[idx]);
  }

  render() {
    const { id, title, values, value, onSelect } = this.props;
    const { added } = this.state;

    return (
      <div id={id} className="AutocompleteFilter">
        <h3 className="ReactUiFilter__filterTitle">{title}</h3>
        <ListAutocomplete
          id={`list-autocomplete-${id}`}
          className="TransactionFilter__Autocomplete"
          onAdd={this.onAdd}
          onSelect={onSelect}
          onUnselect={this.onUnselect}
          values={values.concat(added)}
          value={value}
          multiple
          searchOnClick
        />
      </div>
    );
  }
}
export default AutocompleteFilter;
