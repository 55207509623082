import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { SwitchButton } from '@alkem/react-ui-button';
import List from '@alkem/react-ui-list';
import { Spinner } from '@alkem/react-ui-spinner';

import { separateActions } from 'utils/redux';

import {
  getBrandClaims,
  nextPage,
  onLimitChange,
  previousPage,
  setActionnableClaimsOnly,
} from '../../actions';
import {
  selectActionnableClaimsOnly,
  selectBrandClaims,
  selectCurrentPage,
  selectIsLoading,
  selectLimit,
  selectSearch,
  selectTotalPages,
  selectTotalResults,
} from '../../selectors';

import BrandClaimHeaderRow from './header';
import './index.scss';
import BrandClaimRow from './row';

const mapStateToProps = createStructuredSelector({
  brandClaims: selectBrandClaims,
  limit: selectLimit,
  search: selectSearch,
  totalResults: selectTotalResults,
  currentPage: selectCurrentPage,
  totalPages: selectTotalPages,
  isLoading: selectIsLoading,
  actionnableClaimsOnly: selectActionnableClaimsOnly,
});

const mapDispatchToProps = {
  getBrandClaims,
  nextPage,
  previousPage,
  onLimitChange,
  setActionnableClaimsOnly,
};

export class BrandClaimList extends Component {
  static propTypes = {
    brandClaims: ImmutablePropTypes.list.isRequired,
    isLoading: PropTypes.bool,
    actionnableClaimsOnly: PropTypes.bool,
    search: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      getBrandClaims: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      onLimitChange: PropTypes.func.isRequired,
      setActionnableClaimsOnly: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.getBrandClaims();
  }

  componentDidUpdate(prevProps) {
    const { actions, limit, currentPage, search } = this.props;
    if (
      limit !== prevProps.limit ||
      currentPage !== prevProps.currentPage ||
      search !== prevProps.search
    ) {
      actions.getBrandClaims(search !== prevProps.search);
    }
  }

  onActionnableItemsOnlyChanged = (checked) => {
    this.props.actions.setActionnableClaimsOnly(checked);
    this.props.actions.getBrandClaims(true);
  };

  getLimitLabel(count) {
    return `${count} per page.`;
  }

  getPageLabel(page, total) {
    return `${page} / ${total} ${total > 1 ? 'pages' : 'page'}.`;
  }

  getEntityLabel(count) {
    return `${count} claims in total.`;
  }

  renderActions() {
    const { actionnableClaimsOnly } = this.props;
    return (
      <SwitchButton
        checked={actionnableClaimsOnly}
        onChange={this.onActionnableItemsOnlyChanged}
        content="Pending actions only"
      />
    );
  }

  render() {
    const { brandClaims, isLoading } = this.props;
    return (
      <div className="BrandClaimList">
        <List
          currentPage={this.props.currentPage}
          totalPages={this.props.totalPages}
          totalResults={this.props.totalResults}
          onNext={this.props.actions.nextPage}
          onPrev={this.props.actions.previousPage}
          limit={this.props.limit}
          onLimitChange={this.props.actions.onLimitChange}
          getLimitLabel={this.getLimitLabel}
          getPageLabel={this.getPageLabel}
          getEntityLabel={this.getEntityLabel}
          actions={this.renderActions()}
          bottomActions
        >
          <BrandClaimHeaderRow />
          {isLoading && (
            <div className="BrandClaimList__spinner">
              <Spinner big loading />
            </div>
          )}
          {!isLoading &&
            brandClaims
              .valueSeq()
              .map((claim) => (
                <BrandClaimRow
                  key={`claim-${claim.get('id')}`}
                  brandClaim={claim}
                />
              ))}
        </List>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(BrandClaimList);
