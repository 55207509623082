import { fromJS } from 'immutable';

import { createReducer } from 'utils/reducers';

import * as events from '../constants/events';

const initialState = {
  pcr: fromJS({
    psr_sync_id: null,
    psr_item_id: null,
    status: 'RECEIVED',
    reason: null,
    validate: true,
  }),
};

export default createReducer(initialState, {
  [events.PCR_UPDATE]: (state, action) => {
    return {
      ...state,
      pcr: state.pcr.setIn(action.payload.key, action.payload.value),
    };
  },
});
