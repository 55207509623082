import { Map as ImmutableMap, fromJS } from 'immutable';

import { fieldTypeToRuleType } from 'modules/organization-uses-fields/constants';
import {
  ruleEntityTypes,
  ruleTemplateTypes,
} from 'modules/validation-dashboard/constants';
import { getIn } from 'utils/immutable';

import { FieldMetadata } from '../hooks/types';

function getDefaultPackagingTypes(
  entityTypeId: number,
  { applicableForTypePackagings }: FieldMetadata,
) {
  if (entityTypeId === ruleEntityTypes.SHARING_UNIT.id) {
    return [];
  }
  return applicableForTypePackagings.map(({ typePackaging: { id } }) => ({
    id,
  }));
}

export function makeRule(
  field: ImmutableMap<string, any>,
  fieldMetadata: FieldMetadata,
  entityType: string,
  extraData = {},
  templateType = ruleTemplateTypes.REQUIRED,
) {
  const ruleEntityTypeId = fieldTypeToRuleType[entityType];
  let errorMessage = 'This field is required';
  let errorLabel = null;
  if (ruleEntityTypeId === ruleEntityTypes.LOGISTICAL_UNIT.id) {
    errorLabel = getIn(field, 'displayInfo.label');
    errorMessage = 'The field <code>%s</code> is required';
  }
  return fromJS({
    template: {
      type: templateType,
      field: field,
    },
    entityType: ruleEntityTypeId,
    bypassable: false,
    errorMessage,
    errorLabel,
    applicableForTypePackagings: getDefaultPackagingTypes(
      ruleEntityTypeId,
      fieldMetadata,
    ),
    applicableForKinds: [],
    ...extraData,
  });
}
