import { set } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import { ActionWithPayload } from 'utils/reducers/types';

import * as events from './events';
import * as types from './types';

export const REDUCER_KEY = 'indexer-queue';

const getInitialState = (): types.RevisionState => ({
  loading: false,
  selectedFilters: {
    organization_ids: [],
    product_key_ids: [],
    head_only: false,
    logistic_uuid: undefined,
    offset: 0,
    limit: 20,
  },
  revisions: [],
});

export default createReducer(getInitialState(), {
  [events.INIT]: () => getInitialState(),
  [events.SET_LOADING]: (
    state,
    { payload: loading }: ActionWithPayload<boolean>,
  ) => set(['loading'], loading, state),
  [events.RECEIVE_REVISIONS]: (
    state,
    { payload: revisions }: ActionWithPayload<types.ProductRevisionCompact[]>,
  ) => set(['revisions'], revisions, state),
  [events.UPDATE_FILTERS]: (
    state,
    { payload: filters }: ActionWithPayload<types.ProductRevisionFilter>,
  ) => set(['selectedFilters'], filters, state),
});
