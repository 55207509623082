import { ModuleWrapper } from 'redux/register';

import reducer, { FIELD_SUGGESTION_DASHBOARD_STORE_KEY } from './reducers';
import FieldSuggestionDashboardView from './views';

export default function FieldSuggestionDashboardContainer() {
  return (
    <ModuleWrapper
      reducerKey={FIELD_SUGGESTION_DASHBOARD_STORE_KEY}
      reducer={reducer}
    >
      <FieldSuggestionDashboardView />
    </ModuleWrapper>
  );
}
