import { createAction, createActionWithPayload } from 'utils/reducers';

import * as events from './events';
import * as types from './types';

export const init = createAction(events.INIT);

export const setLoading = createActionWithPayload<boolean>(events.SET_LOADING);
export const fetchQueueList = createAction(events.FETCH_QUEUE_LIST);
export const receiveQueueList = createActionWithPayload<types.Organization[]>(
  events.RECEIVE_QUEUE_LIST,
);
export const receiveQueuePage = createActionWithPayload<types.Page>(
  events.RECEIVE_QUEUE_PAGE,
);
export const fetchDetail = createActionWithPayload<number | null>(
  events.FETCH_DETAIL,
);
export const receiveDetail =
  createActionWithPayload<types.OrganizationDetail | null>(
    events.RECEIVE_DETAIL,
  );

export const selectOrganization =
  createActionWithPayload<types.SelectedOrganization | null>(
    events.SELECT_ORGANIZATION,
  );
export const fetchSources = createAction(events.FETCH_SOURCES);
export const receivedSources = createActionWithPayload<types.Source[]>(
  events.RECEIVE_SOURCES,
);
export const selectSource = createActionWithPayload<number | null>(
  events.SELECT_SOURCE,
);
export const selectPriority = createActionWithPayload<number | null>(
  events.SELECT_PRIORITY,
);
export const previousPage = createAction(events.PREVIOUS_PAGE);
export const nextPage = createAction(events.NEXT_PAGE);
export const changePageSize = createActionWithPayload<number>(
  events.CHANGE_PAGE_SIZE,
);

export const fetchQueueStatus = createAction(events.FETCH_QUEUE_STATUS);
export const receivedQueueStatus = createActionWithPayload<types.QueueStatus>(
  events.RECEIVE_QUEUE_STATUS,
);

export const reindexOrganization =
  createActionWithPayload<types.ManualOrganizationIndexRequest>(
    events.REINDEX_ORGANIZATION,
  );

export const fetchOrganization = createActionWithPayload<number>(
  events.FETCH_ORGANIZATION,
);

export const fetchOrganizationStatistics =
  createActionWithPayload<types.OrganizationIndexRequest>(
    events.FETCH_ORGANIZATION_STATISTICS,
  );
export const receiveOrganizationStatistics =
  createActionWithPayload<types.OrganizationIndexRequestStatistics>(
    events.RECEIVE_ORGANIZATION_STATISTICS,
  );
