import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FilterListItem from './item';
import './list.scss';

class FilterList extends PureComponent {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { filters, name, onChange } = this.props;
    return (
      <ul className="FilterList">
        {filters.map((filter) => (
          <FilterListItem
            key={JSON.stringify(filter)}
            filter={filter}
            name={name}
            onChange={onChange}
          />
        ))}
      </ul>
    );
  }
}

export default FilterList;
