import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';

import StatusBar from 'components/ui/status-bar';
import PcrModal from 'modules/io/gdsn/pcr-modal';
import { separateActions } from 'utils/redux';

import { deleteRelation, downloadGDSNDocument } from '../../actions';

import './row.scss';

const mapDispatchToProps = {
  downloadGDSNDocument,
  deleteRelation,
};

export class GDSNRelationListRow extends Component {
  static propTypes = {
    relation: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      downloadGDSNDocument: PropTypes.func.isRequired,
      deleteRelation: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      isPCRModalOpen: false,
    };
  }

  onClickExpand = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  onClickPCR = () => {
    const { isPCRModalOpen } = this.state;
    this.setState({ isPCRModalOpen: !isPCRModalOpen });
  };

  onClickDeleteRelation = () => {
    const { relation } = this.props;
    this.props.actions.deleteRelation(relation.id);
  };

  getDocumentState(doc) {
    return doc.transaction.ack.status !== 'ACCEPTED' ? 'FAILURE' : 'SUCCESS';
  }

  downloadDocument(messageID) {
    return () => this.props.actions.downloadGDSNDocument(messageID);
  }

  renderDocuments() {
    const { relation } = this.props;
    let lines = null;
    if (relation.documents !== undefined) {
      lines = relation.documents.reverse().map((doc) => (
        <div
          key={doc.id}
          className="GDSNRelationListRow GDSNRelationListRow_mini row"
        >
          <StatusBar type={this.getDocumentState(doc)} />
          <div className="col-xs-1">{doc.type}</div>
          <div className="col-xs-2">{doc.createdAt}</div>
          <div className="col-xs-2">{doc.status}</div>
          <div className="col-xs-1">{doc.message.state}</div>
          <div className="col-xs-1">
            <i
              className="mdi mdi-download GDSNRelationListDetailsIconDoc"
              onClick={this.downloadDocument(doc.message.id)}
            />
          </div>
          <div className="col-xs-4">
            {`${
              doc.transaction.ack.document_id
                ? `${doc.transaction.ack.status} @ ${doc.transaction.ack.createdAt}`
                : 'Not received'
            } `}
            {doc.transaction.ack.document_id && (
              <i
                className="mdi mdi-download GDSNRelationListDetailsIconDoc"
                onClick={this.downloadDocument(doc.transaction.ack.document_id)}
              />
            )}
          </div>
        </div>
      ));
    }
    return (
      <div className="GDSNRelationListRowWrapper">
        <div className="GDSNRelationListRow GDSNRelationListRow_mini GDSNRelationListRow__Header row">
          <div className="col-xs-1">Type</div>
          <div className="col-xs-2">Created At</div>
          <div className="col-xs-2">Status</div>
          <div className="col-xs-1">State</div>
          <div className="col-xs-1">Download</div>
          <div className="col-xs-2">GS1Response</div>
        </div>
        <div className="GDSNRelationListRow__Lines">{lines}</div>
      </div>
    );
  }

  renderParty(party) {
    const org =
      party.organizations.length > 0
        ? party.organizations[0]
        : {
            id: 0,
            name: 'Unknown',
          };
    return (
      <div className="col-xs-3">
        <div>
          <i className="mdi mdi-domain" />
          {` ${org.nameLegal} (${org.id})`}
        </div>
        <div>
          <i className="mdi mdi-steam" />
          {` ${party.gln}`}
        </div>
      </div>
    );
  }

  render() {
    const { relation } = this.props;
    const { isExpanded, isPCRModalOpen } = this.state;
    const expandClass = {
      GDSNRelationListDetailsIcon: true,
      'col-xs-0.5': true,
      mdi: true,
      'mdi-chevron-up': isExpanded,
      'mdi-chevron-down': !isExpanded,
    };
    return (
      <div className="GDSNRelationListRowWrapper">
        <div className="GDSNRelationListRow row">
          <div className="col-xs-2">
            <div>
              <i className="mdi mdi-barcode" />
              {` ${relation.business_location_gln}`}
            </div>
            <div>
              <i className="mdi mdi-currency-btc" />
              {` ${relation.currency_code}`}
            </div>
            <div>
              <i className="mdi mdi-format-align-right" />
              {` ${relation.trade_channel}`}
            </div>
          </div>
          {this.renderParty(relation.party_source)}
          <div className="col-xs-1">
            <i
              data-tip
              data-for={`relation-sync-status-${relation.id}`}
              className={`mdi mdi mdi-24px mdi-${
                relation.deleted === true
                  ? 'sync-off GDSNRelationIconDoc_red'
                  : 'sync GDSNRelationIconDoc_green'
              } GDSNPSRListDetailsIconDoc`}
            />
            {relation.deleted === true && (
              <Tooltip id={`relation-sync-status-${relation.id}`} place="right">
                The relation is deleted (By CIS DELETE)
                <br />A new one will be automatically created on next export
              </Tooltip>
            )}
            {relation.deleted === false && (
              <Tooltip id={`relation-sync-status-${relation.id}`} place="right">
                The relation is active
              </Tooltip>
            )}
          </div>
          {this.renderParty(relation.party_recipient)}
          <div className="col-xs-1">{`ID: ${relation.id}`}</div>
          <div className="col-xs-1 GDSNRelationListRow_actions">
            <Button secondary onClick={this.onClickPCR}>
              PCR
            </Button>
            <Button secondary onClick={this.onClickDeleteRelation}>
              Delete
            </Button>
            <div
              onClick={this.onClickExpand}
              className={classNames(expandClass)}
            />
          </div>
        </div>
        {isExpanded ? this.renderDocuments() : null}
        {isPCRModalOpen && (
          <PcrModal psrSyncId={relation.id} onClose={this.onClickPCR} />
        )}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions,
)(GDSNRelationListRow);
