import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';

import Image from 'components/ui/image';
import missingImg from 'images/missing.png';
import { formatMediaUrl } from 'utils/media';

import './item.scss';

export default class OrganizationGroupsItem extends PureComponent {
  static propTypes = {
    group: ImmutablePropTypes.map.isRequired,
    edit: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  onEdit = () => {
    const { group, edit } = this.props;
    edit(group);
  };

  render() {
    const { group, readOnly } = this.props;
    return (
      <div className="OrganizationGroupsItem">
        <div className="OrganizationGroupsItem__container">
          <div className="OrganizationGroupsItem__picture">
            <Image
              src={formatMediaUrl(
                `/group/picture/${group.get('id')}/logo.png?d=${Date.now()}`,
              )}
              fallbackSrc={missingImg}
            />
          </div>
          {!readOnly && (
            <div className="OrganizationGroupsItem__edit">
              <Button content="Edit" onClick={this.onEdit} primary />
            </div>
          )}
          <hr />
          <div className="OrganizationGroupsItem__name">
            {group.get('name')}
          </div>
        </div>
      </div>
    );
  }
}
