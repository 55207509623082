import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY } from './reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REDUCER_KEY];
}

export function selectGroups(state) {
  return selectState(state).get('groups');
}

export function selectSearch(state) {
  return selectState(state).get('search');
}

export function selectEdited(state) {
  return selectState(state).get('edited');
}

export function selectUploading(state) {
  return selectState(state).get('uploading');
}

export function selectSaving(state) {
  return selectState(state).get('saving');
}
