import { ModuleWrapper } from 'redux/register';

import { REDUCER_KEY } from './constants';
import reducer from './reducer';
import saga from './sagas';
import { OrganizationFieldGroupView } from './view';

export default function OrganizationFieldGroupsModule() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer} saga={saga}>
      <OrganizationFieldGroupView />
    </ModuleWrapper>
  );
}
