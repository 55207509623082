import { fromJS } from 'immutable';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { AddButton, Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import Search from 'components/ui/input/search';

import {
  addEntity,
  deleteEntity,
  getReferentialEntities,
  reset,
  save,
  updateEntityKey,
} from '../../actions';
import { getEntities, getIsLoading } from '../../selectors';
import ReferentialEntity from '../referential-entity';

import './referential.scss';

const mapStateToProps = (state, { referential }) => ({
  entities: getEntities(state)(referential.get('id')),
  isLoading: getIsLoading(state),
});

export class Referential extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    referential: ImmutablePropTypes.map.isRequired,
    entities: ImmutablePropTypes.map,
    isLoading: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  static LIMIT = 20;

  state = {
    page: 0,
    codeFilter: '',
  };

  componentDidMount() {
    const { dispatch, referential } = this.props;
    dispatch(getReferentialEntities(referential));
  }

  componentDidUpdate(prevProps) {
    const { dispatch, referential } = this.props;
    if (
      referential &&
      referential.get('id') !== prevProps.referential.get('id')
    ) {
      this.setState({ page: 0 }); // eslint-disable-line
      dispatch(getReferentialEntities(referential));
    }
  }

  onNextPage = () =>
    this.setState((prevState) => ({
      page: (prevState.page + 1) % (this.getMaxPage() + 1),
    }));

  onPreviousPage = () => {
    const maxPage = this.getMaxPage();
    this.setState((prevState) => ({
      page: Math.abs((prevState.page + maxPage) % (maxPage + 1)),
    }));
  };

  onFilterOnCode = (value) => {
    this.setState({ codeFilter: value, page: 0 });
  };

  onAddEntity = () => {
    const { dispatch, referential } = this.props;
    this.setState({ page: this.getMaxPage(), codeFilter: '' });
    return dispatch(addEntity(referential.get('id')));
  };

  onDeleteEntity = (index, isNewlyCreated) => {
    const { dispatch, referential } = this.props;
    return dispatch(deleteEntity(referential.get('id'), index, isNewlyCreated));
  };

  onUpdateEntity = (index, path, value) => {
    const { dispatch, referential } = this.props;
    return dispatch(updateEntityKey(referential.get('id'), index, path, value));
  };

  getMaxPage() {
    return parseInt(this.getFilteredEntities().size / Referential.LIMIT, 10);
  }

  getFilteredEntities() {
    const { entities } = this.props;
    const { codeFilter } = this.state;
    return entities
      .get('edited')
      .map((e, i) => fromJS({ index: i, entity: e }))
      .filter((e) => {
        const code = e.getIn(['entity', 'code']);
        return (
          !codeFilter ||
          (!!code && `${code}`.toLowerCase().includes(codeFilter.toLowerCase()))
        );
      });
  }

  reset = (dispatch, referential) => () => dispatch(reset(referential));

  save = (dispatch, referential) => () => dispatch(save(referential));

  renderHeader() {
    const { referential, entities, dispatch, readOnly } = this.props;
    const { codeFilter } = this.state;
    const dirty = entities.get('dirty');
    const buttons = (
      <div>
        <Button
          id="ReferentialDetail__ResetButton"
          onClick={this.reset(dispatch, referential)}
          key="discard"
          warning
          className="ReferentialDetail__DiscardButton"
          content="Discard"
          secondary
          disabled={!dirty}
        />
        <Button
          id="ReferentialDetail__SaveButton"
          onClick={this.save(dispatch, referential)}
          key="save"
          className="ReferentialDetail__SaveButton"
          content="Save"
          primary
          disabled={!dirty}
        />
      </div>
    );
    return (
      <div className="ReferentialDetail__header">
        <Search
          query={codeFilter}
          updateSearchQuery={this.onFilterOnCode}
          placeholder="Search by code..."
        />
        {readOnly ? null : buttons}
      </div>
    );
  }

  renderPagination() {
    const { entities } = this.props;
    if (entities.get('edited').size <= Referential.LIMIT) {
      return null;
    }
    const maxPage = this.getMaxPage();
    return (
      <div className="Referential__pagination">
        <span>{`Page ${this.state.page + 1} / ${maxPage + 1}`}</span>
        <div className="btn-group">
          <Button
            id="referential-prev-page-btn"
            onClick={this.onPreviousPage}
            secondary
          >
            <i className="mdi mdi-chevron-left Pagination__chevron" />
          </Button>
          <Button
            id="referential-next-page-btn"
            onClick={this.onNextPage}
            secondary
          >
            <i className="mdi mdi-chevron-right Pagination__chevron" />
          </Button>
        </div>
      </div>
    );
  }

  renderEntities() {
    const { entities, readOnly } = this.props;
    return (
      <table className="Referential__entities">
        <thead>
          <tr data-testid="referential-thead">
            <th className="ReferentialEntity__code">Code</th>
            <th className="ReferentialEntity__label">Label</th>
            <th className="ReferentialEntity__description">Description</th>
            <th className="ReferentialEntity__rank">Rank</th>
            <th className="ReferentialEntity__data">Data</th>
          </tr>
        </thead>
        <tbody data-testid="referential-tbody">
          {this.getFilteredEntities()
            .slice(
              this.state.page * Referential.LIMIT,
              (this.state.page + 1) * Referential.LIMIT,
            )
            .map((e) => (
              <ReferentialEntity
                key={e.get('index')}
                index={e.get('index')}
                source={entities.getIn(['source', e.get('index')])}
                edited={e.get('entity')}
                isNewlyCreated={isUndefined(e.getIn(['entity', 'id']))}
                onUpdate={this.onUpdateEntity}
                onDelete={this.onDeleteEntity}
                readOnly={readOnly}
              />
            ))}
        </tbody>
      </table>
    );
  }

  renderReferentialList() {
    const { referential, readOnly } = this.props;
    return (
      <div
        id={`referential-${referential.get('const')}`}
        className="Referential"
      >
        {this.renderHeader()}
        {this.renderPagination()}
        {this.renderEntities()}
        {!readOnly && (
          <div className="Referential__addEntity">
            <AddButton onClick={this.onAddEntity}>Add</AddButton>
          </div>
        )}
        {this.renderPagination()}
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {
      return (
        <center style={{ paddingTop: '100px' }}>
          <Spinner loading big />
        </center>
      );
    }
    if (!this.props.entities) {
      return null;
    }
    return (
      <div className="Referential__content">{this.renderReferentialList()}</div>
    );
  }
}

export default connect(mapStateToProps)(Referential);
