import classNames from 'classnames';
import { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';

import { Modal } from '@alkem/react-ui-modal';

interface Props {
  onImport: (organization: any) => void | Promise<void>;
  onClose: () => void | Promise<void>;
}

export const ImportModal = ({ onImport, onClose }: Props) => {
  const [files, setFiles] = useState<any[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const onImportCallback = useCallback(() => {
    if (files.length !== 1) {
      return;
    }

    setIsProcessing(true);
    const reader = new FileReader();
    reader.onload = function () {
      const content = reader.result as string;
      const organization = JSON.parse(content);
      onImport(organization);
      setIsProcessing(false);
    };
    reader.readAsText(files[0]);
  }, [files, onImport]);

  return (
    <Modal
      title="Import data"
      modalStyle="fullHeight"
      confirmButtonText="Import"
      onConfirm={onImportCallback}
      onClose={onClose}
      isProcessing={isProcessing}
    >
      <Dropzone multiple onDrop={setFiles}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: classNames(
                'Dropzone',
                isDragActive && 'Dropzone--active',
              ),
            })}
          >
            <input {...getInputProps()} />
            <div>
              {files.length > 0 ? (
                <>File selected: {files[0].name}</>
              ) : (
                <>
                  <b>Drag and drop your file here</b> or select a file from your
                  computer...
                </>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </Modal>
  );
};
