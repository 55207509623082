import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from '../constants/events';

const initialState = fromJS({
  organizationId: null,
  isModalOpened: false,
  saveInProgress: false,
  saveErrorMessage: '',
  featureStatus: {},
});

export default createReducer(initialState, {
  [events.ORGANIZATION_RECEIVED]: (state, { organization }) =>
    state.set('organizationId', organization.get('id')),
  [events.SAVE_MODAL_TOGGLED]: (state, { open }) =>
    state.set('isModalOpened', open).set('saveErrorMessage', ''),
  [events.SAVE_IN_PROGRESS]: (state, { value }) =>
    state.set('saveInProgress', value),
  [events.SET_SAVE_ERROR_MESSAGE]: (state, { value }) =>
    state.set('saveErrorMessage', value),
  [events.SAVE_MODAL_INIT_FEATURE_STATUS]: (state, { featureStatus }) =>
    state.set('featureStatus', featureStatus),
  [events.SAVE_MODAL_UPDATE_FEATURE_STATUS]: (state, { feature, status }) =>
    state.setIn(['featureStatus', feature], { value: status }),
});
