import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Radio } from '@alkem/react-ui-inputs';
import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';

import { SET_SELECTED_EXPORT_MAPPING } from '../../../actions/constants';
import { CONDITION_CAST_LIST, XPATH_TYPES } from '../../../constants';
import {
  Condition,
  ConditionCastOption,
  ConditionCastOptions,
  Conditions,
  ExportMapping,
  Xpath,
  XpathList,
} from '../../../types';
import { isConditionCastOption } from '../utils';

import { ExportMappingsItemCondition } from './condition';
import styles from './item-conditions.module.scss';

interface Props {
  name: string;
  element: Xpath;
  sameLevelElementChildren?: XpathList;
  exportMapping: ExportMapping;
  disabled?: boolean;
}
export function ExportMappingsItemConditions({
  name,
  element,
  sameLevelElementChildren,
  exportMapping,
  disabled = true,
}: Props) {
  const dispatch = useDispatch();
  const conditionCastOptions: ConditionCastOptions = CONDITION_CAST_LIST.map(
    (conditionCast) => ({ id: conditionCast }),
  );
  const [conditionCastOption, setConditionCastOption] = useState<
    ConditionCastOption | undefined
  >(undefined);
  const [conditions, setConditions] = useState<Conditions>(
    exportMapping.payload.conditions?.data || [],
  );
  const [mustUseOrLogic, setMustUseOrLogic] = useState<true | undefined>(
    exportMapping.payload.conditions?.must_use_or_logic,
  );
  const [mustCheckConditionsAtChildLevel, setMustCheckConditionsAtChildLevel] =
    useState<true | undefined>(
      exportMapping.payload.must_check_conditions_at_child_level,
    );

  const dispatchUpdatedConditions = (
    updatedConditions: Conditions,
    must_use_or_logic?: true,
    must_check_conditions_at_child_level?: true,
  ) => {
    dispatch({
      type: SET_SELECTED_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
          payload: {
            ...exportMapping.payload,
            must_check_conditions_at_child_level,
            conditions:
              updatedConditions.length === 0
                ? undefined
                : {
                    must_use_or_logic,
                    data: updatedConditions,
                  },
          },
        },
      },
    });
  };

  const addCondition = () => {
    if (conditionCastOption) {
      if (
        conditionCastOption.id === 'does_module_name_match_composition' ||
        conditionCastOption.id === 'not_empty_module'
      ) {
        const updatedConditions: Conditions = [
          ...conditions,
          { cast: conditionCastOption.id, data: true },
        ];
        setConditions(updatedConditions);
        dispatchUpdatedConditions(
          updatedConditions,
          mustUseOrLogic,
          mustCheckConditionsAtChildLevel,
        );
      } else {
        const updatedConditions: Conditions = [
          ...conditions,
          { cast: conditionCastOption.id },
        ];
        setConditions(updatedConditions);
        dispatchUpdatedConditions(
          updatedConditions,
          mustUseOrLogic,
          mustCheckConditionsAtChildLevel,
        );
      }
    }
  };

  const updateCondition = (index: number, condition: Condition) => {
    const updatedConditions = conditions.slice();
    updatedConditions[index] = {
      ...condition,
    };
    setConditions(updatedConditions);
    dispatchUpdatedConditions(
      updatedConditions,
      mustUseOrLogic,
      mustCheckConditionsAtChildLevel,
    );
  };

  const deleteCondition = (index: number) => {
    let updatedMustUseOrLogic = mustUseOrLogic;
    const updatedConditions = conditions.slice();
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);
    if (updatedConditions.length < 2) {
      updatedMustUseOrLogic = undefined;
      setMustUseOrLogic(updatedMustUseOrLogic);
    }
    dispatchUpdatedConditions(
      updatedConditions,
      updatedMustUseOrLogic,
      mustCheckConditionsAtChildLevel,
    );
  };

  const updateMustUseOrLogic = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedMustUseOrLogic = value === 'true' || undefined;
    setMustUseOrLogic(updatedMustUseOrLogic);
    dispatchUpdatedConditions(
      conditions,
      updatedMustUseOrLogic,
      mustCheckConditionsAtChildLevel,
    );
  };

  const updateMustCheckConditionsAtChildLevel = (event: {
    target: { value: string };
  }) => {
    const { value } = event.target;
    const updatedMustCheckConditionsAtChildLevel =
      value === 'true' || undefined;
    setMustCheckConditionsAtChildLevel(updatedMustCheckConditionsAtChildLevel);
    dispatchUpdatedConditions(
      conditions,
      mustUseOrLogic,
      updatedMustCheckConditionsAtChildLevel,
    );
  };

  const mustShowMustCheckConditionsAtChildLevel =
    element.type === XPATH_TYPES.list ||
    exportMapping.payload.field?.includes('.');

  return (
    <div
      data-testid="GDSNExportMappingsItem_conditions"
      className={classNames(styles.container, {
        [styles.containerDisabled]: disabled,
      })}
    >
      <div className={styles.sectionTitle}>Conditions</div>
      <InputWithLabel
        inputId={`gdsn-export-mappings-condition-list-${name}`}
        label={
          <Button
            id={`gdsn-export-mappings-condition-add-button-${name}`}
            content="Add"
            secondary
            onClick={addCondition}
            disabled={!conditionCastOption}
          />
        }
      >
        <div data-testid={`gdsn-export-mappings-condition-list-${name}`}>
          <TurboSelect
            id={`gdsn-export-mappings-condition-list-${name}`}
            options={conditionCastOptions}
            getOptionLabel={(condition) => condition.id}
            getOptionValue={(condition) => condition.id}
            value={conditionCastOption}
            isMulti={false}
            isSearchable={false}
            isClearable
            onChange={(value: TurboSelectOption<ConditionCastOption>) =>
              setConditionCastOption(
                isConditionCastOption(value) ? value : undefined,
              )
            }
            placeholder="Select..."
          />
        </div>
      </InputWithLabel>
      {conditions.length > 1 && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-condition-logic-${name}`}
          label="Logic"
        >
          <div data-testid={`gdsn-export-mappings-condition-logic-${name}`}>
            <Radio
              id={`gdsn-export-mappings-condition-logic-${name}`}
              value={mustUseOrLogic}
              options={[
                { label: 'And', value: undefined },
                { label: 'Or', value: true },
              ]}
              onChange={updateMustUseOrLogic}
            />
          </div>
        </InputWithLabel>
      )}
      {mustShowMustCheckConditionsAtChildLevel && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-condition-must-check-conditions-at-child-level-${name}`}
          label="Must check conditions at child level"
        >
          <div
            data-testid={`gdsn-export-mappings-condition-must-check-conditions-at-child-level-${name}`}
          >
            <Radio
              id={`gdsn-export-mappings-condition-must-check-conditions-at-child-level-${name}`}
              value={mustCheckConditionsAtChildLevel}
              options={[
                { label: 'TRUE', value: true },
                { label: 'FALSE', value: undefined },
              ]}
              onChange={updateMustCheckConditionsAtChildLevel}
            />
          </div>
        </InputWithLabel>
      )}
      {conditions.map((condition, index) => (
        <ExportMappingsItemCondition
          key={`condition_${index}_${name}`}
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          deleteCondition={deleteCondition}
          sameLevelElementChildren={sameLevelElementChildren}
          name={`condition_${index}_${name}`}
          disabled={disabled}
        />
      ))}
    </div>
  );
}
