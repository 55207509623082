import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import qs from 'querystringify';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';
import { Select, SimpleSelect } from '@alkem/react-ui-select';
import { Spinner } from '@alkem/react-ui-spinner';

import { notificationError } from 'actions/notifications';
import * as routes from 'constants/routes';
import { withLocation } from 'hocs/with-location';
import { withNavigate } from 'hocs/with-navigate';
import productGoApiDefault, { createProductApi } from 'resources/productGoApi';

import Graph from './components/graph';
import NodeList from './components/node-list';
import ProductTimeline from './components/product-timeline';
import Runner from './components/runner';
import './play.scss';

const LOCAL_PRODUCT_HOST = 'http://127.0.0.1:6363';

const mapDispatchToProps = {
  notifyError: notificationError,
};

class ProductWorkflowPlayground extends PureComponent {
  static propTypes = {
    notifyError: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  state = {
    graphs: [],
    selectedGraphName: '',
    currentGraph: {},
    subGraphs: {},
    allNodes: [],
    currentTab: 0,
    runResults: {
      res: null,
      error: null,
    },

    api: 'default',
    productGoApi: productGoApiDefault,
    busy: false,
  };

  setTab = (currentTab) => () => {
    this.setState({ currentTab });
  };

  reloadGraph = memoize((selectedGraphName) => () => {
    this.fetchGraph(selectedGraphName.replace('.yml', ''));
  });

  componentDidMount() {
    this.fetchGraphs();
    this.fetchAllNodes();

    const query = qs.parse(this.props.location.search);
    if (query.graph) {
      this.onSelectGraph({ value: query.graph }, false);
    }
  }

  onSelectGraph = ({ value }, replace = true) => {
    const { selectedGraphName } = this.state;
    this.setState({ selectedGraphName: value });
    if (selectedGraphName !== value) {
      this.fetchGraph(value);
      if (replace) {
        const query = { graph: value };
        this.props.navigate({
          pathname: this.props.location.pathname,
          search: qs.stringify(query),
        });
      }
    }
  };

  onUnselectGraph = () => {
    this.setState({
      selectedGraphName: '',
      currentGraph: {},
      subGraphs: {},
      runResults: {
        res: null,
        error: null,
      },
    });

    const query = {};
    this.props.navigate({
      pathname: this.props.location.pathname,
      search: qs.stringify(query),
    });
  };

  onRunFinished = ({ res, error }) =>
    this.setState({ runResults: { res, error } });

  onSelectApi = (option) => {
    let api = 'default';
    let productGoApi = productGoApiDefault;

    if (option.value === 'local') {
      api = 'local';
      productGoApi = createProductApi(LOCAL_PRODUCT_HOST);
    }

    this.setState(
      {
        api,
        productGoApi,
      },
      () => {
        this.fetchGraphs();
        this.fetchAllNodes();
      },
    );
  };

  fetchGraph = async (graphName) => {
    const { productGoApi } = this.state;
    this.setState({ busy: true });
    let currentGraph;
    try {
      const response = await productGoApi.get(
        `/product/v2/graphs/load?${qs.stringify({ graphName })}`,
      );
      currentGraph = response.data;
    } catch (error) {
      this.props.notifyError(`could not load the graph ${graphName}: ${error}`);
    }
    if (currentGraph) {
      this.setState({ currentGraph, subGraphs: {} });
    }
    this.setState({ busy: false });
  };

  fetchSubGraph = async (graphName) => {
    const cleanGraphName = graphName
      .slice(graphName.lastIndexOf('/') + 1)
      .replace('.go', '')
      .replace('.', '/');

    const { productGoApi, subGraphs } = this.state;
    try {
      const { data } = await productGoApi.get(
        `/product/v2/graphs/load?${qs.stringify({
          graphName: cleanGraphName,
        })}`,
      );
      this.setState({
        subGraphs: Object.assign({}, subGraphs, {
          [`${data.pkg}.${data.type}`]: data,
        }),
      });
    } catch (error) {
      this.props.notifyError(
        `could not load the graph ${cleanGraphName}: ${error}`,
      );
    }
  };

  formatGraphOption = (opt, path) => {
    const label = path ? `${path}/${opt.label}` : opt.label;

    return {
      id: label,
      value: label.replace('.yml', ''),
      label: label.replace('.yml', ''),
      children: (opt.children || []).map((c) =>
        this.formatGraphOption(c, label),
      ),
    };
  };

  async fetchGraphs() {
    const { productGoApi } = this.state;
    try {
      const response = await productGoApi.listGraphs();
      this.setState({ graphs: response.data });
    } catch (error) {
      this.props.notifyError(`could not fetch the graphs: ${error}`);
    }
  }

  async fetchAllNodes() {
    const { productGoApi } = this.state;
    try {
      const response = await productGoApi.get('/product/v2/nodes');
      this.setState({
        allNodes: response.data.nodes.map((node) =>
          Object.assign(node, {
            id: `${node.pkg}.${node.type}-${node.method}`,
          }),
        ),
      });
    } catch (error) {
      this.props.notifyError(`could not fetch the nodes: ${error}`);
    }
  }

  render() {
    const {
      selectedGraphName,
      currentGraph,
      subGraphs,
      currentTab,
      runResults,
      allNodes,
      api,
      productGoApi,
      graphs,
      busy,
    } = this.state;

    return (
      <div id="play-ground">
        <HeaderLayout
          title="Product workflow playground"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        >
          <div className="ProductWorkflowPlayground__header">
            <div className="ApiSelect">
              Host:
              <SimpleSelect
                id="product-playground-api-select"
                options={[
                  { value: 'default', host: productGoApiDefault.host },
                  { value: 'local', host: LOCAL_PRODUCT_HOST },
                ].map((opt) => ({
                  id: opt.value,
                  value: opt.value,
                  label: `${opt.value} (${opt.host})`,
                }))}
                value={{
                  id: api,
                  value: api,
                  label: `${api} (${productGoApi.host})`,
                }}
                onSelect={this.onSelectApi}
              />
            </div>
          </div>
        </HeaderLayout>
        <div className="ProductWorkflowPlayground__meta">
          <Select
            id="play-yml-graph-select"
            options={graphs.map((g) => this.formatGraphOption(g))}
            values={
              selectedGraphName ? [{ label: selectedGraphName }] : undefined
            }
            placeholder="Choose an existing graph..."
            onValueAdd={this.onSelectGraph}
            onValueDelete={this.onUnselectGraph}
            inputable
          />
          <div>
            <Button
              onClick={this.setTab(0)}
              primary={currentTab === 0}
              content="Graph"
            />
            <Button
              onClick={this.setTab(1)}
              primary={currentTab === 1}
              content="Timeline"
              disabled={!runResults.res}
            />
            <Button
              onClick={this.setTab(2)}
              primary={currentTab === 2}
              content="Nodes"
            />
            <Button
              onClick={this.setTab(3)}
              primary={currentTab === 3}
              content="Runner"
            />
            <span className="ProductWorkflowPlayground__separator" />
            <Button
              onClick={this.setTab(4)}
              primary={currentTab === 4}
              content="All nodes"
            />
          </div>
        </div>
        {busy && <Spinner big label="loading..." />}
        {currentTab === 0 && !busy && (
          <div className="ProductWorkflowPlayground__content">
            <Graph
              graph={currentGraph}
              subGraphs={subGraphs}
              reloadGraph={this.reloadGraph(selectedGraphName)}
              runResults={runResults.res || {}}
              onLoadSubgraph={this.fetchSubGraph}
            />
          </div>
        )}
        {currentTab === 1 && !busy && (
          <div>
            {runResults.res && (
              <ProductTimeline trace={runResults.res.inspectors.trace} />
            )}
          </div>
        )}
        {currentTab === 2 && !busy && (
          <div>
            <NodeList
              id="graph-nodes-list"
              nodes={currentGraph.nodes || []}
              edges={currentGraph.edges}
              runResults={runResults.res}
            />
          </div>
        )}
        <Runner
          graph={currentGraph}
          onRunFinished={this.onRunFinished}
          result={runResults.res}
          productGoApi={productGoApi}
          isVisible={currentTab === 3 && !busy}
        />
        {currentTab === 4 && !busy && (
          <div>
            <NodeList id="all-nodes-list" nodes={allNodes} />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  () => ({}),
  mapDispatchToProps,
)(withLocation(withNavigate(ProductWorkflowPlayground)));
