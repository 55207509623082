import { concat, pullAt, set, update } from 'lodash/fp';

import * as notificationConstants from 'constants/events/notifications';
import { createReducer } from 'utils/reducers';

const initialState = {
  displayed: false,
  messages: [],
};

export default createReducer(initialState, {
  [notificationConstants.ADD_NOTIFICATION]: (state, action) => {
    const message = {
      message: action.message,
      style: action.style,
      context: action.context,
    };
    return update('messages', concat([message]), state);
  },

  [notificationConstants.REMOVE_NOTIFICATION]: (state, action) =>
    update('messages', pullAt(action.index), state),

  [notificationConstants.NOTIFICATION_DISPLAYED]: (state) =>
    set('displayed', true, state),

  [notificationConstants.NOTIFICATION_HIDDEN]: (state) =>
    set('displayed', false, state),
});
