import classNames from 'classnames';
import { useRef } from 'react';

import Well from '@alkem/react-ui-well';

import { IMPORT_MAPPING_NOT_SUPPORTED_TYPES } from 'modules/io/gdsn/imports/constants';

import { ImportMappingsItemChildren } from '..';
import { ImportMapping, ImportMappingDetails } from '../../../../types';
import { ImportMappingsItemWhichCanNotBeCreatedMessage } from '../../can-not-be-created-message';
import { ImportMappingsItemAutomaticProperties } from '../../item-automatic-properties';
import { ImportMappingsItemFallbackProperties } from '../../item-fallback-properties';
import { ImportMappingsItemMandatoryProperties } from '../../item-mandatory-properties';
import { ImportMappingsItemOptionalProperties } from '../../item-optional-properties';

import './item-child.scss';

interface Props {
  child: ImportMappingDetails;
  childName: string;
  importMapping: ImportMapping;
  isFirst: boolean;
  parent?: ImportMappingDetails;
  parentName?: string;
  parentNames: string[];
  parents: ImportMappingDetails[];
}
export function ImportMappingsItemChild({
  child,
  childName,
  importMapping,
  isFirst,
  parent,
  parentName,
  parentNames,
  parents,
}: Props): JSX.Element {
  const childXpath = useRef(child.xpath).current;
  const canNotBeCreated =
    !child.cast || IMPORT_MAPPING_NOT_SUPPORTED_TYPES.includes(child.cast);
  let headerText = childName;
  if (canNotBeCreated) {
    headerText = `${childName} (can't be mapped)`;
  } else if (
    childXpath.length === 1 &&
    childXpath[0] === '' &&
    !importMapping.isNew
  ) {
    headerText = `${childName} (isn't mapped yet)`;
  }
  const newParents = [parent || importMapping.details, ...parents];
  const newParentNames = [parentName || importMapping.field, ...parentNames];
  return (
    <div
      className={classNames({
        GDSNImportMappingsItemChild: isFirst,
        GDSNImportMappingsItemChild__bordered: !isFirst,
      })}
    >
      <Well v2 header={headerText}>
        <ImportMappingsItemAutomaticProperties
          fieldName={childName}
          details={child}
          isChild
        />
        {canNotBeCreated ? (
          <ImportMappingsItemWhichCanNotBeCreatedMessage type={child.cast} />
        ) : (
          <>
            <ImportMappingsItemMandatoryProperties
              details={child}
              fieldName={childName}
              importMapping={importMapping}
              parentNames={newParentNames}
              parents={newParents}
            />
            <ImportMappingsItemOptionalProperties
              details={child}
              fieldName={childName}
              importMapping={importMapping}
              parentNames={newParentNames}
              parents={newParents}
            />
            <ImportMappingsItemFallbackProperties
              details={child}
              fieldName={childName}
              importMapping={importMapping}
              parentNames={newParentNames}
              parents={newParents}
            />
            <ImportMappingsItemChildren
              importMapping={importMapping}
              importMappingChildren={child.children}
              parent={child}
              parentName={childName}
              parentNames={newParentNames}
              parents={newParents}
            />
          </>
        )}
      </Well>
    </div>
  );
}
