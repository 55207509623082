import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import StatusBar from 'components/ui/status-bar';
import { separateActions } from 'utils/redux';

import { downloadGDSNDocument, sendDeleteParty } from '../../actions';

import './row.scss';

const mapDispatchToProps = {
  downloadGDSNDocument,
  sendDeleteParty,
};

export class GDSNPartyListRow extends Component {
  static propTypes = {
    party: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      downloadGDSNDocument: PropTypes.func.isRequired,
      sendDeleteParty: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  onClickExpand = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  getDocumentState(doc) {
    return doc.transaction.ack.status !== 'ACCEPTED' ? 'FAILURE' : 'SUCCESS';
  }

  getPartyState(entity) {
    if (entity.is_alkemics_party === false) {
      return 'EMPTY';
    }
    return !entity.documents ||
      entity.documents.filter(
        (doc) => doc.transaction.ack.status === 'ACCEPTED',
      ).length === 0
      ? 'FAILURE'
      : 'SUCCESS';
  }

  renderDocuments() {
    const { party } = this.props;
    let lines = null;
    if (party.documents !== undefined) {
      lines = party.documents.map((doc) => (
        <div
          key={doc.id}
          className="GDSNPartyListRow GDSNPartyListRow_mini row"
        >
          <StatusBar type={this.getDocumentState(doc)} />
          <div className="col-xs-1">{doc.type}</div>
          <div className="col-xs-2">{doc.createdAt}</div>
          <div className="col-xs-2">{doc.status}</div>
          <div className="col-xs-1">{doc.message.state}</div>
          <div className="col-xs-1">
            <i
              className="mdi mdi-download GDSNPartyListDetailsIconDoc"
              onClick={() => {
                this.props.actions.downloadGDSNDocument(doc.message.id);
              }}
            />
          </div>
          <div className="col-xs-2">{doc.transaction.type}</div>
          <div className="col-xs-2">
            {`${
              doc.transaction.ack.document_id
                ? `${doc.transaction.ack.status} @ ${doc.transaction.ack.createdAt}`
                : 'Not received'
            } `}
            {doc.transaction.ack.document_id && (
              <i
                className="mdi mdi-download GDSNPartyListDetailsIconDoc"
                onClick={() => {
                  this.props.actions.downloadGDSNDocument(
                    doc.transaction.ack.document_id,
                  );
                }}
              />
            )}
          </div>
        </div>
      ));
    }
    return (
      <div className="GDSNPartyListRowWrapper">
        <div className="GDSNPartyListRow GDSNPartyListRow_mini GDSNPartyListRow__Header row">
          <div className="col-xs-1">Type</div>
          <div className="col-xs-2">Created At</div>
          <div className="col-xs-2">Status</div>
          <div className="col-xs-1">State</div>
          <div className="col-xs-1">Download</div>
          <div className="col-xs-2">Type</div>
          <div className="col-xs-2">GS1Response</div>
        </div>
        <div className="GDSNPartyListRow__Lines">{lines}</div>
      </div>
    );
  }

  renderParty(party) {
    if (party.organizations.length > 0) {
      return (
        <div className="GDSNPartyListRow_boldy">
          <i className="mdi mdi-domain" />
          {` ${party.organizations[0].nameLegal} (${party.organizations[0].id})`}
          {party.organizations.length > 1 &&
            ` +${party.organizations.length - 1}`}
        </div>
      );
    }
    if (party.nameLegal === null) {
      return <div />;
    }
    return (
      <div className="GDSNPartyListRow_boldy">
        <i className="mdi mdi-domain" />
        {` ${party.nameLegal}`}
      </div>
    );
  }

  render() {
    const { party } = this.props;
    const { isExpanded } = this.state;
    const expandClass = {
      GDSNPartyListDetailsIcon: true,
      'col-xs-0.5': true,
      mdi: true,
      'mdi-chevron-up': party.documents.length > 0 && isExpanded,
      'mdi-chevron-down': party.documents.length > 0 && !isExpanded,
      hidden: party.documents.length === 0,
    };
    const deleteClass = {
      GDSNPartyListDetailsIcon: true,
      mdi: true,
      'mdi-24px': true,
      'mdi-delete-circle': true,
      hidden: party.documents.length === 0,
    };
    return (
      <div className="GDSNPartyListRowWrapper">
        <div key={party.id} id={party.id} className="GDSNPartyListRow row">
          <div className="col-xs-0 GDSNPartyList_StatusBar">
            <StatusBar type={this.getPartyState(party)} />
          </div>
          <div className="col-xs-9">
            {this.renderParty(party)}
            <div>
              <i className="mdi mdi-apps" />
              {` ${party.gln}`}
            </div>
            {party.datapool && (
              <div>
                <i className="mdi mdi-steam" />
                {` ${party.datapool.name} (${party.datapool.gln})`}
              </div>
            )}
          </div>
          <div className="col-xs-2 GDSNPartyListRow_actions">
            <div
              onClick={this.onClickExpand}
              className={classNames(expandClass)}
            />
            <div
              data-toggle="tooltip"
              title="/!\ CAUTION /!\ This will send a DELETE message to global registery."
              onClick={() => {
                this.props.actions.sendDeleteParty(party);
              }}
              className={classNames(deleteClass)}
            />
          </div>
        </div>
        {isExpanded ? this.renderDocuments() : null}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions,
)(GDSNPartyListRow);
