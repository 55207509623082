import classNames from 'classnames';
import { fromJS } from 'immutable';
import { isNumber } from 'lodash';
import moment from 'moment';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { KindsIndex } from 'redux/state/kinds';

import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import Tooltip from '@alkem/react-ui-tooltip';

import InputWithLabel from 'components/input-with-label';
import * as routes from 'constants/routes';
import { Rule } from 'modules/validation-dashboard/types';
import { stopPropagation } from 'modules/validation-dashboard/utils';
import { fill } from 'utils/routes';

import { ruleEntityTypesMap, ruleTemplateLabels } from '../../constants';
import { ApplicableKindsSummary } from '../applicable-kinds/applicable-kinds-summary/applicable-kinds-summary';
import { ConditionsDisplay } from '../rule-editor/validation-conditions-display';
import { ValidationRuleHeader } from '../rule-header';

interface Props {
  rule: Rule;
  showSummary: boolean;
  kinds: KindsIndex;
  isCheckboxChecked: boolean;
  isCheckboxHighlighted: boolean;

  onSelectRule: (event: ChangeEvent<HTMLInputElement>) => void;
  deleteRule?: (ruleId: number) => void;
  forbiddenKinds?: object;
  forbiddenTypePackagings?: object;
}

export const ValidationRuleTags = ({
  ruleEntityType,
  ruleId,
  ruleTemplateType,
}: {
  ruleEntityType: any;
  ruleTemplateType: string;
  ruleId?: number;
}) => {
  return (
    <div className="alk-flex alk-flex-row ValidationRuleTags">
      {ruleEntityType && (
        <div
          data-tip
          data-for={`entity-type-${ruleId}`}
          onClick={stopPropagation}
        >
          <div className="ValidationRule__templateType">
            {ruleEntityType.label}
          </div>
          <Tooltip
            sanitize={(label: string) => label.trim()}
            id={`entity-type-${ruleId}`}
            place="right"
          >
            {ruleEntityType.help || ruleEntityType.label}
          </Tooltip>
        </div>
      )}
      {ruleTemplateType === ruleTemplateLabels.CUSTOM && (
        <div
          data-tip
          data-for={`template-type-${ruleId}`}
          onClick={stopPropagation}
        >
          <div className="ValidationRule__templateType">CUSTOM</div>
          <Tooltip
            sanitize={(label: string) => label.trim()}
            id={`template-type-${ruleId}`}
            place="right"
          >
            Rule was not generated but written by POPs team.
          </Tooltip>
        </div>
      )}
    </div>
  );
};
export const getEntityType = (rule: Rule) =>
  isNumber(rule?.ruleEntityType)
    ? ruleEntityTypesMap[rule.ruleEntityType]
    : null;

export const ValidationRuleBeta = ({
  rule,
  kinds,
  isCheckboxChecked,
  isCheckboxHighlighted,
  onSelectRule,
  showSummary = false,
}: Props) => {
  const [summaryShown, setSummaryShown] = useState(false);

  useEffect(() => {
    setSummaryShown(showSummary);
  }, [showSummary]);

  const ruleEntityType = getEntityType(rule);
  const navigate = useNavigate();
  const location = useLocation();
  const openRule = () => {
    navigate({
      pathname: fill(routes.validationRuleEditor, rule.id),
      search: location.search,
    });
  };

  const handleSummaryToggle = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    setSummaryShown((shown) => !shown);
  };

  return (
    <div
      className={`ValidationRule alk-flex`}
      key={`${rule.id}`}
      data-testid={`validation-rule-${rule.id}`}
    >
      <div
        className={classNames('ValidationRuleCheckboxContainer alk-flex', {
          'ValidationRuleCheckboxContainer--highlighted': isCheckboxHighlighted,
        })}
      >
        <Checkbox
          onChangeWithEvent={onSelectRule}
          checked={isCheckboxChecked}
          id={'rule-' + rule.id}
          testid={'checkbox-rule-' + rule.id}
        />
      </div>
      <div
        className="ValidationRuleContainer alk-flex alk-flex-row alk-flex-space-between"
        onClick={openRule}
      >
        <ValidationRuleHeader
          rule={rule as Rule}
          allowDuplicate={rule.template?.label === ruleTemplateLabels.CUSTOM}
          allowDelete={!!rule.ruleSet}
        />
        <div className="ValidationRuleInfos alk-flex alk-flex-space-between">
          <ValidationRuleTags
            ruleEntityType={ruleEntityType}
            ruleId={rule.id}
            ruleTemplateType={rule.template?.label as string}
          />
          <div className="ValidationRuleInfos__contextual">
            <small>Created {moment(rule.createdAt).format('DD/MM/YYYY')}</small>
            &nbsp;
            <small>Updated {moment(rule.updatedAt).format('DD/MM/YYYY')}</small>
            &nbsp;
            <small>
              Enforced by <b>{rule.organizations?.length}</b> organizations
            </small>
          </div>

          <div data-tip data-for={`preview-rule-${rule.id}-button`}>
            <Button
              link
              className="ValidationRuleInfos__ShowSummaryButton"
              onClick={handleSummaryToggle}
            >
              {summaryShown ? '\u25be' : '\u25b8'}
            </Button>
            <Tooltip id={`preview-rule-${rule.id}-button`} place="bottom">
              Display rule content
            </Tooltip>
          </div>
        </div>

        {summaryShown && (
          <div
            className="ValidationRule__summary"
            onClick={stopPropagation}
            data-testid="ValidationRule-summary"
          >
            <ConditionsDisplay
              rule={rule}
              id={`validation-rule-${rule.id}-summary`}
            />
            <hr />

            <InputWithLabel
              inputId={`applicable-kinds-display`}
              label="Applicable kinds"
            >
              <ApplicableKindsSummary
                applicableKinds={fromJS(rule.applicableForKinds)}
                kinds={kinds}
                kindsAreManuallySelected={rule.kindsAreManuallySelected}
              />
            </InputWithLabel>
          </div>
        )}
      </div>
    </div>
  );
};
