import classNames from 'classnames';

import './input-with-label.scss';

type Props = {
  inputId: string;
  children: React.ReactNode;
  label: React.ReactNode | string;
  extraInputClassNames?: Object;
  extraLabelClassNames?: Object;
  testid?: string;
};

const InputWithLabel = ({
  inputId,
  children,
  label,
  extraInputClassNames = {},
  extraLabelClassNames = {},
  testid,
}: Props) => {
  const inputClassNames = {
    InputWithLabel__input: true,
    ...extraInputClassNames,
  };

  const labelClassNames = {
    InputWithLabel__label: true,
    ...extraLabelClassNames,
  };

  return (
    <div className="InputWithLabel" data-testid={testid}>
      <div className={classNames(labelClassNames)}>
        <label htmlFor={inputId}>{label}</label>
      </div>
      <div className={classNames(inputClassNames)}>{children}</div>
    </div>
  );
};

export default InputWithLabel;
export { InputWithLabel };
