import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Checkbox, Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';

import InputWithLabel from 'components/input-with-label';
import { separateActions } from 'utils/redux';

import {
  closePartyModal,
  saveGDSNParty,
  updateGDSNPartyAttr,
} from '../actions';
import { selectModal } from '../selectors';

import './modal.scss';

const mapStateToProps = (state) => ({
  party: selectModal(state).party,
  isOpen: selectModal(state).isOpen,
});

const mapDispatchToProps = {
  saveGDSNParty,
  updateGDSNPartyAttr,
  closePartyModal,
};

const partyInRoleCode = [
  { value: 'BUYER', label: 'Buyer' },
  { value: 'SELLER', label: 'Seller' },
];

const processCapabilityCode = [
  { value: 'DATA_SYNCHRONISATION', label: 'Data Synchronisation' },
  { value: 'ITEM_AUTHORISATION', label: 'Item Authorisation' },
  { value: 'ITEM_FUTURISATION', label: 'Item Futurisation' },
  { value: 'PARTY_SYNCHRONISATION', label: 'Party Synchronisation' },
  { value: 'PRICE_SYNCHRONISATION', label: 'Price Synchronisation' },
];

const contactTypeCode = [
  { value: 'AA', label: '[AA] Insurance contact' },
  { value: 'AD', label: '[AD] Accounting contact' },
  { value: 'AE', label: '[AE] Contract contact' },
  { value: 'AM', label: '[AM] Claims contact' },
  { value: 'AP', label: '[AP] Accounts payable contact' },
  { value: 'AR', label: '[AR] Accounts receivable contact' },
  { value: 'BC', label: '[BC] Banking contact' },
  { value: 'BO', label: '[BO] After business hours contact' },
  { value: 'BVP', label: '[BVP] Production Facility' },
  { value: 'BXA', label: '[BXA] Administrative' },
  { value: 'BYF', label: '[BYF] Financial' },
  { value: 'BZL', label: '[BZL] Licensee Registrar' },
  { value: 'CB', label: '[CB] Changed by' },
  { value: 'CKE', label: '[CKE] Cook' },
  { value: 'CR', label: '[CR] Customer relations' },
  { value: 'CXC', label: '[CXC] Consumer Support' },
  { value: 'CYC', label: '[CYC] Customer Support' },
  { value: 'CZL', label: '[CZL] Logistics' },
  { value: 'DIS', label: '[DIS] Distributor' },
  { value: 'DL', label: '[DL] Delivery contact' },
  { value: 'DMO', label: '[DMO] Operations' },
  { value: 'DNR', label: '[DNR] Recall Support' },
  { value: 'DOG', label: '[DOG] GDS Contact' },
  { value: 'DPP', label: '[DPP] Packaging engineer' },
  { value: 'DQT', label: '[DQT] Target Market Information Provider' },
  { value: 'DSU', label: '[DSU] Unspecified' },
  { value: 'ED', label: '[ED] Engineering contact' },
  { value: 'EXP', label: '[EXP] Exporter' },
  { value: 'GR', label: '[GR] Goods receiving contact' },
  { value: 'HE', label: '[HE] Emergency dangerous goods contact' },
  { value: 'HG', label: '[HG] Dangerous goods contact' },
  { value: 'IC', label: '[IC] Information contact' },
  { value: 'IMP', label: '[IMP] Importer' },
  { value: 'LO', label: '[LO] Place of collection contact' },
  { value: 'MGR', label: '[MGR] Manager' },
  { value: 'NT', label: '[NT] Notification contact' },
  { value: 'OC', label: '[OC] Order contact' },
  { value: 'PD', label: '[PD] Purchasing contact' },
  { value: 'PM', label: '[PM] Product management contact' },
  { value: 'PRC', label: '[PRC] Product Recall Notification Contact' },
  { value: 'PRM', label: '[PRM] Product Recall Media Contact' },
  { value: 'PRR', label: '[PRR] Product Recall Removal Contact' },
  { value: 'QC', label: '[QC] Quality coordinator contact' },
  { value: 'SA', label: '[SA] Sales administration' },
  { value: 'SD', label: '[SD] Shipping contact' },
  { value: 'SR', label: '[SR] Sales representative or department' },
  { value: 'TA', label: '[TA] Traffic administrator' },
  { value: 'TD', label: '[TD] Test contact' },
  { value: 'TR', label: '[TR] Transport contact' },
  { value: 'WH', label: '[WH] Warehouse' },
  { value: 'WLS', label: '[WLS] Wholesaler' },
];

export class GDSNPartyModal extends Component {
  static propTypes = {
    party: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      closePartyModal: PropTypes.func.isRequired,
      saveGDSNParty: PropTypes.func.isRequired,
      updateGDSNPartyAttr: PropTypes.func.isRequired,
    }).isRequired,
  };

  onChange =
    (field, fn = (event) => event.target.value) =>
    (event) => {
      this.props.actions.updateGDSNPartyAttr([field], fn(event));
    };

  render() {
    const { actions, party, isOpen } = this.props;
    if (!isOpen) {
      return null;
    }
    const title = party.get('id')
      ? `Updating GDSN Party ${party.get('name')} (${party.get('id')})`
      : 'New GDSN Party';
    return (
      <Modal
        title={title}
        modalStyle="fullHeight"
        confirmButtonText="Save"
        onConfirm={actions.saveGDSNParty}
        onClose={actions.closePartyModal}
      >
        <div className="GDSNPartyModal_Body">
          <div className="GDSNPartyModal__Form">
            <div className="GDSNPartyModal__Line">
              <h3>General</h3>
              <InputWithLabel
                inputId={`party-${party.get('id')}-nameLegal`}
                label="Name"
              >
                <Text
                  id={`party-${party.get('id')}-nameLegal`}
                  value={party.get('nameLegal')}
                  placeholder="nameLegal"
                  onChange={this.onChange('nameLegal')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-gln`}
                label="GLN"
              >
                <Text
                  id={`party-${party.get('id')}-gln`}
                  value={party.get('gln')}
                  placeholder="gln"
                  onChange={this.onChange('gln')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-isPartyActive`}
                label=""
              >
                <Checkbox
                  id={`party-${party.get('id')}-isPartyActive`}
                  checked={party.getIn(['isPartyActive'])}
                  onChange={this.onChange(
                    'isPartyActive',
                    (event) => event.target.checked,
                  )}
                  label={<div className="GDSNPartyModal_Checkbox">Active</div>}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-partyInRole_code`}
                label="Role code"
              >
                <SimpleSelect
                  id={`party-${party.get('id')}-partyInRole_code`}
                  options={partyInRoleCode}
                  value={partyInRoleCode.find(
                    (o) => o.value === party.getIn(['partyInRole_code']),
                  )}
                  onSelect={this.onChange(
                    'partyInRole_code',
                    (event) => event.value,
                  )}
                  autoSize
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-partyInRole_name`}
                label="Role Name"
              >
                <Text
                  id={`party-${party.get('id')}-partyInRole_name`}
                  value={party.get('partyInRole_name')}
                  placeholder="Role Name"
                  onChange={this.onChange('partyInRole_name')}
                />
              </InputWithLabel>
              <hr />
              <h3>Localization</h3>
              <InputWithLabel
                inputId={`party-${party.get('id')}-country`}
                label="Country"
              >
                <Text
                  id={`party-${party.get('id')}-country`}
                  value={party.get('country')}
                  placeholder="country"
                  onChange={this.onChange('country')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-countryCode`}
                label="Country Code"
              >
                <Text
                  id={`party-${party.get('id')}-countryCode`}
                  value={party.get('countryCode')}
                  placeholder="countryCode"
                  onChange={this.onChange('countryCode')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-city`}
                label="City"
              >
                <Text
                  id={`party-${party.get('id')}-city`}
                  value={party.get('city')}
                  placeholder="city"
                  onChange={this.onChange('city')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-streetAddressOne`}
                label="Address"
              >
                <Text
                  id={`party-${party.get('id')}-streetAddressOne`}
                  value={party.get('streetAddressOne')}
                  placeholder="Address"
                  onChange={this.onChange('streetAddressOne')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-postCode`}
                label="PostCode"
              >
                <Text
                  id={`party-${party.get('id')}-postCode`}
                  value={party.get('postCode')}
                  placeholder="Post code"
                  onChange={this.onChange('postCode')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-languageOfThePartyCode`}
                label="Language"
              >
                <Text
                  id={`party-${party.get('id')}-languageOfThePartyCode`}
                  value={party.get('languageOfThePartyCode')}
                  placeholder="language"
                  onChange={this.onChange('languageOfThePartyCode')}
                />
              </InputWithLabel>
              <hr />
              <h3>Contact</h3>
              <InputWithLabel
                inputId={`party-${party.get('id')}-contact_typeCode`}
                label="Type"
              >
                <SimpleSelect
                  id={`party-${party.get('id')}-contact_typeCode`}
                  options={contactTypeCode}
                  value={contactTypeCode.find(
                    (o) => o.value === party.getIn(['contact_typeCode']),
                  )}
                  onSelect={this.onChange(
                    'contact_typeCode',
                    (event) => event.value,
                  )}
                  autoSize
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-contact_personName`}
                label="Name"
              >
                <Text
                  id={`party-${party.get('id')}-contact_personName`}
                  value={party.get('contact_personName')}
                  placeholder="name"
                  onChange={this.onChange('contact_personName')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get('id')}-contact_communicationValue`}
                label="Email"
              >
                <Text
                  id={`party-${party.get('id')}-contact_communicationValue`}
                  value={party.get('contact_communicationValue')}
                  placeholder="mail"
                  onChange={this.onChange('contact_communicationValue')}
                />
              </InputWithLabel>
              <hr />
              <h3>Process capabilities</h3>
              <InputWithLabel
                inputId={`party-${party.get('id')}-processCapabilityCode`}
                label="Process capabilities"
              >
                <SimpleSelect
                  id={`party-${party.get('id')}-processCapabilityCode`}
                  options={processCapabilityCode}
                  value={processCapabilityCode.find(
                    (o) => o.value === party.getIn(['processCapabilityCode']),
                  )}
                  onSelect={this.onChange(
                    'processCapabilityCode',
                    (event) => event.value,
                  )}
                  autoSize
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`party-${party.get(
                  'id',
                )}-processCapabilityContextIdentification`}
                label="Process capabilities context ID"
              >
                <Text
                  id={`party-${party.get(
                    'id',
                  )}-processCapabilityContextIdentification`}
                  value={party.get('processCapabilityContextIdentification')}
                  placeholder="Process capabilities context ID"
                  onChange={this.onChange(
                    'processCapabilityContextIdentification',
                  )}
                />
              </InputWithLabel>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(GDSNPartyModal);
