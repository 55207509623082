import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import Copyable from 'components/copyable';

import './field-header.scss';

export default class FieldHeader extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.string,
    modified: PropTypes.bool.isRequired,
    saveInProgress: PropTypes.bool.isRequired,
    deleteInProgress: PropTypes.bool.isRequired,
    shouldBlockSaveField: PropTypes.bool,
    save: PropTypes.func.isRequired,
    discard: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    canDelete: PropTypes.bool,
  };

  state = {
    modalOpenDelete: false,
    modalOpenSave: false,
    shouldBlockSaveField: false,
  };

  openModalDelete = () => {
    this.setState({ modalOpenDelete: true });
  };

  closeModalDelete = () => {
    this.setState({ modalOpenDelete: false });
  };

  delete = () => {
    this.closeModalDelete();
    this.props.delete();
  };

  openModalSave = () => {
    this.setState({ modalOpenSave: true });
  };

  closeModalSave = () => {
    this.setState({ modalOpenSave: false });
  };

  save = () => {
    this.closeModalSave();
    this.props.save();
  };

  render() {
    const {
      id,
      name,
      label,
      modified,
      saveInProgress,
      deleteInProgress,
      shouldBlockSaveField,
      discard,
      readOnly,
      canDelete,
    } = this.props;
    const { modalOpenDelete, modalOpenSave } = this.state;
    return (
      <div className="FieldHeader">
        <div className="FieldHeader__label">
          <span>{label} </span>
          <code>
            <Copyable text={name} />
          </code>
          <span>({id})</span>
        </div>
        <div className="FieldHeader__actions">
          {!readOnly && (
            <>
              <LazyTooltip
                id={`save-field-button`}
                tooltipLabel={'Something block the save'}
                disabled={!shouldBlockSaveField}
                place="bottom"
              >
                <Button
                  id="save-changes"
                  content="Save"
                  onClick={this.openModalSave}
                  disabled={
                    !modified ||
                    saveInProgress ||
                    deleteInProgress ||
                    shouldBlockSaveField
                  }
                  displaySpinner={saveInProgress}
                  primary
                  testid={'save-field-button'}
                />
              </LazyTooltip>

              <Button
                id="discard-changes"
                content="Discard"
                onClick={discard}
                disabled={!modified || saveInProgress || deleteInProgress}
                warning
              />
              {window.env === 'ppr' && canDelete && (
                <Button
                  id="delete-field"
                  content="Delete"
                  onClick={this.openModalDelete}
                  disabled={saveInProgress || deleteInProgress}
                  displaySpinner={deleteInProgress}
                  warning
                />
              )}
              {modalOpenDelete && (
                <Modal
                  danger
                  title="Confirm deletion"
                  modalStyle="dynamic"
                  confirmButtonText="Delete"
                  onConfirm={this.delete}
                  onClose={this.closeModalDelete}
                >
                  <div>
                    This action is irreversible (note that this action will be
                    logged).
                  </div>
                </Modal>
              )}
              {modalOpenSave && (
                <Modal
                  danger
                  title="Confirm changes save"
                  modalStyle="dynamic"
                  confirmButtonText="Save"
                  onConfirm={this.save}
                  onClose={this.closeModalSave}
                >
                  <div>
                    Are you sure you want to change this Field settings? It can
                    have major behavior impacts on the platform (note that this
                    action will be logged).
                  </div>
                </Modal>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
