import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { SwitchButton } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';
import { Application } from '@alkem/sdk-dashboard';

import * as routes from 'constants/routes';
import { fill } from 'utils/routes';

import './item.scss';

interface Props {
  application: Application;
  change?: boolean;
  readOnly?: boolean;
  onChange: (applicationId: number, isEnabled: boolean) => void;
}

function OrganizationApplicationItem({
  application,
  change,
  readOnly,
  onChange,
}: Props) {
  const onLocalChange = useCallback(
    (isEnabled: boolean) => {
      onChange(application.id, isEnabled);
    },
    [application, onChange],
  );
  const stopPropagation = useCallback(
    (event: React.MouseEvent) => event.stopPropagation(),
    [],
  );
  const hasChanged = typeof change === 'boolean';
  return (
    <li
      id={`organization-application-${application.id}`}
      className={classNames(
        'OrganizationApplicationItem',
        `OrganizationApplicationItem--type${application.type}`,
        hasChanged && 'OrganizationApplicationItem--changed',
      )}
    >
      <SwitchButton
        checked={hasChanged ? change : application.enabled}
        disabled={readOnly}
        content={
          <span className="OrganizationApplicationItem__label">
            <span>{application.name}</span>
            <span className="OrganizationApplicationItem__icons">
              <i
                className="mdi mdi-help-circle"
                data-tip={
                  application.descriptionShort || application.description
                }
                data-for={`app-info-${application.id}`}
              />
              <Tooltip id={`app-info-${application.id}`} />
              <Link
                to={fill(routes.applications, application.id)}
                onClick={stopPropagation}
              >
                <i
                  className="mdi mdi-settings"
                  data-tip="Configure application"
                  data-for={`go-to-app-dashboard-${application.id}`}
                />
                <Tooltip id={`go-to-app-dashboard-${application.id}`} />
              </Link>
            </span>
          </span>
        }
        onChange={onLocalChange}
      />
    </li>
  );
}

export default OrganizationApplicationItem;
