import { asUser, hasFullPower } from 'utils/user';

export type RequiredPermission = [string, string[]];

export interface AccessPolicy {
  userTypes: number[];
  permissions: {
    read: RequiredPermission[];
    write: RequiredPermission[];
  };
}

export function hasAccess(
  userType: number,
  userPermissions?: { [key: string]: string[] },
  accessPolicy?: AccessPolicy,
  accessType: 'read' | 'write' = 'read',
) {
  if (hasFullPower(asUser({ type: userType }))) {
    return true;
  }
  if (!userPermissions || !accessPolicy) {
    return false;
  }
  return (
    accessPolicy.userTypes.includes(userType) &&
    accessPolicy.permissions[accessType].every(([entityType, permissions]) =>
      permissions.every((permission) =>
        (userPermissions[entityType] || []).includes(permission),
      ),
    )
  );
}
