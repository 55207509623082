import { List, fromJS } from 'immutable';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';
import Checkbox from '@alkem/react-ui-checkbox';
import { Text } from '@alkem/react-ui-inputs';
import EntityList from '@alkem/react-ui-list';
import { Select } from '@alkem/react-ui-select';

import * as routes from 'constants/routes';
import { separateActions } from 'utils/redux';

import {
  listOrganizations,
  listVersions,
  nextPage,
  previousPage,
  setLimit,
} from './actions';
import {
  selectCurrentPage,
  selectLimit,
  selectTotalResults,
  selectVersions,
} from './selectors';
import './views.scss';

const mapStateToProps = (state) => ({
  versions: selectVersions(state),
  totalResults: selectTotalResults(state),
  limit: selectLimit(state),
  currentPage: selectCurrentPage(state),
});

const mapDispatchToProps = {
  listVersions,
  listOrganizations,
  setLimit,
  nextPage,
  previousPage,
};

export class RetailerProductPictureComparator extends PureComponent {
  static propTypes = {
    versions: PropTypes.object,
    totalResults: PropTypes.number,
    limit: PropTypes.number,
    currentPage: PropTypes.number,
    actions: PropTypes.shape({
      listVersions: PropTypes.func.isRequired,
      listOrganizations: PropTypes.func.isRequired,
      setLimit: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
    }),
  };

  static retailerPictureUrls = {
    // AuchanDrive.
    4: (ref) =>
      !!ref &&
      `https://www.auchandrive.fr/drive/static-media/front/pictures/product/zoom/${ref}.jpg`,
    // CasinoDrive.
    15: (ref) => {
      if (!ref) {
        return null;
      }
      const strRef = `${ref}`;
      const lastDigit = strRef[strRef.length - 1];
      return `http://www.casinodrive.fr/imageserver/MC2/${lastDigit}/${ref}_M1_S1.jpg`;
    },
    // AuchanDirect.
    3: (ref) =>
      !!ref &&
      `https://www.auchandirect.fr/backend/media/products_images/0N_${ref}.jpg`,
    // ChronoDrive.
    9: (ref) => {
      if (!ref) {
        return null;
      }
      const strRef = `${ref}`;
      const last2Digit = strRef.slice(strRef.length - 2);
      return `https://static1.chronodrive.com//img/PM/P/0/${last2Digit}/0P_${ref}.gif`;
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      gtins: '',
      allOrganizations: List(),
      organizations: List(),
      checkedGTINs: List(),
    };
    this.renderVersion = this.renderVersion.bind(this);
    this.getVersions = this.getVersions.bind(this);
    this.onAddOrganization = this.onAddOrganization.bind(this);
    this.onRemoveOrganization = this.onRemoveOrganization.bind(this);
    this.onSetLimit = this.onSetLimit.bind(this);
    this.onNextPage = this.onNextPage.bind(this);
    this.onPreviousPage = this.onPreviousPage.bind(this);
  }

  componentDidMount() {
    this.props.actions
      .listOrganizations(
        Object.keys(RetailerProductPictureComparator.retailerPictureUrls),
      )
      .then((organizations) =>
        this.setState({
          allOrganizations: organizations.map((o) =>
            o.set('label', o.get('nameLegal')),
          ),
          organizations: organizations.map((o) =>
            o.set('label', o.get('nameLegal')),
          ),
        }),
      );
  }

  onAddOrganization(value) {
    const { organizations } = this.state;
    this.setState({ organizations: organizations.push(fromJS(value)) });
  }

  onRemoveOrganization(index) {
    const { organizations } = this.state;
    this.setState({ organizations: organizations.delete(index) });
  }

  onSetLimit(limit) {
    const { actions } = this.props;
    actions.setLimit(limit);
    return this.getVersions(limit);
  }

  onNextPage() {
    const { limit, currentPage, actions } = this.props;
    actions.nextPage();
    return this.getVersions(limit, limit * currentPage);
  }

  onPreviousPage() {
    const { limit, currentPage, actions } = this.props;
    actions.previousPage();
    return this.getVersions(limit, limit * (currentPage - 2));
  }

  onCheckGTIN = memoize((gtin) => (value) => {
    const { checkedGTINs } = this.state;
    if (!value && checkedGTINs.includes(gtin)) {
      this.setState({
        checkedGTINs: checkedGTINs.delete(checkedGTINs.findIndex(gtin)),
      });
    } else if (value && !checkedGTINs.includes(gtin)) {
      this.setState({ checkedGTINs: checkedGTINs.push(gtin) });
    }
  });

  getVersions(limit, offset = 0, resetChecks = false) {
    const { gtins, organizations } = this.state;
    const { actions } = this.props;
    if (resetChecks) {
      this.setState({ checkedGTINs: List() });
    }
    return actions.listVersions(
      organizations.map((o) => o.get('id')).toJS(),
      gtins,
      limit || this.props.limit,
      offset,
    );
  }

  getOrganizationName(organizationId) {
    const { allOrganizations } = this.state;
    return allOrganizations
      .find((o) => o.get('id') === organizationId)
      .get('nameLegal');
  }

  getAlkemicsPicture(version) {
    const packshot = (version.getIn(['assets', 'pictures']) || List()).find(
      (p) => !!p.get('isPackshot'),
    );
    if (!packshot) {
      return null;
    }
    const exportable = (packshot.get('exportables') || List()).find(
      (e) => e.get('width') === 256,
    );
    if (!exportable) {
      return null;
    }
    return (
      <img
        width="250"
        height="250"
        src={exportable.get('uniformResourceIdentifier')}
        alt=""
      />
    );
  }

  getRetailerPicture(version) {
    const primaryInternalReference = version.get('primaryInternalReference');
    const gtin = version.get('gtin');
    const contentOwnerId = version.getIn(['tags', 'contentOwner', 'id']);
    if (!RetailerProductPictureComparator.retailerPictureUrls[contentOwnerId]) {
      return null;
    }
    const imageUrl = RetailerProductPictureComparator.retailerPictureUrls[
      contentOwnerId
    ](primaryInternalReference, gtin);
    if (!imageUrl) {
      return null;
    }
    return <img width="250" height="250" src={imageUrl} alt="" />;
  }

  getTotalPages() {
    const { limit, totalResults } = this.props;
    const left = totalResults % limit !== 0 ? 1 : 0;
    return Math.floor(totalResults / limit) + left;
  }

  getLimitLabel = (count) => `${count} per page`;

  getPageLabel = (page, total) =>
    `${page} / ${total} ${total > 1 ? 'pages' : 'page'}.`;

  getEntityLabel = (count) =>
    `${count} ${count > 1 ? 'products' : 'product'} in total.`;

  onChange = (event) => {
    this.setState({ gtins: event.target.value });
  };

  onClick = () => {
    this.getVersions(null, 0, true);
  };

  renderVersion(version) {
    const { checkedGTINs } = this.state;
    const gtin = version.get('gtin');
    const isGTINChecked = checkedGTINs.includes(gtin);
    return (
      <div
        className="RetailerProductPictureComparator__version"
        key={version.get('id')}
      >
        <div className="RetailerProductPictureComparator__checkbox">
          <Checkbox
            id={`checkbox-${version.get('id')}`}
            checked={isGTINChecked}
            onChange={this.onCheckGTIN(gtin)}
          />
        </div>
        <div className="RetailerProductPictureComparator__versionItem">
          <div className="RetailerProductPictureComparator__versionItemInfo">
            <div className="RetailerProductPictureComparator__versionItemTitle">
              Organization:
            </div>
            <div>
              {this.getOrganizationName(
                version.getIn(['tags', 'contentOwner', 'id']),
              )}
            </div>
          </div>
          <div className="RetailerProductPictureComparator__versionItemInfo">
            <div className="RetailerProductPictureComparator__versionItemTitle">
              GTIN:
            </div>
            <div>{version.get('gtin')}</div>
          </div>
          <div className="RetailerProductPictureComparator__versionItemInfo">
            <div className="RetailerProductPictureComparator__versionItemTitle">
              Internal reference:
            </div>
            <div>{version.get('primaryInternalReference')}</div>
          </div>
        </div>
        <div className="RetailerProductPictureComparator__versionItem">
          <div className="RetailerProductPictureComparator__versionItemTitle">
            Salsify SupplierXM packshot:
          </div>
          <div>{this.getAlkemicsPicture(version)}</div>
        </div>
        <div className="RetailerProductPictureComparator__versionItem">
          <div className="RetailerProductPictureComparator__versionItemTitle">
            Retailer picture:
          </div>
          <div>{this.getRetailerPicture(version)}</div>
        </div>
      </div>
    );
  }

  renderVersions() {
    const { versions } = this.props;
    if (!versions || !versions.size) {
      return null;
    }
    return (
      <div className="RetailerProductPictureComparator__versions">
        {this.renderSelectedGtins()}
        <EntityList
          currentPage={this.props.currentPage}
          totalPages={this.getTotalPages()}
          totalResults={this.props.totalResults}
          onNext={this.onNextPage}
          onPrev={this.onPreviousPage}
          limit={this.props.limit}
          onLimitChange={this.onSetLimit}
          optionsLimit={[10, 50, 100]}
          getLimitLabel={this.getLimitLabel}
          getPageLabel={this.getPageLabel}
          getEntityLabel={this.getEntityLabel}
          bottomActions
        >
          {versions.map(this.renderVersion)}
        </EntityList>
        {this.renderSelectedGtins()}
      </div>
    );
  }

  renderSelectedGtins() {
    const { checkedGTINs } = this.state;
    if (!checkedGTINs.size) {
      return null;
    }
    const commaSeparated = checkedGTINs.join(', ');
    return (
      <div className="RetailerProductPictureComparator__checkedGTINs">
        Selected GTINs: {commaSeparated}
      </div>
    );
  }

  render() {
    const { gtins, allOrganizations, organizations } = this.state;
    const selectedOrganizationIds = organizations
      .map((e) => e.get('id'))
      .toJS();
    return (
      <div>
        <HeaderLayout
          title="Retailer product picture comparator"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        />
        <div className="container-fluid row">
          <div className="RetailerProductPictureComparator col-xs-12">
            <div className="RetailerProductPictureComparator__header">
              <Select
                id="organization-select"
                onValueAdd={this.onAddOrganization}
                onValueDelete={this.onRemoveOrganization}
                options={allOrganizations
                  .filter((o) => !selectedOrganizationIds.includes(o.get('id')))
                  .toJS()}
                multiple
                values={organizations.toJS()}
              />
              <Text
                id="gtins-input"
                placeholder="GTINs or internal references"
                value={gtins || ''}
                onChange={this.onChange}
              />
              <Button
                id="get-versions-btn"
                content="Go"
                onClick={this.onClick}
                primary
              />
            </div>
            {this.renderVersions()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(RetailerProductPictureComparator);
