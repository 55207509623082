import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AddButton } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';

import { separateActions } from 'utils/redux';

import {
  addGLN,
  closeGLNModal,
  onChangeGLN,
  openGLNModal,
  removeGLN,
} from '../../actions/gln';
import {
  selectIsGLNModalOpen,
  selectOrganizationGLN,
} from '../../selectors/gln';

import './index.scss';

const mapStateToProps = (state) => ({
  ownsGLN: selectOrganizationGLN(state),
  isOpen: selectIsGLNModalOpen(state),
});

const mapDispatchToProps = {
  openGLNModal,
  closeGLNModal,
  onChangeGLN,
  addGLN,
  removeGLN,
};

export class OrganizationGLN extends PureComponent {
  static propTypes = {
    ownsGLN: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    isReadOnly: PropTypes.bool,
    actions: PropTypes.shape({
      openGLNModal: PropTypes.func.isRequired,
      closeGLNModal: PropTypes.func.isRequired,
      onChangeGLN: PropTypes.func.isRequired,
      addGLN: PropTypes.func.isRequired,
      removeGLN: PropTypes.func.isRequired,
    }).isRequired,
  };

  onClickDeleteGLN(gln) {
    return () => {
      // eslint-disable-next-line no-alert
      const confirmed = window.confirm(
        `Are you sure you want to remove gln: ${gln.get(
          'gln',
        )} from this organization ?`,
      );
      if (confirmed) {
        this.props.actions.removeGLN(gln.get('id'));
      }
    };
  }

  render() {
    const { isOpen, ownsGLN, actions, isReadOnly } = this.props;
    const glnList = ownsGLN.map((gln) => (
      <div key={gln.get('gln')} className="OrganizationPageBlock__gln">
        {gln.get('gln')} - {gln.get('nameLegal')}
        {!isReadOnly && (
          <i
            className="mdi mdi-delete OrganizationPageBlock__gln_del"
            onClick={this.onClickDeleteGLN(gln)}
          />
        )}
      </div>
    ));
    return (
      <div className="OrganizationPageInfo OrganizationPageBlock">
        <div className="OrganizationPageBlock__header">
          <h2>GLN</h2>
          {!isReadOnly && (
            <div>
              <AddButton label="Add GLN" onClick={actions.openGLNModal} />
            </div>
          )}
        </div>
        <div className="OrganizationPageBlock__Listgln">{glnList}</div>
        {isOpen && (
          <Modal
            title="Add GLN"
            modalStyle="dynamic"
            confirmButtonText="Send"
            onConfirm={actions.addGLN}
            onClose={actions.closeGLNModal}
          >
            <Text id="addGLN" onChange={actions.onChangeGLN} label="GLN" />
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(OrganizationGLN);
