import { MODULE_REDUCER_KEY } from 'constants/reducer';

import {
  USER_LIST_ORGANIZATION_STORE_KEY,
  USER_LIST_STORE_KEY,
} from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][USER_LIST_STORE_KEY][
    USER_LIST_ORGANIZATION_STORE_KEY
  ];
}

export function selectOrganizationList(state) {
  return selectState(state).list;
}

export function selectOrganizationFilteredList(state) {
  return selectState(state).filteredList;
}

export function selectOrganizationSearch(state) {
  return selectState(state).search;
}
