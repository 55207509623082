import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import ListController from 'components/ui/list/controller';
import {
  changeLimit,
  changeSorting,
  checkAllRows,
  checkRow,
  getFieldSuggestionList,
  getKindLeaves,
  getSegmentHierarchies,
  nextPage,
  openModal,
  previousPage,
  updateSearch,
  validateSuggestion,
} from 'modules/field-suggestion/actions';
import ActionBar from 'modules/field-suggestion/components/list/actionbar';
import FieldSuggestionRow from 'modules/field-suggestion/components/list/row';
import { PAGINATION_SORT_OPTIONS } from 'modules/field-suggestion/constants';
import {
  selectKindLeaves,
  selectList,
  selectModalState,
  selectPagination,
} from 'modules/field-suggestion/selectors';
import { separateActions } from 'utils/redux';

import './list.scss';
import EditModal from './modal/edit';
import ValidateModal from './modal/validate';

const mapStateToProps = (state) => ({
  list: selectList(state),
  pagination: selectPagination(state),
  modalState: selectModalState(state),
  kindLeaves: selectKindLeaves(state),
});

const mapDispatchToProps = {
  changeSorting,
  changeLimit,
  nextPage,
  previousPage,
  checkRow,
  checkAllRows,
  getFieldSuggestionList,
  getSegmentHierarchies,
  validateSuggestion,
  openModal,
  updateSearch,
  getKindLeaves,
};

export class FieldSuggestionList extends PureComponent {
  static propTypes = {
    list: ImmutablePropTypes.list.isRequired,
    pagination: PropTypes.object.isRequired,
    modalState: PropTypes.object,
    kindLeaves: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      changeSorting: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      checkRow: PropTypes.func.isRequired,
      checkAllRows: PropTypes.func.isRequired,
      getFieldSuggestionList: PropTypes.func.isRequired,
      getSegmentHierarchies: PropTypes.func.isRequired,
      validateSuggestion: PropTypes.func.isRequired,
      openModal: PropTypes.func.isRequired,
      updateSearch: PropTypes.func.isRequired,
      getKindLeaves: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    modalState: null,
    kindLeaves: [],
  };

  constructor(props) {
    super(props);
    this.getCheckedRows = this.getCheckedRows.bind(this);
    this.onCheckAll = this.onCheckAll.bind(this);
  }

  componentDidMount() {
    this.props.actions.getFieldSuggestionList();
    this.props.actions.getKindLeaves();
    this.props.actions.getSegmentHierarchies();
  }

  onCheckAll(checked) {
    this.props.actions.checkAllRows(checked);
  }

  onCheck = (rowIndex) => (event) => {
    const { actions } = this.props;
    actions.checkRow(rowIndex, event);
  };

  getCheckedRows() {
    return this.props.list.filter((e) => e.get('checked'));
  }

  renderModal() {
    const { modalState } = this.props;
    if (modalState === null) {
      return null;
    } else if (['validate', 'refuse'].indexOf(modalState.id) > -1) {
      return <ValidateModal />;
    } else if (modalState.id === 'edit') {
      return <EditModal />;
    }
    return null;
  }

  renderActions() {
    const { actions } = this.props;
    const allChecked =
      this.getCheckedRows().size > 0 &&
      this.getCheckedRows().size === this.props.list.size;
    return (
      <ActionBar
        onCheckAll={this.onCheckAll}
        entities={this.getCheckedRows()}
        checked={allChecked}
        openModal={actions.openModal}
      />
    );
  }

  renderList() {
    const { list, actions, kindLeaves } = this.props;
    return list
      .map((row, i) => (
        <FieldSuggestionRow
          key={row.get('id')}
          entity={row}
          checked={row.get('checked')}
          onCheck={this.onCheck(i)}
          firstRow={i === 0}
          onValidate={actions.validateSuggestion}
          updateSearch={actions.updateSearch}
          kindLeaves={kindLeaves}
        />
      ))
      .toJS();
  }

  render() {
    const { pagination, actions } = this.props;

    return (
      <div>
        <ListController
          actions={this.renderActions()}
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          limit={pagination.limit}
          paginationSortOptions={PAGINATION_SORT_OPTIONS}
          sortBy={pagination.sortBy}
          sortOrder={pagination.sortOrder}
          onLimitChange={actions.changeLimit}
          onSortChange={actions.changeSorting}
          totalResults={pagination.totalResults}
          rowsLength={this.getCheckedRows().size}
          type="default"
        >
          {this.renderList()}
        </ListController>
        {this.renderModal()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(FieldSuggestionList);
