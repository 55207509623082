import * as kindsConstants from 'constants/events/kinds';
import { createReducer, getReducer } from 'utils/reducers';

const initialState = {
  tree: {},
  indexed: {},
  selected: null,
  idToPath: {},
};

const indexKindsFromTree = (tree, parent) => {
  let kindIndex = {};
  kindIndex[tree.id] = tree;
  kindIndex[tree.id].parent = parent;
  tree.children.forEach((child) => {
    kindIndex = Object.assign(kindIndex, indexKindsFromTree(child, tree));
  });
  return kindIndex;
};

export const addPathToHierarchy = (children, currentPath, accumulator) => {
  for (let i = 0; i < children.length || 0; i += 1) {
    const child = children[i];
    const childCurrentPath = [...currentPath, child.id];
    child.path = childCurrentPath;
    accumulator[child.id] = child.path;
    if (child.children) {
      addPathToHierarchy(child.children, childCurrentPath, accumulator);
    }
  }
  return accumulator;
};

const receiveKindListReducer = (state, action) => ({
  tree: action.data,
  indexed: indexKindsFromTree(action.data, null),
  selected: null,
  idToPath: addPathToHierarchy(action.data.children, [], {}),
});

const receiveKindReducer = (state, action) => {
  const newState = { ...state };
  for (const kind of action.data) {
    newState.indexed[kind.id] = Object.assign(newState.indexed[kind.id], kind);
  }
  return newState;
};

const selectKindReducer = (state, action) => ({
  ...state,
  selected: action.data.id,
});

export default createReducer(initialState, {
  [kindsConstants.GET_KIND_LIST]: getReducer(initialState),
  [kindsConstants.RECEIVE_KIND_LIST]: receiveKindListReducer,
  [kindsConstants.RECEIVE_KIND]: receiveKindReducer,
  [kindsConstants.SELECT_KIND]: selectKindReducer,
});
