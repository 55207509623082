import { List, Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import {
  changeLimit,
  initPagination,
  nextPage,
  previousPage,
  receiveReducer,
} from 'utils/reducers/list';

import * as events from './events';

export const TRANSACTION_DASHBOARD_STORE_KEY = 'transaction';

const LIMIT_STORAGE_KEY = 'transactionListLimit';
const SORTING_STORAGE_KEY = 'transactionListSorting';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][TRANSACTION_DASHBOARD_STORE_KEY];
}

export const baseFilters = fromJS({
  q: '',
  selected: [],

  statuses: [],
  eventTypes: [],
  eventActions: [],
  dataType: '',

  sourceOrganization: {},
  targetOrganization: {},
  sourceProductKey: {},
  targetProductKey: {},
  sourceEntities: [],
  targetEntities: [],

  createdAtFrom: null,
  createdAtTo: null,
});

const initialState = {
  list: List(),
  aggregations: Map(),
  pagination: initPagination.bind(
    this,
    LIMIT_STORAGE_KEY,
    SORTING_STORAGE_KEY,
  )(),
  isLoading: true,
  filters: baseFilters,
  payloadModal: {
    show: false,
    payload: {},
  },
  diffModal: {
    show: false,
    payload: {},
    dataType: '',
  },
  actionModal: {
    show: false,
    isApplying: '',
    transaction: {},
  },

  // For display
  extra: fromJS({
    organizations: {},
    products: {},
    productKeys: {},
  }),
};

// Add filter
const pushInArray = (filterType) => (filters, key) =>
  filters.set(filterType, filters.get(filterType).push(key));
const setIn = (filterType) => (filters, key, label) =>
  filters.set(filterType, fromJS({ id: key, name: label }));

const filterSetters = {
  statuses: pushInArray('statuses'),
  eventTypes: pushInArray('eventTypes'),
  eventActions: pushInArray('eventActions'),
  sourceOrganization: setIn('sourceOrganization'),
  targetOrganization: setIn('targetOrganization'),
  sourceProductKey: setIn('sourceProductKey'),
  targetProductKey: setIn('targetProductKey'),
  sourceEntities: pushInArray('sourceEntities'),
  targetEntities: pushInArray('targetEntities'),
  createdAtFrom: setIn('createdAtFrom'),
  createdAtTo: setIn('createdAtTo'),
  dataType: (filters, dataType) => filters.set('dataType', dataType),
};

// Remove filter
const removeInArray = (filterType) => (filters, key) => {
  const idx = filters.get(filterType).findIndex((e) => e === key);
  if (idx === -1) {
    return filters;
  }
  return filters.deleteIn([filterType, idx]);
};
const resetIn = (filterType) => (filters) => filters.set(filterType, Map());

const filterRemovers = {
  statuses: removeInArray('statuses'),
  eventTypes: removeInArray('eventTypes'),
  eventActions: removeInArray('eventActions'),
  sourceOrganization: resetIn('sourceOrganization'),
  targetOrganization: resetIn('targetOrganization'),
  sourceProductKey: resetIn('sourceProductKey'),
  targetProductKey: resetIn('targetProductKey'),
  sourceEntities: removeInArray('sourceEntities'),
  targetEntities: removeInArray('targetEntities'),
  createdAtFrom: resetIn('createdAtFrom'),
  createdAtTo: resetIn('createdAtTo'),
  dataType: (filters) => filters.set('dataType', ''),
};

// Manage selected filters
const selectArray = (filterType) => (selected, filterId, filterLabel) =>
  selected.push(
    fromJS({
      filterType,
      filterId,
      filterLabel,
    }),
  );
const selectOne = (filterType) => (selected, filterId, filterLabel) => {
  const idx = selected.findIndex((s) => s.get('filterType') === filterType);
  if (idx === -1) {
    return selected.push(
      fromJS({
        filterType,
        filterId,
        filterLabel,
      }),
    );
  }
  return selected.set(
    idx,
    fromJS({
      filterType,
      filterId,
      filterLabel,
    }),
  );
};

const selectedFilterSetters = {
  statuses: selectArray('statuses'),
  eventTypes: selectArray('eventTypes'),
  eventActions: selectArray('eventActions'),
  sourceOrganization: selectOne('sourceOrganization'),
  targetOrganization: selectOne('targetOrganization'),
  sourceProductKey: selectOne('sourceProductKey'),
  targetProductKey: selectOne('targetProductKey'),
  sourceEntities: selectArray('sourceEntities'),
  targetEntities: selectArray('targetEntities'),
  createdAtFrom: selectOne('createdAtFrom'),
  createdAtTo: selectOne('createdAtTo'),
  dataType: selectOne('dataType'),
};

const removeSelectedArray = (filterType) => (selected, filterId) => {
  const idx = selected.findIndex(
    (s) => s.get('filterType') === filterType && s.get('filterId') === filterId,
  );
  if (idx === -1) {
    return selected;
  }

  return selected.remove(idx);
};

const removeSelectedOne = (filterType) => (selected) => {
  const idx = selected.findIndex((s) => s.get('filterType') === filterType);
  if (idx === -1) {
    return selected;
  }

  return selected.remove(idx);
};

const selectedFilterRemovers = {
  statuses: removeSelectedArray('statuses'),
  eventTypes: removeSelectedArray('eventTypes'),
  eventActions: removeSelectedArray('eventActions'),
  sourceOrganization: removeSelectedOne('sourceOrganization'),
  targetOrganization: removeSelectedOne('targetOrganization'),
  sourceProductKey: removeSelectedOne('sourceProductKey'),
  targetProductKey: removeSelectedOne('targetProductKey'),
  sourceEntities: removeSelectedArray('sourceEntities'),
  targetEntities: removeSelectedArray('targetEntities'),
  createdAtFrom: removeSelectedOne('createdAtFrom'),
  createdAtTo: removeSelectedOne('createdAtTo'),
  dataType: removeSelectedOne('dataType'),
};

export default createReducer(initialState, {
  // List
  [events.TRANSACTION_RECEIVE_LIST]: receiveReducer,
  [events.TRANSACTION_RECEIVE_AGGREGATIONS]: (state, { aggregations }) => {
    const newState = { ...state };
    newState.aggregations = aggregations;
    return newState;
  },
  [events.TRANSACTION_LOAD]: (state, action) => {
    const newState = { ...state };
    newState.isLoading = action.isLoading;
    return newState;
  },

  // Pagination
  [events.TRANSACTION_NEXT_PAGE]: nextPage,
  [events.TRANSACTION_PREVIOUS_PAGE]: previousPage,
  [events.TRANSACTION_CHANGE_LIMIT]: changeLimit,

  // Filters
  [events.TRANSACTION_SEARCH_ID]: (state, action) => {
    const newState = { ...state };

    newState.filters = newState.filters.set('q', action.q);
    return newState;
  },
  [events.TRANSACTION_ADD_FILTER]: (state, action) => {
    const { filterType, filterId, filterLabel } = action;
    const newState = { ...state };

    newState.filters = newState.filters.withMutations((newFilters) => {
      filterSetters[filterType](newFilters, filterId, filterLabel);
      newFilters.set(
        'selected',
        selectedFilterSetters[filterType](
          newFilters.get('selected'),
          filterId,
          filterLabel,
        ),
      );
    });
    return newState;
  },
  [events.TRANSACTION_REMOVE_FILTER]: (state, action) => {
    const { filterType, filterId } = action;
    const newState = { ...state };

    newState.filters = newState.filters.withMutations((newFilters) => {
      filterRemovers[filterType](newFilters, filterId);

      newFilters.set(
        'selected',
        selectedFilterRemovers[filterType](
          newFilters.get('selected'),
          filterId,
        ),
      );
    });
    return newState;
  },
  [events.TRANSACTION_CLEAR_FILTERS]: (state) => {
    const newState = { ...state };
    newState.filters = baseFilters;
    return newState;
  },
  [events.TRANSACTION_SELECT_PRESET]: (state, action) => {
    const newState = { ...state };

    newState.filters = baseFilters;
    const defaultFilters = fromJS(action.preset.filters) || Map();
    newState.filters = newState.filters.mergeDeep(defaultFilters);

    return newState;
  },
  [events.TRANSACTION_FILTERS_LOADED]: (state, { filters }) => {
    const newState = { ...state };
    newState.filters = fromJS(filters);
    return newState;
  },
  [events.TRANSACTION_UPDATE_SELECTED_FILTERS]: (
    state,
    { selectedFilters },
  ) => {
    const newState = { ...state };
    newState.filters = newState.filters.set('selected', selectedFilters);
    return newState;
  },

  // Payload modal
  [events.TRANSACTION_PAYLOAD_MODAL_CLOSE]: (state) => {
    const newState = { ...state };
    newState.payloadModal = {
      show: false,
      payload: {},
    };
    return newState;
  },
  [events.TRANSACTION_PAYLOAD_MODAL_SHOW]: (state, action) => {
    const newState = { ...state };
    newState.payloadModal = {
      show: !!action.payload,
      payload: action.payload,
    };
    return newState;
  },

  // Diff modal
  [events.TRANSACTION_DIFF_MODAL_CLOSE]: (state) => {
    const newState = { ...state };
    newState.diffModal = {
      show: false,
      payload: Map(),
      dataType: '',
    };
    return newState;
  },
  [events.TRANSACTION_DIFF_MODAL_SHOW]: (state, action) => {
    const newState = { ...state };
    newState.diffModal = {
      show: !!action.payload,
      payload: action.payload,
      dataType: action.dataType,
    };
    return newState;
  },

  // Action modal
  [events.TRANSACTION_ACTION_MODAL_CLOSE]: (state) => {
    const newState = { ...state };
    newState.actionModal = {
      show: false,
      isApplying: '',
      transaction: Map(),
      dataType: '',
    };
    return newState;
  },
  [events.TRANSACTION_ACTION_MODAL_SHOW]: (state, action) => {
    const newState = { ...state };
    newState.actionModal = {
      show: !!action.transaction,
      isApplying: '',
      transaction: action.transaction,
    };
    return newState;
  },
  [events.TRANSACTION_ACTION_MODAL_APPLY]: (state, action) => {
    const newState = { ...state };
    newState.actionModal.isApplying = action.action;
    return newState;
  },
  [events.TRANSACTION_RECEIVE_EXTRAS]: (state, { key, data }) => {
    const newState = { ...state };
    newState.extra = newState.extra.set(key, fromJS(data));
    return newState;
  },
});
