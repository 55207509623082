import { fromJS } from 'immutable';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import authApi from 'resources/authApi';
import mediaApi from 'resources/mediaApi';
import { logError } from 'utils';
import { requestWithHeaders } from 'utils/api';

import * as events from './events';
import * as selectors from './selectors';

export default function* mainSaga() {
  yield takeEvery(events.FETCH_GROUPS, fetchGroups);
  yield takeEvery(events.UPLOAD_PICTURE, upload);
  yield takeEvery(events.SAVE, save);
}

function* fetchGroups() {
  const search = yield select(selectors.selectSearch);
  const { result, error } = yield call(
    requestWithHeaders,
    authApi,
    'listOrganizationGroups',
    search,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load groups'));
  } else {
    yield put({
      type: events.RECEIVE_GROUPS,
      groups: fromJS(result.data.data),
    });
  }
}

function* upload({ picture }) {
  yield put({ type: events.SET_UPLOADING_PICTURE, uploading: true });
  const group = yield select(selectors.selectEdited);
  const query = '/media/v4/public/picture/upload';
  const payload = {
    remote_path: `/group/picture/${group.get('id')}/logo`,
  };
  const { error } = yield call(
    requestWithHeaders,
    mediaApi,
    'post',
    query,
    payload,
    picture,
  );
  if (error) {
    logError(error);
    yield put({ type: events.SET_UPLOADING_PICTURE, uploading: false });
    yield put(notificationError('Failed to upload picture'));
  } else {
    yield put({ type: events.SET_UPLOADING_PICTURE, uploading: false });
    yield put(notificationSuccess('Group saved'));
    yield put({ type: events.FETCH_GROUPS });
  }
}

function* save() {
  yield put({ type: events.SET_SAVING, saving: true });
  const group = yield select(selectors.selectEdited);
  const { result, error } = yield call(
    requestWithHeaders,
    authApi,
    'upsertOrganizationGroup',
    group.toJS(),
  );
  if (error) {
    logError(error);
    yield put({ type: events.SET_SAVING, saving: false });
    yield put(notificationError('Failed to save group'));
  } else {
    yield put({ type: events.SET_SAVING, saving: false });
    yield put(notificationSuccess('Group saved'));
    yield put({ type: events.EDIT, group: fromJS(result.data) });
    yield put({ type: events.FETCH_GROUPS });
  }
}
