import { List, Map, fromJS } from 'immutable';

import * as events from 'constants/events/fields';
import { createReducer } from 'utils/reducers';

const initialState = fromJS({
  // Raw data.
  source: [],
  // Flattened fields by ID.
  flat: {},
  // Cache refresh
  refreshingCache: false,
});

const flattenFields = (fields, flattenedFields = Map()) =>
  flattenedFields.withMutations((ff_) => {
    let ff = ff_;
    fields.forEach((field) => {
      ff = ff.set(field.get('id'), field);
      flattenFields(field.get('children'), ff);
    });
    return ff;
  });

export default createReducer(initialState, {
  [events.GET_FIELD_LIST]: (state) =>
    state.set('source', List()).set('flat', Map()),
  [events.RECEIVE_FIELD_LIST]: (state, { data }) => {
    const sortedData = data.sort((a, b) => a.get('id') - b.get('id'));
    return state.withMutations((s) =>
      s.set('source', sortedData).set('flat', flattenFields(sortedData)),
    );
  },
  [events.REFRESHING_CACHE]: (state, { refreshing }) =>
    state.set('refreshingCache', refreshing),
});
