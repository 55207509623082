import { ChangeEvent, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';
import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';
import { ResponseWithBody } from '@alkem/sdk-dashboard';

import { notificationError } from 'actions/notifications';
import InputWithLabel from 'components/input-with-label';
import { ruleEntityTypes } from 'modules/validation-dashboard/constants';
import { ReadOnlyRule, RuleSet } from 'modules/validation-dashboard/types';
import { validationApi } from 'resources/validationApi';

import './rule-creation-modal.scss';
import { RuleInEdition, makePostableRule, ruleIsIncomplete } from './types';

const loadRulesetsOptions = async (inputValue: string): Promise<RuleSet[]> => {
  const {
    data: { data: rulesets },
  } = await validationApi.listRuleSets(inputValue);
  return rulesets.toJS();
};

const labelForRulesetOption = ({ id, label }: RuleSet) => `${id}: ${label}`;

const valueForRulesetOption = ({ id }: RuleSet) => id.toString();

interface Props {
  // either creation went fine
  // either it was aborted or on error => createdRule === undefined
  onClose: (createdRule?: ReadOnlyRule) => void;
}

export const RuleCreationModal = (props: Props) => {
  const { onClose } = props;

  const dispatch = useDispatch();

  const [isCreatingRule, setIsCreatingRule] = useState(false);

  const ruleEntityTypeOptions = useMemo(
    () =>
      Object.values(ruleEntityTypes).map(({ id, label, help }) => ({
        key: id,
        label,
        help,
      })),
    [],
  );

  const [rule, setRule] = useState<RuleInEdition>({
    name: '',
    ruleEntityType: ruleEntityTypeOptions[0].key,
  });

  const handleChange = (prop: string, value: unknown) =>
    setRule({ ...rule, [prop]: value });

  const handleNameChange = ({ currentTarget }: ChangeEvent<Element>) =>
    handleChange('name', (currentTarget as HTMLInputElement).value);

  const handleRuleEntityTypeChange = ({
    key,
  }: (typeof ruleEntityTypeOptions)[number]) =>
    handleChange('ruleEntityType', key);

  const handleRulesetChange = (rs: TurboSelectOption<RuleSet>) => {
    if (!rs || Array.isArray(rs)) {
      // for typing consistency
      // undefined or null should be prevent by ruleset selector to be not clearable
      // array should not occur single selector is not multiple
      return;
    }
    handleChange('ruleSet', rs);
  };

  const handleConfirm = async () => {
    try {
      setIsCreatingRule(true);
      const { data: createdRule } = await validationApi.post<
        ReadOnlyRule,
        ResponseWithBody<ReadOnlyRule>
      >('/validation/v3/rules/custom', makePostableRule(rule));
      onClose(createdRule);
    } catch (error) {
      const typedError = error as ResponseWithBody<{ message: string }>;
      dispatch(
        notificationError(
          `An error occured while creating the rule. ${typedError.data.message}`,
        ),
      );
    } finally {
      setIsCreatingRule(false);
    }
  };

  const handleAbort = () => onClose();

  const ruleEntityTypeSelectedOption = ruleEntityTypeOptions.find(
    ({ key }) => key === rule.ruleEntityType,
  );

  return (
    <Modal
      title="Create Rule"
      modalStyle="dynamic"
      onClose={handleAbort}
      onConfirm={handleConfirm}
      confirmDisabled={ruleIsIncomplete(rule)}
      className="RuleCreationModal"
      hideCloseButton
      isProcessing={isCreatingRule}
    >
      <div className="RuleCreationModal__modalBody">
        <InputWithLabel
          inputId="InputText-rule-creation-modal-name"
          label="Name"
          testid="rule-creation-modal--row-name"
        >
          <Text
            id="rule-creation-modal-name"
            value={rule.name}
            placeholder={'This field is required'}
            onChange={handleNameChange}
          />
        </InputWithLabel>
        <InputWithLabel
          inputId="SimpleSelect-rule-creation-modal-entity-type"
          label="Entity Type"
          testid="rule-creation-modal--entity-type"
        >
          <SimpleSelect
            autoSize
            id="rule-creation-modal-entity-type"
            value={ruleEntityTypeSelectedOption}
            onSelect={handleRuleEntityTypeChange}
            options={ruleEntityTypeOptions}
          />
        </InputWithLabel>
        {ruleEntityTypeSelectedOption?.help ? (
          <InputWithLabel inputId="fake" label="">
            <span className="RuleEntityTypeHelp">
              {ruleEntityTypeSelectedOption.help}
            </span>
          </InputWithLabel>
        ) : null}
        <InputWithLabel
          inputId="rule-creation-modal-ruleset"
          label="Ruleset"
          testid="rule-creation-modal--ruleset"
        >
          <TurboSelect
            cacheOptions
            isSearchable
            isClearable={false}
            value={rule.ruleSet}
            loadOptions={loadRulesetsOptions}
            onChange={handleRulesetChange}
            getOptionLabel={labelForRulesetOption}
            getOptionValue={valueForRulesetOption}
            placeholder="-- choose --"
            menuPlacement="bottom"
          />
        </InputWithLabel>
      </div>
    </Modal>
  );
};
