import { CancelablePromise } from 'cancelable-promise';

import { ResponseWithBody } from '@alkem/sdk-dashboard';

import { fieldsApiImmutable } from 'resources/fieldsApi';

import { FieldMetadata } from './types';

export const fetchFieldMetadata: (
  fieldId: number,
) => CancelablePromise<FieldMetadata> = (fieldId) => {
  const p = fieldsApiImmutable.getField(
    fieldId,
  ) as unknown as CancelablePromise<ResponseWithBody<FieldMetadata>>;

  return p.then((response: ResponseWithBody<FieldMetadata>) => {
    const {
      data: { applicableForKinds, applicableForTypePackagings },
    } = response;
    return {
      applicableForKinds,
      applicableForTypePackagings,
    };
  });
};
