import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUsers, resetModal, setModalOpen } from '../../actions/users';
import {
  selectCurrentPage,
  selectIsModalOpen,
  selectOrganizationId,
  selectUsers,
} from '../../selectors/users';

import './index.scss';
import { AddUserModal } from './modal';
import OrganizationPageUsersPagination from './pagination';
import OrganizationPageUsersRow from './row';

type Props = {
  displayLogas: boolean;
  isReadOnly: boolean;
};

const OrganizationPageUsers = ({ displayLogas, isReadOnly }: Props) => {
  const dispatch = useDispatch();
  const users: any = useSelector(selectUsers);
  const organizationId: string = useSelector(selectOrganizationId);
  const currentPage = useSelector(selectCurrentPage);
  const isModalOpen = useSelector(selectIsModalOpen);

  useEffect(() => {
    if (organizationId) {
      dispatch(getUsers(organizationId));
    }
  });

  useEffect(() => {
    dispatch(getUsers(organizationId));
  }, [organizationId, currentPage, dispatch]);

  const closeModal = () => {
    dispatch(setModalOpen(false));
    dispatch(resetModal());
  };

  return (
    <div className="OrganizationPageUsers OrganizationPageBlock">
      <div className="OrganizationPageBlock__header">
        <h2>Users</h2>
      </div>
      <div>
        <ul className="OrganizationPageUsers__help">
          <li className="OrganizationPageUsers__help OrganizationPageUsers__help--disabled">
            Disabled
          </li>
          <li className="OrganizationPageUsers__help OrganizationPageUsers__help--pending">
            Pending email validation
          </li>
          <li className="OrganizationPageUsers__help OrganizationPageUsers__help--relation-refused">
            Refused in organization
          </li>
          <li className="OrganizationPageUsers__help OrganizationPageUsers__help--relation-pending">
            Pending organization acceptation
          </li>
        </ul>
      </div>
      <div className="OrganizationPageUsers__container">
        {!!users.size && (
          <ul className="OrganizationPageUsers__users">
            {users.valueSeq().map((user) => (
              <OrganizationPageUsersRow
                key={user.get('id')}
                user={user}
                displayLogas={displayLogas}
              />
            ))}
          </ul>
        )}
        <OrganizationPageUsersPagination />
      </div>
      {!isReadOnly && (
        <>
          <div
            className="OrganizationPageUserAdd"
            onClick={() => dispatch(setModalOpen(true))}
          >
            Add a user
          </div>
          {isModalOpen && (
            <AddUserModal
              organizationId={organizationId}
              closeModal={closeModal}
            />
          )}
        </>
      )}
    </div>
  );
};

export { OrganizationPageUsers };
