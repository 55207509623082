import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';

import { searchOrganizations } from '../actions';
import OrganizationListFilters from '../components/filters';
import OrganizationList from '../components/list';
import { selectSearch } from '../selectors';

const mapStateToProps = (state) => ({
  search: selectSearch(state),
});

const mapDispatchToProps = {
  searchOrganizations,
};

class OrganizationDashboardView extends PureComponent {
  static propTypes = {
    search: PropTypes.string,
    searchOrganizations: PropTypes.func.isRequired,
  };

  constructor(tomate) {
    super(tomate);
    this.onUpdateSearchQuery = this.onUpdateSearchQuery.bind(this);
  }

  onUpdateSearchQuery(query) {
    this.props.searchOrganizations(query);
  }

  render() {
    return (
      <div>
        <HeaderLayout
          title="Organization Dashboard"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        >
          <Search
            query={this.props.search}
            placeholder="Name or Ids, separated by commas"
            updateSearchQuery={this.onUpdateSearchQuery}
          />
        </HeaderLayout>
        <div className="container-fluid row">
          <div className="col-md-3">
            <OrganizationListFilters />
          </div>
          <div className="col-md-9">
            <OrganizationList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationDashboardView);
