import { notificationError } from 'actions/notifications';
import {
  selectFieldMetaDataId,
  selectFilters,
  selectNbSources,
  selectPagination,
  selectSearch,
  selectShowChildren,
} from 'modules/field-suggestion/selectors';
import classificationApi from 'resources/classificationApi';
import { getConceptApi } from 'resources/conceptApi';
import FieldSuggestionApi from 'resources/fieldsuggestionApi';

import {
  FIELDMETADATA_ISCLASSIFIEDIN_ID,
  FIELDMETADATA_KIND_ID,
  REGEX_GTIN_LIST,
} from './constants';
import * as events from './events';

const constructFieldIdsFilters = (
  filters,
  currentFieldMetaDataId,
  showChildren,
) => {
  if (
    showChildren &&
    [FIELDMETADATA_KIND_ID, FIELDMETADATA_ISCLASSIFIEDIN_ID].includes(
      currentFieldMetaDataId,
    )
  ) {
    return {
      should: Object.entries(filters.fieldIds).map(([filterId]) => ({
        must: [
          {
            query: currentFieldMetaDataId,
            fields: ['field.fieldmetadata_id'],
          },
          {
            query: filterId,
            fields: ['field.path'],
          },
        ],
      })),
    };
  }
  return {
    should: Object.entries(filters.fieldIds).map(([filterId]) => ({
      must: [
        {
          query: currentFieldMetaDataId,
          fields: ['field.fieldmetadata_id'],
        },
        {
          query: filterId,
          fields: ['field.field_id'],
        },
      ],
    })),
  };
};

export const constructGtinFilter = (state) => {
  const filter = { should: [] };
  const search = selectSearch(state);
  if (!search) {
    return filter;
  }
  const gtinsMatch = search.match(REGEX_GTIN_LIST);
  if (!gtinsMatch) {
    return filter;
  }
  return {
    should: gtinsMatch[0].split(',').map((gtin) => ({
      query: gtin,
      fields: ['metadata.gtin'],
    })),
  };
};

export const constructFilters = (state) => {
  const filters = selectFilters(state).toJS();

  const languagesFilters = {
    should: Object.entries(filters.languages)
      .filter(([, checked]) => checked)
      .map(([value]) => ({
        query: value,
        fields: ['metadata.language'],
        type: 'term',
      })),
  };

  const nbSources = selectNbSources(state);
  const nbSourcesFilter = {
    query: nbSources,
    fields: ['metadata.source_count'],
  };

  const selectedFieldMetaDataId = selectFieldMetaDataId(state);
  const fieldMetaDataFilter = {
    query: selectedFieldMetaDataId,
    fields: ['field.fieldmetadata_id'],
  };

  const validationStatusFormattedFilters = {
    should: Object.entries(filters.validationStatus)
      .filter(([, checked]) => checked)
      .map(([statusId]) => ({
        query: statusId,
        fields: ['field.status'],
      })),
  };

  const currentValueFormattedFilters = {
    should: Object.entries(filters.isCurrentValue)
      .filter(([, checked]) => checked)
      .map(([value]) => ({
        query: value,
        fields: ['field.is_current_value'],
      })),
  };

  const sourceFormattedFilters = {
    must: Object.entries(filters.source)
      .filter(([, checked]) => checked)
      .map(([value]) => ({
        query: value,
        fields: ['metadata.sources'],
        type: 'term',
      })),
  };

  const organizationFormattedFilters = {
    should: Object.entries(filters.organizations)
      .filter(([, checked]) => checked)
      .map(([value]) => ({
        query: value,
        fields: ['metadata.contentowner.id'],
      })),
  };

  const fieldIdsFilters = constructFieldIdsFilters(
    filters,
    selectedFieldMetaDataId,
    selectShowChildren(state),
  );

  const gtinsFilter = constructGtinFilter(state);
  const httpFilters = {
    must: [
      languagesFilters,
      fieldIdsFilters,
      validationStatusFormattedFilters,
      currentValueFormattedFilters,
      organizationFormattedFilters,
      gtinsFilter,
    ].filter((f) => f.should.length), // Remove empty filters
  };
  if (sourceFormattedFilters.must.length) {
    httpFilters.must.push(sourceFormattedFilters);
  }

  // the fieldMetaData filter is taken into account only if a fieldMetaDataId is selected, and no fields are chosen
  if (selectedFieldMetaDataId && fieldIdsFilters.should.length === 0) {
    httpFilters.must.push(fieldMetaDataFilter);
  }
  if (nbSources) {
    httpFilters.must.push(nbSourcesFilter);
  }

  return httpFilters.must.length ? httpFilters : {};
};

export const constructRangeQuery = (state) => {
  const metricValue = selectFilters(state).get('metricValue').keySeq().first();
  if (!metricValue) {
    return null;
  }

  return {
    range_field: 'extended_attributes.probability',
    from: metricValue,
  };
};

export const fetchInitialAggregations = () => async (dispatch) => {
  const body = {};
  body.aggregations = [
    {
      key_field: 'field.fieldmetadata_id',
      value_field: 'field.field_id',
      value_size: 1000,
      top_hits_fields: ['field.fieldmetadata_name', 'field.field_name'],
    },
    {
      key_field: 'metadata.contentowner.id',
      value_size: 1000,
      top_hits_fields: ['metadata.contentowner.name'],
    },
    {
      key_field: 'metadata.source_count',
      value_size: 1000,
    },
  ];
  try {
    const response = await FieldSuggestionApi.GetFieldSuggestionList(body);
    dispatch({
      type: events.FIELD_SUGGESTION_RECEIVE_AGGREGATIONS,
      aggregations: response.data.aggregations,
    });
  } catch (error) {
    dispatch(
      notificationError("Couldn't fetch organizations and fieldmetadata ids."),
    );
  }
};

export const buildServiceSearchEsQuery = (state, resetPage) => {
  const filter = constructFilters(state);
  const pagination = selectPagination(state);
  const search = selectSearch(state);

  // remove GTINs from query string
  const filteredSearch = search ? search.replace(REGEX_GTIN_LIST, '') : search;
  const sort = {
    path: pagination.sortBy,
    order: pagination.sortOrder,
  };
  const offset = resetPage
    ? 0
    : Math.max((pagination.currentPage - 1) * pagination.limit, 0);
  const rangeQuery = constructRangeQuery(state);

  return {
    filter,
    sort_by: sort,
    offset,
    limit: pagination.limit,
    query_string: filteredSearch,
    range_query: rangeQuery,
  };
};

export const getFieldSuggestionList = (resetPage) => (dispatch, getState) => {
  const state = getState();
  const body = buildServiceSearchEsQuery(state, resetPage);

  return FieldSuggestionApi.GetFieldSuggestionList(body).then((response) => {
    const list = response.data.data.map((e) => e.set('checked', false));
    const { totalResults } = response.data;
    dispatch({
      type: events.FIELD_SUGGESTION_RECEIVE_LIST,
      list,
      totalResults,
      resetPage,
    });
  });
};

export const addFilter = (
  filterType,
  toggleFilterType,
  filterId,
  filterLabel,
) => ({
  type: events.FIELD_SUGGESTION_ADD_FILTER,
  filterType,
  toggleFilterType,
  filterId,
  filterLabel,
});

export const removeFilter = (filterType, filterId) => ({
  type: events.FIELD_SUGGESTION_REMOVE_FILTER,
  filterType,
  filterId,
});

export const clearFilters = () => ({
  type: events.FIELD_SUGGESTION_CLEAR_FILTERS,
});

export const nextPage = () => ({ type: events.FIELD_SUGGESTION_NEXT_PAGE });

export const previousPage = () => ({
  type: events.FIELD_SUGGESTION_PREVIOUS_PAGE,
});

export const changeLimit = (limit) => ({
  type: events.FIELD_SUGGESTION_CHANGE_LIMIT,
  limit,
});
export const changeSorting = (sortBy, sortOrder) => ({
  type: events.FIELD_SUGGESTION_CHANGE_SORTING,
  sortBy,
  sortOrder,
});

export const checkRow = (index, checked) => ({
  type: events.FIELD_SUGGESTION_CHECK_ROW,
  index,
  checked,
});

export const checkAllRows = (checked) => ({
  type: events.FIELD_SUGGESTION_CHECK_ALL_ROWS,
  checked,
});

export const openModal = (modalState) => ({
  type: events.FIELD_SUGGESTION_OPEN_MODAL,
  modalState,
});

export const closeModal = () => ({
  type: events.FIELD_SUGGESTION_CLOSE_MODAL,
});

export const validateSuggestion = (entity, validationStatus) => ({
  type: events.FIELD_SUGGESTION_SINGLE_VALIDATION,
  entity,
  validationStatus,
});

export const bulkValidateSuggestions = (validationStatus) => ({
  type: events.FIELD_SUGGESTION_BULK_VALIDATION,
  validationStatus,
});

export const bulkEditSuggestions = (fieldmetadata, field) => ({
  type: events.FIELD_SUGGESTION_BULK_EDITION,
  fieldmetadata,
  field,
});

export const updateSearch = (search) => ({
  type: events.FIELD_SUGGESTION_UPDATE_SEARCH,
  search,
});

export const getKindLeaves = () => async (dispatch, getState) => {
  const conceptApi = getConceptApi(getState().user.get('language'));
  const response = await conceptApi.ConceptGetByName('kinds', {
    onlyLeaves: true,
    onlyVisible: true,
  });
  dispatch({
    type: events.RECEIVE_KIND_LEAVES,
    data: response.data.data.map((kind) => kind.id),
  });
};

export const getSegmentHierarchies = () => async (dispatch) => {
  const body = {
    aggregations: [
      {
        key_field: 'metadata.contentowner.id',
        top_hits_fields: 'metadata.contentowner',
      },
    ],
    filter: {
      query: 'retailer',
      fields: ['metadata.workflow'],
    },
  };
  // feed state with retailers' hierarchies
  FieldSuggestionApi.GetFieldSuggestionList(body).then((response) => {
    const retailers = response.data.aggregations[
      'metadata.contentowner.id'
    ].map((agg) => agg.top_hits[0].metadata.contentowner);
    retailers.forEach((retailer) => {
      classificationApi
        .GetProductSegmentHierarchy(retailer.id)
        .then((searchResponse) =>
          dispatch({
            type: events.RECEIVE_SEGMENT_HIERARCHY,
            hierarchy: searchResponse.data.data,
            organization: retailer,
          }),
        );
    });
  });
};

export const toggleShowHierarchyChildren = () => ({
  type: events.TOGGLE_SHOW_HIERARCHY_CHILDREN,
});
