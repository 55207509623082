import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { SET_SELECTED_EXPORT_MAPPING } from '../../../actions/constants';
import { DECLINABLE_TYPES } from '../../../constants';
import { ExportMapping, Xpath } from '../../../types';

import { ExportMappingsItemBooleanElementProperties } from './boolean-element-properties';
import { ExportMappingsItemDateElementProperties } from './date-element-properties';
import { ExportMappingsItemDeclinableByLanguagesStringElementProperties } from './declinable-by-languages-string-element-properties';
import { ExportMappingsItemDeclinableByOtherThanLanguagesElementProperties } from './declinable-by-other-than-languages-element-properties';
import { ExportMappingsItemDoNotAddModuleProperty } from './do-not-add-module-property';
import styles from './item-properties.module.scss';
import { ExportMappingsItemMustNotLoopProperty } from './must-not-loop-property';
import { ExportMappingsItemNewModuleProperty } from './new-module-property';
import { ExportMappingsItemNotDeclinableElementProperties } from './not-declinable-element-properties';
import { ExportMappingsItemSetAttributeProperty } from './set-attribute-property';
import { ExportMappingsItemUseGetAttrRecWithChildrenProperty } from './use-get-attr-rec-with-children-property';

interface Props {
  name: string;
  exportMapping: ExportMapping;
  disabled?: boolean;
  isFirst?: boolean;
  parentExportMapping?: ExportMapping;
  parentElement?: Xpath;
}
export function ExportMappingsItemProperties({
  name,
  exportMapping,
  disabled = true,
  isFirst = false,
  parentExportMapping,
  parentElement,
}: Props) {
  const dispatch = useDispatch();

  if (
    !exportMapping.payload.cast ||
    (exportMapping.payload.cast === 'gdsn_node' && isFirst)
  )
    return null;

  const dispatchProperty = (name: string, value: any) => {
    dispatch({
      type: SET_SELECTED_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
          payload: {
            ...exportMapping.payload,
            [name]: value,
          },
        },
      },
    });
  };

  return (
    <div
      data-testid={`GDSNExportMappingsItem_properties_${name}`}
      className={classNames(styles.container, {
        [styles.disabledContainer]: disabled,
      })}
    >
      <div className={styles.sectionTitle}>Properties</div>
      {exportMapping.payload.cast === 'gdsn_node' && (
        <ExportMappingsItemDoNotAddModuleProperty
          name={name}
          exportMapping={exportMapping}
          dispatchProperty={dispatchProperty}
        />
      )}
      {exportMapping.payload.cast !== 'gdsn_node' && (
        <ExportMappingsItemUseGetAttrRecWithChildrenProperty
          name={name}
          exportMapping={exportMapping}
          dispatchProperty={dispatchProperty}
        />
      )}
      {!['gdsn_node', 'list'].includes(exportMapping.payload.cast || '') && (
        <div
          data-testid={`GDSNExportMappingsItem_properties_simpleElements_${name}`}
        >
          <ExportMappingsItemMustNotLoopProperty
            name={name}
            field={exportMapping.payload.field}
            exportMapping={exportMapping}
            dispatchProperty={dispatchProperty}
          />
          {parentExportMapping?.payload.cast === 'gdsn_node' &&
            parentExportMapping.payload.do_not_add_module &&
            parentElement && (
              <ExportMappingsItemNewModuleProperty
                name={name}
                exportMapping={exportMapping}
                parentElement={parentElement}
                dispatchProperty={dispatchProperty}
              />
            )}
          <ExportMappingsItemSetAttributeProperty
            name={name}
            exportMapping={exportMapping}
            dispatchProperty={dispatchProperty}
          />
          {exportMapping.payload.cast === 'boolean' && (
            <ExportMappingsItemBooleanElementProperties
              name={name}
              exportMapping={exportMapping}
              dispatchProperty={dispatchProperty}
            />
          )}
          {DECLINABLE_TYPES.includes(exportMapping.payload.cast || '') &&
            !exportMapping.payload.is_declinable && (
              <ExportMappingsItemNotDeclinableElementProperties
                name={name}
                exportMapping={exportMapping}
                dispatchProperty={dispatchProperty}
              />
            )}
          {['string', 'text'].includes(exportMapping.payload.cast || '') &&
            exportMapping.payload.is_declinable &&
            exportMapping.payload.referential === 'languages' && (
              <ExportMappingsItemDeclinableByLanguagesStringElementProperties
                name={name}
                disabled={disabled}
                exportMapping={exportMapping}
                dispatchProperty={dispatchProperty}
              />
            )}
          {!(
            ['string', 'text'].includes(exportMapping.payload.cast || '') &&
            exportMapping.payload.referential === 'languages'
          ) &&
            exportMapping.payload.is_declinable && (
              <ExportMappingsItemDeclinableByOtherThanLanguagesElementProperties
                name={name}
                disabled={disabled}
                exportMapping={exportMapping}
                dispatchProperty={dispatchProperty}
              />
            )}
          {['date', 'iso_date'].includes(exportMapping.payload.cast || '') && (
            <ExportMappingsItemDateElementProperties
              name={name}
              exportMapping={exportMapping}
            />
          )}
        </div>
      )}
    </div>
  );
}
