import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './search.scss';

export default class CatalogAdvancedFilterSearch extends PureComponent {
  static propTypes = {
    query: PropTypes.string,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    query: '',
    placeholder: '',
  };

  constructor(props) {
    super(props);
    this.state = { query: props.query };
  }

  onSearch = debounce((query) => {
    this.props.onSearch(query);
  }, 500);

  onChange = (event) => {
    const { value } = event.target;
    this.setState({ query: value });
    this.onSearch(value);
  };

  onClear = () => {
    this.setState({ query: '' });
    this.props.onSearch('');
  };

  render() {
    return (
      <div className="CatalogAdvancedFilterSearch">
        <i className="CatalogAdvancedFilterSearch__searchIcon mdi mdi-24px mdi-magnify" />
        <input
          className="CatalogAdvancedFilterSearch__searchInput"
          value={this.state.query}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
        />
        {!!this.state.query && (
          <i
            className="CatalogAdvancedFilterSearch__clearIcon mdi mdi-24px mdi-close"
            onClick={this.onClear}
          />
        )}
      </div>
    );
  }
}
