import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import { notificationError } from 'actions/notifications';
import InputWithLabel from 'components/input-with-label';

import { SET_SELECTED_EXPORT_MAPPING } from '../../../actions/constants';
import {
  ExportMapping,
  ExportMappingPayloadConstants,
  Option,
  Options,
} from '../../../types';
import { isOption } from '../utils';

import { ExportMappingsItemConstantItem } from './constant';
import styles from './item-constants.module.scss';

const constantCastOptions: Options = [{ id: 'function' }, { id: 'lambda' }];
interface Props {
  name: string;
  exportMapping: ExportMapping;
  disabled?: boolean;
}
export function ExportMappingsItemConstants({
  name,
  exportMapping,
  disabled = true,
}: Props) {
  const dispatch = useDispatch();
  const [constantCastOption, setConstantCastOption] = useState<
    Option | undefined
  >(undefined);

  const dispatchUpdatedConstants = (
    constants_for_later_use?: ExportMappingPayloadConstants,
  ) => {
    dispatch({
      type: SET_SELECTED_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
          payload: {
            ...exportMapping.payload,
            constants_for_later_use,
          },
        },
      },
    });
  };

  const removeConstant = (name: string): void => {
    const updatedConstants = {
      ...exportMapping.payload.constants_for_later_use,
    };
    delete updatedConstants[name];
    dispatchUpdatedConstants(
      Object.keys(updatedConstants).length === 0 ? undefined : updatedConstants,
    );
  };

  const addConstant = () => {
    if (constantCastOption) {
      const updatedConstants = {
        ...(exportMapping.payload.constants_for_later_use || {}),
      };
      const prefix = 'new_constant_';
      let isNameFound = false;
      let id = Object.keys(updatedConstants).length;
      let name = prefix + id;
      while (!isNameFound) {
        name = prefix + id;
        if (!Object.keys(updatedConstants).includes(name)) {
          isNameFound = true;
        } else {
          id += 1;
        }
      }
      updatedConstants[name] = {
        cast: constantCastOption.id,
      };
      dispatchUpdatedConstants(updatedConstants);
    }
  };

  const editConstantName = (currentName: string, newName: string) => {
    if (currentName !== newName) {
      if (newName === '') {
        const errorMessage = 'The constant must have a name.';
        dispatch(notificationError(errorMessage));
        throw new Error(errorMessage);
      } else if (
        Object.keys(
          exportMapping.payload.constants_for_later_use || [],
        ).includes(newName)
      ) {
        const errorMessage =
          'This constant name already exists. You should remove the existing or create a new one.';
        dispatch(notificationError(errorMessage));
        throw new Error(errorMessage);
      } else {
        const updatedConstants = {
          ...exportMapping.payload.constants_for_later_use,
        };
        delete Object.assign(updatedConstants, {
          [newName]: updatedConstants[currentName],
        })[currentName];
        dispatchUpdatedConstants(updatedConstants);
      }
    }
  };

  const editConstantData = (name: string, data?: string) => {
    const updatedConstants = {
      ...exportMapping.payload.constants_for_later_use,
    };
    updatedConstants[name].data = data || undefined;
    dispatchUpdatedConstants(updatedConstants);
  };

  const { constants_for_later_use } = exportMapping.payload;

  return (
    <div
      data-testid={`GDSNExportMappingsItem_constants_${name}`}
      className={classNames({
        [styles.containerDisabled]: disabled,
      })}
    >
      <div className={styles.sectionTitle}>Constants usable in childrens</div>
      <InputWithLabel
        inputId={`gdsn-export-mappings-item-constant-cast-list-${name}`}
        label={
          <Button
            id={`gdsn-export-mappings-item-constant-add-button-${name}`}
            content="Add"
            secondary
            onClick={addConstant}
            disabled={!constantCastOption}
          />
        }
      >
        <div
          data-testid={`gdsn-export-mappings-item-constant-cast-list-${name}`}
        >
          <TurboSelect
            id={`gdsn-export-mappings-item-constant-cast-list-${name}`}
            options={constantCastOptions}
            getOptionLabel={(item) => item.id}
            getOptionValue={(item) => item.id}
            value={constantCastOption}
            isMulti={false}
            isSearchable={false}
            isClearable
            onChange={(value: TurboSelectOption<Option>) =>
              setConstantCastOption(isOption(value) ? value : undefined)
            }
            placeholder="Select..."
          />
        </div>
      </InputWithLabel>
      {constants_for_later_use &&
        Object.entries(constants_for_later_use).map(([key, value]) => (
          <ExportMappingsItemConstantItem
            key={key}
            id={`${name}-${key}`}
            constant={value}
            name={key}
            removeConstant={removeConstant}
            editConstantName={editConstantName}
            editConstantData={editConstantData}
          />
        ))}
    </div>
  );
}
