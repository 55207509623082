import { ConditionCast } from './types';

export const XPATH_TYPES = {
  avp: 'AVP',
  module: 'MODULE',
  list: 'LIST',
  simple: 'SIMPLE',
};

export const FORBIDDEN_FIELD_TYPES = [
  'int negative',
  'float negative',
  'ternary',
  'timestamp',
  'time',
  'product',
  'productsegment',
  'brand',
  'kind',
  'enum',
  'uri_entity',
  'gln',
  'copy',
];

export const DECLINABLE_TYPES = [
  'int',
  'int positive',
  'float',
  'float positive',
  'string',
  'text',
];

export const CONDITION_CAST_LIST: ConditionCast[] = [
  'function',
  'lambda',
  'target_glns',
  'not_target_glns',
  'same_level_gdsn_field_exists',
  'does_module_name_match_composition',
  'not_empty_module',
  'rules_bypassed',
  'same_level_fields_exist',
  'same_level_fields_dont_exist',
];

export const FIELD_TYPES_WITH_CHILDREN = ['list', 'dict'];

export const REDUCER_KEY = 'gdsn-export-mappings';

export const DATETIME_FORMAT = 'YYYY-MM-DD[T00:00:00]';
export const UNLIMITED_DATE = '9999-12-31T00:00:00';
