import { get, isEmpty } from 'lodash/fp';
import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY } from './constants';
import { OrganizationGroupFieldState } from './type';

const selectState = (state) =>
  get([MODULE_REDUCER_KEY, REDUCER_KEY], state) as OrganizationGroupFieldState;

export const selectOrganization = createSelector(selectState, (state) =>
  isEmpty(state.organization) ? null : state.organization,
);

export const selectGroups = createSelector(
  selectState,
  (state) => state.groups,
);

export const selectGroupsForImport = createSelector(
  selectState,
  (state) => state.importModal?.groups,
);

export const selectDefaultFieldGroup = createSelector(
  selectState,
  (state) => state.defaultFieldGroup,
);
