import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { ExportMapping } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

interface Props {
  name: string;
  exportMapping: ExportMapping;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemBooleanElementProperties({
  name,
  exportMapping,
  dispatchProperty,
}: Props) {
  const [isGS1Enum, setIsGS1Enum] = useState<true | undefined>(
    exportMapping.payload.is_GS1_enum,
  );
  const [defaultValue, setDefaultValue] = useState<boolean | undefined>(
    exportMapping.payload.cast !== 'boolean'
      ? undefined
      : exportMapping.payload.default_value,
  );

  if (exportMapping.payload.cast !== 'boolean') return null;

  const updateIsGS1Enum = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedIsGS1Enum = value === 'true' || undefined;
    setIsGS1Enum(updatedIsGS1Enum);
    dispatchProperty('is_GS1_enum', updatedIsGS1Enum);
  };

  const updateDefaultValue = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedDefaultValue =
      value === 'true' || (value === 'false' ? false : undefined);
    setDefaultValue(updatedDefaultValue);
    dispatchProperty('default_value', updatedDefaultValue);
  };

  return (
    <div data-testid={`gdsn-export-mappings-bln-element-properties-${name}`}>
      <div className={styles.property}>
        <InputWithLabel
          inputId={`gdsn-export-mappings-bln-element-is-gs1-enum-property-${name}`}
          label={
            <span>
              Is GS1 enum ?{' '}
              <HelpTooltip
                id={`gdsn-export-mappings-bln-element-is-gs1-enum-property-${name}-help`}
                place="top"
                message="Must be set to TRUE if the boolean is mapped to a shared_common:NonBinaryLogicEnumerationType in the xsd"
              />
            </span>
          }
        >
          <Radio
            id={`gdsn-export-mappings-bln-element-is-gs1-enum-property-${name}`}
            value={isGS1Enum}
            options={[
              { label: 'TRUE', value: true },
              { label: 'FALSE', value: undefined },
            ]}
            onChange={updateIsGS1Enum}
          />
        </InputWithLabel>
      </div>
      <div className={styles.property}>
        <InputWithLabel
          inputId={`gdsn-export-mappings-bln-element-default-value-property-${name}`}
          label="Default value"
        >
          <Radio
            id={`gdsn-export-mappings-bln-element-default-value-property-${name}`}
            value={defaultValue}
            options={[
              { label: 'TRUE', value: true },
              { label: 'FALSE', value: false },
              { label: 'None', value: undefined },
            ]}
            onChange={updateDefaultValue}
          />
        </InputWithLabel>
      </div>
    </div>
  );
}
