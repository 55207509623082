import { datadogRum } from '@datadog/browser-rum';
import { get } from 'lodash';

import { User } from 'modules/auth/types';
import type { AppConfig } from 'types/config';
import { logError } from 'utils';
import { getOrganizationId, getUserId } from 'utils/user';

let initialized = false;

const getDatadogConfig = () =>
  (get(global, ['appconfig', 'datadog']) as AppConfig['datadog']) || {
    enabled: false,
  };

export const initDataDogRUM = () => {
  try {
    const config = getDatadogConfig();
    if (initialized || !config.enabled) {
      return;
    }

    const { enabled, allowedTracingUrls, ...rumConfig } = config;
    datadogRum.init({
      ...rumConfig,
      version: global.FA_VERSION,
    });

    initialized = true;
  } catch (err) {
    logError(err);
  }
};

export function identifyDataDogSession(user: User) {
  try {
    datadogRum.setUser({
      id: getUserId(user) as any,
      oid: getOrganizationId(user),
    });
  } catch (err) {
    logError(err);
  }
}
