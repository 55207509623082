import { useMemo, useState } from 'react';

import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';

import { CodeEditor } from 'components/code-editor';
import { toJsIfImmutable } from 'utils/immutable';

import './payload-modal.scss';
import { filterOnKeys } from './utils';

interface Props {
  payload: any;
  onClose: Function;
}

export function PayloadModal({ payload, onClose }: Props) {
  const [keyFilter, setKeyFilter] = useState('');
  const data = useMemo(() => toJsIfImmutable(payload), [payload]);
  return (
    <Modal
      title="Payload"
      modalStyle="large"
      hideFooter
      onClose={() => {
        setKeyFilter('');
        onClose();
      }}
    >
      <div className="PayloadModal">
        <div className="PayloadModal__Body">
          <div>
            <Text
              id="payload-modal-key-filter"
              value={keyFilter}
              placeholder="Search on the keys"
              onChange={(evt) => {
                setKeyFilter(evt.target.value);
              }}
            />
          </div>
          <CodeEditor
            name="payload"
            className="PayloadModal__Content"
            value={JSON.stringify(filterOnKeys(data, keyFilter), null, '\t')}
            readOnly
            mode="json"
            theme="monokai"
            width="inital"
            height="inital"
            editorProps={{ $blockScrolling: Infinity }}
          />
        </div>
      </div>
    </Modal>
  );
}
