import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import {
  Condition,
  ConditionWithStringArrayData,
} from 'modules/io/gdsn/exports/types';

import styles from '../item-conditions.module.scss';

interface Props {
  index: number;
  condition: ConditionWithStringArrayData;
  updateCondition: (index: number, condition: Condition) => void;
  name: string;
}
export function ExportMappingsItemSameLevelSxmFieldsDontExistCondition({
  index,
  condition,
  updateCondition,
  name,
}: Props) {
  const [sameLevelSxmFieldsDontExist, setSameLevelSxmFieldsDontExist] =
    useState<string | undefined>(condition.data?.join(',') || undefined);

  const updateSameLevelSxmFieldsDontExist = (event: {
    target: { value: string };
  }) => {
    const data = event.target.value.replace(' ', '') || undefined;
    setSameLevelSxmFieldsDontExist(data);
    updateCondition(index, { ...condition, data: data?.split(',') });
  };

  return (
    <InputWithLabel
      inputId={`gdsn-export-mappings-same-level-sxm-fields-dont-exist-${name}`}
      label={
        <span>
          Sxm fields don't exist{' '}
          <HelpTooltip
            id={`gdsn-export-mappings-same-level-sxm-fields-dont-exist-${name}-help`}
            place="top"
            message={
              <div className={styles.messageContainer}>
                <span>
                  Write a list of SXM fields on the same level of the actual
                  field with a comma separator if you want to check several
                  values.
                </span>
                <span>
                  If at least one of the field doesn't exist, the condition will
                  be fullfilled.
                </span>
              </div>
            }
          />
        </span>
      }
    >
      <div
        data-testid={`gdsn-export-mappings-same-level-sxm-fields-dont-exist-${name}`}
      >
        <Text
          id={`gdsn-export-mappings-same-level-sxm-fields-dont-exist-${name}`}
          value={sameLevelSxmFieldsDontExist || ''}
          onChange={updateSameLevelSxmFieldsDontExist}
          type="text"
          invalid={!sameLevelSxmFieldsDontExist}
        />
      </div>
    </InputWithLabel>
  );
}
