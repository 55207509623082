import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import ListController from 'components/ui/list/controller';
import { separateActions } from 'utils/redux';

import {
  changeLimit,
  getGDSNRelationList,
  nextPage,
  previousPage,
} from '../actions';
import { selectList, selectPagination, selectState } from '../selectors';

import './list.scss';
import GDSNRelationListRow from './row';

const mapStateToProps = (state) => ({
  list: selectList(state),
  isLoading: selectState(state).isLoading,
  pagination: selectPagination(state),
});

const mapDispatchToProps = {
  getGDSNRelationList,
  nextPage,
  previousPage,
  changeLimit,
};

export class GDSNRelationList extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      getGDSNRelationList: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.actions.getGDSNRelationList();
  }

  refresh = () => {
    this.props.actions.getGDSNRelationList();
  };

  renderList() {
    const { list, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className="GDSNRelationList__spinner">
          <Spinner loading big />
        </div>
      );
    }
    let i = 0;
    return list.map((relation) => {
      i += 1;
      return (
        <GDSNRelationListRow key={`${relation.id}-${i}`} relation={relation} />
      );
    });
  }

  render() {
    const { pagination, actions } = this.props;
    const { currentPage, totalPages, limit, totalResults } = pagination;
    return (
      <div>
        <ListController
          actions={
            <div>
              <Button onClick={this.refresh}>
                <i className="mdi mdi-refresh" />
              </Button>
            </div>
          }
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalResults={totalResults}
          onLimitChange={actions.changeLimit}
          rowsLength={0}
          type="relationlist"
        >
          {this.renderList()}
        </ListController>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(GDSNRelationList);
