import { List, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Checkbox } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { Select } from '@alkem/react-ui-select';

import InputWithLabel from 'components/input-with-label';

import DisplayGroupPicker from '../field-form/visibility-info/display-groups';
import FieldKinds from '../field-form/visibility-info/kinds';
import FieldPackagingTypes from '../field-form/visibility-info/packaging-types';

import './bulk-edit.scss';
import FieldSelect from './field-select';

export default class FieldBulkEdit extends PureComponent {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    tags: PropTypes.array,
    isConsumerUnitField: PropTypes.bool.isRequired,
    saveInProgress: PropTypes.bool.isRequired,
    stop: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = {
    ...this.getDefaultState(),
    formattedFields: [],
  };

  componentDidMount() {
    this.setState({
      formattedFields: this.props.fields
        .map((f) => this.formatField(f, true))
        .toArray(),
    });
  }

  onCancel = () => {
    const { stop } = this.props;
    this.setState(this.getDefaultState());
    stop();
  };

  onApply = () => {
    const { save } = this.props;
    const { edited, selectedFields } = this.state;
    const filteredEdited = edited.filter((v, k) => this.hasAttribute(k));
    const updatedFields = selectedFields.map((f) =>
      filteredEdited
        .set('id', f.id)
        .update('tags', (tags) =>
          (tags || List()).concat(List(f.tags)).toSet().toArray(),
        )
        .toJS(),
    );
    save(updatedFields);
  };

  onAddField = (field) => {
    const { selectedFields } = this.state;
    this.setState({ selectedFields: [...selectedFields, field] });
  };

  onRemoveField = (index) => {
    const { selectedFields } = this.state;
    this.setState({
      selectedFields: selectedFields.filter((f, i) => i !== index),
    });
  };

  onToggleAttribute = (attribute) => () => {
    const { selectedAttributes } = this.state;
    const hasAttribute = this.hasAttribute(attribute.path);
    if (!hasAttribute) {
      this.setState({ selectedAttributes: [...selectedAttributes, attribute] });
    } else {
      this.setState({
        selectedAttributes: selectedAttributes.filter(
          (a) => a.path !== attribute.path,
        ),
      });
    }
  };

  onUpdateEnabled = (event) => {
    const { edited } = this.state;
    const { checked } = event.target;
    this.setState({ edited: edited.set('enabled', checked) });
  };

  onAddTag = (tag) => {
    const { edited } = this.state;
    const tags = edited.get('tags');
    if (!tags.includes(tag.label)) {
      this.setState({ edited: edited.set('tags', tags.push(tag.label)) });
    }
  };

  onRemoveTag = (index) => {
    const { edited } = this.state;
    const tags = edited.get('tags');
    this.setState({ edited: edited.set('tags', tags.remove(index)) });
  };

  onUpdateDisplayGroup = (displayGroup) => {
    const { edited } = this.state;
    this.setState({
      edited: edited.set('belongsToDisplayGroups', fromJS([displayGroup])),
    });
  };

  onUpdateKinds = (kinds) => {
    const { edited } = this.state;
    this.setState({ edited: edited.set('applicableForKinds', kinds) });
  };

  onUpdatePackagingTypes = (packagingTypes) => {
    const { edited } = this.state;
    this.setState({
      edited: edited.set('applicableForTypePackagings', packagingTypes),
    });
  };

  getDefaultState() {
    return {
      selectedFields: [],
      selectedAttributes: [],
      edited: fromJS({
        enabled: false,
        tags: [],
        belongsToDisplayGroups: [],
        applicableForKinds: [],
        applicableForTypePackagings: [],
      }),
    };
  }

  formatField = (field, isRoot) => ({
    id: field.get('id'),
    label: field.get('name'),
    tags: (field.get('tags') || List()).toArray(),
    children: (field.get('children') || List())
      .map((f) => this.formatField(f, false))
      .toArray(),
    isRoot,
  });

  componentsWillReceiveProps(nextProps) {
    if (nextProps.fields !== this.props.fields) {
      this.setState({
        formattedFields: nextProps.fields
          .map((f) => this.formatField(f, true))
          .toArray(),
      });
    }
  }

  hasAttribute(path) {
    const { selectedFields, selectedAttributes } = this.state;
    return (
      selectedAttributes.findIndex((a) => a.path === path) !== -1 &&
      // Only `enabled` can be modified for non root fields.
      (path === 'enabled' || selectedFields.every((f) => f.isRoot))
    );
  }

  renderAddField() {
    const { formattedFields, selectedFields } = this.state;

    return (
      <div
        className="FieldBulkEdit__addField"
        data-testid="fieldBulkEdit-addField"
      >
        <FieldSelect
          id="FieldBulkEdit-add-field"
          placeholder="Add a field..."
          options={formattedFields}
          values={selectedFields}
          onValueAdd={this.onAddField}
          onValueDelete={this.onRemoveField}
          isTree
          inputable
          multiple
        />
      </div>
    );
  }

  renderAddAttributes() {
    const { isConsumerUnitField } = this.props;
    let attributes = [
      { label: 'Enabled on stream', path: 'enabled' },
      { label: 'Add tags', path: 'tags' },
      { label: 'Position in product page', path: 'belongsToDisplayGroups' },
    ];
    if (isConsumerUnitField) {
      attributes = [
        ...attributes,
        { label: 'Kinds', path: 'applicableForKinds' },
        { label: 'Packaging types', path: 'applicableForTypePackagings' },
      ];
    }
    return (
      <div
        className="FieldBulkEdit__addAttributes"
        data-testid="fieldBulkEdit-addAttributes"
      >
        <span>Select attributes to edit:</span>
        {attributes.map((a) => (
          <Checkbox
            key={a.path}
            id={`FieldBulkEdit-add-attribute-${a.path}`}
            checked={this.hasAttribute(a.path)}
            onChange={this.onToggleAttribute(a)}
            label={a.label}
          />
        ))}
      </div>
    );
  }

  renderAttributes() {
    const { tags } = this.props;
    const { edited } = this.state;
    return (
      <div
        className="FieldBulkEdit__attributes"
        data-testid="fieldBulkEdit-attributes"
      >
        {this.hasAttribute('enabled') && (
          <InputWithLabel inputId="field-enabled" label="Enabled on stream">
            <Checkbox
              id="field-enabled"
              checked={edited.get('enabled')}
              onChange={this.onUpdateEnabled}
              label={<div className="FieldForm__checkboxLabel" />}
            />
          </InputWithLabel>
        )}
        {this.hasAttribute('tags') && (
          <InputWithLabel inputId="field-tags" label="Add tags">
            <Select
              id="field-tags"
              placeholder="Add a tag..."
              options={tags}
              values={(edited.get('tags') || List())
                .toArray()
                .map((t) => ({ label: t }))}
              onValueAdd={this.onAddTag}
              onValueDelete={this.onRemoveTag}
              inputable
              multiple
            />
          </InputWithLabel>
        )}
        {this.hasAttribute('belongsToDisplayGroups') && (
          <InputWithLabel
            inputId="field-belongsToDisplayGroups"
            label="Position in product page"
          >
            <DisplayGroupPicker
              link={edited.getIn(['belongsToDisplayGroups', 0])}
              fieldId={null}
              save={this.onUpdateDisplayGroup}
            />
          </InputWithLabel>
        )}
        {this.hasAttribute('applicableForKinds') && (
          <InputWithLabel
            inputId="field-applicableForKinds"
            label="Applicable for kinds"
          >
            <FieldKinds
              id="field-applicableForKinds"
              kinds={edited.get('applicableForKinds') || List()}
              onChange={this.onUpdateKinds}
            />
          </InputWithLabel>
        )}
        {this.hasAttribute('applicableForTypePackagings') && (
          <InputWithLabel
            inputId="field-applicableForTypePackagings"
            label="Applicable for packaging types"
          >
            <FieldPackagingTypes
              id="field-applicableForTypePackagings"
              packagingTypes={
                edited.get('applicableForTypePackagings') || List()
              }
              onChange={this.onUpdatePackagingTypes}
            />
          </InputWithLabel>
        )}
      </div>
    );
  }

  render() {
    const { saveInProgress } = this.props;
    return (
      <Modal
        title="Bulk edit fields"
        modalStyle="large"
        confirmButtonText="Save"
        isProcessing={saveInProgress}
        onConfirm={this.onApply}
        onClose={this.onCancel}
      >
        <div className="FieldBulkEdit">
          {this.renderAddField()}
          {this.renderAddAttributes()}
          {this.renderAttributes()}
        </div>
      </Modal>
    );
  }
}
