import proptypes from 'prop-types';
import React from 'react';

import './suggestion.scss';

export function CommandHistoryItem({
  commandText,
  selectCommandHistory,
  isFocused,
  handleMouseOver,
  handleMouseOut,
}) {
  return (
    <button
      className={
        isFocused
          ? 'CommandPalette__Suggestion CommandPalette__Suggestion__selected'
          : 'CommandPalette__Suggestion'
      }
      onClick={selectCommandHistory}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="CommandPalette__SuggestionCommand">{commandText}</div>
    </button>
  );
}

CommandHistoryItem.propTypes = {
  commandText: proptypes.string,
  selectCommandHistory: proptypes.func,
  isFocused: proptypes.bool,
  handleMouseOver: proptypes.func,
  handleMouseOut: proptypes.func,
};
