import { TurboSelectOption } from '@alkem/react-ui-turbo-select';
import { isArray } from '@alkem/sdk-dashboard/src/utils/validators';

import { CONDITION_CAST_LIST, XPATH_TYPES } from '../../constants';
import {
  ConditionCastOption,
  ExportMapping,
  ExportMappingPayloadCast,
  FieldOption,
  Option,
  RegularOptionWithNumberId,
  RegularOptionWithStringId,
  Xpath,
  XpathList,
} from '../../types';

export const reorderArray = <A extends Array<any>>(
  event: { newIndex: number; oldIndex: number },
  originalArray: A,
) => {
  const newArray = [...originalArray];
  newArray.splice(event.newIndex, 0, newArray.splice(event.oldIndex, 1)[0]);
  return newArray;
};

export const isOption = (value: TurboSelectOption<Option>): value is Option => {
  if (value && !isArray(value)) {
    return 'id' in value;
  }
  return false;
};

export const isXpath = (value: TurboSelectOption<Xpath>): value is Xpath => {
  if (value && !isArray(value)) {
    return (
      'id' in value &&
      'name' in value &&
      'type' in value &&
      'order' in value &&
      'children' in value &&
      'xpath' in value
    );
  }
  return false;
};

export const isConditionCastOption = (
  value: TurboSelectOption<ConditionCastOption>,
): value is ConditionCastOption => {
  if (value && !isArray(value)) {
    return 'id' in value && CONDITION_CAST_LIST.includes(value.id);
  }
  return false;
};

export const isFieldOption = (
  value: TurboSelectOption<FieldOption>,
): value is FieldOption => {
  if (value && !isArray(value)) {
    return 'id' in value && 'label' in value && 'data' in value;
  }
  return false;
};

export const isRegularOption = (
  value: TurboSelectOption<
    RegularOptionWithStringId | RegularOptionWithNumberId
  >,
): value is RegularOptionWithStringId | RegularOptionWithNumberId => {
  if (value && !isArray(value)) {
    return 'id' in value && 'label' in value;
  }
  return false;
};

export const isExportMapping = (
  item: ExportMapping | {},
): item is ExportMapping => {
  return 'id' in item;
};

export const isExportMappingPayloadCast = (
  cast: string,
): cast is ExportMappingPayloadCast => {
  if (cast) {
    return [
      'boolean',
      'int',
      'float',
      'int positive',
      'float positive',
      'string',
      'text',
      'gdsn_node',
      'list',
      'date',
      'iso_date',
      'entity',
    ].includes(cast);
  }
  return false;
};

export const getModuleId = (xpathList: XpathList, xpath: string) => {
  for (let i = 0; i < xpathList.length; i++) {
    const moduleXpath = xpathList[i];
    if (xpath.includes(moduleXpath.xpath)) {
      if (moduleXpath.type === XPATH_TYPES.avp) {
        return undefined;
      }
      return moduleXpath.id;
    }
  }
  return undefined;
};
