import classNames from 'classnames';
import { List, Set, fromJS } from 'immutable';
import React, { useCallback, useMemo } from 'react';

import { Checkbox } from '@alkem/react-ui-inputs';

import typePackagings from 'constants/typePackagings';
import { isImmutable, size, toJsIfImmutable } from 'utils/immutable';

import './applicable-packaging-types.scss';

type Props = {
  id: string;
  ruleEntityType: number;
  applicablePackagingTypes: List<any> | { id: number }[];
  forbiddenTypePackagings: Set<number>;
  onChange: any;
  compact?: boolean;
  disabled?: boolean;
};

export const ApplicablePackagingTypes = React.memo(
  ({
    id,
    ruleEntityType,
    applicablePackagingTypes: _applicablePackagingTypes,
    forbiddenTypePackagings = Set([]),
    compact = false,
    disabled = false,
    onChange,
  }: Props) => {
    // TODO please definitely get rid of immutable, or only use immutable, but make a choice...
    const applicablePackagingTypes: { id: number }[] = useMemo(
      () => toJsIfImmutable(_applicablePackagingTypes),
      [_applicablePackagingTypes],
    );

    const update = useCallback(
      (id) => {
        const updatedApplicablePackagingTypes = applicablePackagingTypes.some(
          (pt) => pt.id === id,
        )
          ? applicablePackagingTypes.filter((pt) => pt.id !== id)
          : [...applicablePackagingTypes, { id }];
        return onChange(
          isImmutable(_applicablePackagingTypes)
            ? fromJS(updatedApplicablePackagingTypes)
            : updatedApplicablePackagingTypes,
        );
      },
      [_applicablePackagingTypes, applicablePackagingTypes, onChange],
    );

    const _onChange = useCallback((pt) => () => update(pt.id), [update]);

    if (!typePackagings[ruleEntityType]) {
      return null;
    }

    const hasNoPackagingTypeSelected = !(
      applicablePackagingTypes || size(applicablePackagingTypes)
    );

    return (
      <div
        className={classNames({
          ApplicablePackagingTypes: true,
          ApplicablePackagingTypesCompact: compact,
        })}
      >
        {hasNoPackagingTypeSelected
          ? 'No packaging type selected, this rule will apply to all'
          : Object.values(typePackagings[ruleEntityType]).map((pt: any) => {
              const isDisabled = forbiddenTypePackagings.has(pt.id) || disabled;
              return (
                <Checkbox
                  key={pt.id}
                  id={`${id}-${pt.name}`}
                  checked={
                    !!applicablePackagingTypes.find((t) => t.id === pt.id)
                  }
                  onChange={_onChange(pt)}
                  disabled={isDisabled}
                  label={pt.name}
                />
              );
            })}
      </div>
    );
  },
);

export default ApplicablePackagingTypes;
