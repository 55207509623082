import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Ellitips } from '@alkem/react-ui-ellitips';
import { Text } from '@alkem/react-ui-inputs';
import { ListColumn as Column, ListRow as Row } from '@alkem/react-ui-list';

import { separateActions } from 'utils/redux';

import { updateGLNClaimStatus, updateGLNNameLegal } from '../../actions';
import {
  STATUS_ACCEPTED,
  STATUS_CREATED,
  STATUS_MAPPING,
  STATUS_REFUSED,
} from '../../constants';
import { selectActionInProgress } from '../../selectors';

import './row.scss';

const mapStateToProps = (state) => ({
  actionInProgress: selectActionInProgress(state),
});

const mapDispatchToProps = {
  updateGLNClaimStatus,
  updateGLNNameLegal,
};

export class GLNClaimRow extends Component {
  static propTypes = {
    glnClaim: ImmutablePropTypes.map,
    glnData: ImmutablePropTypes.map,
    actionInProgress: PropTypes.bool,
    actions: PropTypes.shape({
      updateGLNClaimStatus: PropTypes.func.isRequired,
      updateGLNNameLegal: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayGLNEditor: false,
      glnName: props.glnData ? props.glnData.get('nameLegal') : '',
      originalGLNName: props.glnData ? props.glnData.get('nameLegal') : '',
    };
  }

  openGLNEditor = () => {
    this.setState({ displayGLNEditor: true });
  };

  onUpdateGLNNameLegal = (e) => {
    this.setState({ glnName: e.target.value });
  };

  cancelGLNEditor = () => {
    this.setState({
      displayGLNEditor: false,
      glnName: this.state.originalGLNName,
    });
  };

  onClickSaveGLNNameLegal = () => {
    const { glnData } = this.props;
    const { glnName } = this.state;
    this.props.actions.updateGLNNameLegal(glnData.get('id'), glnName);
    this.setState({ displayGLNEditor: false, originalGLNName: glnName });
  };

  onAcceptClaim = () => {
    this.props.actions.updateGLNClaimStatus(
      this.props.glnClaim.get('id'),
      STATUS_ACCEPTED.id,
    );
  };

  onRefuseClaim = () => {
    this.props.actions.updateGLNClaimStatus(
      this.props.glnClaim.get('id'),
      STATUS_REFUSED.id,
    );
  };

  formatDate(date) {
    if (!date) {
      return '';
    }
    return moment.utc(date).local().format('L LT');
  }

  renderData() {
    const { glnData, glnClaim, actionInProgress } = this.props;
    const { displayGLNEditor, glnName, originalGLNName } = this.state;
    if (!glnData) {
      return '';
    }
    const infos = [
      <div className="GLNClaimRow__dataBasicInfos" key="gln-basic-infos">
        {displayGLNEditor === false && actionInProgress === false && (
          <div className="GLNClaimRowName_Action">
            <i
              className="mdi mdi-marker GLNCLaimRow__Edit"
              onClick={this.openGLNEditor}
            />
          </div>
        )}
        {displayGLNEditor === true && (
          <div className="GLNClaimRowName_Action">
            <i
              className="mdi mdi-marker-cancel GLNCLaimRow__Edit"
              onClick={this.cancelGLNEditor}
            />
          </div>
        )}
        {glnData.get('country') && (
          <div className="GLNClaimRow__dataCountry">
            {glnData.get('country')}
          </div>
        )}
        {displayGLNEditor === false && (
          <Ellitips
            id={`data-namelegal-${glnClaim.get('id')}`}
            label={glnName}
          />
        )}
        {displayGLNEditor === true && (
          <Text
            id={`data-namelegal-updater-${glnClaim.get('id')}`}
            value={glnName}
            placeholder="name"
            onChange={this.onUpdateGLNNameLegal}
          />
        )}
        {displayGLNEditor === true && glnName !== originalGLNName && (
          <div className="GLNClaimRowName_Action">
            <i
              className="mdi mdi-content-save GLNCLaimRow__Edit"
              onClick={this.onClickSaveGLNNameLegal}
            />
          </div>
        )}
      </div>,
    ];
    if (glnData.get('contact')) {
      infos.push(
        <div className="GLNClaimRow__dataContact" key="gln-contact">
          <Ellitips
            id={`data-contact-${glnClaim.get('id')}`}
            label={`(${glnData.get('contact')})`}
          />
        </div>,
      );
    }
    return infos;
  }

  renderHeader(headerName) {
    return <span className="GLNClaimRow__header">{headerName}</span>;
  }

  renderStatus() {
    const { glnClaim } = this.props;
    const status = STATUS_MAPPING[glnClaim.get('status')];
    return <span>{status ? status.label : '?'}</span>;
  }

  renderActions() {
    const { glnClaim, actionInProgress } = this.props;
    const buttons = [
      <Button
        primary
        key="accept"
        className="GLNClaimRow__buttonAccept"
        disabled={actionInProgress}
        content="Accept"
        onClick={this.onAcceptClaim}
        testid="gln-btn-accept"
      />,
      <Button
        secondary
        key="refuse"
        className="GLNClaimRow__buttonRefuse"
        disabled={actionInProgress}
        content="Refuse"
        onClick={this.onRefuseClaim}
        testid="gln-btn-refuse"
      />,
    ];
    return (
      <Column className="GLNClaimRow__actions">
        {glnClaim.get('status') === STATUS_CREATED.id && buttons}
      </Column>
    );
  }

  render() {
    const { glnClaim } = this.props;

    const rowClasses = {
      GLNClaimRow: true,
    };

    return (
      <Row className={classNames(rowClasses)}>
        <Column className="GLNClaimRow__organization">
          <Ellitips
            id={`organizationname-${glnClaim.get('id')}`}
            label={glnClaim.get('organization_name')}
          />
        </Column>
        <Column className="GLNClaimRow__gln">
          <Ellitips
            id={`glnname-${glnClaim.get('id')}`}
            label={glnClaim.get('value')}
          />
        </Column>
        <Column className="GLNClaimRow__data">{this.renderData()}</Column>
        <Column className="GLNClaimRow__username">
          <Ellitips
            id={`username-${glnClaim.get('id')}`}
            label={glnClaim.getIn(['user', 'username'])}
          />
        </Column>
        <Column className="GLNClaimRow__status">{this.renderStatus()}</Column>
        <Column className="GLNClaimRow__createdAt">
          {this.formatDate(glnClaim.get('createdAt'))}
        </Column>
        <Column className="GLNClaimRow__updatedAt">
          {this.formatDate(glnClaim.get('updatedAt'))}
        </Column>
        {this.renderActions()}
      </Row>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(GLNClaimRow);
