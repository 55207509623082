import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { updateSearch } from 'modules/field-suggestion/actions';
import FieldSuggestionFilters from 'modules/field-suggestion/components/filters';
import FieldSuggestionList from 'modules/field-suggestion/components/list';
import { selectSearch } from 'modules/field-suggestion/selectors';

const mapStateToProps = (state) => ({
  search: selectSearch(state),
});

const mapDispatchToProps = {
  updateSearchQuery: updateSearch,
};

export class FieldSuggestionDashboardView extends Component {
  static propTypes = {
    search: PropTypes.string,
    updateSearchQuery: PropTypes.func.isRequired,
  };

  static defaultProps = {
    search: '',
  };

  render() {
    const { updateSearchQuery, search } = this.props;

    const headerProps = {
      title: 'Field Suggestion Dashboard',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };

    return (
      <div className="KindDashboardView__body">
        <HeaderLayout {...headerProps}>
          <Search
            query={search}
            updateSearchQuery={updateSearchQuery}
            placeholder="Search for product name, brand..."
          />
        </HeaderLayout>
        <div className="container-fluid row">
          <div className="col-md-3">
            <FieldSuggestionFilters />
          </div>
          <div className="col-md-9">
            <FieldSuggestionList />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FieldSuggestionDashboardView);
