import { useState } from 'react';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { Textarea } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { getStringWithoutLineBreaks } from 'modules/io/gdsn/imports/utils';

import { IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_PREDICATE } from '../../../../constants';
import { ImportMappingDetails, Property } from '../../../../types';

interface Props {
  details: ImportMappingDetails;
  dispatchImportMapping: (properties: Property[]) => void;
  fieldName: string;
  type: string;
}
export function ImportMappingsItemOptionalPropertyPredicate({
  details,
  dispatchImportMapping,
  fieldName,
  type,
}: Props): JSX.Element {
  const [predicate, setPredicate] = useState(details.predicate);

  const updatePredicate = (event: { target: { value: string } }) => {
    const formattedString = getStringWithoutLineBreaks(event.target.value);
    setPredicate(formattedString);
    dispatchImportMapping([{ name: 'predicate', value: formattedString }]);
  };

  return (
    <>
      {IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_PREDICATE.includes(type) && (
        <InputWithLabel
          inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-predicate`}
          label={
            <span>
              Predicate{' '}
              <HelpTooltip
                id={`gdsn-import-mappings-field-${fieldName}-optional-property-predicate-help`}
                place="top"
                message="Lambda function that will be used to make a filter on the values"
              />
            </span>
          }
        >
          <Textarea
            id={`gdsn-import-mappings-field-${fieldName}-optional-property-predicate`}
            value={predicate}
            autoresize
            onChange={updatePredicate}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          />
        </InputWithLabel>
      )}
    </>
  );
}
