import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from './events';

const initialState = fromJS({
  organizations: {},
});

export default createReducer(initialState, {
  [events.RECEIVE_ENTITIES]: (state, { key, data }) =>
    state.set(key, fromJS(data)),
});
