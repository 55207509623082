import { immutableKpiApi } from 'resources/kpiApi';

import * as events from '../constants/events';

export const getDashboardList = () => async (dispatch) => {
  const response = await immutableKpiApi.GetDashboardList();
  dispatch({
    type: events.DASHBOARD_LIST_RECEIVED,
    dashboardList: response.data.data,
  });
};
