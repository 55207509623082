import type Immutable from 'immutable';

import * as events from './events';

export const search = () => ({ type: events.TRANSACTION_SEARCH });

// Pagination
export const nextPage = () => ({ type: events.TRANSACTION_NEXT_PAGE });
export const previousPage = () => ({ type: events.TRANSACTION_PREVIOUS_PAGE });
export const changeLimit = (limit) => ({
  type: events.TRANSACTION_CHANGE_LIMIT,
  limit,
});

// Filters
export const searchQ = (q) => ({ type: events.TRANSACTION_SEARCH_ID, q });

export const addFilter = (filterType, filterId, filterLabel) => ({
  type: events.TRANSACTION_ADD_FILTER,
  filterType,
  filterId,
  filterLabel,
});

export const removeFilter = (filterType, filterId) => ({
  type: events.TRANSACTION_REMOVE_FILTER,
  filterType,
  filterId,
});

export const clearFilters = () => ({
  type: events.TRANSACTION_CLEAR_FILTERS,
});

export const selectPreset = (preset) => ({
  type: events.TRANSACTION_SELECT_PRESET,
  preset,
});

export const loadFilters = () => ({
  type: events.TRANSACTION_LOAD_FILTERS,
});

// Payload modal
export const closePayloadModal = () => ({
  type: events.TRANSACTION_PAYLOAD_MODAL_CLOSE,
});

export const onShowPayload = (payload) => ({
  type: events.TRANSACTION_PAYLOAD_MODAL_SHOW,
  payload,
});

// Diff modal
export const closeDiffModal = () => ({
  type: events.TRANSACTION_DIFF_MODAL_CLOSE,
});

export const onShowDiff = (payload, dataType) => ({
  type: events.TRANSACTION_DIFF_MODAL_SHOW,
  payload,
  dataType,
});

// Actions
export const closeActionModal = () => ({
  type: events.TRANSACTION_ACTION_MODAL_CLOSE,
});

export const onShowActions = (transaction: Immutable.Map<string, any>) => ({
  type: events.TRANSACTION_ACTION_MODAL_SHOW,
  transaction,
});

export const applyTransaction = (
  action: string,
  transaction: Immutable.Map<string, any>,
) => ({
  type: events.TRANSACTION_ACTION_MODAL_APPLY,
  transaction,
  action,
});

export const restoreHierarchy = (transaction: Immutable.Map<string, any>) => ({
  type: events.TRANSACTION_RESTORE_HIERARCHY,
  transaction,
});

export const reindex = (transactionId: number) => ({
  type: events.TRANSACTION_REINDEX,
  transactionId,
});
