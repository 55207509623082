import CancelablePromise from 'cancelable-promise';

import { immutableAuthApi } from 'resources/authApi';

import { NETWORKS_RECEIVE } from '../constants/networks';
import { selectNetworkList } from '../selectors/networks';

export const receiveNetworks = (list) => ({
  type: NETWORKS_RECEIVE,
  list,
});

export const getNetworks = () => (dispatch, getState) => {
  const networks = selectNetworkList(getState());
  if (networks.size > 0) {
    return new CancelablePromise((resolve) => resolve(networks));
  }

  return immutableAuthApi.NetworkList().then((response) => {
    const { data } = response.data;
    dispatch(receiveNetworks(data));
    return data;
  });
};
