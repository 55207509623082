import { fromJS } from 'immutable';

import { notificationError } from 'actions/notifications';
import { immutableAuthApi } from 'resources/authApi';
import { createAction } from 'utils/actions';

import * as events from '../constants/events';
import {
  selectEditedOrganization,
  selectOrganization,
  selectOrganizationField,
} from '../selectors/infos';

export const addTargetMarket = (value) => ({
  type: events.ORGANIZATION_INFOS_ADD_TARGET_MARKET,
  value,
});

export const removeTargetMarket = (value) => ({
  type: events.ORGANIZATION_INFOS_REMOVE_TARGET_MARKET,
  value,
});

export const updateInfosField = createAction(
  events.ORGANIZATION_INFOS_UPDATE_FIELD,
);

export const createGroup = (name) => async (dispatch) => {
  try {
    return await immutableAuthApi.upsertOrganizationGroup({ name });
  } catch (exception) {
    dispatch(notificationError('Could not create group'));
  }
  return null;
};

export const save = () => (dispatch, getState) => {
  const state = getState();

  const organizationId = selectOrganizationField(state, 'id');
  const editedOrganization = selectEditedOrganization(state)
    .set('id', organizationId)
    .toJS();

  return immutableAuthApi.OrganizationUpdate(editedOrganization);
};

export const importInfos =
  ({ worksOnTargetMarkets }) =>
  (dispatch, getState) => {
    const currentTargetMarkets = selectOrganization(getState()).get(
      'worksOnTargetMarkets',
    );
    const currentTargetMarketIds = currentTargetMarkets.map((tm) =>
      tm.get('id'),
    );
    const importingIds = worksOnTargetMarkets.map((tm) => tm.id);

    worksOnTargetMarkets
      .filter((tm) => !currentTargetMarketIds.includes(tm.id))
      .forEach((tm) => {
        dispatch(addTargetMarket(fromJS(tm)));
      });
    currentTargetMarkets
      .filter((tm) => !importingIds.includes(tm.get('id')))
      .forEach((tm) => {
        dispatch(removeTargetMarket(fromJS(tm)));
      });
  };
