import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';
import { TwinPanel } from '@alkem/react-ui-twinpanel';

import * as routes from 'constants/routes';

import {
  GET_EXPORT_MAPPING_LIST,
  GET_USER_DATA,
  GET_XPATH_LIST,
  SET_EDITION_ID,
  SET_REORDERING_ID,
  SET_SELECTED_EXPORT_MAPPING,
} from '../actions/constants';
import { ElementSelector } from '../components/element-selector';
import { ExportMappingsView } from '../components/export-mappings';
import { ExportMappingsNewElementCreation } from '../components/export-mappings/new-element-creation';
import { ExportMappingsNewModuleCreation } from '../components/export-mappings/new-module-creation/index';
import { selectExportMappings, selectXpathList } from '../selectors';
import { Xpath } from '../types';

import styles from './gdsn-export-mappings-view.module.scss';

const headerProps = {
  title: 'GDSN Export Mappings',
  backHref: routes.home,
  backMessage: 'Back home',
  isTitleSmall: true,
};

export default function GDSNExportMappingsView() {
  const dispatch = useDispatch();
  const xpathList = useSelector(selectXpathList);
  const exportMappings = useSelector(selectExportMappings);
  const [element, setElement] = useState<Xpath | undefined>(undefined);
  const [isXpathCreationInProgress, setIsXpathCreationInProgress] =
    useState(false);
  const [xpathElementOrder, setXpathElementOrder] = useState<
    number | undefined
  >(undefined);
  const [isChildXpathElementCreation, setIsChildXpathElementCreation] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch({ type: GET_XPATH_LIST });
    dispatch({ type: GET_USER_DATA });
  }, [dispatch]);

  const updateTitle = (type: string, isChild: boolean) => {
    return isChild || type !== 'MODULE'
      ? 'Creation of a new element'
      : 'Creation of a new module';
  };

  const updateElement = (xpath: Xpath) => {
    setElement(xpath);
    dispatch({ type: GET_EXPORT_MAPPING_LIST, xpath });
    dispatch({ type: SET_EDITION_ID });
    dispatch({ type: SET_REORDERING_ID });
    dispatch({ type: SET_SELECTED_EXPORT_MAPPING });
  };

  const closeXpathCreationView = () => {
    setIsXpathCreationInProgress(false);
    setXpathElementOrder(undefined);
    setIsChildXpathElementCreation(false);
  };

  const renderXpathCreationView = () => {
    if (
      !isXpathCreationInProgress ||
      typeof xpathElementOrder === 'undefined' ||
      !element
    ) {
      return null;
    }
    if (element.type === 'MODULE' && !isChildXpathElementCreation) {
      return (
        <ExportMappingsNewModuleCreation
          closeXpathCreationView={closeXpathCreationView}
          moduleOrder={xpathElementOrder}
        />
      );
    }
    return (
      <ExportMappingsNewElementCreation
        element={element}
        closeXpathCreationView={closeXpathCreationView}
        elementOrder={xpathElementOrder}
        isChild={isChildXpathElementCreation}
      />
    );
  };

  let rightPanelTitle = !isXpathCreationInProgress
    ? element?.name || 'No element selected'
    : updateTitle(element?.type || '', isChildXpathElementCreation);
  if (rightPanelTitle === 'NameModuleAVP') {
    rightPanelTitle = 'AVPs';
  }

  return (
    <div className={styles.body}>
      <HeaderLayout {...headerProps} />
      <div className={styles.content}>
        <TwinPanel
          leftPanel={{
            title: 'Select an element',
            content: (
              <div className={styles.elementSelectorContainer}>
                <ElementSelector
                  elements={xpathList}
                  selectElement={updateElement}
                  selectedElement={element}
                  showElementCreationView={() =>
                    setIsXpathCreationInProgress(true)
                  }
                  disabled={isXpathCreationInProgress}
                  setXpathElementOrder={setXpathElementOrder}
                  isXpathCreationInProgress={isXpathCreationInProgress}
                  setIsChildXpathElementCreation={
                    setIsChildXpathElementCreation
                  }
                  xpathElementOrder={xpathElementOrder}
                />
              </div>
            ),
            className: styles.panelLeft,
          }}
          rightPanel={{
            title: rightPanelTitle,
            content: (
              <>
                {!isXpathCreationInProgress && (
                  <div>
                    {element && (
                      <ExportMappingsView
                        key={`export_mappings_list_${element.id}`}
                        element={element}
                        exportMappings={exportMappings}
                        isFirst
                      />
                    )}
                  </div>
                )}
                {renderXpathCreationView()}
              </>
            ),
            className: styles.panelRight,
          }}
        />
      </div>
    </div>
  );
}
