import Fuse from 'fuse.js';
import proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import referentialApi from 'resources/referentialApi';

import './show-referential.scss';

export function ShowReferential({ slug }) {
  const [referentialEntities, setReferentialEntities] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [referentialIndex, setReferentialIndex] = useState(0);
  const [fuse, setFuse] = useState();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const init = async () => {
      if (!slug) {
        return;
      }

      setIsLoading(true);
      const callReferentialApi = async () => {
        try {
          const response = await referentialApi.ReferentialGetList(slug);
          return response.data.data;
        } catch (exception) {
          return;
        }
      };

      const referentialEntitiesResult = await callReferentialApi();
      setReferentialEntities(referentialEntitiesResult);
      setFuse(
        () =>
          new Fuse(referentialEntitiesResult, {
            keys: ['id', 'code', 'description', 'label'],
          }),
        [],
      );

      setIsLoading(false);
    };
    init();
  }, [slug]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);

    if (!fuse || !event.target.value) return;

    const searchResults = fuse.search(event.target.value);
    if (searchResults && searchResults.length > 0) {
      setReferentialIndex(searchResults[0].refIndex);
    }
  };

  const handleGoLeft = () => {
    if (referentialIndex === 0) return;

    setReferentialIndex(referentialIndex - 1);
    setSearchText('');
  };
  const handleGoRight = () => {
    if (referentialIndex === referentialEntities.length - 1) return;

    setReferentialIndex(referentialIndex + 1);
    setSearchText('');
  };

  if (isLoading) {
    return (
      <div>{`Fetching information for referential with slug: ${slug}`}</div>
    );
  }

  return (
    <div>
      {referentialEntities ? (
        <div className="ShowReferential__container">
          <div className="ShowReferential__previewContainer">
            <div className="ShowReferential__previewNavigator">
              <button
                className="ShowReferential__previewNavigatorButton"
                onClick={handleGoLeft}
              >
                {'<'}
              </button>
              <button
                className="ShowReferential__previewNavigatorButton"
                onClick={handleGoRight}
              >
                {'>'}
              </button>
              <input
                className="ShowReferential__previewNavigatorSearch"
                placeholder={'    search by id, code, description, or label'}
                type="text"
                value={searchText}
                onChange={handleSearch}
              />
              <div className="ShowReferential__previewNavigatorPageIndicator">
                {`${referentialIndex + 1} / ${referentialEntities.length}`}
              </div>
            </div>
            <div className="ShowReferential__previewEntity">
              <div className="ShowReferential__previewEntityRow">
                <span className="ShowReferential__fieldLabel">ID:</span>
                <span className="ShowReferential__fieldResult">
                  {referentialEntities[referentialIndex].id}
                </span>
              </div>
              <div className="ShowReferential__previewEntityRow">
                <span className="ShowReferential__fieldLabel">Code:</span>
                <span className="ShowReferential__fieldResult">
                  {referentialEntities[referentialIndex].code}
                </span>
              </div>
              <div className="ShowReferential__previewEntityRow">
                <span className="ShowReferential__fieldLabel">Rank:</span>
                <span className="ShowReferential__fieldResult">
                  {referentialEntities[referentialIndex].rank}
                </span>
              </div>
              <div className="ShowReferential__previewEntityRow">
                <span className="ShowReferential__fieldLabel">Label:</span>
                <span className="ShowReferential__fieldResult">
                  {referentialEntities[referentialIndex].label}
                </span>
              </div>
              <div className="ShowReferential__previewEntityRow">
                <span className="ShowReferential__fieldLabel">
                  Description:
                </span>
                <span className="ShowReferential__fieldResult">
                  {referentialEntities[referentialIndex].description}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        `no referential found for slug: ${slug}`
      )}
    </div>
  );
}

ShowReferential.propTypes = {
  slug: proptypes.string,
};
