import { combineReducers } from 'redux';

import { FORM_REDUCER_KEY, MODAL_REDUCER_KEY } from '../constants';

import formReducer from './form';
import modalReducer from './modal';

export default combineReducers({
  [MODAL_REDUCER_KEY]: modalReducer,
  [FORM_REDUCER_KEY]: formReducer,
});
