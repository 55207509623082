import { FORM_REDUCER_KEY } from '../constants';

import { selectMainState } from '.';

export function selectFormState(state) {
  return selectMainState(state)[FORM_REDUCER_KEY];
}

export function selectOrgName(state) {
  return selectFormState(state).get('name');
}

export function selectOrgType(state) {
  return selectFormState(state).get('type');
}

export function selectOrgNetwork(state) {
  return selectFormState(state).get('network');
}

export function selectOrgGroup(state) {
  return selectFormState(state).get('group');
}

export function selectOrgCountries(state) {
  return selectFormState(state).get('worksOnTargetMarkets');
}

export function selectOrgSource(state) {
  return selectFormState(state).get('source');
}

export function selectOrgTagOnShare(state) {
  return selectFormState(state).get('tagOnShare');
}

export function selectOrgUntagOnChange(state) {
  return selectFormState(state).get('untagOnChange');
}

export function selectOrgListing(state) {
  return selectFormState(state).get('listing');
}

export function selectOrgRestrictedRecipients(state) {
  return selectFormState(state).get('restrictedRecipients');
}
