import * as events from '../constants/events';

// Data events
export const receiveExportList = (list, totalResults) => ({
  type: events.RECEIVE_EXPORT_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const getExportList = ({ replaceHistory = true }) => ({
  type: events.GET_EXPORT_LIST,
  payload: { replaceHistory },
});

export const exportListIsLoading = () => ({
  type: events.EXPORT_LIST_IS_LOADING,
});

// Pagination
export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_EXPORT_LIST,
  limit,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_EXPORT_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_EXPORT_LIST,
});

// Filters
export const addExportListFilters = (key, value) => ({
  type: events.ADD_EXPORT_LIST_FILTER,
  key,
  value,
});

export const addOneExportListFilters = (key, value) => ({
  type: events.ADD_ONE_EXPORT_LIST_FILTER,
  key,
  value,
});

export const removeExportListFilters = (key, value) => ({
  type: events.REMOVE_EXPORT_LIST_FILTER,
  key,
  value,
});

export const updateExportListFilters = (key, value) => ({
  type: events.UPDATE_EXPORT_LIST_FILTER,
  key,
  value,
});

export const clearExportListFilters = (key, value) => ({
  type: events.CLEAR_EXPORT_LIST_FILTER,
  key,
  value,
});

export const getExportListFilters = () => ({
  type: events.GET_EXPORT_LIST_FILTER,
});

export const receiveExportListFilters = (filters) => ({
  type: events.RECEIVE_EXPORT_LIST_FILTER,
  filters,
});

export const searchExportList = (search) => ({
  type: events.SEARCH_EXPORT_LIST,
  search,
});

export const downloadExportedFile = (
  efID,
  fileIndex,
  filename,
  stepName,
  stepIndex,
) => ({
  type: events.DOWNLOAD_EXPORTED_FILE,
  efID,
  fileIndex,
  filename,
  stepName,
  stepIndex,
});

export const openModal = (parameters) => ({
  type: events.EXPORTFILE_MODAL_OPEN,
  parameters,
});

export const closeModal = () => ({
  type: events.EXPORTFILE_MODAL_CLOSE,
});

export const generateExportFile = (params) => ({
  type: events.EXPORTFILE_GENERATE,
  params,
});

export const openPotentialSupplierModal = (parameters) => ({
  type: events.EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_OPEN,
  parameters,
});

export const closePotentialSupplierModal = () => ({
  type: events.EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_CLOSE,
});

export const generatePotentialSupplierExportFile = (params) => ({
  type: events.EXPORTFILE_POTENTIAL_SUPPLIER_GENERATE,
  params,
});

export const currentPotentialSupplierExportFileUpdate = (
  supplierExportFile,
  isPotentialSupplierExportFileInProgress,
) => ({
  type: events.CURRENT_POTENTIAL_SUPPLIER_EXPORTFILE_UPDATE,
  supplierExportFile,
  isPotentialSupplierExportFileInProgress,
});

export const pollExportFilePotentialSupplierGeneration = (exportFileID) => ({
  type: events.POLL_EXPORTFILE_POTENTIAL_SUPPLIER_GENERATION,
  exportFileID,
});

export const pollExportFileGeneration = (exportFileID) => ({
  type: events.POLL_EXPORTFILE_GENERATION,
  exportFileID,
});

export const currentExportFileUpdate = (
  exportFile,
  isExportFileInProgress,
) => ({
  type: events.CURRENT_EXPORTFILE_UPDATE,
  exportFile,
  isExportFileInProgress,
});

export const getExportFileReferenceList = (exportFile, payload = {}) => ({
  type: events.GET_EXPORT_FILE_REFERENCE_LIST,
  exportFile,
  payload,
});

export const receiveExportFileReferenceList = (references, exportFileId) => ({
  type: events.RECEIVE_EXPORT_FILE_REFERENCE_LIST,
  payload: {
    references: references,
    exportFileId,
  },
});

export const openGDSNExportModal = () => ({
  type: events.EXPORTGDSNFILE_MODAL_OPEN,
});

export const closeGDSNExportModal = () => ({
  type: events.EXPORTGDSNFILE_MODAL_CLOSE,
});

export const pushGDSNExport = (
  productKeyID,
  dct,
  includeData,
  includePrice,
) => ({
  type: events.EXPORTGDSNFILE_PUSH,
  payload: {
    productKeyID,
    dct,
    includeData,
    includePrice,
  },
});

export const cancelExport = (efID, status) => ({
  type: events.CANCEL_EXPORT,
  efID,
  status,
});

export const openViewJsonModal = (jsonData) => ({
  type: events.VIEW_JSON_MODAL_OPEN,
  jsonData,
});

export const closeViewJsonModal = () => ({
  type: events.VIEW_JSON_MODAL_CLOSE,
});
