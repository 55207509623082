import { get } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Authentication } from '@alkem/sdk-dashboard';

import authApi, { immutableAuthApi } from 'resources/authApi';
import { identifyDataDogSession } from 'utils/datadog';
import { hasFullPower, hasRestrictedPower } from 'utils/user';

import {
  FETCH_PROFILE,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGOUT,
  LOGOUT_REQUEST,
  RECEIVE_PROFILE,
} from './constants';

function* loginSaga(action) {
  const { email, password, rememberMe } = action.payload;
  let response;
  try {
    response = yield call([authApi, authApi.UserLogin], email, password, false);
    yield put({ type: LOGIN });
    const accessToken = get(response, 'data.access_token');
    const refreshToken = get(response, 'data.refresh_token');
    Authentication.storeTokensAfterLogin(accessToken, refreshToken, rememberMe);
  } catch (error) {
    yield put({
      type: LOGIN_ERROR,
      error:
        get(error, 'data.message') ||
        get(error, 'data.error_description') ||
        get(error, 'error.message') ||
        get(error, 'message') ||
        get(error, 'data.data.message') ||
        'An unexpected error has occurred while logging in',
    });
  }
}

function* fetchProfileSaga() {
  try {
    yield call(Authentication.initTokenStorageOnLoad);
    if (Authentication.getAccessToken()) {
      const [userResponse, permissionsResponse] = yield all([
        call(() => immutableAuthApi.UserMe()),
        call(() => authApi.get('/auth/v3/user/permissions/admin')),
      ]);
      const user = userResponse.data.data;
      const permissions = permissionsResponse.data.data;
      yield call(identifyDataDogSession, user);
      if (hasFullPower(user) || hasRestrictedPower(user)) {
        yield put({ type: RECEIVE_PROFILE, user, permissions });
      } else {
        yield put({ type: LOGIN_ERROR, error: 'Access denied' });
        yield put({ type: LOGOUT_REQUEST });
      }
    } else {
      yield put({ type: LOGOUT_REQUEST });
    }
  } catch (error) {
    yield put({
      type: LOGIN_ERROR,
      error:
        get(error, 'data.message') ||
        'An unexpected error has occurred while fetching your profile',
    });
    yield put({ type: LOGOUT_REQUEST });
  }
}

function* logoutSaga() {
  Authentication.clearTokensAfterLogout();
  yield put({ type: LOGOUT });
}

export default function* mainSaga() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
  yield takeLatest(FETCH_PROFILE, fetchProfileSaga);
  yield takeLatest(LOGOUT_REQUEST, logoutSaga);
}
