import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProductKeyAutocomplete } from 'components/autocomplete';

import './autocomplete.scss';

class ProductKeyAutocompleteFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    onSelect: PropTypes.func.isRequired,
    onUnselect: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.any,
        label: PropTypes.node,
      }),
    ),
    organizationId: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.onUnselect = this.onUnselect.bind(this);
  }

  onUnselect({ item }) {
    this.props.onUnselect({ key: parseInt(item.key, 10) });
  }

  render() {
    const { id, title, value, onSelect, organizationId } = this.props;

    const extraParams = {
      allow_not_consumer_units: true,
      filter_is_displayable_as_target: false,
    };
    if (organizationId) {
      extraParams.filter_owners_in = [organizationId];
    }

    return (
      <div id={id} className="AutocompleteFilter">
        <h3 className="ReactUiFilter__filterTitle">{title}</h3>
        <ProductKeyAutocomplete
          id={`list-autocomplete-${id}`}
          className="TransactionFilter__Autocomplete"
          onSelect={onSelect}
          onUnselect={this.onUnselect}
          value={value}
          searchOnClick
          extraParams={extraParams}
        />
      </div>
    );
  }
}
export default ProductKeyAutocompleteFilter;
