import { Map } from 'immutable';
import { flow, get } from 'lodash/fp';

import { BIG_MANUFACTURER } from 'constants/organization-settings';

import { BILLING_REDUCER_KEY, ORGANIZATION_PAGE_STORE_KEY } from '../constants';
import { OrganizationBillingState } from '../types';

import { selectSettings } from './settings';

const selectState: (state: any) => OrganizationBillingState = get([
  'module',
  ORGANIZATION_PAGE_STORE_KEY,
  BILLING_REDUCER_KEY,
]);

export const selectBillingChanges = flow(selectState, (state) => state.changes);

export const hasBillingChanges = flow(selectBillingChanges, (changes) =>
  Object.values(changes).some((change) => typeof change === 'boolean'),
);

export const selectIsBigManufacturer = flow(
  selectSettings,
  (settings: Map<string, any>) => settings.get(BIG_MANUFACTURER),
);
