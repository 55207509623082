import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';
import { selectFunctionForConditionsOptions } from 'modules/io/gdsn/exports/selectors';
import {
  Condition,
  ConditionWithStringData,
  Option,
  Options,
} from 'modules/io/gdsn/exports/types';

import { isOption } from '../../utils';
import styles from '../item-conditions.module.scss';

const getInitialFunctionForConditionOption = (
  functionForConditionsOptions: Options,
  data?: string,
) => {
  if (!data) return undefined;
  return functionForConditionsOptions.find(({ id }) => id === data);
};

interface Props {
  index: number;
  condition: ConditionWithStringData;
  updateCondition: (index: number, condition: Condition) => void;
  name: string;
}
export function ExportMappingsItemFunctionCondition({
  index,
  condition,
  updateCondition,
  name,
}: Props) {
  const functionOptions = useSelector(selectFunctionForConditionsOptions);
  const [functionOption, setFunctionOption] = useState<Option | undefined>(
    getInitialFunctionForConditionOption(functionOptions, condition.data),
  );

  const updateTargetGln = (option: TurboSelectOption<Option>) => {
    if (isOption(option)) {
      setFunctionOption(option);
      updateCondition(index, { ...condition, data: option.id });
    } else {
      setFunctionOption(undefined);
      updateCondition(index, { ...condition, data: undefined });
    }
  };

  return (
    <div data-testid={`gdsn-export-mappings-function-${name}`}>
      <InputWithLabel
        label="Function"
        inputId={`gdsn-export-mappings-function-${name}`}
      >
        <TurboSelect
          id={`gdsn-export-mappings-function-${name}`}
          options={functionOptions}
          getOptionLabel={(option) => option.id}
          getOptionValue={(option) => option.id}
          value={functionOption}
          isMulti={false}
          isSearchable={false}
          isClearable
          onChange={updateTargetGln}
          placeholder="Select..."
          className={classNames({
            [styles.errorInput]: !functionOption,
          })}
        />
      </InputWithLabel>
    </div>
  );
}
