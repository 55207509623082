import ReactRouterPrompt from 'react-router-prompt';

import { Modal } from '@alkem/react-ui-modal';

interface Props {
  when: Parameters<typeof ReactRouterPrompt>[0]['when'];
  children: React.ReactNode;
  title?: string | null;
}

const LeavePagePrompt = ({ when, children, title }: Props) => {
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive ? (
          <Modal
            danger
            modalStyle="dynamic"
            title={title || 'You are about to leave the page'}
            confirmButtonText="Leave the page"
            onConfirm={onConfirm}
            closeButtonText="Cancel"
            onClose={onCancel}
          >
            {children}
          </Modal>
        ) : null
      }
    </ReactRouterPrompt>
  );
};

export { LeavePagePrompt };
