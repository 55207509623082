import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { SET_SELECTED_EXPORT_MAPPING } from 'modules/io/gdsn/exports/actions/constants';
import { ExportMapping } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

const getInitialType = (exportMapping: ExportMapping) => {
  const { is_on_paris_timezone, is_on_other_timezone } = exportMapping.payload;
  if (is_on_paris_timezone) return 'is_on_paris_timezone';
  if (is_on_other_timezone) return 'is_on_other_timezone';
  return undefined;
};
interface Props {
  name: string;
  exportMapping: ExportMapping;
}
export function ExportMappingsItemDateElementProperties({
  name,
  exportMapping,
}: Props) {
  const dispatch = useDispatch();
  const [type, setType] = useState<string | undefined>(
    getInitialType(exportMapping),
  );

  const dispatchProperties = (updatedType?: string) => {
    dispatch({
      type: SET_SELECTED_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
          payload: {
            ...exportMapping.payload,
            is_on_paris_timezone:
              updatedType === 'is_on_paris_timezone' || undefined,
            is_on_other_timezone:
              updatedType === 'is_on_other_timezone' || undefined,
          },
        },
      },
    });
  };

  const updateType = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedType = value !== 'undefined' ? value : undefined;
    setType(updatedType);
    dispatchProperties(updatedType);
  };

  return (
    <div
      className={styles.property}
      data-testid={`gdsn-export-mappings-date-element-properties-${name}`}
    >
      <InputWithLabel
        inputId={`gdsn-export-mappings-date-element-type-property-${name}`}
        label="Default value"
      >
        <Radio
          id={`gdsn-export-mappings-date-element-type-property-${name}`}
          value={type}
          options={[
            { label: 'Regular', value: undefined },
            { label: 'Paris timezone', value: 'is_on_paris_timezone' },
            { label: 'Iso 8601', value: 'is_on_other_timezone' },
          ]}
          onChange={updateType}
        />
      </InputWithLabel>
    </div>
  );
}
