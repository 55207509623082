import { List, Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  nextPage,
  previousPage,
  receiveReducer,
  search,
} from 'utils/reducers/list';

import * as events from '../constants';

const {
  USERS_RECEIVE,
  USERS_NEXT_PAGE,
  USERS_PREVIOUS_PAGE,
  USERS_SEARCH,
  USERS_CHANGE_LIMIT,
  USERS_FILTERS_ADD_SINGLE,
  USERS_FILTERS_REMOVE_SINGLE,
  USERS_FILTERS_CLEAR,
  USERS_ARE_LOADING,
} = events;

const initialState = {
  list: List(),
  entities: Map(),
  search: null,
  filters: fromJS({
    selected: [],
    admin: {},
    organizations: {},
  }),
  pagination: {
    totalResults: 0,
    currentPage: 1,
    totalPages: 1,
    limit: 50,
  },
  isLoading: false,
};

export default createReducer(initialState, {
  [USERS_RECEIVE]: (state, action) => {
    const newState = receiveReducer(state, action);
    newState.pagination.totalResults = action.totalResults;
    newState.list = List().withMutations((orgList) => {
      newState.entities = Map().withMutations((orgMap) => {
        action.list.forEach((org) => {
          const orgId = org.get('id');
          orgMap.set(orgId, org);
          orgList.push(org);
        });
        return orgMap;
      });
      return orgList;
    });
    return newState;
  },
  [USERS_NEXT_PAGE]: nextPage,
  [USERS_PREVIOUS_PAGE]: previousPage,
  [USERS_SEARCH]: search,
  [USERS_CHANGE_LIMIT]: (state, action) => {
    const newState = { ...state };
    newState.pagination.limit = action.limit;
    return newState;
  },
  [USERS_FILTERS_ADD_SINGLE]: (state, action) => {
    const newState = { ...state };
    const { filterType, filterId, filterValue, filterLabel } = action;

    newState.filters = newState.filters
      .setIn([filterType, filterId], filterValue)
      .set(
        'selected',
        newState.filters
          .get('selected')
          .push(fromJS({ filterType, filterId, filterLabel })),
      );
    return newState;
  },
  [USERS_FILTERS_REMOVE_SINGLE]: (state, action) => {
    const newState = { ...state };
    const { filterType, filterId } = action;
    newState.filters = newState.filters.deleteIn([filterType, filterId]);

    newState.filters = newState.filters.set(
      'selected',
      newState.filters
        .get('selected')
        .filter(
          (e) =>
            !(
              e.get('filterType') === filterType &&
              e.get('filterId') === filterId
            ),
        ),
    );

    return newState;
  },
  [USERS_FILTERS_CLEAR]: (state) => {
    const newState = { ...state };
    newState.filters = initialState.filters;
    return newState;
  },
  [USERS_ARE_LOADING]: (state, { payload }) => {
    const newState = { ...state };
    newState.isLoading = !!payload;
    return newState;
  },
});
