import PropTypes from 'prop-types';
import { ModuleWrapper } from 'redux/register';

import OrganizationPageDashboardView from './components';
import { ORGANIZATION_PAGE_STORE_KEY } from './constants';
import reducer from './reducers';

export default function OrganizationListContainer() {
  return (
    <ModuleWrapper reducerKey={ORGANIZATION_PAGE_STORE_KEY} reducer={reducer}>
      <OrganizationPageDashboardView />
    </ModuleWrapper>
  );
}

OrganizationListContainer.propTypes = {
  match: PropTypes.object,
};
