import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';
import { selectTargetGlnOptions } from 'modules/io/gdsn/exports/selectors';
import {
  Condition,
  ConditionWithStringData,
  Option,
  Options,
} from 'modules/io/gdsn/exports/types';

import { isOption } from '../../utils';
import styles from '../item-conditions.module.scss';

const getInitialTargetGln = (targetGlnOptions: Options, data?: string) => {
  if (!data) return undefined;
  return targetGlnOptions.find(({ id }) => id === data);
};

interface Props {
  index: number;
  condition: ConditionWithStringData;
  updateCondition: (index: number, condition: Condition) => void;
  name: string;
}
export function ExportMappingsItemTargetGlnsNotInCondition({
  index,
  condition,
  updateCondition,
  name,
}: Props) {
  const targetGlnOptions = useSelector(selectTargetGlnOptions);
  const [targetGlnOption, setTargetGlnOption] = useState<Option | undefined>(
    getInitialTargetGln(targetGlnOptions, condition.data),
  );

  const updateTargetGln = (option: TurboSelectOption<Option>) => {
    if (isOption(option)) {
      setTargetGlnOption(option);
      updateCondition(index, { ...condition, data: option.id });
    } else {
      setTargetGlnOption(undefined);
      updateCondition(index, { ...condition, data: undefined });
    }
  };

  return (
    <InputWithLabel
      inputId={`gdsn-export-mappings-target-glns-not-in-${name}`}
      label={
        <span>
          Target gln not in{' '}
          <HelpTooltip
            id={`gdsn-export-mappings-target-glns-not-in-${name}-help`}
            place="top"
            message="If the target GLN is different from these GLNs, the condition will be fullfilled."
          />
        </span>
      }
    >
      <div data-testid={`gdsn-export-mappings-target-glns-not-in-${name}`}>
        <TurboSelect
          id={`gdsn-export-mappings-target-glns-not-in-${name}`}
          options={targetGlnOptions}
          getOptionLabel={(option) => option.id}
          getOptionValue={(option) => option.id}
          value={targetGlnOption}
          isMulti={false}
          isSearchable={false}
          isClearable
          onChange={updateTargetGln}
          placeholder="Select..."
          className={classNames({
            [styles.errorInput]: !targetGlnOption,
          })}
        />
      </div>
    </InputWithLabel>
  );
}
