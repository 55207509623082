import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from '../constants/events';

const initialState = fromJS({
  organizationId: null,
  users: [],
  totalUsers: null,
  pagination: {
    limit: 20,
    page: 1,
    totalPage: 1,
  },
  passwordErrors: null,
  usernameErrors: null,
  existingUsers: [],
  isLoading: false,
  isModalOpen: false,
});

export default createReducer(initialState, {
  [events.ORGANIZATION_RECEIVED]: (state, { organization }) =>
    state.set('organizationId', organization.get('id')),

  [events.RECEIVE_USERS]: (state, { users, totalUsers }) =>
    state.withMutations((newState) =>
      newState
        .set('users', users)
        .set('totalUsers', totalUsers)
        .setIn(
          ['pagination', 'totalPages'],
          Math.floor(totalUsers / state.getIn(['pagination', 'limit'])) + 1,
        ),
    ),

  [events.USERS_SET_PAGE]: (state, { page }) =>
    state.setIn(['pagination', 'page'], page),

  [events.PASSWORD_CHECK]: (state, { passwordErrors }) =>
    state.set('passwordErrors', passwordErrors),

  [events.USERNAME_CHECK]: (state, { usernameErrors }) =>
    state.set('usernameErrors', usernameErrors),

  [events.USER_CHECK]: (state, { users }) => state.set('existingUsers', users),

  [events.RESET_MODAL]: (state) => state.set('existingUsers', []),

  [events.SET_LOADING]: (state, { isLoading }) =>
    state.set('isLoading', isLoading),

  [events.SET_MODAL_OPEN]: (state, { isModalOpen }) =>
    state.set('isModalOpen', isModalOpen),
});
