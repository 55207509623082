import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { ListController } from 'components/ui/list/controller';

import * as actions from '../../actions';
import {
  selectFilters,
  selectIsLoading,
  selectRevisions,
} from '../../selectors';
import * as types from '../../types';

import styles from './revision.module.scss';

function shortUUID(uuid: string) {
  return uuid.slice(-12);
}

function Tag(props: {
  text: string;
  intent: string;
  icon?: string;
  onClickFunc?: any;
}) {
  const intentColorClass = {
    success: styles.colorSuccess,
    primary: styles.colorPrimary,
    base: styles.colorBase,
  }[props.intent];
  return (
    <span
      onClick={props.onClickFunc}
      className={classNames(
        styles.tag,
        intentColorClass,
        props.onClickFunc ? styles.tagClickable : null,
      )}
    >
      {props.text}
      {props.icon && (
        <i className={classNames('mdi', `mdi-${props.icon}`, styles.tagIcon)} />
      )}
    </span>
  );
}

export function RevisionRowDetail(props: {
  revision: types.ProductRevisionCompact;
}) {
  const dispatch = useDispatch();
  const filters: types.ProductRevisionFilter = useSelector(selectFilters);
  const revision = props.revision;
  const ElementType = [
    undefined,
    'UNIT',
    'ASSETS',
    'LISTINGS',
    'LOGISTICAL_HIERARCHIES',
    'VALIDATION_RULES_BYPASS',
    'SPECIFIC_DATA',
  ];

  return (
    <>
      <div className={styles.rowDetailHeader}>
        <div className={styles.rowColumn}>UUID</div>
        <div className={styles.rowColumn}>Element Type</div>
      </div>
      {Object.entries(revision.elements).map(([el_type, el_uuid]) => (
        <div key={`el-${el_uuid}`} className={styles.rowDetail}>
          <div className={styles.rowColumn}>
            <Tag text={shortUUID(el_uuid)} intent="base" />
          </div>
          <div className={styles.rowColumn}>{ElementType[el_type]}</div>
        </div>
      ))}
      {revision.logistics?.length > 0 && (
        <>
          <div className={styles.rowDetailHeader}>
            <div className={styles.rowColumn}>Link UUID</div>
            <div className={styles.rowColumn}>Main logistic</div>
            <div className={styles.rowColumn}>Logistic UUID</div>
          </div>
          {revision.logistics.map((el) => (
            <div key={`lu-${el.uuid}`} className={styles.rowDetail}>
              <div className={styles.rowColumn}>
                <Tag
                  text={shortUUID(el.uuid)}
                  intent={el.main === true ? 'success' : 'primary'}
                />
              </div>
              <div className={styles.rowColumn}>
                {el.main === true ? (
                  <i className="mdi mdi-check" />
                ) : (
                  <i className="mdi mdi-close" />
                )}
              </div>
              <div className={styles.rowColumn}>
                <Tag
                  text={shortUUID(el.logistic_uuid)}
                  onClickFunc={() =>
                    dispatch(
                      actions.updateFilters({
                        ...filters,
                        offset: 0,
                        logistic_uuid: el.logistic_uuid,
                      }),
                    )
                  }
                  intent="base"
                  icon="filter"
                />
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export function RevisionRow(props: { revision: types.ProductRevisionCompact }) {
  const [detail, setDetail] = useState<boolean>(false);
  const revision = props.revision;
  return (
    <>
      <div className={styles.row}>
        <div className={styles.rowColumn}>
          <Tag text={shortUUID(revision.uuid)} intent="success" />
        </div>
        <div className={styles.rowColumn}>{revision.product_key_id}</div>
        <div className={styles.rowColumn}>{revision.organization_id}</div>
        <div className={styles.rowColumn}>
          {revision.head === true ? (
            <i className="mdi mdi-check" />
          ) : (
            <i className="mdi mdi-close" />
          )}
        </div>
        <div className={styles.rowColumn}>
          {revision.logistics.some((e) => e.main) ? (
            <i className="mdi mdi-check" />
          ) : (
            <i className="mdi mdi-close" />
          )}
        </div>
        <div className={styles.rowColumn}>{revision.created_at}</div>
        <div className={styles.rowColumn}>
          {Object.keys(revision.elements).length}
        </div>
        <div className={styles.rowColumn}>{revision.logistics.length}</div>
        <div className={styles.rowColumn}>
          <Button link onClick={() => setDetail(!detail)}>
            <i
              className={classNames(
                'mdi',
                detail ? 'mdi-chevron-down' : 'mdi-chevron-right',
              )}
            />
          </Button>
        </div>
      </div>
      {detail && <RevisionRowDetail revision={revision} />}
    </>
  );
}

export function RevisionList(props: {
  revisions: types.ProductRevisionCompact[];
}) {
  const { revisions } = props;
  if (revisions?.length === 0) {
    return (
      <>
        No revision match your criterion...
        <i className="mdi mdi-heart-broken" />
      </>
    );
  }
  return (
    <>
      <div className={styles.rowHeader}>
        <div className={styles.rowColumn}>UUID</div>
        <div className={styles.rowColumn}>Product Key</div>
        <div className={styles.rowColumn}>Organization</div>
        <div className={styles.rowColumn}>Head</div>
        <div className={styles.rowColumn}>Main</div>
        <div className={styles.rowColumn}>Created At</div>
        <div className={styles.rowColumn}>Elements</div>
        <div className={styles.rowColumn}>Logistics</div>
        <div className={styles.rowColumn}>Actions</div>
      </div>
      {revisions?.map((rev) => (
        <RevisionRow key={`revision-${rev.uuid}`} revision={rev} />
      )) || <div>No revisions found</div>}
    </>
  );
}

export function Revisions() {
  const dispatch = useDispatch();
  const revisions: types.ProductRevisionCompact[] =
    useSelector(selectRevisions);
  const isLoading: boolean = useSelector(selectIsLoading);
  const filters: types.ProductRevisionFilter = useSelector(selectFilters);

  const onClickRefresh = () => {
    dispatch(actions.fetchRevisionList());
  };

  useEffect(() => {
    dispatch(actions.fetchRevisionList());
  }, [dispatch]);

  const totalPages =
    (revisions?.length || 0) === filters.limit
      ? filters.offset / filters.limit + 2
      : filters.offset / filters.limit + 1;
  const totalResuls =
    revisions?.length === undefined
      ? 0
      : revisions.length === filters.limit
        ? filters.limit + 1
        : revisions?.length;
  return (
    <ListController
      actions={
        <Button
          secondary
          onClick={onClickRefresh}
          className="IndexerQueue__refresh"
          disabled={isLoading}
        >
          <i className="mdi mdi-refresh" />
        </Button>
      }
      rowsLength={revisions?.length || 0}
      currentPage={filters.offset / filters.limit + 1}
      totalPages={totalPages}
      totalResults={totalResuls}
      limit={filters.limit}
      onNext={() => {
        dispatch(
          actions.updateFilters({
            ...filters,
            offset: filters.offset + filters.limit,
          }),
        );
      }}
      onPrev={() => {
        dispatch(
          actions.updateFilters({
            ...filters,
            offset: filters.offset - filters.limit,
          }),
        );
      }}
      onLimitChange={(e) => {
        dispatch(actions.updateFilters({ ...filters, offset: 0, limit: e }));
      }}
      type="indexerQueueList"
    >
      <RevisionList revisions={revisions} />
    </ListController>
  );
}
