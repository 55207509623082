import { useState } from 'react';
import { useDispatch } from 'react-redux';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio, Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { SET_SELECTED_EXPORT_MAPPING } from 'modules/io/gdsn/exports/actions/constants';
import { ExportMapping } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

const getInitialType = (exportMapping: ExportMapping) => {
  const { set_attribute_from_field, set_attribute_from_value } =
    exportMapping.payload;
  if (set_attribute_from_field) return 'set_attribute_from_field';
  if (set_attribute_from_value) return 'set_attribute_from_value';
  return undefined;
};
interface Props {
  name: string;
  exportMapping: ExportMapping;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemSetAttributeProperty({
  name,
  exportMapping,
  dispatchProperty,
}: Props) {
  const dispatch = useDispatch();
  const type = getInitialType(exportMapping);
  const splittedProperty = type
    ? exportMapping.payload[type]?.split('=')
    : undefined;
  const attrName = splittedProperty?.[0];
  let attrValue = splittedProperty?.[1];
  let attrReferential: string | undefined = undefined;
  if (attrValue?.includes('@')) {
    const splittedAttrName = attrValue.split('@');
    attrValue = splittedAttrName[0];
    attrReferential = splittedAttrName[1];
  }
  const [xmlAttributeCreationType, setXmlCreationAttributeType] = useState<
    string | undefined
  >(type);
  const [attributeName, setAttributeName] = useState<string | undefined>(
    attrName,
  );
  const [attributeValue, setAttributeValue] = useState<string | undefined>(
    attrValue,
  );
  const [attributeReferential, setAttributeReferential] = useState<
    string | undefined
  >(attrReferential);

  const updateMustCreateNewModule = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedXmlAttributeCreationType =
      value === 'undefined' ? undefined : value;
    setXmlCreationAttributeType(updatedXmlAttributeCreationType);
    setAttributeName(undefined);
    setAttributeValue(undefined);
    setAttributeReferential(undefined);
    dispatch({
      type: SET_SELECTED_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
          payload: {
            ...exportMapping.payload,
            set_attribute_from_field: undefined,
            set_attribute_from_value: undefined,
          },
        },
      },
    });
  };

  const updateAttributeName = (event: { target: { value: string } }) => {
    const updatedAttributeName = event.target.value || undefined;
    setAttributeName(updatedAttributeName);
    if (xmlAttributeCreationType) {
      dispatchProperty(
        xmlAttributeCreationType,
        `${updatedAttributeName || ''}${
          attributeValue ? `=${attributeValue}` : ''
        }${attributeReferential ? `@${attributeReferential}` : ''}`,
      );
    }
  };

  const updateAttributeValue = (event: { target: { value: string } }) => {
    const updatedAttributeValue = event.target.value || undefined;
    setAttributeValue(updatedAttributeValue);
    if (xmlAttributeCreationType) {
      dispatchProperty(
        xmlAttributeCreationType,
        `${attributeName || ''}${
          updatedAttributeValue ? `=${updatedAttributeValue}` : ''
        }${attributeReferential ? `@${attributeReferential}` : ''}`,
      );
    }
  };

  const updateAttributeReferential = (event: { target: { value: string } }) => {
    const updatedAttributeReferential =
      event.target.value.toLowerCase() || undefined;
    setAttributeReferential(updatedAttributeReferential);
    if (xmlAttributeCreationType) {
      dispatchProperty(
        xmlAttributeCreationType,
        `${attributeName || ''}${attributeValue ? `=${attributeValue}` : ''}${
          updatedAttributeReferential ? `@${updatedAttributeReferential}` : ''
        }`,
      );
    }
  };

  return (
    <div
      className={styles.property}
      data-testid={`gdsn-export-mappings-set-attribute-property-${name}`}
    >
      <InputWithLabel
        inputId={`gdsn-export-mappings-xml-attribute-creation-type-${name}`}
        label="Create xml tag attribute"
      >
        <Radio
          id={`gdsn-export-mappings-xml-attribute-creation-type-${name}`}
          value={xmlAttributeCreationType}
          options={[
            { label: 'No', value: undefined },
            { label: 'From value', value: 'set_attribute_from_value' },
            { label: 'From field', value: 'set_attribute_from_field' },
          ]}
          onChange={updateMustCreateNewModule}
        />
      </InputWithLabel>
      {xmlAttributeCreationType && (
        <>
          <InputWithLabel
            inputId={`gdsn-export-mappings-xml-attribute-name-${name}`}
            label="Attribute name"
          >
            <Text
              id={`gdsn-export-mappings-xml-attribute-name-${name}`}
              value={attributeName || ''}
              onChange={updateAttributeName}
              invalid={!attributeName}
            />
          </InputWithLabel>
          <InputWithLabel
            inputId={`gdsn-export-mappings-xml-attribute-value-${name}`}
            label={
              xmlAttributeCreationType === 'set_attribute_from_value' ? (
                'Attribute value'
              ) : (
                <span>
                  Field name{' '}
                  <HelpTooltip
                    id={`gdsn-export-mappings-xml-attribute-value-${name}-help`}
                    place="top"
                    message={
                      <div className={styles.messageContainer}>
                        <span>
                          Write the name of a field located at the same level of
                          the hierarchy.
                        </span>
                        <span>
                          The field mustn't be declinable by and can be of type:
                          string / int / float / entity.
                        </span>
                        <span>
                          If the field is of type entity, a referential will be
                          required.
                        </span>
                      </div>
                    }
                  />
                </span>
              )
            }
          >
            <Text
              id={`gdsn-export-mappings-xml-attribute-value-${name}`}
              value={attributeValue || ''}
              onChange={updateAttributeValue}
              invalid={!attributeValue}
            />
          </InputWithLabel>
        </>
      )}
      {xmlAttributeCreationType === 'set_attribute_from_field' && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-xml-attribute-referential-${name}`}
          label={
            <span>
              Referential{' '}
              <HelpTooltip
                id={`gdsn-export-mappings-xml-attribute-referential-${name}-help`}
                place="top"
                message="If the field chosen is an entity this field is required."
              />
            </span>
          }
        >
          <Text
            id={`gdsn-export-mappings-xml-attribute-referential-${name}`}
            value={attributeReferential || ''}
            onChange={updateAttributeReferential}
          />
        </InputWithLabel>
      )}
    </div>
  );
}
