import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { ORGANIZATION_PAGE_STORE_KEY, USERS_REDUCER_KEY } from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_PAGE_STORE_KEY][
    USERS_REDUCER_KEY
  ];
}

export function selectUsers(state) {
  return selectState(state).get('users');
}

export function selectTotalUsers(state) {
  return selectState(state).get('totalUsers');
}

export function selectOrganizationId(state) {
  return selectState(state).get('organizationId');
}

export function selectPagination(state) {
  return selectState(state).get('pagination');
}

export function selectCurrentPage(state) {
  return selectPagination(state).get('page');
}

export function selectLimit(state) {
  return selectPagination(state).get('limit');
}

export function selectTotalPages(state) {
  return selectPagination(state).get('totalPages');
}

export const selectPasswordErrors = (state) =>
  selectState(state).get('passwordErrors');

export const selectUsernameErrors = (state) =>
  selectState(state).get('usernameErrors');

export const selectExistingUsers = (state) =>
  selectState(state).get('existingUsers');

export const selectIsLoading = (state) => selectState(state).get('isLoading');

export const selectIsModalOpen = (state) =>
  selectState(state).get('isModalOpen');
