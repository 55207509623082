import { Set } from 'immutable';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Filter } from '@alkem/react-ui-filter';

import { getKindList } from 'actions/kinds';

import * as utils from './utils';

const mapStateToProps = (state) => ({
  kinds: state.kinds.tree,
  index: state.kinds.indexed,
});

export class KindTree extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    kinds: PropTypes.shape({
      children: PropTypes.array,
    }),
    index: PropTypes.object,
    selection: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    multiple: PropTypes.bool,
    title: PropTypes.string,
    onSelectionChange: PropTypes.func,
    useFormatters: PropTypes.bool, // the formatters format selection as set of inclusions/exclusions
    revealSelectedPaths: PropTypes.bool,
    forbiddenKinds: ImmutablePropTypes.set,
  };

  static defaultProps = {
    onSelectionChange: noop,
    multiple: true,
    useFormatters: false,
    revealSelectedPaths: false,
    forbiddenKinds: Set(),
  };

  static cleanKindLabel(kindLabel) {
    const REGEX = /__/g;
    return kindLabel.replace(REGEX, '');
  }

  state = {
    tree: null,
    selected: {},
    ignored: [], // EXP-879 this is where we store kinds that are outside the main Kind tree
  };

  componentDidMount() {
    this.props.dispatch(getKindList());
  }

  componentDidUpdate(prevProps) {
    const { kinds, selection } = this.props;
    if (kinds !== prevProps.kinds || selection !== prevProps.selection) {
      this.initialize();
    }
  }

  onKindClick = (treeName, clickedKind) => {
    let selected;
    if (!this.props.multiple) {
      selected = this.selectOneKind(clickedKind);
    } else if (!clickedKind.checked) {
      selected = this.selectKindAndItsChildren(clickedKind);
    } else {
      selected = this.unselectKindAndItsChildren(clickedKind);
    }
    this.setState({ selected });

    if (!this.props.multiple) {
      this.props.onSelectionChange(selected);
    } else {
      let selection;
      if (this.props.useFormatters) {
        selection = utils.computeInclusionAndExclusionsFromSelection(
          selected,
          this.props.index,
          this.state.ignored,
        );
      } else {
        selection = selected;
      }
      this.props.onSelectionChange(selection);
    }
  };

  initialize() {
    const { kinds, selection, useFormatters, index } = this.props;
    let selected;
    let ignored = [];
    if (selection) {
      if (useFormatters) {
        [selected, ignored] = utils.computeSelectedFromInclusionsAndExclusions(
          selection,
          index,
        );
      } else {
        selected = selection;
      }
      this.setState({ selected, ignored });
    }
    if (kinds) {
      this.updateTree(kinds, selected);
    }
  }

  updateTree(kinds, selected) {
    const tree = this.formatKindChildren([kinds], selected, {
      revealSelectedPaths: this.props.revealSelectedPaths,
      forbiddenKinds: this.props.forbiddenKinds,
    });
    this.setState({ tree });
  }

  formatKindChildren(children, selected, options) {
    return children
      .map((child) => {
        const disabled =
          options.forbiddenKinds &&
          (options.forbiddenKinds.has(child.id) ||
            options.forbiddenKinds.has(`${child.id}`));

        const checked = selected && selected[child.id];

        let childTree = [];
        let visible = false;
        if (child.children.length) {
          childTree = this.formatKindChildren(
            child.children,
            selected,
            options,
          );

          // child should open when:
          // - some children are checked unless they are all checked
          // or
          // - some children should be visible
          // or
          // - some children are not disabled when child is
          // or
          // - child is disabled and not checked
          //   (most of the time will be filtered but if child is not filtered there is children to show below)
          visible =
            options.revealSelectedPaths &&
            ((childTree.some((_child) => _child.checked) &&
              !childTree.every((_child) => _child.checked)) ||
              childTree.some((_child) => _child.visible) ||
              (disabled && childTree.some((_child) => !_child.disabled)) ||
              (disabled && !checked));
        }

        return {
          id: child.id,
          label: KindTree.cleanKindLabel(child.name),
          value: child.id,
          checked,
          disabled,
          children: childTree,
          visible,
        };
      })
      .filter(
        (child) => !child.disabled || child.children.length || child.checked,
      );
  }

  selectOneKind(kind) {
    return { [kind.id]: true };
  }

  selectKindAndItsChildren(kind, reverse = false) {
    const { forbiddenKinds } = this.props;
    const ids = utils.getKindAndChildrenIds(kind);

    const selected = { ...this.state.selected };
    for (const id of ids) {
      if (!forbiddenKinds.has(`${id}`)) {
        selected[id] = !reverse;
      } else {
        selected[id] = false;
      }
    }
    return selected;
  }

  unselectKindAndItsChildren(kind) {
    return this.selectKindAndItsChildren(kind, true /* reverse */);
  }

  render() {
    const { title } = this.props;
    return (
      <div id="KindTree">
        {this.state.tree && (
          <Filter
            type="tree"
            filters={this.state.tree}
            onChange={this.onKindClick}
            name="KindTree"
            title={title}
            listLimit={-1}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(KindTree);
