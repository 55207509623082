import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Radio, Text } from '@alkem/react-ui-inputs';
import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';

import {
  GET_FIELD_LIST,
  SET_SELECTED_EXPORT_MAPPING,
} from '../../../actions/constants';
import {
  selectCustomParseOptions,
  selectListFieldOptions,
} from '../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  ExportMappingPayloadSimple,
  FieldOption,
  Option,
  RegularOptionWithStringId,
  RegularOptionsWithStringId,
} from '../../../types';
import {
  isExportMappingPayloadCast,
  isFieldOption,
  isOption,
  isRegularOption,
} from '../utils';

import styles from './list-element-item-type.module.scss';

const itemTypeOptions: RegularOptionsWithStringId = [
  { id: 'gdsn_node', label: 'GDSN node' },
  { id: 'list', label: 'SXM list field' },
  { id: 'custom_parse', label: 'Custom parse' },
];

const getInitialItemType = (payload: ExportMappingPayload) => {
  if (!payload) return undefined;

  const { cast, custom_parse } = payload;
  if (!cast && !!custom_parse) {
    return itemTypeOptions[2];
  }
  if (cast) {
    return itemTypeOptions.find(({ id }) => id === cast);
  }
  return undefined;
};

interface Props {
  name: string;
  exportMapping: ExportMapping;
  isEditionInProgress?: boolean;
  disabled?: boolean;
}
export function ExportMappingsListElementItemType({
  name,
  exportMapping,
  isEditionInProgress = false,
  disabled = true,
}: Props) {
  const dispatch = useDispatch();
  const listFieldOptions = useSelector(selectListFieldOptions);
  const customParseOptions = useSelector(selectCustomParseOptions);
  const [itemTypeOption, setItemTypeOption] = useState<
    RegularOptionWithStringId | undefined
  >(getInitialItemType(exportMapping.payload));
  const [category, setCategory] = useState(
    exportMapping.payload.use_su ? 'sharingUnit' : 'consumerUnit',
  );
  const [fieldSearchValue, setFieldSearchValue] = useState('');
  const [listFieldOption, setListFieldOption] = useState<
    FieldOption | undefined
  >(undefined);
  const [field, setField] = useState(exportMapping.payload.field || '');
  const [customParseOption, setCustomParseOption] = useState<
    Option | undefined
  >(
    customParseOptions.find(
      (customParseOption) =>
        customParseOption.id === exportMapping.payload.custom_parse,
    ),
  );

  const dispatchGetFieldList = useCallback(
    ({
      entityType,
      search,
    }: {
      entityType?: string;
      search?: string;
    }): void => {
      dispatch({
        type: GET_FIELD_LIST,
        entityType: entityType || category,
        search: search ?? fieldSearchValue,
        mustGetListFields: true,
        sxmListParentField: exportMapping.sxm_list_parent?.field,
      });
    },
    [dispatch, category, fieldSearchValue],
  );

  useEffect(() => {
    dispatchGetFieldList({});
  }, []);

  const dispatchSetExportMapping = useCallback(
    ({ cast, field, custom_parse, use_su }: ExportMappingPayloadSimple) => {
      dispatch({
        type: SET_SELECTED_EXPORT_MAPPING,
        payload: {
          exportMapping: {
            ...exportMapping,
            payload: {
              ...exportMapping.payload,
              cast,
              field,
              custom_parse,
              use_su,
              do_not_add_module: undefined,
              use_get_attr_rec_with_children: undefined,
            },
          },
        },
      });
    },
    [dispatch, exportMapping],
  );

  const clearFieldSelectorFields = () => {
    setFieldSearchValue('');
    setListFieldOption(undefined);
    setField('');
  };

  const updateItemType = (
    value: TurboSelectOption<RegularOptionWithStringId>,
  ) => {
    clearFieldSelectorFields();
    setCustomParseOption(undefined);
    if (isRegularOption(value)) {
      setItemTypeOption(value);
      let cast: string | undefined = value.id;
      if (cast === 'custom_parse' || !isExportMappingPayloadCast(cast)) {
        cast = undefined;
      }
      dispatchSetExportMapping({ cast });
    } else {
      setItemTypeOption(undefined);
      setCategory('consumerUnit');
      dispatchSetExportMapping({});
    }
  };

  const updateCategory = (event: { target: { value: string } }) => {
    const { cast } = exportMapping.payload;
    const { value } = event.target;
    setCategory(value);
    dispatchGetFieldList({ entityType: value, search: '' });
    clearFieldSelectorFields();
    dispatchSetExportMapping({
      cast,
      use_su: value === 'sharingUnit' || undefined,
    });
  };

  const updateSearchValue = useCallback(
    (event: { target: { value: string } }): void => {
      const { value } = event.target;
      setFieldSearchValue(value);
      dispatchGetFieldList({ search: value });
    },
    [setFieldSearchValue, category],
  );

  const updateField = (value: TurboSelectOption<FieldOption>) => {
    const { cast, use_su } = exportMapping.payload;
    if (isFieldOption(value)) {
      setListFieldOption(value);
      setField(value.label);
      dispatchSetExportMapping({
        cast,
        field: value.label,
        use_su,
      });
    } else {
      setListFieldOption(undefined);
      setField('');
      dispatchSetExportMapping({
        cast,
        use_su,
      });
    }
  };

  const updateCustomParse = (value: TurboSelectOption<Option>) => {
    const { cast } = exportMapping.payload;
    if (isOption(value)) {
      setCustomParseOption(value);
      dispatchSetExportMapping({ cast, custom_parse: value.id });
    } else {
      setCustomParseOption(undefined);
      dispatchSetExportMapping({ cast });
    }
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.disabledContainer]: disabled,
      })}
    >
      <div
        data-testid={`gdsn-export-mappings-list-element-${name}-type-property`}
      >
        <InputWithLabel
          inputId={`gdsn-export-mappings-list-element-${name}-type-property`}
          label="Type of mapping"
        >
          <TurboSelect
            id={`gdsn-export-mappings-item-${name}-data-function-property`}
            options={itemTypeOptions}
            getOptionLabel={(itemType) => itemType.label}
            getOptionValue={(itemType) => itemType.id}
            value={itemTypeOption}
            isMulti={false}
            isSearchable={false}
            isClearable
            onChange={updateItemType}
            placeholder="Select..."
            className={classNames({
              [styles.errorInput]: !itemTypeOption && !disabled,
            })}
          />
        </InputWithLabel>
      </div>
      {itemTypeOption?.id === 'list' && (
        <>
          <div
            className={classNames({
              disabledContainer: !disabled && !!exportMapping.sxm_list_parent,
            })}
            data-testid={`gdsn-export-mappings-list-element-${name}-category-property`}
          >
            <InputWithLabel
              inputId={`gdsn-export-mappings-list-element-${name}-category-property`}
              label="Category"
            >
              <Radio
                id={`gdsn-export-mappings-list-element-${name}-category-property`}
                value={category}
                options={[
                  { label: 'Consumer unit', value: 'consumerUnit' },
                  { label: 'Sharing unit', value: 'sharingUnit' },
                ]}
                onChange={updateCategory}
                disabled={!!exportMapping.sxm_list_parent?.field}
              />
            </InputWithLabel>
          </div>
          {isEditionInProgress && !disabled && (
            <InputWithLabel
              inputId={`gdsn-export-mappings-list-element-${name}-field-selector`}
              label="Select a field"
            >
              <div
                data-testid={`gdsn-export-mappings-list-element-${name}-field-selector`}
              >
                {!exportMapping.sxm_list_parent && (
                  <Text
                    className={styles.textField}
                    id={`gdsn-export-mappings-list-element-${name}-field-search`}
                    placeholder="Filter fields by name or tag"
                    onChange={updateSearchValue}
                    value={fieldSearchValue}
                  />
                )}
                <TurboSelect
                  id={`gdsn-export-mappings-list-element-${name}-field-selector`}
                  options={listFieldOptions}
                  getOptionLabel={(itemType) => itemType.label}
                  getOptionValue={(itemType) => itemType.id.toString()}
                  value={listFieldOption}
                  isMulti={false}
                  isClearable
                  onChange={updateField}
                  isDisabled={listFieldOptions.length === 0}
                  placeholder={
                    listFieldOptions.length === 0
                      ? 'No field found'
                      : 'Select...'
                  }
                  className={classNames({
                    [styles.errorInput]: !field,
                  })}
                />
              </div>
            </InputWithLabel>
          )}
          <div data-testid={`gdsn-export-mappings-list-element-${name}-field`}>
            <InputWithLabel
              inputId={`gdsn-export-mappings-list-element-${name}-field`}
              label="Field"
            >
              <Text
                id={`gdsn-export-mappings-list-element-${name}-field`}
                value={field}
                onChange={() => {}}
                disabled
              />
            </InputWithLabel>
          </div>
        </>
      )}
      {itemTypeOption?.id === 'custom_parse' && (
        <div
          data-testid={`gdsn-export-mappings-list-element-${name}-custom-parse-selector`}
        >
          <InputWithLabel
            inputId={`gdsn-export-mappings-list-element-${name}-custom-parse-selector`}
            label="Custom parse"
          >
            <TurboSelect
              id={`gdsn-export-mappings-list-element-${name}-field-selector`}
              options={customParseOptions}
              getOptionLabel={(itemType) => itemType.id}
              getOptionValue={(itemType) => itemType.id}
              value={customParseOption}
              isMulti={false}
              isClearable
              onChange={updateCustomParse}
              placeholder="Select..."
              className={classNames({
                [styles.errorInput]: !customParseOption,
              })}
            />
          </InputWithLabel>
        </div>
      )}
    </div>
  );
}
