import { List } from 'immutable';

import { notificationError } from 'actions/notifications';
import { immutableMediaApi } from 'resources/mediaApi';
import { immutableReferentialApi } from 'resources/referentialApi';
import { logError } from 'utils';

import * as events from '../constants/events';
import { selectFilterReferentialSlug, selectFilterSearch } from '../selectors';

export const setIsLoading = (loading) => ({
  type: events.LOADING,
  loading,
});

export const openModal = (referentialEntity) => ({
  type: events.OPEN_MODAL,
  referentialEntity,
});

export const closeModal = () => ({
  type: events.CLOSE_MODAL,
});

export const getReferentialEntities = () => async (dispatch, getState) => {
  const state = getState();

  const referentialSlug = selectFilterReferentialSlug(state);

  if (!referentialSlug) {
    return dispatch({
      type: events.RECEIVED,
      list: List(),
    });
  }

  dispatch(setIsLoading(true));
  try {
    const response = await immutableReferentialApi.ReferentialGetList(
      referentialSlug,
      { search: selectFilterSearch(state) || '' },
    );
    dispatch({
      type: events.RECEIVED,
      list: response.data.data,
    });
  } catch (exception) {
    logError(exception);
    dispatch(
      notificationError(
        'An error occured on referential list. Please retry later',
      ),
    );
  }

  return dispatch(setIsLoading(false));
};

export const updateFilterSearch = (search) => ({
  type: events.SEARCH_UPDATE,
  search,
});

export const updateFilterReferentialSlug = (referentialSlug) => ({
  type: events.TYPE_UPDATE,
  referentialSlug,
});

export const setModalUploading = (uploading) => ({
  type: events.REFERENTIAL_PICTURES_SET_MODAL_UPLOADING,
  uploading,
});

export const uploadPicture =
  (referentialEntityId, picture) => async (dispatch) => {
    dispatch(setModalUploading(true));

    try {
      await immutableMediaApi.ConceptPictureUpload(
        referentialEntityId,
        picture,
      );
    } catch (exception) {
      logError(exception);
      dispatch(
        notificationError('An error occured while uploading your picture'),
      );
    }

    dispatch(closeModal(false));
    dispatch(getReferentialEntities());
  };
