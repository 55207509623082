import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';

import { getKindList } from 'actions/kinds';
import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { fill } from 'utils/routes';

import {
  validationAddToFilters,
  validationClearFilters,
  validationClearSelectedRules,
  validationInitFilters,
  validationSearchQuery,
} from './actions';
import { DocumentationModal } from './components/documentation-modal';
import { RuleCreationModal } from './components/rule-creation-modal';
import { ValidationRuleEditor } from './components/rule-editor';
import { ValidationRulesFilters } from './components/rules-filters';
import { ValidationRulesList } from './components/rules-list';
import {
  selectValidationFiltersSearch,
  selectValidationRules,
} from './selectors';
import { ReadOnlyRule } from './types';
import { formatRuleSetIdInFilter } from './utils';

export const ValidationDashboardView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(validationInitFilters());
    dispatch(getKindList() as any);
  }, [dispatch]);

  const [shownModal, setShownModal] = useState<
    'documentation' | 'rule-creation' | null
  >(null);

  const hideModal = useCallback(() => setShownModal(null), []);

  const handleRuleCreation = (rule: ReadOnlyRule | undefined) => {
    hideModal();
    if (rule) {
      const { ruleSet } = rule;
      if (ruleSet) {
        dispatch(validationClearFilters());
        dispatch(validationClearSelectedRules());
        navigate(fill(routes.validationRuleEditor, rule.id));
        dispatch(validationAddToFilters(formatRuleSetIdInFilter(ruleSet)));
      }
    }
  };

  const rules = useSelector(selectValidationRules);

  const q = useSelector(selectValidationFiltersSearch);
  const updateSearchQuery = useCallback(
    (query: string) => {
      dispatch(validationClearSelectedRules());
      dispatch(validationSearchQuery(query));
    },
    [dispatch],
  );

  let modal: ReactNode = null;
  switch (shownModal) {
    case 'documentation':
      modal = <DocumentationModal close={hideModal} />;
      break;
    case 'rule-creation':
      modal = <RuleCreationModal onClose={handleRuleCreation} />;
      break;
    default:
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <>
            <HeaderLayout
              title="Validation Dashboard"
              backHref={routes.home}
              backMessage="Back home"
              isTitleSmall
            >
              <Search
                query={q}
                updateSearchQuery={updateSearchQuery}
                placeholder="Search for rule id, name, or any rule content"
              >
                <>
                  <Button
                    secondary
                    onClick={() => setShownModal('documentation')}
                  >
                    Documentation
                  </Button>
                  <Button
                    primary
                    onClick={() => setShownModal('rule-creation')}
                  >
                    New Rule
                  </Button>
                </>
              </Search>
            </HeaderLayout>
            {modal}
            <div className="container-fluid row">
              <div className="col-md-3">
                <ValidationRulesFilters />
              </div>
              <div className="col-md-9">
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route path=":ruleId" element={<ValidationRuleEditor />} />
        <Route path="*" element={<ValidationRulesList rules={rules} />} />
      </Route>
    </Routes>
  );
};
