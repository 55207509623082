import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';

import StatusBar from 'components/ui/status-bar';
import { separateActions } from 'utils/redux';
import { TIME_FORMAT_SECOND, formatTimeImplicitUTC } from 'utils/time';

import { deleteEndpoint, getEndpointListFiles } from '../../actions';
import { selectFiles } from '../../selectors';

import './row.scss';

const WorkflowTypeToName = {
  0: 'Product',
  1: 'ProductInShop',
  2: 'ProductExport',
  3: 'ProductSegment',
  4: 'ProductRange',
};

const WorkflowDirectionToName = {
  0: 'Import',
  1: 'Export',
};

const mapDispatchToProps = {
  deleteEndpoint,
  getEndpointListFiles,
};

const mapStateToProps = (state) => ({
  files: selectFiles(state),
});

export class EndpointListRow extends Component {
  static propTypes = {
    endpoint: PropTypes.object.isRequired,
    files: PropTypes.object.isRequired,
    updateEndpointButtonClick: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      getEndpointListFiles: PropTypes.func.isRequired,
      deleteEndpoint: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      isConfirmDeleteModalOpen: false,
      isDeleteInProgress: false,
      endpointFilesFilters: {
        page: 0,
        limit: 20,
        total_result: 0,
      },
    };
  }

  onClickUpdate = () => {
    this.props.updateEndpointButtonClick(this.props.endpoint);
  };

  onClickExpand = () => {
    const { isExpanded, endpointFilesFilters } = this.state;
    const { endpoint } = this.props;
    if (isExpanded === false) {
      this.props.actions.getEndpointListFiles(
        endpoint.id,
        endpointFilesFilters.limit,
        endpointFilesFilters.limit * endpointFilesFilters.page,
      );
    }
    this.setState({ isExpanded: !isExpanded });
  };

  onClickDeleteEndpoint = () => {
    const { endpoint } = this.props;
    this.setState({ isDeleteInProgress: true });
    this.props.actions.deleteEndpoint(endpoint.id, this.hideConfirmDeleteModal);
  };

  getEntityState(entity) {
    return entity.active === 0 ? 'FAILURE' : 'SUCCESS';
  }

  getHandlersSummary(handlers) {
    const importerCount = handlers.filter((h) => h.direction === 0).length;
    const exporterCount = handlers.filter((h) => h.direction === 1).length;
    return `${importerCount} Import / ${exporterCount} Export`;
  }

  hideConfirmDeleteModal = () => {
    this.setState({
      isConfirmDeleteModalOpen: false,
      isDeleteInProgress: false,
    });
  };

  openConfirmDeleteModal = () => {
    this.setState({
      isConfirmDeleteModalOpen: true,
      isDeleteInProgress: false,
    });
  };

  onSearch = (givenPage) => {
    const { endpointFilesFilters } = this.state;
    const { endpoint } = this.props;
    const page =
      givenPage !== undefined ? givenPage : endpointFilesFilters.page;
    this.props.actions.getEndpointListFiles(
      endpoint.id,
      endpointFilesFilters.limit,
      page * endpointFilesFilters.limit,
    );
  };

  simpleSearch = () => this.onSearch();

  searchPrev = () => {
    const { endpointFilesFilters } = this.state;
    if (endpointFilesFilters.page === 0) {
      return;
    }
    this.setState({
      endpointFilesFilters: {
        ...endpointFilesFilters,
        page: endpointFilesFilters.page - 1,
      },
    });
    this.onSearch(endpointFilesFilters.page - 1);
  };

  searchNext = () => {
    const { endpoint, files } = this.props;
    const { endpointFilesFilters } = this.state;
    if (
      (endpointFilesFilters.page + 1) * endpointFilesFilters.limit >=
      files.get(endpoint.id, {
        totalResults: 0,
      }).totalResults
    ) {
      return;
    }
    this.setState({
      endpointFilesFilters: {
        ...endpointFilesFilters,
        page: endpointFilesFilters.page + 1,
      },
    });
    this.onSearch(endpointFilesFilters.page + 1);
  };

  pathMatchHandler = (path, handlers) =>
    handlers.some(
      (h) =>
        h.direction === 0 &&
        h.config.regexp &&
        path.match(h.config.regexp) !== null,
    );

  renderHandlers() {
    const { endpoint } = this.props;
    const lines = endpoint.handlers.map((handler) => (
      <div
        key={handler.id}
        className="EndpointHandlerRow row"
        data-testid="endpointHandlerRow-row"
      >
        <div className="col-xs-0 EndpointHandlerListRow_StatusBar">
          <StatusBar type={this.getEntityState(endpoint)} />
        </div>
        <div className="col-xs-2">
          {WorkflowDirectionToName[handler.direction]}
        </div>
        <div className="col-xs-2">{WorkflowTypeToName[handler.type]}</div>
        <div className="col-xs-6">
          {handler.config && handler.config.regexp
            ? handler.config.regexp
            : '-'}
        </div>
        <div className="col-xs-1">{handler.id}</div>
      </div>
    ));
    return (
      <div className="EndpointHandlerRowWrapper">
        <div className="EndpointHandlerRow EndpointHandlerRow__Header_Title row">
          <div className="col-xs-12">Handlers connected</div>
        </div>
        <div className="EndpointHandlerRow EndpointHandlerRow__Header row">
          <div className="col-xs-2">Direction</div>
          <div className="col-xs-2">Workflow</div>
          <div className="col-xs-6">Regexp</div>
          <div className="col-xs-2">Handler id</div>
        </div>
        <div
          className="EndpointHandlerRow__Lines"
          data-testid="endpointHandlerRow-lines"
        >
          {lines}
        </div>
      </div>
    );
  }

  renderFilterRow = () => {
    const { endpoint, files } = this.props;
    const { endpointFilesFilters } = this.state;

    return (
      <div
        className="EndpointFilesRow__Lines EndpointFilesRow EndpointFilesRow__Search row"
        data-testid="endpointHandlerRow-row"
      >
        <div className="col-xs-2" />
        <div className="col-xs-8 EndpointFilesRow_Navigation">
          {`${endpointFilesFilters.page + 1} / ${
            Math.ceil(
              files.get(endpoint.id, {
                totalResults: 0,
              }).totalResults / endpointFilesFilters.limit,
            ) || 1
          } pages`}
          <div className="EndpointFilesRow_NavigationSeparator" />
          <Button
            key={`endpoint-${endpoint.id}-search_prev`}
            secondary
            disabled={endpointFilesFilters.page === 0}
            onClick={this.searchPrev}
          >
            <i className="mdi mdi-chevron-left" />
          </Button>
          <Button
            key={`endpoint-${endpoint.id}-search_next`}
            secondary
            disabled={
              (endpointFilesFilters.page + 1) * endpointFilesFilters.limit >=
              files.get(endpoint.id, {
                totalResults: 0,
              }).totalResults
            }
            onClick={this.searchNext}
          >
            <i className="mdi mdi-chevron-right" />
          </Button>
          <div className="EndpointFilesRow_NavigationSeparator" />
          <Button
            key={`endpoint-${endpoint.id}-search_btn`}
            secondary
            onClick={this.simpleSearch}
          >
            <i className="mdi mdi-refresh" />
          </Button>
        </div>
      </div>
    );
  };

  renderLastFiles() {
    const { endpoint, files } = this.props;
    const lines = files.get(endpoint.id, { files: [] }).files.map((file) => (
      <div
        key={`endpoint-${endpoint.id}-files-${file.name}`}
        className="EndpointHandlerRow row"
      >
        <StatusBar
          type={
            this.pathMatchHandler(file.path, endpoint.handlers)
              ? 'SUCCESS'
              : 'FAILURE'
          }
        />
        <div className="col-xs-2">
          {formatTimeImplicitUTC(file.createdAt, TIME_FORMAT_SECOND)}
        </div>
        <div className="col-xs-2 EndpointHandlerRowScroll">{file.name}</div>
        <div className="col-xs-1">{file.size}</div>
        <div className="col-xs-5 EndpointHandlerRowScroll">{file.path}</div>
        <div className="col-xs-1">
          <i
            className={`mdi mdi-${
              this.pathMatchHandler(file.path, endpoint.handlers)
                ? 'sync EndpointFileProcessable'
                : 'sync-off EndpointFileUnProcessable'
            }`}
          />
        </div>
      </div>
    ));
    return (
      <div className="EndpointHandlerRowWrapper">
        {this.renderFilterRow()}
        <div className="EndpointHandlerRow EndpointHandlerRow__Header row">
          <div className="col-xs-2">Received date</div>
          <div className="col-xs-2">Name</div>
          <div className="col-xs-1">Size</div>
          <div className="col-xs-5">Path</div>
          <div className="col-xs-1">Wired</div>
        </div>
        <div
          className="EndpointHandlerRow__Lines"
          data-testid="endpointHandlerRow-lines"
        >
          {lines}
        </div>
      </div>
    );
  }

  render() {
    const { endpoint } = this.props;
    const { isExpanded } = this.state;
    const { files } = this.props;
    const isExpandable =
      endpoint.handlers.length > 0 ||
      (files.get(endpoint.id, null) !== null &&
        files.get(endpoint.id).files.length > 0);
    const expandClass = {
      EndpointDetailsIcon: true,
      mdi: true,
      'mdi-chevron-up': isExpandable && isExpanded,
      'mdi-chevron-down': isExpandable && !isExpanded,
      hidden: !isExpandable,
    };
    return (
      <div className="EndpointListRowWrapper">
        <div key={endpoint.id} id={endpoint.id} className="EndpointListRow row">
          <div className="col-xs-0 EndpointListRow_StatusBar">
            <StatusBar type={this.getEntityState(endpoint)} />
          </div>
          <div className="col-xs-6">
            <div
              className="EndpointListRow_boldy"
              data-testid="endpointListRow-endpoint-name"
            >
              <i className="mdi mdi-shape" />
              {` ${endpoint.name}`}
            </div>
            <div
              className="EndpointListRow_smally"
              data-testid="endpointListRow-organization"
            >
              <i className="mdi mdi-office-building" />
              {` ${endpoint.organization.nameLegal} (${endpoint.organization.id})`}
            </div>
            {endpoint.type !== 2 && (
              <div
                className="EndpointListRow_smally"
                data-testid="endpointListRow-user"
              >
                <i className="mdi mdi-odnoklassniki" />
                {` ${endpoint.username} @ ${
                  endpoint.type === 0
                    ? 'Salsify SupplierXM SFTP'
                    : endpoint.config.hostname || endpoint.config.base_url
                }`}
              </div>
            )}
          </div>
          <div className="col-xs-4">
            {endpoint.type !== 2 && (
              <div
                className="EndpointListRow_smally"
                data-testid="endpointListRow-summary"
              >
                {this.getHandlersSummary(endpoint.handlers)}
              </div>
            )}
          </div>
          <div className="col-xs-1 EndpointListRow_actions">
            {endpoint.type !== 2 && (
              <div
                onClick={this.onClickUpdate}
                data-testid="endpointDetailsIcon-update"
                className="EndpointDetailsIcon mdi mdi-border-color"
              />
            )}
            <div
              onClick={this.openConfirmDeleteModal}
              className="EndpointDetailsIcon mdi mdi-delete"
            />
            <div
              onClick={this.onClickExpand}
              data-testid="endpointDetailsIcon-expand"
              className={classNames(expandClass)}
            />
          </div>
        </div>
        {isExpanded && endpoint.handlers.length > 0
          ? this.renderHandlers()
          : null}
        {isExpanded &&
        files.get(endpoint.id, null) !== null &&
        files.get(endpoint.id).files.length > 0
          ? this.renderLastFiles()
          : null}
        {this.state.isConfirmDeleteModalOpen && (
          <Modal
            title="Remove SFTP"
            modalStyle="dynamic"
            confirmButtonText="Remove"
            onConfirm={this.onClickDeleteEndpoint}
            onClose={this.hideConfirmDeleteModal}
            isProcessing={this.state.isDeleteInProgress}
          >
            <div>Are you sure you want to remove: {endpoint.name}</div>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(EndpointListRow);
