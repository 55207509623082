import qs from 'querystringify';
import React from 'react';

import buyingUnitApi from 'resources/buyingUnitApi';
import onboardingApi from 'resources/onboardingApi';

import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from '../legacy-autocomplete';

export const URIEntityRoutes = [
  'buyingunit.v2.referentials.get',
  'buyingunit.v3.referentials.get',
  'onboarding.v1.referentials.get',
];

type Props = {
  uri: string;
} & LegacyAutocompleteProps;

export const URIReferentialAutocomplete = React.memo(
  function URIReferentialAutocomplete({ uri, ...props }: Props) {
    const routeToPath = (route) => `/${route.replace(/\./g, '/')}`;
    const endpoints = {
      'buyingunit.v2.referentials.get': {
        path: '/buyingunit/v2/referentials/admin',
      },
      'buyingunit.v3.referentials.get': {
        // same as v2, cf PHI-886
        path: '/buyingunit/v2/referentials/admin',
      },
      'onboarding.v1.referentials.get': {
        api: onboardingApi,
        path: '/onboarding/v1/referentials/all',
        // TODO: PHI: New API with no limit that deduplicate referential name + handle search
        extraParameters: { limit: 250 },
        transformData: (response) =>
          response
            .map(({ name }) => name)
            // remove duplication (the same referential name can appear for multiple retailers)
            .reduce(
              (acc, name) => (acc.includes(name) ? acc : [...acc, name]),
              [],
            )
            .map((n) => ({
              label: n,
              name: n,
              id: n,
              code: n,
            })),
      },
      default: (route) => ({
        path: routeToPath(route),
      }),
    };

    const onSearch = (search) => {
      if (!uri) {
        return null;
      }
      const {
        api = buyingUnitApi,
        extraParameters = {},
        path,
      } = endpoints[uri] || endpoints.default(uri);
      return api.get(path, qs.stringify({ search, ...extraParameters }, true));
    };

    const transformData = (data) => {
      const { transformData = (response) => response } =
        endpoints[uri] || endpoints.default(uri);
      return transformData(data);
    };

    return (
      <LegacyAutocomplete
        onSearch={onSearch}
        transformData={transformData}
        {...props}
      />
    );
  },
);
