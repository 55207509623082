import classNames from 'classnames';
import { MouseEventHandler, SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import {
  validationDeleteRule,
  validationSaveRuleData,
  validationUpdateDirtyRule,
} from 'modules/validation-dashboard/actions';
import { selectValidationIsSaving } from 'modules/validation-dashboard/selectors';
import {
  Rule,
  RuleSelector,
  ValidationRuleStatus,
} from 'modules/validation-dashboard/types';
import { stopPropagation } from 'modules/validation-dashboard/utils';

import { validationDuplicateRule } from '../../actions';

import './validation-rule-header.scss';
import { RuleName } from './validation-rule-name';

const flagFeaturesProps = [
  'allowSave',
  'allowDuplicate',
  'allowToggleStatus',
  'allowDelete',
] as const;

type FlagFeaturesProps = {
  [k in (typeof flagFeaturesProps)[number]]?: boolean;
};

type Props = {
  rule: Rule;
  isDirty?: boolean;
  disabled?: boolean;
  withWarning?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  copyAction?: Function;
} & FlagFeaturesProps;

export const ValidationRuleHeader = ({
  rule,
  isDirty,
  withWarning,
  allowSave = false,
  allowDuplicate = false,
  allowToggleStatus = false,
  allowDelete = false,
  disabled,
}: Props) => {
  const isSaving = useSelector(selectValidationIsSaving(rule.id));

  const [isDuplicating, setDuplicating] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] =
    useState(false);

  const dispatch = useDispatch();

  const onDuplicateRule = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    e.preventDefault();
    setDuplicating(true);
    dispatch(validationDuplicateRule(rule.id));
  };

  const onDeleteRule = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    setDeleting(true);
    setIsConfirmDeleteModalOpened(false);
    dispatch(validationDeleteRule(rule.id));
  };

  const openConfirmDeleteModal = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsConfirmDeleteModalOpened(true);
  };

  const closeConfirmDeleteModal = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    setIsConfirmDeleteModalOpened(false);
  };

  const toggleStatus = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();

    dispatch(
      validationUpdateDirtyRule({
        path: ['status'],
        value: rule.status
          ? ValidationRuleStatus.Disabled
          : ValidationRuleStatus.Enabled,
      }),
    );
  };

  const formItemsToDictReducer = (
    dict: { [key: string]: string },
    entry: RuleSelector,
  ) => ({
    ...dict,
    [entry.key]: entry.value,
  });

  const saveRuleModifications = async () => {
    const conditionSelectorsItems: RuleSelector[] = [];
    const selectorsItems: RuleSelector[] = [];

    rule.selectorList.forEach((item) => {
      if (item.usedToDisplayErrors) {
        selectorsItems.push(item);
      } else {
        conditionSelectorsItems.push(item);
      }
    });

    rule.constants = rule.constantList.reduce(formItemsToDictReducer, {});
    rule.selectors = selectorsItems.reduce(formItemsToDictReducer, {});
    rule.conditionSelectors = conditionSelectorsItems.reduce(
      formItemsToDictReducer,
      {},
    );

    dispatch(validationSaveRuleData(rule));
  };

  return (
    <div
      className="ValidationRuleHeader alk-flex alk-flex-space-between"
      data-testid={`validation-rule-header-${rule.id}`}
    >
      <div
        className="ValidationRuleHeader__title"
        data-testid={`validation-rule-header-${rule.id}-title`}
      >
        {`${rule.id ? rule.id : 'N.A.'}`}
        &nbsp;-&nbsp;
        <RuleName rule={rule} />
      </div>
      <div
        className="ValidationRuleHeader__actions"
        data-testid={`validation-rule-header-${rule.id}-actions`}
      >
        {rule.bypassable ? (
          <div
            className="ValidationRuleHeader__bypassable btn"
            onClick={stopPropagation}
          >
            BYPASSABLE
          </div>
        ) : null}
        <Button
          className={classNames(
            'ValidationRuleStatus',
            { disabled: !allowToggleStatus },
            rule.status === ValidationRuleStatus.Enabled
              ? 'btn-success'
              : 'btn-danger',
          )}
          onClick={allowToggleStatus ? toggleStatus : stopPropagation}
        >
          {rule.status === ValidationRuleStatus.Enabled ? 'LIVE' : 'OFF'}
        </Button>
        {allowSave && (
          <Button
            primary={isDirty && !withWarning}
            className={classNames({
              'save-btn-warning': withWarning,
            })}
            disabled={!isDirty || isSaving || disabled}
            displaySpinner={isSaving}
            onClick={() => saveRuleModifications()}
          >
            {withWarning && (
              <i className="mdi mdi-alert" data-testid="save-button-warning" />
            )}
            <span>Save</span>
          </Button>
        )}
        {allowDuplicate && (
          <LazyTooltip
            id={`duplicate-rule-${rule.id}-button`}
            tooltipLabel={'Duplicate rule'}
            place="bottom"
          >
            <Button
              disabled={isDuplicating}
              className="ValidationRuleHeader__duplicate round"
              onClick={onDuplicateRule}
              testid={`validation-rule-${rule.id}-duplicate`}
            >
              <i className="mdi mdi-checkbox-multiple-blank-outline" />
            </Button>
          </LazyTooltip>
        )}
        {allowDelete && (
          <LazyTooltip
            id={`delete-rule-${rule.id}-button`}
            tooltipLabel={'Delete rule'}
            place="bottom"
          >
            <Button
              disabled={isDeleting}
              className="ValidationRuleHeader__delete round"
              onClick={openConfirmDeleteModal}
              testid={`validation-rule-${rule.id}-delete`}
            >
              <i className="mdi mdi-delete" />
            </Button>
          </LazyTooltip>
        )}
        {isConfirmDeleteModalOpened && (
          <Modal
            danger
            title="Delete rule"
            modalStyle="dynamic"
            confirmButtonText="Delete rule"
            onConfirm={onDeleteRule}
            onClose={closeConfirmDeleteModal}
          >
            <div>
              Do you really want to delete this rule? The deletion will be
              effective immediately and cannot be reverted.
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};
