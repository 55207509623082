import React from 'react';

import AuthApi from 'resources/authApi';

import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from '../legacy-autocomplete';

type Props = {
  filters?: any;
  withs?: any;
} & LegacyAutocompleteProps;

export const OrganizationAutocomplete = React.memo(
  function OrganizationAutocomplete({
    filters = {},
    withs = {},
    ...props
  }: Props) {
    const onSearch = (search) => {
      if (search) {
        if (Number.isNaN(Number(search))) {
          filters.nameLegal = search;
        } else {
          filters.ids = [search];
        }
      }
      return AuthApi.OrganizationListV3(filters, withs);
    };

    const transformData = (data) =>
      data.map((value) => ({
        key: value.id,
        value,
        label: `${value.nameLegal ? value.nameLegal : ''} - ${value.id}`,
        users: value.users || [],
      }));

    return (
      <LegacyAutocomplete
        onSearch={onSearch}
        transformData={transformData}
        {...props}
      />
    );
  },
);
