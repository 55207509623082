import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { AddButton } from '@alkem/react-ui-button';

import './index.scss';
import OrganizationGroupsItem from './item';

export default class OrganizationGroupsList extends PureComponent {
  static propTypes = {
    groups: ImmutablePropTypes.list.isRequired,
    edit: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  onCreateGroup = () => {
    const { edit } = this.props;
    edit(Map());
  };

  render() {
    const { groups, edit, readOnly } = this.props;
    return (
      <div className="OrganizationGroupsList">
        {groups
          .map((group) => (
            <OrganizationGroupsItem
              key={group.get('id')}
              group={group}
              edit={edit}
              readOnly={readOnly}
            />
          ))
          .toArray()}
        {!readOnly && (
          <div className="OrganizationGroupsList__add">
            <AddButton label="Add a group" onClick={this.onCreateGroup} />
          </div>
        )}
      </div>
    );
  }
}
