import { Map } from 'immutable';
import { isFunction } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import { notificationError } from 'actions/notifications';
import { immutableSearchApi } from 'resources/searchApi';
import { isEAN } from 'utils/actions/list';

import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from '../legacy-autocomplete';

type Props = {
  selector?: (value: any) => any;
  extraParams?: any;
} & LegacyAutocompleteProps;

const defaultSelector = (value) => ({
  key: value.getIn(['specializes', 'id']),
  value: value.getIn(['specializes', 'id']),
  label: `${value.getIn(['specializes', 'isIdentifiedBy', 0, 'reference'])} - ${
    value.getIn(['namePublicLong', 0, 'data']) || 'No name'
  }`,
});

export const transformData = (selector) => (data) => {
  if (isFunction(selector)) {
    return data
      .map(selector)
      .reduce((v, e) => v.set(e.key, e), Map())
      .valueSeq()
      .toJS();
  }
  return data;
};

export const ProductAutocomplete = React.memo(function ProductAutocomplete({
  selector = defaultSelector,
  extraParams = {},
  ...props
}: Props) {
  const dispatch = useDispatch();

  const onSearch = (search) => {
    const body: any = {
      filter_source_include: ['specializes', 'namePublicLong'],
      ...extraParams,
    };

    if (isEAN(search)) {
      body.filter_gtins_in = [search];
    } else if (search.startsWith('::')) {
      body.filter_id_in = [search.slice(2)];
      if (search.length === 2) {
        return null;
      }
    } else if (search.startsWith(':')) {
      body.filter_product_id_in = [search.slice(1)];
      if (search.length === 1) {
        return null;
      }
    } else if (search) {
      body.q = search;
    }

    return immutableSearchApi
      .post('/search/v1/productversion', body)
      .catch((e) => {
        dispatch(notificationError(e.data.message));
        throw e;
      });
  };

  const _transformData = transformData(selector);

  return (
    <LegacyAutocomplete
      onSearch={onSearch}
      transformData={_transformData}
      {...props}
    />
  );
});
