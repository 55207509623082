import { ModuleWrapper } from 'redux/register';

import { VALIDATION_DASHBOARD_STORE_KEY } from './constants';
import reducer from './reducers';
import { ValidationDashboardView } from './view';

export default function ValidationDashboardModule() {
  return (
    <ModuleWrapper
      reducerKey={VALIDATION_DASHBOARD_STORE_KEY}
      reducer={reducer}
    >
      <ValidationDashboardView />
    </ModuleWrapper>
  );
}
