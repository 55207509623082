import { get, isFunction, isUndefined, set } from 'lodash';
import { Action } from 'redux';

import {
  ActionBuilder,
  ActionWithPayload,
  ActionWithPayloadStrict,
} from './types';

export const getReducer =
  <S>(initialState: S) =>
  (state: S): S => {
    if (typeof state === 'undefined') {
      return initialState;
    }
    // For now, don’t handle any actions
    // and just return the state given to us.
    return state;
  };

export function createWithPayload<P = any>(type: string, payload?: P) {
  if (type == null) {
    throw new Error(
      "Action with type 'undefined'. Have you misspelled a constant?",
    );
  }
  if (payload === undefined) {
    return { type } as Action;
  }

  return { type, payload } as ActionWithPayload<P>;
}

export function createAction<P = any>(actionType: string): ActionBuilder<P> {
  return (payload?: P) => createWithPayload(actionType, payload);
}

export function createActionWithPayload<P>(actionType: string) {
  return (payload: P) =>
    createWithPayload(actionType, payload) as { type: string; payload: P };
}

export function createReducer<S>(
  initialState: S,
  reducers: {
    [key: string]: (s: S, a: ActionWithPayload) => S;
  },
) {
  return function reducer(state = initialState, action: ActionWithPayload): S {
    if (reducers[action.type]) {
      return reducers[action.type](state, action);
    }
    return state;
  };
}

type F<S> = (s: S) => S;

export function createFpReducer<S>(
  initialState: S,
  reducers: {
    [key: string]: (p: any, s: S) => F<S> | S;
  },
) {
  return function reducer(
    state = initialState,
    action: ActionWithPayload | { type: string; payload?: any },
  ) {
    const { type, payload, ...otherProps } = action;
    if (reducers[type]) {
      const result = reducers[type](
        isUndefined(payload) ? otherProps : payload,
        state,
      );
      if (isFunction(result)) {
        return result(state);
      }
      return result;
    }
    return state;
  };
}

export function createReducerStrict<S>(
  initialState: S,
  reducers: {
    [key: string]: (s: S, a: ActionWithPayloadStrict) => S;
  },
) {
  return function reducer(
    state = initialState,
    action: ActionWithPayloadStrict,
  ): S {
    if (reducers[action.type]) {
      return reducers[action.type](state, action);
    }
    return state;
  };
}

export const updateReferenceOfPath = (object: any, key: string) => {
  const chunks = key.split('.');
  chunks.reduce((previousValue: string | undefined, currentValue: string) => {
    let test = currentValue;
    if (previousValue) {
      test = `${previousValue}.${currentValue}`;
    }

    const data = get(object, test);
    if (Array.isArray(data)) {
      set(object, test, [...data]);
    } else if (data instanceof Object) {
      set(object, test, { ...data });
    }

    return test;
  }, undefined);

  return object;
};
