import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import {
  CheckboxFilter,
  Filter,
  Filters,
  FiltersBlock,
  SelectedFilters,
} from '@alkem/react-ui-list-filter';

import OrganizationFilterLite from 'components/ui/filter/organization/lite';

import { addFilter, clearFilters, removeFilter } from '../../actions';
import { getOrganizations } from '../../actions/organizations';
import {
  selectAdminFilters,
  selectOrganizationFilters,
  selectSelectedFilters,
} from '../../selectors';
import { selectOrganizationList } from '../../selectors/organizations';

const mapStateToProps = (state) => ({
  selected: selectSelectedFilters(state),
  organizations: selectOrganizationList(state),
  adminFilter: selectAdminFilters(state),
  organizationsFilters: selectOrganizationFilters(state),
});

const mapDispatchToProps = {
  getOrganizations,
  addFilter,
  removeFilter,
  clearFilters,
};

class UserListFilters extends PureComponent {
  static propTypes = {
    selected: ImmutablePropTypes.list.isRequired,
    organizations: ImmutablePropTypes.list.isRequired,
    adminFilter: ImmutablePropTypes.map.isRequired,
    organizationsFilters: ImmutablePropTypes.map.isRequired,
    getOrganizations: PropTypes.func.isRequired,
    addFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.clearFilter = this.clearFilter.bind(this);
    this.onAdminFilterChange = this.onAdminFilterChange.bind(this);
    this.onOrganizationFilterChange =
      this.onOrganizationFilterChange.bind(this);

    this.adminFilterModel = [{ id: 1, name: 'Admin' }];
    this.adminFilterSelectors = {
      selectId: (filter) => `admin-${filter.id}`,
      selectNode: (filter) => filter.name,
    };
  }

  componentDidMount() {
    this.props.getOrganizations();
  }

  onAdminFilterChange(filter, newValue) {
    if (newValue === false) {
      this.props.removeFilter('admin', filter.id);
    } else {
      this.props.addFilter('admin', filter.id, newValue, filter.name);
    }
  }

  onOrganizationFilterChange(filter, newValue) {
    if (newValue === false) {
      this.props.removeFilter('organizations', filter.get('id'));
    } else {
      this.props.addFilter(
        'organizations',
        filter.get('id'),
        newValue,
        filter.get('nameLegal'),
      );
    }
  }

  clearFilter(filter) {
    this.props.removeFilter(filter.get('filterType'), filter.get('filterId'));
  }

  selectSelectedFilterId(filter) {
    return `${filter.get('filterType')}-${filter.get('filterId')}`;
  }

  selectSelectedFilterLabel(filter) {
    return filter.get('filterLabel');
  }

  isAdminFilterSelected(filter, selection) {
    return selection.get(filter.id);
  }

  render() {
    return (
      <Filters>
        <SelectedFilters
          id="userlist-selectedfilters"
          title="Selected Filters"
          emptyLabel="No selected filters"
          removeLabel="Remove"
          filters={this.props.selected}
          selectId={this.selectSelectedFilterId}
          selectLabel={this.selectSelectedFilterLabel}
          onRemoveAll={this.props.clearFilters}
          onRemove={this.clearFilter}
        />
        <FiltersBlock>
          <OrganizationFilterLite
            id="userlist-filter-organization"
            filters={this.props.organizations}
            selection={this.props.organizationsFilters}
            onChange={this.onOrganizationFilterChange}
          />
          <Filter
            id="userlist-filter-admin"
            title="User Rights"
            filters={this.adminFilterModel}
            selection={this.props.adminFilter}
            Item={CheckboxFilter}
            selectors={this.adminFilterSelectors}
            isSelected={this.isAdminFilterSelected}
            onChange={this.onAdminFilterChange}
          />
        </FiltersBlock>
      </Filters>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListFilters);
