import { ModuleWrapper } from 'redux/register';

import ReferentialsManager from './components';
import { REDUCER_KEY } from './constants';
import reducer from './reducers';

export default function ReferentialDashboardContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <ReferentialsManager />
    </ModuleWrapper>
  );
}
