import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Text, Textarea } from '@alkem/react-ui-inputs';
import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';

import { selectFunctionForConstantsOptions } from '../../../../selectors';
import {
  ExportMappingConstantCast,
  ExportMappingPayloadConstant,
  Option,
  Options,
} from '../../../../types';
import { isOption } from '../../utils';

import styles from './constant.module.scss';

const getInitialFunctionValue = (
  detail: ExportMappingPayloadConstant,
  functionOptions: Options,
) => {
  const { cast, data } = detail;
  if (!cast || cast !== 'function') return undefined;

  return functionOptions.find((functionOption) => functionOption.id === data);
};

interface Props {
  id: string;
  name?: string;
  constant: ExportMappingPayloadConstant;
  removeConstant: (name: string) => void;
  editConstantName: (currentName: string, newName: string) => void;
  editConstantData: (name: string, data?: string) => void;
}
export function ExportMappingsItemConstantItem({
  id,
  name = '',
  constant,
  removeConstant,
  editConstantName,
  editConstantData,
}: Props) {
  const functionOptions = useSelector(selectFunctionForConstantsOptions);
  const [isEditingName, setIsEditingName] = useState(false);
  const [nameValue, setNameValue] = useState(name);
  const [functionOption, setFunctionOption] = useState<Option | undefined>(
    getInitialFunctionValue(constant, functionOptions),
  );

  const updateNameValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp(/[^a-zA-Z0-9_]/, 'gm');
    let value = event.target.value.replace(regex, '');
    if (value.length === 1 && !isNaN(parseInt(value))) {
      value = '';
    }
    setNameValue(value);
  };

  const updateConstantName = () => {
    try {
      editConstantName(name, nameValue);
      setIsEditingName(false);
    } catch {
      setNameValue(name);
    }
  };

  const updateDataFunction = (value: TurboSelectOption<Option>) => {
    if (isOption(value)) {
      setFunctionOption(value);
      editConstantData(name, value.id);
    } else {
      setFunctionOption(undefined);
      editConstantData(name, undefined);
    }
  };

  const { cast } = constant;

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <i
          className="mdi mdi-trash-can"
          onClick={() => removeConstant(name)}
          data-testid={`remove-button-${id}`}
        />
      </div>
      <InputWithLabel
        inputId={`gdsn-export-mappings-item-${id}-constant-name`}
        label="Constant name"
      >
        <div
          className={styles.nameContainer}
          data-testid={`gdsn-export-mappings-item-${id}-constant-name`}
        >
          <Text
            id={`gdsn-export-mappings-item-${id}-constant-name`}
            value={nameValue}
            onChange={updateNameValue}
            invalid={!nameValue}
            disabled={!isEditingName}
          />
          {isEditingName ? (
            <i className="mdi mdi-check" onClick={updateConstantName} />
          ) : (
            <i
              className="mdi mdi-pencil"
              onClick={() => setIsEditingName(true)}
            />
          )}
        </div>
      </InputWithLabel>
      {cast === ExportMappingConstantCast.FUNCTION && (
        <div
          data-testid={`gdsn-export-mappings-item-${id}-data-function-property`}
        >
          <InputWithLabel
            inputId={`gdsn-export-mappings-item-${id}-data-function-property`}
            label="Function"
          >
            <TurboSelect
              id={`gdsn-export-mappings-item-${id}-data-function-property`}
              options={functionOptions}
              getOptionLabel={(item) => item.id}
              getOptionValue={(item) => item.id}
              value={functionOption}
              isMulti={false}
              isSearchable={false}
              isClearable
              onChange={updateDataFunction}
              placeholder="Select..."
              className={classNames({
                [styles.errorInput]: !functionOption,
              })}
            />
          </InputWithLabel>
        </div>
      )}
      {cast === ExportMappingConstantCast.LAMBDA && (
        <div
          data-testid={`gdsn-export-mappings-item-${id}-data-lambda-property`}
        >
          <InputWithLabel
            inputId={`gdsn-export-mappings-item-${id}-data-lambda-property`}
            label="Lambda self, hierarchy, su, constants, module:"
          >
            <Textarea
              id={`gdsn-export-mappings-item-${id}-data-lambda-property`}
              value={constant.data || ''}
              autoresize
              onChange={(event) => editConstantData(name, event.target.value)}
              invalid={!constant.data}
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            />
          </InputWithLabel>
        </div>
      )}
    </div>
  );
}
