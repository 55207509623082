import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import OrganizationFilter from 'components/ui/filter/organization';
import SelectedFilters from 'components/ui/selected-filters';
import { separateActions } from 'utils/redux';

import {
  addEndpointListFilters,
  clearEndpointListFilters,
  getEndpointListFilters,
  removeEndpointListFilters,
  updateEndpointListFilters,
} from '../actions';
import {
  selectFilters,
  selectRawFilters,
  selectedOrganizations,
} from '../selectors';

const mapStateToProps = (state) => ({
  filters: selectFilters(state),
  filtersRaw: selectRawFilters(state),
  selectedOrgas: selectedOrganizations(state),
});

const mapDispatchToProps = {
  getEndpointListFilters,
  addEndpointListFilters,
  removeEndpointListFilters,
  updateEndpointListFilters,
  clearEndpointListFilters,
};

class EndpointFilters extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    filtersRaw: PropTypes.object.isRequired,
    selectedOrgas: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      getEndpointListFilters: PropTypes.func.isRequired,
      addEndpointListFilters: PropTypes.func.isRequired,
      removeEndpointListFilters: PropTypes.func.isRequired,
      updateEndpointListFilters: PropTypes.func.isRequired,
      clearEndpointListFilters: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.getEndpointListFilters();
  }

  onOrganizationFilterChange = (filter, newValue) => {
    if (newValue === false) {
      this.props.actions.removeEndpointListFilters('organizations', {
        label: filter.get('nameLegal'),
        value: filter.get('id'),
        checked: true,
      });
    } else {
      this.props.actions.addEndpointListFilters('organizations', {
        label: filter.get('nameLegal'),
        value: filter.get('id'),
        checked: false,
      });
    }
  };

  render() {
    const { filters, filtersRaw, selectedOrgas } = this.props;
    return (
      <div>
        <SelectedFilters
          filters={filters}
          removeFilter={this.props.actions.removeEndpointListFilters}
          removeAllFilters={this.props.actions.clearEndpointListFilters}
        />
        <div className="card">
          <OrganizationFilter
            id="endpoint-filter-organization"
            filters={filtersRaw.get('organizations')}
            selection={selectedOrgas}
            onChange={this.onOrganizationFilterChange}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(EndpointFilters);
