import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';
import { Tooltip } from '@alkem/react-ui-tooltip';

import StatusBar from 'components/ui/status-bar';
import * as routes from 'constants/routes';
import { PERMISSION_PRODUCT_EXPORT } from 'modules/access-policy/common/constants';
import { selectPermissions } from 'modules/auth/reducer';
import { separateActions } from 'utils/redux';
import { fill } from 'utils/routes';
import {
  TIME_FORMAT_MINUTE,
  TIME_FORMAT_SECOND,
  formatTimeImplicitUTC,
} from 'utils/time';
import { hasFullPower } from 'utils/user';

import {
  cancelExport,
  clearExportListFilters,
  downloadExportedFile,
  getExportFileReferenceList,
  openModal,
  openViewJsonModal,
  updateExportListFilters,
} from '../../actions';
import { selectReferences } from '../../selectors';

import StackTraceModal from './modal';
import './row.scss';

const mapDispatchToProps = {
  downloadExportedFile,
  getExportFileReferenceList,
  cancelExport,
  openModal,
  openViewJsonModal,
  clearExportListFilters,
  updateExportListFilters,
};

const mapStateToProps = (state) => ({
  references: selectReferences(state),
  userPermissions: selectPermissions(state),
  user: state.user,
});

export class ExportListRow extends Component {
  static propTypes = {
    exportFile: PropTypes.object.isRequired,
    references: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    userPermissions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      downloadExportedFile: PropTypes.func.isRequired,
      getExportFileReferenceList: PropTypes.func.isRequired,
      cancelExport: PropTypes.func.isRequired,
      openModal: PropTypes.func.isRequired,
      openViewJsonModal: PropTypes.func.isRequired,
      clearExportListFilters: PropTypes.func.isRequired,
      updateExportListFilters: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      isOrgaExpanded: false,
      isStackTraceModalOpen: false,
      stackTrace: null,
      exportDetailsFilters: {
        page: 0,
        offset: 0,
        limit: 20,
        filter_references_in: '',
      },
    };
    this.props.exportFile.steps.reverse();
  }

  onCloseModal = () => {
    this.setState({ isStackTraceModalOpen: false, stackTrace: null });
  };

  onOpenModal = (stackTrace) => () => {
    this.setState({ isStackTraceModalOpen: true, stackTrace });
  };

  onClickOrgaExpand = () => {
    const { isOrgaExpanded } = this.state;
    this.setState({ isOrgaExpanded: !isOrgaExpanded });
  };

  onClickExpand = () => {
    const { isExpanded, exportDetailsFilters } = this.state;
    if (isExpanded === false) {
      this.props.actions.getExportFileReferenceList(
        this.props.exportFile,
        exportDetailsFilters,
      );
    }
    this.setState({ isExpanded: !isExpanded });
  };

  getExportFileState(entity) {
    if (entity.steps.some((s) => ['ERROR', 'REJECTED'].includes(s.status))) {
      return 'FAILURE';
    } else if (entity.steps.some((s) => ['RETRY'].includes(s.status))) {
      return 'TIMEOUT';
    } else if (entity.steps.some((s) => ['REVIEW'].includes(s.status))) {
      return 'REVIEW';
    }
    return 'SUCCESS';
  }

  getExportFileStepState(entity) {
    return (
      {
        ERROR: 'FAILURE',
        REJECTED: 'FAILURE',
        RETRY: 'TIMEOUT',
        REVIEW: 'REVIEW',
      }[entity.status] || 'SUCCESS'
    );
  }

  getUploadedFileNamePerIndex(ef, fileIndex) {
    const filename = ef.steps.filter((s) => s.name === 'upload')[0].data
      .remote_files[fileIndex];
    if (!filename) {
      return 'Missing path';
    }
    return filename.replace(/^.*[\\/]/, '');
  }

  filterReferences = (e) => {
    e.persist();
    this.setState((prevState) => ({
      exportDetailsFilters: {
        ...prevState.exportDetailsFilters,
        filter_references_in: e.target.value,
        page: 0,
      },
    }));
  };

  onOpenViewJSONModal = () => {
    const { exportFile } = this.props;
    this.props.actions.openViewJsonModal(exportFile);
  };

  onSearch = (givenPage) => {
    const { exportDetailsFilters } = this.state;
    const page =
      typeof givenPage === 'number' ? givenPage : exportDetailsFilters.page;
    this.props.actions.getExportFileReferenceList(this.props.exportFile, {
      ...exportDetailsFilters,
      offset: page * exportDetailsFilters.limit,
    });
  };

  searchPrev = () => {
    const { exportDetailsFilters } = this.state;
    if (exportDetailsFilters.page === 0) {
      return;
    }
    this.setState({
      exportDetailsFilters: {
        ...exportDetailsFilters,
        page: exportDetailsFilters.page - 1,
      },
    });
    this.onSearch(exportDetailsFilters.page - 1);
  };

  searchNext = () => {
    const { exportFile } = this.props;
    const { exportDetailsFilters } = this.state;
    if (
      (exportDetailsFilters.page + 1) * exportDetailsFilters.limit >=
      exportFile.references_count
    ) {
      return;
    }
    this.setState({
      exportDetailsFilters: {
        ...exportDetailsFilters,
        page: exportDetailsFilters.page + 1,
      },
    });
    this.onSearch(exportDetailsFilters.page + 1);
  };

  openReplayModal = () => {
    const { exportFile } = this.props;
    const { parameters } = exportFile;
    if (parameters.organization_id === undefined) {
      parameters.organization_id = exportFile.recipient_organization.id;
    }
    this.props.actions.openModal(parameters);
  };

  hasOneFileToDownload(ef) {
    return ef.steps.some(
      (s) =>
        s.name === 'upload' &&
        s.status === 'SUCCESS' &&
        s.data.remote_files &&
        s.data.remote_files.length > 0,
    );
  }

  isWebExport(ef) {
    return ef.steps.some((s) => s.name === 'webwrite' || s.name === 'webasync');
  }

  downloadExportedFile(efID, fileIndex, filename, stepName, stepIndex) {
    return () => {
      this.props.actions.downloadExportedFile(
        efID,
        fileIndex,
        filename,
        stepName,
        stepIndex,
      );
    };
  }

  cancelExport(efID, status) {
    return () => {
      this.props.actions.cancelExport(efID, status);
    };
  }

  renderCancelExport() {
    const { exportFile } = this.props;
    const cancelableExport = ['GDSN', 'GDSN_PRICE'];
    if (!cancelableExport.includes(exportFile.format.toUpperCase())) {
      return null;
    }
    return (
      <Button
        key={`exportfile-${exportFile.id}-search_btn`}
        secondary
        onClick={this.cancelExport(exportFile.id, !exportFile.is_canceled)}
      >
        {exportFile.is_canceled ? 'Restore export' : 'Cancel export'}
      </Button>
    );
  }

  renderUploadStepAction(step, efID, stepName, stepIndex) {
    let i = Math.ceil(Math.random() * 1000);
    if (!step.data.remote_files) {
      return <span />;
    }
    return step.data.remote_files.map((filepath, index) => {
      if (!filepath) {
        return (
          <span key="missing-file-ref" className="ExportListDetailsMissingFile">
            Missing file reference
          </span>
        );
      }
      const filename = filepath.replace(/^.*[\\/]/, '');
      i += 1;
      return (
        <div
          key={`file-${efID}-${Math.ceil(i)}`}
          data-tip
          data-for={`file-tooltip-${efID}-${Math.ceil(i)}`}
        >
          {step.data.type === 'S3' ? (
            <a
              href={step.data.remote_files[0]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="mdi mdi-download ExportListDetailsIconDoc" />
            </a>
          ) : (
            <i
              className="mdi mdi-download ExportListDetailsIconDoc"
              onClick={this.downloadExportedFile(
                efID,
                index,
                filename,
                stepName,
                stepIndex,
              )}
            />
          )}
          <Tooltip id={`file-tooltip-${efID}-${Math.ceil(i)}`} place="right">
            {filename}
          </Tooltip>
        </div>
      );
    });
  }

  renderStepAction(step, efID, stepIndex) {
    switch (step.name) {
      case 'prerequisite':
        return (
          <div>
            {this.renderUploadStepAction(step, efID, step.name, stepIndex)}
          </div>
        );
      case 'integration':
        return this.renderUploadStepAction(step, efID, step.name, stepIndex);
      case 'ack':
        return this.renderUploadStepAction(step, efID, step.name, stepIndex);
      case 'pre-integration':
        return this.renderUploadStepAction(step, efID, step.name, stepIndex);
      case 'pre-ack':
        return this.renderUploadStepAction(step, efID, step.name, stepIndex);
      case 'upload':
        return this.renderUploadStepAction(step, efID, step.name, stepIndex);
      default:
        return null;
    }
  }

  renderSources() {
    const DEFAULT_DISPLAYED_ORGANIZATION = 8;
    const { exportFile } = this.props;
    const { isOrgaExpanded } = this.state;
    const sourceOrgaCount = exportFile.source_organizations.filter(
      (o) => o.id !== exportFile.recipient_organization.id,
    ).length;
    let i = 0;
    const lines = exportFile.source_organizations
      .filter((o) => o.id !== exportFile.recipient_organization.id)
      .map((organization) => {
        if (organization.id === exportFile.recipient_organization.id) {
          return null;
        }
        if (!isOrgaExpanded && i > DEFAULT_DISPLAYED_ORGANIZATION) {
          return null;
        }
        i += 1;
        if (!isOrgaExpanded && i > DEFAULT_DISPLAYED_ORGANIZATION) {
          return (
            <div
              key={`${exportFile.id}-src-org-extend`}
              className="ExportListStepRow row ExportListIconMini"
            >
              <div
                className="offset-xs-4 col-xs-4"
                onClick={this.onClickOrgaExpand}
              >
                <span>
                  <i className="mdi mdi-plus-box" />
                  {` See ${
                    sourceOrgaCount - DEFAULT_DISPLAYED_ORGANIZATION
                  } more organizations...`}
                </span>
              </div>
            </div>
          );
        }
        return (
          <div
            key={`${exportFile.id}-src-org-${Math.ceil(i)}`}
            className="ExportListStepRow row"
          >
            <div className="col-xs-4">
              <i className="mdi mdi-domain" />
              {` ${organization.name} (${organization.id})`}
            </div>
            <div className="col-xs-4">
              {`${organization.count} / ${exportFile.references_count}`}
            </div>
          </div>
        );
      });
    if (lines.length === 0) {
      return null;
    }
    if (isOrgaExpanded && sourceOrgaCount > DEFAULT_DISPLAYED_ORGANIZATION) {
      lines.push(
        <div
          key={`${exportFile.id}-src-org-hide}`}
          className="ExportListStepRow row ExportListIconMini"
        >
          <div
            className="offset-xs-4 col-xs-4"
            onClick={this.onClickOrgaExpand}
          >
            <span>
              <i className="mdi mdi-minus-box" />
              hide organizations...
            </span>
          </div>
        </div>,
      );
    }
    return (
      <div className="ExportListStepRowWrapper">
        <div className="ExportListStepRow ExportListStepRow__Header row">
          <div className="col-xs-4">Source Organizations</div>
          <div className="col-xs-4">Organization products / overall</div>
        </div>
        <div className="ExportListStepRow__Lines">{lines}</div>
        <div>
          <div className="offset-xs-6 col-xs-4">
            <i className="mdi mdi-owl" />
          </div>
        </div>
      </div>
    );
  }

  renderFilterRow() {
    const { exportFile } = this.props;
    const { exportDetailsFilters } = this.state;
    return (
      <div className="ExportDetailsRow__Lines ExportDetailsRow ExportDetailsRow__Search row">
        <div className="col-xs-2">{this.renderCancelExport()}</div>
        <div className="col-xs-4">
          <Text
            id={`exportfile-${exportFile.id}-reference`}
            value={exportDetailsFilters.filter_references_in}
            placeholder="GTIN / #Product identifier / :Product key id (comma separated)"
            onChange={this.filterReferences}
          />
        </div>
        <div className="col-xs-2 flex flex-align-items--center">
          <div data-tip data-for="product-key-filter-tooltip">
            <i className="mdi mdi-24px mdi-help-circle" />
            <Tooltip id="product-key-filter-tooltip" place="right">
              GTIN: just enter the number
              <br />
              Product Key: prefixed with a colon ":" (e.g. :123456)
              <br />
              Product Identifier: prefixed with a hash "#" (e.g. ::123456)
            </Tooltip>
          </div>
          <Button
            key={`exportfile-${exportFile.id}-search_btn`}
            secondary
            onClick={this.onSearch}
          >
            <i className="mdi mdi-magnify" />
          </Button>
        </div>
        <div className="col-xs-4 ExportDetailsRow_Navigation">
          <div>{`${exportDetailsFilters.page + 1} / ${
            Math.ceil(
              exportFile.references_count / exportDetailsFilters.limit,
            ) || 1
          } pages`}</div>
          <div className="ExportDetailsRow_NavigationSeparator" />
          <div className="btn-group">
            <Button
              key={`Exportfile-${exportFile.id}-search_prev`}
              secondary
              disabled={exportDetailsFilters.page === 0}
              onClick={this.searchPrev}
            >
              <i className="mdi mdi-chevron-left" />
            </Button>
            <Button
              key={`exportfile-${exportFile.id}-search_next`}
              secondary
              disabled={
                (exportDetailsFilters.page + 1) * exportDetailsFilters.limit >=
                exportFile.references_count
              }
              onClick={this.searchNext}
            >
              <i className="mdi mdi-chevron-right" />
            </Button>
          </div>
          <div className="ExportDetailsRow_NavigationSeparator" />
          <Button
            key={`exportfile-${exportFile.id}-search_btn`}
            secondary
            onClick={this.onSearch}
          >
            <i className="mdi mdi-refresh" />
          </Button>
        </div>
      </div>
    );
  }

  renderReference = () => {
    const { references, exportFile } = this.props;
    if (exportFile.references_count === 0) {
      return null;
    }
    const lines = references.get(exportFile.id, []).map((exportline) => (
      <div
        key={exportline.gtin ?? exportline.identifier}
        className="ExportDetailsRow row"
      >
        <div className="col-xs-3">{exportline.gtin ?? '-'}</div>
        <div className="col-xs-3">{exportline.identifier ?? '-'}</div>
        <div className="col-xs-4">
          {exportline.sourceOrganization.nameLegal} (
          {exportline.sourceOrganization.id})
        </div>
      </div>
    ));
    return (
      <div className="ExportDetailsRowWrapper">
        <div>
          <div className="offset-xs-6 col-xs-4">
            <i className="mdi mdi-owl" />
          </div>
        </div>
        {this.renderFilterRow()}
        <div className="ExportDetailsRow ExportDetailsRow__Header row">
          <div className="col-xs-3">GTIN</div>
          <div className="col-xs-3">Product identifier</div>
          <div className="col-xs-4">Source organization</div>
        </div>
        <div className="ExportDetailsRow__Lines">{lines}</div>
      </div>
    );
  };

  renderSteps() {
    const { exportFile } = this.props;
    const stepNameToIndex = {};
    let i = 0;
    const start = exportFile.steps.length
      ? moment(`${exportFile.steps[0].updatedAt}Z`)
      : null;
    const lines = exportFile.steps.map((step) => {
      if (step.name in stepNameToIndex) {
        stepNameToIndex[step.name] += 1;
      } else {
        stepNameToIndex[step.name] = 0;
      }
      i += 1;
      const elapsed = moment
        .utc(moment(`${step.updatedAt}Z`).diff(start))
        .format('HH:mm:ss');
      const elapsedD = moment(`${step.updatedAt}Z`).diff(start, 'days');
      return (
        <div
          key={`${exportFile.id}-${step.name}-${Math.ceil(i)}`}
          className="ExportListStepRow row"
        >
          <StatusBar type={this.getExportFileStepState(step)} />
          <div className="col-xs-2">{step.name}</div>
          <div className="col-xs-2">
            {formatTimeImplicitUTC(step.updatedAt, TIME_FORMAT_SECOND)}
          </div>
          <div className="col-xs-1">
            {elapsedD === 0 ? `+${elapsed}` : `${elapsedD}d +${elapsed}`}
          </div>
          <div className="col-xs-1">{step.status}</div>
          <div className="col-xs-4">
            {step.message && (
              <span>
                {step.message.substr(0, 64)}
                {step.message.length > 64 && (
                  <span>
                    ...
                    <i
                      className="mdi mdi-plus-box ExportListIconMini"
                      onClick={this.onOpenModal(step.message)}
                    />
                  </span>
                )}
              </span>
            )}
            {step.data.stack_trace && (
              <span>
                {' '}
                <i
                  className="mdi mdi-playlist-remove ExportListIconMini"
                  onClick={this.onOpenModal(step.data.stack_trace)}
                />
              </span>
            )}
          </div>
          <div className="col-xs-1">
            {this.renderStepAction(
              step,
              exportFile.id,
              stepNameToIndex[step.name],
            )}
          </div>
        </div>
      );
    });
    return (
      <div className="ExportListStepRowWrapper">
        <div className="ExportListStepRow ExportListStepRow__Header row">
          <div className="col-xs-2">Step name</div>
          <div className="col-xs-2">Date</div>
          <div className="col-xs-1">Elapsed</div>
          <div className="col-xs-1">Status</div>
          <div className="col-xs-4">Message</div>
        </div>
        <div className="ExportListStepRow__Lines">{lines}</div>
      </div>
    );
  }

  renderRowAction() {
    const { exportFile, userPermissions, user } = this.props;
    const { filters } = this.props;

    const canExport =
      hasFullPower(user) ||
      userPermissions.organizations.includes(PERMISSION_PRODUCT_EXPORT);
    const canViewExportFileJson = hasFullPower(user);
    const { isExpanded } = this.state;
    const expandClass = {
      ExportListIcon: true,
      mdi: true,
      'mdi-chevron-up': exportFile.steps.length > 0 && isExpanded,
      'mdi-chevron-down': exportFile.steps.length > 0 && !isExpanded,
      hidden: exportFile.steps.length === 0,
    };
    const uploadSteps = exportFile.steps.filter((s) => s.name === 'upload');
    return (
      <span>
        {(!filters.filter_ids_in || filters.filter_ids_in.length === 0) && (
          <a
            href={`${routes.ioExport}?filter_ids_in=${exportFile.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="mdi mdi-filter ExportListIcon">&nbsp;</i>
          </a>
        )}
        {canViewExportFileJson && (
          <i
            className="mdi mdi-code-braces ExportListIcon"
            onClick={this.onOpenViewJSONModal}
            title="View JSON"
          >
            &nbsp;
          </i>
        )}
        <div className="ExportListRow_RemoteFiles">
          {this.hasOneFileToDownload(exportFile) &&
            uploadSteps.map((step, index) =>
              this.renderUploadStepAction(step, exportFile.id, 'upload', index),
            )}
        </div>
        {canExport &&
          exportFile.parameters &&
          Object.keys(exportFile.parameters).length > 0 && (
            <i
              onClick={this.openReplayModal}
              className="ExportListIcon mdi mdi-sync"
            />
          )}
        <div onClick={this.onClickExpand} className={classNames(expandClass)} />
      </span>
    );
  }

  render() {
    const { exportFile } = this.props;
    const { isExpanded, isStackTraceModalOpen, stackTrace } = this.state;

    let lastStepDate = null;
    exportFile.steps.forEach((e) => {
      if (!lastStepDate || lastStepDate < e.updatedAt) {
        lastStepDate = e.updatedAt;
      }
    });
    return (
      <div className="ExportListRowWrapper">
        <div
          key={exportFile.id}
          id={exportFile.id}
          className="ExportListRow row"
        >
          <div className="col-xs-0 ExportListRow_StatusBar">
            <StatusBar type={this.getExportFileState(exportFile)} />
          </div>
          <div className="col-xs-4">
            <div className="ExportListRow_boldy">
              <i className="mdi mdi-domain" />
              <Link
                className="btn btn-link ExportButton_no_padding"
                to={fill(
                  routes.organization,
                  exportFile.recipient_organization.id,
                )}
              >
                {` ${exportFile.recipient_organization.name} (${exportFile.recipient_organization.id})`}
              </Link>
            </div>
            {this.isWebExport(exportFile) && exportFile.user && (
              <div>
                <i className="mdi mdi-account" />
                {` ${exportFile.user.firstname} ${exportFile.user.lastname} (${exportFile.user.username})`}
              </div>
            )}
          </div>
          <div className="col-xs-2">
            <span className="ExportFileListRow__tag">
              {exportFile.format.toUpperCase()}
            </span>
            {this.isWebExport(exportFile) && (
              <span className="ExportFileListRow__tag">Web</span>
            )}
          </div>
          <div className="col-xs-2">
            <i className="mdi mdi-calendar-clock" />
            <span>
              {formatTimeImplicitUTC(lastStepDate, TIME_FORMAT_MINUTE)}
            </span>
            <br />
            <i className="mdi mdi-briefcase-upload" />
            <span>
              {formatTimeImplicitUTC(exportFile.updatedAt, TIME_FORMAT_MINUTE)}
            </span>
            <br />
            <i className="mdi mdi-clock-start" />
            <span>
              {formatTimeImplicitUTC(exportFile.createdAt, TIME_FORMAT_MINUTE)}
            </span>
          </div>
          <div className="col-xs-2">
            {exportFile.references_count === 0
              ? '-'
              : `${exportFile.references_count} products`}
          </div>
          <div className="col-xs-1 ExportListDetailsIcon">
            {this.renderRowAction()}
          </div>
        </div>
        {isExpanded ? this.renderSources() : null}
        {isExpanded ? this.renderSteps() : null}
        {isExpanded ? this.renderReference() : null}
        {isStackTraceModalOpen && stackTrace && (
          <StackTraceModal
            onCloseModal={this.onCloseModal}
            stackTrace={
              typeof stackTrace === 'object'
                ? JSON.stringify(stackTrace, null, 4)
                : stackTrace
            }
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(ExportListRow);
