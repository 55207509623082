import { List, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';

import displayGroupApi from 'resources/displayGroupApi';
import { get, getIn } from 'utils/immutable';

import './display-group-picker-modal.scss';

export default class DisplayGroupPickerModal extends PureComponent {
  static propTypes = {
    link: PropTypes.object,
    fieldId: PropTypes.number,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
  };

  state = {
    displayGroupLinks: null,
    currentPath: [],
    currentLink: null,
  };

  componentDidMount() {
    const { link } = this.props;
    displayGroupApi.listAdmin().then((response) => {
      const displayGroupLinks = fromJS([
        {
          id: 0,
          label: 'Select a display group',
          children: response.data.data,
        },
      ]);
      const currentLink = link;
      let currentPath = [];
      // Identify the current path.
      if (link) {
        // Recursive function to find the proper display group.
        const selectedDisplayGroupId = getIn(link, ['displayGroup', 'id']);
        const findCurrentPath = (dg, path = []) => {
          if (get(dg, 'id') === selectedDisplayGroupId) {
            return path;
          }
          return (get(dg, 'children') || List())
            .map((child, index) =>
              findCurrentPath(child, [...path, 'children', `${index}`]),
            )
            .filter((p) => !!p)
            .first();
        };
        currentPath = findCurrentPath(displayGroupLinks.first()) || [];
      }
      this.setState({
        displayGroupLinks,
        currentPath,
        currentLink,
      });
    });
  }

  onBack = () => {
    const { currentPath } = this.state;
    // path is of form 'children.i.children.j...'.
    // To get the previous path, simply exclude the last 2 items.
    this.setState({
      currentPath: currentPath.slice(0, currentPath.length - 2),
      currentLink: null,
    });
  };

  onSelectDisplayGroup = (index) => () => {
    // Move down into a display group.
    const { currentPath } = this.state;
    this.setState({
      currentPath: [...currentPath, 'children', `${index}`],
      currentLink: null,
    });
  };

  onSelectPosition = (rank) => () => {
    // Select a position in a display group.
    const currentDisplayGroup = this.getCurrentDisplayGroup();
    this.setState({
      currentLink: {
        displayGroup: {
          id: get(currentDisplayGroup, 'id'),
          label: get(currentDisplayGroup, 'label'),
        },
        rank,
      },
    });
  };

  onSave = () => {
    this.props.save(this.state.currentLink);
  };

  getCurrentDisplayGroup() {
    const { currentPath, displayGroupLinks } = this.state;
    if (!displayGroupLinks || !displayGroupLinks.size) {
      return null;
    }
    const baseDisplayGroup = displayGroupLinks.first();
    if (!currentPath.length) {
      return baseDisplayGroup;
    }
    return getIn(baseDisplayGroup, currentPath);
  }

  getTitle() {
    const currentDisplayGroup = this.getCurrentDisplayGroup();
    if (!currentDisplayGroup) {
      return 'Loading...';
    }
    return get(currentDisplayGroup, 'label');
  }

  renderBody() {
    const { displayGroupLinks } = this.state;
    if (!displayGroupLinks) {
      return (
        <div
          className="DisplayGroupPickerModal__items"
          data-testid="dgpm-items"
        />
      );
    }
    const displayGroup = this.getCurrentDisplayGroup();
    // Render display groups, first placeholder and fields.
    return (
      <div className="DisplayGroupPickerModal__items" data-testid="dgpm-items">
        {(get(displayGroup, 'children') || []).map(this.renderDisplayGroup)}
        {this.renderFirstPlaceholder(displayGroup)}
        {(get(displayGroup, 'fields') || []).map(this.renderFieldLink)}
      </div>
    );
  }

  renderDisplayGroup = (displayGroup, index) => (
    <div
      className="DisplayGroupPickerModal__displayGroup"
      key={`group-${get(displayGroup, 'id')}`}
      onClick={this.onSelectDisplayGroup(index)}
      data-testid="dgpm-display-group"
    >
      {get(displayGroup, 'label')}
      <i className="mdi mdi-chevron-right" />
    </div>
  );

  renderFieldLink = (link) => {
    const { fieldId } = this.props;
    // Do not display the current field.
    if (fieldId === getIn(link, ['field', 'id'])) {
      return null;
    }
    const { currentLink } = this.state;
    const rank = get(link, 'rank');
    const selected =
      !!currentLink &&
      rank < get(currentLink, 'rank') &&
      get(currentLink, 'rank') <= rank + 100;
    return (
      <div
        className="DisplayGroupPickerModal__link"
        key={`link-${getIn(link, ['field', 'id'])}`}
        data-testid="dgpm-link"
      >
        {getIn(link, ['field', 'name'])}
        <div className="DisplayGroupPickerModal__linkButton">
          <Button
            content="After"
            primary={selected}
            secondary={!selected}
            onClick={this.onSelectPosition(rank + 50)}
          />
        </div>
      </div>
    );
  };

  renderFirstPlaceholder(displayGroup) {
    const { currentLink } = this.state;
    const selected = !!currentLink && get(currentLink, 'rank') <= 100;
    if (!get(displayGroup, 'id')) {
      return null;
    }
    return (
      <div
        className="DisplayGroupPickerModal__firstPlaceholder"
        key="first-placeholder"
        data-testid="dgpm-first-placeholder"
      >
        <Button
          content="Place first"
          primary={selected}
          secondary={!selected}
          onClick={this.onSelectPosition(0)}
        />
      </div>
    );
  }

  renderBackButton() {
    const { currentPath } = this.state;
    if (currentPath.length > 0) {
      return (
        <Button link onClick={this.onBack}>
          Back
        </Button>
      );
    } else {
      return null;
    }
  }

  render() {
    const { cancel } = this.props;
    const { currentLink, displayGroupLinks } = this.state;
    return (
      <Modal
        title={this.getTitle()}
        hideFooter={!displayGroupLinks}
        confirmButtonText="Save"
        confirmDisabled={!currentLink}
        onConfirm={this.onSave}
        onClose={cancel}
        additionalFooterContent={this.renderBackButton()}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}
