import qs from 'querystringify';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import translationApi from 'resources/translationApi';
import { logError } from 'utils';
import { requestWithHeaders } from 'utils/api';

import * as actions from './actions';
import * as events from './events';
import { selectPagination, selectSelectedFilters } from './selectors';

export default function* mainSaga() {
  yield takeEvery(events.FETCH_FILTERS, fetchFilters);
  yield takeEvery(
    [
      events.FETCH_FILES,
      events.UPDATE_SELECTED_CURRENT,
      events.UPDATE_SELECTED_FILENAME,
      events.UPDATE_SELECTED_LANG,
      events.NEXT_FILES_PAGE,
      events.PREVIOUS_FILES_PAGE,
      events.CHANGE_FILES_PAGE_SIZE,
    ],
    fetchFiles,
  );
  yield takeEvery(events.UPDATE_LATEST_FILE, updateLatest);
  yield takeEvery(events.FETCH_ONE_FILE, fetchOneFile);
  yield takeEvery(events.DOWNLOAD_ONE_TRANSLATION, downloadOneFile);
  yield takeEvery(events.DOWNLOAD_ALL_TRANSLATIONS, downloadAllFiles);
}

function* fetchOneFile({ fileID, filename }) {
  const { result, error } = yield call(
    requestWithHeaders,
    translationApi,
    'get',
    `/translation/files/v1/${fileID}/download`,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to download file'));
  } else {
    yield put(
      actions.fetchedOneFile({
        name: filename,
        content: result.data,
      }),
    );
  }
}

function* downloadOneFile({ fileID, filename }) {
  const { result, error } = yield call(
    requestWithHeaders,
    translationApi,
    'getBlob',
    `/translation/files/v1/${fileID}/download-raw`,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to download file'));
  } else {
    (window as any).saveAs(result.data, filename);
  }
}

function* downloadAllFiles() {
  const { result, error } = yield call(
    requestWithHeaders,
    translationApi,
    'getBlob',
    `/translation/files/v1/download-full`,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to download files'));
  } else {
    (window as any).saveAs(result.data, 'locale.zip');
  }
}

function* fetchFilters() {
  const { result, error } = yield call(
    requestWithHeaders,
    translationApi,
    'get',
    '/translation/files/v1/filters',
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load filters'));
  } else {
    yield put({
      type: events.RECEIVE_FILTERS,
      payload: result.data,
    });
  }
}

function* buildListFilesParams() {
  const selectedFilters = yield select(selectSelectedFilters);
  const pagination = yield select(selectPagination);
  const params: any = {};
  if (selectedFilters.current !== null) {
    params.current = selectedFilters.current;
  }
  if (selectedFilters.filename !== null) {
    params.filename = selectedFilters.filename;
  }
  if (selectedFilters.lang !== null) {
    params.lang = selectedFilters.lang;
  }
  params.page = pagination.page;
  params.size = pagination.size;
  return params;
}

function* fetchFiles() {
  const params = yield buildListFilesParams();
  const { result, error } = yield call(
    requestWithHeaders,
    translationApi,
    'get',
    '/translation/files/v1/list',
    qs.stringify(params, true),
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load files'));
  } else {
    yield put({
      type: events.RECEIVE_FILES,
      payload: result.data,
    });
  }
}

function* updateLatest(payload) {
  const file_id = payload.fileID;
  const { error } = yield call(
    requestWithHeaders,
    translationApi,
    'put',
    `/translation/files/v1/${file_id}/current`,
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to update latest'));
  } else {
    yield put(notificationSuccess('Successfully updated latest'));
  }
  yield* fetchFiles();
}
