import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';

import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';

import { triggerReview } from './api';
import './index.scss';
import { ProductReviewView } from './view';

const ProductReview = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(
    queryParams.get('product_key_id') || '',
  );
  const [lastRefresh, setLastRefresh] = useState(Date.now());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchQuery) {
      navigate({ search: `product_key_id=${searchQuery}` });
    }
  }, [searchQuery, navigate]);

  const onReviewTrigger = async () => {
    setLoading(true);
    await triggerReview(searchQuery);
    await new Promise((r) => setTimeout(r, 5000));
    setLastRefresh(Date.now());
    setLoading(false);
  };

  return (
    <div>
      <HeaderLayout
        title="Product Review"
        backHref={routes.home}
        backMessage="Back home"
      >
        <div className="ProductReview__Searchbar">
          <Search
            query={searchQuery}
            placeholder="Search for a product key"
            updateSearchQuery={(search: string) => {
              setSearchQuery(search);
            }}
          />
          <Button
            primary
            disabled={loading}
            displaySpinner={loading}
            onClick={onReviewTrigger}
            content="Review trigger"
          />
        </div>
      </HeaderLayout>
      <ProductReviewView searchQuery={searchQuery} lastRefresh={lastRefresh} />
    </div>
  );
};

export { ProductReview };
