import { flow, get, getOr, isEmpty, keys, negate } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY } from '../constants';
import { SettingOrganizationState } from '../types';

const selectState: (globalState: any) => SettingOrganizationState = get([
  MODULE_REDUCER_KEY,
  REDUCER_KEY,
]);

export const selectSettings = flow(selectState, (state) => state.settings);

export const selectSettingChanges = flow(selectState, getOr({}, 'changes'));

export const selectOrganizationId = flow(
  selectState,
  (state) => state.organizationId,
);

export const selectHasSettingFlagChanges = flow(
  selectSettingChanges,
  keys,
  negate(isEmpty),
);
