import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { SET_IMPORT_MAPPING_PROPERTY } from '../../../actions/constants';
import {
  ImportMapping,
  ImportMappingDetails,
  ImportMappingDetailsFallback,
} from '../../../types';
import { getUpdatedImportMapping } from '../../../utils';

import { ImportMappingsItemFallbackProperty } from './fallback';
import './item-fallback-properties.scss';

interface Props {
  details: ImportMappingDetails;
  fieldName: string;
  importMapping: ImportMapping;
  parentNames?: string[];
  parents?: ImportMappingDetails[];
}
export function ImportMappingsItemFallbackProperties({
  details,
  fieldName,
  importMapping,
  parentNames = [],
  parents = [],
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const [fallbacks, setFallbacks] = useState(details.fallback);
  const addFallback = () => {
    const newFallbacks = [...fallbacks];
    newFallbacks.push({
      index:
        fallbacks.length === 0 ? 0 : fallbacks[fallbacks.length - 1].index + 1,
      xpath: [''],
    });
    setFallbacks(newFallbacks);

    const updatedImportMapping = getUpdatedImportMapping({
      details,
      fieldName,
      importMapping,
      parentNames,
      parents,
      properties: [{ name: 'fallback', value: newFallbacks }],
    });

    dispatch({
      type: SET_IMPORT_MAPPING_PROPERTY,
      payload: {
        importMapping: { ...updatedImportMapping },
      },
    });
  };

  const dispatchImportMappingFallback = (
    fallback: ImportMappingDetailsFallback,
  ) => {
    const newFallbacks = [...fallbacks];
    newFallbacks[fallback.index] = fallback;
    setFallbacks(newFallbacks);
    const updatedImportMapping = getUpdatedImportMapping({
      details,
      fieldName,
      importMapping,
      parentNames,
      parents,
      properties: [{ name: 'fallback', value: newFallbacks }],
    });
    dispatch({
      type: SET_IMPORT_MAPPING_PROPERTY,
      payload: {
        importMapping: { ...updatedImportMapping },
      },
    });
  };

  return (
    <div
      data-testid="GDSNImportMappingsItem_fallbackProperties"
      className="GDSNImportMappingsItemFallbackProperties"
    >
      <div className="GDSNImportMappingsItemFallbackProperties__sectionTitle">
        Fallback properties (optional)
      </div>
      {fallbacks.map((fallback) => (
        <ImportMappingsItemFallbackProperty
          key={fallback.index}
          dispatchImportMappingFallback={dispatchImportMappingFallback}
          details={details}
          fallback={fallback}
          fieldName={fieldName}
          parents={parents}
        />
      ))}
      <div className="GDSNImportMappingsItemFallbackProperties__buttonContainer">
        <Button
          testid="GDSNImportMappingsItemFallbackProperties__button"
          content="Add fallback"
          onClick={addFallback}
          secondary
        />
      </div>
    </div>
  );
}
