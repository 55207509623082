import { createReducer } from 'redux-create-reducer';

import * as events from './events';

const getInitialState = () => ({
  versions: null,
  totalResults: null,
  limit: 50,
  currentPage: 1,
});

export default createReducer(getInitialState(), {
  [events.RETAILER_PICTURE_COMPARATOR_RECEIVE_VERSIONS]: (
    state,
    { versions, totalResults },
  ) => ({
    ...state,
    versions,
    totalResults,
  }),
  [events.RETAILER_PICTURE_COMPARATOR_SET_LIMIT]: (state, { limit }) => ({
    ...state,
    limit,
    currentPage: 1,
  }),
  [events.RETAILER_PICTURE_COMPARATOR_NEXT_PAGE]: (state) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [events.RETAILER_PICTURE_COMPARATOR_PREVIOUS_PAGE]: (state) => ({
    ...state,
    currentPage: state.currentPage - 1,
  }),
});
