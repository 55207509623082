import { navigate } from 'utils/location';

export const home = {
  title: 'Go home',
  command: 'home',
  action: () => {
    navigate({ to: '/' });
  },
  actionNewTab: () => {
    window.open('/', '_blank')?.focus();
  },
};

export const clear = {
  title: 'Clear all the results',
  command: 'clear',
};

export const histoire = {
  title: 'Display the history of the command typed in',
  command: 'history',
};

export const help = {
  title: 'basic commands information',
  help: 'usage: "show basic key commands available"',
  command: 'help',
  render: () => (
    <div>
      <p>
        [entity] being the category you want to search for
        <br />
        [entity] : redirect you to the entity page
        <br />
        [entity] goto id:[X] : redirect you to the page of the entity
        <br />
        [entity] show id:[X] : show basic info for the entity
        <br />
        [entity] what : describe entity
      </p>
    </div>
  ),
};

export const commands = [home, clear, histoire, help];
