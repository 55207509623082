import { isEmpty, pick } from 'lodash/fp';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddButton, Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';

import { CodeEditor } from 'components/code-editor';
import { ShowMore } from 'components/show-more';
import { DispatchType } from 'types/redux';

import { JsonText } from '../../../../components/ui/input/json';
import {
  createOrganizationSSO,
  deleteOrganizationSSO,
  loadOrganizationSSO,
  updateOrganizationSSO,
} from '../../actions/sso';
import { selectIsLoading, selectSSOConfiguration } from '../../selectors/sso';
import { OrganizationSSOConfiguration } from '../../types';

import { OrganizationSSOForm } from './form';
import './index.scss';

const FIELDS_IN_PREVIEW = [
  'sp_data',
  'security_data',
  'idp_data',
  'idp_metadata_url',
  'strict',
  'debug',
];

interface Props {
  organizationId: number;
  isReadOnly: boolean;
}

export const OrganizationSSO = ({ organizationId, isReadOnly }: Props) => {
  const dispatch: DispatchType = useDispatch();
  const currentSSOConfiguration = useSelector(selectSSOConfiguration);
  const [ssoConfiguration, setSSOConfiguration] = useState<
    Partial<OrganizationSSOConfiguration>
  >({});
  const isLoading = useSelector(selectIsLoading);
  const [hasDeleteModal, toggleDeleteModal] = useState(false);
  const [hasUpsertModal, toggleUpsertModal] = useState(false);
  const hasSSO = useMemo(
    () => !isEmpty(currentSSOConfiguration),
    [currentSSOConfiguration],
  );

  useEffect(() => {
    dispatch(loadOrganizationSSO({ organizationId }));
  }, [dispatch, organizationId]);

  useEffect(() => {
    setSSOConfiguration(currentSSOConfiguration || {});
  }, [currentSSOConfiguration]);

  const onDeleteSetup = async () => {
    await dispatch(deleteOrganizationSSO({ organizationId }));
    toggleDeleteModal(false);
  };

  const onUpsertSetup = async () => {
    if (!ssoConfiguration) {
      return;
    }
    if (!hasSSO) {
      await dispatch(
        createOrganizationSSO({
          organizationId,
          ssoConfiguration: ssoConfiguration as OrganizationSSOConfiguration,
        }),
      );
    } else {
      await dispatch(
        updateOrganizationSSO({
          organizationId,
          ssoConfiguration: ssoConfiguration as OrganizationSSOConfiguration,
        }),
      );
    }
    toggleUpsertModal(false);
  };

  return (
    <div className="OrganizationSSO OrganizationPageBlock">
      <div className="OrganizationPageBlock__header">
        <h2>SAML Configuration</h2>
        {!isReadOnly && hasSSO && (
          <div className="text-align--right">
            <Button secondary onClick={() => toggleUpsertModal(true)}>
              Edit
            </Button>
            <Button primary warning onClick={() => toggleDeleteModal(true)}>
              Delete
            </Button>
          </div>
        )}
      </div>
      {hasSSO && (
        <ShowMore label="preview">
          <CodeEditor
            name="sso"
            value={JSON.stringify(
              pick(FIELDS_IN_PREVIEW, currentSSOConfiguration),
              null,
              2,
            )}
            readOnly
            showGutter={false}
            showPrintMargin={false}
            editorProps={{ $blockScrolling: Infinity }}
            mode="json"
            width="100%"
            height="250px"
          />
        </ShowMore>
      )}
      {!isReadOnly && !hasSSO && (
        <AddButton onClick={() => toggleUpsertModal(true)}>Create</AddButton>
      )}
      {hasUpsertModal && (
        <Modal
          title="SSO Configuration"
          modalStyle="large"
          confirmButtonText={hasSSO ? 'Update' : 'Create'}
          isProcessing={isLoading}
          onConfirm={onUpsertSetup}
          onClose={() => toggleUpsertModal(false)}
          className="OrganizationSSO__modal"
        >
          <OrganizationSSOForm
            configuration={ssoConfiguration}
            onChange={setSSOConfiguration}
          />
        </Modal>
      )}
      {hasDeleteModal && (
        <Modal
          title="Delete SSO Configuration ?"
          modalStyle="large"
          confirmButtonText={'Delete'}
          isProcessing={isLoading}
          onConfirm={onDeleteSetup}
          onClose={() => toggleDeleteModal(false)}
          className="OrganizationSSO__modal"
          danger
        >
          <JsonText
            id="sso"
            defaultValue={currentSSOConfiguration}
            readOnly
            showGutter={false}
            showPrintMargin={false}
            editorProps={{ $blockScrolling: Infinity }}
            mode="json"
            width="initial"
            height="100%"
          />
        </Modal>
      )}
    </div>
  );
};
