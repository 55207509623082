import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { SimpleSelect } from '@alkem/react-ui-select';

import Copyable from 'components/copyable';

import './right-panel-header.scss';

export default class RightPanelHeader extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.string,
    status: PropTypes.bool,
    versions: PropTypes.array,
    currentVersion: PropTypes.object,
    selectVersion: PropTypes.func,
  };

  render() {
    const { id, name, label, status, versions, currentVersion } = this.props;
    return (
      <div className="RightPanelHeader">
        <div className="RightPanelHeader__label">
          <span>{label} </span>
          <code>
            <Copyable text={name} />
          </code>
          <span>({id})</span>
          {!status && (
            <span className="RightPanelHeader__status">MAPPING MISSING</span>
          )}
        </div>
        <div className="RightPanelHeader__actions">
          {versions && (
            <SimpleSelect
              id="right-panel-options"
              options={versions}
              value={currentVersion}
              onSelect={this.props.selectVersion}
              autoSize
            />
          )}
        </div>
      </div>
    );
  }
}
