import Immutable from 'immutable';
import React, { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import EmptyState from '@alkem/react-ui-empty-state';
import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { Spinner } from '@alkem/react-ui-spinner';
import { ResponseWithBody } from '@alkem/sdk-dashboard';

import { notificationError } from 'actions/notifications';
import { DiffEditor } from 'components/code-editor';
import { ErrorFallback } from 'components/error/ErrorFallback';
import { filterOnKeys } from 'components/payload-modal/utils';
import { closeDiffModal } from 'modules/transaction/actions';
import { selectState } from 'modules/transaction/reducer';
import coreApi from 'resources/coreBaseApi';
import { logError } from 'utils';
import { getIn, toJsIfImmutable } from 'utils/immutable';

import { HistoryDiffHeader } from './diff/header';
import { HistoryDiffLine } from './diff/line';
import './diffModal.scss';

export default function DiffModal() {
  const dispatch = useDispatch();

  const show = useSelector<any, boolean>(
    (state) => selectState(state).diffModal.show,
  );
  const content = useSelector<any, Immutable.Map<string, any>>(
    (state) => selectState(state).diffModal.payload,
  );
  const dataType = useSelector<any, string>(
    (state) => selectState(state).diffModal.dataType,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [diffs, setDiffs] = useState<any[]>([]);
  const [unsupportedDataType, setUnsupportedDataType] = useState<
    string | undefined
  >();
  const [keyFilter, setKeyFilter] = useState('');

  const [oldContent, newContent] = useMemo(
    () => [
      toJsIfImmutable(getIn(content, ['old'])),
      toJsIfImmutable(getIn(content, ['new'])),
    ],
    [content],
  );

  useEffect(() => {
    if (!oldContent || !newContent) {
      setUnsupportedDataType(undefined);
      setDiffs([]);
      setIsLoading(false);
      return;
    }

    if (dataType === 'ProductVersion') {
      const body = {
        user_id: 1, // not used
        current: oldContent,
        request: newContent,
      };

      body.current.isConsumerUnit = body.current.isConsumerUnit || true;
      body.request.isConsumerUnit = body.request.isConsumerUnit || true;

      setUnsupportedDataType(undefined);
      setDiffs([]);
      setIsLoading(true);
      fetchDiff(body);
    } else {
      setUnsupportedDataType(dataType);
      setDiffs([]);
      setIsLoading(false);
    }

    async function fetchDiff(body) {
      try {
        const response: ResponseWithBody<any[]> = await coreApi.post(
          '/core/v4/service-product/productversion/diff',
          body,
        );
        setUnsupportedDataType(undefined);
        setDiffs(response.data?.map((d) => Object.assign(d, { uuid: uuid() })));
        setIsLoading(false);
      } catch (error) {
        logError(error);
        dispatch(closeDiffModal());
        dispatch(
          notificationError(
            getIn(error, ['data', 'message']) ||
              getIn(error, ['message']) ||
              'Fetching product version diff failed',
          ),
        );
      }
    }
  }, [dataType, dispatch, newContent, oldContent]);

  if (!show) {
    return null;
  }

  let body: React.ReactNode = null;
  if (isLoading) {
    body = (
      <div className="TransactionList__spinner">
        <Spinner loading big />
      </div>
    );
  } else if (unsupportedDataType !== undefined) {
    const oldData = JSON.stringify(
      filterOnKeys(oldContent, keyFilter),
      null,
      '\t',
    );
    const newData = JSON.stringify(
      filterOnKeys(newContent, keyFilter),
      null,
      '\t',
    );
    body = (
      <div className="TransactionDiffModal__rawDiff">
        <Text
          id="diff-modal-key-filter"
          value={keyFilter}
          placeholder="Search on the keys"
          onChange={(event) => {
            setKeyFilter(event.target.value);
          }}
        />
        <DiffEditor
          name="transaction-diff"
          className="TransactionDiffModal__rawDiffPayload"
          value={[oldData, newData]}
          readOnly
          mode="json"
          theme="monokai"
          showGutter={false}
          width="inital"
          height="inital"
          editorProps={{ $blockScrolling: Infinity }}
        />
      </div>
    );
  } else if (!isLoading && diffs.length === 0) {
    body = (
      <EmptyState
        title="No diff"
        text="No diff could be computed for this payload"
      />
    );
  } else {
    body = (
      <div>
        <HistoryDiffHeader />
        {diffs.map((item) => (
          <HistoryDiffLine key={item.uuid} diff={item} />
        ))}
      </div>
    );
  }

  return (
    <Modal
      title="Diff"
      className="TransactionDiffModal"
      modalStyle="dynamic"
      onClose={() => dispatch(closeDiffModal())}
      hideFooter
    >
      <ErrorBoundary
        FallbackComponent={({ error }) => <ErrorFallback error={error} />}
      >
        {body}
      </ErrorBoundary>
    </Modal>
  );
}
