import jp from 'jsonpath/jsonpath.min';
import { isArray, isObject } from 'lodash';

import { logError } from 'utils';
import { set } from 'utils/immutable';

export const matchesQuery = (
  key: string,
  value: object | any[],
  keys?: string[] | null,
) => {
  if (!keys || keys.length === 0) {
    return true;
  }

  let nextKeys = keys;
  let matches = false;
  if (key.toLowerCase().startsWith(keys[0].toLowerCase())) {
    nextKeys = keys.slice(1);
    matches = true;
  }

  if (isObject(value)) {
    matches =
      matches ||
      Object.entries(value as Record<string, any>).some(([k, v]) =>
        matchesQuery(k, v, nextKeys),
      );
  } else if (isArray(value)) {
    matches =
      matches ||
      (value as any[]).some((v, k) => matchesQuery(k.toString(), v, nextKeys));
  }

  return matches;
};

const joinPath = (path: string | null, next: string): string =>
  path ? `${path}.${next}` : `${next}`;

export const filterTree = (value: object | any[], keys?: string[] | null) => {
  const flags = {};
  const visit = (k, v, ks, path) => {
    let matches = false;
    let nextKeys = ks;
    if (!ks || ks.length === 0) {
      matches = true;
    } else if (
      ks.length > 0 &&
      k.toLowerCase().startsWith(ks[0].toLowerCase())
    ) {
      nextKeys = ks.slice(1);
      matches = true;
    }

    if (isObject(v)) {
      matches =
        Object.entries(v)
          .map(([kk, vv]) => visit(kk, vv, nextKeys, `${path}.${kk}`))
          .some((x) => x) || matches;
    } else if (isArray(v)) {
      matches =
        v
          .map((vv, kk) => visit(kk.toString(), vv, nextKeys, `${path}.${kk}`))
          .some((x) => x) || matches;
    }

    flags[path] = matches;
    return matches;
  };

  if (isObject(value)) {
    Object.entries(value).forEach(([k, v]) => {
      visit(k, v, keys || [], k);
    });
  } else if (isArray(value)) {
    (value as any[]).forEach((v, k) => {
      visit(k.toString(), v, keys || [], k);
    });
  }

  return flags;
};

export const filterOnKeys = (
  value: object | any[],
  keyFilter: string,
  path?: string,
) => {
  if (keyFilter.startsWith('$')) {
    try {
      return jp.query(value, keyFilter);
    } catch (e) {
      logError(e);
      return value;
    }
  }

  const keys = keyFilter.split('.');
  const flags = filterTree(value, keys);

  const filter = (vv, p) => {
    if (isArray(vv)) {
      return (vv as any[])
        .filter((_, k) => flags[joinPath(p, k.toString())])
        .map((v, k) => filter(v, joinPath(p, k.toString())));
    }

    if (isObject(vv)) {
      return Object.entries(vv as object)
        .filter(([k]) => flags[joinPath(p, k.toString())])
        .reduce((acc, [k, v]) => set(acc, k, v), {});
    }

    return vv;
  };

  return filter(value, path);
};
