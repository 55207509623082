import { CallTrace } from '../type';

import './rule-applier-call-detail.scss';

type ElementProps = {
  callCount: number;
  callDetails: CallTrace;
  outcome: any;
};

export const RuleApplierCallDetail = ({
  callCount,
  callDetails,
  outcome,
}: ElementProps) => {
  if (!callDetails) {
    return (
      <div className="RuleApplierCallDetail">
        <h6>Call Execution Details</h6>
        <ul className="RuleApplierCallDetail--none">
          <li>There is nothing to show for now.</li>
          <li>
            Navigate using the tree on your left to understand how the execution
            has been performed.
          </li>
          <li>Select an entry to display specific execution details.</li>
        </ul>
      </div>
    );
  }

  const interrupted: boolean = !!callDetails.raise_flag;
  const status: string = interrupted
    ? 'RuleApplierCallDetail--interrupted'
    : 'RuleApplierCallDetail--completed';

  const getResolutionOfCall = (callEntry: CallTrace) => {
    if (callEntry.raise_flag) {
      return (
        <div
          data-testid={'RuleApplierCallDetail__Parameters--empty'}
          className={'RuleApplierCallDetail__Parameters--empty'}
        >
          &empty;
        </div>
      );
    }
    return (
      <code className="RuleApplierCallDetail__Code">
        {JSON.stringify(callEntry.outcome)}
      </code>
    );
  };
  const getSubExpressionWithResult = (callEntry: CallTrace) => {
    return (
      <ul
        className="RuleApplierCallDetail__Parameters"
        data-testid={'ParametersDetailsList'}
      >
        {callEntry.children.map((element) => (
          <li
            key={`${callDetails.item}__${callDetails.seq_no}--${element.seq_no}`}
            data-testid={`${callDetails.item}__${callDetails.seq_no}--${element.seq_no}`}
          >
            <code className="RuleApplierCallDetail__Code">
              {element.expression}
            </code>{' '}
            &rArr; {getResolutionOfCall(element)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="RuleApplierCallDetail">
      <h6>
        Call #{callDetails.seq_no} / {callCount}
      </h6>
      <ul className={status}>
        <li>
          Expression{' '}
          <code className="RuleApplierCallDetail__Code">
            {callDetails.expression}
          </code>
        </li>
        {!!callDetails.children.length && (
          <li>
            This evaluation requires evaluation of
            <code className="RuleApplierCallDetail__Code">
              {callDetails.item}
            </code>{' '}
            (classification:{' '}
            <code className="RuleApplierCallDetail__Code">
              {callDetails.typename}
            </code>
            ) along with <b>{callDetails.children.length}</b>{' '}
            {callDetails.children.length === 1 ? 'parameter' : 'parameters'}:
            {getSubExpressionWithResult(callDetails)}
          </li>
        )}
        {!callDetails.children.length && (
          <li>
            Nothing more required to evaluate{' '}
            <code className="RuleApplierCallDetail__Code">
              {callDetails.item}
            </code>{' '}
            (classification:
            <code className="RuleApplierCallDetail__Code">
              {callDetails.typename}
            </code>
            )
          </li>
        )}
        {interrupted && (
          <>
            <li>
              This call has been interrupted{' '}
              {!!callDetails.children.length && (
                <>(one of the arguments / parameters cannot be evaluated)</>
              )}
              :
              <ul>
                <li>
                  Error:{' '}
                  <code className="RuleApplierCallDetail__Code">
                    {outcome.interruption.error_type}
                  </code>
                </li>
                <li>
                  <code>{outcome.interruption.message}</code>
                </li>
              </ul>
            </li>
          </>
        )}
        {!interrupted && (
          <>
            <li>
              Outcome:{' '}
              <code className="RuleApplierCallDetail__Code">
                {JSON.stringify(callDetails.outcome)}
              </code>
            </li>
          </>
        )}
        <li className="RuleApplierCallDetail__Duration">
          Done in {callDetails.duration} µ-seconds
        </li>
      </ul>
    </div>
  );
};
