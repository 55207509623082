import proptypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

export function SearchBar({
  handleKeyDown,
  handleChange,
  commandText,
  suggestedCommand,
}) {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  });

  return (
    <div className="CommandPalette__SearchBarInput">
      <input
        ref={inputRef}
        type="text"
        placeholder="  example commands: goto organizations, organization show 34, validation show rule 34"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={commandText}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length,
          )
        }
      />
      <input
        className="CommandPalette__AutoCompleteSearchBarInput"
        type="text"
        value={suggestedCommand}
        readOnly
      />
    </div>
  );
}

SearchBar.propTypes = {
  handleChange: proptypes.func,
  handleKeyDown: proptypes.func,
  commandText: proptypes.string,
  suggestedCommand: proptypes.string,
};
