import PropTypes from 'prop-types';
import qs from 'querystringify';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { HeaderLayout } from '@alkem/react-layout';
import { Button, SplitButton } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';

import { notificationError } from 'actions/notifications';
import { CodeEditor } from 'components/code-editor';
import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { withLocation } from 'hocs/with-location';
import { withNavigate } from 'hocs/with-navigate';
import api from 'resources/coreBaseApi';

import './rabbitmq.scss';

const mapDispatchToProps = {
  notifyError: notificationError,
};

class Rabbitmq extends PureComponent {
  static propTypes = {
    notifyError: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  state = {
    q: null,
    queues: [],
    replaying: {},
    purging: {},
    downloading: {},
    isModalOpen: false,
    modalTitle: '',
    purgeWithRegexp: {
      queue: null,
      count: 0,
      regexp: '',
      isPurging: false,
    },
    messages: [],
  };

  onClickReplay = (queue) => () => this.replay(queue);

  onClickPurging = (queue) => () => this.purge(queue);

  onClickDownload = (queue) => () => this.download(queue);

  onClickView = (queue) => () => this.showMessages(queue);

  componentDidMount() {
    const { q } = qs.parse(this.props.location.search);
    this.setState({ searchQuery: q });
    this.fetchQueues();
  }

  updateSearchQuery = async (query) => {
    this.setState({ searchQuery: query });
    const queryString = query ? qs.stringify({ q: query }) : null;
    this.props.navigate({ search: queryString });
  };

  filteredQueues() {
    const { searchQuery, queues } = this.state;
    return queues.filter((queue) => queue.name.match(searchQuery));
  }

  getPrefix() {
    if (window.env === 'prod') {
      return 'https://rmq.internal.alkemics.com/#/queues/%2F';
    } else if (window.env === 'ltg') {
      return 'http://rmq.ltg.alkemics.com/#/queues/%2F';
    }
    return 'https://ppr-rmq.internal.alkemics.com/#/queues/%2F';
  }

  fetchQueues = async () => {
    try {
      const res = await api.get('/rabbitmq/queues');
      this.setState({ queues: res.data.data });
    } catch (err) {
      this.props.notifyError(
        'could not fetch the errors from the queues. Have a look at your network and console',
      );
      console.error(err); // eslint-disable-line no-console
    }
  };

  replay = async (queue) => {
    const { replaying, queues } = this.state;

    const before = { ...replaying };
    before[queue] = true;
    this.setState({ replaying: before });

    try {
      await api.post('/rabbitmq/replay', { queue });
    } catch (err) {
      this.props.notifyError(
        'could not replay the errors. Have a look at your network and console',
      );
      console.error(err); // eslint-disable-line no-console
    }

    const after = { ...before };
    delete after[queue];

    const queuesAfter = queues.filter((q) => q.name !== queue);
    this.setState({ replaying: after, queues: queuesAfter });
  };

  purge = async (queue) => {
    if (!window.confirm('Are you sure to want to do this ?')) {
      return;
    }
    const { purging, queues } = this.state;

    const before = { ...purging };
    before[queue] = true;
    this.setState({ purging: before });

    try {
      await api.post(`/rabbitmq/queues/${queue}/purge`, {});
    } catch (err) {
      this.props.notifyError(
        'could not purging the errors. Have a look at your network and console',
      );
      console.error(err); // eslint-disable-line no-console
    }

    const after = { ...before };
    delete after[queue];

    const queuesAfter = queues.filter((q) => q.name !== queue);
    this.setState({ purging: after, queues: queuesAfter });
  };

  download = async (queue) => {
    const { downloading } = this.state;

    const before = { ...downloading };
    before[queue] = true;
    this.setState({ downloading: before });

    try {
      const result = await api.getBlob(`/rabbitmq/download/${queue}`);
      window.saveAs(result.data, `${queue}.json`);
    } catch (err) {
      this.props.notifyError(
        'could not download messages. Check the console for more details',
      );
      console.error(err); // eslint-disable-line no-console
    }

    const after = { ...before };
    delete after[queue];

    this.setState({ downloading: after });
  };

  showMessages = async (queue) => {
    try {
      const res = await api.get(`/rabbitmq/messages/${queue}`);
      this.setState({ messages: res.data.data });
      this.setState({
        isModalOpen: true,
        modalTitle: `Error messages in queue "${queue}"`,
      });
    } catch (err) {
      this.props.notifyError(
        'could not get messages. Have a look at your network and console',
      );
      console.error(err); // eslint-disable-line no-console
    }
  };

  closeModal = () => this.setState({ isModalOpen: false });

  openPurgeModal = (queue) => () =>
    this.setState({
      purgeWithRegexp: { ...this.state.purgeWithRegexp, queue },
    });
  closePurgeModal = () =>
    this.setState({
      purgeWithRegexp: {
        queue: null,
        count: 0,
        regexp: '',
        isPurging: false,
      },
    });

  renderMessage = (message) => {
    return (
      <div key={uuid()}>
        <div className="PayloadModal__Body RabbitmqPayloadModal__Body RabbitmqMessage__Body">
          {message.user.user_id > 0 && (
            <div>
              {message.user.username} ({message.user.user_id}){' '}
              {message.user.organization !== '' &&
                `belongs to ${message.user.organization}`}
            </div>
          )}
          <CodeEditor
            name="payload"
            className="PayloadModal__Content"
            value={JSON.stringify(JSON.parse(message.body), null, 2)}
            readOnly
            mode="json"
            theme="monokai"
            width="inital"
            height="inital"
            editorProps={{ $blockScrolling: Infinity }}
          />
        </div>
        <div className="PayloadModal__Body RabbitmqPayloadModal__Body RabbitmqHeader__Body">
          <CodeEditor
            name="payload"
            className="PayloadModal__Content"
            value={JSON.stringify(message.headers, null, 2)}
            readOnly
            mode="json"
            theme="monokai"
            width="inital"
            height="inital"
            editorProps={{ $blockScrolling: Infinity }}
          />
        </div>
      </div>
    );
  };

  onUpdatePurgeRegexp = (evt) => {
    this.setState({
      purgeWithRegexp: {
        ...this.state.purgeWithRegexp,
        regexp: evt.currentTarget.value,
      },
    });
  };

  renderPurgeModal = () => {
    const { purgeWithRegexp } = this.state;
    return (
      <div className="PurgeModal">
        <h2>
          You will purge the queue using a regexp. Be very careful about what
          you are doing
        </h2>
        <div className="PurgeModal__Info">
          <span>You will purge {purgeWithRegexp.count} message</span>
          <div className="PurgeModal__Regexp">
            <input
              type="text"
              value={purgeWithRegexp.regexp}
              className="form-control"
              onChange={this.onUpdatePurgeRegexp}
              placeholder="regexp"
            />
            <Button
              secondary
              onClick={this.onPurgeWithRegexp(true)}
              disabled={purgeWithRegexp.isPurging}
              displaySpinner={purgeWithRegexp.isPurging}
            >
              Test
            </Button>
          </div>
          <div className="PurgeModal__Footer">
            You need to test first you regexp before purging
          </div>
        </div>
      </div>
    );
  };

  onPurgeWithRegexp = (dryRun) => async () => {
    const purgeWithRegexp = this.state.purgeWithRegexp;
    try {
      this.setState({
        purgeWithRegexp: { ...purgeWithRegexp, isPurging: true },
      });
      const response = await api.post(
        `/rabbitmq/queues/${purgeWithRegexp.queue}/purgeFromRegexp`,
        {
          regexp: purgeWithRegexp.regexp,
          dry_run: dryRun,
        },
      );
      this.setState({
        purgeWithRegexp: {
          ...purgeWithRegexp,
          count: response.data.messagePurgedCount,
          isPurging: false,
        },
      });
      if (!dryRun) {
        this.closePurgeModal();
      }
    } catch (err) {
      this.props.notifyError(
        'could not purging the errors. Have a look at your network and console',
      );
      console.error(err); // eslint-disable-line no-console
    }
  };

  renderQueue = (queue) => {
    const { replaying, downloading, purging } = this.state;

    const link = `${this.getPrefix()}/${queue.name}`;
    return (
      <div key={queue.name} className="card Rabbitmq__Queue">
        <div>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {queue.name}
          </a>
          : {queue.messages}
        </div>
        <div>
          <Button
            secondary
            onClick={this.onClickView(queue.name)}
            disabled={replaying[queue.name]}
          >
            View
          </Button>
          <Button
            secondary
            onClick={this.onClickDownload(queue.name)}
            displaySpinner={downloading[queue.name]}
            disabled={downloading[queue.name]}
          >
            Download
          </Button>
          <Button
            warning
            onClick={this.onClickReplay(queue.name)}
            displaySpinner={replaying[queue.name]}
            disabled={replaying[queue.name]}
          >
            Replay
          </Button>
          <SplitButton
            warning
            disabled={purging[queue.name]}
            text="Purge"
            onClick={this.onClickPurging(queue.name)}
            alternativeOptions={[
              {
                text: 'Purge with regexp',
                onClick: this.openPurgeModal(queue.name),
              },
            ]}
          />
        </div>
      </div>
    );
  };

  render() {
    const { searchQuery, isModalOpen, modalTitle, messages, purgeWithRegexp } =
      this.state;
    const filteredQueues = this.filteredQueues();
    return (
      <div>
        <HeaderLayout
          title="RabbitMQ"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        >
          <Search
            query={searchQuery}
            updateSearchQuery={this.updateSearchQuery}
            placeholder="Filter queues using regex"
          />
        </HeaderLayout>
        <div className="container-fluid row">
          <div className="col-xs-12">
            <div className="Rabbitmq__Header">
              <Button onClick={this.fetchQueues}>
                <i className="mdi mdi-refresh" />
              </Button>
              <div className="Rabbitmq__Number">
                {filteredQueues.length} queues in error
              </div>
            </div>
            {filteredQueues.map(this.renderQueue)}
          </div>
        </div>
        {isModalOpen && (
          <Modal
            title={modalTitle}
            modalStyle="large"
            hideFooter
            onClose={this.closeModal}
          >
            <div className="PayloadModal">
              {messages.map(this.renderMessage)}
            </div>
          </Modal>
        )}
        {purgeWithRegexp.queue && (
          <Modal
            title={`Purge ${this.state.purgeWithRegexp.queue}`}
            onClose={this.closePurgeModal}
            onConfirm={this.onPurgeWithRegexp(false)}
            confirmButtonText="Purge"
            confirmDisabled={
              purgeWithRegexp.isPurging || !purgeWithRegexp.count
            }
            isProcessing={purgeWithRegexp.isPurging}
          >
            {this.renderPurgeModal()}
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  () => ({}),
  mapDispatchToProps,
)(withLocation(withNavigate(Rabbitmq)));
