import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SwitchButton } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';

import { OrganizationAutocomplete } from 'components/autocomplete';

import { updateFilters } from '../../actions';
import { selectFilters } from '../../selectors';
import * as types from '../../types';

import styles from './filter.module.scss';

interface SelectedOrganization {
  key: number;
  label: string;
  value: {
    id: number;
    name: string;
    type: string;
  };
}

export function RevisionFilters() {
  const filters: types.ProductRevisionFilter = useSelector(selectFilters);
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState<
    SelectedOrganization | undefined
  >(undefined);

  const onSelectOrganization = (e) => {
    setOrganization(e);
    dispatch(
      updateFilters({ ...filters, offset: 0, organization_ids: [e.value.id] }),
    );
  };
  const onUnSelectOrganization = () => {
    setOrganization(undefined);
    dispatch(updateFilters({ ...filters, offset: 0, organization_ids: [] }));
  };

  return (
    <div>
      <div className={styles.Section}>
        <h3>Organization</h3>
        <OrganizationAutocomplete
          id="kronos-revision-filters-organization"
          onSelect={onSelectOrganization}
          onUnselect={onUnSelectOrganization}
          value={organization ? [organization] : undefined}
          placeholder="Select organization..."
          searchOnClick
        />
      </div>
      <div className={styles.Section}>
        <h3>Head only</h3>
        <SwitchButton
          content=""
          checked={filters.head_only}
          onChange={() => {
            dispatch(
              updateFilters({
                ...filters,
                offset: 0,
                head_only: !filters.head_only,
              }),
            );
          }}
        />
      </div>
      <div className={styles.Section}>
        <h3>Logistic UUID</h3>
        <Text
          id="kronos-revision-filters-logistic"
          value={filters.logistic_uuid}
          placeholder="Logistic uuid..."
          onChange={(e) =>
            dispatch(
              updateFilters({
                ...filters,
                offset: 0,
                logistic_uuid:
                  e.target.value === '' ? undefined : e.target.value,
              }),
            )
          }
        />
      </div>
    </div>
  );
}
