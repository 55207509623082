import { OrganizationGroupRelationStatus } from '@alkem/sdk-dashboard';

import { Group } from './types';

export const isActiveGroupStatus = (status: number | undefined) =>
  status === (OrganizationGroupRelationStatus.ACCEPTED as number) ||
  status === (OrganizationGroupRelationStatus.DEACTIVATION_REQUESTED as number);

export const isActiveGroup = ({ status }: Group) => isActiveGroupStatus(status);

export const isGroupDeactivationRequested = ({ status }: Group) =>
  status === (OrganizationGroupRelationStatus.DEACTIVATION_REQUESTED as number);
