import { ConceptApi } from '@alkem/sdk-dashboard';

export default new ConceptApi({
  cacheEnabled: true,
});

export const immutableConceptApi = new ConceptApi({
  immutable: true,
  cacheEnabled: true,
});

export const getConceptApi = (acceptLanguage = 'noop') =>
  new ConceptApi({
    cacheEnabled: true,
    acceptLanguage,
  });
