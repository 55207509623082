import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio, Text } from '@alkem/react-ui-inputs';
import { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import InputWithLabel from 'components/input-with-label';
import {
  GET_REFERENTIAL_CODE_LIST,
  SET_SELECTED_EXPORT_MAPPING,
} from 'modules/io/gdsn/exports/actions/constants';
import { selectReferentialCodeOptions } from 'modules/io/gdsn/exports/selectors';
import {
  ExportMapping,
  RegularOptionWithNumberId,
} from 'modules/io/gdsn/exports/types';

import { ExportMappingsTurboSelector } from '../../turbo-selector';
import { isRegularOption } from '../../utils';
import styles from '../item-properties.module.scss';

const getInitialType = (exportMapping: ExportMapping) => {
  const { is_normalized, is_not_GS1_declinable, expressed_in_code } =
    exportMapping.payload;
  if (is_normalized) return 'is_normalized';
  if (is_not_GS1_declinable) return 'is_not_GS1_declinable';
  if (expressed_in_code) return 'expressed_in_code';
  return undefined;
};

const getInitialDefaultValue = (exportMapping: ExportMapping) => {
  if (
    (exportMapping.payload.cast !== 'string' &&
      exportMapping.payload.cast !== 'text') ||
    !exportMapping.payload.is_declinable ||
    exportMapping.payload.referential !== 'languages'
  )
    return undefined;
  return exportMapping.payload.default_value;
};

interface Props {
  name: string;
  exportMapping: ExportMapping;
  disabled: boolean;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemDeclinableByLanguagesStringElementProperties({
  name,
  disabled,
  exportMapping,
  dispatchProperty,
}: Props) {
  const dispatch = useDispatch();
  const referentialCodeOptions = useSelector(selectReferentialCodeOptions);
  const [type, setType] = useState<string | undefined>(
    getInitialType(exportMapping),
  );
  const [unit, setUnit] = useState<string | undefined>(
    exportMapping.payload.unit,
  );
  const [repeat, setRepeat] = useState<false | undefined>(
    exportMapping.payload.repeat,
  );
  const [stripHtml, setStripHtml] = useState<true | undefined>(
    exportMapping.payload.strip_html,
  );
  const [codeListNameCodeValue, setCodeListNameCodeValue] = useState<
    string | undefined
  >(exportMapping.payload.code_list_name_code);
  const [referentialCodeOption, setReferentialCodeOption] = useState<
    RegularOptionWithNumberId | undefined
  >(undefined);
  const [referentialCode, setReferentialCode] = useState<string | undefined>(
    exportMapping.payload.expressed_in_code,
  );
  const [defaultValue, setDefaultValue] = useState<string | undefined>(
    getInitialDefaultValue(exportMapping),
  );

  useEffect(() => {
    if (!Object.keys(referentialCodeOptions).includes('languages')) {
      dispatch({
        type: GET_REFERENTIAL_CODE_LIST,
        referential: 'languages',
      });
    }
  }, []);

  if (
    (exportMapping.payload.cast !== 'string' &&
      exportMapping.payload.cast !== 'text') ||
    !exportMapping.payload.is_declinable ||
    exportMapping.payload.referential !== 'languages'
  )
    return null;

  const updateType = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedType = value !== 'undefined' ? value : undefined;
    setType(updatedType);
    setUnit(undefined);
    setRepeat(undefined);
    setStripHtml(undefined);
    setCodeListNameCodeValue(undefined);
    setReferentialCodeOption(undefined);
    setReferentialCode(undefined);
    setDefaultValue(undefined);
    dispatch({
      type: SET_SELECTED_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
          payload: {
            ...exportMapping.payload,
            is_normalized: updatedType === 'is_normalized' || undefined,
            is_not_GS1_declinable:
              updatedType === 'is_not_GS1_declinable' || undefined,
            expressed_in_code: undefined,
            unit: undefined,
            repeat: undefined,
            strip_html: undefined,
            code_list_name_code: undefined,
            default_value: undefined,
          },
        },
      },
    });
  };

  const updateUnit = (event: { target: { value: string } }) => {
    const updatedUnit = event.target.value || undefined;
    setUnit(updatedUnit);
    dispatchProperty('unit', updatedUnit);
  };

  const updateRepeat = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedRepeat = value === 'false' ? false : undefined;
    setRepeat(updatedRepeat);
    dispatchProperty('repeat', updatedRepeat);
  };

  const updateStripHtml = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedStripHtml = value === 'true' || undefined;
    setStripHtml(updatedStripHtml);
    dispatchProperty('strip_html', updatedStripHtml);
  };

  const updateCodeListNameCodeValue = (event: {
    target: { value: string };
  }) => {
    const updatedCodeListNameCodeValue = event.target.value || undefined;
    setCodeListNameCodeValue(updatedCodeListNameCodeValue);
    dispatchProperty('code_list_name_code', updatedCodeListNameCodeValue);
  };

  const updateReferentialCodeOption = (
    valueOption: TurboSelectOption<RegularOptionWithNumberId>,
  ) => {
    if (isRegularOption(valueOption)) {
      setReferentialCodeOption(valueOption);
      setReferentialCode(valueOption.label);
      dispatchProperty('expressed_in_code', valueOption.label);
    } else {
      setReferentialCodeOption(undefined);
      setReferentialCode(undefined);
      dispatchProperty('expressed_in_code', undefined);
    }
  };

  const updateDefaultValue = (event: { target: { value: string } }) => {
    const updatedDefaultValue = event.target.value || undefined;
    setDefaultValue(event.target.value || undefined);
    dispatchProperty('default_value', updatedDefaultValue);
  };

  return (
    <div
      data-testid={`gdsn-export-mappings-declinable-by-languages-string-properties-${name}`}
    >
      <div className={styles.property}>
        <InputWithLabel
          inputId={`gdsn-export-mappings-declinable-by-languages-string-type-property-${name}`}
          label="Type"
        >
          <Radio
            id={`gdsn-export-mappings-declinable-by-languages-string-type-property-${name}`}
            value={type}
            options={[
              { label: 'Regular', value: undefined },
              { label: 'Normalized', value: 'is_normalized' },
              { label: 'Not GS1 declinable', value: 'is_not_GS1_declinable' },
              {
                label: 'Expressed in only one language',
                value: 'expressed_in_code',
              },
            ]}
            onChange={updateType}
          />
        </InputWithLabel>
        {type === 'expressed_in_code' && (
          <ExportMappingsTurboSelector
            label="Select language code"
            testId={`gdsn-export-mappings-declinable-by-languages-string-expressed-in-code-property-${name}`}
            options={referentialCodeOptions['languages']}
            onSelect={updateReferentialCodeOption}
            option={referentialCodeOption}
            mustShowError={!referentialCode}
            displayedValueProps={{
              label: 'Language code',
              value: referentialCode,
            }}
            showEditionFields={!disabled}
          />
        )}
      </div>
      {!type && (
        <div
          data-testid={`gdsn-export-mappings-declinable-by-languages-string-regular-properties-${name}`}
        >
          <div className={styles.property}>
            <InputWithLabel
              inputId={`gdsn-export-mappings-declinable-by-languages-string-unit-property-${name}`}
              label={
                <span>
                  Unit attribute name{' '}
                  <HelpTooltip
                    id={`gdsn-export-mappings-declinable-by-languages-string-unit-property-${name}-help`}
                    place="top"
                    message={
                      <div className={styles.messageContainer}>
                        <span>
                          Attribute name of the attribute that will contain the
                          unit given by the languages referential.
                        </span>
                        <span>
                          For strings and text, the defaultValue is
                          languageCode.
                        </span>
                      </div>
                    }
                  />
                </span>
              }
            >
              <Text
                id={`gdsn-export-mappings-declinable-by-languages-string-unit-property-${name}`}
                value={unit || ''}
                onChange={updateUnit}
              />
            </InputWithLabel>
          </div>
          <div className={styles.property}>
            <InputWithLabel
              inputId={`gdsn-export-mappings-declinable-by-languages-string-repeat-property-${name}`}
              label="Create only one item"
            >
              <Radio
                id={`gdsn-export-mappings-declinable-by-languages-string-repeat-property-${name}`}
                value={repeat}
                options={[
                  { label: 'TRUE', value: false },
                  { label: 'FALSE', value: undefined },
                ]}
                onChange={updateRepeat}
              />
            </InputWithLabel>
          </div>
          <div className={styles.property}>
            <InputWithLabel
              inputId={`gdsn-export-mappings-declinable-by-languages-string-code-list-name-code-property-${name}`}
              label={
                <span>
                  Attribute codeListNameCode value{' '}
                  <HelpTooltip
                    id={`gdsn-export-mappings-declinable-by-languages-string-code-list-name-code-property-${name}-help`}
                    place="top"
                    message="If set, an attribute codeListNameCode will be created."
                  />
                </span>
              }
            >
              <Text
                id={`gdsn-export-mappings-declinable-by-languages-string-code-list-name-code-property-${name}`}
                value={codeListNameCodeValue || ''}
                onChange={updateCodeListNameCodeValue}
              />
            </InputWithLabel>
          </div>
          <div className={styles.property}>
            <InputWithLabel
              inputId={`gdsn-export-mappings-declinable-by-languages-string-strip-html-property-${name}`}
              label="Strip html"
            >
              <Radio
                id={`gdsn-export-mappings-declinable-by-languages-string-strip-html-property-${name}`}
                value={stripHtml}
                options={[
                  { label: 'TRUE', value: true },
                  { label: 'FALSE', value: undefined },
                ]}
                onChange={updateStripHtml}
              />
            </InputWithLabel>
          </div>
        </div>
      )}
      {type && (
        <div
          className={styles.property}
          data-testid={`gdsn-export-mappings-declinable-by-languages-string-not-regular-properties-${name}`}
        >
          <InputWithLabel
            inputId={`gdsn-export-mappings-declinable-by-languages-string-default-value-property-${name}`}
            label="Default value"
          >
            <Text
              id={`gdsn-export-mappings-declinable-by-languages-string-default-value-property-${name}`}
              value={defaultValue || ''}
              onChange={updateDefaultValue}
            />
          </InputWithLabel>
        </div>
      )}
    </div>
  );
}
