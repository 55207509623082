import type { EntryTypesType } from './types';

export const EntryTypes: EntryTypesType = {
  0: {
    icon: '📋',
    color: '#789922',
    keep: false,
    name: 'TXT',
    component: () => (
      <i className="mdi mdi-note-text ProductReviewView__Icon" />
    ),
  },
  1: {
    icon: '✅',
    color: '#16C172',
    keep: true,
    name: 'REVIEW_PROCESS_COMPLETED',
    component: () => (
      <i className="mdi mdi-check-all ProductReviewView__Icon" />
    ),
  },
  2: {
    icon: '📜',
    color: '#4a3b5c',
    keep: false,
    name: 'JSON',
    component: () => (
      <i className="mdi mdi-file-document ProductReviewView__Icon" />
    ),
  },
  3: {
    icon: '👍',
    color: '#da291a',
    keep: true,
    name: 'THUMB',
    component: () => <i className="mdi mdi-thumb-up ProductReviewView__Icon" />,
  },
  4: {
    icon: '✍️',
    color: '#5271ff',
    keep: true,
    name: 'FIELD_UPDATED',
    component: () => <i className="mdi mdi-download ProductReviewView__Icon" />,
  },
  5: {
    icon: '♻️',
    color: '#d5e711',
    keep: true,
    name: 'REVIEW_PROCESS_TRIGGERED',
    component: () => <i className="mdi mdi-loop ProductReviewView__Icon" />,
  },
  6: {
    icon: '➡️',
    color: '#9d295a',
    keep: true,
    name: 'REVIEW_PROCESS_STARTED',
    component: () => (
      <i className="mdi mdi-arrow-right-bold ProductReviewView__Icon" />
    ),
  },
  7: {
    icon: '↗️',
    color: '#f47fff',
    keep: true,
    name: 'REVIEW_PROCESS_PROGRESSED',
    component: () => (
      <i className="mdi mdi-arrow-top-right ProductReviewView__Icon" />
    ),
  },
  8: {
    icon: '📐',
    color: '#858a83',
    keep: true,
    name: 'PRODUCT_DIM_UPSERT',
    component: () => <i className="mdi mdi-ruler ProductReviewView__Icon" />,
  },
  9: {
    icon: '🚀',
    color: '#f8526c',
    keep: true,
    name: 'PRODUCT_SHARE',
    component: () => (
      <i className="mdi mdi-share-variant ProductReviewView__Icon" />
    ),
  },
  10: {
    icon: '🚀',
    color: '#adad00',
    keep: false,
    name: 'PRODUCT_SHARE_INFO',
    component: () => <i className="mdi mdi-share ProductReviewView__Icon" />,
  },
  11: {
    icon: '🤖',
    color: '#0a465d',
    keep: true,
    name: 'AUTO_ACCEPT',
    component: () => <i className="mdi mdi-robot ProductReviewView__Icon" />,
  },
  12: {
    icon: '👮',
    color: '#5271ff',
    keep: true,
    name: 'REVIEW_FIELD_REQUIRED',
    component: () => <i className="mdi mdi-play ProductReviewView__Icon" />,
  },
  13: {
    icon: '🗑️',
    color: '#f6d42a',
    keep: true,
    name: 'REVIEW_STATE_DELETED',
    component: () => <i className="mdi mdi-delete ProductReviewView__Icon" />,
  },
  14: {
    icon: '💥',
    color: '#f6d42a',
    keep: true,
    name: 'INTEGRITY_ERROR',
    component: () => <i className="mdi mdi-close ProductReviewView__Icon" />,
  },
  15: {
    icon: '👍',
    color: '#84faa6',
    keep: true,
    name: 'THUMB_UP',
    component: () => <i className="mdi mdi-thumb-up ProductReviewView__Icon" />,
  },
  16: {
    icon: '👎',
    color: '#fad384',
    keep: true,
    name: 'THUMB_DOWN',
    component: () => (
      <i className="mdi mdi-thumb-down ProductReviewView__Icon" />
    ),
  },
  17: {
    icon: '🗓️',
    color: '#5BC0EB',
    keep: true,
    name: 'DATE',
    component: () => (
      <i className="mdi mdi-calendar-today ProductReviewView__Icon" />
    ),
  },
  18: {
    icon: '💣',
    color: '#ffe0b3',
    keep: true,
    name: 'TRANSITION_INTEGRITY',
    component: () => <i className="mdi mdi-close ProductReviewView__Icon" />,
  },
  19: {
    icon: '✅',
    color: '#16C172',
    keep: true,
    name: 'USER_ACCEPT_REVIEW',
    component: () => (
      <i className="mdi mdi-check-all ProductReviewView__Icon" />
    ),
  },
};
