import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';

import { updateFilters } from '../actions';
import { selectFilters } from '../selectors';
import * as types from '../types';

import { RevisionFilters } from './filters';
import styles from './index.module.scss';
import { Revisions } from './revisions';

export default function RevisionList() {
  const headerProps = {
    title: 'Kronos: Revisions',
    backHref: routes.home,
    backMessage: 'Back home',
    isTitleSmall: true,
  };
  const filters: types.ProductRevisionFilter = useSelector(selectFilters);
  const [search, setSearch] = useState<string>('');
  const dispatch = useDispatch();

  const onUpdateSearch = (s) => {
    setSearch(s);
    dispatch(
      updateFilters({
        ...filters,
        offset: 0,
        product_key_ids: s
          .split(',')
          .filter((e) => e !== '')
          .map((e: string) => parseInt(e)),
      }),
    );
  };

  return (
    <div>
      <HeaderLayout {...headerProps}>
        <Search
          query={search}
          placeholder="product key ids..."
          updateSearchQuery={onUpdateSearch}
        />
      </HeaderLayout>
      <div className={styles.list}>
        <div className={styles.listFilters}>
          <RevisionFilters />
        </div>
        <div className={styles.listContent}>
          <Revisions />
        </div>
      </div>
    </div>
  );
}
