import { createAction } from 'utils/reducers';

import { SettingOrganizationUpdate } from '../types';

import { FETCH_SETTINGS, RECEIVE_SETTINGS, UPDATE_SETTING } from './constants';

export const fetchSettings = createAction<number>(FETCH_SETTINGS);
export const receiveSettings = createAction(RECEIVE_SETTINGS);
export const updateSetting =
  createAction<SettingOrganizationUpdate>(UPDATE_SETTING);
