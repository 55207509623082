import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';

import { Tooltip } from '@alkem/react-ui-tooltip';

import './copyable.scss';

interface Props {
  id?: string;
  children?: React.ReactNode;
  text: string;
}

export default function Copyable(props: Props) {
  const { id, children, text } = props;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      copy(text);
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied, text]);

  const mdiIcon = copied ? 'check' : 'content-copy';
  const tooltipId = `copyable-${id || text}`;
  return (
    <span className="Copyable">
      {children || text}
      <i
        data-tip
        data-for={tooltipId}
        className={`cursor-pointer mdi mdi-${mdiIcon}`}
        onClick={() => setCopied(true)}
      />
      <Tooltip id={tooltipId} place="right">
        {copied ? 'Copied!' : `Click to copy: ${text}`}
      </Tooltip>
    </span>
  );
}
