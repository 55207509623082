import { isNumber } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { HeaderLayout } from '@alkem/react-layout';
import EmptyState from '@alkem/react-ui-empty-state';

import { OrganizationAutocomplete } from 'components/autocomplete';
import { home, organizationGroupFieldRoot } from 'constants/routes';

import { setOrganization } from './actions';
import { FieldGroup, FieldGroupActions } from './components';
import {
  selectDefaultFieldGroup,
  selectGroups,
  selectOrganization,
} from './selectors';
import './view.scss';

export const OrganizationFieldGroup = React.memo(() => {
  const organization = useSelector(selectOrganization);
  const groups = useSelector(selectGroups);
  const defaultFieldGroup = useSelector(selectDefaultFieldGroup);
  const hasSelectedOrganization = Boolean(organization);

  return (
    <div className="OrganizationFieldGroup">
      {!hasSelectedOrganization && (
        <EmptyState
          title="No organization selected"
          text="Please select an organization"
        />
      )}
      {hasSelectedOrganization && (
        <div className="card">
          <FieldGroupActions />
        </div>
      )}
      {organization &&
        hasSelectedOrganization &&
        groups.map((group, i) => (
          <div key={group.id} className="card">
            <FieldGroup
              group={group}
              index={i}
              organization={organization}
              default_group={group.id === defaultFieldGroup}
            />
          </div>
        ))}
    </div>
  );
});

export const OrganizationFieldGroupView = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organizationId } = useParams<{ organizationId: string }>();
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);

  useEffect(() => {
    dispatch(
      setOrganization(
        organizationId && isNumber(+organizationId)
          ? { id: +organizationId }
          : undefined,
      ),
    );
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (
      selectedOrganization?.value?.id &&
      selectedOrganization?.value?.id !== organizationId
    ) {
      navigate(
        `${organizationGroupFieldRoot}/${
          selectedOrganization?.value?.id ?? ''
        }`,
      );
    }
  }, [navigate, selectedOrganization, organizationId]);

  return (
    <div className="OrganizationGroupField alk-padding-bottom">
      <HeaderLayout
        title="Organization field groups"
        backHref={home}
        backMessage="Back home"
        isTitleSmall
      >
        <OrganizationAutocomplete
          id="organization-list-autocomplete"
          className="OrganizationGroupField__Search"
          onSelect={setSelectedOrganization}
          onUnselect={() => setSelectedOrganization(null)}
          value={selectedOrganization ? [selectedOrganization] : null}
          placeholder="Select an organization..."
          searchOnClick
          withs={{ users: true, fieldsUsed: true }}
        />
      </HeaderLayout>
      <div className="container alk-flex alk-flex-column alk-margin-bottom">
        <OrganizationFieldGroup />
      </div>
    </div>
  );
});
