import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Checkbox from '@alkem/react-ui-checkbox';
import { Ellitips } from '@alkem/react-ui-ellitips';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import StatusBar from 'components/ui/status-bar';
import {
  FIELDMETADATA_KIND_ID,
  VALIDATION_STATUS_FILTERS,
} from 'modules/field-suggestion/constants';
import { getStreamUrl } from 'utils/stream';

import './row.scss';

export default class FieldSuggestionRow extends Component {
  static propTypes = {
    entity: ImmutablePropTypes.map.isRequired,
    checked: PropTypes.bool.isRequired,
    onCheck: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    firstRow: PropTypes.bool,
    updateSearch: PropTypes.func.isRequired,
    kindLeaves: PropTypes.array.isRequired,
  };

  static defaultProps = {
    firstRow: false,
    kindLeaves: [],
  };

  getSuggestionStatus = () => {
    const { entity } = this.props;
    const status = VALIDATION_STATUS_FILTERS.find(
      (s) => s.id === entity.getIn(['field', 'status']),
    );
    return status ? status.statusBarType : null;
  };

  validateSuggestion = () => {
    const { onValidate, entity } = this.props;
    if ([0, 2].indexOf(entity.get('field').get('status')) > -1) {
      onValidate(entity, true);
    }
  };

  refuseSuggestion = () => {
    const { onValidate, entity } = this.props;
    if ([0, 1].indexOf(entity.get('field').get('status')) > -1) {
      onValidate(entity, false);
    }
  };

  showMore = () => {
    const { updateSearch, entity } = this.props;
    updateSearch(entity.get('metadata').get('productversion_id').toString());
  };

  renderHeaderItem(label) {
    const { firstRow } = this.props;
    if (!firstRow) {
      return null;
    }
    return (
      <div className="FieldSuggestionRow__header">
        <div className="FieldSuggestionRow__headerLabel">{label}</div>
      </div>
    );
  }

  renderCheckBox(entity, checked) {
    return (
      <div className="col-xs-1 FieldSuggestionRow__col FieldSuggestionRow__col--first">
        <div className="FieldSuggestionRow__checkbox">
          <Checkbox
            id={`checkbox-suggestion-${entity.get('id')}`}
            checked={checked}
            onChange={this.props.onCheck}
          />
        </div>
      </div>
    );
  }

  renderMoreInfo(entity) {
    const current = entity
      .getIn(['field', 'current'])
      .map((f) => f.get('field_name'))
      .join(', ');
    const features = entity.getIn(['extended_attributes', 'features']);
    const message = (
      <dl>
        <dt>Current</dt>
        <dd>{current}</dd>
        <dt>Features</dt>
        <dd>{features}</dd>
      </dl>
    );
    return (
      <div className="col-xs-1 FieldSuggestionRow__col">
        {this.renderHeaderItem('')}
        <HelpTooltip
          id={`moreinfo-${entity.get('id')}`}
          place="right"
          message={message}
          extraClass="FieldSuggestionRow__helptooltip"
          icon="eye"
        />
      </div>
    );
  }

  renderNameAndBrand(entity) {
    const link = getStreamUrl(`product/${entity.getIn(['metadata', 'gtin'])}`);
    const brand = entity.getIn(['metadata', 'brand']);
    const name = entity.getIn(['metadata', 'name']);
    const namebrand = [
      brand !== '' ? brand : 'N/A',
      name !== '' ? name : 'N/A',
    ].join(' - ');
    return (
      <div className="col-xs-8 FieldSuggestionRow__col">
        {this.renderHeaderItem('Brand - Name')}
        <a href={link} className="FieldSuggestionRow__link">
          <Ellitips
            id={`ellitips-suggestion-name-${entity.get('id')}`}
            label={namebrand}
          />
        </a>
      </div>
    );
  }

  renderFieldMetaDataName(entity) {
    return (
      <div className="col-xs-3 FieldSuggestionRow__col">
        {this.renderHeaderItem('Field')}
        <div className="FieldSuggestionRow__fmdName">
          <Ellitips
            id={`ellitips-value-fmd-${entity.get('id')}`}
            label={entity.getIn(['field', 'fieldmetadata_name'])}
          />
        </div>
      </div>
    );
  }

  renderSuggestedValue(entity, isKindNotLeaf = false) {
    return (
      <div className="col-xs-4 FieldSuggestionRow__col">
        {this.renderHeaderItem('Suggestion')}
        <div
          className={classNames(
            'FieldSuggestionRow__suggestedValue',
            isKindNotLeaf && 'FieldSuggestionRow__suggestedValue--warning',
          )}
        >
          <Ellitips
            id={`ellitips-value-${entity.get('id')}`}
            label={entity.getIn(['field', 'field_name'])}
            lines={2}
          />
        </div>
      </div>
    );
  }

  renderScores(entity) {
    const rawProba = entity.getIn(
      ['extended_attributes', 'probability'],
      ' - ',
    );
    const rawSourcesNb = entity.getIn(['metadata', 'source_count']);
    const cleanProba =
      typeof rawProba === 'number' ? parseFloat(rawProba.toFixed(2)) : rawProba;
    const cleanSourcesNb = parseFloat(rawSourcesNb.toFixed(2));
    return (
      <div className="col-xs-2 FieldSuggestionRow__col">
        {this.renderHeaderItem('Sources / Probability')}
        <div>
          {cleanSourcesNb} / {cleanProba}
        </div>
      </div>
    );
  }

  renderValidateButton(entity, isKindNotLeaf) {
    const validated = entity.get('field').get('status');
    if ([0, 2].indexOf(validated) > -1) {
      return (
        <div className="col-xs-1 FieldSuggestionRow__col">
          <span
            className={
              isKindNotLeaf
                ? 'mdi mdi-checkbox-marked FieldSuggestionRow__action_validate--warning'
                : 'mdi mdi-checkbox-marked FieldSuggestionRow__action_validate'
            }
            onClick={this.validateSuggestion}
            title="Validate"
          />
        </div>
      );
    }
    return (
      <div className="col-xs-1">
        <i className="mdi mdi-checkbox-marked FieldSuggestionRow__action_disabled" />
      </div>
    );
  }

  renderPicture(entity) {
    return (
      <div className="FieldSuggestionRow__picture col-xs-2">
        <a href={entity.getIn(['metadata', 'media'])}>
          <img src={entity.getIn(['metadata', 'media'])} alt="" />
        </a>
      </div>
    );
  }

  renderRefuseButton(entity) {
    const validated = entity.get('field').get('status');
    if ([0, 1].indexOf(validated) > -1) {
      return (
        <div className="col-xs-1 FieldSuggestionRow__col">
          <span
            className="mdi mdi-close-circle FieldSuggestionRow__action_refuse"
            onClick={this.refuseSuggestion}
            title="Refuse"
          />
        </div>
      );
    }
    return (
      <div className="col-xs-1">
        <i className="mdi mdi-close-circle FieldSuggestionRow__action_disabled" />
      </div>
    );
  }

  renderFocusButton() {
    return (
      <div className="col-xs-1 FieldSuggestionRow__col">
        <span
          className="mdi mdi-magnify FieldSuggestionRow__action_showmore"
          onClick={this.showMore}
          title="Show more"
        />
      </div>
    );
  }

  render() {
    const { checked, entity, firstRow, kindLeaves } = this.props;
    const isKindNotLeaf = kindLeaves
      ? entity.getIn(['field', 'fieldmetadata_id']) === FIELDMETADATA_KIND_ID &&
        kindLeaves.indexOf(entity.getIn(['field', 'field_id'])) === -1
      : false;
    const classes = {
      row: true,
      FieldSuggestionRow: true,
      'FieldSuggestionRow--first': firstRow,
    };

    return (
      <div
        key={entity.get('id')}
        id={entity.get('id')}
        className={classNames(classes)}
      >
        <StatusBar type={this.getSuggestionStatus()} />
        <div className="col-xs-6 row FieldSuggestionRow__SubRow">
          {this.renderCheckBox(entity, checked)}
          {this.renderPicture(entity)}
          {this.renderNameAndBrand(entity)}
          {this.renderMoreInfo(entity)}
        </div>
        <div className="col-xs-6 row FieldSuggestionRow__SubRow">
          {this.renderFieldMetaDataName(entity)}
          {this.renderSuggestedValue(entity, isKindNotLeaf)}
          {this.renderScores(entity)}
          {this.renderValidateButton(entity, isKindNotLeaf)}
          {this.renderRefuseButton(entity)}
          {this.renderFocusButton()}
        </div>
      </div>
    );
  }
}
