/* eslint-disable react/jsx-no-bind */
import { Map } from 'immutable';
import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HeaderLayout } from '@alkem/react-layout';
import Tooltip from '@alkem/react-ui-tooltip';

import {
  accessPolicyDashboardAccessPolicy,
  applicationDashboardAccessPolicy,
  displayGroupDashboardAccessPolicy,
  edaEventCapturesAccessPolicy,
  exchangeDashboardAccessPolicy,
  fieldDashboardAccessPolicy,
  gdsnDasshboardAccessPolicy,
  gdsnExportMappingsDashboardAccessPolicy,
  gdsnImportMappingsDashboardAccessPolicy,
  gdsnRciDashboardAccessPolicy,
  glnDashboardAccessPolicy,
  importExportDashboardAccessPolicy,
  indexerDashboardAccessPolicy,
  kindDashboardAccessPolicy,
  kronosDashboardAccessPolicy,
  organizationDashboardAccessPolicy,
  organizationGroupDashboardAccessPolicy,
  organizationGroupFieldDashboardAccessPolicy,
  productImportDashboardAccessPolicy,
  productMaintenanceDashboardAccessPolicy,
  productPlaygroundAccessPolicy,
  referentialDashboardAccessPolicy,
  retailerFieldsDashboardAccessPolicy,
  settingsDashboardAccessPolicy,
  transactionDashboardAccessPolicy,
  translationDashboardAccessPolicy,
  userDashboardAccessPolicy,
  validationDashboardAccessPolicy,
} from 'access-policies';
import Search from 'components/ui/input/search';
import * as ROUTES from 'constants/routes';
import { AccessPolicy, hasAccess } from 'modules/access-policy/common/utils';
import { selectPermissions } from 'modules/auth/reducer';
import { logError } from 'utils';
import { getAppConfig } from 'utils/app-config';
import { getAppHostname } from 'utils/location';
import { sortAsc } from 'utils/sort';
import { getUserType } from 'utils/user';

import { rabbitmqDashboardAccessPolicy } from '../../../access-policies';

import './HomeView.scss';
import HomeCard from './card';

interface Card {
  name: string; // eslint-disable-line no-restricted-globals
  path: string;
  external?: boolean;
  accessPolicy?: AccessPolicy;
  enabled?: boolean;
}

interface Item {
  label: string;
  children: Card[];
}

const getItems: () => Item[] = () => [
  {
    label: 'Product metadata',
    children: [
      {
        path: ROUTES.fieldsPage,
        name: 'Fields',
        accessPolicy: fieldDashboardAccessPolicy,
      },
      {
        path: ROUTES.displayGroups,
        name: 'Product page structure',
        accessPolicy: displayGroupDashboardAccessPolicy,
      },
      {
        path: ROUTES.kinds,
        name: 'Kinds',
        accessPolicy: kindDashboardAccessPolicy,
      },
      {
        path: ROUTES.referentials,
        name: 'Referentials',
        accessPolicy: referentialDashboardAccessPolicy,
      },
      {
        path: ROUTES.organizationUsesFields,
        name: 'Fields used by retailers',
        accessPolicy: retailerFieldsDashboardAccessPolicy,
      },
      {
        path: ROUTES.validationDashboard,
        name: 'Validation dashboard',
        accessPolicy: validationDashboardAccessPolicy,
      },
      {
        path: ROUTES.referentialPictures,
        name: 'Referential Pictures',
      },
    ],
  },
  {
    label: 'Classification',
    children: [
      {
        path: ROUTES.fieldSuggestion,
        name: 'Field suggestion',
      },
    ],
  },
  {
    label: 'Stream',
    children: [
      {
        path: ROUTES.organizations,
        name: 'Organization Dashboard',
        accessPolicy: organizationDashboardAccessPolicy,
      },
      {
        path: ROUTES.users,
        name: 'User Dashboard',
        accessPolicy: userDashboardAccessPolicy,
      },
      {
        path: ROUTES.pictureComparator,
        name: 'Picture comparator',
      },
      {
        path: ROUTES.applicationsRoot,
        name: 'Applications Dashboard',
        accessPolicy: applicationDashboardAccessPolicy,
      },
      {
        path: ROUTES.organizationGroups,
        name: 'Organization groups',
        accessPolicy: organizationGroupDashboardAccessPolicy,
      },
      {
        path: ROUTES.settings,
        name: 'Settings',
        accessPolicy: settingsDashboardAccessPolicy,
      },
      {
        path: ROUTES.organizationGroupFieldRoot,
        name: 'Organization field group',
        accessPolicy: organizationGroupFieldDashboardAccessPolicy,
      },
    ],
  },
  {
    label: 'IO',
    children: [
      {
        path: ROUTES.ioImport,
        name: 'Import',
        accessPolicy: importExportDashboardAccessPolicy,
      },
      {
        path: ROUTES.ioProductImport,
        name: 'Product Import',
        accessPolicy: productImportDashboardAccessPolicy,
        enabled: ['int', 'pillar', 'ppr'].includes(getAppConfig().env),
      },
      {
        path: ROUTES.ioImportGeneric,
        name: 'Import Generic (beta)',
        accessPolicy: importExportDashboardAccessPolicy,
      },
      {
        path: ROUTES.ioExport,
        name: 'Export',
        accessPolicy: importExportDashboardAccessPolicy,
      },
      {
        path: ROUTES.ioEndpoints,
        name: 'Endpoints',
        accessPolicy: exchangeDashboardAccessPolicy,
      },
      {
        path: ROUTES.ioMapperAfs,
        name: 'Mapper: AFS',
        accessPolicy: importExportDashboardAccessPolicy,
      },
    ],
  },
  {
    label: 'GDSN',
    children: [
      {
        path: ROUTES.ioGdsnCi,
        name: 'Hierarchies (CIN -> CIC)',
        accessPolicy: gdsnDasshboardAccessPolicy,
      },
      {
        path: ROUTES.ioGdsnCis,
        name: 'Subscription (CIS)',
        accessPolicy: gdsnDasshboardAccessPolicy,
      },
      {
        path: ROUTES.ioGdsnParty,
        name: 'Organization (Party)',
      },
      {
        path: ROUTES.ioGdsnRci,
        name: 'Item registry (RCI)',
        accessPolicy: gdsnRciDashboardAccessPolicy,
      },
      {
        path: ROUTES.ioGdsnPsr,
        name: 'Price: Items',
      },
      {
        path: ROUTES.ioGdsnRelation,
        name: 'Price: Relations',
      },
      {
        path: ROUTES.ioGdsnImports,
        name: 'Import mappings',
        accessPolicy: gdsnImportMappingsDashboardAccessPolicy,
      },
      {
        path: ROUTES.ioGdsnExports,
        name: 'Export mappings',
        accessPolicy: gdsnExportMappingsDashboardAccessPolicy,
      },
    ],
  },
  {
    label: 'Products',
    children: [
      {
        path: ROUTES.productMaintenance,
        name: 'Product maintenance',
        accessPolicy: productMaintenanceDashboardAccessPolicy,
      },
      {
        path: ROUTES.productReview,
        name: 'Product Review',
      },
    ],
  },
  {
    label: 'Claims',
    children: [
      {
        path: ROUTES.brandClaims,
        name: 'Brand',
      },
      {
        path: ROUTES.glnClaims,
        name: 'GLN',
        accessPolicy: glnDashboardAccessPolicy,
      },
    ],
  },
  {
    label: 'Transaction',
    children: [
      {
        path: ROUTES.transaction,
        name: 'Transaction',
        accessPolicy: transactionDashboardAccessPolicy,
      },
    ],
  },
  {
    label: 'Reporting',
    children: [
      {
        path: ROUTES.reportingHandling,
        name: 'Reporting Handling',
      },
    ],
  },
  {
    label: 'Tools',
    children: [
      {
        path: ROUTES.servicesDiscover,
        name: 'Routes',
      },
      {
        path: ROUTES.productWorkflowPlay,
        name: 'Product playground',
        accessPolicy: productPlaygroundAccessPolicy,
      },
      {
        path: ROUTES.rabbitmq,
        name: 'Rabbitmq',
        accessPolicy: rabbitmqDashboardAccessPolicy,
      },
      {
        path: `https://automation-dashboard.${getAppHostname()}/home/`,
        name: 'Automation Dashboards',
        external: true,
      },
      {
        path: `https://automation-dashboard.${getAppHostname()}/dataModel/`,
        name: 'Data model explorer',
        external: true,
      },
      {
        path: ROUTES.translation,
        name: 'Translation',
        accessPolicy: translationDashboardAccessPolicy,
      },
      {
        path: ROUTES.servicesOpenapi,
        name: 'OpenAPI',
      },
    ],
  },
  {
    label: 'Administration',
    children: [
      {
        path: ROUTES.accessPolicy,
        name: 'Access Policy',
        accessPolicy: accessPolicyDashboardAccessPolicy,
      },
    ],
  },
  {
    label: 'EDA',
    children: [
      {
        path: ROUTES.edaRmqReplayer,
        name: 'RMQ Replayer',
        accessPolicy: rabbitmqDashboardAccessPolicy,
      },
      {
        path: ROUTES.edaRmqExplorer,
        name: 'RMQ Explorer',
        accessPolicy: rabbitmqDashboardAccessPolicy,
      },
      {
        path: ROUTES.edaRmqViewer,
        name: 'RMQ Viewer',
        accessPolicy: rabbitmqDashboardAccessPolicy,
      },
      {
        path: ROUTES.edaEventCaptures,
        name: 'Event capture',
        accessPolicy: edaEventCapturesAccessPolicy,
      },
      {
        path: 'https://eda-catalog.supplierxm.salsify.com',
        name: 'EDA Catalog',
        external: true,
      },
    ],
  },
  {
    label: 'Indexer',
    children: [
      {
        path: ROUTES.indexerQueue,
        name: 'Queue',
        accessPolicy: indexerDashboardAccessPolicy,
      },
      {
        path: ROUTES.indexerConfig,
        name: 'Pump Config',
      },
    ],
  },
  {
    label: 'Kronos',
    children: [
      {
        path: ROUTES.kronosRevisions,
        name: 'Revisions',
        accessPolicy: kronosDashboardAccessPolicy,
      },
    ],
  },
];

interface UserPermissions {
  [key: string]: string[];
}

interface Props {
  user: Map<string, any>;
  userPermissions: UserPermissions;
}

const mapStateToProps = createStructuredSelector({
  user: (state: any) => state.user as Map<string, any>,
  userPermissions: selectPermissions as (state: any) => UserPermissions,
});

function HomeView({ user, userPermissions }: Props) {
  const favoritesStorageKey = 'home-favorites';
  const userType = getUserType(user);

  const [search, setSearch] = useState('');

  const items = useMemo(() => getItems(), []);

  const clearFavoritesStorage = useCallback(() => {
    try {
      localStorage.removeItem(favoritesStorageKey);
    } catch (err) {
      logError(err);
    }
  }, []);

  const favoritesFromStorage = useMemo<Card[]>(() => {
    try {
      const stored: Card[] = JSON.parse(
        localStorage.getItem(favoritesStorageKey) || '[]',
      );
      const allCards = items.reduce(
        (acc, item) => acc.concat(item.children || []),
        [] as Card[],
      );
      const cleaned = stored
        .map((card) =>
          allCards.find((localCard) => localCard.path === card.path),
        )
        .filter((card) => card) as Card[];
      if (stored.length !== cleaned.length) {
        localStorage.setItem(favoritesStorageKey, JSON.stringify(cleaned));
      }
      return cleaned;
    } catch (error) {
      logError(error);
      clearFavoritesStorage();
    }
    return [];
  }, [items, clearFavoritesStorage]);

  const [favorites, setFavorites] = useState<Card[]>(favoritesFromStorage);

  const isCardAvailable = useCallback(
    (accessPolicy?: AccessPolicy) =>
      hasAccess(userType, userPermissions, accessPolicy),
    [userType, userPermissions],
  );

  const dashboards = useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          const searchRegExp = search ? new RegExp(search, 'i') : null;
          const children = item.children.filter(
            ({ name, accessPolicy, enabled }: Card) =>
              isCardAvailable(accessPolicy) &&
              (!searchRegExp || searchRegExp.test(name)) &&
              enabled !== false,
          );
          if (children.length > 0) {
            acc.push({ ...item, children });
          }
          return acc;
        },
        [] as typeof items,
      ),
    [items, search, isCardAvailable],
  );

  const favoriteDashboards = useMemo(() => {
    let localFavorites = favorites;
    if (search) {
      const searchRegExp = new RegExp(search, 'i');
      localFavorites = favorites.filter((card) => searchRegExp.test(card.name));
    }
    return localFavorites
      .filter((card: Card) => isCardAvailable(card.accessPolicy))
      .sort((cardA, cardB) => sortAsc(cardA.name, cardB.name));
  }, [favorites, search, isCardAvailable]);

  const makeFavorite = useCallback(
    (card: Card, isFavorite: boolean) => {
      const newFavorites = isFavorite
        ? [...favorites, card]
        : favorites.filter((localCard) => localCard.path !== card.path);
      setFavorites(newFavorites);
      try {
        localStorage.setItem(favoritesStorageKey, JSON.stringify(newFavorites));
      } catch (error) {
        logError(error);
      }
    },
    [favorites],
  );

  return (
    <div className="HomeView__body">
      <HeaderLayout
        title="Home"
        backHref={ROUTES.logout}
        backMessage="Logout"
        isTitleSmall
      >
        <Search
          query={search}
          placeholder="Search for a dashboard"
          updateSearchQuery={(query: string) => setSearch(query)}
        />
      </HeaderLayout>
      <div className="HomeView__dashboards container-fluid">
        {favoriteDashboards.length > 0 && (
          <div className="row">
            <h2 className="col-xs-12">
              Favorites{' '}
              <i
                className="mdi mdi-help-circle"
                data-for="home-favorites-help"
                data-tip
              />
              <Tooltip id="home-favorites-help" place="right">
                Favorites are stored in the local storage of your browser and so
                are only available in your current browser.
              </Tooltip>
            </h2>
            {favoriteDashboards.map((card) => (
              <HomeCard
                key={card.path}
                card={card}
                isFavorite
                onFavorite={makeFavorite}
              />
            ))}
          </div>
        )}
        {dashboards.map(
          ({ label, children }) =>
            children.length > 0 && (
              <div key={label} className="row">
                <h2 className="col-xs-12">{label}</h2>
                {children.map((child) => (
                  <HomeCard
                    key={child.path}
                    card={child}
                    isFavorite={favorites.some(
                      (card) => card.path === child.path,
                    )}
                    onFavorite={makeFavorite}
                  />
                ))}
              </div>
            ),
        )}
        {dashboards.length === 0 && <h3>No dashboard found.</h3>}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(HomeView);
