import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import { Text } from '@alkem/react-ui-inputs';
import { Tooltip } from '@alkem/react-ui-tooltip';

import StatusBar from 'components/ui/status-bar';
import { ORGANIZATION_TYPE_RETAILER } from 'constants/organization';
import * as routes from 'constants/routes';
import { getFormatType } from 'modules/io/import/constants';
import ErrorModal from 'modules/io/import/modals/error';
import ReplayModal from 'modules/io/import/modals/replay';
import { getIn } from 'utils/immutable';
import { separateActions } from 'utils/redux';
import {
  TIME_FORMAT_MINUTE,
  TIME_FORMAT_SECOND,
  formatTimeImplicitUTC,
} from 'utils/time';
import { isAtLeastPlatformAdmin } from 'utils/user';

import {
  clearImportListFilters,
  productImportReportGenerate,
  productImportReportGetFile,
  pushImport,
  setImportStatus,
  setSupportStatus,
  updateImportListFilters,
} from '../../actions';
import { getTargetOrganizations } from '../../utils';

import { excel } from './images/base64';
import IconCase from './images/case_50x50.png';
import IconEach from './images/each_50x50.png';
import IconEmpty from './images/empty_50x50.png';
import IconPack from './images/pack_50x50.png';
import IconPallet from './images/pallet_50x50.png';
import './row.scss';
import { ImportStatusSelect } from './select-import-status';
import SupportStatusSelect from './select-status';

export function getSourceUser(user) {
  if (!user) {
    return null;
  }
  return (
    <p>
      <i className="mdi mdi-account" />
      {` ${user.firstname} ${user.lastname} (${user.username})`}
    </p>
  );
}

export function hasDifferentValidSourceOrganization(importfile) {
  if (
    !importfile.sourceOrganization.id ||
    importfile.sourceOrganization.id === 1
  ) {
    return false;
  }
  if (
    importfile.targetOrganizations.length === 1 &&
    importfile.sourceOrganization.id === importfile.targetOrganizations[0].id
  ) {
    return false;
  }
  return true;
}

export function isWriteAPICall(importfile) {
  return (
    getIn(importfile, 'parameters.operator.extra.event_type') === 'WRITEAPI'
  );
}

export function isReplayedImport(importfile) {
  return getIn(importfile, 'parameters.replay_info.user_id') !== undefined;
}

function compareHierarchyIndex(a, b) {
  return a.hierarchy_index - b.hierarchy_index;
}

const mapDispatchToProps = {
  pushImport,
  productImportReportGetFile,
  productImportReportGenerate,
  setSupportStatus,
  setImportStatus,
  updateImportListFilters,
  clearImportListFilters,
};

const mapStateToProps = () => ({});

class _ImportFileListRow extends PureComponent {
  static propTypes = {
    importfile: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    importDetails: PropTypes.array,
    onSearchDetails: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      pushImport: PropTypes.func.isRequired,
      productImportReportGenerate: PropTypes.func.isRequired,
      productImportReportGetFile: PropTypes.func.isRequired,
      setSupportStatus: PropTypes.func.isRequired,
      setImportStatus: PropTypes.func.isRequired,
      updateImportListFilters: PropTypes.func.isRequired,
      clearImportListFilters: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    importDetails: [],
  };

  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
    this.state = {
      shouldRenderChildren: false,
      isReplayModalOpen: false,
      isErrorModalOpen: false,
      selectedErrorMessage: null,
      stackTrace: null,
      payload: null,
      locationsUnrolled: false,
      status: this.props.importfile.status,
      importDetailsFilters: {
        page: 0,
        offset: 0,
        limit: 20,
        filter_references_in: '',
        filter_error_only: false,
      },
    };
  }

  getFilename = (index) => {
    const { importfile } = this.props;
    if (index === undefined) {
      if (importfile.name !== 'Unknown location' && importfile.name) {
        return importfile.name;
      }
      return (
        importfile.replayParameters &&
        importfile.replayParameters.fetcher &&
        importfile.replayParameters.fetcher.location &&
        importfile.replayParameters.fetcher.location.split('/').pop()
      );
    }
    return importfile.parameters.fetcher.locations[index].split('/').pop();
  };

  onClickDownload = (index) => {
    return () => {
      const { importfile } = this.props;
      this.props.actions.productImportReportGetFile(index, importfile);
    };
  };

  onClickLoadAlongOriginal = () => {
    const { importfile } = this.props;

    if (
      getIn(importfile, 'parameters.replay_info.original_import_id') ===
      undefined
    ) {
      return;
    }

    const newFilterIds = `${importfile.id},${getIn(
      importfile,
      'parameters.replay_info.original_import_id',
    )}`;

    this.props.actions.clearImportListFilters();
    this.props.actions.updateImportListFilters('filter_ids', {
      label: `Show only ${newFilterIds}`,
      value: newFilterIds,
      checked: true,
    });
  };

  onClickDownloadErrorReport = () => {
    const { importfile } = this.props;
    this.props.actions.productImportReportGenerate(importfile.id);
  };

  onClickReplay = (payload) => {
    this.props.actions.pushImport(payload);
    this.toggleReplayModal();
  };

  onClickExpand = () => {
    const { shouldRenderChildren } = this.state;
    this.setState({ shouldRenderChildren: !shouldRenderChildren });
    if (!shouldRenderChildren === true) {
      this.onSearch();
    }
  };

  onSearch = (givenPage) => {
    const { importDetailsFilters } = this.state;
    const page =
      givenPage !== undefined ? givenPage : importDetailsFilters.page;
    this.props.onSearchDetails(this.props.importfile.id, {
      ...importDetailsFilters,
      offset: page * importDetailsFilters.limit,
    });
  };

  getImportDetailsStatus = (importLine) =>
    importLine.steps.some((step) => step.status === 'ERROR') ||
    importLine.errorMessage
      ? 'FAILURE'
      : 'SUCCESS';

  getTypePackagingIcon = (typePackaging) => {
    return (
      {
        EACH: IconEach,
        PACK: IconPack,
        CASE: IconCase,
        PALLET: IconPallet,
      }[typePackaging] || IconEmpty
    );
  };

  toggleErrorModal = (errorMessage, stackTrace, payload) => () => {
    const { isErrorModalOpen } = this.state;
    this.setState({
      isErrorModalOpen: !isErrorModalOpen,
      isReplayModalOpen: false,
      selectedErrorMessage: isErrorModalOpen ? null : errorMessage,
      stackTrace: isErrorModalOpen ? null : stackTrace,
      payload: isErrorModalOpen ? null : payload,
    });
  };

  toggleReplayModal = () => {
    const { isReplayModalOpen } = this.state;
    this.setState({
      isReplayModalOpen: !isReplayModalOpen,
      isErrorModalOpen: false,
    });
  };

  filterErrorOnly = (e) => {
    this.setState(
      (prevState) => ({
        importDetailsFilters: {
          ...prevState.importDetailsFilters,
          filter_error_only: e,
          page: 0,
        },
      }),
      this.onSearch,
    );
  };

  filterReferences = (e) => {
    e.persist();
    this.setState((prevState) => ({
      importDetailsFilters: {
        ...prevState.importDetailsFilters,
        filter_references_in: e.target.value,
        page: 0,
      },
    }));
  };

  searchPrev = () => {
    const { importDetailsFilters } = this.state;
    if (importDetailsFilters.page === 0) {
      return;
    }
    this.setState({
      importDetailsFilters: {
        ...importDetailsFilters,
        page: importDetailsFilters.page - 1,
      },
    });
    this.onSearch(importDetailsFilters.page - 1);
  };

  searchNext = () => {
    const { importfile, importDetails } = this.props;
    const { importDetailsFilters } = this.state;
    if (
      (importDetailsFilters.page + 1) * importDetailsFilters.limit >=
        importfile.counts.total ||
      importDetails.length < importDetailsFilters.limit
    ) {
      return;
    }
    this.setState({
      importDetailsFilters: {
        ...importDetailsFilters,
        page: importDetailsFilters.page + 1,
      },
    });
    this.onSearch(importDetailsFilters.page + 1);
  };

  searchOnEnter = (e) => {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  };

  simpleSearch = () => {
    // this wrapper is here to remove the event spider is passed to simpleSearch by React
    return this.onSearch();
  };

  onChangeSupportStatus = (importFileId, status) => {
    this.props.actions.setSupportStatus(importFileId, status);
    this.props.importfile.support.status = status;
  };

  onChangeImportStatus = (importFileId, status) => {
    this.props.actions.setImportStatus(importFileId, status);
    this.setState({ status: status });
  };

  renderFilterRow = () => {
    const { importfile, importDetails } = this.props;
    const { importDetailsFilters } = this.state;

    return (
      <div className="ImportDetailsRow__Lines ImportDetailsRow ImportDetailsRow__Search row">
        <div className="col-xs-1 ImportDetailsRow__ErrorsOnly">
          <Checkbox
            id={`importfile-${importfile.id}-filter_error_only`}
            checked={importDetailsFilters.filter_error_only}
            onChange={this.filterErrorOnly}
            label={<div className="ImportDetailsRow_Checkbox">Errors only</div>}
          />
        </div>
        <div className="col-xs-2">
          <Button
            link
            className="ImportDetailsRow__ExcelErrorReport"
            key={`import-${importfile.id}-generate-report`}
            onClick={this.onClickDownloadErrorReport}
            secondary
          >
            {excel}
            <span>Error report</span>
          </Button>
        </div>
        <div className="col-xs-4">
          <Text
            id={`importfile-${importfile.id}-gtin`}
            value={importDetailsFilters.filter_references_in}
            placeholder="Gtins or product identifiers, separated by commas"
            onChange={this.filterReferences}
            onKeyDown={this.searchOnEnter}
          />
        </div>
        <div className="col-xs-1">
          <Button
            secondary
            key={`importfile-${importfile.id}-search_btn`}
            onClick={this.simpleSearch}
          >
            <i className="mdi mdi-magnify" />
          </Button>
        </div>
        <div className="col-xs-4 ImportDetailsRow_Navigation">
          <div>
            {`${importDetailsFilters.page + 1} / ${
              Math.ceil(importfile.counts.total / importDetailsFilters.limit) ||
              1
            } pages`}
          </div>
          <div className="ImportDetailsRow_NavigationSeparator" />
          <div className="btn-group">
            <Button
              secondary
              key={`importfile-${importfile.id}-search_prev`}
              disabled={importDetailsFilters.page === 0}
              onClick={this.searchPrev}
            >
              <i className="mdi mdi-chevron-left" />
            </Button>
            <Button
              secondary
              key={`importfile-${importfile.id}-search_next`}
              disabled={
                (importDetailsFilters.page + 1) * importDetailsFilters.limit >=
                  importfile.counts.total ||
                importDetails.length < importDetailsFilters.limit
              }
              onClick={this.searchNext}
            >
              <i className="mdi mdi-chevron-right" />
            </Button>
          </div>
          <div className="ImportDetailsRow_NavigationSeparator" />
          <Button
            secondary
            key={`importfile-${importfile.id}-search_btn`}
            onClick={this.simpleSearch}
          >
            <i className="mdi mdi-refresh" />
          </Button>
        </div>
      </div>
    );
  };

  renderChildren = () => {
    const { importDetails, importfile } = this.props;
    importDetails.sort(compareHierarchyIndex);
    let hierarchyIndicator;
    const lines = importDetails.map((importline, i) => {
      if (
        (i === 0 ||
          importDetails[i - 1].hierarchy_index !==
            importline.hierarchy_index) &&
        (i === importDetails.length - 1 ||
          importDetails[i + 1].hierarchy_index !== importline.hierarchy_index)
      ) {
        hierarchyIndicator = 'single';
      } else if (
        i === 0 ||
        importDetails[i - 1].hierarchy_index !== importline.hierarchy_index
      ) {
        hierarchyIndicator = 'start';
      } else if (
        i > 0 &&
        i < importDetails.length - 1 &&
        importDetails[i - 1].hierarchy_index === importline.hierarchy_index &&
        importDetails[i + 1].hierarchy_index === importline.hierarchy_index
      ) {
        hierarchyIndicator = 'middle';
      } else {
        hierarchyIndicator = 'end';
      }
      return (
        <div key={importline.id} className="ImportDetailsRow row">
          <StatusBar type={this.getImportDetailsStatus(importline)} />
          <div className="col-xs-2 ImportDetailsRow_FirstCell">
            <div className="ImportDetailsRow_HierarchyTree">
              <div className="line top">
                <div className="block topLeft" />
                <div
                  className={classNames({
                    block: true,
                    topRight: true,
                    [hierarchyIndicator]: true,
                  })}
                />
              </div>
              <div className="line bottom">
                <div className="block bottomLeft" />
                <div
                  className={classNames({
                    block: true,
                    bottomRight: true,
                    [hierarchyIndicator]: true,
                  })}
                />
              </div>
            </div>
            <img
              className="ImportDetailsRow_TypePackagingIcon"
              src={this.getTypePackagingIcon(importline.typePackaging)}
              alt={importline.typePackaging}
            />
            <span> {importline.gtin} </span>
          </div>
          <div className="col-xs-4">
            {importline.targetOrganization.nameLegal} (
            {importline.targetOrganization.id})
          </div>
          <div className="col-xs-5">
            {this.renderSteps(importline.id, importline.steps)}
          </div>
          <div className="col-xs-1 ImportDetailsRow__actions">
            {importfile.sourceOrganization.type !==
              ORGANIZATION_TYPE_RETAILER.id && (
              <a
                href={`${routes.transaction}?sourceEntities=ProductImport:${importline.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="mdi mdi-sitemap ImportDetailsIcon" />
              </a>
            )}
          </div>
        </div>
      );
    });
    return (
      <div className="ImportDetailsRowWrapper">
        {this.renderFilterRow()}
        <div className="ImportDetailsRow ImportDetailsRow__Header row">
          <div className="col-xs-2">Product reference</div>
          <div className="col-xs-4">Target organization</div>
          <div className="col-xs-5">Workflow</div>
        </div>
        <div className="ImportDetailsRow__Lines">{lines}</div>
      </div>
    );
  };

  renderTimeInfo = (importfile) => {
    if (importfile.count) {
      const average = Math.round(importfile.duration / importfile.count);
      return (
        <div className="ImportFileListRow__time">
          <div>
            <i className="mdi mdi-calendar-clock" />
            <span>
              {formatTimeImplicitUTC(importfile.createdAt, TIME_FORMAT_MINUTE)}
            </span>
          </div>
          <div>
            <i className="mdi mdi-clock" />
            <span>
              {importfile.duration}s (
              {average > 9 ? <i className="mdi mdi-alert" /> : null}
              {average}
              s/item)
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="ImportFileListRow__time">
        <i className="mdi mdi-calendar-clock" />
        <span>
          {formatTimeImplicitUTC(importfile.createdAt, TIME_FORMAT_MINUTE)}
        </span>
      </div>
    );
  };

  renderSteps = (id, steps) => {
    const stepIcons = {
      configuration: 'mdi-wrench',
      fetch: 'mdi-download',
      crawl: 'mdi-format-align-justify',
      identify: 'mdi-magnify',
      import: 'mdi-import',
      parse: 'mdi-shuffle-variant',
      async: 'mdi-notification-clear-all',
      clean: 'mdi-filter-outline',
      bounce: 'mdi-close-octagon-outline',
      media: 'mdi-attachment',
      slow: 'mdi-progress-clock',
      hierarchize: 'mdi-file-tree',
      labelize: 'mdi-label-outline',
      operate: 'mdi-import',
      upsert: 'mdi-content-save-outline',
      parsing: 'mdi-shuffle-variant',
      integration: 'mdi-import',
      assignation: 'mdi-email-outline',
      report: 'mdi-email-outline',
      validate: 'mdi mdi-factory',
      save: 'mdi mdi-database-check',
      index: 'mdi mdi-alpha-i-circle-outline',
      share: 'mdi mdi-share-variant',
      target_accept: 'mdi mdi-shield-check-outline',
      target_share_index: 'mdi-shield-link-variant-outline',
      target_accept_index: 'mdi-alpha-i-box-outline',
    };
    const timeline = steps.map((step, i) => (
      <span
        key={step.name}
        className="ImportFileListRow__Step"
        data-tip
        data-for={`importfile-${id}-step-${step.name}`}
      >
        {i > 0 ? <i className="mdi mdi-arrow-right" /> : null}
        <i
          className={`mdi ${
            stepIcons[step.name] || 'mdi-help'
          } ImportFileListRow__Step--Icon  ImportFileListRow__Step--${step.status.replace(
            / +/g,
            '',
          )}`}
          onClick={
            step.message ||
            step.payload ||
            step.stack_trace ||
            step.validation_report
              ? this.toggleErrorModal(
                  step.message,
                  step.stack_trace ||
                    JSON.stringify(step.validation_report, undefined, 2),
                  step.payload,
                )
              : null
          }
        />
        <Tooltip id={`importfile-${id}-step-${step.name}`}>
          <div className="ImportDetailsIcon__Tooltip--Header">
            {step.name.toUpperCase()}
          </div>
          <br />
          {step.name === 'validate' ? (
            <p>Click here to see the validation report.</p>
          ) : null}
          <div>
            finished at: {formatTimeImplicitUTC(step.time, TIME_FORMAT_SECOND)}
          </div>
          {step.message ? <div>message: {step.message}</div> : null}
        </Tooltip>
      </span>
    ));
    return <div className="ImportFileListRow__Steps">{timeline}</div>;
  };

  renderStatusSupport = () => {
    const { importfile } = this.props;
    return (
      <div
        className="ImportFileSupportStatus"
        key={`importfile-${importfile.id}-supportstatus-user`}
        data-tip
        data-for={`importfile-${importfile.id}-supportstatus-user`}
      >
        <SupportStatusSelect
          importFileId={importfile.id}
          onChange={this.onChangeSupportStatus}
          status={importfile.support.status}
        />
        <Tooltip id={`importfile-${importfile.id}-supportstatus-user`}>
          {!importfile.support.user
            ? `No user has changed the status`
            : `User ${importfile.support.user.username} (${
                importfile.support.user.id
              }) has changed the status ${moment(
                `${importfile.support.updatedAt}Z`,
              ).calendar()}`}
        </Tooltip>
      </div>
    );
  };

  renderMultiLocation(locations) {
    if (locations.length > 2 && this.state.locationsUnrolled === false) {
      return (
        <span>
          <i
            key={`filename-${locations[0]}`}
            data-tip
            data-for={`filename-${locations[0]}`}
            className="mdi mdi-download ImportDetailsIcon"
            onClick={this.onClickDownload(0)}
          >
            <Tooltip id={`filename-${locations[0]}`}>
              {this.getFilename(0)}
            </Tooltip>
          </i>
          <i
            className="mdi mdi-dots-horizontal ImportDetailsIcon"
            onClick={() => this.setState({ locationsUnrolled: true })}
          />
        </span>
      );
    }
    const renderedLocations = locations.map((location, i) => (
      <i
        key={`filename-${location}`}
        data-tip
        data-for={`filename-${location}`}
        className="mdi mdi-download ImportDetailsIcon"
        onClick={this.onClickDownload(i)}
      >
        <Tooltip id={`filename-${location}`}>{this.getFilename(i)}</Tooltip>
      </i>
    ));
    if (locations.length > 2) {
      return (
        <span>
          {renderedLocations}
          <i
            className="mdi mdi-eye-off-outline ImportDetailsIcon"
            onClick={() => this.setState({ locationsUnrolled: false })}
          />
        </span>
      );
    }
    return renderedLocations;
  }

  renderImportStatus = () => {
    const { importfile } = this.props;
    return (
      <div
        className="ImportStatus"
        key={`importfile-${importfile.id}-importstatus-user`}
        data-tip
        data-for={`importfile-${importfile.id}-importstatus-user`}
      >
        <ImportStatusSelect
          importFileId={importfile.id}
          onChange={this.onChangeImportStatus}
          status={importfile.status}
        />
      </div>
    );
  };

  render() {
    const { importfile, user } = this.props;
    const { filters } = this.props;
    const {
      isErrorModalOpen,
      isReplayModalOpen,
      selectedErrorMessage,
      shouldRenderChildren,
      payload,
      stackTrace,
    } = this.state;
    const formatType = getFormatType(importfile.spider);
    return (
      <div className="ImportFileListRowWrapper">
        <div
          key={importfile.id}
          id={importfile.id}
          className="ImportFileListRow row"
        >
          <StatusBar type={importfile.status} />
          <div className="col-xs-3 Organizations_names">
            <p>
              <i className="mdi mdi-domain" />
              {importfile.targetOrganizations.length > 0 &&
                getTargetOrganizations(importfile.targetOrganizations)}
              {hasDifferentValidSourceOrganization(importfile) && (
                <span>
                  {importfile.targetOrganizations.length > 0 && 'made by'}{' '}
                  {importfile.sourceOrganization.nameLegal} (
                  {importfile.sourceOrganization.id})
                </span>
              )}
            </p>
            {getSourceUser(importfile.user)}
          </div>
          <div className="col-xs-1">
            <div
              className={`ImportFileListRow__tag ImportFileListRow__tag_${formatType}`}
            >
              {importfile.spider}
            </div>
            {isWriteAPICall(importfile) && (
              <div
                className={`ImportFileListRow__tag ImportFileListRow__tag_product ImportFileListRow__tag--${importfile.spider}`}
              >
                API
              </div>
            )}
            {isReplayedImport(importfile) && (
              <div
                className="ImportFileListRow__tag ImportFileListRow__tag_replay"
                data-tip
                data-for={`replay-tag-${importfile.id}`}
                onClick={this.onClickLoadAlongOriginal}
              >
                <i className="mdi mdi-recycle" /> replayed
                <Tooltip id={`replay-tag-${importfile.id}`}>
                  {`user: ${importfile.parameters.replay_info.user_id}`}
                </Tooltip>
              </div>
            )}
          </div>
          <div className="col-xs-1">
            {formatType !== 'rfp' && (
              <div className="ImportFileListRow__count">
                {importfile.counts.success} / {importfile.counts.total}
              </div>
            )}
          </div>
          <div className="col-xs-2">{this.renderTimeInfo(importfile)}</div>
          <div className="col-xs-2">
            {this.renderSteps(importfile.id, importfile.steps)}
          </div>
          <div className="col-xs-2 ImportFileListRow__actions">
            {importfile.support.status && this.renderStatusSupport()}
            {isAtLeastPlatformAdmin(user) && this.renderImportStatus()}
          </div>
          <div className="col-xs-1 ImportFileListRow__actions">
            {(!filters.filter_ids || filters.filter_ids.length === 0) && (
              <a
                href={`${routes.ioImport}?filter_ids=${importfile.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="mdi mdi-filter ImportDetailsIcon">&nbsp;</i>
              </a>
            )}
            {((importfile.parameters &&
              importfile.parameters.fetcher &&
              importfile.parameters.fetcher.location) ||
              (importfile.replayParameters &&
                importfile.replayParameters.fetcher &&
                importfile.replayParameters.fetcher.location)) && (
              <i
                data-tip
                data-for={`filename-${importfile.id}`}
                className="mdi mdi-download ImportDetailsIcon"
                onClick={this.onClickDownload()}
              >
                <Tooltip id={`filename-${importfile.id}`}>
                  {this.getFilename()}
                </Tooltip>
              </i>
            )}
            {importfile.parameters &&
              importfile.parameters.fetcher &&
              importfile.parameters.fetcher.locations &&
              this.renderMultiLocation(importfile.parameters.fetcher.locations)}
            {formatType === 'product' && (
              <i
                className="mdi mdi-sync ImportDetailsIcon"
                onClick={this.toggleReplayModal}
              />
            )}
            <div
              onClick={this.onClickExpand}
              className={`ImportDetailsIcon col-xs-0.5 mdi mdi-chevron-${
                shouldRenderChildren ? 'up' : 'down'
              }`}
            />
          </div>
        </div>
        {shouldRenderChildren && this.renderChildren()}
        {isErrorModalOpen && (
          <ErrorModal
            stackTrace={stackTrace}
            errorMessage={selectedErrorMessage}
            payload={payload}
            onCloseModal={this.toggleErrorModal()}
          />
        )}
        {isReplayModalOpen && (
          <ReplayModal
            importfile={importfile}
            onReplayClick={this.onClickReplay}
            onCloseModal={this.toggleReplayModal}
          />
        )}
      </div>
    );
  }
}

const ImportFileListRow = connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(_ImportFileListRow);

export default ImportFileListRow;
export { ImportFileListRow };
