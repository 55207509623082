import { remove as removeDiacritics } from 'diacritics';
import { OrderedMap } from 'immutable';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Checkbox } from '@alkem/react-ui-inputs';

import './lite.scss';

export default class OrganizationFilterLite extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    filters: ImmutablePropTypes.list.isRequired,
    selection: ImmutablePropTypes.map.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: 'Organizations',
  };

  state = {
    filterSearch: '',
    filtersFiltered: null,
  };

  cache = {
    filters: null,
    formattedFilters: [],
  };

  filterFilters = debounce((value) => {
    const filters = this.formatFilters();
    if (value == null || value === '') {
      this.setState({ filtersFiltered: null });
    }
    this.setState({
      filtersFiltered: filters.filter((filter) => {
        const name = removeDiacritics(
          (filter.get('nameLegal') || '').toLowerCase(),
        );
        const id = filter.get('id').toString();
        const search = removeDiacritics(value.toLowerCase());
        return name.indexOf(search) >= 0 || id === search;
      }),
    });
  }, 500);

  onSelect = ({ value }, path, selected) => {
    const { onChange, filters } = this.props;
    onChange(
      filters.find((filter) => filter.get('id') === value),
      selected,
    );
  };

  onSearchFilter = (event) => {
    const { value } = event.target;
    this.setState({ filterSearch: value });
    this.filterFilters(value);
  };

  onClearSearch = () => {
    this.setState({ filterSearch: '', filtersFiltered: null });
  };

  onSelectFilter = (filter, isSelected) => {
    this.props.onChange(filter, isSelected);
  };

  formatFilters() {
    const { filters } = this.props;
    if (this.cache.filters === filters) {
      return this.cache.formattedFilters;
    }
    this.cache.filters = filters;
    this.cache.formattedFilters = filters.reduce(
      (dict, filter) => dict.set(filter.get('id'), filter),
      OrderedMap(),
    );
    return this.cache.formattedFilters;
  }

  render() {
    const { id, title, selection } = this.props;
    const { filterSearch, filtersFiltered } = this.state;
    const filters = this.formatFilters();
    return (
      <div className="ReactUiFilter__filter ReactUiFilter__filter--withPadding">
        <h3 className="ReactUiFilter__filterTitle">{title}</h3>
        <div className="OrganizationFilterLite__checkbox">
          <input
            type="text"
            value={filterSearch}
            placeholder="Search by name or ID"
            onChange={this.onSearchFilter}
          />
          {filterSearch && (
            <i className="mdi mdi-close" onClick={this.onClearSearch} />
          )}
        </div>
        <div>
          {(filtersFiltered || filters)
            .toList()
            .slice(0, 10)
            .map((filter) => {
              const filterId = filter.get('id');
              const filterName = filter.get('nameLegal') || filterId;
              const inputId = `${id}-${filterId}`;
              return (
                <div key={inputId}>
                  <Checkbox
                    id={inputId}
                    label={filterName}
                    checked={selection.get(filterId)}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(event) =>
                      this.onSelectFilter(filter, event.target.checked)
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
