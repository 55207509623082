import { List, Map } from 'immutable';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '@alkem/react-ui-checkbox';
import { Tooltip } from '@alkem/react-ui-tooltip';

import { updateSettingValue } from '../../../actions/settings';
import {
  selectEditedSettings,
  selectOrganizationType,
  selectSettings,
} from '../../../selectors/settings';

import './listmultiple.scss';

export interface PathInfos {
  label: string;
  help?: string;
}

type Props = {
  path: string[];
  subPaths: string[];
  pathInfos: PathInfos[];
  options?: any;
  optionsLabelPath?: string;
  optionsIdPath?: string;
  disabled?: boolean;
  getSubLabel?: any;
  onChange?: any;
};

export const OrganizationListMultipleController = React.memo(
  ({
    path,
    subPaths,
    pathInfos,
    options,
    optionsLabelPath = 'label',
    optionsIdPath = 'id',
    disabled,
    getSubLabel = () => {},
    onChange,
  }: Props) => {
    const dispatch = useDispatch();
    const organizationType = useSelector(selectOrganizationType);
    const settings = useSelector(selectSettings);
    const editedSettings = useSelector(selectEditedSettings);

    const optionsToDisplay = options?.filter(
      (option) =>
        !option.get('data')?.get('restrictedToOrganizationType') ||
        option.get('data').get('restrictedToOrganizationType') ===
          organizationType,
    );

    const onValueChange = (optionId, subPath, checked) => {
      let newSetting = editedSettings.getIn(path, settings.getIn(path, Map()));

      if (checked) {
        newSetting = newSetting.set(
          subPath,
          newSetting.get(subPath, List()).push(optionId),
        );
      } else {
        newSetting = newSetting.set(
          subPath,
          newSetting.get(subPath, List()).filter((id) => id !== optionId),
        );
      }
      dispatch(updateSettingValue(path, newSetting));
    };

    const _onChange = (optionId, subPath) => (event) => {
      if (!onChange || onChange({ id: optionId, subPath, checked: event })) {
        onValueChange(optionId, subPath, event);
      }
    };

    const renderCheckbox = ({ id: optionId, subPath }) => {
      const fullPath = path.concat([subPath]);
      const sourceList = settings.getIn(fullPath, List());
      const editedList = editedSettings.getIn(fullPath);
      const checked = editedList
        ? editedList.includes(optionId)
        : sourceList.includes(optionId);
      return (
        <span
          key={`ref-${optionId}-${fullPath.join('-')}`}
          className="OrganizationListMultiple__Columns"
        >
          <Checkbox
            id={`ref-${optionId}-${fullPath.join('-')}`}
            checked={checked}
            onChange={_onChange(optionId, subPath)}
            disabled={disabled}
          />
        </span>
      );
    };

    const renderLabel = ({ label, subLabel }) => {
      if (subLabel) {
        return (
          <span className="OrganizationListMultiple__labelAndSubLabel">
            <span>{label}</span>
            <span>{subLabel}</span>
          </span>
        );
      }
      return <span>{label}</span>;
    };

    const renderOption = ({ option }) => {
      const id = option.get(optionsIdPath);
      const label = option.get(optionsLabelPath);
      const subLabel = getSubLabel(option);

      const checkboxes = subPaths.map((subPath) =>
        renderCheckbox({ id, subPath }),
      );

      return (
        <div key={id} className="OrganizationListMultiple__Row">
          {checkboxes} {renderLabel({ label, subLabel })}
        </div>
      );
    };

    return (
      <div className="OrganizationPageSettingsListController">
        {pathInfos &&
          pathInfos.map((pathInfo) => (
            <div
              data-tip
              data-for={`help-column-${pathInfo.label}`}
              key={pathInfo.label}
              className="OrganizationListMultiple__Columns OrganizationListMultiple__ColumnsLabel"
            >
              {pathInfo.label}
              {!!pathInfo.help && (
                <>
                  <i className="mdi mdi-help-circle" />
                  <Tooltip id={`help-column-${pathInfo.label}`}>
                    {pathInfo.help}
                  </Tooltip>
                </>
              )}
            </div>
          ))}
        {optionsToDisplay?.map((option) => renderOption({ option }))}
      </div>
    );
  },
);

export default OrganizationListMultipleController;
