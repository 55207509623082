import coreApi from 'resources/coreBaseApi';
import indexerApi from 'resources/indexerApi';
import productApi from 'resources/productApi';
import reviewApi from 'resources/reviewApi';
import searchApi from 'resources/searchApi';

export const loadProduct = async (
  productKeyId: number,
  filter_source_includes: string[] = [],
) => {
  const body = {
    filter_source_include: filter_source_includes,

    allow_not_consumer_units: true,
    filter_is_displayable_as_target: false,
    advanced_search: {
      query: productKeyId,
      fields: ['product_key.id'],
    },
  };

  const response = await searchApi.post('/search/v1/productversion/all', body);

  return { product: response.data.data[0] };
};

export const saveProduct = (product, reason) =>
  productApi.post('/product/v3/products/data/upsert', {
    product,
    cause: {
      action: 'maintenance',
      reason,
    },
  });

export const reindexProduct = (productKeyId) =>
  indexerApi.post('/indexer/product/v1/request', {
    product_key_ids: [productKeyId],
  });

export const resyncProduct = (productKeyId) =>
  productApi.post('/product/v2/products/sync', {
    product_key_id: productKeyId,
  });

export const triggerReview = (productKeyId) =>
  reviewApi.post('/review/process/v1/trigger', {
    product_key_id: productKeyId,
  });

export const fetchDocumentation = async () => {
  const response = await coreApi.get('/core/v3/fieldsmetadata/documentation');
  return { fields: response.data.data.filter((f) => !f.isSharingUnitField) };
};
