import { ModuleWrapper } from 'redux/register';

import { IMPORT_GENERIC_LIST_STORE_KEY } from './constants/reducers';
import reducer from './reducers';
import ImportGenericDashboardView from './views';

export default function ImportGenericDashboardViewContainer() {
  return (
    <ModuleWrapper reducerKey={IMPORT_GENERIC_LIST_STORE_KEY} reducer={reducer}>
      <ImportGenericDashboardView />
    </ModuleWrapper>
  );
}
