import { ModuleWrapper } from 'redux/register';

import { GDSN_CIS_LIST_STORE_KEY } from './constants/reducers';
import reducer from './reducers';
import GDSNCISDashboardView from './views';

export default function GDSNCISDashboardViewContainer() {
  return (
    <ModuleWrapper reducerKey={GDSN_CIS_LIST_STORE_KEY} reducer={reducer}>
      <GDSNCISDashboardView />
    </ModuleWrapper>
  );
}
