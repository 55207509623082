import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Text, Textarea } from '@alkem/react-ui-inputs';

import { isValidJSON } from 'utils';

export default class ReferentialManagementAddChildRow extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isUpdating: PropTypes.bool,
    parent: PropTypes.object,
    onAddChild: PropTypes.func,
  };

  state = {
    code: null,
    label: null,
    data: '{}',
  };

  componentDidUpdate(prevProps) {
    const { parent } = this.props;
    if (parent !== prevProps.parent) {
      // eslint-disable-next-line
      this.setState({
        code: null,
        label: null,
        data: '{}',
      });
    }
  }

  onChange = (nodeKey) => (event) => {
    const { value } = event.target;
    this.setState({ [nodeKey]: value });
  };

  onAddChild = () => this.props.onAddChild(this.state);

  render = () => {
    const { parent, isLoading, isUpdating } = this.props;
    const { code, label, data } = this.state;
    const dataClasses = classNames({
      'OrganizationUsesFieldsView__referential__data--invalid':
        data !== null && !isValidJSON(data),
    });
    return (
      <div className="OrganizationUsesFieldsView__referential__update">
        <div className="OrganizationUsesFieldsView__referential__update__row">
          <div>Code</div>
          <div>Label</div>
          <div>Data</div>
        </div>
        <div className="OrganizationUsesFieldsView__referential__update__row">
          <div>
            <Text
              id="organization-referential-update-code"
              value={code}
              onChange={this.onChange('code')}
              disabled={!parent}
            />
          </div>
          <div>
            <Text
              id="organization-referential-update-label"
              onChange={this.onChange('label')}
              value={label}
              disabled={!parent}
            />
          </div>
          <div className={dataClasses}>
            <Textarea
              id="organization-referential-update-data"
              onChange={this.onChange('data')}
              value={data}
              disabled={!parent}
            />
          </div>
          <div>
            <Button
              id="organization-referential-update-button"
              onClick={this.onAddChild}
              content="Add child"
              disabled={isLoading || !label || !code || !parent}
              displaySpinner={isUpdating}
              primary
            />
          </div>
        </div>
      </div>
    );
  };
}
