import { useParams } from 'react-router-dom';
import { ModuleWrapper } from 'redux/register';

import MapperAFSDashboardView from './components';
import { MAPPER_AFS_STORE_KEY } from './constants/reducers';
import reducer from './reducers';

export default function MapperAFSDashboardViewContainer() {
  const { type, id } = useParams();
  return (
    <ModuleWrapper reducerKey={MAPPER_AFS_STORE_KEY} reducer={reducer}>
      <MapperAFSDashboardView type={type} id={id} />
    </ModuleWrapper>
  );
}
