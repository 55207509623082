import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';
import { Spinner } from '@alkem/react-ui-spinner';

import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { separateActions } from 'utils/redux';

import { loadFilters, updateFilter } from '../actions';
import ImportGenericFilters from '../filters';
import ImportFileList from '../list';
import {
  selectFiltersIsLoading,
  selectSearch,
  selectWorkflowSelected,
  selectWorkflows,
} from '../selectors';

import './view.scss';

const mapStateToProps = (state) => ({
  workflows: selectWorkflows(state),
  selectedWorkflow: selectWorkflowSelected(state),
  search: selectSearch(state),
  filtersIsLoading: selectFiltersIsLoading(state),
});

const mapDispatchToProps = {
  loadFilters,
  updateFilter,
};

export class ImportGenericDashboardView extends Component {
  static propTypes = {
    search: PropTypes.string,
    selectedWorkflow: PropTypes.string,
    workflows: PropTypes.object,
    filtersIsLoading: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      loadFilters: PropTypes.func.isRequired,
      updateFilter: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    this.props.actions.loadFilters();
  }

  updateSearchQuery = (e) => {
    this.props.actions.updateFilter('identifier', e);
  };

  selectWorkflow = (workflow) => {
    return () => this.props.actions.updateFilter('workflow', workflow);
  };

  renderLoadingFilters() {
    const headerProps = {
      title: 'Import dashboard',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };
    return (
      <div>
        <HeaderLayout {...headerProps} />
        <div className="container-fluid row">
          <Spinner loading big />
        </div>
      </div>
    );
  }

  renderSelectWorkflow() {
    const headerProps = {
      title: 'Import: Workflow selection',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };
    const { workflows } = this.props;
    let content;
    if (workflows.size) {
      content = workflows.map((workflow) => (
        <div
          key={`select-workflow-${workflow}`}
          className="row ImportDashboardView_Workflow"
          onClick={this.selectWorkflow(workflow)}
        >
          <i className="mdi mdi-database-search" /> {workflow}
        </div>
      ));
    } else {
      content = (
        <div>
          No workflow is available. Table alk_etl.ImportFile is probably empty,
          please insert bootstrapping rows.
        </div>
      );
    }
    return (
      <div>
        <HeaderLayout {...headerProps} />
        <div className="container-fluid row">
          <div className="col-md-2" />
          <div className="col-md-8">{content}</div>
        </div>
      </div>
    );
  }

  renderWorkflowView(selectedWorkflow) {
    const headerProps = {
      title: `Import: ${selectedWorkflow}`,
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };
    return (
      <div>
        <HeaderLayout {...headerProps}>
          <Search
            query={this.props.search}
            updateSearchQuery={this.updateSearchQuery}
            placeholder="Search"
          />
        </HeaderLayout>
        <div className="container-fluid row">
          <div className="col-md-2">
            <ImportGenericFilters />
          </div>
          <div className="col-md-10">
            <ImportFileList />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { filtersIsLoading, selectedWorkflow } = this.props;
    if (filtersIsLoading) {
      return this.renderLoadingFilters();
    } else if (selectedWorkflow === null) {
      return this.renderSelectWorkflow();
    } else {
      return this.renderWorkflowView(selectedWorkflow);
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(ImportGenericDashboardView);
