import moment from 'moment';
import qs from 'querystringify';
import { all, call, put } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import etlApi from 'resources/etlApi';
import fieldsApi from 'resources/fieldsApi';
import referentialAdminApi from 'resources/referentialAdminApi';
import { logError } from 'utils';
import { request } from 'utils/api';
import { takeEverySafe, takeFirst, takeLatestSafe } from 'utils/saga';

import {
  CHANGE_ORDER_EXPORT_MAPPING,
  DELETE_XPATH_ELEMENT,
  GET_EXPORT_MAPPING_LIST,
  GET_FIELD_LIST,
  GET_REFERENTIAL_CODE_LIST,
  GET_USER_DATA,
  GET_XPATH_LIST,
  RECEIVE_EXPORT_MAPPING_LIST,
  RECEIVE_FIELD_OPTIONS,
  RECEIVE_LIST_FIELD_OPTIONS,
  RECEIVE_REFERENTIAL_CODE_OPTIONS,
  RECEIVE_USER_DATA_OPTIONS,
  RECEIVE_XPATH_LIST,
  SAVE_EXPORT_MAPPING,
  SAVE_NEW_XPATH_ELEMENT,
  SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
  SET_SELECTED_EXPORT_MAPPING,
} from '../actions/constants';
import { DATETIME_FORMAT, UNLIMITED_DATE } from '../constants';
import { ExportMapping, Xpath, XpathList } from '../types';
import {
  getFieldOptions,
  getListFieldOptions,
  getOptionsFromStringArray,
  getResultAtRightLevel,
} from '../utils';

export default function* mainSaga() {
  yield takeFirst(GET_XPATH_LIST, getXpathList);
  yield takeFirst(GET_USER_DATA, getUserData);
  yield takeEverySafe(GET_REFERENTIAL_CODE_LIST, getReferentialList);
  yield takeLatestSafe(GET_FIELD_LIST, getFieldList);
  yield takeLatestSafe(GET_EXPORT_MAPPING_LIST, getExportMappingList);
  yield takeLatestSafe(SAVE_EXPORT_MAPPING, saveExportMapping);
  yield takeLatestSafe(CHANGE_ORDER_EXPORT_MAPPING, changeOrderExportMapping);
  yield takeLatestSafe(SAVE_NEW_XPATH_ELEMENT, saveNewXpathElement);
  yield takeLatestSafe(DELETE_XPATH_ELEMENT, deleteXpathElement);
}

function* getUserData() {
  try {
    const [
      reponseCustomParseList,
      reponseFunctionListForValues,
      reponseFunctionListForConstants,
      reponseFunctionListForConditions,
      responseTargetGlnList,
    ] = yield all([
      call(() =>
        etlApi.get('/etl/v2/gdsndashboard/export/custom_parse_list', '', false),
      ),
      call(() =>
        etlApi.get(
          '/etl/v2/gdsndashboard/export/function_list_for_values',
          '',
          false,
        ),
      ),
      call(() =>
        etlApi.get(
          '/etl/v2/gdsndashboard/export/function_list_for_constants',
          '',
          false,
        ),
      ),
      call(() =>
        etlApi.get(
          '/etl/v2/gdsndashboard/export/function_list_for_conditions',
          '',
          false,
        ),
      ),
      call(() =>
        etlApi.get('/etl/v2/gdsndashboard/export/target_gln_list', '', false),
      ),
    ]);

    const customParseOptions = getOptionsFromStringArray(
      reponseCustomParseList.data.data,
    );
    const functionForValuesOptions = getOptionsFromStringArray(
      reponseFunctionListForValues.data.data,
    );
    const functionForConstantsOptions = getOptionsFromStringArray(
      reponseFunctionListForConstants.data.data,
    );
    const functionForConditionsOptions = getOptionsFromStringArray(
      reponseFunctionListForConditions.data.data,
    );
    const targetGlnOptions = getOptionsFromStringArray(
      responseTargetGlnList.data.data,
    );

    yield put({
      type: RECEIVE_USER_DATA_OPTIONS,
      payload: {
        customParseOptions,
        functionForValuesOptions,
        functionForConstantsOptions,
        functionForConditionsOptions,
        targetGlnOptions,
      },
    });
  } catch (error) {
    logError(error);
    yield put({
      type: RECEIVE_USER_DATA_OPTIONS,
    });
    yield put(notificationError('Failed to get user data'));
  }
}

function* getXpathList() {
  try {
    const response = yield call(() =>
      etlApi.get('/etl/v2/gdsndashboard/export/xpaths', '', false),
    );
    const xpathList: XpathList = response.data.data[0];

    yield put({
      type: RECEIVE_XPATH_LIST,
      payload: {
        xpathList,
      },
    });
  } catch (error) {
    logError(error);
    yield put({
      type: RECEIVE_XPATH_LIST,
    });
    yield put(notificationError('Failed to get xpath list'));
  }
}

function* getFieldList({
  entityType,
  search,
  mustGetListFields = false,
  sxmListParentField,
}: {
  entityType: string;
  search: string;
  mustGetListFields?: boolean;
  sxmListParentField?: string;
}) {
  const firstSxmListParent = sxmListParentField?.split('.')[0];

  const { result, error } = yield call(
    request,
    fieldsApi,
    'listMainFields',
    firstSxmListParent || search,
    [
      'tags',
      'displayInfo',
      'children',
      'type',
      'declinable_by',
      'referential',
      'specific',
      'functional_key',
    ],
    entityType,
    { with_disabled: true, with_specific: true, limit: 100 },
  );

  if (error) {
    logError(error);
    yield put(notificationError('Failed to fetch fields'));
  } else {
    const resultAtRightLevel = sxmListParentField
      ? getResultAtRightLevel(result, sxmListParentField)
      : result;
    if (mustGetListFields) {
      const listFieldOptions = getListFieldOptions(resultAtRightLevel);
      yield put({
        type: RECEIVE_LIST_FIELD_OPTIONS,
        payload: {
          listFieldOptions,
        },
      });
    } else {
      const fieldOptions = getFieldOptions(resultAtRightLevel);
      yield put({
        type: RECEIVE_FIELD_OPTIONS,
        payload: {
          fieldOptions,
        },
      });
    }
  }
}

function* getExportMappingList({ xpath }: { xpath: Xpath }) {
  try {
    const response = yield call(() =>
      etlApi.get(
        '/etl/v2/gdsndashboard/export/mappings',
        qs.stringify(
          {
            id_xpath: xpath.id,
          },
          true,
        ),
        false,
      ),
    );
    const exportMappings = response.data.data[0];

    yield put({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings,
      },
    });
  } catch (error) {
    logError(error);
    yield put({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings: [],
      },
    });
    yield put(
      notificationError(
        `Failed to get export mappings for xpath ${xpath.name}`,
      ),
    );
  }
}

function* saveExportMapping({
  exportMapping,
  xpath,
}: {
  exportMapping: ExportMapping;
  xpath: Xpath;
}) {
  try {
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: true },
    });

    if (typeof exportMapping.id === 'undefined') {
      yield call(() =>
        etlApi.post(
          '/etl/v2/gdsndashboard/export/mappings/post',
          exportMapping,
        ),
      );
    } else {
      yield call(() =>
        etlApi.put(
          `/etl/v2/gdsndashboard/export/mappings/put/${exportMapping.id}`,
          exportMapping,
        ),
      );
    }

    yield put({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings: [],
      },
    });
    yield put({
      type: SET_SELECTED_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          date_start: moment().format(DATETIME_FORMAT),
          date_end: UNLIMITED_DATE,
          order: 0,
          xpath_id: xpath.id,
          payload: {},
        },
      },
    });
    yield getExportMappingList({ xpath });
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationSuccess('Export mapping saved'));
  } catch (error) {
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(
      notificationError(
        'Failed to save export mapping. Check the inspector console.',
      ),
    );
    logError(error, { force: true });
  }
}

function* getReferentialList({ referential }: { referential: string }) {
  try {
    const { result } = yield call(
      request,
      referentialAdminApi,
      'ReferentialGetList',
      referential,
      { lang: 'noop' },
    );

    const referentialCodeOptions = result?.map(
      (referentialCode: { id: number; code: string }) => ({
        id: referentialCode.id,
        label: referentialCode.code,
      }),
    );

    yield put({
      type: RECEIVE_REFERENTIAL_CODE_OPTIONS,
      payload: {
        referential,
        referentialCodeOptions,
      },
    });
  } catch (error) {
    logError(error);
    yield put(
      notificationError(
        `Failed to get referential codes list for referential ${referential}`,
      ),
    );
  }
}

function* changeOrderExportMapping({
  exportMappingOrders,
  xpath,
}: {
  exportMappingOrders: [{ mapping_id: number; order: number }];
  xpath: Xpath;
}) {
  try {
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: true },
    });

    yield call(() =>
      etlApi.post('/etl/v2/gdsndashboard/export/mappings/changeOrder', {
        mappings: exportMappingOrders,
      }),
    );

    yield put({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings: [],
      },
    });
    yield put({
      type: SET_SELECTED_EXPORT_MAPPING,
    });
    yield getExportMappingList({ xpath });
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationSuccess('Change order export mapping saved'));
  } catch (error) {
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationError('Failed to change order export mapping'));
    logError(error);
  }
}

function* saveNewXpathElement({
  name,
  moduleName,
  elementType,
  order,
  parentId,
}: {
  name: string;
  moduleName?: string;
  elementType: string;
  order: number;
  parentId?: number;
}) {
  try {
    yield call(() =>
      etlApi.post('/etl/v2/gdsndashboard/export/xpath/post', {
        name: name,
        module_name: moduleName,
        type: elementType,
        order,
        parent_id: parentId,
      }),
    );

    yield getXpathList();

    yield put(notificationSuccess('New xpath element saved'));
  } catch (error) {
    yield put(
      notificationError(
        'Failed to save new xpath element. Check the inspector console.',
      ),
    );
    logError(error, { force: true });
  }
}

function* deleteXpathElement({ xpath }: { xpath: Xpath }) {
  try {
    yield call(() =>
      etlApi.put(`/etl/v2/gdsndashboard/export/xpath/archive/${xpath.id}`),
    );

    yield getXpathList();

    yield put(notificationSuccess('Xpath element archived'));
  } catch (error) {
    yield put(
      notificationError(
        'Failed to archived xpath element. Check the inspector console.',
      ),
    );
    logError(error, { force: true });
  }
}
