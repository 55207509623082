import Immutable from 'immutable';

import { get } from 'utils/fp';
import { createReducer } from 'utils/reducers';

import {
  FETCH_PROFILE,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGOUT,
  MODULE_KEY,
  RECEIVE_PROFILE,
} from './constants';

export const initialState = Immutable.Map({
  isAuthPending: false,
  isAuthenticated: false,
  loginError: null,
  isFetchingProfile: true,
  permissions: undefined,
});

export default createReducer(initialState, {
  [LOGIN_REQUEST]: () => initialState.set('isAuthPending', true),
  [LOGIN_ERROR]: (state, action) =>
    initialState.set('loginError', action.error),
  [LOGIN]: () =>
    initialState.set('isAuthenticated', true).set('isFetchingProfile', true),
  [LOGOUT]: () => initialState.set('isFetchingProfile', false),
  [FETCH_PROFILE]: () => initialState.set('isFetchingProfile', true),
  [RECEIVE_PROFILE]: (state, { permissions }) =>
    initialState.withMutations((newState) =>
      newState
        .set('isAuthenticated', true)
        .set('isFetchingProfile', false)
        .set('permissions', permissions),
    ),
});

const withModuleState = (getter) => (state) =>
  MODULE_KEY in state ? getter(state[MODULE_KEY]) : undefined;

export const selectIsAuthenticated = withModuleState(get('isAuthenticated'));
export const selectLoginError = withModuleState(get('loginError'));
export const selectIsAuthPending = withModuleState(get('isAuthPending'));
export const selectIsFetchingProfile = withModuleState(
  get('isFetchingProfile'),
);
export const selectPermissions = withModuleState(get('permissions'));
