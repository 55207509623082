import qs from 'querystringify';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notifications';
import * as routes from 'constants/routes';
import EtlApi from 'resources/etlApi';
import { saveAs } from 'utils';
import { get } from 'utils/immutable';
import { navigate } from 'utils/location';

import {
  addImportListFilters,
  importListIsLoading,
  receiveImportDetails,
  receiveImportList,
  receiveImportSpider,
  removeImportListFilters,
  updateImportListFilters,
} from '../actions';
import {
  ADD_IMPORT_LIST_FILTER,
  CHANGE_LIMIT_IMPORT_LIST,
  CLEAR_IMPORT_LIST_FILTER,
  GET_IMPORT_DETAILS,
  GET_IMPORT_LIST,
  GET_IMPORT_SPIDER,
  NEXT_PAGE_IMPORT_LIST,
  PREVIOUS_PAGE_IMPORT_LIST,
  PRODUCT_IMPORT_REPORT_GENERATE,
  PRODUCT_IMPORT_REPORT_GET_FILE,
  PUSH_IMPORT,
  REDIRECT_TO_TRANSACTION_DASHBOARD,
  REMOVE_IMPORT_LIST_FILTER,
  SEARCH_IMPORT_LIST,
  SET_IMPORT_STATUS,
  SET_SUPPORT_STATUS,
  UPDATE_IMPORT_LIST_FILTER,
  UPDATE_SPIDER,
} from '../constants/events';
import { selectFilters, selectPagination, selectSearch } from '../selectors';

export default function* importMainSaga() {
  yield takeLatest(GET_IMPORT_DETAILS, getImportDetails);
  yield takeLatest(PUSH_IMPORT, pushImport);
  yield takeLatest(
    REDIRECT_TO_TRANSACTION_DASHBOARD,
    redirectToTransactionDashboard,
  );
  yield takeLatest(PRODUCT_IMPORT_REPORT_GENERATE, productImportReportGenerate);
  yield takeLatest(PRODUCT_IMPORT_REPORT_GET_FILE, productImportReportGetFile);
  yield takeLatest(SET_SUPPORT_STATUS, setSupportStatus);
  yield takeLatest(SET_IMPORT_STATUS, setImportStatus);
  yield takeLatest(UPDATE_SPIDER, updateSpider);
  yield takeLatest(GET_IMPORT_SPIDER, getImportSpider);
  yield takeLatest(UPDATE_IMPORT_LIST_FILTER, updateImportListFilter);
  yield takeLatest(
    [
      GET_IMPORT_LIST,
      SEARCH_IMPORT_LIST,
      ADD_IMPORT_LIST_FILTER,
      REMOVE_IMPORT_LIST_FILTER,
      UPDATE_IMPORT_LIST_FILTER,
      CLEAR_IMPORT_LIST_FILTER,
      CHANGE_LIMIT_IMPORT_LIST,
      NEXT_PAGE_IMPORT_LIST,
      PREVIOUS_PAGE_IMPORT_LIST,
    ],
    getImportList,
  );
}

function* getImportDetails(data) {
  try {
    const response = yield call(
      [EtlApi, EtlApi.ProductImportReportGet],
      data.importfileId,
      data.filters,
    );
    yield put(
      receiveImportDetails(
        data.importfileId,
        response.data.data.productImports,
      ),
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getImportDetails'));
    }
  }
}

function* getImportList({ payload }) {
  try {
    yield put(importListIsLoading());
    const pagination = yield select(selectPagination);
    const search = yield select(selectSearch);
    const filters = yield select(selectFilters);

    const qsv = {};
    const filter_references_in = search
      ? search
          .split(',')
          .map((ref) => ref.trim())
          .join(',') || null
      : null;

    const filter_step_name_status = search
      ? search
          .split(',')
          .filter((s) => s.includes('|'))
          .map((f) => f.trim())[0]
      : null;

    const qFilters = {
      offset: (pagination.currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      filter_references_in,
      filter_step_name_status,
      with_gtins: false,
    };

    if (search) {
      qsv.q = search;
    }

    if (filters.spiders.length > 0) {
      qFilters.filter_spiders = filters.spiders.map((o) => o.value);
      qsv.spiders = filters.spiders.map((o) => o.value);
    }

    if (filters.organization.length > 0) {
      qFilters.filter_organization_ids = filters.organization.map(
        (o) => o.value,
      );
      qsv.filter_organization_ids = filters.organization.map((o) => o.value);
    }

    if (filters.unknown_gln.length >= 1 && filters.unknown_gln[0].value) {
      qFilters.filter_unknown_gln = true;
      qsv.unknown_gln = true;
    } else {
      qFilters.filter_unknown_gln = false;
      qsv.unknown_gln = false;
    }

    if (filters.empty_out.length >= 1 && filters.empty_out[0].value) {
      qFilters.filter_empty_out = true;
      qsv.empty_out = true;
    } else {
      qFilters.filter_empty_out = false;
      qsv.empty_out = false;
    }

    if (filters.user_out.length >= 1 && filters.user_out[0].value) {
      qFilters.filter_user_ids_out = filters.user_out[0].value;
      qsv.user_out = filters.user_out[0].value;
    } else {
      delete qFilters.filter_user_ids_out;
      delete qsv.user_out;
    }

    if (filters.status.length > 0) {
      qFilters.filter_status = filters.status.map((o) => o.value);
      qsv.status = filters.status.map((o) => o.value);
    }

    if (filters.supportStatus.length > 0) {
      qFilters.filter_supportStatus = filters.supportStatus.map((o) => o.value);
      qsv.supportStatus = filters.supportStatus.map((o) => o.value);
    }

    if (filters.filter_ids && filters.filter_ids.length > 0) {
      qFilters.filter_ids = filters.filter_ids.map((o) => o.value);
      qsv.filter_ids = filters.filter_ids.map((o) => o.value);
    }

    if (filters.dates.length > 0) {
      filters.dates.forEach((date) => {
        qFilters[`filter_${date.name}`] = date.value;
        qsv[date.name] = date.value;
      });
    }
    if (get(payload, 'replaceHistory') !== false) {
      yield call(navigate, {
        to: `${location.pathname}${qs.stringify(qsv, true)}`,
        replace: true,
      });
    }

    yield delay(500);
    const response = yield call(
      [EtlApi, EtlApi.ProductImportReportList],
      qFilters,
    );
    yield put(
      receiveImportList(response.data.data, response.data.totalResults),
    );
    yield put(receiveImportSpider({ ...filters }));
  } catch (error) {
    yield put(notificationError('An error occured: getImportList'));
  }
}

function* pushImport(data) {
  try {
    yield call([EtlApi, EtlApi.ProductImportPush], data.payload);
    yield put(notificationSuccess('Import pushed for integration :)'));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: pushImport'));
    }
  }
}

function* productImportReportGenerate(payload) {
  try {
    const response = yield call([EtlApi, EtlApi.ProductImportReportGenerate], {
      pif_id: payload.pifId,
    });
    saveAs(response.data, 'Errors.xlsx');
  } catch (error) {
    yield put(notificationError('Unable to get import report error file.'));
  }
}

function* productImportReportGetFile(payload) {
  try {
    if (
      !payload.index &&
      (payload.importFile.name === '' ||
        payload.importFile.path === '/' ||
        payload.importFile.path === '')
    ) {
      yield put(notificationError('No file attached to this import'));
    } else {
      let file;
      if (payload.index === undefined) {
        if (
          payload.importFile.name !== 'Unknown location' &&
          payload.importFile.name
        ) {
          file = payload.importFile.name;
        } else {
          file =
            payload.importFile.replayParameters &&
            payload.importFile.replayParameters.fetcher &&
            payload.importFile.replayParameters.fetcher.location &&
            payload.importFile.replayParameters.fetcher.location
              .split('/')
              .pop();
        }
      } else {
        file = payload.importFile.parameters.fetcher.locations[payload.index]
          .split('/')
          .pop();
      }
      const response = yield call(
        [EtlApi, EtlApi.ProductImportReportGetFile],
        payload.importFile.id,
        payload.index,
      );
      saveAs(response.data, file);
    }
  } catch (error) {
    yield put(notificationError('Unable to get file.'));
  }
}

function* redirectToTransactionDashboard(data) {
  try {
    yield call(navigate, {
      to: `${routes.transaction}?sourceEntities=ProductImport:${data.productImportID}`,
    });
  } catch (error) {
    yield put(notificationError('Cannot redirect to transaction.'));
  }
}

function* setSupportStatus(data) {
  try {
    yield call(
      [EtlApi, EtlApi.ProductImportFileSupportStatus],
      data.importfileId,
      { new_status: data.status },
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: setSupportStatus'));
    }
  }
}

function* setImportStatus(data) {
  try {
    yield call([EtlApi, EtlApi.ImportFileStatus], data.importfileId, {
      status: data.status,
    });
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: setImportStatus'));
    }
  }
}

function* updateImportListFilter({ key, value }) {
  const filters = yield select(selectFilters);
  const isInFilter = filters[key].find((s) => s.value === value.value);
  if (!isInFilter) {
    yield put(addImportListFilters(key, value));
  } else {
    yield put(removeImportListFilters(key, value));
  }
}

function* updateSpider({ name, value, selected }) {
  try {
    const filters = yield select(selectFilters);
    if (
      filters.organization.length > 0 &&
      filters.spiders.length === 1 &&
      selected === false
    ) {
      yield put(
        notificationError(
          'You must clear your organization filters before removing all your format filters',
        ),
      );
    } else {
      const isSpiderInFilter = filters.spiders.find(
        (s) => s.value === value.value,
      );
      if (selected && !isSpiderInFilter) {
        yield put(addImportListFilters(name, value, selected));
      } else if (!selected && isSpiderInFilter) {
        yield put(removeImportListFilters(name, value, selected));
      } else {
        yield put(updateImportListFilters(name, value, selected));
      }
      yield put(receiveImportSpider({ ...filters }));
    }
  } catch (error) {
    yield put(notificationError('An error occured: updateSpider'));
  }
}

function* getImportSpider() {
  try {
    const response = yield call([EtlApi, EtlApi.ProductImportFilters]);
    yield put(receiveImportSpider(response.data.data));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getImportFilters'));
    }
  }
}
