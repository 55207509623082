import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import InputWithLabel from 'components/input-with-label';
import { Rule, RuleSelector } from 'modules/validation-dashboard/types';
import { copyEntryValue } from 'modules/validation-dashboard/utils';

import './validation-conditions.scss';

interface Props {
  rule: Rule;
  id: string;
}

function syntaxHighlight(json: string) {
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  json = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return `<span class="${cls}"> ${match}</span>`;
    },
  );
  return `<div>${json}</div>`;
}

export const ConditionDisplayItems = ({
  id,
  items,
  withCopyButton = true,
  withErrorDisplaySelection = false,
}: {
  id: string;
  items: RuleSelector[];
  withCopyButton?: boolean;
  withErrorDisplaySelection?: boolean;
}) => {
  const dispatch = useDispatch();
  return (
    <div
      className="ConditionDisplayItems alk-flex alk-flex-column flex-grow--1"
      id={id}
    >
      {items.map(({ key, value, id: entryId, usedToDisplayErrors }) => (
        <div
          key={`entry-${entryId}`}
          className="ConditionDisplayItem alk-flex alk-flex-column"
        >
          <div className="ConditionDisplayItem__keyValue alk-flex alk-flex-row alk-flex-baseline">
            <code>${key}</code>&nbsp;
            <span
              className="pre"
              dangerouslySetInnerHTML={{
                __html:
                  typeof value === 'string'
                    ? value.trim()
                    : syntaxHighlight(JSON.stringify(value, undefined, 2)),
              }}
            />
            {withCopyButton && (
              <LazyTooltip
                id={`copy-value-${id}-button`}
                place="bottom"
                tooltipLabel="Copy value"
                className="ConditionDisplayItem__copyTooltip"
              >
                <Button
                  small
                  link
                  className="ConditionDisplayItem__copyButton"
                  onClick={() => copyEntryValue(value, dispatch)}
                >
                  <i className="text-dark mdi mdi-content-copy"></i>
                </Button>
              </LazyTooltip>
            )}
          </div>
          {withErrorDisplaySelection && (
            <div className="ConditionDisplayItem__errorDisplay">
              Used to display errors:{' '}
              {usedToDisplayErrors ? (
                <span className="ConditionDisplayItem__usedToDisplayErrors">
                  YES
                </span>
              ) : (
                <span className="ConditionDisplayItem__notUsedToDisplayErrors">
                  NO
                </span>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const ConditionDisplayText = ({
  id,
  content,
  dispatch,
  pre,
}: {
  id: string;
  content: string;
  dispatch: any;
  pre?: boolean;
}) => {
  return (
    <div className="ConditionDisplayText alk-flex alk-flex-row alk-flex-baseline">
      <div
        className={classNames({ pre })}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <LazyTooltip
        id={`copy-value-${id}-button`}
        place="left"
        tooltipLabel="Copy value"
        className="ConditionDisplayText__copyTooltip"
      >
        <Button
          small
          link
          className="ConditionDisplayText__copyButton"
          onClick={() => copyEntryValue(content, dispatch)}
        >
          <i className="text-dark mdi mdi-content-copy"></i>
        </Button>
      </LazyTooltip>
    </div>
  );
};

export const ConditionsDisplay = ({ rule, id }: Props) => {
  const dispatch = useDispatch();
  return (
    <div className="ConditionsDisplay" id={id}>
      {rule.constants && !isEqual(rule.constants, {}) && (
        <InputWithLabel
          inputId="constants-display"
          label="Constants"
          extraInputClassNames={{ ConditionDisplayItems__constants: true }}
        >
          <ConditionDisplayItems
            id="constants-display"
            items={rule.constantList}
          />
        </InputWithLabel>
      )}
      {rule.selectorList.length && (
        <InputWithLabel inputId="selectors-display" label="Selectors">
          <ConditionDisplayItems
            id="selectors-display"
            items={rule.selectorList}
            withErrorDisplaySelection
          />
        </InputWithLabel>
      )}

      <hr />

      {rule.condition && (
        <InputWithLabel inputId="condition-display" label="Condition">
          <ConditionDisplayText
            id="condition"
            content={rule.condition}
            pre
            dispatch={dispatch}
          />
        </InputWithLabel>
      )}
      {rule.validation && (
        <InputWithLabel inputId="validation-display" label="Validation">
          <ConditionDisplayText
            id="validation"
            content={rule.validation}
            pre
            dispatch={dispatch}
          />
        </InputWithLabel>
      )}
      <hr />

      {rule.errorMessage && (
        <InputWithLabel inputId="error-message-display" label="Error message">
          <ConditionDisplayText
            id="errorMessage"
            content={rule.errorMessage}
            dispatch={dispatch}
          />
        </InputWithLabel>
      )}
    </div>
  );
};
