import { set } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import {
  PRODUCT_LOADED,
  REINDEX,
  REINDEX_FAILURE,
  REINDEX_SUCCESS,
  RESYNC,
  RESYNC_FAILURE,
  RESYNC_SUCCESS,
  REVIEW,
  REVIEW_FAILURE,
  REVIEW_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_REASON,
} from './constants';

const initialState = {
  product: {},
  reason: '',

  isSaving: false,
  isReindexing: false,
  isResyncing: false,
  isReviewing: false,
};

export default createReducer(initialState, {
  [PRODUCT_LOADED]: (state, { product }) => ({ ...state, product }),
  [UPDATE_PRODUCT]: (state, { key, value }) => ({
    ...state,
    product: set(key, value, state.product),
  }),
  [UPDATE_REASON]: (state, { reason }) => ({ ...state, reason }),
  [SAVE]: (state) => ({ ...state, isSaving: true }),
  [SAVE_FAILURE]: (state) => ({ ...state, isSaving: false }),
  [SAVE_SUCCESS]: (state) => ({ ...state, isSaving: false }),
  [REINDEX]: (state) => ({ ...state, isReindexing: true }),
  [REINDEX_FAILURE]: (state) => ({ ...state, isReindexing: false }),
  [REINDEX_SUCCESS]: (state) => ({ ...state, isReindexing: false }),
  [RESYNC]: (state) => ({ ...state, isResyncing: true }),
  [RESYNC_FAILURE]: (state) => ({ ...state, isResyncing: false }),
  [RESYNC_SUCCESS]: (state) => ({ ...state, isResyncing: false }),
  [REVIEW]: (state) => ({ ...state, isReviewing: true }),
  [REVIEW_FAILURE]: (state) => ({ ...state, isReviewing: false }),
  [REVIEW_SUCCESS]: (state) => ({ ...state, isReviewing: false }),
});
