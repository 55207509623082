import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FilterDate from './date';
import './filter.scss';
import FilterList from './list';
import FilterTree from './tree';

class Filter extends PureComponent {
  static propTypes = {
    filters: PropTypes.array,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    hasLinks: PropTypes.bool,
    listLimit: PropTypes.number,
  };

  static defaultProps = {
    filters: [],
    title: '',
    type: '',
    hasLinks: false,
    listLimit: -1,
  };

  renderTitle() {
    const { title } = this.props;
    if (title) {
      return <h3 className="Filter__title">{title}</h3>;
    }
    return null;
  }

  renderFilter() {
    const { filters, name, onChange, type, hasLinks, listLimit } = this.props;
    switch (type) {
      case 'list':
        return <FilterList filters={filters} name={name} onChange={onChange} />;
      case 'tree':
        return (
          <FilterTree
            filters={filters}
            name={name}
            onChange={onChange}
            hasLinks={hasLinks}
            listLimit={listLimit}
          />
        );
      case 'date':
        return <FilterDate filters={filters} onChange={onChange} name={name} />;
      default:
        return null;
    }
  }

  render() {
    const titleDOM = this.renderTitle();
    const filtersDOM = this.renderFilter();

    return (
      <div className="Filter">
        <div className="Filter__fieldset">
          {titleDOM}
          {filtersDOM}
        </div>
      </div>
    );
  }
}

export default Filter;
