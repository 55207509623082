import classNames from 'classnames';
import { Map } from 'immutable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';

import Filter from 'components/ui/filter';
import OrganizationFilter from 'components/ui/filter/organization';
import SelectedFilters from 'components/ui/selected-filters';

import {
  addImportListFilters,
  clearImportListFilters,
  getImportSpider,
  removeImportListFilters,
  updateImportListFilters,
  updateSpider,
} from '../actions';
import { dataSpiders, rangeSpiders, rfpSpiders } from '../constants';
import { selectFilters, selectFiltersAvailable } from '../selectors';

import './filters.scss';

export const DATE = 'date';
export const DATE_FROM = 'date_from';
export const DATE_TO = 'date_to';
export const DATES = 'dates';
export const EMPTY_OUT = 'empty_out';
export const ORGA = 'organization';
export const UNKNOWN_GLN = 'unknown_gln';
export const USER_OUT = 'user_out';
export const FILTER_IDS = 'filter_ids';

export const MONITORING_USER = {
  label: 'Hide monitoring probe',
  value: 79,
  checked: true,
};
export const UNKNOWN_GLN_CHECKED = {
  label: 'Hide unknown GLN',
  value: true,
  checked: true,
};
export const EMPTY_OUT_CHECKED = {
  label: 'Hide empty imports',
  value: true,
  checked: true,
};

export const ImportFilters = () => {
  const filters: any = useSelector(selectFilters);
  const filtersAvailable: Map<string, any> = useSelector(
    selectFiltersAvailable,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getImportSpider());
  }, [dispatch]);

  const onOrganizationFilterChange = (filter, newValue) => {
    if (newValue === false) {
      dispatch(
        removeImportListFilters('organization', {
          label: filter.get('nameLegal'),
          value: filter.get('id'),
          checked: true,
        }),
      );
    } else {
      dispatch(
        addImportListFilters('organization', {
          label: filter.get('nameLegal'),
          value: filter.get('id'),
          checked: false,
        }),
      );
    }
  };

  const onFilterUnknownGLN = (checked) => {
    if (checked) {
      dispatch(addImportListFilters(UNKNOWN_GLN, UNKNOWN_GLN_CHECKED));
    } else {
      dispatch(removeImportListFilters(UNKNOWN_GLN, UNKNOWN_GLN_CHECKED));
    }
  };

  const onFilterMonitoringUser = (checked) => {
    if (checked) {
      dispatch(addImportListFilters(USER_OUT, MONITORING_USER));
    } else {
      dispatch(removeImportListFilters(USER_OUT, MONITORING_USER));
    }
  };

  const onFilterEmptyOut = (checked) => {
    if (checked) {
      dispatch(addImportListFilters(EMPTY_OUT, EMPTY_OUT_CHECKED));
    } else {
      dispatch(removeImportListFilters(EMPTY_OUT, EMPTY_OUT_CHECKED));
    }
  };

  const areAllSpidersFiltered = (spiderGroup) => {
    return spiderGroup.every((sg) => {
      const fa = filtersAvailable
        .get('spiders')
        .find((s) => s.get('value') === sg.value);
      if (fa) {
        return fa.get('checked') === true;
      }
      return true;
    });
  };

  const createOnClickFilterImportType = (spiderGroup) => () => {
    const add = areAllSpidersFiltered(spiderGroup);
    spiderGroup.forEach((sg) => {
      const fa = filtersAvailable
        .get('spiders')
        .find((s) => s.get('value') === sg.value);
      if (fa) {
        dispatch(updateSpider('spiders', sg, !add));
      }
    });
  };

  const onFilterChange = (key, value) => {
    dispatch(updateImportListFilters(key, value));
  };

  const onSpiderChange = (name, value, selected) => {
    dispatch(updateSpider(name, value, selected));
  };

  const onRemoveFilter = (key, value) => {
    dispatch(removeImportListFilters(key, value));
  };

  const onRemoveAllFilters = (key, value) => {
    dispatch(clearImportListFilters(key, value));
  };

  const selectedOrgas = filters.organization.reduce(
    (prev, curr) => prev.set(curr.value, true),
    Map(),
  );

  return (
    <div>
      <SelectedFilters
        filters={filters}
        removeFilter={onRemoveFilter}
        removeAllFilters={onRemoveAllFilters}
      />
      <div className="card">
        <Filter
          type="tree"
          filters={filtersAvailable.get('spiders').toJS()}
          onChange={onSpiderChange}
          name="spiders"
          title={
            <div>
              <span>
                <i className="mdi mdi-file-xml" /> Formats
              </span>
              <div className="ImportFileListFilter__spiderCategory">
                <Button
                  onClick={createOnClickFilterImportType(dataSpiders)}
                  className={classNames(
                    'ImportFileListFilter__spiderCategoryProduct',
                    'ImportFileListFilter__spiderCategory',
                    { active: areAllSpidersFiltered(dataSpiders) },
                  )}
                >
                  Product
                </Button>
                <Button
                  onClick={createOnClickFilterImportType(rangeSpiders)}
                  className={classNames(
                    'ImportFileListFilter__spiderCategoryRange',
                    'ImportFileListFilter__spiderCategory',
                    { active: areAllSpidersFiltered(rangeSpiders) },
                  )}
                >
                  Range
                </Button>
                <Button
                  onClick={createOnClickFilterImportType(rfpSpiders)}
                  className={classNames(
                    'ImportFileListFilter__spiderCategoryRfp',
                    'ImportFileListFilter__spiderCategory',
                    { active: areAllSpidersFiltered(rfpSpiders) },
                  )}
                >
                  RFP
                </Button>
              </div>
            </div>
          }
        />
        <OrganizationFilter
          id="import-filter-organization"
          filters={filtersAvailable.get('organization')}
          selection={selectedOrgas}
          onChange={onOrganizationFilterChange}
          title={
            <span>
              <i className="mdi mdi-account-group" /> Organizations
            </span>
          }
        />
        <Checkbox
          id="import-hide-unknown-gln"
          checked={filters[UNKNOWN_GLN].some(
            (x) => x.value === UNKNOWN_GLN_CHECKED.value,
          )}
          onChange={onFilterUnknownGLN}
          label={
            <div>
              <i className="mdi mdi-account-question" />
              Hide imports with unknown GLN
            </div>
          }
        />
        <Checkbox
          id="import-hide-monitoring-user"
          checked={filters[USER_OUT].some(
            (u) => u.value === MONITORING_USER.value,
          )}
          onChange={onFilterMonitoringUser}
          label={
            <div>
              <i className="mdi mdi-robot" />
              Hide imports for user alk_monitoring (79)
            </div>
          }
        />
        <Checkbox
          id="import-hide-empty"
          checked={filters[EMPTY_OUT].some(
            (x) => x.value === EMPTY_OUT_CHECKED.value,
          )}
          onChange={onFilterEmptyOut}
          label={
            <div>
              <i className="mdi mdi-null" />
              Hide empty imports
            </div>
          }
        />
        <Filter
          type="list"
          filters={filtersAvailable.get('status').toJS()}
          onChange={onFilterChange}
          name="status"
          title={
            <span>
              <i className="mdi mdi-traffic-light" /> Status
            </span>
          }
        />
        <Filter
          type="list"
          filters={filtersAvailable.get('supportStatus').toJS()}
          onChange={onFilterChange}
          name="supportStatus"
          title={
            <span>
              <i className="mdi mdi-face-agent" /> Support Status
            </span>
          }
        />
        <Filter
          type="date"
          filters={filtersAvailable.get('dates').toJS()}
          onChange={onFilterChange}
          name="dates"
          title={
            <span>
              <i className="mdi mdi-calendar-search" /> Dates
            </span>
          }
        />
      </div>
    </div>
  );
};

export default ImportFilters;
