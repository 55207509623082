import classNames from 'classnames';
import { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { SwitchButton } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import { Spinner } from '@alkem/react-ui-spinner';
import { Tooltip } from '@alkem/react-ui-tooltip';

import {
  hasBillingChanges,
  selectBillingChanges,
  selectIsBigManufacturer,
} from 'modules/organization-page/selectors/billing';
import { separateActions } from 'utils/redux';

import { updateGroupRelation } from '../../actions/billing';

import { fetchGroups } from './api';
import './index.scss';
import { Group } from './types';
import { isActiveGroup, isGroupDeactivationRequested } from './utils';

const getConfirmButtonLabel = (groups: Group[], groupId: number) => {
  const group = groups.find(({ id }) => id === groupId);
  if (group) {
    return `Remove ${group.label}`;
  } else {
    return 'Remove group';
  }
};

interface Props {
  organizationId: number;
  hasChanges: boolean;
  changes: { [key: number]: boolean };
  isBigManufacturer: boolean;
  isReadOnly: boolean;
  actions: {
    updateGroupRelation: ({
      groupId,
      isEnabled,
    }: {
      groupId: number | null;
      isEnabled: boolean;
    }) => void;
  };
}

const mapStateToProps = createStructuredSelector({
  hasChanges: hasBillingChanges,
  changes: selectBillingChanges,
  isBigManufacturer: selectIsBigManufacturer,
});

const mapDispatchToProps = {
  updateGroupRelation,
};

function OrganizationBilling({
  organizationId,
  hasChanges,
  changes,
  isBigManufacturer,
  actions,
  isReadOnly,
}: Props) {
  const [groups, setGroups] = useState([] as Group[]);
  const [requireConfirmation, setRequireConfirmation] = useState<number | null>(
    null,
  );

  useEffect(() => {
    let mounted = true;
    fetchGroups(organizationId).then((groups) => {
      if (mounted) {
        setGroups(groups);
      }
    });
    return () => {
      mounted = false;
    };
  }, [organizationId]);

  const updateRelation = useCallback(
    (groupId: number) => (isEnabled: boolean) => {
      if (isBigManufacturer) {
        actions.updateGroupRelation({ groupId, isEnabled });
      } else if (isEnabled === false) {
        setRequireConfirmation(groupId);
      }
    },
    [actions, isBigManufacturer],
  );

  const closeModal = useCallback(() => {
    setRequireConfirmation(null);
  }, []);

  const deactivateGroup = useCallback(() => {
    actions.updateGroupRelation({
      groupId: requireConfirmation,
      isEnabled: false,
    });
    setRequireConfirmation(null);
  }, [actions, requireConfirmation]);

  if (!isBigManufacturer) {
    return null;
  }

  return (
    <div className="OrganizationBilling OrganizationPageBlock">
      <div className="OrganizationPageBlock__header">
        <h2>Billing</h2>
        <span className="OrganizationPageBlock__edited">
          {hasChanges && (
            <span>
              <i className="mdi mdi-alert" />
              Edited
            </span>
          )}
        </span>
      </div>
      {!isBigManufacturer && (!groups || !groups.length) ? (
        <>
          <Spinner medium center />
          <br />
        </>
      ) : (
        <>
          <ul className="OrganizationBilling__list">
            {(groups || []).map((group) => {
              const checked =
                group.id in changes ? changes[group.id] : isActiveGroup(group);
              const deactivationRequested =
                checked && isGroupDeactivationRequested(group);
              return (
                <li
                  key={group.id}
                  className={classNames({
                    OrganizationBilling__groupRow: true,
                    OrganizationBilling__groupDeactivationRequested:
                      deactivationRequested,
                  })}
                >
                  <SwitchButton
                    content={group.label}
                    checked={checked}
                    onChange={updateRelation(group.id)}
                    disabled={isReadOnly || (!isBigManufacturer && !checked)}
                  />
                  {!group.hasPayingRecipient && (
                    <>
                      <i
                        data-tip
                        data-for={`invalid-group-${group.id}`}
                        className="mdi mdi-alert alk-txt-danger"
                      />
                      <Tooltip id={`invalid-group-${group.id}`} place="top">
                        Group enabled but no paying organization in the
                        recipient list
                      </Tooltip>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
      {requireConfirmation && (
        <Modal
          danger
          modalStyle="dynamic"
          title="Are you sure you want to proceed?"
          confirmButtonText={getConfirmButtonLabel(groups, requireConfirmation)}
          onConfirm={deactivateGroup}
          onClose={closeModal}
        >
          By deactivating this recipient, you are removing the time constraint
          on this account and decreasing their invoice accordingly.
        </Modal>
      )}
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(memo(OrganizationBilling));
