import { curry, isFunction, get as ldget } from 'lodash';

const isImmutable = (o) => isFunction(ldget(o, 'getIn'));

/**
 * Get the value from an immutable or plain js object along a path of one or
 * many keys.
 *
 * Examples: see unit tests.
 */
export const get = curry(
  (path, o) => (isImmutable(o) ? o.getIn(path.split('.')) : ldget(o, path)),
  2,
);
