import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { validationFecthOrganizationsCache } from 'modules/validation-dashboard/api';
import { selectValidationCachedOrganizations } from 'modules/validation-dashboard/selectors';
import { RuleSet } from 'modules/validation-dashboard/types';
import { DispatchType } from 'types/redux';

import './validation-rule-organizations.scss';

const OrganizationsTableHeader = ({
  hasRulesets,
}: {
  hasRulesets: boolean;
}) => (
  <thead className="GenericTable__header">
    <tr>
      <th colSpan={2}>Organization</th>
      <th className="center">Solvable</th>
      <th className={classNames({ center: !hasRulesets })} colSpan={2}>
        Rule set
      </th>
      <th className="center">Blocking</th>
    </tr>
  </thead>
);

interface mappedOrganization {
  id: number;
  is_solvable: boolean;
  name: string;
  rulesets: RuleSet[];
  restrictionType?: boolean;
}
const OrganizationLines = ({
  organization,
}: {
  organization: mappedOrganization;
}) => {
  return (
    <>
      <tr className="GenericTable__row OrganizationLines__organization">
        <td className="GenericTable__cell center">{organization.id}</td>
        <td className="GenericTable__cell">
          <b>{organization.name}</b>{' '}
        </td>
        <td className="GenericTable__cell center">
          <i
            className={`mdi mdi-${
              organization.is_solvable
                ? 'checkbox-marked-circle text-success'
                : 'close-circle text-danger'
            }`}
          />
        </td>
        <td className="GenericTable__cell center" colSpan={2}>
          {!organization.rulesets.length && '-'}
        </td>
        <td className="GenericTable__cell center">
          {!organization.rulesets.length && (
            <b>{organization.restrictionType ? 'YES' : 'NO'}</b>
          )}
        </td>
      </tr>
      {organization.rulesets?.map((ruleset) => (
        <tr
          key={`org-${organization.id}-ruleset-${ruleset.id}`}
          className="GenericTable__row OrganizationLines__ruleset"
        >
          <td className="GenericTable__cell"></td>
          <td className="GenericTable__cell"></td>
          <td className="GenericTable__cell"></td>
          <td className="GenericTable__cell center">{ruleset.id}</td>
          <td
            className="GenericTable__cell"
            data-testid={`ruleset-label-${ruleset.id}`}
          >
            {`${ruleset.label} (${ruleset.type})`}
          </td>
          <td className="GenericTable__cell center">
            <b>{ruleset.restrictionType ? 'YES' : 'NO'}</b>
          </td>
        </tr>
      ))}
    </>
  );
};

interface Props {
  organizations: any[] | undefined;
  rulesets: RuleSet[];
}
export const ValidationRuleOrganizations = ({
  organizations,
  rulesets,
}: Props) => {
  // TODO use back to serialize the info
  const dispatch: DispatchType = useDispatch();
  const cachedOrganizations = useSelector(selectValidationCachedOrganizations);
  useEffect(() => {
    dispatch(
      validationFecthOrganizationsCache(
        organizations
          ? organizations
              .map((org) => org.organization_id)
              .filter(
                (id) => !Object.keys(cachedOrganizations).includes(`${id}`),
              )
          : [],
      ),
    );
  }, [dispatch, organizations, cachedOrganizations]);

  if (!organizations || !organizations.length) {
    return <div>Not enforced by any organization.</div>;
  }

  const mappedOrganizations = organizations.map((org) => ({
    id: org.organization_id,
    name: `${cachedOrganizations[org.organization_id] || 'Loading ...'}`,
    is_solvable: org.is_solvable,
    restrictionType: org.ruleset_ids?.length ? undefined : org.restrictionType,
    rulesets: org.ruleset_ids
      ? org.ruleset_ids.map((id) => {
          const ruleset = rulesets.find((rs) => rs.id === id);
          return {
            id,
            label: ruleset?.label || '',
            type: ruleset?.type,
            restrictionType: ruleset?.restrictionType || org.restrictionType,
          };
        })
      : rulesets.map((rs) => ({
          ...rs,
          restrictionType: rs.restrictionType || org.restrictionType,
        })),
  }));

  return (
    <div className="ValidationRuleOrganizations">
      <table className="GenericTable">
        <OrganizationsTableHeader hasRulesets={rulesets.length !== 0} />
        <tbody>
          {mappedOrganizations.map((org) => (
            <OrganizationLines organization={org} key={`org-${org.id}`} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
