import { isArray, isObject } from 'lodash';
import React from 'react';

import { immutableSearchApi } from 'resources/searchApi';
import { isEAN } from 'utils/actions/list';

import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from '../legacy-autocomplete';

import { transformData } from './product-autocomplete';

type Props = {
  selector?: (value: any) => any;
  extraParams?: any;
} & LegacyAutocompleteProps;

export const getProductKeyLabel = (product) => {
  const gtin = product.get('gtin');
  const retailerId = product.getIn(['owner', 'id']);
  const tmId = product.getIn(['tags', 'targetMarket', 'id']);
  const manufId = product.getIn(['manufacturerInfo', 'id']) || '';
  const productName = product.getIn(['namePublicLong', 0, 'data']) || 'No name';
  return [gtin, retailerId, tmId, manufId, productName]
    .filter((el) => el !== '')
    .join(' - ');
};

const defaultSelector = (value) => ({
  key: value.getIn(['product_key', 'id']),
  value: value.getIn(['product_key', 'id']),
  label: getProductKeyLabel(value),
});

export const ProductKeyAutocomplete = React.memo(
  function ProductKeyAutocomplete({
    selector = defaultSelector,
    extraParams = {},
    ...props
  }: Props) {
    const onSearch = (search) => {
      let advancedSearch: any = extraParams.advanced_search;
      if (isArray(advancedSearch)) {
        advancedSearch = {
          must: [
            ...advancedSearch,
            {
              exists: true,
              fields: ['product_key.id'],
            },
          ],
        };
      } else if (isObject(advancedSearch)) {
        if (isArray(advancedSearch['must'])) {
          advancedSearch = {
            ...advancedSearch,
            must: [
              ...advancedSearch['must'],
              {
                exists: true,
                fields: ['product_key.id'],
              },
            ],
          };
        } else {
          advancedSearch = {
            must: [
              advancedSearch,
              {
                exists: true,
                fields: ['product_key.id'],
              },
            ],
          };
        }
      } else {
        advancedSearch = {
          must: [
            {
              exists: true,
              fields: ['product_key.id'],
            },
          ],
        };
      }

      let filterSourceInclude = extraParams.filter_source_include;
      filterSourceInclude = [
        ...new Set([
          ...(filterSourceInclude || []),
          'product_key',
          'gtin',
          'namePublicLong.data',
          'owner.id',
          'tags.targetMarket.id',
          'manufacturerInfo.id',
        ]),
      ];

      const body: any = {
        ...extraParams,
        filter_source_include: filterSourceInclude,
        advanced_search: advancedSearch,
      };

      if (isEAN(search)) {
        body.advanced_search.must.push({
          query_string: `*${search}*`,
          fields: ['gtin'],
        });
      } else if (search.startsWith('::')) {
        if (search.length === 2) {
          return null;
        }
        body.advanced_search.must.push({
          query: search.slice(2),
          fields: ['product_key.product_id'],
        });
      } else if (search.startsWith(':')) {
        if (search.length === 1) {
          return null;
        }
        body.advanced_search.must.push({
          query: search.slice(1),
          fields: ['product_key.id'],
        });
      } else if (search) {
        body.advanced_search.must.push({
          should: ['namePublicLong', 'namePublicShort', 'nameLegal'].map(
            (key) => ({
              nested: {
                path: `${key}`,
                must: [
                  {
                    query_string: `${search}*`,
                    fields: [`${key}.data`],
                  },
                ],
              },
            }),
          ),
        });
      }

      return immutableSearchApi.post('/search/v1/productversion/all', body);
    };

    const _transformData = transformData(selector);

    return (
      <LegacyAutocomplete
        onSearch={onSearch}
        transformData={_transformData}
        {...props}
      />
    );
  },
);
