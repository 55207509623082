import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import 'setup';

import 'index.scss';
import { getStore } from 'redux/store';

import { AppRouter } from 'AppRouter';
import { ErrorFallback } from 'components/error/ErrorFallback';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={getStore()}>
    <ErrorBoundary
      FallbackComponent={({ error }) => <ErrorFallback error={error} />}
    >
      <AppRouter />
    </ErrorBoundary>
  </Provider>,
);
