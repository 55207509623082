import classNames from 'classnames';
import PropTypes from 'prop-types';
import qs from 'querystringify';
import { PureComponent } from 'react';
import Dropzone from 'react-dropzone';

import { Button } from '@alkem/react-ui-button';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { Checkbox } from '@alkem/react-ui-inputs';

import { CodeEditor } from 'components/code-editor';
import { PayloadModal } from 'components/payload-modal';
import { saveAs } from 'utils';

import './runner.scss';

class Runner extends PureComponent {
  static propTypes = {
    graph: PropTypes.object.isRequired,
    onRunFinished: PropTypes.func.isRequired,
    result: PropTypes.object,
    productGoApi: PropTypes.object.isRequired,
    isVisible: PropTypes.bool,
  };

  static defaultProps = {
    result: {},
  };

  state = {
    value: '',
    headers: '',
    verbose: false,
    loading: false,
    showModal: false,
  };

  onExport = () => {
    const { result } = this.props;
    const { value } = this.state;

    if (result) {
      const blob = new Blob(
        [
          JSON.stringify(
            {
              parameters: JSON.parse(value),
              result,
            },
            null,
            4,
          ),
        ],
        {
          type: 'application/json',
        },
      );
      saveAs(blob, `service-product.json`);
    }
  };

  onImport = (files) => {
    const { onRunFinished } = this.props;
    if (files.length !== 1) {
      return;
    }

    const reader = new FileReader();
    this.setState({ loading: true });
    reader.onload = () => {
      const content = reader.result;
      const { parameters, result } = JSON.parse(content);
      this.setState({
        value: JSON.stringify(parameters, null, 4),
        loading: false,
      });
      onRunFinished({ res: result });
    };
    reader.readAsText(files[0]);
  };

  onRun = async () => {
    this.setState({ loading: true });
    const { graph, onRunFinished, productGoApi } = this.props;
    const { value, verbose, headers } = this.state;

    try {
      const res = await productGoApi.post(
        `/product/v2/graphs/run?${qs.stringify({
          graphName: graph.id,
          verbose,
        })}`,
        JSON.parse(value || '{}'),
        null,
        JSON.parse(headers || '[]'),
      );
      onRunFinished({ res: res.data });
    } catch (error) {
      onRunFinished({ error });
    }
    this.setState({ loading: false });
  };

  onVerboseChanged = (e) => {
    this.setState({ verbose: e.target.checked });
  };

  onChange = (value) => {
    this.setState({ value });
  };

  onChangeHeaders = (value) => {
    this.setState({ headers: value });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { graph, result, isVisible } = this.props;
    if (!graph.inputs || !isVisible) {
      return null;
    }

    const { value, headers, verbose, loading, showModal } = this.state;
    return (
      <div className="Runner">
        <div className="card Runner__Buttons">
          <div className="flex flex-align-items--center">
            <Button
              onClick={this.onRun}
              primary
              content="Run"
              disabled={loading}
              displaySpinner={loading}
            />
            <Checkbox
              label="Verbose"
              checked={verbose}
              onChange={this.onVerboseChanged}
              id="verbose-checkbox"
            />
            <Button
              onClick={this.showModal}
              secondary
              content="View output"
              disabled={loading || !result}
            />
          </div>
          <div className="flex flex-align-items--center">
            <Dropzone
              accept={{ 'application/json': ['.json'] }}
              onDrop={this.onImport}
              disabled={loading}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  {...getRootProps({
                    className: classNames(
                      'Runner__dropzone Button btn btn-secondary',
                      { disabled: loading },
                      isDragActive && 'Runner__dropzone--active',
                    ),
                  })}
                >
                  <input {...getInputProps()} />
                  <span>Import</span>
                </div>
              )}
            </Dropzone>
            <Button
              onClick={this.onExport}
              secondary
              content="Export"
              disabled={loading || !result}
            />
          </div>
        </div>
        <div className="card Runner__Top">
          <div className="Runner__Inputs">
            <h4>
              <strong>Inputs:</strong>
            </h4>
            <ul>
              {graph.inputs.map((input) => (
                <li key={input.name}>
                  <code>{input.name}</code>:{' '}
                  <code className="code-no-bg">{input.type}</code>
                </li>
              ))}
            </ul>
          </div>
          <div className="Runner__Headers">
            <h4>
              <strong>Headers:</strong>
              <HelpTooltip
                message={`[<br>
                     {"key": "X-Alk-Profile-Depth", "value": "20"},<br>
                     {"key": "X-Alk-Profile-Routes", "value": ""},<br>
                     {"key": "X-Alk-Profile-Filter", "value": "*"}<br>
                   ]`}
              />
            </h4>
            <CodeEditor
              name="headers"
              className="Runner__Headers_Editor"
              value={headers}
              mode="json"
              theme="github"
              width="initial"
              height="initial"
              editorProps={{ $blockScrolling: Infinity }}
              onChange={this.onChangeHeaders}
              showGutter={false}
              showPrintMargin={false}
              displayIndentGuides
            />
          </div>
        </div>
        <div className="card Runner_Payload">
          <CodeEditor
            name="payload"
            className="Runner__Payload_Editor"
            value={value}
            mode="json"
            theme="github"
            width="initial"
            height="initial"
            editorProps={{ $blockScrolling: Infinity }}
            onChange={this.onChange}
            showGutter={false}
            showPrintMargin={false}
            displayIndentGuides
          />
        </div>
        {showModal && (
          <PayloadModal payload={result || {}} onClose={this.hideModal} />
        )}
      </div>
    );
  }
}

export default Runner;
