import { immutableAuthApi as AuthApi } from 'resources/authApi';
import ServiceProductApi from 'resources/productGoApi';
import { immutableSearchApi as SearchApi } from 'resources/searchApi';
import TransactionApi from 'resources/transactionApi';
import { TIME_FORMAT_ISO_8601, formatTime } from 'utils/time';

export async function search(filters, pagination, resetPagination) {
  const offset = resetPagination
    ? 0
    : Math.max((pagination.currentPage - 1) * pagination.limit, 0);

  const body = Object.assign({}, buildBody(filters), {
    limit: pagination.limit,
    offset,
    withStatusHistory: true,
    aggregations: {
      dataTypes: {
        terms: { field: 'metadata.type', size: 1000, order: { _key: 'asc' } },
      },
    },
  });

  try {
    const response = await TransactionApi.Search(body);
    return { response };
  } catch (error) {
    return {
      error: error?.data?.message ? error.data.message : error,
    };
  }
}

function buildBody(filters) {
  const entities = [];

  filters.sourceEntities.forEach((se) => {
    const split = se.split(' ');
    entities.push({ type: split[0], ids: [parseInt(split[1], 10)], role: 0 });
  });

  filters.targetEntities.forEach((se) => {
    const split = se.split(' ');
    entities.push({ type: split[0], ids: [parseInt(split[1], 10)], role: 1 });
  });

  if (filters.sourceOrganization.id) {
    entities.push({
      type: 'Organization',
      ids: [filters.sourceOrganization.id],
      role: 0,
    });
  }

  if (filters.targetOrganization.id) {
    entities.push({
      type: 'Organization',
      ids: [filters.targetOrganization.id],
      role: 1,
    });
  }

  if (filters.sourceProductKey.id) {
    entities.push({
      type: 'ProductKey',
      ids: [filters.sourceProductKey.id],
      role: 0,
    });
  }

  if (filters.targetProductKey.id) {
    entities.push({
      type: 'ProductKey',
      ids: [filters.targetProductKey.id],
      role: 1,
    });
  }

  return buildCommon(
    {
      statuses: filters.statuses.map((s) => parseInt(s, 10)),
      eventTypes: filters.eventTypes,
      eventActions: filters.eventActions,
      entities,
    },
    filters,
  );
}

function buildCommon(body, filters) {
  const enriched = body;

  if (filters.q && filters.q.length > 0) {
    enriched.ids = filters.q.split(',').map((s) => Number(s.trim()));
  }

  if (filters.createdAtFrom && filters.createdAtFrom.id) {
    enriched.createdAtFrom = formatTime(
      filters.createdAtFrom.id,
      TIME_FORMAT_ISO_8601,
    );
  }

  if (filters.createdAtTo && filters.createdAtTo.id) {
    enriched.createdAtTo = formatTime(
      filters.createdAtTo.id,
      TIME_FORMAT_ISO_8601,
    );
  }

  enriched.dataType = filters.dataType;

  return enriched;
}

export async function apply(transactionId, action) {
  try {
    const response = await TransactionApi.Apply(transactionId, action);
    return { response };
  } catch (error) {
    return {
      error: error?.data?.message ? error.data.message : error,
    };
  }
}

export async function index(transactionIds) {
  try {
    await TransactionApi.Index(transactionIds);
    return { data: 'ok' };
  } catch (error) {
    return {
      error: error?.data?.message ? error.data.message : error,
    };
  }
}

export async function getOrganizations(orgIds) {
  try {
    const filters = { ids: orgIds };
    const response = await AuthApi.OrganizationListV3(filters);
    return { response };
  } catch (error) {
    return {
      error: error?.data?.message ? error.data.message : error,
    };
  }
}

export async function getProducts(productIds) {
  try {
    const body = {
      filter_source_include: ['specializes', 'namePublicLong', 'nameLegal'],
      filter_product_id_in: productIds,
      allow_not_consumer_units: true,
      filter_is_displayable_as_target: false,
      limit: productIds.length,
    };
    const response = await SearchApi.post('/search/v1/productversion', body);
    return { response };
  } catch (error) {
    return {
      error: error?.data?.message ? error.data.message : error,
    };
  }
}

export async function getProductKeys(productKeyIds) {
  try {
    const body = {
      filter_source_include: [
        'product_key.id',
        'gtin',
        'namePublicLong.data',
        'owner.id',
        'tags.targetMarket.id',
      ],
      advanced_search: {
        should: productKeyIds.map((id) => ({
          query: id,
          fields: ['product_key.id'],
        })),
      },
      allow_not_consumer_units: true,
      filter_is_displayable_as_target: false,
      limit: productKeyIds.length,
    };
    const response = await SearchApi.post('/search/v1/productversion', body);
    return { response };
  } catch (error) {
    return {
      error: error?.data?.message ? error.data.message : error,
    };
  }
}

export async function listProductKeysFromIds(productKeyIds) {
  try {
    const body = {
      product_key_ids: productKeyIds,
      raise_if_missing: false,
    };
    const response = await ServiceProductApi.post(
      '/product/v2/products/keys/list_by_ids',
      body,
    );
    return { response };
  } catch (error) {
    return {
      error: error?.data?.message ? error.data.message : error,
    };
  }
}

export async function restoreHierarchies(transactionIds) {
  try {
    const response = await ServiceProductApi.post(
      '/product/v2/hierarchies/restore',
      {
        transaction_ids: transactionIds,
      },
    );
    return { response };
  } catch (error) {
    return { error };
  }
}
