import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from './events';

export const REDUCER_KEY = 'organization-groups';

const getInitialState = () =>
  fromJS({
    groups: null,
    search: '',
    edited: null,
    uploading: false,
    saving: false,
  });

export default createReducer(getInitialState(), {
  [events.INIT]: () => getInitialState(),
  [events.RECEIVE_GROUPS]: (state, { groups }) => state.set('groups', groups),
  [events.UPDATE_SEARCH]: (state, { search }) => state.set('search', search),
  [events.EDIT]: (state, { group }) => state.set('edited', group),
  [events.UPDATE_NAME]: (state, { name }) =>
    state.setIn(['edited', 'name'], name),
  [events.SET_UPLOADING_PICTURE]: (state, { uploading }) =>
    state.set('uploading', uploading),
  [events.SET_SAVING]: (state, { saving }) => state.set('saving', saving),
});
