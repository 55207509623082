import { spiders } from '../constants';

export function getTargetOrganizations(targetOrganizations) {
  if (targetOrganizations.length === 1) {
    const { nameLegal, id } = targetOrganizations[0];
    return ` ${nameLegal} (${id}) `;
  } else {
    return ` ${targetOrganizations.length} Organizations `;
  }
}

export function scoreFormat(format) {
  if (format.label.startsWith('RFP: ')) {
    return 10;
  } else if (format.label.startsWith('Retailer: ')) {
    return 5;
  }
  return 0;
}

export function getSpiderLabel(spiderName) {
  const spiderLabel = spiders.find(
    (it) => it.value === spiderName.toUpperCase(),
  );
  return (spiderLabel && spiderLabel.label) || spiderName;
}

export function formatSpiderFilter(spiderNames) {
  return spiderNames
    .filter((spiderName) => !!spiderName)
    .map((spiderName) => ({
      label: getSpiderLabel(spiderName),
      value: spiderName,
      checked: false,
    }))
    .sort(
      (a, b) =>
        a.label.localeCompare(b.label) + scoreFormat(a) - scoreFormat(b),
    );
}
