import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { ExportMapping } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

interface Props {
  name: string;
  exportMapping: ExportMapping;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemDoNotAddModuleProperty({
  name,
  exportMapping,
  dispatchProperty,
}: Props) {
  const [doNotAddModule, setDoNotAddModule] = useState<true | undefined>(
    exportMapping.payload.do_not_add_module,
  );

  const updateDoNotAddModule = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedDoNotAddModule = value === 'true' || undefined;
    setDoNotAddModule(updatedDoNotAddModule);
    dispatchProperty('do_not_add_module', updatedDoNotAddModule);
  };

  return (
    <div
      className={styles.property}
      data-testid={`gdsn-export-mappings-do-not-add-module-property-${name}`}
    >
      <InputWithLabel
        inputId={`gdsn-export-mappings-do-not-add-module-property-${name}`}
        label={
          <span>
            Do not add module{' '}
            <HelpTooltip
              id={`gdsn-export-mappings-do-not-add-module-property-${name}-help`}
              place="top"
              message={
                <div className={styles.messageContainer}>
                  <span>
                    If this property is set to TRUE, when an element exist
                    already in the xsd thanks to the previous mappings, a new
                    one won't be created.
                  </span>
                  <span>
                    Coupled with the property New module path on the children,
                    this will allow to create a new_module per child.
                  </span>
                </div>
              }
            />
          </span>
        }
      >
        <Radio
          id={`gdsn-export-mappings-do-not-add-module-property-${name}`}
          value={doNotAddModule}
          options={[
            { label: 'TRUE', value: true },
            { label: 'FALSE', value: undefined },
          ]}
          onChange={updateDoNotAddModule}
        />
      </InputWithLabel>
    </div>
  );
}
