import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Textarea } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

import {
  CHANGE_ORDER_EXPORT_MAPPING,
  GET_EXPORT_MAPPING_LIST,
  SET_REORDERING_ID,
} from '../../actions/constants';
import { DATETIME_FORMAT, UNLIMITED_DATE, XPATH_TYPES } from '../../constants';
import { selectEditionId, selectReorderingId } from '../../selectors';
import { ExportMapping, ExportMappings, Xpath, XpathList } from '../../types';

import './export-mappings.scss';
import { ExportMappingsItem } from './item';
import { reorderArray } from './utils';

interface Props {
  element: Xpath;
  parentElement?: Xpath;
  sameLevelElementChildren?: XpathList;
  exportMappings: ExportMappings;
  parent_name?: string;
  parent_id?: number;
  sxm_list_parent?: {
    field: string;
    use_su: boolean;
  };
  isFirst?: true;
  parentExportMapping?: ExportMapping;
}
export function ExportMappingsView({
  element,
  parentElement,
  sameLevelElementChildren,
  exportMappings,
  parent_id,
  sxm_list_parent,
  isFirst,
  parentExportMapping,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const elementName = element.name;
  const editionId = useSelector(selectEditionId);
  const reorderingId = useSelector(selectReorderingId);
  let use_su: boolean | undefined = undefined;
  if (sxm_list_parent) {
    use_su = sxm_list_parent.use_su || undefined;
  }
  const [orderedExportMappings, setOrderedExportMappings] =
    useState(exportMappings);

  useEffect(() => {
    setOrderedExportMappings(exportMappings);
  }, [exportMappings]);

  const reorder = (): void => {
    dispatch({
      type: SET_REORDERING_ID,
      payload: { reorderingId: element.id },
    });
  };

  const saveReorder = (): void => {
    dispatch({ type: SET_REORDERING_ID });
    const orders_to_update: { id: number | undefined; order: number }[] = [];
    for (let i = 0; i < orderedExportMappings.length; i++) {
      if (orderedExportMappings[i].id !== undefined) {
        const item = { id: orderedExportMappings[i].id, order: i };
        orders_to_update.push(item);
      }
    }
    dispatch({
      type: CHANGE_ORDER_EXPORT_MAPPING,
      exportMappingOrders: orders_to_update,
      xpath: element,
    });
  };

  const cancelReorder = (): void => {
    dispatch({ type: SET_REORDERING_ID });
    dispatch({ type: GET_EXPORT_MAPPING_LIST, xpath: element });
  };

  const changeOrder = (index: number, direction: string) => {
    const newExportMappings = reorderArray(
      { oldIndex: index, newIndex: index + (direction === 'UP' ? -1 : 1) },
      orderedExportMappings,
    );
    setOrderedExportMappings(newExportMappings);
  };

  const isReordering = reorderingId === element.id;

  return (
    <div className="GDSNExportMappings">
      <div className="GDSNExportMappings__xpathContainer">
        <InputWithLabel
          inputId={`gdsn-export-mappings-${elementName}-xpath`}
          label="xpath"
        >
          <div className="GDSNExportMappings__xpathContainer--disabled">
            <Textarea
              id={`gdsn-export-mappings-${elementName}-xpath`}
              value={element.xpath}
              autoresize
              onChange={() => {}}
            />
          </div>
        </InputWithLabel>
      </div>
      {![XPATH_TYPES.module, XPATH_TYPES.avp].includes(element.type) && (
        <div
          className="GDSNExportMappings_mappingsContainer"
          data-testid="gdsn-export-mappings-mappings-container"
        >
          <div className="GDSNExportMappings_buttonsContainer">
            {orderedExportMappings.length > 1 && !isReordering && (
              <Button
                id="reorder-gdsn-export-mapping"
                content="Reorder"
                primary
                onClick={reorder}
                disabled={!!editionId || typeof reorderingId !== 'undefined'}
              />
            )}
            {isReordering && (
              <>
                <Button
                  id="cancel-reorder-gdsn-export-mapping"
                  content="Cancel"
                  secondary
                  onClick={cancelReorder}
                />
                <Button
                  id="save-reorder-gdsn-export-mapping"
                  content="Save reorder"
                  primary
                  onClick={saveReorder}
                />
              </>
            )}
          </div>
          {orderedExportMappings.map((exportMapping, index) => (
            <ExportMappingsItem
              key={exportMapping.id}
              element={element}
              sameLevelElementChildren={sameLevelElementChildren}
              exportMapping={exportMapping}
              changeOrder={changeOrder}
              index={index}
              lengthMappings={orderedExportMappings.length}
              editionId={editionId}
              reorderingId={reorderingId}
              isFirst={isFirst && index === 0}
              parentExportMapping={parentExportMapping}
              sxm_list_parent={sxm_list_parent}
              parentElement={parentElement}
            />
          ))}
          {!isReordering && (
            <ExportMappingsItem
              key={`new_export_mapping_${element.id}`}
              element={element}
              sameLevelElementChildren={sameLevelElementChildren}
              exportMapping={{
                date_start: moment().format(DATETIME_FORMAT),
                date_end: UNLIMITED_DATE,
                order: orderedExportMappings.length,
                xpath_id: element.id,
                parent_id,
                payload: {
                  use_su,
                },
                children: [],
                sxm_list_parent,
                fallback: {},
              }}
              editionId={editionId}
              isNew
              changeOrder={changeOrder}
              isFirst={orderedExportMappings.length === 0}
              parentExportMapping={parentExportMapping}
              parentElement={parentElement}
            />
          )}
        </div>
      )}
    </div>
  );
}
