import { List } from 'immutable';
import { useSelector } from 'react-redux';

import {
  COLUMN_REQUESTED_PRODUCTS,
  INFORMATION_REQUEST,
  LISTCOLUMN,
} from 'constants/organization-settings';
import {
  selectEditedSettings,
  selectListColumnReferential,
  selectSettings,
} from 'modules/organization-page/selectors/settings';

import SimpleSettingController from './controllers/simple';
import { getListColumnValueForUpdate } from './utils';

const InformationRequest = (props: { disabled: boolean }) => {
  const { disabled } = props;

  const referential: List<any> | null = useSelector(
    selectListColumnReferential,
  );
  const editedSettings: any = useSelector(selectEditedSettings);
  const settings: any = useSelector(selectSettings);

  return (
    <SimpleSettingController
      label="Request Engine"
      help="Define if the retailer can send product requests to suppliers"
      path={[INFORMATION_REQUEST]}
      checkedValue={true}
      uncheckedValue={false}
      disabled={disabled}
      syncOthers={[
        {
          path: [LISTCOLUMN],
          checkedValue: getListColumnValueForUpdate(
            settings,
            editedSettings,
            referential,
            [COLUMN_REQUESTED_PRODUCTS],
            true,
          ),
          uncheckedValue: getListColumnValueForUpdate(
            settings,
            editedSettings,
            referential,
            [COLUMN_REQUESTED_PRODUCTS],
            false,
          ),
        },
      ]}
    />
  );
};

export default InformationRequest;
