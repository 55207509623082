import Tooltip from '@alkem/react-ui-tooltip';
import { Env, setPriority } from '@alkem/sdk-dashboard';

import { setDebug } from 'utils';
import { initDataDogRUM } from 'utils/datadog';
import 'utils/lodash';
import { sanitize } from 'utils/sanitize';

initDataDogRUM();
setPriority(8);

const { host } = window.location;
window.env = Env.getEnv().env;
setDebug(host);

global.VERSION = Object.assign(
  { admin: global.FA_VERSION, sdk: global.SDK_VERSION },
  global.VERSION,
);

Tooltip.setDefaultSanitizer(sanitize);
