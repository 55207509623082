import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectDetail, selectSources } from '../../selectors';
import * as types from '../../types';
import { getSourceLabel } from '../source-utils';

import './detail.scss';

function DetailRow({ entry }: { entry: types.OrganizationDetailEntry }) {
  const sources = useSelector(selectSources);
  let source: ReactNode = entry.source;
  if (sources !== null) {
    const foundSource = sources.find((s) => s.id === entry.source);
    if (foundSource) {
      source = getSourceLabel(foundSource);
    }
  }
  return (
    <div className="IndexerQueueDetailRow">
      <div className="IndexerQueueDetailRow__column IndexerQueueDetailRow__source">
        {source}
      </div>
      <div className="IndexerQueueDetailRow__column IndexerQueueDetailRow__priority">
        {entry.priority}
      </div>
      <div className="IndexerQueueDetailRow__column IndexerQueueDetailRow__data">
        {entry.pending.toLocaleString()}
      </div>
      <div className="IndexerQueueDetailRow__column IndexerQueueDetailRow__data">
        {entry.in_progress.toLocaleString()}
      </div>
    </div>
  );
}

export function IndexerQueueOrganizationDetail() {
  const detail = useSelector(selectDetail);

  if (detail === null) {
    return null;
  }

  return (
    <div className="IndexerQueueDetail">
      <div className="IndexerQueueDetailHeader">
        <div className="IndexerQueueDetailRow__column IndexerQueueDetailRow__source">
          Source
        </div>
        <div className="IndexerQueueDetailRow__column IndexerQueueDetailRow__priority">
          Priority
        </div>
        <div className="IndexerQueueDetailRow__column IndexerQueueDetailRow__data">
          Pending
        </div>
        <div className="IndexerQueueDetailRow__column IndexerQueueDetailRow__data">
          In progress
        </div>
      </div>
      {detail.entries
        .sort((a, b) => b.priority - a.priority)
        .map((entry) => (
          <DetailRow
            key={`indexer-queue-detail-${entry.source}-${entry.priority}`}
            entry={entry}
          />
        ))}
    </div>
  );
}
