import { isEqual } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import List from '@alkem/react-ui-list';
import { Spinner } from '@alkem/react-ui-spinner';

import OrganizationDeactivate from 'modules/organization-deactivate';
import { computeTotalPages } from 'utils/list';

import {
  changeOrganizationsLimit,
  getOrganizations,
  nextOrganizations,
  previousOrganizations,
} from '../../actions';
import {
  selectAreOrganizationsLoading,
  selectCurrentPage,
  selectFilters,
  selectLimit,
  selectList,
  selectPagination,
  selectSearch,
  selectTotalResults,
} from '../../selectors';
import { getOrganizationId } from '../../utils';

import './index.scss';
import OrganizationRow from './row';

const mapStateToProps = (state) => ({
  list: selectList(state),
  search: selectSearch(state),
  filters: selectFilters(state),
  pagination: selectPagination(state),
  limit: selectLimit(state),
  totalResults: selectTotalResults(state),
  currentPage: selectCurrentPage(state),
  isLoading: selectAreOrganizationsLoading(state),
});

const mapDispatchToProps = {
  nextPage: nextOrganizations,
  previousPage: previousOrganizations,
  getList: getOrganizations,
  onLimitChange: changeOrganizationsLimit,
};

class OrganizationList extends Component {
  static propTypes = {
    list: ImmutablePropTypes.list.isRequired,
    filters: ImmutablePropTypes.map.isRequired,
    search: PropTypes.string,
    pagination: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    nextPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    getList: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.optionsLimit = [50, 100, 200];
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    this.props.getList();
  }

  componentDidUpdate(prevProps) {
    const { pagination, filters, search } = this.props;
    if (
      !isEqual(pagination, prevProps.pagination) ||
      filters !== prevProps.filters ||
      search !== prevProps.search
    ) {
      this.props.getList();
    }
  }

  onCloseModal = () => {
    this.props.getList();
  };

  getLimitLabel(count) {
    return `${count} per page.`;
  }

  getPageLabel(page, total) {
    return `${page} / ${total} ${total > 1 ? 'pages' : 'page'}.`;
  }

  getEntityLabel(count) {
    return `${count} ${count > 1 ? 'organizations' : 'organization'} in total.`;
  }

  computeTotalPages() {
    return computeTotalPages(this.props.totalResults, this.props.limit);
  }

  renderActions() {
    return (
      this.props.isLoading && (
        <div className="OrganizationList__spinner">
          <Spinner small />
        </div>
      )
    );
  }

  render() {
    const { list } = this.props;
    return (
      <List
        currentPage={this.props.currentPage}
        totalPages={this.computeTotalPages()}
        totalResults={this.props.totalResults}
        onNext={this.props.nextPage}
        onPrev={this.props.previousPage}
        limit={this.props.limit}
        onLimitChange={this.props.onLimitChange}
        optionsLimit={this.optionsLimit}
        getLimitLabel={this.getLimitLabel}
        getPageLabel={this.getPageLabel}
        getEntityLabel={this.getEntityLabel}
        bottomActions
        actions={this.renderActions()}
      >
        {list.map((org, index) => (
          <OrganizationRow
            key={`row-${getOrganizationId(org)}`}
            organization={org}
            isFirst={index === 0}
          />
        ))}
        <OrganizationDeactivate onClose={this.onCloseModal} />
      </List>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationList);
