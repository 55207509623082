import { MODULE_REDUCER_KEY } from 'constants/reducer';

import {
  ORGANIZATION_PAGE_STORE_KEY,
  RECIPIENTS_REDUCER_KEY,
} from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_PAGE_STORE_KEY][
    RECIPIENTS_REDUCER_KEY
  ];
}

function selectOrganization(state) {
  return selectState(state).get('organization');
}

export function selectOrganizationId(state) {
  return selectOrganization(state).get('id');
}

export function selectIsOrganizationRestricted(state) {
  return selectOrganization(state).get('restricted');
}

export function selectRecipients(state) {
  return selectState(state).get('recipients');
}

export function selectEditedRecipients(state) {
  return selectState(state).get('editedRecipients');
}

export function selectEditedOrSourceRecipients(state) {
  const edited = selectEditedRecipients(state);
  if (edited) {
    return edited;
  }
  return selectRecipients(state);
}

export function hasChange(state) {
  return !!selectEditedRecipients(state);
}
