import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';

import { notificationError } from 'actions/notifications';
import InputWithLabel from 'components/input-with-label';
import { DispatchType } from 'types/redux';

import { resyncOrganization } from '../../actions';

type Props = {
  organizationId: number;
  scheduleType: string | null;
  close: () => void;
};

export const OrganizationPageResyncModal = ({
  organizationId,
  scheduleType,
  close,
}: Props) => {
  const dispatch: DispatchType = useDispatch();
  const [reason, updateReason] = useState<string>('');
  const onUpdateReason = useCallback(
    (e: ChangeEvent<any>) => {
      updateReason(e.target.value);
    },
    [updateReason],
  );

  const onResync = useCallback(() => {
    if (reason.length < 10) {
      dispatch(
        notificationError(
          'You must fill a reason with at least 10 characters.',
        ),
      );
      return;
    }
    dispatch(resyncOrganization(organizationId, reason, scheduleType));
    close();
  }, [dispatch, close, organizationId, reason, scheduleType]);

  return (
    <Modal
      title="Resync Organization"
      confirmButtonText="Resync"
      onConfirm={onResync}
      onClose={close}
    >
      <p>Resync all products of an organization.</p>
      {!!scheduleType && (
        <p>Resync will not be launched immediately but will be scheduled.</p>
      )}
      <InputWithLabel
        inputId="InputText-organization-resync-reason"
        label="Why are you resyncing this organization?"
      >
        <Text
          id="organization-resync-reason"
          value={reason}
          placeholder="Note: this will be sent to a slack channel for us to better understand the usage."
          onChange={onUpdateReason}
        />
      </InputWithLabel>
    </Modal>
  );
};
