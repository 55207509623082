import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import RuleSetSelector from 'modules/ruleset-selector';
import { getIn } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import * as _actions from '../../actions';
import * as selectors from '../../selectors';
import '../../views.scss';

const mapStateToProps = (state) => ({
  editedOrganization: selectors.selectEditedOrganization(state),
});

const mapDispatchToProps = {
  ..._actions,
};

export class TabRuleSets extends PureComponent {
  static propTypes = {
    editedOrganization: ImmutablePropTypes.map,
    readOnly: PropTypes.bool,
    actions: PropTypes.shape({
      updateRuleSets: PropTypes.func,
    }),
  };

  render() {
    const { editedOrganization, actions, readOnly } = this.props;
    const rulesets = getIn(editedOrganization, 'ruleSets');
    if (!rulesets) {
      return <span />;
    }

    return (
      <div>
        <RuleSetSelector
          rulesets={rulesets}
          onChange={actions.updateRuleSets}
          readOnly={readOnly}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(TabRuleSets);
