import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';
import { Filter } from '@alkem/react-ui-filter';
import { Text } from '@alkem/react-ui-inputs';
import { Spinner } from '@alkem/react-ui-spinner';
import { TwinPanel } from '@alkem/react-ui-twinpanel';

import { applicationDashboardAccessPolicy } from 'access-policies';
import { notificationError } from 'actions/notifications';
import * as routes from 'constants/routes';
import { withNavigate } from 'hocs/with-navigate';
import { withParams } from 'hocs/with-params';
import {
  createApplication,
  fetchData,
  resetApplication,
  savingApplication,
  selectApplication,
} from 'modules/applications/actions';
import ApplicationDetails from 'modules/applications/components/application-details';
import {
  getApplications,
  getApplicationsLoading,
  getCreated,
  getPristine,
  getSaving,
  getSelectedApplicationId,
} from 'modules/applications/selectors';
import { selectUserReadOnlyAccess } from 'modules/auth/selectors';
import { fill } from 'utils/routes';

import { NEW_APPLICATION_ID } from '../constants';

import './index.scss';

const transformToFilterList = (apps, selected) =>
  apps
    .valueSeq()
    .toArray()
    .map((app) => ({
      id: app.get('id'),
      value: app.get('id'),
      label: app.get('name'),
      checked: app.get('id') === selected,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));

export const messages = {
  unsavedNotificationError:
    'You have unsaved changes. Please save them or discard them before switching to another application.',
};

const mapStateToProps = createStructuredSelector({
  applications: getApplications,
  isLoadingApplications: getApplicationsLoading,
  pristine: getPristine,
  saving: getSaving,
  created: getCreated,
  selected: getSelectedApplicationId,
  readOnly: selectUserReadOnlyAccess(applicationDashboardAccessPolicy),
});

const mapDispatchToProps = {
  fetchData,
  selectApplication,
  resetApplication,
  savingApplication,
  createApplication,
  notificationError,
};

export class ApplicationsDashboard extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    applications: ImmutablePropTypes.map.isRequired,
    isLoadingApplications: PropTypes.bool,
    selected: PropTypes.number,
    pristine: PropTypes.bool,
    saving: PropTypes.bool,
    created: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    fetchData: PropTypes.func.isRequired,
    selectApplication: PropTypes.func.isRequired,
    savingApplication: PropTypes.func.isRequired,
    resetApplication: PropTypes.func.isRequired,
    createApplication: PropTypes.func.isRequired,
    notificationError: PropTypes.func.isRequired,
  };

  state = { q: '' };

  componentDidMount() {
    this.props.fetchData(Number(this.props.params.id));
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.params.id !== prevProps.params.id &&
      this.props.applications.size
    ) {
      const id = Number(this.props.params.id);
      if (id > 0) {
        this.props.selectApplication(Number(id));
      } else if (id === NEW_APPLICATION_ID) {
        this.props.createApplication();
      }
    }
  }

  onApplicationSelect = (name, selectedApplication) => {
    if (this.props.pristine) {
      this.props.navigate(fill(routes.applications, selectedApplication.id));
    } else {
      this.props.notificationError(messages.unsavedNotificationError);
    }
  };

  onFilter = (e) => {
    this.setState({ q: e.target.value });
  };

  onDiscard = () => {
    if (this.props.selected === NEW_APPLICATION_ID) {
      this.props.navigate(fill(routes.applicationsRoot));
    }
    this.props.resetApplication();
  };

  getFilteredApps() {
    const { applications } = this.props;
    const { q } = this.state;
    if (!q) {
      return applications;
    }
    return applications.filter(
      (app) =>
        app.get('name').toLowerCase().indexOf(q.toLowerCase()) >= 0 ||
        String(app.get('id')) === q,
    );
  }

  createApplication = () => {
    if (this.props.pristine) {
      this.props.navigate(fill(routes.applications, NEW_APPLICATION_ID));
    } else {
      this.props.notificationError(messages.unsavedNotificationError);
    }
  };

  renderApplicationList = () => {
    const { selected } = this.props;
    const { q } = this.state;

    const filtered = this.getFilteredApps();

    return (
      <div id="ApplicationsList">
        <Text
          id="field-name-filter"
          placeholder="Filter apps by name or id"
          onChange={this.onFilter}
          value={q}
        />
        <Filter
          name="Application"
          type="list"
          filters={transformToFilterList(filtered, selected)}
          onChange={this.onApplicationSelect}
        />
      </div>
    );
  };

  renderButtons() {
    const { selected, pristine, saving, readOnly } = this.props;
    if (!selected || readOnly) {
      return null;
    }
    const newApp = selected === NEW_APPLICATION_ID;
    return [
      <Button
        id="reset-app-button"
        onClick={this.onDiscard}
        key="discard"
        warning
        className="ApplicationDetail__DiscardButton"
        content="Discard"
        secondary
        disabled={(pristine || saving) && !newApp}
      />,
      <Button
        id="save-app-button"
        onClick={this.props.savingApplication}
        key="save"
        className="ApplicationDetail__SaveButton"
        content="Save"
        primary
        disabled={pristine || saving}
        displaySpinner={saving}
      />,
    ];
  }

  renderApplicationDetailTitle = () => {
    const { applications, selected } = this.props;
    const selectedApp = applications.get(selected);
    const appId =
      selected && selectedApp.get('id') > 0
        ? `${selectedApp.get('id')} - `
        : '';
    const title = selected
      ? `${appId}${selectedApp.get('name')}`
      : 'Pick an application on the left';
    return (
      <div className="ApplicationDetail__Header">
        <h2 className="ApplicationDetail__Title">{title}</h2>
        {this.renderButtons()}
      </div>
    );
  };

  renderApplicationDetails = () => {
    const { applications, selected, pristine, saving, readOnly } = this.props;
    if (!selected) {
      return null;
    }

    return (
      <ApplicationDetails
        application={applications.get(selected)}
        pristine={pristine}
        saving={saving}
        readOnly={readOnly}
      />
    );
  };

  renderLeftTitle() {
    const { readOnly, saving } = this.props;
    return (
      <div className="app-left-panel-title">
        <span> Applications</span>
        {!readOnly && (
          <Button
            primary
            id="create-new-app-button"
            onClick={this.createApplication}
            disabled={saving}
          >
            Create
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { isLoadingApplications } = this.props;
    const headerProps = {
      title: 'Applications Dashboard',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };
    return (
      <div className="ApplicationsDashboard__body">
        <HeaderLayout {...headerProps} />
        <div className="ApplicationsDashboard__content">
          {isLoadingApplications ? (
            <div className="ApplicationsDashboard__loading">
              <Spinner loading big />
              <div>Loading applications</div>
            </div>
          ) : (
            <TwinPanel
              leftPanel={{
                title: this.renderLeftTitle(),
                className: 'Applications',
                content: this.renderApplicationList(),
              }}
              rightPanel={{
                title: this.renderApplicationDetailTitle(),
                content: this.renderApplicationDetails(),
                className: 'ApplicationsDashboardView__panel--right',
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withParams(withNavigate(ApplicationsDashboard)));
