import qs from 'querystringify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { notificationError } from 'actions/notifications';
import EtlApi from 'resources/etlApi';
import { saveAs } from 'utils';
import { getFilenameFromHTTPHeaders } from 'utils/http';
import { navigate } from 'utils/location';

import {
  listIsLoading,
  receiveFilters,
  receiveImportDetail,
  receiveImportList,
} from '../actions';
import {
  CHANGE_LIMIT_IMPORT_LIST,
  DOWNLOAD_FILE,
  LOAD_FILTERS,
  LOAD_IMPORT_DETAIL,
  LOAD_IMPORT_LIST,
  NEXT_PAGE_IMPORT_LIST,
  PREVIOUS_PAGE_IMPORT_LIST,
  UPDATE_SELECTED_FILTERS,
} from '../constants/events';
import { selectFiltersSelected, selectPagination } from '../selectors';

function updateURLWithFilters(filtersSelected) {
  const qsv = {};
  for (const [key, value] of Object.entries(filtersSelected)) {
    if (value != null && value !== '') {
      qsv[key] = value;
    }
  }
  navigate({
    to: `${location.pathname}${qs.stringify(qsv, true)}`,
    replace: true,
  });
}

export default function* importGenericMainSaga() {
  yield takeLatest(LOAD_FILTERS, loadFilters);
  yield takeLatest(
    [
      UPDATE_SELECTED_FILTERS,
      LOAD_IMPORT_LIST,
      CHANGE_LIMIT_IMPORT_LIST,
      NEXT_PAGE_IMPORT_LIST,
      PREVIOUS_PAGE_IMPORT_LIST,
    ],
    loadImportList,
  );
  yield takeLatest(LOAD_IMPORT_DETAIL, loadImportDetail);
  yield takeLatest(DOWNLOAD_FILE, downloadFile);
}

function* downloadFile(event) {
  try {
    const response = yield call(
      [EtlApi, EtlApi.ImportGetFile],
      event.importFileId,
    );
    const filename = getFilenameFromHTTPHeaders(response.headers);
    saveAs(response.data, filename);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: downloadFile'));
    }
  }
}

function* loadFilters() {
  try {
    const response = yield call([EtlApi, EtlApi.ImportFilters]);
    yield put(receiveFilters(response.data.data));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: loadFilters'));
    }
  }
}

function* loadImportList() {
  try {
    yield put(listIsLoading());
    const filters = yield select(selectFiltersSelected);
    const pagination = yield select(selectPagination);

    updateURLWithFilters(filters);
    if (filters.identifier) {
      const identifier_type = {
        'supplier-directory-v1': 'Supplier',
      }[filters.workflow];
      filters.identifier_type = identifier_type;
    }

    const response = yield call([EtlApi, EtlApi.ImportList], {
      ...filters,
      offset: (pagination.currentPage - 1) * pagination.limit,
      limit: pagination.limit,
    });
    const factor =
      response.data.data.length === pagination.limit
        ? 1
        : (pagination.limit - response.data.data.length) * -1;
    const totalResults = pagination.currentPage * pagination.limit + factor;
    yield put(receiveImportList(response.data.data, totalResults));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: loadImportList'));
    }
  }
}

function* loadImportDetail(event) {
  try {
    const response = yield call(
      [EtlApi, EtlApi.ImportEntities],
      event.importFileId,
      { offset: event.offset, limit: event.limit },
    );
    yield put(receiveImportDetail(event.importFileId, response.data.entities));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: loadImportDetail'));
    }
  }
}
