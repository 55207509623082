import { flow, set } from 'lodash/fp';

import { createReducer } from 'utils/reducers';
import { ActionWithPayload } from 'utils/reducers/types';

import {
  CREATE_SSO,
  DELETE_SSO,
  ERROR_SSO,
  FETCH_SSO,
  RECEIVE_SSO,
  UPDATE_SSO,
} from '../constants/events';
import { OrganizationSSOState } from '../types';

const initialState: OrganizationSSOState = {
  sso: null,
  isLoading: false,
};

export const ssoReducer = createReducer<OrganizationSSOState>(initialState, {
  [FETCH_SSO]: set('isLoading', true),
  [CREATE_SSO]: set('isLoading', true),
  [DELETE_SSO]: set('isLoading', true),
  [UPDATE_SSO]: set('isLoading', true),
  [ERROR_SSO]: set('isLoading', false),
  [RECEIVE_SSO]: (
    state,
    { payload: sso = null }: ActionWithPayload<object | null>,
  ) => flow(set('sso', sso), set('isLoading', false))(state),
});
