import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { Spinner } from '@alkem/react-ui-spinner';

import InputWithLabel from 'components/input-with-label';
import { ListController } from 'components/ui/list/controller';
import { getGoogleLoginClientId } from 'utils/google';
import { separateActions } from 'utils/redux';

import {
  changeLimit,
  closeModal,
  consentGMAIL,
  getEndpointList,
  nextPage,
  openModal,
  previousPage,
} from '../actions';
import EndpointModal from '../modal';
import { selectList, selectPagination, selectState } from '../selectors';

import './list.scss';
import EndpointListRow from './row';

const mapStateToProps = (state) => ({
  list: selectList(state),
  isLoading: selectState(state).isLoading,
  pagination: selectPagination(state),
});

const mapDispatchToProps = {
  getEndpointList,
  nextPage,
  previousPage,
  openModal,
  closeModal,
  changeLimit,
  consentGMAIL,
};

export class EndpointList extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      getEndpointList: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      openModal: PropTypes.func.isRequired,
      closeModal: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
      consentGMAIL: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      gmail: {
        isModalOpen: false,
        code: null,
        organizationID: null,
      },
    };
  }

  componentDidMount() {
    this.props.actions.getEndpointList();
  }

  updateEndpointButtonClick = (endpoint) => {
    this.props.actions.openModal(endpoint);
  };

  closeGmailModal = () => {
    this.setState({
      gmail: { code: null, isModalOpen: false, organizationID: null },
    });
  };

  openGmailModal = (e) => {
    this.setState({
      gmail: { code: e.code, isModalOpen: true, organizationID: null },
    });
  };

  exchangeGmailCodeForAccess = () => {
    this.props.actions.consentGMAIL(
      this.state.gmail.code,
      window.location.origin,
      parseInt(this.state.gmail.organizationID, 10),
      this.closeGmailModal,
    );
  };

  refresh = () => {
    this.props.actions.getEndpointList();
  };

  renderList() {
    const { list, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className="EndpointList__spinner">
          <Spinner loading big />
        </div>
      );
    }
    return list.map((endpoint) => (
      <EndpointListRow
        key={endpoint.id}
        endpoint={endpoint}
        updateEndpointButtonClick={this.updateEndpointButtonClick}
      />
    ));
  }

  openModal = () => {
    this.props.actions.openModal();
  };

  onUpdateGmailOrganizationID = (e) => {
    if (/^\d+$/.test(e.target.value) === false) {
      return;
    }
    const { gmail } = this.state;
    this.setState({
      gmail: { ...gmail, organizationID: e.target.value },
    });
  };

  renderGmailButton(renderProps) {
    return (
      <Button onClick={renderProps.onClick} disabled={renderProps.disabled}>
        <i className="mdi mdi-gmail" />
      </Button>
    );
  }

  render() {
    const { pagination, actions } = this.props;
    const { currentPage, totalPages, limit, totalResults } = pagination;
    return (
      <div>
        <ListController
          actions={
            <div>
              <Button onClick={this.openModal}>
                <i className="mdi mdi-plus" />
              </Button>
              <Button onClick={this.refresh}>
                <i className="mdi mdi-refresh" />
              </Button>
              <GoogleLogin
                clientId={getGoogleLoginClientId()}
                buttonText="Login"
                onSuccess={this.openGmailModal}
                cookiePolicy="single_host_origin"
                scope="https://www.googleapis.com/auth/gmail.modify"
                accessType="offline"
                responseType="code"
                render={this.renderGmailButton}
                prompt="consent"
              />
            </div>
          }
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalResults={totalResults}
          onLimitChange={actions.changeLimit}
          rowsLength={0}
          type="endpointlist"
        >
          {this.renderList()}
        </ListController>
        <EndpointModal onCloseModal={actions.closeModal} />
        {this.state.gmail.isModalOpen && (
          <Modal
            modalStyle="dynamic"
            title="Link organization to GMAIL access"
            className="EndpointModal"
            confirmButtonText="Save"
            onConfirm={this.exchangeGmailCodeForAccess}
            onClose={this.closeGmailModal}
          >
            <InputWithLabel
              inputId="endpoint-gmail-consent-organization"
              label="Organization ID"
            >
              <Text
                id="endpoint-gmail-consent-organization"
                value={this.state.gmail.organizationID}
                placeholder="organization id"
                onChange={this.onUpdateGmailOrganizationID}
              />
            </InputWithLabel>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(EndpointList);
