import React from 'react';
import { ModuleWrapper } from 'redux/register';

import OrganizationDeactivate from './components';
import { REDUCER_KEY } from './constants';
import reducer from './reducers';

export default function OrganizationDeactivateContainer(props) {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <OrganizationDeactivate {...props} />
    </ModuleWrapper>
  );
}
