import classNames from 'classnames';
import { useState } from 'react';

import { XPATH_TYPES } from '../../../constants';
import { Xpath, XpathList } from '../../../types';

import styles from './list-element.module.scss';

interface Props {
  highestParentElement?: Xpath;
  element: Xpath;
  renderChildren: (
    children: XpathList,
    highestParentElement?: Xpath,
  ) => JSX.Element;
  selectElement: (element: Xpath) => void;
  updateShowElementCreationModal: (element: Xpath) => void;
  updateShowElementDeletionModal: (element: Xpath) => void;
  updateShowChildElementCreationModal: (element: Xpath) => void;
}

export function ListElement({
  highestParentElement,
  element,
  renderChildren,
  selectElement,
  updateShowElementCreationModal,
  updateShowChildElementCreationModal,
  updateShowElementDeletionModal,
}: Props) {
  const [showChildren, setShowChildren] = useState(false);

  return (
    <div data-testid={element.id}>
      <div className={styles.listContainer}>
        <button
          className={classNames('alk-btn-phantom', styles.button)}
          data-testid={`chevron-${element.id}`}
          onClick={() =>
            element.children.length === 0
              ? updateShowChildElementCreationModal(element)
              : setShowChildren(!showChildren)
          }
        >
          <i
            className={classNames('mdi', {
              'mdi-chevron-down': showChildren,
              'mdi-chevron-right': !showChildren,
            })}
          />
        </button>
        <button
          className={classNames(
            'alk-btn-phantom',
            styles.button,
            element.is_inactive ? styles.inactiveElement : '',
          )}
          onClick={() => selectElement(highestParentElement || element)}
        >
          {element.type === XPATH_TYPES.avp ? 'AVPs' : element.name}
        </button>
        <button
          className={classNames('alk-btn-phantom', styles.button)}
          data-testid={`plus-${element.id}`}
          onClick={() => updateShowElementCreationModal(element)}
        >
          <i className="mdi mdi-plus" />
        </button>
        <button
          className={classNames('alk-btn-phantom', styles.button)}
          data-testid={`delete-${element.id}`}
          onClick={() => updateShowElementDeletionModal(element)}
        >
          <i className="mdi mdi-trash-can-outline" />
        </button>
      </div>
      {showChildren && renderChildren(element.children, highestParentElement)}
    </div>
  );
}
