import { Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  RECIPIENTS_RULE,
  VALUE_RECIPIENT_RULE_RESTRICTED,
} from 'constants/organization-settings';

import * as events from '../constants/events';

const initialState = fromJS({
  organization: {
    id: null,
    restricted: false,
  },
  recipients: [],
  editedRecipients: null,
});

export default createReducer(initialState, {
  [events.ORGANIZATION_RECEIVED]: (state, { organization }) =>
    state.withMutations((newState) =>
      newState
        .setIn(['organization', 'id'], organization.get('id'))
        .setIn(
          ['organization', 'restricted'],
          organization.getIn(['settings', RECIPIENTS_RULE]) ===
            VALUE_RECIPIENT_RULE_RESTRICTED,
        ),
    ),

  [events.RECEIVE_RECIPIENTS]: (state, { recipients }) =>
    state.withMutations((newState) =>
      newState
        .set(
          'recipients',
          recipients.map((recipient) =>
            recipient.filter(
              (value, key) =>
                !!['organization_id', 'organization_name', 'type'].find(
                  (field) => field === key,
                ),
            ),
          ),
        )
        .set('editedRecipients', null),
    ),

  [events.ADD_RECIPIENT]: (state, { targetOrganization, relationType }) => {
    const source = state.get('recipients');
    const edited = (
      state.get('editedRecipients') || state.get('recipients')
    ).push(
      Map({
        organization_id: targetOrganization.id,
        organization_name: targetOrganization.nameLegal,
        type: relationType,
      }),
    );
    if (edited.sort().equals(source.sort())) {
      return state.set('editedRecipients', null);
    }
    return state.set('editedRecipients', edited);
  },

  [events.REMOVE_RECIPIENT]: (state, { targetOrganizationId }) => {
    const source = state.get('recipients');
    const edited = (
      state.get('editedRecipients') || state.get('recipients')
    ).filterNot(
      (recipient) => recipient.get('organization_id') === targetOrganizationId,
    );
    if (edited.sort().equals(source.sort())) {
      return state.set('editedRecipients', null);
    }
    return state.set('editedRecipients', edited);
  },
});
