import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import {
  COLUMN_SUPPLIER_INTERNAL_ID,
  COLUMN_SUPPLIER_NAME,
  LISTCOLUMN,
  SUPPLIER_DIRECTORY,
} from 'constants/organization-settings';
import { get, set } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import {
  retrieveSuppliersReconciliationKeysReferential,
  updateSettingValue,
} from '../../actions/settings';
import {
  selectEditedOrSourceSetting,
  selectEditedSettings,
  selectListColumnReferential,
  selectSettings,
  selectSuppliersReconciliationKeysReferential,
} from '../../selectors/settings';

import LabeledCheckbox from './controllers/checkbox';
import ListMultipleController from './controllers/listmultiple';
import './supplier-directory.scss';
import { getListColumnValueForUpdate } from './utils';

const mapStateToProps = (state) => ({
  supplierDirectorySetting: selectEditedOrSourceSetting(state, [
    SUPPLIER_DIRECTORY,
  ]),
  reconciliationKeyReferential:
    selectSuppliersReconciliationKeysReferential(state),
  settings: selectSettings(state),
  editedSettings: selectEditedSettings(state),
  listColumnReferential: selectListColumnReferential(state),
});

const mapDispatchToProps = {
  updateSettingValue,
  retrieveSuppliersReconciliationKeysReferential,
};

export class OrganizationPageSettingsSupplierDirectory extends PureComponent {
  static propTypes = {
    isReadOnly: PropTypes.bool,
    supplierDirectorySetting: ImmutablePropTypes.map,
    reconciliationKeyReferential: ImmutablePropTypes.list.isRequired,
    settings: ImmutablePropTypes.map.isRequired,
    editedSettings: ImmutablePropTypes.map,
    listColumnReferential: ImmutablePropTypes.list.isRequired,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
      retrieveSuppliersReconciliationKeysReferential: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.retrieveSuppliersReconciliationKeysReferential();
  }

  onEnabledChanged = (enabled) => {
    const {
      supplierDirectorySetting,
      actions,
      settings,
      editedSettings,
      listColumnReferential,
    } = this.props;
    const updatedSetting = fromJS({
      enabled,
      reconciliationKeys:
        get(supplierDirectorySetting, 'reconciliationKeys') || [],
    });
    actions.updateSettingValue([SUPPLIER_DIRECTORY], updatedSetting);

    actions.updateSettingValue(
      [LISTCOLUMN],
      getListColumnValueForUpdate(
        settings,
        editedSettings,
        listColumnReferential,
        [COLUMN_SUPPLIER_NAME, COLUMN_SUPPLIER_INTERNAL_ID],
        enabled,
      ),
    );
  };

  onAutomaticContactInvitationChanged = (enabled) => {
    const { supplierDirectorySetting, actions } = this.props;
    const updatedSetting = set(
      supplierDirectorySetting,
      'automaticContactInvitation',
      enabled,
    );
    actions.updateSettingValue([SUPPLIER_DIRECTORY], updatedSetting);
  };

  render() {
    const {
      supplierDirectorySetting,
      isReadOnly,
      reconciliationKeyReferential,
    } = this.props;
    const enabled = get(supplierDirectorySetting, 'enabled') || false;
    const automaticContactInvitation =
      get(supplierDirectorySetting, 'automaticContactInvitation') || false;

    return (
      <>
        <LabeledCheckbox
          label="Supplier directory"
          onChange={this.onEnabledChanged}
          checked={enabled}
          disabled={isReadOnly}
        />
        {enabled && (
          <div className="OrganizationPageSupplierDirectory__content">
            <LabeledCheckbox
              label="Automatic contact invitation"
              onChange={this.onAutomaticContactInvitationChanged}
              checked={automaticContactInvitation}
              disabled={isReadOnly}
              help="This option is only available for retailers that have not subscribed to Salsify SupplierXM onboarding"
            />
            <div className="OrganizationPageSupplierDirectory__subtitle">
              Reconciliation keys{' '}
              <HelpTooltip
                id="reconciliation-key-help"
                place="top"
                message="Keys used in the reconciliation process to match suppliers and organizations"
              />
            </div>
            <ListMultipleController
              pathsLabels={[]}
              path={[SUPPLIER_DIRECTORY]}
              subPaths={['reconciliationKeys']}
              options={reconciliationKeyReferential}
              optionsIdPath="code"
              disabled={isReadOnly}
            />
          </div>
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(OrganizationPageSettingsSupplierDirectory);
