import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { Text } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';

import { SAVE_NEW_XPATH_ELEMENT } from '../../../actions/constants';

import styles from './new-module-creation.module.scss';

interface Props {
  closeXpathCreationView: () => void;
  moduleOrder: number | undefined;
}

export function ExportMappingsNewModuleCreation({
  closeXpathCreationView,
  moduleOrder,
}: Props): JSX.Element {
  const [snakeCaseName, setSnakeCaseName] = useState<string | undefined>(
    undefined,
  );
  const [camelCaseName, setCamelCaseName] = useState<string | undefined>(
    undefined,
  );

  const dispatch = useDispatch();

  const saveNewModule = (): void => {
    dispatch({
      type: SAVE_NEW_XPATH_ELEMENT,
      name: snakeCaseName,
      moduleName: camelCaseName,
      elementType: 'MODULE',
      order: moduleOrder,
    });
    closeXpathCreationView();
  };

  return (
    <div className={styles.container}>
      <InputWithLabel
        inputId="gdsn-export-mappings-snake-case-name"
        label={
          <span>
            Snake case name{' '}
            <HelpTooltip
              id={`gdsn-export-mappings-snake-case-name-tooltips`}
              place="top"
              message="Name of the module typed this way: name_of_the_module. It can be found on the first xsd schema attibutes of the module."
            />
          </span>
        }
      >
        <Text
          id={`gdsn-export-mappings-snake-case-name`}
          placeholder="name_of_new_module"
          value={snakeCaseName || ''}
          onChange={(e) => setSnakeCaseName(e.target.value || undefined)}
          invalid={!snakeCaseName}
        />
      </InputWithLabel>
      <InputWithLabel
        inputId="gdsn-export-mappings-camel-case-name"
        label={
          <span>
            Camel case name{' '}
            <HelpTooltip
              id={`gdsn-export-mappings-camel-case-name-tooltips`}
              place="top"
              message="Name of the module typed this way: nameOfTheModule. It can be found on the url of the module xsd."
            />
          </span>
        }
      >
        <Text
          id={`gdsn-export-mappings-camel-case-name`}
          placeholder="nameOfNewModule"
          value={camelCaseName || ''}
          onChange={(e) => setCamelCaseName(e.target.value || '')}
        />
      </InputWithLabel>
      <div className={styles.buttonsContainer}>
        <Button secondary content="Cancel" onClick={closeXpathCreationView} />
        <Button
          primary
          content="Save"
          onClick={saveNewModule}
          disabled={!snakeCaseName}
        />
      </div>
    </div>
  );
}
