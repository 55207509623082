import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class TextInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onLocalChange = this.onLocalChange.bind(this);
    this.onChange = debounce(this.onChange.bind(this), 400);
    this.state = { value: props.value || '' };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value }); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    this.onChange.cancel();
  }

  onChange(value) {
    this.props.onChange(value);
  }

  onLocalChange(event) {
    this.setState({ value: event.target.value });
    this.onChange(event.target.value);
  }

  render() {
    return (
      <input
        type="text"
        className={this.props.className}
        value={this.state.value}
        placeholder={this.props.placeholder}
        onChange={this.onLocalChange}
      />
    );
  }
}
