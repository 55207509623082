import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';

import { referentialDashboardAccessPolicy } from 'access-policies';
import { notificationError } from 'actions/notifications';
import { ReferentialAutocomplete } from 'components/autocomplete';
import * as routes from 'constants/routes';
import { selectUserReadOnlyAccess } from 'modules/auth/selectors';
import { separateActions } from 'utils/redux';

import {
  deleteReferential,
  getReferentialList,
  refreshCache,
  selectReferential,
  updateReferentialTranslatable,
} from '../actions';
import {
  getEntitiesByRefIds,
  getReferentials,
  getRefreshingCache,
} from '../selectors';

import './index.scss';
import Referential from './referential';
import ReferentialGroups from './referentialGroups';

const mapStateToProps = (state) => ({
  referentials: getReferentials(state),
  entitiesByRefId: getEntitiesByRefIds(state),
  refreshingCache: getRefreshingCache(state),
  readOnly: selectUserReadOnlyAccess(referentialDashboardAccessPolicy, state),
});

const mapDispatchToProps = {
  getReferentialList,
  selectReferential,
  refreshCache,
  deleteReferential,
  updateReferentialTranslatable,
  notificationError,
};

export class ReferentialsManager extends Component {
  static propTypes = {
    referentials: ImmutablePropTypes.list.isRequired,
    entitiesByRefId: ImmutablePropTypes.map.isRequired,
    refreshingCache: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      getReferentialList: PropTypes.func.isRequired,
      selectReferential: PropTypes.func.isRequired,
      refreshCache: PropTypes.func.isRequired,
      deleteReferential: PropTypes.func.isRequired,
      updateReferentialTranslatable: PropTypes.func.isRequired,
      notificationError: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    selectedReferential: null,
    isReferentialGroupSelected: false,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.getReferentialList();
  }

  onDeleteReferential = async () => {
    const { actions } = this.props;
    const { selectedReferential } = this.state;
    // eslint-disable-next-list no-alert
    const confirmed = window.confirm(
      'Are you sure you want to delete this entire referential?',
    );
    if (confirmed) {
      await actions.deleteReferential(selectedReferential.slug);
      this.setState({ selectedReferential: null });
    }
  };

  onUpdateReferentialTranslatable = async () => {
    const { actions } = this.props;
    const { selectedReferential } = this.state;
    const { error } = await actions.updateReferentialTranslatable(
      selectedReferential.id,
      !selectedReferential.translatable,
    );
    if (!error) {
      this.setState({
        selectedReferential: {
          ...selectedReferential,
          translatable: !selectedReferential.translatable,
        },
      });
    }
  };

  onRefreshCache = (referentialSlug) => {
    const { actions } = this.props;
    actions.refreshCache(referentialSlug);
  };

  onReferentialSelect = (value) => {
    this.props.actions.selectReferential(
      value.value ? fromJS(value.value) : fromJS({}),
    );
    this.setState({
      selectedReferential: value.value,
      isReferentialGroupSelected: false,
    });
  };

  getIsDirty() {
    let dirty = false;
    if (this.state.selectedReferential) {
      dirty = this.props.entitiesByRefId.getIn([
        this.state.selectedReferential.id,
        'dirty',
      ]);
    }
    return dirty;
  }

  getEnv() {
    return window.env;
  }

  setReferentialGroupSelected = (isReferentialGroupSelected) => () => {
    if (this.getIsDirty()) {
      this.displayShouldDiscard();
    } else {
      this.setState({ isReferentialGroupSelected });
    }
  };

  displayShouldDiscard() {
    this.props.actions.notificationError(
      'You have unsaved changes. Please save them or discard them before switching to another referential.',
    );
  }

  renderHeader() {
    const { readOnly } = this.props;
    const { selectedReferential, refreshingCache } = this.state;
    return (
      <div className="Referential__header">
        <ReferentialAutocomplete
          id="referential-select"
          value={selectedReferential ? [selectedReferential] : []}
          onSelect={this.onReferentialSelect}
          onUnselect={this.onReferentialSelect}
          disabled={this.getIsDirty()}
          searchOnClick
        />
        {!readOnly && selectedReferential != null && (
          <Button
            id="cache-refresh-button"
            content="Refresh cache"
            onClick={() => this.onRefreshCache(selectedReferential.slug)}
            disabled={refreshingCache}
            displaySpinner={refreshingCache}
            warning
          />
        )}
      </div>
    );
  }

  renderActions() {
    const { readOnly } = this.props;
    const { selectedReferential, isReferentialGroupSelected } = this.state;
    if (!selectedReferential) {
      return null;
    }
    return (
      <div className="ReferentialDetail__Header">
        <div className="ReferentialsManager__btnGroup btn-group">
          <Button
            secondary
            className={classNames({
              selected: !isReferentialGroupSelected,
            })}
            onClick={this.setReferentialGroupSelected(false)}
          >
            <span>Referential entities</span>
          </Button>
          <Button
            secondary
            className={classNames({
              selected: isReferentialGroupSelected,
            })}
            onClick={this.setReferentialGroupSelected(true)}
          >
            <span>Referential groups</span>
          </Button>
        </div>
        {!readOnly && (
          <div className="ReferentialDetail__actions">
            {this.getEnv() === 'ppr' && (
              <Button
                id="ReferentialManager-delete-referential-button"
                content="Delete"
                onClick={this.onDeleteReferential}
                warning
              />
            )}
            <Button
              id="ReferentialManager-update-referential-translatable-button"
              content={
                selectedReferential.translatable
                  ? 'Remove from translations'
                  : 'Add to translations'
              }
              onClick={this.onUpdateReferentialTranslatable}
              warning
            />
          </div>
        )}
      </div>
    );
  }

  renderReferential() {
    const { selectedReferential, isReferentialGroupSelected } = this.state;
    const { readOnly } = this.props;
    if (!selectedReferential) {
      return null;
    }
    const displayed = isReferentialGroupSelected ? (
      <ReferentialGroups
        referential={fromJS(selectedReferential)}
        readOnly={readOnly}
      />
    ) : (
      <Referential
        referential={fromJS(selectedReferential)}
        readOnly={readOnly}
      />
    );
    return <div className="ReferentialDetail__content">{displayed}</div>;
  }

  render() {
    const headerProps = {
      title: 'Referentials Management',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };
    return (
      <div className="ReferentialsManager__body">
        <HeaderLayout {...headerProps} />
        <div className="ReferentialsManager__content">
          {this.renderHeader()}
          {this.renderActions()}
          {this.renderReferential()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(ReferentialsManager);
