import classNames from 'classnames';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { SwitchButton } from '@alkem/react-ui-button';
import {
  CheckboxFilter,
  Filter,
  Filters,
  FiltersBlock,
  SelectedFilters,
  TreeFilter,
} from '@alkem/react-ui-list-filter';
import { SimpleSelect } from '@alkem/react-ui-select';

import { getKindList } from 'actions/kinds';
import {
  addFilter,
  clearFilters,
  fetchInitialAggregations,
  removeFilter,
  toggleShowHierarchyChildren,
} from 'modules/field-suggestion/actions';
import {
  FIELDMETADATA_ISCLASSIFIEDIN_ID,
  FIELDMETADATA_KIND_ID,
  IS_CURRENT_VALUE_FILTERS,
  LANGUAGES_FILTERS,
  METRIC_VALUES,
  SOURCE_FILTER,
  VALIDATION_STATUS_FILTERS,
} from 'modules/field-suggestion/constants';
import {
  selectFieldMetaData,
  selectFieldMetaDataAggregates,
  selectFilters,
  selectInternalCategories,
  selectInternalCategoriesIdPath,
  selectOrganizations,
  selectShowChildren,
  selectSourceCount,
} from 'modules/field-suggestion/selectors';
import { get } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import AdvancedFilter from './advanced-filter/filter';
import './filters.scss';

const mapStateToProps = (state) => ({
  filters: selectFilters(state),
  kindTree: state.kinds.tree,
  kindTreeToPath: state.kinds.idToPath,
  fieldMetaDataAggregates: selectFieldMetaDataAggregates(state),
  fieldMetaData: selectFieldMetaData(state),
  internalCategories: selectInternalCategories(state),
  internalCategoriesToPath: selectInternalCategoriesIdPath(state),
  organizations: selectOrganizations(state),
  sourceCount: selectSourceCount(state),
  showChildren: selectShowChildren(state),
});

const mapDispatchToProps = {
  getKindList,
  fetchInitialAggregations,
  addFilter,
  removeFilter,
  clearFilters,
  toggleShowHierarchyChildren,
};

export class FieldSuggestionFilters extends PureComponent {
  static propTypes = {
    filters: ImmutablePropTypes.map.isRequired,
    organizations: ImmutablePropTypes.list.isRequired,
    sourceCount: ImmutablePropTypes.list.isRequired,
    kindTree: PropTypes.object.isRequired,
    kindTreeToPath: PropTypes.object.isRequired,
    internalCategories: PropTypes.array.isRequired,
    internalCategoriesToPath: PropTypes.object.isRequired,
    fieldMetaData: PropTypes.object,
    fieldMetaDataAggregates: ImmutablePropTypes.list.isRequired,
    showChildren: PropTypes.bool,
    actions: PropTypes.shape({
      getKindList: PropTypes.func.isRequired,
      fetchInitialAggregations: PropTypes.func.isRequired,
      addFilter: PropTypes.func.isRequired,
      removeFilter: PropTypes.func.isRequired,
      clearFilters: PropTypes.func.isRequired,
      toggleShowHierarchyChildren: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      orgaPage: 1,
      orgaList: props.organizations,
      fieldPage: 1,
      fieldList: [],
    };
  }

  componentDidMount() {
    this.props.actions.getKindList();
    this.props.actions.fetchInitialAggregations();
  }

  componentDidUpdate(prevProps) {
    const { organizations, fieldMetaData } = this.props;
    const { orgaList } = organizations;
    if (orgaList !== prevProps.organizations.orgaList) {
      this.setState({ orgaList }); // eslint-disable-line
    }
    const fieldList = fieldMetaData.get('options');
    if (fieldList !== prevProps.fieldMetaData.get('options')) {
      this.setState({ fieldList }); // eslint-disable-line
    }
  }

  // Actions
  onFilterChange = (filterType, filterId, toggleFilterType, filterLabel) => {
    const isFilterAlreadySelected = get(
      get(this.props.filters, filterType),
      filterId,
    );
    if (isFilterAlreadySelected) {
      this.props.actions.removeFilter(filterType, filterId);
    } else {
      this.props.actions.addFilter(
        filterType,
        toggleFilterType,
        filterId,
        filterLabel,
      );
    }
  };

  onValidationStatusFilterChange = (filter) =>
    this.onFilterChange(
      'validationStatus',
      filter.id,
      false,
      `validation status: ${filter.label}`,
    );

  onSourceFilterChange = (filter) =>
    this.onFilterChange('source', filter.id, false, `source: ${filter.label}`);

  onCurrentValueFilterChange = (filter) =>
    this.onFilterChange(
      'isCurrentValue',
      filter.id,
      false,
      `is current value: ${filter.label}`,
    );

  onLanguagesFilterChange = (filter) =>
    this.onFilterChange(
      'languages',
      filter.id,
      false,
      `languages: ${filter.label}`,
    );

  onFilterToggleMetricValue = (filter) =>
    this.onFilterChange(
      'metricValue',
      filter.id,
      true,
      `probability: ${filter.label}`,
    );

  onFilterToggleNumberSources = (filter) =>
    this.onFilterChange(
      'nbSources',
      filter.id,
      true,
      `number of sources: ${filter.label}`,
    );

  onFilterToggleFieldMetaData = (filter) =>
    this.onFilterChange(
      'fieldMetaData',
      filter.id,
      true,
      `fieldmetadata: ${filter.label}`,
    );

  onFieldIdFilterChangeKind = (filter) =>
    this.onFilterChange(
      'fieldIds',
      filter.id,
      false,
      `field: ${get(filter, 'name')}`,
    );

  onFieldIdFilterChange = ({ value, label }) =>
    this.onFilterChange('fieldIds', value, false, label);

  onOrganizationFilterChange = ({ value, label }) =>
    this.onFilterChange('organizations', value, false, label);

  // organization filter display
  onChangeOrgaFilterPage = (key, page) => {
    this.setState({ orgaPage: page });
  };

  onChangeFieldFilterPage = (key, page) => {
    this.setState({ fieldPage: page });
  };

  onFilterOrgaFilter = (key, query) => {
    const orgaList = this.props.organizations.filter(
      (e) => e.get('label').toLowerCase().indexOf(query.toLowerCase()) > -1,
    );
    this.setState({ orgaList });
  };

  onFilterFieldFilter = (key, query) => {
    const fieldList = this.props.fieldMetaData
      .get('options')
      .filter(
        (e) => e.get('label').toLowerCase().indexOf(query.toLowerCase()) > -1,
      );
    this.setState({ fieldList });
  };

  getSourceCount = memoize((list) => list.toJS());
  getFieldMetaDataAggregates = memoize((list) => list.toJS());

  removeFromSelected = (filter) => {
    this.props.actions.removeFilter(
      get(filter, 'filterType'),
      get(filter, 'filterId'),
    );
  };

  isFilterSelectedKind = (filter, selection) => {
    const { showChildren, kindTreeToPath } = this.props;
    if (showChildren) {
      const idWithParents = kindTreeToPath[get(filter, 'id')] || [
        get(filter, 'id'),
      ];
      return idWithParents.some((id) => get(selection, id));
    }
    return get(selection, get(filter, 'id'));
  };

  isFilterSelectedRetailerTaxonomy = (filter, selection) => {
    const { showChildren, internalCategoriesToPath } = this.props;
    if (showChildren) {
      const idWithParents = internalCategoriesToPath[get(filter, 'id')] || [
        get(filter, 'id'),
      ];
      return idWithParents.some((id) => get(selection, id));
    }
    return get(selection, get(filter, 'id'));
  };

  // Selectors
  isFilterSelected(filter, selection) {
    return get(selection, get(filter, 'id'));
  }

  selectSelectedFilterId(filter) {
    return `${get(filter, 'filterType')}-${get(filter, 'filterId')}`;
  }

  selectSelectedFilterLabel(filter) {
    return get(filter, 'filterLabel');
  }

  buildSelector(filterType) {
    return {
      selectId: (filter) => `${filterType}-${get(filter, 'id')}`,
      selectNode: (filter) => get(filter, 'label') || get(filter, 'name'),
    };
  }

  renderSingleStatusFilter(props) {
    const classes = {
      FieldSuggestionFilters__statusBar: true,
      [`FieldSuggestionFilters__statusBar--${props.filter.statusBarType}`]: true,
    };
    return (
      <div className="FieldSuggestionFilters__singleStatus">
        <span className={classNames(classes)} />
        <CheckboxFilter {...props} />
      </div>
    );
  }

  // Renderers
  renderStatusFilters() {
    const { filters } = this.props;
    if (!filters.has('validationStatus')) {
      return null;
    }

    return (
      <Filter
        title="Validation Status"
        id="fieldsuggestion-filter-status"
        filters={VALIDATION_STATUS_FILTERS}
        selection={filters.get('validationStatus')}
        selectors={this.buildSelector('validationStatus')}
        isSelected={this.isFilterSelected}
        Item={this.renderSingleStatusFilter}
        onChange={this.onValidationStatusFilterChange}
      />
    );
  }

  renderIsCurrentValueFilters() {
    const { filters } = this.props;
    if (!filters.has('isCurrentValue')) {
      return null;
    }
    return (
      <Filter
        title="Current value"
        id="currentvalue-filter"
        filters={IS_CURRENT_VALUE_FILTERS}
        selection={filters.get('isCurrentValue')}
        selectors={this.buildSelector('isCurrentValue')}
        isSelected={this.isFilterSelected}
        Item={CheckboxFilter}
        onChange={this.onCurrentValueFilterChange}
      />
    );
  }

  renderLanguageFilters() {
    const { filters } = this.props;
    if (!filters.has('languages')) {
      return null;
    }
    return (
      <Filter
        title="Languages"
        id="languages-filter"
        filters={LANGUAGES_FILTERS}
        selection={filters.get('languages')}
        selectors={this.buildSelector('languages')}
        isSelected={this.isFilterSelected}
        Item={CheckboxFilter}
        onChange={this.onLanguagesFilterChange}
      />
    );
  }

  renderSourcesFilters() {
    const { filters } = this.props;
    if (!filters.has('source')) {
      return null;
    }
    return (
      <Filter
        title="Sources"
        id="source-filters"
        filters={SOURCE_FILTER}
        selection={filters.get('source')}
        selectors={this.buildSelector('source')}
        isSelected={this.isFilterSelected}
        Item={CheckboxFilter}
        onChange={this.onSourceFilterChange}
      />
    );
  }

  renderOrganizationsFilter() {
    const { filters, organizations } = this.props;
    const { orgaPage, orgaList } = this.state;
    if (organizations.isEmpty()) {
      return null;
    }
    return (
      <div className="FieldSuggestionFilters__organizations">
        <AdvancedFilter
          id="fieldsuggestion-filter-organizations"
          filterKey="organizations"
          filterLabel="Organization"
          filters={orgaList}
          selectedFilterMap={filters.get('organizations')}
          page={orgaPage}
          itemsPerPage={10}
          onChangePage={this.onChangeOrgaFilterPage}
          onFilter={this.onFilterOrgaFilter}
          onChange={this.onOrganizationFilterChange}
          searchPlaceholder="Search for organization"
        />
      </div>
    );
  }

  renderFieldMetaDataDropdownFilter() {
    const { fieldMetaData, fieldMetaDataAggregates } = this.props;
    const selectedFieldMetaData =
      Object.keys(fieldMetaData).length === 0 ? null : fieldMetaData.toJS();
    return (
      <div className="FieldSuggestionFilters__fmdSelector">
        <SimpleSelect
          autoSize
          id="fmd-dropdown"
          onSelect={this.onFilterToggleFieldMetaData}
          value={selectedFieldMetaData}
          options={this.getFieldMetaDataAggregates(fieldMetaDataAggregates)}
          placeholder="Select a field"
        />
      </div>
    );
  }

  renderChildrenSwitch() {
    return (
      <div className="ShowChildren__switch">
        <SwitchButton
          content="Select children"
          onChange={this.props.actions.toggleShowHierarchyChildren}
          checked={this.props.showChildren}
        />
      </div>
    );
  }

  renderKindFilter() {
    const { kindTree, filters } = this.props;
    return (
      <div>
        {this.renderChildrenSwitch()}
        <Filter
          title="Kinds"
          id="fieldsuggestion-filter-kind"
          filters={kindTree.children}
          selection={filters.get('fieldIds')}
          selectors={this.buildSelector('kinds')}
          isSelected={this.isFilterSelectedKind}
          Item={TreeFilter}
          onChange={this.onFieldIdFilterChangeKind}
        />
      </div>
    );
  }

  renderInternalCategoriesFilter() {
    const { internalCategories, filters } = this.props;
    return (
      <div>
        {this.renderChildrenSwitch()}
        <Filter
          title="Internal categories"
          id="fieldsuggestion-filter-isclassifiedin"
          filters={internalCategories}
          selection={filters.get('fieldIds')}
          selectors={this.buildSelector('isclassifiedin')}
          isSelected={this.isFilterSelectedRetailerTaxonomy}
          Item={TreeFilter}
          onChange={this.onFieldIdFilterChangeKind}
        />
      </div>
    );
  }

  renderFieldsFilter() {
    const { filters } = this.props;
    const { fieldPage, fieldList } = this.state;

    return (
      <div className="FieldSuggestionFilters__organizations">
        <AdvancedFilter
          id="fieldsuggestion-filter-fields"
          filterKey="fieldIds"
          filterLabel="field"
          filters={fieldList}
          selectedFilterMap={filters.get('fieldIds')}
          page={fieldPage}
          itemsPerPage={10}
          onChangePage={this.onChangeFieldFilterPage}
          onFilter={this.onFilterFieldFilter}
          onChange={this.onFieldIdFilterChange}
          searchPlaceholder="Search field"
        />
      </div>
    );
  }

  renderFieldsDropdownFilter() {
    const { fieldMetaDataAggregates, fieldMetaData, kindTree } = this.props;
    if (
      fieldMetaDataAggregates.isEmpty() ||
      Object.keys(fieldMetaData).length === 0 ||
      !kindTree ||
      !kindTree.children
    ) {
      return null;
    }
    if (fieldMetaData.get('id') === FIELDMETADATA_KIND_ID) {
      return this.renderKindFilter();
    }
    if (fieldMetaData.get('id') === FIELDMETADATA_ISCLASSIFIEDIN_ID) {
      return this.renderInternalCategoriesFilter();
    }
    return this.renderFieldsFilter();
  }

  renderProbaDropdownFilter() {
    const { filters } = this.props;
    const selectedMetricValueId = filters.get('metricValue').keySeq().first();
    const formattedMetricValue = selectedMetricValueId
      ? {
          id: selectedMetricValueId,
          value: selectedMetricValueId,
          label: selectedMetricValueId,
        }
      : null;
    return (
      <div className="FieldSuggestionFilters__fmdSelector">
        <SimpleSelect
          autoSize
          id="fmd-dropdown"
          onSelect={this.onFilterToggleMetricValue}
          value={formattedMetricValue}
          options={METRIC_VALUES}
          placeholder="Select a value"
        />
      </div>
    );
  }

  renderSourcesDropdownFilter() {
    const { filters, sourceCount } = this.props;
    const concordantNumberValueId = filters.get('nbSources').keySeq().first();
    const formattedNumberSources = concordantNumberValueId
      ? {
          id: concordantNumberValueId,
          value: concordantNumberValueId,
          label: concordantNumberValueId,
        }
      : null;
    return (
      <div className="FieldSuggestionFilters__fmdSelector">
        <SimpleSelect
          autoSize
          id="fmd-dropdown"
          onSelect={this.onFilterToggleNumberSources}
          value={formattedNumberSources}
          options={this.getSourceCount(sourceCount)}
          placeholder="Select a value"
        />
      </div>
    );
  }

  renderTitle(title) {
    return (
      <div className="FieldSuggestionFilters__fieldset">
        <h3 className="FieldSuggestionFilters__title">{title}</h3>
      </div>
    );
  }

  render() {
    const { actions, filters } = this.props;
    return (
      <Filters>
        <SelectedFilters
          id="fieldsuggestion-selectedfilters"
          title="Selected Filters"
          emptyLabel="No selected filters"
          removeLabel="Remove"
          filters={filters.get('selected')}
          selectId={this.selectSelectedFilterId}
          selectLabel={this.selectSelectedFilterLabel}
          onRemoveAll={actions.clearFilters}
          onRemove={this.removeFromSelected}
        />
        <FiltersBlock>
          <div className="card">
            {this.renderLanguageFilters()}
            {this.renderStatusFilters()}
            {this.renderIsCurrentValueFilters()}
            {this.renderTitle('Field')}
            {this.renderFieldMetaDataDropdownFilter()}
            {this.renderFieldsDropdownFilter()}
            {this.renderTitle('Organizations')}
            {this.renderOrganizationsFilter()}
            {this.renderSourcesFilters()}
            {this.renderTitle('Number of sources')}
            {this.renderSourcesDropdownFilter()}
            {this.renderTitle('Probability')}
            {this.renderProbaDropdownFilter()}
          </div>
        </FiltersBlock>
      </Filters>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(FieldSuggestionFilters);
