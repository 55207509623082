import { useState } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio } from '@alkem/react-ui-inputs';

import InputWithLabel from 'components/input-with-label';
import { ExportMapping, Xpath } from 'modules/io/gdsn/exports/types';

import styles from '../item-properties.module.scss';

interface Props {
  name: string;
  exportMapping: ExportMapping;
  parentElement: Xpath;
  dispatchProperty: (name: string, value: any) => void;
}
export function ExportMappingsItemNewModuleProperty({
  name,
  exportMapping,
  parentElement,
  dispatchProperty,
}: Props) {
  const [mustCreateNewModule, setMustCreateNewModule] = useState<boolean>(
    !!exportMapping.payload.new_module,
  );

  const updateMustCreateNewModule = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const updatedMustCreateNewModule = value === 'true';
    setMustCreateNewModule(updatedMustCreateNewModule);
    dispatchProperty(
      'new_module',
      updatedMustCreateNewModule ? parentElement.name : undefined,
    );
  };

  return (
    <div
      className={styles.property}
      data-testid={`gdsn-export-mappings-new-module-property-${name}`}
    >
      <InputWithLabel
        inputId={`gdsn-export-mappings-new-module-property-${name}`}
        label={
          <span>
            Create new module{' '}
            <HelpTooltip
              id={`gdsn-export-mappings-new-module-property-${name}-help`}
              place="top"
              message={
                <div className={styles.messageContainer}>
                  <span>
                    Must be set to TRUE if you want to create a new element per
                    child:
                  </span>
                  <span>{`      <parentName>`}</span>
                  <span>{`            <childName />`}</span>
                  <span>{`      </parentName>`}</span>
                  <span>{`      <parentName>`}</span>
                  <span>{`            <childName />`}</span>
                  <span>{`      </parentName>`}</span>
                  <span>instead of creating:</span>
                  <span>{`      <parentName>`}</span>
                  <span>{`            <childName />`}</span>
                  <span>{`            <childName />`}</span>
                  <span>{`      </parentName>`}</span>
                </div>
              }
            />
          </span>
        }
      >
        <Radio
          id={`gdsn-export-mappings-new-module-property-${name}`}
          value={mustCreateNewModule}
          options={[
            { label: 'TRUE', value: true },
            { label: 'FALSE', value: false },
          ]}
          onChange={updateMustCreateNewModule}
        />
      </InputWithLabel>
    </div>
  );
}
