import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { updateMultipleSelection } from '@alkem/react-ui-choicetree';
import { Ellitips } from '@alkem/react-ui-ellitips';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { Modal } from '@alkem/react-ui-modal';
import { ProgressBar } from '@alkem/react-ui-progress';
import { MultipleSelector, SimpleSelect } from '@alkem/react-ui-select';

import { getKindList } from 'actions/kinds';
import { notificationSuccess } from 'actions/notifications';
import {
  bulkEditSuggestions,
  closeModal,
} from 'modules/field-suggestion/actions';
import {
  FIELDMETADATA_ISCLASSIFIEDIN_ID,
  FIELDMETADATA_KIND_ID,
} from 'modules/field-suggestion/constants';
import {
  selectEditedEntitiesIds,
  selectEditing,
  selectEditionErrors,
  selectFieldMetaDataAggregates,
  selectInternalCategories,
  selectList,
  selectModalState,
} from 'modules/field-suggestion/selectors';
import fieldsApiImmutable from 'resources/fieldsApi';
import immutableReferentialApi from 'resources/referentialApi';
import { separateActions } from 'utils/redux';

import './index.scss';

const mapStateToProps = (state) => ({
  list: selectList(state),
  modalState: selectModalState(state),
  kindTree: state.kinds.tree,
  editing: selectEditing(state),
  errors: selectEditionErrors(state),
  editedEntitiesIds: selectEditedEntitiesIds(state),
  internalCategories: selectInternalCategories(state),
  fieldMetaDataAggregates: selectFieldMetaDataAggregates(state),
});

const mapDispatchToProps = {
  getKindList,
  notificationSuccess,
  bulkEditSuggestions,
  closeModal,
};

export class FieldSuggestionEditionModal extends Component {
  static propTypes = {
    fieldMetaDataAggregates: ImmutablePropTypes.list.isRequired,
    editedEntitiesIds: PropTypes.array.isRequired,
    editing: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    modalState: PropTypes.object.isRequired,
    list: ImmutablePropTypes.list.isRequired,
    kindTree: PropTypes.object.isRequired,
    internalCategories: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      getKindList: PropTypes.func.isRequired,
      notificationSuccess: PropTypes.func.isRequired,
      bulkEditSuggestions: PropTypes.func.isRequired,
      closeModal: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedFieldMetaData: null,
      selectedField: null,
      fieldOptions: [],
      kindTree: this.formatTree(props.kindTree),
      internalCategories: props.internalCategories.map((cat) =>
        this.formatTree(cat),
      ),
      selection: [],
    };
  }

  componentDidMount() {
    // For the kinds, we actually want to display the full tree
    this.props.actions.getKindList();
  }

  componentDidUpdate(prevProps) {
    const { actions, list, editing, editedEntitiesIds, errors, kindTree } =
      this.props;
    const count = list.filter((e) => e.get('checked')).size;
    if (!editing && editedEntitiesIds.length === count) {
      if (errors.length === 0) {
        actions.closeModal();
        actions.notificationSuccess(
          `${editedEntitiesIds.length} field suggestions were correctly edited`,
        );
      }
    }
    if (kindTree !== prevProps.kindTree) {
      this.setState({ kindTree: this.formatTree(kindTree) }); // eslint-disable-line
    }
  }

  onEdit = () => {
    const { actions } = this.props;
    const { selectedFieldMetaData, selectedField } = this.state;
    actions.bulkEditSuggestions(selectedFieldMetaData, selectedField);
  };

  onSelectField = (value, path, select) => {
    const { selectedFieldMetaData } = this.state;

    this.setState((prevState) => ({
      selection: updateMultipleSelection(
        prevState.selection,
        path,
        select,
        prevState.fieldOptions,
      ),
      selectedField: {
        id:
          selectedFieldMetaData.id === FIELDMETADATA_KIND_ID ||
          selectedFieldMetaData.id === FIELDMETADATA_ISCLASSIFIEDIN_ID
            ? value.code
            : value.id,
        label: value.label,
      },
    }));
  };

  getFieldMetaDataAggregates = memoize((list) => list.toJS());

  getStatus() {
    const { editing, errors } = this.props;
    if (editing) {
      return 'info';
    } else if (errors.length) {
      return 'danger';
    }
    return 'success';
  }

  selectFmd = (selectedFieldMetaData) => {
    if (selectedFieldMetaData.id === FIELDMETADATA_KIND_ID) {
      const { kindTree } = this.state;
      this.setState({
        selectedFieldMetaData: {
          id: selectedFieldMetaData.id,
          label: selectedFieldMetaData.label,
        },
        fieldOptions: [kindTree],
      });
    } else if (selectedFieldMetaData.id === FIELDMETADATA_ISCLASSIFIEDIN_ID) {
      const { internalCategories } = this.state;
      this.setState({
        selectedFieldMetaData: {
          id: selectedFieldMetaData.id,
          label: selectedFieldMetaData.label,
        },
        fieldOptions: internalCategories,
      });
    } else {
      // get referential name
      fieldsApiImmutable
        .FieldMetaDataList({ fieldIds: selectedFieldMetaData.id })
        .then((response) => {
          const fmd = response.data.data[0];
          let referentialName;
          if (fmd.referential) {
            referentialName = fmd.referential;
          } else if (
            fmd.children.some((e) => e.name === 'isConceptualizedBy')
          ) {
            referentialName = fmd.children.find(
              (e) => e.name === 'isConceptualizedBy',
            ).referential;
          } else {
            referentialName = fmd.children.find(
              (e) => e.referential,
            ).referential;
          }
          // get referential values
          immutableReferentialApi
            .ReferentialGetList(referentialName)
            .then((refResponse) => {
              this.setState({
                selectedFieldMetaData: {
                  id: selectedFieldMetaData.id,
                  label: selectedFieldMetaData.label,
                },
                fieldOptions: refResponse.data.data,
              });
            });
        });
    }
  };

  // format kind tree for Select component
  formatTree(tree) {
    const formattedTree = {};
    formattedTree.code = tree.id;
    formattedTree.key = tree.id;
    formattedTree.label = tree.name;
    formattedTree.description = tree.name;
    formattedTree.children = [];
    tree.children.forEach((child) =>
      formattedTree.children.push(this.formatTree(child)),
    );
    return formattedTree;
  }

  renderFailedEntity(error) {
    const id = error.entity.get('id');
    return (
      <div key={id} className="FieldSuggestionValidationModal__failedRow row">
        <div className="col-xs-1">
          {error.entity.getIn(['metadata', 'productversion_id'])}
        </div>
        <div className="col-xs-5">
          <Ellitips
            label={error.entity.getIn(['metadata', 'name'])}
            id={`name-${id}`}
          />
        </div>
        <div className="col-xs-2">
          <Ellitips
            label={error.entity.getIn(['field', 'fieldmetadata_name'])}
            id={`fmd-${id}`}
          />
        </div>
        <div className="col-xs-3">
          <Ellitips
            label={error.entity.getIn(['field', 'field_name'])}
            id={`field-${id}`}
          />
        </div>
        <div className="col-xs-1">
          <HelpTooltip id={`helptooltip-${id}`} message={error.error} />
        </div>
      </div>
    );
  }

  renderProgressBar(max) {
    const { editedEntitiesIds } = this.props;
    const savedCount = editedEntitiesIds.length;
    return (
      <div className="BulkEditModalSave__progressBar">
        <ProgressBar
          value={savedCount}
          max={max}
          color={this.getStatus()}
          height="medium"
        />
      </div>
    );
  }

  renderFailureReportHeader() {
    return (
      <div className="FieldSuggestionValidationModal__failuresHeader row">
        <div className="col-xs-1">PV id</div>
        <div className="col-xs-5">Name</div>
        <div className="col-xs-2">FieldMetaData</div>
        <div className="col-xs-3">Field</div>
        <div className="col-xs-1">Error</div>
      </div>
    );
  }

  renderFmdDropdown() {
    const { fieldMetaDataAggregates } = this.props;
    const { selectedFieldMetaData } = this.state;
    return (
      <div className="FieldSuggestionFilters__fmdSelector">
        <SimpleSelect
          autoSize
          id="fmd-dropdown"
          onSelect={this.selectFmd}
          value={selectedFieldMetaData}
          options={this.getFieldMetaDataAggregates(fieldMetaDataAggregates)}
          placeholder="Select a field to edit"
        />
      </div>
    );
  }

  renderFieldSelector() {
    return (
      <div className="FieldSuggestionFilters__fmdSelector">
        <MultipleSelector
          id="field-select"
          options={this.state.fieldOptions}
          selection={this.state.selection}
          onSelect={this.onSelectField}
          emptyLabel="No results"
          scrollable
          removeFiltered
        />
      </div>
    );
  }

  render() {
    const { actions, list, editing, editedEntitiesIds, errors, modalState } =
      this.props;
    const { selectedFieldMetaData } = this.state;
    const count = list.filter((e) => e.get('checked')).size;

    return (
      <Modal
        title={`${modalState.label} Products`}
        confirmButtonText={modalState.label}
        isProcessing={editing}
        onConfirm={this.onEdit}
        onClose={actions.closeModal}
      >
        <div>
          <div>
            <div className="card">
              {this.renderFmdDropdown()}
              {!!selectedFieldMetaData && this.renderFieldSelector()}
            </div>
          </div>
          {editedEntitiesIds.length || editing
            ? this.renderProgressBar(count)
            : null}
          {!!errors.length && (
            <div className="FieldSuggestionValidationModal__failures">
              <h3 className="FieldSuggestionValidationModal__failureTitle">
                Failure report
              </h3>
              {this.renderFailureReportHeader()}
              {errors.map((error) => this.renderFailedEntity(error))}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(FieldSuggestionEditionModal);
