import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';

import { notificationError, notificationSuccess } from 'actions/notifications';
import { OrganizationAutocomplete } from 'components/autocomplete';
import { STATUS_ACTIVE } from 'constants/organization';
import { dataSpiders } from 'modules/io/import/constants';
import etlApi from 'resources/etlApi';

import { ImportDropzone } from '../../dropzone';

import './modal.scss';

const mergeModes = [
  {
    value: 'deep_patch',
    label: 'Merge list',
  },
  {
    value: 'patch',
    label: 'Override list (except labels)',
  },
  {
    value: 'patch_all',
    label: 'Override list',
  },
  {
    value: 'no_patch',
    label: '⚠ Override all ⚠',
  },
];

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

class ImportModal extends Component {
  static propTypes = {
    onCloseModal: PropTypes.func,
    dispatch: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: null,
      loading: false,
      config: fromJS({
        merge_mode: 'deep_patch',
        synchronous: false,
        from_dashboard: true,
      }),
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  onSelectFiles = (files) => {
    this.setState({ files });
  };

  filesSelected = () => {
    const { files } = this.state;
    return !!(files && files.length);
  };

  uploadFiles = () => {
    const { files } = this.state;
    const { dispatch, onCloseModal } = this.props;
    files.forEach((file) => {
      const payload = this.state.config.toJS();
      this.setState({ loading: true });
      etlApi.ProductsImportUploadV2(payload, file).then(
        () => {
          this.setState({
            loading: false,
          });
          dispatch(notificationSuccess('File successfully imported'));
          onCloseModal();
        },
        () => {
          dispatch(
            notificationError('There was an error while importing your file'),
          );
          this.setState({ loading: false });
          onCloseModal();
        },
      );
    });
  };

  onUpdateMergeMode = (e) =>
    this.setState((prevState) => ({
      config: e.value
        ? prevState.config.set('merge_mode', e.value)
        : prevState.config.delete('merge_mode'),
    }));

  onUpdateFormat = (e) =>
    this.setState((prevState) => ({
      config: prevState.config.set('spider', e.value),
    }));

  getFormat = () =>
    dataSpiders.find((o) => o.value === this.state.config.get('spider'));

  getMergeMode = () =>
    mergeModes.find((o) => o.value === this.state.config.get('merge_mode'));

  selectOrganization = (organization) => {
    this.setState((prevState) => ({
      organization,
      config: prevState.config.set('organization_id', organization.value.id),
    }));
  };

  renderParameters() {
    return (
      <div className="row ImportModal__params">
        <div className="col-xs-4">
          <SimpleSelect
            id="ImportModal-Spider"
            options={dataSpiders}
            value={this.getFormat()}
            onSelect={this.onUpdateFormat}
            autoSize
            placeholder="Select a format..."
          />
        </div>
        <div className="col-xs-4">
          <SimpleSelect
            id="ImportModal-Merge"
            options={mergeModes}
            value={this.getMergeMode()}
            onSelect={this.onUpdateMergeMode}
            autoSize
          />
        </div>
        <div className="col-xs-4">
          <OrganizationAutocomplete
            id="organization-autocomplete"
            value={this.state.organization ? [this.state.organization] : []}
            onSelect={this.selectOrganization}
            onUnselect={this.unselectOrganization}
            placeholder="Select an organization..."
            searchOnClick
            filters={{ statuses: [STATUS_ACTIVE] }}
          />
        </div>
      </div>
    );
  }

  renderDrodown() {
    const { files } = this.state;
    if (this.filesSelected()) {
      return (
        <div className="ImportModal__summary">
          <div className="ImportModal__summaryTitle">
            {`You are about to import ${files.length} file(s):`}
          </div>
          <div className="ImportModal__fileSummary">
            {files.map((file) => (
              <div key={file.name}>
                <i className="mdi mdi-file" />
                {file.name}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return <ImportDropzone onSelectFiles={this.onSelectFiles} />;
  }

  render() {
    const { onCloseModal } = this.props;
    const { files, loading } = this.state;
    return (
      <Modal
        title="Import data"
        modalStyle="fullHeight"
        confirmButtonText="Upload"
        confirmDisabled={!files}
        isProcessing={loading}
        onConfirm={this.uploadFiles}
        onClose={onCloseModal}
      >
        {this.renderDrodown()}
        {this.renderParameters()}
      </Modal>
    );
  }
}

export default connect(mapDispatchToProps)(ImportModal);
