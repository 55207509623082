import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { GDSN_CI_LIST_STORE_KEY } from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][GDSN_CI_LIST_STORE_KEY];
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export const selectFilters = createSelector(
  selectState,
  (state) => state.filters,
);

export function selectModal(state) {
  return selectState(state).cihwModal;
}

export function selectFiltersDirection(state) {
  return selectState(state).filters.direction;
}

export function selectFiltersGLN(state) {
  return selectState(state).filters.filter_gln_in;
}

export function selectFiltersGTIN(state) {
  return selectState(state).filters.filter_gtin_in;
}
