import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button, SwitchButton } from '@alkem/react-ui-button';
import { Text, Textarea } from '@alkem/react-ui-inputs';

import { isValidJSON } from 'utils';

export default class ReferentialManagementNodeUpdateRow extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isUpdating: PropTypes.bool,
    selected: PropTypes.object,
    onUpdate: PropTypes.func,
  };

  state = {
    label: null,
    data: null,
  };

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (selected !== prevProps.selected) {
      // eslint-disable-next-line
      this.setState({
        label: get(selected, 'label') || null,
        data: JSON.stringify(get(selected, 'data')) || null,
        translatable: get(selected, 'translatable') || false,
      });
    }
  }

  onChange = (nodeKey) => (event) => {
    const { value } = event.target;
    this.setState({ [nodeKey]: value });
  };

  toggleTranslatable = (value) => {
    this.setState({ translatable: value });
  };

  onUpdate = () => this.props.onUpdate(this.state);

  render = () => {
    const { selected, isLoading, isUpdating } = this.props;
    const { label, data, translatable } = this.state;
    const dataClasses = classNames({
      'OrganizationUsesFieldsView__referential__data--invalid':
        data !== null && !isValidJSON(data),
    });
    return (
      <div className="OrganizationUsesFieldsView__referential__update">
        <div className="OrganizationUsesFieldsView__referential__update__row">
          <div>Code</div>
          <div>Label</div>
          <div>Data</div>
        </div>
        <div className="OrganizationUsesFieldsView__referential__update__row">
          <div>
            <Text
              id="organization-referential-update-code"
              value={get(selected, 'code')}
              onChange={this.onChange('code')}
              disabled
            />
          </div>
          <div>
            <Text
              id="organization-referential-update-label"
              onChange={this.onChange('label')}
              value={label}
            />
          </div>
          <div className={dataClasses}>
            <Textarea
              id="organization-referential-update-data"
              onChange={this.onChange('data')}
              value={data}
            />
          </div>
          <div>
            <div>
              <SwitchButton
                id="organization-referential-update-translatable"
                checked={translatable}
                onChange={this.toggleTranslatable}
                content="translatable"
                disabled={get(selected, 'translatable')}
              />
            </div>
            <Button
              id="organization-referential-update-button"
              onClick={this.onUpdate}
              content="Update referential"
              disabled={isLoading || !label}
              displaySpinner={isUpdating}
              primary
            />
          </div>
        </div>
      </div>
    );
  };
}
