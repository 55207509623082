import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import { SET_SELECTED_EXPORT_MAPPING } from '../../../actions/constants';
import { selectCustomParseOptions } from '../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  ExportMappingPayloadSimple,
  Option,
  RegularOptionWithStringId,
} from '../../../types';
import { ExportMappingsTurboSelector } from '../turbo-selector';
import { isOption, isRegularOption } from '../utils';

import { ExportMappingsSimpleElementItemFieldTypePropsSelector } from './field-type-props-selector';
import styles from './simple-element-item-type.module.scss';
import { itemTypeOptions } from './utils';
import { ExportMappingsSimpleElementItemValueTypePropsSelector } from './value-type-props-selector';

const getInitialItemType = (payload: ExportMappingPayload) => {
  const { field, value, custom_parse } = payload;
  if (custom_parse) {
    return itemTypeOptions[2];
  }
  if (value) {
    return itemTypeOptions[1];
  }
  if (field) {
    return itemTypeOptions[0];
  }
  return undefined;
};
interface Props {
  name: string;
  exportMapping: ExportMapping;
  isEditionInProgress?: boolean;
  disabled?: boolean;
}
export function ExportMappingsSimpleElementItemType({
  name,
  exportMapping,
  isEditionInProgress = false,
  disabled = true,
}: Props) {
  const dispatch = useDispatch();
  const customParseOptions = useSelector(selectCustomParseOptions);
  const [itemTypeOption, setItemTypeOption] = useState<
    RegularOptionWithStringId | undefined
  >(getInitialItemType(exportMapping.payload));
  const [customParseOption, setCustomParseOption] = useState<
    Option | undefined
  >(
    customParseOptions.find(
      (customParseOption) =>
        customParseOption.id === exportMapping.payload.custom_parse,
    ),
  );

  const dispatchSetExportMapping = useCallback(
    (
      {
        cast,
        field,
        use_su,
        referential,
        is_declinable,
        value,
        custom_parse,
      }: ExportMappingPayloadSimple,
      isUpdatingValue?: boolean,
    ) => {
      let payload = { ...exportMapping.payload };
      if (!isUpdatingValue) {
        payload = {
          ...exportMapping.payload,
          do_not_add_module: undefined,
          use_get_attr_rec_with_children: undefined,
          is_GS1_enum: undefined,
          must_not_loop: undefined,
          new_module: undefined,
          set_attribute_from_value: undefined,
          set_attribute_from_field: undefined,
          is_normalized: undefined,
          is_not_GS1_declinable: undefined,
          expressed_in_code: undefined,
          unit: undefined,
          strip_html: undefined,
          predicate: undefined,
          code_list_name_code: undefined,
          repeat: undefined,
          is_on_paris_timezone: undefined,
          is_on_other_timezone: undefined,
          default_value: undefined,
        };
      }
      dispatch({
        type: SET_SELECTED_EXPORT_MAPPING,
        payload: {
          exportMapping: {
            ...exportMapping,
            payload: {
              ...payload,
              cast,
              field,
              use_su,
              referential,
              is_declinable,
              value,
              custom_parse,
            },
          },
        },
      });
    },
    [dispatch, exportMapping],
  );

  const updateItemType = (
    value: TurboSelectOption<RegularOptionWithStringId>,
  ) => {
    setCustomParseOption(undefined);
    if (isRegularOption(value)) {
      setItemTypeOption(value);
    } else {
      setItemTypeOption(undefined);
    }
    dispatchSetExportMapping({});
  };

  const updateCustomParse = (value: TurboSelectOption<Option>) => {
    const { cast } = exportMapping.payload;
    if (isOption(value)) {
      setCustomParseOption(value);
      dispatchSetExportMapping({ cast, custom_parse: value.id });
    } else {
      setCustomParseOption(undefined);
      dispatchSetExportMapping({ cast });
    }
  };

  const showEditionFields = isEditionInProgress && !disabled;

  return (
    <div
      className={classNames(styles.container, {
        [styles.disabledContainer]: disabled,
      })}
    >
      <ExportMappingsTurboSelector
        label="Type of mapping"
        testId={`gdsn-export-mappings-simple-element-${name}-type-property`}
        options={itemTypeOptions}
        onSelect={updateItemType}
        option={itemTypeOption}
        showEditionFields={showEditionFields}
        mustShowError={!disabled}
      />
      {itemTypeOption?.id === 'field' && (
        <ExportMappingsSimpleElementItemFieldTypePropsSelector
          name={name}
          exportMapping={exportMapping}
          dispatchSetExportMapping={dispatchSetExportMapping}
          showEditionFields={showEditionFields}
        />
      )}
      {itemTypeOption?.id === 'value' && (
        <ExportMappingsSimpleElementItemValueTypePropsSelector
          name={name}
          exportMapping={exportMapping}
          dispatchSetExportMapping={dispatchSetExportMapping}
          showEditionFields={showEditionFields}
        />
      )}
      {itemTypeOption?.id === 'custom_parse' && (
        <ExportMappingsTurboSelector
          label="Custom parse"
          testId={`gdsn-export-mappings-simple-element-${name}-custom-parse`}
          options={customParseOptions}
          onSelect={updateCustomParse}
          option={customParseOption}
          placeholder={
            customParseOptions.length === 0
              ? 'No custom parse found'
              : undefined
          }
          disabled={customParseOptions.length === 0}
        />
      )}
    </div>
  );
}
