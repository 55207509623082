import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { SET_IMPORT_MAPPING_PROPERTY } from '../../../actions/constants';
import { DATETIME_FORMAT, UNLIMITED_DATE } from '../../../constants';
import { ImportMapping } from '../../../types';
import { ImportMappingsDateSelector } from '../date-selector';

import './item-activation-dates.scss';

interface Props {
  importMapping: ImportMapping;
}
export function ImportMappingsItemActivationDates({
  importMapping,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<Moment | undefined>(
    moment(importMapping.dateStart),
  );
  const [endDate, setEndDate] = useState<Moment | undefined>(
    moment(importMapping.dateEnd),
  );
  const [showStartDateCalendar, setShowStartDateCalendar] = useState(false);
  const [showEndDateCalendar, setShowEndDateCalendar] = useState(false);
  const [isEndDateUnlimited, setIsEndDateUnlimited] = useState(
    moment(importMapping.dateEnd).isSame(UNLIMITED_DATE) || false,
  );

  const updateStartDate = (date: Moment) => {
    setStartDate(date);
    dispatch({
      type: SET_IMPORT_MAPPING_PROPERTY,
      payload: {
        importMapping: {
          ...importMapping,
          dateStart: date.format(DATETIME_FORMAT),
        },
      },
    });
  };

  const updateEndDate = (date?: Moment) => {
    setEndDate(date);
    dispatch({
      type: SET_IMPORT_MAPPING_PROPERTY,
      payload: {
        importMapping: {
          ...importMapping,
          dateEnd: date ? date.format(DATETIME_FORMAT) : undefined,
        },
      },
    });
  };

  const toggleShowStartDateCalendar = (): void => {
    if (showEndDateCalendar) setShowEndDateCalendar(false);
    setShowStartDateCalendar(!showStartDateCalendar);
  };

  const toggleShowEndDateCalendar = (): void => {
    if (showStartDateCalendar) setShowStartDateCalendar(false);
    setShowEndDateCalendar(!showEndDateCalendar);
  };

  const updateIsEndDateUnlimited = (isUnlimited: boolean) => {
    setIsEndDateUnlimited(isUnlimited);
    if (isUnlimited) {
      setShowEndDateCalendar(false);
      updateEndDate(moment(UNLIMITED_DATE));
    } else {
      updateEndDate(undefined);
    }
  };

  return (
    <div
      data-testid="GDSNImportMappingsItem_activationDates"
      className="GDSNImportMappingsItemActivationDates"
    >
      <div className="GDSNImportMappingsItemActivationDates__sectionTitle">
        Activation dates
      </div>
      <ImportMappingsDateSelector
        date={startDate}
        label="Start date"
        name={`${importMapping.field}-start`}
        selectDate={updateStartDate}
        showCalendar={showStartDateCalendar}
        toggleShowCalendar={toggleShowStartDateCalendar}
      />
      <ImportMappingsDateSelector
        date={endDate}
        isUnlimited={isEndDateUnlimited}
        label="End date"
        name="end"
        selectDate={updateEndDate}
        setIsUnlimited={updateIsEndDateUnlimited}
        showCalendar={showEndDateCalendar}
        toggleShowCalendar={toggleShowEndDateCalendar}
      />
    </div>
  );
}
