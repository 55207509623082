import proptypes from 'prop-types';
import qs from 'querystringify';
import React, { useEffect, useState } from 'react';

import { ORGANIZATION_ROLE_ADMIN } from 'constants/user';
import { authApi } from 'resources/authApi';
import { getStreamUrl } from 'utils/stream';

export function GetLogAs({ id }) {
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState();
  const [userName, setUserName] = useState();

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }
      const response = await authApi.UserMe();
      const userData = response.data.data;
      const getUserMail = async () => {
        try {
          const response = await authApi.OrganizationUsersV2(
            id,
            qs.stringify(
              {
                with_not_accepted: false,
                with_inactive: false,
                permissions: ORGANIZATION_ROLE_ADMIN,
                limit: 1,
                offset: 0,
              },
              true,
            ),
          );
          return response.data.data;
        } catch (exception) {
          setIsLoading(false);
          return;
        }
      };
      const targetEmail = await getUserMail();
      if (!targetEmail || targetEmail.size === 0) {
        setIsLoading(false);
        return null;
      }
      setUserName(targetEmail[0].username);
      const encodedSource = encodeURIComponent(userData.username);
      const encodedTarget = encodeURIComponent(targetEmail[0].username);
      const encodedOrganizationId = encodeURIComponent(
        targetEmail[0].belongsTo[0].id,
      );

      setUrl(
        getStreamUrl(
          `logas?source=${encodedSource}&target=${encodedTarget}&organization=${encodedOrganizationId}`,
        ),
      );
      setIsLoading(false);
    };
    init();
  }, [id]);

  if (isLoading) {
    return <div>{`Fetching users for organization with id: ${id}`}</div>;
  }

  return (
    <div>
      {url ? (
        <div>
          <div>
            link to log as {userName} : <a href={url}>click here</a>
          </div>
        </div>
      ) : (
        `could not generate logas link for organization id ${id}. Check if the organization has active admins accounts.`
      )}
    </div>
  );
}

GetLogAs.propTypes = {
  id: proptypes.string,
};
