import { ExportMapping, Xpath } from '../../../types';
import { ExportMappingsItemChild } from '../item-child';

import styles from './item-children.module.scss';

interface Props {
  name: string;
  element: Xpath;
  exportMapping: ExportMapping;
}
export function ExportMappingsItemChildren({
  name,
  element,
  exportMapping,
}: Props) {
  const { children } = element;
  let sxm_list_parent: { use_su: boolean; field: string } | undefined =
    undefined;

  if (exportMapping.payload.cast === 'list') {
    const payloadField = exportMapping.payload.field as string;
    sxm_list_parent = {
      use_su: exportMapping.payload.use_su || false,
      field: exportMapping.sxm_list_parent?.field
        ? `${exportMapping.sxm_list_parent?.field}.${payloadField}`
        : payloadField,
    };
  }

  return (
    <div className={`GDSNExportMappingsItemChildren_${name}`}>
      <div className={styles.sectionTitle}>Children</div>
      {children.map((child) => {
        const childExportMappings = exportMapping.children.filter(
          ({ xpath_id }) => xpath_id === child.id,
        );
        const sameLevelElementChildren = children.filter(
          ({ id }) => id !== child.id,
        );

        return (
          <ExportMappingsItemChild
            key={child.id}
            element={child}
            parentElement={element}
            sameLevelElementChildren={sameLevelElementChildren}
            exportMappings={childExportMappings}
            parent_id={exportMapping.id}
            sxm_list_parent={sxm_list_parent}
            parentExportMapping={exportMapping}
          />
        );
      })}
    </div>
  );
}
