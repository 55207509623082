import { List } from 'immutable';
import React from 'react';

import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from 'components/autocomplete';
import {
  ORGANIZATION_TYPE_RETAILER,
  ORGANIZATION_TYPE_THIRD_PARTY,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
} from 'constants/organization';

import { authApi } from '../resources';
import { getOrganizationOption } from '../selectors';

type Props = {
  showInactives?: boolean;
} & LegacyAutocompleteProps;

export const OrganizationAutocomplete = React.memo(
  function OrganizationAutocomplete({
    showInactives = false,
    ...props
  }: Props) {
    const onSearch = (search) => {
      let nameLegal = search;
      let searchIds: number[] | null = null;
      if (search.match(/^\d+$/)) {
        searchIds = [Number(search)];
        nameLegal = '';
      }
      return authApi.OrganizationListV3(
        {
          nameLegal,
          types: [
            ORGANIZATION_TYPE_RETAILER.id,
            ORGANIZATION_TYPE_THIRD_PARTY.id,
          ],
          statuses: showInactives
            ? [STATUS_ACTIVE, STATUS_INACTIVE]
            : [STATUS_ACTIVE],
          ids: searchIds,
        },
        {
          fieldsUsed: true,
          type: true,
          network: true,
          settings: true,
          status: true,
        },
      );
    };

    const transformData = (orgList = List()) =>
      orgList.map(getOrganizationOption).toArray();

    return (
      <LegacyAutocomplete
        onSearch={onSearch}
        transformData={transformData}
        {...props}
      />
    );
  },
);
