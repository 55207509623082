import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { AdvancedModal } from '@alkem/react-ui-modal';

import { DELETE_XPATH_ELEMENT } from '../../actions/constants';
import { XPATH_TYPES } from '../../constants';
import { Xpath, XpathList } from '../../types';

import styles from './element-selector.module.scss';
import { ListElement } from './list-element';

interface Props {
  elements: XpathList;
  selectElement: (element: Xpath) => void;
  selectedElement: Xpath | undefined;
  showElementCreationView: () => void;
  disabled: boolean;
  setXpathElementOrder: (moduleOrder: number) => void;
  isXpathCreationInProgress: boolean;
  setIsChildXpathElementCreation: (
    isChildXpathElementCreation: boolean,
  ) => void;
  xpathElementOrder?: number;
}

export function ElementSelector({
  elements,
  selectElement,
  selectedElement,
  showElementCreationView,
  disabled,
  setXpathElementOrder,
  isXpathCreationInProgress,
  setIsChildXpathElementCreation,
  xpathElementOrder,
}: Props) {
  const dispatch = useDispatch();
  const [showElementCreationModal, setShowElementCreationModal] =
    useState(false);
  const [showChildElementCreationModal, setShowChildElementCreationModal] =
    useState(false);
  const [showElementDeletionModal, setShowElementDeletionModal] =
    useState(false);

  const updateShowElementCreationModal = (element: Xpath) => {
    selectElement(element);
    setShowElementCreationModal(true);
  };

  const updateShowElementDeletionModal = (element: Xpath) => {
    selectElement(element);
    setShowElementDeletionModal(true);
  };

  const updateShowChildElementCreationModal = (element: Xpath) => {
    selectElement(element);
    setShowChildElementCreationModal(true);
  };

  const confirmElementCreation = (isUp = false) => {
    if (selectedElement) {
      setIsChildXpathElementCreation(false);
      setShowElementCreationModal(false);
      showElementCreationView();
      setXpathElementOrder(selectedElement.order + (isUp ? 0 : 1));
    }
  };

  const confirmChildElementCreation = () => {
    if (selectedElement) {
      setIsChildXpathElementCreation(true);
      setShowChildElementCreationModal(false);
      showElementCreationView();
      setXpathElementOrder(0);
    }
  };

  const confirmElementDeletion = () => {
    if (selectedElement) {
      setShowElementDeletionModal(false);
      dispatch({ type: DELETE_XPATH_ELEMENT, xpath: selectedElement });
    }
  };

  const renderElementCreationModal = (element: Xpath) => {
    return (
      <AdvancedModal
        title={`Create element up or down ${element.name}?`}
        onClose={() => setShowElementCreationModal(false)}
        hideFooter
      >
        <div
          className={styles.modalButtonsContainer}
          data-testid={`modal-${element.id}`}
        >
          <Button content="Up" onClick={() => confirmElementCreation(true)} />
          <Button content="Down" onClick={() => confirmElementCreation()} />
        </div>
      </AdvancedModal>
    );
  };

  const renderChildElementCreationModal = (element: Xpath) => {
    return (
      <AdvancedModal
        title={`Create child element for ${element.name}?`}
        onClose={() => setShowChildElementCreationModal(false)}
        onConfirm={confirmChildElementCreation}
      >
        <div
          className={styles.modalButtonsContainer}
          data-testid={`modal-${element.id}`}
        >
          <div className={styles.modalText}>
            <span>Element {element.name} doesn't have any children.</span>
            <span>Create the first child?</span>
          </div>
        </div>
      </AdvancedModal>
    );
  };

  const renderChildElementDeletionModal = (element: Xpath) => {
    return (
      <AdvancedModal
        title={`Delete the element ${element.name}?`}
        onClose={() => setShowElementDeletionModal(false)}
        onConfirm={confirmElementDeletion}
        modalStyle="warning"
      >
        <div
          className={styles.modalButtonsContainer}
          data-testid={`modal-deletion-${element.id}`}
        >
          <div className={styles.modalText}>
            <span>
              You won't be able to see it{' '}
              {element.type !== 'SIMPLE' ? 'or its children' : ''} in the
              dashboard anymore.
            </span>
            <span>All of the mappings associated will be deactivated.</span>
          </div>
        </div>
      </AdvancedModal>
    );
  };

  const renderNewXpath = (isModule = false) => {
    return (
      <div className={styles.newXpath}>
        {isModule ? 'new_module' : 'new_element'}
      </div>
    );
  };

  const renderChildren = (
    children: XpathList,
    highestParentElement?: Xpath,
  ) => {
    return (
      <div className={styles.childContainer}>
        {children.map((child) => {
          const mustDisplayNewElement =
            isXpathCreationInProgress &&
            selectedElement?.id === child.id &&
            typeof xpathElementOrder !== 'undefined';

          if (child.type === XPATH_TYPES.list) {
            return (
              <React.Fragment key={child.id}>
                {mustDisplayNewElement &&
                  xpathElementOrder === child.order &&
                  renderNewXpath()}
                <ListElement
                  element={child}
                  renderChildren={renderChildren}
                  selectElement={selectElement}
                  updateShowElementCreationModal={
                    updateShowElementCreationModal
                  }
                  updateShowChildElementCreationModal={
                    updateShowChildElementCreationModal
                  }
                  updateShowElementDeletionModal={
                    updateShowElementDeletionModal
                  }
                  highestParentElement={highestParentElement || child}
                />
                {mustDisplayNewElement &&
                  xpathElementOrder > child.order &&
                  renderNewXpath()}
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={child.id}>
                {mustDisplayNewElement &&
                  xpathElementOrder === child.order &&
                  renderNewXpath()}
                <div className={styles.childNamesContainer} key={child.id}>
                  <button
                    className={classNames(
                      'alk-btn-phantom',
                      styles.button,
                      child.is_inactive ? styles.inactiveElement : '',
                    )}
                    onClick={() => selectElement(highestParentElement || child)}
                    data-testid={child.id}
                  >
                    {child.name}
                  </button>
                  <button
                    className={classNames('alk-btn-phantom', styles.button)}
                    data-testid={`plus-${child.id}`}
                    onClick={() => updateShowElementCreationModal(child)}
                  >
                    <i className="mdi mdi-plus" />
                  </button>
                  <button
                    className={classNames('alk-btn-phantom', styles.button)}
                    data-testid={`delete-${child.id}`}
                    onClick={() => updateShowElementDeletionModal(child)}
                  >
                    <i className="mdi mdi-trash-can-outline" />
                  </button>
                </div>
                {mustDisplayNewElement &&
                  xpathElementOrder > child.order &&
                  renderNewXpath()}
              </React.Fragment>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div
      className={classNames({
        [styles.elementSelectorDisabled]: disabled,
      })}
    >
      {elements.map((element) => {
        if ([XPATH_TYPES.module, XPATH_TYPES.avp].includes(element.type)) {
          const mustDisplayNewModule =
            isXpathCreationInProgress &&
            selectedElement?.id === element.id &&
            typeof xpathElementOrder !== 'undefined';
          return (
            <React.Fragment
              key={`${element.id}${mustDisplayNewModule ? element.id : ''}`}
            >
              {mustDisplayNewModule &&
                xpathElementOrder === element.order &&
                renderNewXpath(true)}
              <ListElement
                element={element}
                renderChildren={renderChildren}
                selectElement={selectElement}
                updateShowElementCreationModal={updateShowElementCreationModal}
                updateShowChildElementCreationModal={
                  updateShowChildElementCreationModal
                }
                updateShowElementDeletionModal={updateShowElementDeletionModal}
              />
              {mustDisplayNewModule &&
                xpathElementOrder > element.order &&
                renderNewXpath(true)}
            </React.Fragment>
          );
        } else {
          return;
        }
      })}
      {showElementCreationModal &&
        selectedElement &&
        renderElementCreationModal(selectedElement)}
      {showChildElementCreationModal &&
        selectedElement &&
        renderChildElementCreationModal(selectedElement)}
      {showElementDeletionModal &&
        selectedElement &&
        renderChildElementDeletionModal(selectedElement)}
    </div>
  );
}
