import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Text } from '@alkem/react-ui-inputs';

import './right-panel-functions.scss';

export default class RightPanelFunction extends PureComponent {
  static propTypes = {
    selectedFunctionList: PropTypes.array,
  };

  render() {
    const { selectedFunctionList } = this.props;
    if (!selectedFunctionList) {
      return null;
    }
    return (
      <table className="RightPanel__Functions">
        <thead>
          <tr>
            <th className="ReferentialEntity__name">Name</th>
            <th className="ReferentialEntity__description">Description</th>
          </tr>
        </thead>
        <tbody>
          {selectedFunctionList.map((e) => (
            <tr key={`right-panel-category-attribute-${e.id}`}>
              <td>
                <Text
                  id={`right-panel-attribute-${e.name}`}
                  value={e.name}
                  disabled={true}
                />
              </td>
              <td>
                <Text
                  id={`right-panel-attribute-${e.description}`}
                  value={e.description}
                  disabled={true}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
