import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Radio, Text } from '@alkem/react-ui-inputs';
import { SimpleSelect } from '@alkem/react-ui-select';

import InputWithLabel from 'components/input-with-label';

import { IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES } from '../../../../constants';
import { selectReferentialCodes } from '../../../../selectors';
import {
  ImportMappingDetails,
  ImportMappingDetailsDefault,
  Property,
  ReferentialCode,
} from '../../../../types';

import './item-optional-property-default-value.scss';

interface Props {
  details: ImportMappingDetails;
  dispatchImportMapping: (properties: Property[]) => void;
  fieldName: string;
  type: string;
}
export function ImportMappingsItemOptionalPropertyDefaultValue({
  details,
  dispatchImportMapping,
  fieldName,
  type,
}: Props): JSX.Element {
  const referentialCodes = useSelector(selectReferentialCodes);
  const [defaultValue, setDefaultValue] = useState<ImportMappingDetailsDefault>(
    type === 'entity' && details.referential
      ? referentialCodes?.[details.referential]?.find(
          ({ id }) => Object(details.default)?.id === id,
        )
      : details.default,
  );
  const [isReset, setIsReset] = useState(false);
  const originalDefaultValue = useRef(details.default).current;

  const updateDefaultValueFromText = (event: {
    target: { value: string };
  }): void => {
    let importMappingDetailsDefault: string | number = event.target.value;
    if (type.includes('positive') && importMappingDetailsDefault === '-') {
      importMappingDetailsDefault = '';
    }
    setDefaultValue(importMappingDetailsDefault);
    if (IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.number.includes(type)) {
      const importMappingDetailsDefaultApi = type.includes('float')
        ? parseFloat(String(importMappingDetailsDefault))
        : parseInt(String(importMappingDetailsDefault));
      dispatchImportMapping([
        {
          name: 'default',
          value: isNaN(Number(importMappingDetailsDefaultApi))
            ? undefined
            : Number(importMappingDetailsDefaultApi),
        },
      ]);
    } else {
      dispatchImportMapping([
        { name: 'default', value: importMappingDetailsDefault || undefined },
      ]);
    }
  };

  const updateDefaultValueFromRadio = (event: {
    target: { value: string };
  }): void => {
    let importMappingDetailsDefault: boolean | undefined;
    if (event.target.value === 'true') importMappingDetailsDefault = true;
    if (event.target.value === 'false') importMappingDetailsDefault = false;
    setDefaultValue(importMappingDetailsDefault);
    dispatchImportMapping([
      { name: 'default', value: importMappingDetailsDefault },
    ]);
  };

  const updateDefaultValueFromSimpleSelect = (
    referentialCode: ReferentialCode,
  ): void => {
    setDefaultValue(referentialCode);
    dispatchImportMapping([
      {
        name: 'default',
        value: { id: referentialCode.id, code: referentialCode.label },
      },
    ]);
  };

  const resetDefaultValue = () => {
    setDefaultValue(undefined);
    setIsReset(true);
    dispatchImportMapping([
      {
        name: 'default',
        value: undefined,
      },
    ]);
  };
  return (
    <>
      {(IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.string.includes(type) ||
        IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.number.includes(type)) && (
        <InputWithLabel
          inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-default-value`}
          label="Default value"
        >
          <Text
            id={`gdsn-import-mappings-field-${fieldName}-optional-property-default-value`}
            value={String(defaultValue || '')}
            onChange={updateDefaultValueFromText}
            type={
              IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.string.includes(type)
                ? 'text'
                : 'number'
            }
          />
        </InputWithLabel>
      )}
      {IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.boolean.includes(type) && (
        <InputWithLabel
          inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-default-value`}
          label="Default value"
        >
          <Radio
            id={`gdsn-import-mappings-field-${fieldName}-optional-property-default-value`}
            value={defaultValue}
            options={[
              { label: 'TRUE', value: true },
              { label: 'FALSE', value: false },
              { label: 'N/A', value: undefined },
            ]}
            onChange={updateDefaultValueFromRadio}
          />
        </InputWithLabel>
      )}
      {IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.referential_codes_list.includes(
        type,
      ) &&
        details.referential &&
        referentialCodes &&
        (referentialCodes[details.referential] || []).length > 0 && (
          <InputWithLabel
            inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-default-value`}
            label="Default value"
          >
            <div className="GDSNImportMappingsItemOptionalPropertyDefaultValue__listContainer">
              <SimpleSelect
                id={`gdsn-import-mappings-field-${fieldName}-optional-property-default-value`}
                options={referentialCodes[details.referential]}
                onSelect={updateDefaultValueFromSimpleSelect}
                value={defaultValue}
              />
              <span
                className="GDSNImportMappingItem__reset-button"
                onClick={resetDefaultValue}
              >
                <i className="mdi mdi-close" />
              </span>
            </div>
            {!isReset && !!originalDefaultValue && !defaultValue && (
              <div className="GDSNImportMappingItemOptionalPropertyDefaultValue__message">
                {`A default value is set to ${
                  typeof originalDefaultValue === 'object'
                    ? originalDefaultValue?.code
                    : originalDefaultValue
                }.
                This value doesn't exist in the referential ${
                  details.referential
                }.
                Set a new value to modify it or click on the cross to delete it.`}
              </div>
            )}
          </InputWithLabel>
        )}
      {IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.referential_codes_list.includes(
        type,
      ) &&
        (!details.referential ||
          (referentialCodes?.[details.referential] || []).length === 0) && (
          <InputWithLabel
            inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-default-value`}
            label="Default value"
          >
            <div className="GDSNImportMappingItemOptionalPropertyDefaultValue__message">
              {details.referential
                ? `The referential ${details.referential} has no existing codes associated.`
                : "No referential is defined for this entity so the default value can't be set."}
            </div>
            {!isReset && !!originalDefaultValue && (
              <div className="GDSNImportMappingItemOptionalPropertyDefaultValue__message">
                {`A default value is set to ${
                  typeof originalDefaultValue === 'object'
                    ? originalDefaultValue?.code
                    : originalDefaultValue
                }. Click on the cross to delete it:`}
                <span
                  className="GDSNImportMappingItem__reset-button"
                  onClick={resetDefaultValue}
                >
                  <i className="mdi mdi-close" />
                </span>
              </div>
            )}
          </InputWithLabel>
        )}
    </>
  );
}
