import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@alkem/react-ui-button';

import Dropdown from 'components/dropdown';
import { useConfirm } from 'components/use-confirm';
import { edaApi } from 'resources/edaApi';
import { logError } from 'utils';

import { Message, Queue } from '../types';

import { EdaRmqReplayerQueueErrorMessage } from './error-message';
import './row.scss';

export function EdaRmqReplayerQueue({
  queue,
  maxCountLength,
  onRefresh,
}: {
  queue: Queue;
  maxCountLength: number;
  onRefresh: () => void;
}) {
  const [actionCount, setActionCount] = useState<number>(queue.messages_ready);
  const [isOpen, setIsOpen] = useState(false);
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    if (isOpen) {
      fetchQueueDetail(queue.name, 10);
    }
  }, [isOpen, queue.name]);

  const [confirmDiscardModal, openConfirmDiscard] = useConfirm({
    body: (
      <div>
        <p>Do you want to discard the following queue?</p>
        <p className="EdaRmqReplayerQueue__confirmQueyeName">{queue.name}</p>
      </div>
    ),
    actionLabel: 'Discard',
    isProcessing: isPerformingAction,
  });

  const [confirmPurgeModal, openConfirmPurge] = useConfirm({
    body: (
      <div>
        <p>Do you want to purge the following queue?</p>
        <p className="EdaRmqReplayerQueue__confirmQueyeName">{queue.name}</p>
      </div>
    ),
    actionLabel: 'Purge',
    isProcessing: isPerformingAction,
  });

  async function fetchQueueDetail(queueName: string, limit: number) {
    setIsPerformingAction(true);
    try {
      const res: any = await edaApi.RmqErrorDetail(queueName, limit);
      setMessages(res.data.errors || []);
    } catch (err) {
      logError(err);
    }
    setIsPerformingAction(false);
  }

  const replayPage = useCallback(
    async (queueName: string, actionCount: number) => {
      setIsPerformingAction(true);
      try {
        await edaApi.RmqErrorAction(
          queueName,
          'reject_requeue_false',
          actionCount,
        );
        onRefresh();
      } catch (err) {
        logError(err);
      }
      setIsPerformingAction(false);
    },
    [onRefresh],
  );

  const discardPage = useCallback(
    async (queueName: string, actionCount: number) => {
      setIsPerformingAction(true);
      try {
        await edaApi.RmqErrorAction(
          queueName,
          'ack_requeue_false',
          actionCount,
        );
        onRefresh();
      } catch (err) {
        logError(err);
      }
      setIsPerformingAction(false);
    },
    [onRefresh],
  );

  const purgeQueue = useCallback(
    async (queueName: string) => {
      setIsPerformingAction(true);
      try {
        await edaApi.RmqErrorPurge(queueName);
        onRefresh();
      } catch (err) {
        logError(err);
      }
      setIsPerformingAction(false);
    },
    [onRefresh],
  );

  const actions = useMemo(
    () =>
      [
        isOpen && {
          key: 'view',
          label: (
            <div>
              <i
                className={classNames(
                  'mdi',
                  'mdi-eye',
                  'EdaRmqReplayerQueue__buttonIcon',
                )}
              />
              View
            </div>
          ),
          onClick: () => fetchQueueDetail(queue.name, actionCount),
          attrs: { disabled: isPerformingAction },
        },
        {
          key: 'replay',
          label: (
            <div>
              <i
                className={classNames(
                  'mdi',
                  'mdi-reload',
                  'EdaRmqReplayerQueue__buttonIcon',
                )}
              />
              Replay
            </div>
          ),
          onClick: () => replayPage(queue.name, actionCount),
          attrs: { disabled: isPerformingAction },
        },
        queue.name !== 'catch-all-proxy-error' && {
          key: 'discard',
          label: (
            <div className="EdaRmqReplayerQueue__dangerousAction">
              <i
                className={classNames(
                  'mdi',
                  'mdi-trash-can',
                  'EdaRmqReplayerQueue__buttonIcon',
                )}
              />
              Discard
            </div>
          ),
          onClick: () =>
            openConfirmDiscard(() => discardPage(queue.name, actionCount)),
          attrs: { disabled: isPerformingAction },
        },
        queue.name !== 'catch-all-proxy-error' && {
          key: 'purge',
          label: (
            <div className="EdaRmqReplayerQueue__dangerousAction">
              <i
                className={classNames(
                  'mdi',
                  'mdi-trash-can-outline',
                  'EdaRmqReplayerQueue__buttonIcon',
                )}
              />
              Purge all
            </div>
          ),
          onClick: () => openConfirmPurge(() => purgeQueue(queue.name)),
          attrs: { disabled: isPerformingAction },
        },
      ].filter((o) => o),
    [
      actionCount,
      discardPage,
      isOpen,
      isPerformingAction,
      openConfirmDiscard,
      openConfirmPurge,
      purgeQueue,
      queue.name,
      replayPage,
    ],
  );

  return (
    <div className="EdaRmqReplayerQueue" data-testid="list-row">
      <div className="EdaRmqReplayerQueue__row">
        <div>
          <Button
            className="EdaRmqReplayerQueue__chevron"
            onClick={() => setIsOpen(!isOpen)}
            testid="show-details"
            link
          >
            <i
              className={classNames(
                'mdi',
                isPerformingAction
                  ? `mdi-loading mdi-spin`
                  : `mdi-chevron-${isOpen ? 'down' : 'right'}`,
              )}
            />
          </Button>
        </div>
        <div className="EdaRmqReplayerQueue__count">
          <div>{'0'.repeat(maxCountLength)}</div>
          <div>{queue.messages_ready}</div>
        </div>
        <div className="EdaRmqReplayerQueue__name">{queue.name}</div>
        <div className="EdaRmqReplayerQueue__actions">
          <input
            id="input-replay-count"
            className="EdaRmqReplayerQueue__input"
            style={{ width: `${maxCountLength + 1}em` }}
            type="number"
            value={actionCount}
            onChange={(e) => setActionCount(Number(e.target.value))}
          />
          <Dropdown
            label="Actions"
            options={actions}
            testid="actions-button"
            withoutWrappers
            rightDropdown
          />
        </div>
      </div>
      {isOpen && (
        <div className="EdaRmqReplayerQueue__bottomSeparator">
          {messages.map((message) => (
            <EdaRmqReplayerQueueErrorMessage
              key={message.context.event_id}
              message={message}
            />
          ))}
        </div>
      )}
      {confirmDiscardModal}
      {confirmPurgeModal}
    </div>
  );
}
